import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Icon } from 'shared/components'
import { submitProject, getProject } from 'features/Project/redux/ProjectOperations'
import { setModalContent, openConfirmModal, setLoading } from 'shared/redux/ScreenActions'
import { getContacts, getCustomer, getCustomerUsers, getPeopleLists } from 'shared/redux/ListActions'
import './ProjectDetails.scss'
import ProjectForm from '../ProjectForm'
import ProjectService, { IRejectWithMessage } from 'utils/api/project/ProjectService'
import { contactInfoConfig, customerInfoConfig, projectInfoConfig } from './projectDetailsConfig'
import { FormComponentProps } from 'antd/lib/form'
import { IProject, IUserDetail } from 'shared/types/swagger'
import { sanitizeNonASCIIInput, typedUseSelector } from 'utils/helpers'
import { useAppDispatch } from 'shared/hooks/app'
import { unwrapResult } from '@reduxjs/toolkit'

type WrappedForm = { props: FormComponentProps }

function ProjectDetails() {
  const history = useHistory()
  const currentProject = typedUseSelector(
    (state) => state.ProjectReducer.selectedProject
  )
  const { permissions, roles, authorizedexternalprojecttypes } = typedUseSelector((state) => state.UserReducer)
  const dispatch = useAppDispatch()
  const [error, setError] = useState(null)
  const [fetchingProject, setFetchingProject] = useState(false)
  const [project, setProject] = useState<Partial<IProject>>(currentProject)

  const customerid = currentProject?.customerid

  const projectInfo = useRef<WrappedForm>(null)
  const customerInfo = useRef<WrappedForm>(null)
  const contactInfo = useRef<WrappedForm>(null)
  const projectOwner = useRef<WrappedForm>(null)

  useEffect(() => {
    (async () => {
      if (!currentProject) {
        setFetchingProject(true)
      }
      await dispatch(getPeopleLists())
      if (customerid) {
        await dispatch(getCustomer(customerid))
        await dispatch(getContacts(customerid))
        await dispatch(getCustomerUsers(customerid))
      }
      if (!currentProject) {
        try {
          const projectName = await ProjectService.generateProjectName(null)
          if (projectName) {
            setProject({ number: projectName })
            setFetchingProject(false)
          }
        } catch (e) {
          console.log(e)
          setFetchingProject(false)
        }
      }
      window.addEventListener('beforeunload', onUnload)
    })()

    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (customerid) {
      dispatch(getContacts(customerid))
      dispatch(getCustomerUsers(customerid))
    }
  }, [customerid, dispatch])

  useEffect(() => {
    if ('modifieddatetime' in (currentProject || {})) {
      setProject(currentProject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.modifieddatetime, currentProject])

  const onUnload = (e) => {
    // the method that will be used for both add and remove event
    const _e = e || window.event
    const confirmationMessage = 'You may lose unsaved changes if you leave this page.'
    _e.returnValue = confirmationMessage
    return confirmationMessage
  }

  const handleProjectDetailsSubmit = async () => {
    const canEditProjectDetais = permissions.includes('view_edit_projects_details')

    let errors = false
    const formValues: {
      project: Partial<IProject>;
      customer?: IUserDetail;
      contact?: IUserDetail;
    } = {
      project: {},
    }
    const forms = [
      {
        name: projectInfo.current,
        key: 'project',
      },
    ]

    if (canEditProjectDetais) {
      forms.push(
        {
          name: customerInfo.current,
          key: 'customer',
        },
        {
          name: contactInfo.current,
          key: 'contact',
        }
      )
    }

    forms.forEach((form) => {
      try {
        form.name.props.form.validateFields(async (err, values) => {
          if (!err) {
            formValues[form.key] = values
          } else {
            errors = true
          }
        })
      } catch (e) {
        errors = true
      }
    })

    if (errors) {
      dispatch(
        openConfirmModal({
          bodyText: 'Please fix all validation errors and try again.',
          cancelButton: false,
          confirmButtonText: 'OK',
        })
      )
    } else
    {
      completeProjectDetailsSubmit(formValues)
    }
  }

  const completeProjectDetailsSubmit = async (formValues) => {
    const payload: Partial<IProject> = {
      ...formValues.project,
      name: sanitizeNonASCIIInput(formValues.project.name),
    }
    if ('processFlow' in formValues.project) {
      payload.processflow = sanitizeNonASCIIInput(formValues.project.processflow)
    }
    if (formValues.customer) {
      payload.customerid = formValues.customer.customerid
    }
    if (formValues.contact) {
      payload.contactid = formValues.contact.contactid
    }

    // case for project creation for external user with only one project type allowed. The radio button choice is not displayed and therefore the externalprojecttype is not populated in the payload
    if ((project.isexternalproject || (project.isexternalproject ==null && roles.includes('ExternalUser'))) && project.externalprojecttype == null  && authorizedexternalprojecttypes.length==1)
    {
      payload.externalprojecttype = authorizedexternalprojecttypes[0]
    }

    try {
      // case for project creation for external user, we re-fetch a project number with the given externalprojecttype
      if (project.isexternalproject ==null && roles.includes('ExternalUser') && payload.number == null) {
        payload.number = await ProjectService.generateProjectName(payload.externalprojecttype)
      }
  
      const response = await dispatch(submitProject({ payload, history }))
      if ('error' in response) {
        const { payload: e } = response as { payload: IRejectWithMessage }
        if (e?.status === 422) {
          setProject({ ...project, number: e.message })
          projectInfo.current.props.form.setFieldsValue({ number: e.message })
          setError(
            "This project number has already been used. We've automatically incremented it to the next available project number. Please try again."
          )
          const errorMessage = document.getElementById('project-details')
          if (errorMessage) {
            errorMessage.scrollIntoView(true)
          }
        }
      } else {
        dispatch(setModalContent(null))
      }
    } catch (e) {
      debugger
      dispatch(setLoading(false))
      setError(e.message)
      const errorMessage = document.getElementById('project-details')
      if (errorMessage) {
        errorMessage.scrollIntoView(true)
      }
    }
  }
  
  const resetFields = (form) => {
    if (form === 'customerid') {
      customerInfo.current.props.form.setFieldsValue({
        name: '',
        number: '',
        streetaddress1: '',
        streetaddress2: '',
        city: '',
        state: '',
        zipcode: '',
        phonenumber: '',
        faxnumber: '',
      })
    }
  }

  const renderDivider = () => <div className="divider" />

  const canEditProjectDetais = permissions.includes('view_edit_projects_details')
  return (
    <div id="project-details">
      <h1 className="project-details__header">
        Project Details
        {error ? <p className="error">{error}</p> : null}
      </h1>
      <Icon icon="x" size={30} onClick={() => dispatch(setModalContent(null))} />

      {renderDivider()}

      <div className="project-details__section">
        <h2>Project Info</h2>
        <ProjectForm
          wrappedComponentRef={(r: WrappedForm) => {
            projectInfo.current = r
          }}
          config={projectInfoConfig}
          project={project}
          fetchingProject={fetchingProject}
        />
      </div>

      {canEditProjectDetais ? (
        <>
          {renderDivider()}

          <div className="project-details__section">
            <h2 id="customer-info-header">Customer Info</h2>
            <ProjectForm
              wrappedComponentRef={(r: WrappedForm) => {
                customerInfo.current = r
              }}
              config={customerInfoConfig}
              resetFields={resetFields}
              project={project}
              fetchingProject={fetchingProject}
            />
          </div>

          {renderDivider()}

          <div className="project-details__section">
            <h2>Contact Info</h2>
            <ProjectForm
              wrappedComponentRef={(r: WrappedForm) => {
                contactInfo.current = r
              }}
              config={contactInfoConfig}
              resetFields={resetFields}
              project={project}
              fetchingProject={fetchingProject}
            />
          </div>

        </>
      ) : null}

      <div>
        <Button
          className="submit"
          text="OK"
          onClick={handleProjectDetailsSubmit}
          margin="20px 10px 0 0"
        />
        <Button secondary text="Cancel" uppercase onClick={() => dispatch(setModalContent(null))} />
      </div>
    </div>
  )
}

export default ProjectDetails
