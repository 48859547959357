import { selectEstimatorMetaReducer } from 'features/Estimator/components/Accessories/redux/selectors'
import React, { useMemo } from 'react'
import {
  selectIntermediateDriveOrientations,
  selectMetalDetectorTypes,
} from 'shared/redux/ListSelectors'
import { MeasuringUnit } from 'shared/types/MeasuringUnit'
import { typedUseSelector } from 'utils/helpers'
import { MM_IN_IN } from '../constants'
import { selectAllConveyors } from '../redux/builderCanvasSelectors'
import { BuilderCanvasSectionData } from '../types/types'
import HorizontalCurve from './HorizontalCurve'
import InfeedDischarge from './InfeedDischarge'
import IntermediateDrive from './IntermediateDrive'
import MetalDetector from './MetalDetector'
import Straight from './Straight'
import VerticalCurve from './VerticalCurve'
import _ from 'lodash'
import { countCautionAndWarnings } from '../helpers/countErrorAndWarnings'
import { getStatusColor } from '../helpers/getStatusColor'
import { selectConveyorSVGWidth } from '../../../redux/selectors'

export type ConveyorRendererProps = {
  conveyorId: number;
  sectionData: BuilderCanvasSectionData;
}

/** Used to render all the 'path' and 'g' tags, needs to be housed in an 'svg' tag */
export default function ConveyorRenderer({ conveyorId, sectionData }: ConveyorRendererProps) {
  const allConveyors = typedUseSelector(selectAllConveyors)
  const estimatorMeta = typedUseSelector(selectEstimatorMetaReducer)
  const metalDetectorTypes = typedUseSelector(selectMetalDetectorTypes)
  const intermediateDriveOrientations = typedUseSelector(selectIntermediateDriveOrientations)
  const conveyor = useMemo(() => allConveyors[conveyorId], [allConveyors, conveyorId])
  const conveyorbuilder = useMemo(() => estimatorMeta[conveyorId]?.conveyorbuilder, [
    estimatorMeta,
    conveyorId,
  ])
  const { chain, unit, conveyorsections } = conveyor
  const { rotateCanvas, selectedSectionId: sectionId } = conveyorbuilder
  const selectedSectionId = parseInt(sectionId)
  const rotateCanvasValue = rotateCanvas ? 90 : 0
  const isMetric = unit === ('Metric' as MeasuringUnit)
  const chainwidth = isMetric ? chain.widthmetric / MM_IN_IN : chain.widthenglish
  const conveyorWidth = typedUseSelector((state) => selectConveyorSVGWidth(state, conveyorId))

  const infeed = sectionData[0] ? (
    <InfeedDischarge
      key={0}
      id={`infeed-${conveyorId}`}
      anchorAngleDeg={sectionData[0].anchorAngleDeg + rotateCanvasValue}
      anchorPosition={sectionData[0].anchorPosition}
      conveyorId={conveyorId}
      type="Infeed"
      width={conveyorWidth}
      length={(12.25) * (100 / 3.76)}
    />
  ) : null

  const sections = _.map(conveyorsections, (sec) => {
    if (!sectionData[sec.sectionnumber]) {
      return null
    } else {
      const { length } = sectionData[sec.sectionnumber]
      const { radius } = sectionData[sec.sectionnumber]
      const errors = countCautionAndWarnings(sec)
      const { cautions, warnings } = errors

      switch (sec.type) {
        case 'Straight': {
          return (
            <Straight
              key={sec.id}
              id={sec.id}
              width={conveyorWidth}
              anchorAngleDeg={sectionData[sec.sectionnumber].anchorAngleDeg + rotateCanvasValue}
              anchorPosition={sectionData[sec.sectionnumber].anchorPosition}
              color={getStatusColor(sec.id === selectedSectionId, cautions, warnings)}
              conveyorId={conveyorId}
              length={(isMetric ? length / MM_IN_IN : length) * (100 / 3.76)}
            />
          )
        }
        case 'HorizontalCurve':
          return (
            <HorizontalCurve
              key={sec.id}
              id={sec.id}
              anchorAngleDeg={sectionData[sec.sectionnumber].anchorAngleDeg + rotateCanvasValue}
              anchorPosition={sectionData[sec.sectionnumber].anchorPosition}
              angle={sec.angle}
              width={conveyorWidth}
              color={getStatusColor(sec.id === selectedSectionId, cautions, warnings)}
              conveyorId={conveyorId}
              radius={(isMetric ? radius / MM_IN_IN : radius) * (100 / 3.76)}
            />
          )
        case 'VerticalCurve':
          return (
            <VerticalCurve
              key={sec.id}
              id={sec.id}
              anchorAngleDeg={sectionData[sec.sectionnumber].anchorAngleDeg + rotateCanvasValue}
              anchorPosition={sectionData[sec.sectionnumber].anchorPosition}
              color={getStatusColor(sec.id === selectedSectionId, cautions, warnings)}
              conveyorId={conveyorId}
              width={conveyorWidth}
              incline={sec.incline}
            />
          )

        case 'MetalDetector': {
          const metalDetectorLengthIN = metalDetectorTypes?.[sec?.metaldetectortypeid]?.numericvalue ?? 0

          return (
            <MetalDetector
              key={sec.id}
              id={sec.id}
              width={conveyorWidth}
              anchorAngleDeg={sectionData[sec.sectionnumber].anchorAngleDeg + rotateCanvasValue}
              anchorPosition={sectionData[sec.sectionnumber].anchorPosition}
              conveyorId={conveyorId}
              length={(metalDetectorLengthIN / chainwidth) * conveyorWidth}
            />
          )
        }
        case 'IntermediateDrive': {
          return (
            <IntermediateDrive
              key={sec.id}
              pullPosition={intermediateDriveOrientations?.[sec?.feeddriveorientationid]?.title}
              id={sec.id}
              width={conveyorWidth}
              anchorAngleDeg={sectionData[sec.sectionnumber].anchorAngleDeg + rotateCanvasValue}
              anchorPosition={sectionData[sec.sectionnumber].anchorPosition}
              angle={sec.angle}
              conveyorId={conveyorId}
              length={(19.5512 / chainwidth) * conveyorWidth}
            />
          )
        }
        default:
          return null
      }
    }
  })

  let discharge: ReturnType<typeof InfeedDischarge> = null
  if (conveyorsections && Object.keys(sectionData).length === Object.keys(conveyorsections ?? {}).length + 2) {
    const dischargeSection = Object.keys(conveyorsections).length + 1
    discharge = (
      <InfeedDischarge
        key={dischargeSection}
        id={`discharge-${conveyorId}`}
        conveyorId={conveyorId}
        anchorAngleDeg={sectionData[dischargeSection].anchorAngleDeg + rotateCanvasValue}
        anchorPosition={sectionData[dischargeSection].anchorPosition}
        type="Discharge"
        width={conveyorWidth}
        length={12.25 * (100 / 3.76)}
      />
    )
  }

  return (
    <>
      {infeed}
      {sections}
      {discharge}
    </>
  )
}
