import { IListElement } from 'shared/types/swagger'
import { ListElementRecord } from 'utils/api/list/types'
import { formatIdAsKey } from 'utils/helpers'
import { ListItem } from '../types'

export default function filterListByTitles(
  list: Record<string, ListItem>,
  titles: Array<string>
): Record<string, ListItem> {
  return formatIdAsKey(
    Object.values(list).filter((listelement) =>
      titles.includes(listelement.title || listelement.gear)
    )
  )
}
