import * as React from 'react'
import { Form, Input } from 'shared/components'
import { Col } from 'antd'

function getExtra(formValue, isTouched, storedValue) {
  if (!storedValue) return null
  const hasNewValue = formValue !== storedValue.toString() && isTouched
  return <span>{hasNewValue && Boolean(formValue) ? `Current Value: ${storedValue}` : ' '}</span>
}

/**
 * @typedef {Object} Props
 * @prop {string} name
 * @prop {string} label
 * @prop {string|number} value
 * @prop {import('antd/lib/form/Form').WrappedFormInternalProps['form']} form
 * @prop {number} [cols]
 * @prop {Object} [inputProps]
 * @prop {boolean} disabled
 */

/** @param {Props} props */
export default function PCFInputRow(props) {
  const { cols = 24, name, label, value, form, disabled, inputProps = {} } = props

  const extra = getExtra(form.getFieldValue(name), form.isFieldTouched(name), value)

  try {
    return (
      <Col sm={cols} className="tm-input tm-input--double">
        <label htmlFor={name} className="tm-input__label">
          {label}
        </label>
        <div className="pcf-container">
          <Form.Item className="pcf-input" extra={extra}>
            {form.getFieldDecorator(name, {
              initialValue: value,
              rules: [{ required: true, message: `${label} is required.` }],
            })(<Input small placeholder={value.toString()} {...inputProps} disabled={disabled} />)}
          </Form.Item>
        </div>
      </Col>
    )
  } catch (error) {
    console.log(props)
    return null
  }
}
