import { FormItemOption } from 'shared/types/formConfig'
import { IListElement } from 'shared/types/swagger'
import { ListItem } from '../types'
import isVisible from './isVisible'
import sortListItems from './sortListItems'

export default function getSelectOptions(
  list: Record<string, ListItem>,
  isExternal?: boolean,
  sortBy?: keyof IListElement
): Array<FormItemOption> {
  const values = Object.values(list)
  const sortedValues = sortListItems(values, sortBy)

  return sortedValues
    .filter((o) => isVisible(o, isExternal))
    .map((o) => ({
      label: o.title || o.gear,
      value: o.id,
      // disabled: !isVisible(o, isExternal),
    }))
}
