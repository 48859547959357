/** @type {import('shared/types/formConfig.d').FormConfigItem[]} */
export default (values) => [
  {
    key: 'projectPrefix',
    type: 'input',
    rules: [{ required: true, message: 'Prefix is required.' }],
    label: 'Project Name Prefix',
    value: values.projectPrefix,
  },
  {
    key: 'sparePartPrefix',
    type: 'input',
    rules: [{ required: true, message: 'Spare Parts Prefix is required.' }],
    label: 'Spare Parts Project Name Prefix',
    value: values.sparePartPrefix,
  },
]
