import { createAsyncThunk } from '@reduxjs/toolkit'
import ProjectService from 'utils/api/project/ProjectService'
import { formatIdAsKey } from 'utils/helpers'
import { removeNotQueuedMasterVersionsMetadata } from 'features/Conveyor/redux/ConveyorActions'
import { Store } from 'srcReducer'
import { IProject } from 'shared/types/swagger'
import { IProjectState } from './ProjectReducer'
import { CancelTokenSource } from 'axios'

// getProjects is in its own file
export const getProjects = createAsyncThunk<
  Partial<IProjectState>,
  { cancelTokenSource: CancelTokenSource, currentFilterPage: number, filterTerm: string, statusFilter:string },
  { state: Store }
>(
  'ProjectReducer/getProjects',
  async ({ cancelTokenSource, currentFilterPage, filterTerm, statusFilter }, { dispatch, rejectWithValue, getState }) => {
    try {      
      const result = await ProjectService.getQueue(cancelTokenSource?.token,currentFilterPage ?? 1,filterTerm , statusFilter!= 'All' ? statusFilter : '')
      const queueProjects = formatIdAsKey(result.searchResults)
      const queueProjectsVersionIds = result.searchResults.map((proj) => proj?.masterversion?.id).filter(Boolean)

      dispatch(removeNotQueuedMasterVersionsMetadata({ versionIds: queueProjectsVersionIds }))

      return {
        projectQueueResults:queueProjects,
        filterTerm:result.filterTerm,
        currentFilterPage:result.currentFilterPage,
        totalFilterPages:result.totalFilterPages,
        currentFilterStatus:statusFilter
      }
    } catch (error) {
      const errorMsg = error?.message || error?.response?.data
      return rejectWithValue(errorMsg)
    }
  }
)
