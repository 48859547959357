import { TextTransformName, TextTransformUnion } from 'shared/types/TextTransform';
import _ from 'lodash'

export function transformText<T extends string>(text: T, option: 'capitalize'): Capitalize<T>
export function transformText<T extends string>(text: T, option: 'uppercase'): Uppercase<T>
export function transformText<T extends string>(text: T, option: 'lowercase'): Lowercase<T>
export function transformText<
  T extends string, 
  E extends TextTransformName
>(text: T, option: E): TextTransformUnion<T>
export function transformText<
  T extends string, 
  E extends TextTransformName
>(text: T, option: E): TextTransformUnion<T> {
  switch (option) {
    case 'uppercase': return text.toUpperCase() as Uppercase<T>
    case 'lowercase': return text.toLowerCase() as Lowercase<T>
    case 'capitalize': return _.capitalize(text) as Capitalize<T>
  }
}