import React, { Component } from 'react'
import { Spin, Icon } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Washdown, Wearstrips, Sideframes } from './components'
import '../shared/MaterialAccessoryItem.scss'
import { captureSentryError } from 'utils/helpers'

class Materials extends Component {
  render() {
    const { fetchingInitialMaterialsData, conveyorId } = this.props

    return (
      <div className="materials-container">
        {fetchingInitialMaterialsData ? (
          <Spin
            style={{ marginTop: '5px' }}
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        ) : (
          <>
            <Washdown conveyorId={conveyorId} />
            <Sideframes conveyorId={conveyorId} />
            <Wearstrips conveyorId={conveyorId} />
          </>
        )}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer } = state
    const { conveyorId, match } = props
    const {
      params: { versionId, projectId },
    } = match

    if (!EstimatorReducer?.[conveyorId]?.materials) {
      return {
        fetchingInitialMaterialsData: true,
      }
    }

    return {
      projectId,
      versionId,
      conveyorId,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(connect(mapStateToProps, null)(Materials))
