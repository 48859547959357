import _ from 'lodash'
import { connect, ConnectedProps } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  deleteNonStandardChainRow,
  duplicateNonStandardChainRow,
  saveCurrentNonStandardRowState,
  updateNonStandardChainRow,
  updateNonStandardRowMeta,
} from 'features/Chain/redux/ChainActions'
import { getVersionConveyors } from 'features/Version/redux/VersionActions'
import { Icon, ActionMenu } from 'shared/components'
import colors from 'shared/constants/colors'
import { nonStandardRowOptions } from 'shared/constants/actionMenuOptions'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'
import { IChainPattern } from 'shared/types/swagger'
import { IRowPattern } from 'features/Chain/shared/types'
import { Store } from 'reduxStore'

interface Props extends PropsFromRedux {
  conveyorId: number
  pattern: IChainPattern
  chainRowMetaInformation: IRowPattern
  rowIndex: number
}

class RowHeader extends Component<Props> {
  componentDidMount() {
    const {
      conveyorId,
      pattern: { id },
      updateNonStandardRowMeta,
      versionId,
    } = this.props
    updateNonStandardRowMeta({
      conveyorId,
      metaInformation: {
        dirty: false,
      },
      versionId,
      nonStdRowId: id,
    })
  }

  render() {
    const {
      chainMetaInformation: { dirty },
      conveyorId,
      isactive,
      islocked,
      pattern: { leftelementid, centerelements, rightelementid },
      totalWidth = 0,
      updateNonStandardRowMeta,
      versionId,
      widthDifference,
    } = this.props

    const isOpened = this.props.chainRowMetaInformation.open
    const isNewRow = !leftelementid || !rightelementid || !centerelements.length

    const previousRowsRepeat = _.take(this.props.chainPatterns, this.props.rowIndex)
      .map((cp) => cp.repeat)
      .reduce((acc, curr) => acc + curr, 0)

    let thisRow
    let endRow
    if (this.props.rowIndex === 0) {
      thisRow = this.props.rowIndex + 1 + previousRowsRepeat
      endRow = this.props.rowIndex + previousRowsRepeat + this.props.pattern.repeat
    } else {
      thisRow = previousRowsRepeat + 1
      endRow = previousRowsRepeat + this.props.pattern.repeat
    }

    return (
      <React.Fragment>
        <div className="chainbuilder-row-header">
          <div className="chainbuilder-row-header__left-content">
            <Icon
              className="expand-icon"
              icon={isOpened ? 'minus' : 'plus'}
              size={16}
              color={isOpened ? colors.novaBlue : colors.ash}
              onClick={() => {
                updateNonStandardRowMeta({
                  conveyorId,
                  metaInformation: {
                    open: !isOpened,
                  },
                  versionId,
                  nonStdRowId: this.props.pattern.id,
                })
              }}
            />
            <div className="title">
              <div className="text">
                {thisRow === endRow ? `Row ${thisRow}` : `Rows ${thisRow} - ${endRow}`}
              </div>
              <span
                className="width-information"
                style={widthDifference ? { color: colors.negative } : {}}
              >
                {`${(totalWidth / 25.4).toFixed?.(2)} in | ${totalWidth.toFixed?.(2)} mm`}
                {/* <span>{widthDifference ? `{ ${ widthDifference < 0 ? '+ ' : '- ' } ${ widthDifference.toFixed(2) } )` : null}</span> */}
              </span>
            </div>
            {widthDifference ? (
              <div className="error-message">
                <Icon icon="x" size={14} color={colors.negative} />
                Invalid Row Width
              </div>
            ) : null}
            {!isNewRow && dirty ? (
              <div className="error-message">
                <Icon icon="caution" size={14} color={colors.bourbon} />
                Unsaved Changes
              </div>
            ) : null}
          </div>
          {isactive && !islocked ? (
            <ActionMenu options={nonStandardRowOptions(this.props)} />
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: Store, props) => {
  try {
    const { ConveyorReducer, ChainReducer, ProjectReducer } = state
    const { conveyorId, widthDifference, match, pattern, totalWidth } = props
    const {
      params: { versionId },
    } = match
    const conveyor = ConveyorReducer.conveyors[conveyorId]
    const { isactive, islocked } = ProjectReducer.versions[versionId]
    return {
      totalWidth,
      versionId,
      widthDifference,
      conveyorId: conveyor.id,
      chainMetaInformation: ChainReducer[versionId][conveyor.id].nonStandardRowMeta[pattern.id],
      chainPatterns: conveyor.chain.chainpatterns,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  deleteNonStandardChainRow,
  duplicateNonStandardChainRow,
  getVersionConveyors,
  openConfirmModal,
  saveCurrentNonStandardRowState,
  updateNonStandardChainRow,
  updateNonStandardRowMeta,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default withRouter(connector(RowHeader))
