export default function ifDecimalRoundToNum(value: number | string, decimalpt = 2) {
  if (value) {
    const valueNum = Number(value)
    const hasDecimalPt = valueNum.toString().split('.').length > 1
    if (hasDecimalPt) {
      return valueNum.toFixed(decimalpt)
    }
    return valueNum
  } else {
    return 0
  }
}
