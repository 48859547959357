import React, { Component } from 'react'
export default class SearchIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="-3 -3 32 32"
      >
        <path
          fill={color}
          d="M23.547116,21.1161061 L18.2659567,15.8768608 C21.2888121,11.6125899 20.5420956,5.74370251 16.5477244,2.37225478 C12.5533533,-0.99919295 6.64241629,-0.749695836 2.94636023,2.94636023 C-0.749695836,6.64241629 -0.99919295,12.5533533 2.37225478,16.5477244 C5.74370251,20.5420956 11.6125899,21.2888121 15.8768608,18.2659567 L21.1580201,23.505202 C21.8177507,24.1649327 22.8873854,24.1649327 23.547116,23.505202 C24.2068466,22.8454714 24.2068466,21.7758368 23.547116,21.1161061 Z M2.50630657,10.0508199 C2.50630657,5.88410025 5.88410025,2.50630657 10.0508199,2.50630657 C14.2175396,2.50630657 17.5953333,5.88410025 17.5953333,10.0508199 C17.5953333,14.2175396 14.2175396,17.5953333 10.0508199,17.5953333 C8.04989057,17.5953333 6.13091408,16.8004672 4.71604337,15.3855965 C3.30117266,13.9707258 2.50630657,12.0517493 2.50630657,10.0508199 L2.50630657,10.0508199 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(4.000000, 4.000000)">
            <g mask="url(#mask-2)" fill="none">
              <g transform="translate(-4.000000, -4.000000)">
                <rect x="0" y="0" width="32" height="32"></rect>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
