import React, { ReactNode } from 'react'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'

interface ModalHeaderProps {
  handleClose?: () => void;
  title: string | ReactNode;
}

function ModalHeader({ handleClose, title = '' }: ModalHeaderProps) {
  return (
    <div className="modal-header">
      <h1 className="modal-header__title">{title}</h1>
      <Icon
        className="modal-header__close"
        icon="x"
        size={24}
        color={Colors.novaBlue}
        onClick={handleClose}
      />
    </div>
  )
}

export default ModalHeader
