/**
 * @typedef {keyof import('shared/types/MiscellanousPartSelection').MiscellanousPartSelection} MiscPartsKeys
 * 
 * @typedef {Object} PCFormConfigItem
 * @property {string} [category]
 * @property {string} key
 * @property {string} label
 * @property {import('antd/lib/input').InputProps} [inputProps]
 * @property {number} [cols]
 */

/** @type {Array<PCFormConfigItem>} */
export const assemblyEngineeringLMConfig = [
  {
    category: 'conveyor',
    key: '0',
    label: 'Straight Conveyor MUF',
  },
  {
    category: 'conveyor',
    key: '1',
    label: '<2 Flat Horizontal Conveyor MUF',
  },
  {
    category: 'conveyor',
    key: '2',
    label: '>2 Flat Horizontal or Helical Conveyor MUF',
  },
  {
    category: 'options',
    key: 'engineeringcost',
    label: 'Engineering Cost',
    cols: 12,
    inputProps: {
      addonBefore: '$',
    },
  },
  {
    category: 'options',
    key: '4',
    label: 'Engineering MUF',
    cols: 12,
  },
  {
    category: 'options',
    key: '5',
    label: 'Microspan Transfer MUF',
    cols: 12,
  },
  {
    category: 'options',
    key: 'assemblycost',
    label: 'Assembly Cost',
    cols: 12,
  },
  {
    category: 'options',
    key: '6',
    label: 'Powertrain MUF',
    cols: 12,
  },
  {
    category: 'options',
    key: 'lamuf',
    label: 'LA MUF',
    cols: 12,
  },
  {
    category: 'lmcosts',
    key: 'lcs',
    label: 'Labor/Machine Costs',
    cols: 8,
  },
]

/** @type {Array<PCFormConfigItem>} */
export const constImportConfig = [
  {
    category: 'all',
    key: 'freightpercentage',
    label: 'Freight Percent',
    cols: 12
  },
  {
    category: 'all',
    key: 'dutypercentage',
    label: 'Duty Percent',
    cols: 12
  }
]

/** @type {Array<Omit<PCFormConfigItem, 'key'> & {key: Exclude<MiscPartsKeys, 'id'>}>} */
export const miscpartsConfig = [
  {
    category: 'all',
    key: 'timeperbend',
    label: 'Time/Bend',
    inputProps: { addonAfter: 'min' },
    cols: 6
  },
  {
    category: 'all',
    key: 'deburrtime',
    label: 'Deburr Time',
    inputProps: { addonAfter: 'lpm' },
    cols: 6
  },
  {
    category: 'all',
    key: 'weldtime',
    label: 'Weld Time',
    inputProps: { addonAfter: 'lpm' },
    cols: 6
  },
  {
    category: 'all',
    key: 'powdercoat',
    label: 'Powder Coat',
    inputProps: { addonAfter: 'in²' },
    cols: 6
  },
  // {
  //   category: 'all',
  //   key: 'lasercutspeed7ga',
  //   label: '7ga Laser Cut Speed',
  //   inputProps: { addonAfter: 'lpm' },
  //   cols: 6
  // },
  // {
  //   category: 'all',
  //   key: 'gacutspeed161412',
  //   label: '16, 14, 12ga Cut Speed',
  //   inputProps: { addonAfter: 'lpm' },
  //   cols: 6
  // },
  {
    category: 'all',
    key: 'pcsqinchedperhour',
    label: 'PC in²/hr',
    cols: 6
  },
  {
    category: 'all',
    key: 'inclusiveservicefee',
    label: 'Inclusive Service Visit Fee',
    cols: 6,
    inputProps: {
      addonBefore: '$',
    },
  },
  {
    category: 'all',
    key: 'inclusiveservicethreshold',
    label: 'Inclusive Service Visit Fee Threshold',
    cols: 6,
    inputProps: {
      addonBefore: '$',
    },
  },
]