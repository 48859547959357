import { ActionMenuOption } from 'shared/components/ActionMenu'
import { FormItemOption } from 'shared/types/formConfig'

export const projectDetailsActionMenu = (project, roles, onClick, isexternalproject, islaproject): Array<ActionMenuOption> => {
  const options: Array<ActionMenuOption> = [
    {
      label: 'Edit Project Details',
      handleSelect: () => onClick('edit'),
      icon: 'editSquare',
      className: `edit-project-menu-action-${project.id}`,
    },
    {
      label: 'Select a Version',
      handleSelect: () => onClick('select'),
      icon: 'check',
      className: `select-version-project-menu-action-${project.id}`,
    },
    {
      label: 'Clone Project',
      handleSelect: () => onClick('clone'),
      icon: 'clone',
      className: `clone-project-menu-action-${project.id}`,
    },
    {
      label: 'Clone Project w/o Customer',
      handleSelect: () => onClick('cloneWithoutCustomer'),
      icon: 'clone',
      className: `clone-project-wo-customer-menu-action-${project.id}`,
    },
    {
      label: 'Download Quote',
      handleSelect: () => onClick('download'),
      icon: 'download',
      className: `quickquote-project-menu-action-${project.id}`,
    },
  ]
  if (islaproject) {
    options.splice(4, 1)
  }
  if (isexternalproject) {
    options.splice(3, 1)
  }
  if (roles.includes('Designer')) {
    options.splice(2, 1)
  }
  if (roles.includes('Admin')) {
    options.push({
      label: 'Delete Project',
      type: 'warning',
      handleSelect: () => onClick('delete'),
      icon: 'delete',
      className: `remove-project-menu-action-${project.id}`,
    })
  }
  return options
}

export const versionDownloadOptions = (
  permissions: Array<string>,
  islaproject: boolean,
  roles: Array<string>
): FormItemOption[] => {
  const canDownload = permissions.includes('download_documents')
  const options = new Set<FormItemOption>()
  if (permissions.includes('export_quote_files') || canDownload) {
    options.add({
      label: 'Quote',
      value: 'Quote',
    })
    options.add({
      label: 'Quote Legend',
      value: 'LegendQuote',
    })
  }
  if (canDownload) {
    options.add({
      label: 'Legend',
      value: 'Legend',
    })
    options.add({
      label: 'Master BOM',
      value: 'MasterBOM',
    })
    options.add({
      label: 'Preliminary BOM',
      value: 'PrelimBOM',
    })
  }
  if (islaproject || roles.includes('Admin')) {
    options.add({
      label: 'LA BOM',
      value: 'LABOM',
    })
  }
  if (canDownload) {
    options.add({
      label: 'Horizontal Bend',
      value: 'Bend',
    })
    options.add({
      label: 'Recommended Spare Parts List',
      value: 'SpareParts',
    })
    options.add({
      label: 'All Documents',
      value: 'All',
    })
  }
  return Array.from(options)
}

export const nonStandardRowOptions = (props): Array<ActionMenuOption> => {
  const {
    conveyorId,
    deleteNonStandardChainRow,
    duplicateNonStandardChainRow,
    getVersionConveyors,
    openConfirmModal,
    pattern,
    updateNonStandardChainRow,
    versionId,
  } = props

  const options: Array<ActionMenuOption> = [
    {
      label: 'Duplicate Row',
      handleSelect: () => duplicateNonStandardChainRow(conveyorId, pattern.id),
      icon: 'clone',
      className: `duplicate-row-menu-action-${pattern.id}`,
    },
    {
      label: 'Clear Center Links',
      handleSelect: () => {
        openConfirmModal({
          bodyText: 'All Center Links will be removed from this pattern. Are you sure?',
          onConfirm: async () => {
            await updateNonStandardChainRow(
              conveyorId,
              pattern.id,
              {
                ...pattern,
                centerelements: [],
              },
              true
            )
            getVersionConveyors(versionId)
          },
        })
      },
      icon: 'cancel',
      className: `duplicate-row-menu-action-${pattern.id}`,
    },
    {
      label: 'Delete Row',
      handleSelect: () => {
        openConfirmModal({
          bodyText: 'This action is not reversible. Are you sure you want to remove this row?',
          onConfirm: () => deleteNonStandardChainRow(conveyorId, pattern.id),
        })
      },
      icon: 'delete',
      className: `remove-row-menu-action-${pattern.id}`,
      type: 'warning',
    },
  ]

  return options
}
