import React, { Component } from 'react'
export default class XIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M23.1622707,15.6909108 L23.0804497,15.6909158 C22.2677876,15.6909656 21.6094468,15.0326248 21.6094966,14.2199626 C21.6095464,13.4073005 22.2679679,12.748879 23.0806301,12.7488292 L23.162451,12.7488242 C23.9747664,12.7491211 24.6331072,13.4074619 24.6334041,14.2197773 C24.6333543,15.0324395 23.9749328,15.690861 23.1622707,15.6909108 Z M4.43306803,7.43313211 L4.43294302,20.9926236 L25.525804,20.9924291 L29.6518703,14.2126454 L25.5253555,7.43293766 L4.43306803,7.43313211 Z M2.35615716,23.0694095 C2.13592213,22.8491744 1.9997106,22.5449159 2.00000046,22.2091117 L2.0001479,6.21668886 C1.99986733,5.54479375 2.54472968,4.9999314 3.21662479,5.00021198 L26.209604,5 C26.6340192,4.99999609 27.0274615,5.22195013 27.2488423,5.58384806 L32.1154915,13.5797278 C32.351784,13.9685817 32.3520662,14.4563724 32.1154799,14.8449439 L27.2486832,22.841487 C27.0278692,23.204536 26.6341361,23.425637 26.2097209,23.425641 L3.21645492,23.4255662 C2.88036398,23.4255693 2.57610543,23.2893577 2.35615716,23.0694095 Z"
            fill={color}
            transform="translate(17.146408, 14.212820) rotate(-45.000000) translate(-17.146408, -14.212820) "
          ></path>
        </g>
      </svg>
    )
  }
}
