import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DatePicker, Icon } from 'antd'
import moment from 'moment'
import { customDatePickerRanges } from './datePickerHelpers'
import {
  getStatistics,
  getProjectsByStatus,
  getProjectByStatusDetail,
} from '../../redux/AdminStatsActions'
import './CustomDatePicker.scss'
import { captureSentryError } from 'utils/helpers'

const { MonthPicker } = DatePicker

class CustomDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      monthEndDate: undefined,
      monthStartDate: undefined,
      yearPickerOpen: false,
    }
  }

  dropdownContents = () => {
    return (
      <div className="custom-date-picker__contents">
        <div
          className="preset"
          onClick={() => this.updateStatistics(customDatePickerRanges['ytd'])}
        >
          Current YTD
        </div>
        <div
          className="preset"
          onClick={() => this.updateStatistics(customDatePickerRanges['mtd'])}
        >
          Current MTD
        </div>
        <div
          className="preset"
          onClick={() => this.updateStatistics(customDatePickerRanges['quarter'])}
        >
          Last Quarter
        </div>
        <div
          className="preset"
          onClick={() => this.updateStatistics(customDatePickerRanges['lastyear'])}
        >
          Last Year
        </div>
        {this.customYearPicker()}
        {this.customMonthPicker()}
      </div>
    )
  }

  customYearPicker = () => {
    const { activeProjects, getProjectByStatusDetail, getStatistics } = this.props
    const { yearPickerOpen } = this.state

    return (
      <div className="picker">
        Custom Year
        <div
          onClick={(e) => {
            if (
              e.target.offsetParent.className !== 'ant-calendar-year-panel-header' &&
              !e.target.offsetParent.className.includes('ant-calendar-year-panel-cell')
            ) {
              this.setState({ yearPickerOpen: !yearPickerOpen })
            }
          }}
        >
          <DatePicker
            format="YYYY"
            placeholder="Select Year"
            mode="year"
            onPanelChange={async (value, mode) => {
              const yearDate = moment(value).format('YYYY')
              const yearStart = moment(value).startOf('year').format('YYYY-MM-DD')
              const yearEnd = moment(value).endOf('year').format('YYYY-MM-DD')

              this.setState({ yearPickerOpen: false }, async () => {
                if (activeProjects) {
                  await getProjectByStatusDetail([yearStart, yearEnd], yearDate)
                } else {
                  await getStatistics([yearStart, yearEnd], yearDate)
                }
                this.setState({
                  dropdownOpen: false,
                  monthStartDate: undefined,
                  monthEndDate: undefined,
                })
              })
            }}
            open={yearPickerOpen}
          />
        </div>
      </div>
    )
  }

  customMonthPicker = () => {
    const { activeProjects, getProjectByStatusDetail, getStatistics } = this.props
    const { monthEndDate, monthStartDate } = this.state
    return (
      <div className="picker">
        Custom Month Range
        <div className="month-pickers">
          <MonthPicker
            placeholder="Start Month"
            onChange={async (date) => {
              const monthStartDate = moment(date).startOf('month').format('YYYY-MM-DD')
              this.setState({ monthStartDate }, async () => {
                if (monthEndDate) {
                  if (activeProjects) {
                    await getProjectByStatusDetail(
                      [monthStartDate, monthEndDate],
                      this.monthRangeLabel(monthStartDate, monthEndDate)
                    )
                  } else {
                    await getStatistics(
                      [monthStartDate, monthEndDate],
                      this.monthRangeLabel(monthStartDate, monthEndDate)
                    )
                  }
                  this.setState({
                    dropdownOpen: false,
                    monthStartDate: undefined,
                    monthEndDate: undefined,
                    yearDate: undefined,
                  })
                }
              })
            }}
          />
          <MonthPicker
            placeholder="End Month"
            onChange={async (date) => {
              const monthEndDate = moment(date).endOf('month').format('YYYY-MM-DD')
              this.setState({ monthEndDate }, async () => {
                if (monthStartDate) {
                  if (activeProjects) {
                    await getProjectByStatusDetail(
                      [monthStartDate, monthEndDate],
                      this.monthRangeLabel(monthStartDate, monthEndDate)
                    )
                  } else {
                    await getStatistics(
                      [monthStartDate, monthEndDate],
                      this.monthRangeLabel(monthStartDate, monthEndDate)
                    )
                  }
                  this.setState({
                    dropdownOpen: false,
                    monthStartDate: undefined,
                    monthEndDate: undefined,
                    yearDate: undefined,
                  })
                }
              })
            }}
          />
        </div>
      </div>
    )
  }

  monthRangeLabel = (start, end) => {
    const startMonth = moment(start).format('YYYY-MM')
    const endMonth = moment(end).format('YYYY-MM')
    return `${startMonth} ➔ ${endMonth}`
  }

  toggleDropdown = (e) => {
    const { dropdownOpen } = this.state
    if (e.currentTarget.className === 'custom-date-picker__select-dropdown') {
      this.setState({ dropdownOpen: !dropdownOpen })
    }
  }

  updateStatistics = async (dateStrings) => {
    const {
      activeProjects,
      getProjectByStatusDetail,
      getStatistics,
      getProjectsByStatus,
    } = this.props
    if (activeProjects) {
      await getProjectByStatusDetail(dateStrings)
    } else {
      const statistics = await getStatistics(dateStrings)
      const projectsByStatus = await getProjectsByStatus()
      await Promise.all([statistics, projectsByStatus])
    }
    this.setState({ dropdownOpen: false })
  }

  render() {
    const { calendarLabel } = this.props
    const { dropdownOpen } = this.state
    return (
      <div className="custom-date-picker">
        <div className="custom-date-picker__select-dropdown" onClick={this.toggleDropdown}>
          <div className="custom-date-picker__select-dropdown__text">{calendarLabel}</div>
          {dropdownOpen ? <Icon type="up" /> : <Icon type="down" />}
        </div>
        {dropdownOpen ? this.dropdownContents() : null}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const { calendarLabel } = state.AdminStatsReducer
    return {
      calendarLabel,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { getProjectByStatusDetail, getProjectsByStatus, getStatistics }

export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker)
