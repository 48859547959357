import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { Checkbox, Radio, InputWithValidation, Select } from 'shared/components'
import { gearmotorConfig } from './gearmotorConfig'
import './Gearmotor.scss'
import { updateConveyor, updateConveyorOptimistic } from '../../../Conveyor/redux/ConveyorActions'
import {
  getGearmotorForConveyor,
  updateGearmotorByBrandSelection,
  updateGearmotor,
  updateGearmotorOptimistic,
} from './redux/GearmotorActions'
import { typedUseSelector } from 'utils/helpers'
import { useAppDispatch } from 'shared/hooks/app'
import { v4 as uuidv4 } from 'uuid'
import { IFormConfigItemCheckboxList, IFormConfigItemInputs } from 'shared/types/formConfig'
import { SelectValue } from 'antd/lib/select'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import isSEWorTAEKDS from './helpers/isSEWorTAEKDS'
import { GEARBOX_DEFAULT } from './constants'
import { getVFDOptions } from './OldGearmotor/redux/GearmotorActions'

type GearmotorProps = {
  conveyorId: number;
}

function Gearmotor(props: GearmotorProps) {
  const { conveyorId } = props
  const conveyor = typedUseSelector((state) => state.ConveyorReducer.conveyors?.[conveyorId])
  const isExternal = typedUseSelector((state) => state.UserReducer.userType === 'External')
  const { gearmotor } = conveyor || {}
  const { versionId } = useParams<{ versionId: string }>()
  const { isactive, islocked } = typedUseSelector(
    (state) => state.ProjectReducer.versions[versionId]
  )
  const VFDOptions = typedUseSelector(
    (state) => state.ConveyorReducer.VFDOptions[conveyorId]
  )
  const gearmotorList = typedUseSelector((state) => state.ProjectReducer.currentlistsettings.gearmotor)
  const gearmotorTitle = gearmotorList?.['drivetypes']?.[conveyor.gearmotortypeid]?.title
  const displayBrandOnly = gearmotorTitle === 'Other'
  const desiredUnitOptions = _.sortBy(
    typedUseSelector(
      (state) =>
        state.EstimatorMetaReducer?.[conveyorId]?.gearmotor?.desiredUnitOptions?.[
          conveyor?.gearmotor?.desiredunitid
        ]?.options || []
    ),
    (o) => o.listorder
  )

  const [fieldsId, setFieldsId] = useState(uuidv4())
  const dispatch = useAppDispatch()

  const fetchVFDOptions = useCallback(async () => {
    dispatch(getVFDOptions(conveyorId))
  }, [conveyorId, dispatch])

  const showVFDOptions = useCallback(async () => {
    await fetchVFDOptions()
  }, [fetchVFDOptions])

  useEffect(() => {
    fetchVFDOptions()
  }, [fetchVFDOptions])

  async function onChange(value, errors, values) {
    const key = Object.keys(values)[0]
    const payload = { [key]: value }

    if (key === 'gearboxid') {
      dispatch(updateConveyorOptimistic(conveyorId, values))
    } else if (key === 'gearmotortypeid') {
      await dispatch(updateConveyor(conveyorId, values))
      const updatedFields = await dispatch(updateGearmotorByBrandSelection(conveyorId, value))
      if (updatedFields) {
        setFieldsId(uuidv4())
      }
    } else if (key === 'mountingtypeid') {
      const brandIsSEWorTAEKDS = isSEWorTAEKDS(gearmotorList.drivetypes[conveyor.gearmotortypeid])
      const mountingTypeIsntRemote =
        gearmotorList.eurodrive.selection.mountingtypes?.[value]?.textvalue !== 'R'
      if (
        brandIsSEWorTAEKDS &&
        mountingTypeIsntRemote &&
        gearmotorList.gearboxes[conveyor.gearboxid].gear.includes('F')
      ) {
        dispatch(
          updateConveyorOptimistic(conveyorId, {
            gearboxid:
              Object.values(gearmotorList.gearboxes).find(
                (listitem) => listitem.gear === GEARBOX_DEFAULT
              )?.id || null,
          })
        )
      }
      await dispatch(updateGearmotorOptimistic(conveyorId, payload))
    } else if (key === 'desiredunitid') {
        await dispatch(updateGearmotor(conveyorId, key, value))
    } else if (key === 'variablespeedoptionsid' || key === 'inputvoltage') {
      await dispatch(updateGearmotorOptimistic(conveyorId, { ...payload, vfdselectionid: null }))
    } else {
      await dispatch(updateGearmotorOptimistic(conveyorId, payload))
    }

    if (['vaconxseriesinverterid', 'inputvoltage', 'variablespeedoptionsid'].includes(key)) {
      showVFDOptions()
    }
  }

  function renderForm() {
    const disabled = !isactive || islocked

    const formFields = gearmotorConfig({
      conveyor,
      displayBrandOnly,
      gearmotor,
      gearmotorList,
      desiredUnitOptions,
      isExternal,
      VFDOptions,
    }).map((field, i) => {
      if (!field.visible) return null
      const key = `${field.key ?? i}-${fieldsId}`

      switch (field.type) {
        case 'inputs':
          return (
            <div key={key} className="inputs">
              {(field as IFormConfigItemInputs).inputs.map((input) => (
                <InputWithValidation
                  key={`${key}-${input.key}`}
                  disabled={disabled}
                  dataKey={input.key}
                  name={input.key}
                  label={input.prettyName}
                  onChange={onChange}
                  rules={input.rules}
                  width={300}
                  margin="0 30px 15px 0"
                  defaultValue={input.value}
                  placeholder={input.placeholder}
                />
              ))}
            </div>
          )

        case 'radio':
          return (
            <Radio
              key={key}
              disabled={disabled}
              dataKey={field.key}
              prettyName={field.prettyName}
              margin="0 0 10px 0"
              options={field.options}
              onChange={onChange}
              rules={field.rules}
              initialValue={field.value}
            />
          )

        case 'checkbox':
          return (
            <Checkbox
              key={key}
              disabled={disabled}
              dataKey={field.key}
              label={field.prettyName}
              onChange={onChange}
              checked={field.value}
              extra={field.extra}
            />
          )

        case 'checkbox_list': {
          const checkboxList = _.map((field as IFormConfigItemCheckboxList).options, (o, i) => {
            return (
              <Checkbox
                key={`${key}-${o.id}`}
                disabled={disabled}
                className="checkbox-list__item"
                dataKey={field.key}
                label={o.label}
                onChange={() => onChange(o.id, null, { [field.key]: o.id })}
                initialValue={field.value === o.id}
                checked={field.value === o.id}
              />
            )
          })
          return field.options.length ? (
            <div key={field.key} className="checkbox-list">
              {checkboxList}
            </div>
          ) : null
        }

        case 'select':
          return (
            <Select
              key={key}
              disabled={disabled}
              label={field.prettyName}
              small
              defaultValue={field.value as SelectValue}
              width={field.width}
              placeholder={field.placeholder || 'Select Option'}
              options={field.options}
              onSelect={(v) => onChange(v, null, { [field.key]: v })}
            />
          )

        case 'info': {
          return (
            <div className="tm-select">
              <div className="tm-select__label">{field.prettyName}</div>
              <p dangerouslySetInnerHTML={{ __html: field.value as string }} />
            </div>
          )
        }
        default:
          return null
      }
    })
    return formFields.filter(Boolean)
  }

  if (!gearmotor) return null

  return (
    <div className={`gearmotor-container ${isactive && !islocked ? '' : 'disabled'}`}>
      {renderForm()}
    </div>
  )
}

export default Gearmotor
