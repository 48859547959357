import React from 'react'
import Colors from 'shared/constants/colors'
import { Icon } from 'shared/components'

/**
 * @param {import('antd/lib/table').ExpandIconProps} props
 */
function ExpandIcon(props) {
  const { record, expanded } = props

  return (
    <button
      className="expand-row-icon"
      onClick={(e) => props.onExpand(record, e)}
      style={{ cursor: 'pointer' }}
      title={`Expand for record ${record.id}`}
      aria-expanded={expanded}
      aria-controls={record.id}
    >
      {props.expanded ? (
        <Icon icon="minus" size={16} color={Colors.novaBlue} />
      ) : (
        <Icon icon="plus" size={16} color={Colors.navy} />
      )}
    </button>
  )
}

export default ExpandIcon
