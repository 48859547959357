import * as React from 'react'
import { Icon, TMIcon } from 'shared/components'
import { IConveyorSection, MeasuringUnit } from 'shared/types/swagger'
import BuilderTileSubheadingStatus from './components/BuilderTileSubheadingStatus'
import { Tooltip } from 'antd'

interface Props {
  validated: boolean
  unit: MeasuringUnit
  item: IConveyorSection 
  warnings: string[]
  cautions: string[]
}

export default function BuilderTileSubheading(props: Props) {
  const { validated, item, unit, warnings, cautions } = props
  const sectionMessage = !validated ? 'Section Incomplete | ' : ''

  const icon = (() => {
    if (!validated) return <Icon icon="circle" size={20} colorKey="ash" />
    if (warnings.length > 0) return TMIcon.Warning
    if (cautions.length > 0) return TMIcon.Caution
    return TMIcon.OK
  })()

  const showToolTip = warnings.length > 0 || cautions.length > 0

  return (
    <div className="collapsed-sub">
      <div className="collapsed-sub__status">
        {showToolTip 
          ? (<Tooltip overlayStyle={{ maxWidth: '850px' }} title={<div>
                {cautions.map((caution) => (<div className="caution-sections">{caution}</div>))}
                {warnings.map((warning) => (<div className="warning-sections">{warning}</div>))}
               </div>}>
              <div className="collapsed-sub__status_icon">{icon}</div>
             </Tooltip>) 
          : (<div className="collapsed-sub__status_icon">{icon}</div>)}
        <div className="collapsed-sub__status_text">
          <span className={`section ${!validated ? 'errors' : ''}`}>{sectionMessage}</span>
          <span>
            {' '}
            <BuilderTileSubheadingStatus item={item} unit={unit} />
          </span>
        </div>
      </div>
    </div>
  )
}
