import React from 'react'
import ModalContent from 'features/Modal/components/ModalContent'
import { Button } from 'shared/components'
import { copy } from '../constants'
import { StatusContentProps } from '../types'

const SuccessContent = ({
  close
}: Pick<StatusContentProps, 'close'>) => <>
  <ModalContent.Section title=''>
    <p className='estimator-modal__status-content estimator-modal__status-content--success'>
      {copy.success}
    </p>
  </ModalContent.Section>
  <ModalContent.Footer>
    <Button
      small 
      secondary 
      text='Ok'
      onClick={close}
    />
  </ModalContent.Footer>
</>

export default SuccessContent