import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import {
  getChainSerieInfo,
  initializeAllChainNonStandardRowsMeta,
  setChainPreviewConveyorId,
  updateChain,
  updateChainOptimistic,
  updateNonStandardRowMeta,
  updateChainValidationErrors,
} from './redux/ChainActions'
import { updateCompletedTabs, updateConveyor } from 'features/Conveyor/redux/ConveyorActions'
import { deleteConveyorSection } from 'features/Estimator/components/ConveyorBuilder/redux/ConveyorSectionActions'
import { openConfirmModal, setLoading, setModalContent } from 'shared/redux/ScreenActions'
import './Chain.scss'
import Chain from './Chain'
import { captureSentryError } from 'utils/helpers'

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { conveyorId, match } = props
    const { versionId } = match.params
    const { isactive, islocked } = state.ProjectReducer.versions[versionId]
    return {
      chainList: state.ListReducer.chains,
      chainSerieData: state.ChainReducer[versionId],
      conveyor: state.ConveyorReducer.conveyors[conveyorId],
      metadata: state.ConveyorMetaReducer[versionId][conveyorId],
      permissions: state.UserReducer.permissions,
      roles: state.UserReducer.roles,
      versionId,
      isactive,
      islocked,
      pendingApiResponse: state.ScreenReducer.pendingApiResponse || {},
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  deleteConveyorSection,
  getChainSerieInfo,
  initializeAllChainNonStandardRowsMeta,
  openConfirmModal,
  setChainPreviewConveyorId,
  setLoading,
  setModalContent,
  updateChain,
  updateChainOptimistic,
  updateCompletedTabs,
  updateConveyor,
  updateNonStandardRowMeta,
  updateChainValidationErrors,
}

/**
 * @typedef {ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps} ChainConnectProps
 */

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'chain' })(Chain))
)
