import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Button, PartsTableLayout } from 'shared/components'
import './CostImportScreen.scss'
import CostImportTable from 'features/Admin/components/CostImport/components/CostImportTable'
import CostImportRowStyle from 'features/Admin/components/CostImport/components/CostImportRowStyle'
import { setUnsavedCostUpdate } from 'shared/redux/SettingsActions'
import ListService from 'utils/api/list/ListService'
import { debounce, isEqual } from 'lodash'
import { exportCSV, getPartNumber } from 'features/Admin/components/CostImport/utils'
import { downloadingAlertProps } from './downloadingStates'
import { typedUseSelector } from 'utils/helpers'
import useEditedState from 'features/Admin/components/CostImport/hooks/useEditedState'
import {
  DownloadingStateKey,
  SytelineResponsePlus,
  UpdateCost,
} from 'features/Admin/components/CostImport/types'
import { ISytelineResponse } from 'shared/types/swagger'
import { ScreenStateKey, SCREEN_STATE } from 'shared/types/Screen'
import { useAppDispatch } from 'shared/hooks/app'
import { closeConfirmModal, openConfirmModal } from 'shared/redux/ScreenActions'
import { IConfirmData } from 'shared/redux/ScreenReducer'
import { ALL_COST_IMPORT_ROWS } from 'features/Admin/components/CostImport/constants'

export default function CostImportScreen() {
  const dispatch = useAppDispatch()
  const unsavedCostUpdate = typedUseSelector((state) => state.SettingsReducer.unsavedCostUpdate)

  /* *** STATE *** */
  const [screenState, setScreenState] = useState<ScreenStateKey>('LOADING')
  const [downloadingState, setDownloadingState] = useState<DownloadingStateKey>('DEFAULT')
  const [sytelineCosts, setSytelineCosts] = useState<Record<string, ISytelineResponse>>(null)
  const [dutypercentage, setDutypercentage] = useState<number>(null)
  const [freightpercentage, setFreightpercentage] = useState<number>(null)
  const [imported, setImported] = useState<boolean>(false)
  const [allCalculated, setAllCalculated] = useState<boolean>(false)
  const [editedIds, editedPartNumbers] = useEditedState(sytelineCosts, unsavedCostUpdate)
  /* *** END STATE *** */

  const loading = ['LOADING', 'SAVING'].includes(screenState)
  const disableActions = ['ERROR', 'LOADING', 'SAVING'].includes(screenState)
  const updating = ['EDITED', 'EDITING', 'SAVING'].includes(screenState)
  const tableData: Array<SytelineResponsePlus> = useMemo(() => Object.values(
    unsavedCostUpdate || sytelineCosts || {}
  ).sort((a, b) => getPartNumber(a).localeCompare(getPartNumber(b)))
  , [unsavedCostUpdate, sytelineCosts])
  const areAllRowsLocked = useMemo(() => tableData.every(item => item.excluded), [tableData])
  const downloadingTimeout = useRef(null)

  useEffect(() => {
    (async () => {
      try {
        await getDutyAndFreight()
        await getSytelineCosts()
      } catch (error) {
        console.log(error)
        setScreenState('ERROR')
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function getSytelineCosts() {
    const response = await ListService.getSytelineCosts()
    setSytelineCosts(response)
    setScreenState(unsavedCostUpdate ? 'EDITING' : 'DEFAULT')
  }

  async function getDutyAndFreight() {
    const response = await ListService.getDutyAndFreight()
    setDutypercentage(response.dutypercentage / 100)
    setFreightpercentage(response.freightpercentage / 100)
  }

  const saveChanges = useCallback(async () => {
    if (!unsavedCostUpdate) return
    try {
      setScreenState('SAVING')
      const payload = Object.values(unsavedCostUpdate || {}).map((record) => {
        const { calcdatetime, ...rest } = record
        return rest
      })
      const response = await ListService.updateSytelineCosts(payload)
      dispatch(setUnsavedCostUpdate(null))
      setSytelineCosts(response)
      setScreenState('EDITED')
      setAllCalculated(false)
    } catch (error) {
      setScreenState('ERROR')
      console.log(error)
    }
  }, [dispatch, unsavedCostUpdate])

  function cancelEdit() {
    dispatch(setUnsavedCostUpdate(null))
    setScreenState('DEFAULT')
    setAllCalculated(false)
    setImported(false)
  }

  async function importCosts() {
    setScreenState('LOADING')
    try {
      const data = await ListService.getSytelineCostUpdates()

      const oldSCValues: ISytelineResponse[] = Object.values(sytelineCosts)
      const importedValues: ISytelineResponse[] = Object.values(data)
      const dataWithTempValues: Record<string, SytelineResponsePlus> = importedValues.reduce(
        (acc, newSC) => {
          const oldSC = oldSCValues.find((oldSC) => getPartNumber(oldSC) === getPartNumber(newSC))
          acc[newSC.id] = {
            ...newSC,
            currenttotalcost: oldSC.totalcost,
            currentfreightcost: oldSC.freightcost,
            currentdutycost: oldSC.dutycost,
          }
          return acc
        },
        {}
      )

      dispatch(setUnsavedCostUpdate(dataWithTempValues))

      const hasEdit = importedValues.some((newSC) => {
        const oldSC = oldSCValues.find((oldSC) => getPartNumber(oldSC) === getPartNumber(newSC))
        return !isEqual(newSC, oldSC)
      })

      setScreenState(hasEdit ? 'EDITING' : 'DEFAULT')
      setImported(true)
    } catch (error) {
      console.log(error)
      setScreenState('ERROR')
    }
  }

  function editCosts() {
    const oldSCValues = Object.values(sytelineCosts)
    const dataWithTempValues: Record<string, SytelineResponsePlus> = oldSCValues.reduce(
      (acc, oldSC) => {
        acc[oldSC.id] = {
          ...oldSC,
          currenttotalcost: oldSC.totalcost,
          currentfreightcost: oldSC.freightcost,
          currentdutycost: oldSC.dutycost,
        }
        return acc
      },
      {}
    )

    dispatch(setUnsavedCostUpdate(dataWithTempValues))
    setScreenState('EDITING')
  }

  const openSaveConfirmModal = useCallback(() => {
    dispatch(openConfirmModal<IConfirmData>({
      headerText: 'Confirm',
      bodyText: 'Are you sure you want to save?',
      onConfirm: saveChanges,
      onCancel: () => dispatch(closeConfirmModal()),
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
    }))
  }, [dispatch, saveChanges])

  function renderEditOrSave() {
    if (SCREEN_STATE.EDITING === screenState) {
      return (
        <>
          <Button
            small
            antIcon="stop"
            secondary
            text="Cancel"
            onClick={cancelEdit}
            disabled={disableActions}
          />
          <Button
            small
            antIcon="save"
            text="Save Changes"
            onClick={openSaveConfirmModal}
            disabled={disableActions || !unsavedCostUpdate}
          />
        </>
      )
    }
    return (
      <>
        <Button
          small
          antIcon="cloud-download"
          text="Import Costs"
          disabled={disableActions}
          onClick={importCosts}
        />
        <Button
          small
          text="Edit Costs"
          antIcon="edit"
          onClick={editCosts}
          disabled={disableActions}
        />
      </>
    )
  }

  const updateCost = useCallback(
    debounce<UpdateCost>((id, value, key) => {
      const numValue = Number(value) || 0
      const costs = unsavedCostUpdate || sytelineCosts

      if (id === ALL_COST_IMPORT_ROWS) {
        const newCosts = Object.keys(costs).map(costKey => ({
          ...costs[costKey],
          [key]: numValue
        }))
        dispatch(
          setUnsavedCostUpdate(newCosts)
        )
      } else {
        dispatch(
          setUnsavedCostUpdate({
            ...costs,
            [id]: {
              ...costs[id],
              [key]: numValue,
            },
          })
        )
      }
    }, 200),
    [unsavedCostUpdate, sytelineCosts]
  )

  function exportCosts() {
    exportCSV(tableData, editedPartNumbers, screenState === 'EDITING')
  }

  const calculateFreightAndDuties = useCallback(
    (record: ISytelineResponse) => {
      const { id, updatedcost } = record
      const costs = unsavedCostUpdate || sytelineCosts
      const newDutyCost = dutypercentage * updatedcost
      const newFreightCost = freightpercentage * updatedcost

      dispatch(
        setUnsavedCostUpdate({
          ...costs,
          [id]: {
            ...costs[id],
            dutycost: Number(newDutyCost.toFixed(4)),
            freightcost: Number(newFreightCost.toFixed(4)),
            calcdatetime: new Date().getTime(),
          },
        })
      )
    },
    [dispatch, dutypercentage, freightpercentage, sytelineCosts, unsavedCostUpdate]
  )

  function calculateAllFreightAndDuties() {
    /** @type {Array<SytelineResponsePlus>} */
    const updatedValues = Object.values(unsavedCostUpdate)
    const calculated = updatedValues.reduce(
      (acc, cur) => {
        const updatedSC = { ...cur }
        if (editedIds.includes(updatedSC.id)) {
          if (updatedSC.dutycost || updatedSC.freightcost) {
            updatedSC.calcdatetime = new Date().getTime()
          }
          if (updatedSC.dutycost) {
            updatedSC.dutycost = Number((dutypercentage * updatedSC.updatedcost).toFixed(4))
          }
          if (updatedSC.freightcost) {
            updatedSC.freightcost = Number((freightpercentage * updatedSC.freightcost).toFixed(4))
          }
        }
        acc[updatedSC.id] = updatedSC
        return acc
      },
      /**@type {Object<string, SytelineResponsePlus>}*/ {}
    )
    dispatch(setUnsavedCostUpdate(calculated))
    setAllCalculated(true)
  }

  async function downloadLog() {
    setDownloadingState('LOADING')
    if (downloadingTimeout.current) {
      console.log('clearing')
      clearTimeout(downloadingTimeout.current)
      downloadingTimeout.current = null
    }

    try {
      const [csvFileData, filename] = await ListService.getCurrentSytelineErrorLog()
      const downloadUrl = window.URL.createObjectURL(new Blob([csvFileData], { type: 'text/csv' }))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', filename)
      link.click()
      setDownloadingState('SUCCESS')
    } catch (error) {
      setDownloadingState('ERROR')
    }

    // Reset the downloading state after 10 seconds
    downloadingTimeout.current = setTimeout(() => {
      setDownloadingState('DEFAULT')
    }, 10000)
  }

  const importDescription = (() => {
    const someEdited = editedIds.length !== 0
    const message = `Import successful with ${
      someEdited ? 'cost updates noted below' : 'no cost updates at this time'
    }.`
    const recalcMessage = allCalculated ? (
      'Freight and Duties recalculated.'
    ) : (
      <Button
        text="Recalculate all Freight & Duties"
        tertiary
        onClick={calculateAllFreightAndDuties}
      />
    )

    return (
      <span>
        {message} {someEdited ? recalcMessage : null}
      </span>
    )
  })()

  const top = (
    <>
      {renderEditOrSave()}
      <Button
        small
        text="Export Costs"
        disabled={disableActions}
        onClick={exportCosts}
        secondary
        antIcon="file-excel"
      />
      <Button
        small
        secondary
        icon="download"
        text="Download Log"
        disabled={disableActions || downloadingState === 'LOADING'}
        onClick={downloadLog}
      />
    </>
  )

  const alertProps = (() => {
    if (imported) {
      return {
        message: false,
        type: 'success',
        showIcon: true,
        description: importDescription,
        closable: true,
        onClose: () => {
          if (editedIds.length !== 0) {
            setTimeout(() => window.dispatchEvent(new Event('resize')), 250)
          } else {
            setScreenState('DEFAULT')
          }
        },
      }
    } else if (downloadingState !== 'DEFAULT') {
      return downloadingAlertProps[downloadingState]
    } else if (screenState === 'EDITED') {
      return {
        message: false,
        type: 'success',
        showIcon: true,
        description: 'Costs Successfully Updated',
        closable: true,
        onClose: () => setScreenState('DEFAULT'),
      }
    } else if (screenState === 'ERROR') {
      return {
        message: 'Error Fetching or Updating Syteline Costs',
        type: 'error',
        showIcon: true,
        description: 'Try refreshing the page or contacting support',
      }
    } else {
      return {}
    }
  })()

  return (
    <PartsTableLayout
      id="cost-import"
      className="cost-import"
      title="Cost Import"
      top={top}
      showAlert={
        imported || ['EDITED', 'ERROR'].includes(screenState) || downloadingState !== 'DEFAULT'
      }
      alertProps={alertProps}
    >
      {screenState !== 'ERROR' ? (
        <>
          <CostImportRowStyle
            updating={updating}
            editedPartNumbers={editedPartNumbers}
            excludedPartNumbers={tableData.filter((r) => r.excluded).map(getPartNumber)}
          />
          <CostImportTable
            screenState={screenState}
            updateCost={updateCost}
            sytelineCosts={tableData}
            areAllRowsLocked={areAllRowsLocked}
            loading={loading}
            calculateFreightAndDuties={calculateFreightAndDuties}
            editedIds={editedIds}
          />
        </>
      ) : null}
    </PartsTableLayout>
  )
}
