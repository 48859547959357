import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { PURGE } from 'redux-persist'

/**
 * @typedef {{open: boolean}} MatAccMeta
 *
 * @interface
 * @typedef {Object} MatMeta
 * @property {MatAccMeta} sideframes
 * @property {MatAccMeta} wearstrips
 * @property {MatAccMeta} [washdown]
 *
 * @interface
 * @typedef {Object} AccMeta
 * @property {MatAccMeta} driptray
 * @property {MatAccMeta} guiderails
 * @property {MatAccMeta} underguard
 * @property {MatAccMeta} sidewalls
 * @property {MatAccMeta} fixedendstop
 * @property {MatAccMeta} floorsupports
 * @property {MatAccMeta} ceilingsupports
 *
 * @typedef {Object} SectionMetadata
 * @property {number|string} id
 * @property {boolean} collapsed
 * @property {boolean} sectionFirstValidation
 * @property {boolean} validated
 * @property {{materials: MatMeta, accessories: AccMeta}} materialsAccessoriesMetadata
 *
 * @typedef {Object<string, SectionMetadata>} SectionsMetadata
 *
 * @typedef {Object} ConveyorBuilderMeta
 * @property {string} selectedSectionId
 * @property {boolean} rotateCanvas
 * @property {SectionsMetadata} sectionsMetadata
 *
 * @typedef {Object} DesiredUnitOptionsOption
 * @property {number} id
 * @property {number} listorder
 * @property {string} label
 *
 * @typedef {Object} DesiredUnitOption
 * @property {number} id
 * @property {Object<number, DesiredUnitOptionsOption>} options
 *
 * @typedef {Object} EstimatorMetaConveyor
 * @property {number} currentTabIndex
 * @property {ConveyorBuilderMeta} conveyorbuilder
 * @property {{materials: MatMeta, accessories: AccMeta}} materialsAccessoriesMetadata
 * @property {{desiredUnitOptions: Object<number, DesiredUnitOption>}} gearmotor
 *
 * @typedef {Object<number, EstimatorMetaConveyor>} EstimatorMetaState
 */

/** @type {EstimatorMetaState} */
const INITIAL_STATE = {
  // conveyorId: {
  //   currentTabIndex: 0,
  //   conveyorbuilder: {
  //     rotateCanvas: false,
  //     selectedSectionId: null,
  //     sectionsMetadata: {
  //       sectionId: {
  //         collapsed: false,
  //         sectionFirstValidation: false,
  //         validated: false,
  //         materialsAccessoriesMetadata: {
  //           materials : {
  //               sideframe: null,
  //               wearstrips: null
  //          },
  //           accessories: {
  //             guiderails: null,
  //             underguard: null,
  //             sidewall: null,
  //             fixedendstop: null,
  //             floorsupports: null,
  //             ceilingsupports: null
  //           },
  //         }
  //       }
  //     }
  //   }
  // },
  // defaultMaterialsAccessoriesMetadata = {
  //   materials: {
  //     washdown: null,
  //     sideframe: null,
  //     wearstrips: null
  //   },
  //   accessories: {
  //     guiderails: null,
  //     underguard: null,
  //     sidewall: null,
  //     fixedendstop: null,
  //     floorsupports: null,
  //     ceilingsupports: null
  //   },
  //   gearmotor: {
  //     desiredUnitOptions: {}
  //   }
  // },
}

const INITIAL_MAT_ACC_META_STATE = {
  materials: {
    washdown: { open: false },
    sideframes: { open: false },
    wearstrips: { open: false },
  },
  accessories: {
    driptray: { open: false },
    guiderails: { open: false },
    underguard: { open: false },
    sidewalls: { open: false },
    fixedendstop: { open: false },
    floorsupports: { open: false },
    ceilingsupports: { open: false },
  },
}

export default createReducer(INITIAL_STATE, {
  //   ___ ___ _  _ ___ ___    _   _
  //  / __| __| \| | __| _ \  /_\ | |
  // | (_ | _|| .` | _||   / / _ \| |__
  //  \___|___|_|\_|___|_|_\/_/ \_\____|

  DELETE_CONVEYOR_METADATA: (state, action) => {
    const { conveyorId } = action.payload
    delete state[conveyorId]
  },
  REMOVE_NOT_CURRENT_COVEYOR_METADATA: (state, action) => {
    const currentConveyorIds = action.payload
    const storedIds = Object.keys(state)
    for (let i = 0; i < storedIds.length; i++) {
      const conveyorId = storedIds[i]
      if (!currentConveyorIds.includes(conveyorId)) {
        delete state[conveyorId]
      }
    }
  },
  SELECT_ESTIMATOR_TAB: (state, action) => {
    const { conveyorId, currentTabIndex } = action.payload
    state[conveyorId].currentTabIndex = currentTabIndex
  },

  //   ___ ___  _  ___   _______   _____  ___   ___ ___ ___ _____ ___ ___  _  _ ___
  //  / __/ _ \| \| \ \ / / __\ \ / / _ \| _ \ / __| __/ __|_   _|_ _/ _ \| \| / __|
  // | (_| (_) | .` |\ V /| _| \ V / (_) |   / \__ \ _| (__  | |  | | (_) | .` \__ \
  //  \___\___/|_|\_| \_/ |___| |_| \___/|_|_\ |___/___\___| |_| |___\___/|_|\_|___/

  ADD_ONE_SECTION_METADATA: (state, action) => {
    const { conveyorId, sectionId, existingMetadata } = action.payload
    if (existingMetadata) {
      state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId] = existingMetadata
    } else {
      state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId] = {
        collapsed: false,
        sectionFirstValidation: false,
        validated: false,
      }
    }
  },
  COLLAPSE_ALL_SECTIONS: (state, action) => {
    const conveyorId = action.payload
    _.forEach(state[conveyorId].conveyorbuilder.sectionsMetadata, (d) => {
      d.collapsed = true
    })
  },
  COLLAPSE_SECTION: (state, action) => {
    const { collapsed, collapseOthers, conveyorId, sectionId } = action.payload
    if (collapseOthers) {
      _.forEach(state[conveyorId].conveyorbuilder.sectionsMetadata, (d) => {
        d.collapsed = true
      })
    }

    state[conveyorId].conveyorbuilder.selectedSectionId = collapsed ? null : sectionId
    state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId].collapsed = collapsed
    state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId].sectionFirstValidation = true
  },
  DELETE_SECTION_METADATA: (state, action) => {
    const { conveyorId, sectionId } = action.payload
    delete state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
  },
  INITIALIZE_CONVEYOR_SECTIONS_METADATA: (state, action) => {
    const { conveyorId, ...metadata } = action.payload
    if (!state.hasOwnProperty(conveyorId)) {
      state[conveyorId] = {
        conveyorbuilder: metadata,
        gearmotor: {},
      }
    } else {
      state[conveyorId].conveyorbuilder.sectionsMetadata = {
        ...metadata.sectionsMetadata,
        ...state[conveyorId].conveyorbuilder.sectionsMetadata,
      }
    }
  },
  INITIALIZE_CONVEYOR_SECTIONS_METADATAS: (state, action) => {
    const { conveyors } = action.payload
    for (let i = 0; i < conveyors.length; i++) {
      const { conveyorId, ...metadata } = conveyors[i]
      if (!state.hasOwnProperty(conveyorId)) {
        state[conveyorId] = {
          conveyorbuilder: metadata,
          gearmotor: {},
        }
      } else {
        state[conveyorId].conveyorbuilder.sectionsMetadata = {
          ...metadata.sectionsMetadata,
          ...state[conveyorId].conveyorbuilder.sectionsMetadata,
        }
      }
    }
  },
  SET_CANVAS_ROTATION: (state, action) => {
    const { conveyorId, rotateCanvas } = action.payload
    state[conveyorId].conveyorbuilder.rotateCanvas = rotateCanvas
  },
  SELECT_SECTION: (state, action) => {
    const { conveyorId, selectedSectionId } = action.payload
    if (!selectedSectionId) {
      state[conveyorId].conveyorbuilder.selectedSectionId = null
    } else if (state[conveyorId].conveyorbuilder.selectedSectionId === selectedSectionId) {
      state[conveyorId].conveyorbuilder.sectionsMetadata[selectedSectionId].collapsed = true
      state[conveyorId].conveyorbuilder.selectedSectionId = null
    } else {
      state[conveyorId].conveyorbuilder.selectedSectionId = selectedSectionId
      _.forEach(state[conveyorId].conveyorbuilder.sectionsMetadata, (d) => {
        d.collapsed = true
      })
      state[conveyorId].conveyorbuilder.sectionsMetadata[selectedSectionId].collapsed = false
    }
  },
  VALIDATE_SECTION: (state, action) => {
    const { validated, conveyorId, sectionId } = action.payload
    state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId].validated = validated
  },

  //  ___  ___ ___ _  _   _ _  _____   __  __   _ _____     _   ___ ___
  // |   \| __| __/_\| | | | ||_   _| |  \/  | /_\_   _|   /_\ / __/ __|
  // | |) | _|| _/ _ \ |_| | |__| |   | |\/| |/ _ \| |    / _ \ (_| (__
  // |___/|___|_/_/ \_\___/|____|_|   |_|  |_/_/ \_\_|   /_/ \_\___\___|

  INITIALIZE_DEFAULT_MATERIAL_ACCESSORIES_METADATA: (state, action) => {
    const { conveyorId } = action.payload
    if (state[conveyorId] && !state[conveyorId].materialsAccessoriesMetadata) {
      state[conveyorId].materialsAccessoriesMetadata = INITIAL_MAT_ACC_META_STATE
    }
  },
  INITIALIZE_DEFAULT_MATERIAL_ACCESSORIES_METADATAS: (state, action) => {
    const { conveyorIds } = action.payload
    for (let i = 0; i < conveyorIds.length; i++) {
      const conveyorId = conveyorIds[i]
      if (state[conveyorId] && !state[conveyorId].materialsAccessoriesMetadata) {
        state[conveyorId].materialsAccessoriesMetadata = INITIAL_MAT_ACC_META_STATE
      }
    }
  },
  UPDATE_DEFAULT_MATERIAL_ACCESSORIES_META_DATA: (state, action) => {
    const { conveyorId, type, name, updatedFields } = action.payload
    const collection = state[conveyorId].materialsAccessoriesMetadata[type]
    collection[name] = {
      ...collection[name],
      ...updatedFields,
    }
  },

  //   _____   _____ ___ ___ ___ ___  ___ ___   __  __   _ _____     _   ___ ___
  //  / _ \ \ / / __| _ \ _ \_ _|   \| __/ __| |  \/  | /_\_   _|   /_\ / __/ __|
  // | (_) \ V /| _||   /   /| || |) | _|\__ \ | |\/| |/ _ \| |    / _ \ (_| (__
  //  \___/ \_/ |___|_|_\_|_\___|___/|___|___/ |_|  |_/_/ \_\_|   /_/ \_\___\___|

  INITIALIZE_SECTION_OVERRIDE_MATERIAL_ACCESSORIES_METADATA: (state, action) => {
    const { conveyorId, sectionId } = action.payload
    const defaultState = { open: false }
    if (
      state[conveyorId] &&
      !state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId].materialsAccessoriesMetadata
    ) {
      state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId].materialsAccessoriesMetadata = {
        materials: {
          washdown: defaultState,
          sideframes: defaultState,
          wearstrips: defaultState,
        },
        accessories: {
          driptray: defaultState,
          guiderails: defaultState,
          underguard: defaultState,
          sidewalls: defaultState,
          fixedendstop: defaultState,
          floorsupports: defaultState,
          ceilingsupports: defaultState,
        },
      }
    }
  },
  UPDATE_SECTION_OVERRIDE_MATERIAL_ACCESSORIES_META_DATA: (state, action) => {
    const { conveyorId, sectionId, type, name, updatedFields } = action.payload
    const section = state[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const collection = section.materialsAccessoriesMetadata[type]
    collection[name] = {
      ...collection[name],
      ...updatedFields,
    }
  },

  //   ___ ___   _   ___ __  __  ___ _____ ___  ___
  //  / __| __| /_\ | _ \  \/  |/ _ \_   _/ _ \| _ \
  // | (_ | _| / _ \|   / |\/| | (_) || || (_) |   /
  //  \___|___/_/ \_\_|_\_|  |_|\___/ |_| \___/|_|_\

  UPDATE_GEARMOTOR_DESIRED_UNIT_OPTIONS: (state, action) => {
    const { conveyorId, desiredUnitOptions } = action.payload
    state[conveyorId].gearmotor.desiredUnitOptions = desiredUnitOptions
  },
  [PURGE]: () => INITIAL_STATE,
})
