import React from 'react'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import { connect } from 'react-redux'
import { collapseAllConveyors } from 'features/Conveyor/redux/ConveyorActions'

/**
 * @typedef {Object} Props
 * @prop {*} collapseAllConveyors
 * @prop {number} versionId
 */

/**
 * @param {Props} props
 * */
function CollapseConveyorsButton(props) {
  const handleClick = () => {
    props.collapseAllConveyors(props.versionId)
  }

  return (
    <button className="project-summary__firstrow__collapse-conveyors" onClick={handleClick}>
      <Icon icon="minusOutline" size={16} color={Colors.novaBlue} />
      Collapse All Conveyors
    </button>
  )
}

/**
 * Connect component which updates the open state in redux for all components
 * with the given version id
 *
 * @param {Props} props
 */
const connectedCollapseConveyorsButton = connect(null, { collapseAllConveyors })(
  CollapseConveyorsButton
)

export default connectedCollapseConveyorsButton
