import { Table } from 'antd'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import { usersByRoleConfig, userTypes } from './usersByRoleConfig'
import './UsersByRole.scss'
import { captureSentryError } from 'utils/helpers'

class UsersByRole extends Component {
  renderUsersByRoleTables = () => {
    const { department, filter, projectByStatusDetailData } = this.props

    let visibleUserTypes = userTypes

    if (department) {
      visibleUserTypes = userTypes.filter((user) => user.key === department)
    }

    return visibleUserTypes.map((userType, i) => {
      let userTypeData = projectByStatusDetailData[userType.key]
      if (userTypeData && Object.keys(filter).length) {
        if (filter.type === 'Employee') {
          userTypeData = userTypeData.filter((u) => u.employeeid === filter.value)
        } else if (filter.type === 'Customer') {
          userTypeData = userTypeData.filter((u) => u.customerid === filter.value)
        }
      }
      return (
        <div key={i} className="users-by-role__section">
          <div className="users-by-role__section__top">
            <div className="users-by-role__section__top__left">
              <div className="title">{userType.title}</div>
            </div>
            <div className="users-by-role__section__top__right">
              <div className="badge">
                <Icon icon={userType.icon} size={36} color={Colors.white} />
              </div>
            </div>
          </div>
          <div className="users-by-role__section__bottom">
            <Table
              rowKey={(r, i) => i}
              className="users-by-role__section__bottom__table"
              columns={usersByRoleConfig}
              dataSource={userTypeData}
              pagination={{
                pageSize: 7,
                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} projects`,
                showLessItems: true,
              }}
            />
          </div>
        </div>
      )
    })
  }

  render() {
    return <div id="users-by-role">{this.renderUsersByRoleTables()}</div>
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const { department, filter, projectByStatusDetailData } = state.AdminStatsReducer
    return {
      department,
      filter: filter || {},
      projectByStatusDetailData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default connect(mapStateToProps)(UsersByRole)
