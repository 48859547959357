import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { formatIdAsKey } from 'utils/helpers'
import { PURGE } from 'redux-persist'
import * as ProjectActions from './ProjectActions'
import { IProject } from 'shared/types/swagger'
import { IVersionWithAutorizedActions } from 'features/Version/shared/types'
import { getProjects } from './ProjectGetProjectsOperations'
import { IOrganizedSettingsList } from 'utils/api/list/types'

export interface IProjectState {
  selectedProject?: IProject;
  versions: Record<string, IVersionWithAutorizedActions>;
  filterTerm: string;
  currentFilterStatus: string;
  currentFilterPage: number;
  totalFilterPages: number;
  projectQueueResults: Record<string, IProject>;
  searchResults: Record<string, IProject>;
  searchTerm: string;
  currentSearchPage: number;
  totalSearchPages: number;
  tab: 'queue' | 'search';
  searchTimestamp: number;
  searchError: boolean;
  searchPending: boolean;
  searchErrorReason: string;
  currentexternalprojecttype?: string;
  currentlistsettings: IOrganizedSettingsList;
  regularprojectsettings: IOrganizedSettingsList;
  laprojectsettings: IOrganizedSettingsList;
}

const INITIAL_STATE: IProjectState = {
  selectedProject: null,
  versions: {},
  filterTerm: '',
  currentFilterPage: 1,
  currentFilterStatus:'',
  totalFilterPages: 1,
  projectQueueResults: {}, // all projects in user's Queue, endpoint Queue?pagesize=5
  searchResults: {}, // only search results, Endpoint /Projects/WithOneVersion
  searchTerm: '',
  currentSearchPage: 1,
  totalSearchPages: 1,
  tab: 'queue',
  searchTimestamp: null,
  searchError: false,
  searchErrorReason: '',
  searchPending: false,
  currentexternalprojecttype:null,
  currentlistsettings:null,
  regularprojectsettings: {
    accessories: {},
    gearmotor: {},
    materials: {},
    product: {},
    sections: {},
    transfers: {},
    yesorno: {},
  }, 
  laprojectsettings: {
    accessories: {},
    gearmotor: {},
    materials: {},
    product: {},
    sections: {},
    transfers: {},
    yesorno: {},
  },
}

const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(ProjectActions.searchProjectsPurge, (state, action) => {
      state.searchTerm = ''
      state.searchResults = {}
      state.currentSearchPage = 1
    })
    .addCase(
      ProjectActions.searchProjectsSuccess,
      (state, action) => {
        const { searchResults, searchTerm, currentSearchPage, totalSearchPages } = action.payload
        state.searchResults = searchResults
        state.searchTerm = searchTerm
        state.currentSearchPage = currentSearchPage
        state.totalSearchPages = totalSearchPages
      }
    )
    .addCase(ProjectActions.selectProjectFromQueueOrSearch, (state, action) => {
      state.selectedProject = action.payload == null 
                        ? null 
                        : state.projectQueueResults && action.payload in state.projectQueueResults 
                          ? state.projectQueueResults[action.payload] 
                          : state.searchResults[action.payload]
      state.currentexternalprojecttype = state.selectedProject?.externalprojecttype 
      state.currentlistsettings = state.currentexternalprojecttype == 'la' ? state.laprojectsettings : state.regularprojectsettings 
    })
    .addCase(ProjectActions.setProjectManually, (state, action) => {
      state.selectedProject = action.payload
      state.currentexternalprojecttype = state.selectedProject?.externalprojecttype 
      state.currentlistsettings = state.currentexternalprojecttype == 'la' ? state.laprojectsettings : state.regularprojectsettings 
    })
    .addCase(ProjectActions.selectProjectTab, (state, action) => {
      state.tab = action.payload
    })
    .addCase(
      ProjectActions.updateMasterVersion,
      (state, action: { payload: { projectId: number; versionId: number } }) => {
        const { projectId, versionId } = action.payload
        const newMasterVersion = _.cloneDeep(state.versions[versionId])
        if (newMasterVersion.authorizedactions) {
          delete newMasterVersion.authorizedactions
        }
        if (state.selectedProject?.id == projectId)
        {
          state.selectedProject.masterversion = newMasterVersion
        }    
        if (state.projectQueueResults && state.projectQueueResults[projectId]) {
          state.projectQueueResults[projectId].masterversion = newMasterVersion
        }
        if (state.searchResults[projectId]) {
          state.searchResults[projectId].masterversion = newMasterVersion
        }
        const updatedVersions = Object.values(state.versions).map((v) => {
          v.ismaster = v.id === versionId
          return v
        })
        state.versions = formatIdAsKey(updatedVersions) || {}
      }
    )
    .addCase(
      ProjectActions.updateProjects,
      (state, action: { payload: Partial<IProjectState> }) => {
        const { projectQueueResults, searchResults } = action.payload
        if (projectQueueResults) {
          state.projectQueueResults = projectQueueResults
        }
        if (searchResults) {
          state.searchResults = searchResults
        }
      }
    )
    .addCase(
      ProjectActions.updateProjectReducerVersion,
      (state, action: { payload: IVersionWithAutorizedActions }) => {
        if (!state.versions) state.versions = {}
        state.versions[action.payload.id] = action.payload
      }
    )
    .addCase(ProjectActions.updateVersions, (state, action) => {
      state.versions = action.payload || {}
    })
    .addCase(getProjects.pending, (state, action) => {
      state.searchPending = true
    })
    .addCase(getProjects.fulfilled, (state, action) => {
      const { projectQueueResults, filterTerm,currentFilterPage, totalFilterPages, currentFilterStatus } = action.payload
      if (projectQueueResults) {
        state.projectQueueResults = projectQueueResults
        state.searchTimestamp = new Date().getTime()
        state.searchPending = false
        state.filterTerm = filterTerm
        state.currentFilterPage = currentFilterPage
        state.totalFilterPages = totalFilterPages
        state.currentFilterStatus = currentFilterStatus
      }
    })
    .addCase(getProjects.rejected, (state, action) => {
      state.searchError = true
      state.searchErrorReason = action?.error?.message
      state.searchPending = false
    })
    .addCase(ProjectActions.updateProject, (state, action) => {
      if (state.selectedProject?.id == action.payload.id)
      {
        state.selectedProject = action.payload
        state.currentexternalprojecttype = action.payload.externalprojecttype
        state.currentlistsettings = state.currentexternalprojecttype == 'la' ? state.laprojectsettings : state.regularprojectsettings  
      }
      if (state.projectQueueResults && state.projectQueueResults[action.payload.id]) {
        state.projectQueueResults[action.payload.id] = action.payload
      }
      if (state.searchResults[action.payload.id]) {
        state.searchResults[action.payload.id] = action.payload
      }
    })
    .addCase(ProjectActions.saveListsSettings, (state, action) => {
      state.regularprojectsettings = action.payload.regularProjectSettings
      state.laprojectsettings = action.payload.laProjectSettings
      state.currentlistsettings = state.currentexternalprojecttype == 'la' ? state.laprojectsettings : state.regularprojectsettings 
    })
    .addCase(PURGE, (state) => {
      state = INITIAL_STATE
    })
})

export default reducer
