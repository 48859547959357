import store from 'utils/../reduxStore'
import { batch } from 'react-redux'
import { createAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import ADP from 'awesome-debounce-promise'
import ConveyorSectionService from 'utils/api/conveyor/ConveyorSectionService'
import {
  addConveyorSectionMetadata,
  addOneSectionMetadata,
  deleteSectionMetadata,
  selectSection,
} from './ConveyorBuilderActions'
import { saveConveyorToConveyors } from '../../../../Conveyor/redux/ConveyorActions'
import {
  fetchDefaultMaterialsForConveyor,
  fetchDefaultAccessoriesForConveyor,
} from 'features/Estimator/redux/EstimatorActions'
import { formatNewConveyorSectionData, formatIdAsKey } from 'utils/helpers'
import { setLoading } from 'shared/redux/ScreenActions'
import {
  addAccessoryWithSidesToConveyorSection,
  addMaterialOrAccessoryToConveyorSection,
  fetchMaterialsForConveyorSection,
  fetchAccessoriesForConveyorSection,
} from 'features/Estimator/redux/SectionOverrideActions'

export const saveConveyorSectionData = createAction('SAVE_CONVEYOR_SECTION_DATA')
export const saveAllConveyorSectionsData = createAction('SAVE_ALL_CONVEYOR_SECTIONS_DATA')
export const deleteConveyorTransfersData = createAction('DELETE_CONVEYOR_TRANSFERS_DATA')
export const saveConveyorTransfersData = createAction('SAVE_CONVEYOR_TRANSFERS_DATA')
export const updateConveyorInfeedDischarge = createAction('UPDATE_CONVEYOR_INFEED_DISCHARGE')

//   _____                                         _____           _   _
//  / ____|                                       / ____|         | | (_)
// | |     ___  _ ____   _____ _   _  ___  _ __  | (___   ___  ___| |_ _  ___  _ __  ___
// | |    / _ \| '_ \ \ / / _ \ | | |/ _ \| '__|  \___ \ / _ \/ __| __| |/ _ \| '_ \/ __|
// | |___| (_) | | | \ V /  __/ |_| | (_) | |     ____) |  __/ (__| |_| | (_) | | | \__ \
//  \_____\___/|_| |_|\_/ \___|\__, |\___/|_|    |_____/ \___|\___|\__|_|\___/|_| |_|___/
//                              __/ |
//                             |___/
export const createConveyorSection = (conveyorId, sectionType, section) => async (dispatch) => {
  const lastSectionNumber = Object.keys(
    store.getState().ConveyorReducer.conveyors[conveyorId].conveyorsections
  ).length
  const payload = {
    ...section,
    sectionnumber: lastSectionNumber + 1,
    type: sectionType,
  }

  dispatch(
    setLoading({
      loading: true,
      loadingMessage: section ? 'Copying section...' : 'Adding new section...',
    })
  )

  return new Promise((resolve, reject) => {
    ConveyorSectionService.createConveyorSection(conveyorId, payload)
      .then(async (data) => {
        const { conveyortorquemasterupdate, ...newSectionData } = data
        const conveyor = await formatNewConveyorSectionData(conveyorId, data)
        conveyor.conveyorsections[newSectionData.id] = {
          ...conveyor.conveyorsections[newSectionData.id],
          ...newSectionData,
        }
        if (sectionType === 'HorizontalCurve') {
          conveyor.conveyorsections[newSectionData.id].incline = 0
        }
        batch(() => {
          dispatch(addConveyorSectionMetadata([conveyor]))
          dispatch(saveConveyorToConveyors(conveyor))
        })
        dispatch(
          selectSection({
            conveyorId,
            selectedSectionId: data.id,
          })
        )
        dispatch(setLoading(false))
      })
      .catch((e) => {
        dispatch(setLoading(false))
        debugger
      })
  })
}

export const deleteConveyorSection = (conveyorId, sectionId) => (dispatch) => {
  const conveyorWithSection = _.cloneDeep(store.getState().ConveyorReducer.conveyors[conveyorId])
  const conveyorsectionsWithSection = conveyorWithSection.conveyorsections

  const sectionToBeRemoved = conveyorsectionsWithSection[sectionId]

  const removedAndReorderedConveyorSections = _.sortBy(
    conveyorsectionsWithSection,
    (s) => s.sectionnumber
  )
  removedAndReorderedConveyorSections.splice(sectionToBeRemoved.sectionnumber - 1, 1)
  removedAndReorderedConveyorSections.forEach((s, i) => {
    s.sectionnumber = i + 1
    return s
  })

  const conveyorWithoutSection = {
    ...conveyorWithSection,
    conveyorsections: formatIdAsKey(removedAndReorderedConveyorSections),
  }

  const sectionMetadata = {
    ...store.getState().EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata,
  }

  dispatch(saveConveyorToConveyors(conveyorWithoutSection))
  dispatch(
    deleteSectionMetadata({
      conveyorId,
      sectionId,
    })
  )

  return new Promise(async (resolve, reject) => {
    await ConveyorSectionService.deleteConveyorSection(conveyorId, sectionId)
      .then(async (data) => {
        const conveyor = await formatNewConveyorSectionData(conveyorId, data)
        dispatch(saveConveyorToConveyors(conveyor))
        dispatch(fetchDefaultMaterialsForConveyor(conveyorId))
        dispatch(fetchDefaultAccessoriesForConveyor(conveyorId))
        resolve(true)
      })
      .catch((e) => {
        dispatch(
          addOneSectionMetadata({
            conveyorId,
            sectionId,
            existingMetadata: sectionMetadata,
          })
        )
        dispatch(saveConveyorToConveyors(conveyorWithSection))
        reject(false)
        debugger
      })
  })
}

export const debouncedUpdateConveyorSection = ADP(
  (dispatch, conveyorId, sectionId, payload, oldSectionData) => {
    return new Promise(async (resolve, reject) => {
      await ConveyorSectionService.updateConveyorSection(conveyorId, sectionId, payload)
        .then(async (data) => {
          const conveyor = await formatNewConveyorSectionData(conveyorId, data)
          dispatch(saveConveyorToConveyors(conveyor))
          resolve(data)
        })
        .catch((e) => {
          reject(e)
          debugger
          throw e
        })
    })
  },
  1500,
  {
    key: (...args) => Object.keys(args[2])[2],
  }
)

export const updateAllConveyorSections = (conveyorId, payload) => (dispatch) => {
  const oldConveyorSectionData = store.getState().ConveyorReducer.conveyors[conveyorId]
    .conveyorsections
  const newConveyorSectionData = formatIdAsKey(payload)
  dispatch(
    saveAllConveyorSectionsData({
      conveyorId,
      allSectionsData: newConveyorSectionData,
    })
  )

  return new Promise(async (resolve, reject) => {
    await ConveyorSectionService.updateAllConveyorSections(conveyorId, payload)
      .then((allSectionsData) => {
        if (allSectionsData !== newConveyorSectionData) {
          dispatch(
            saveAllConveyorSectionsData({
              conveyorId,
              allSectionsData,
            })
          )
        }
        resolve(allSectionsData)
      })
      .catch((e) => {
        dispatch(
          saveAllConveyorSectionsData({
            conveyorId,
            allSectionsData: oldConveyorSectionData,
          })
        )
        reject(e)
        debugger
      })
  })
}

export const updateConveyorSection = (conveyorId, sectionId, payload, debounce) => async (
  dispatch
) => {
  const oldSectionData = store.getState().ConveyorReducer.conveyors[conveyorId].conveyorsections[
    sectionId
  ]
  if (debounce) {
    return debouncedUpdateConveyorSection(dispatch, conveyorId, sectionId, payload, oldSectionData)
  } else {
    return new Promise(async (resolve, reject) => {
      await ConveyorSectionService.updateConveyorSection(conveyorId, sectionId, payload)
        .then(async (data) => {
          const conveyor = await formatNewConveyorSectionData(conveyorId, data)
          dispatch(saveConveyorToConveyors(conveyor))
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}

export const updateConveyorSectionOptimistic = (conveyorId, sectionId, payload, debounce) => async (
  dispatch
) => {
  const oldSectionData = store.getState().ConveyorReducer.conveyors?.[conveyorId]
    ?.conveyorsections?.[sectionId]
  if (!oldSectionData) return

  const sectionData = {
    ...oldSectionData,
    ...payload,
  }

  const sectionValidated = store.getState().EstimatorMetaReducer[conveyorId].conveyorbuilder
    .sectionsMetadata[sectionId].validated

  dispatch(
    saveConveyorSectionData({
      conveyorId,
      sectionId,
      sectionData,
    })
  )

  return new Promise((resolve, reject) => {
    if (sectionValidated || Object.keys(payload).includes('type')) {
      ConveyorSectionService.updateConveyorSection(conveyorId, sectionId, payload)
        .then(async (data) => {
          const conveyor = await formatNewConveyorSectionData(conveyorId, data)
          dispatch(saveConveyorToConveyors(conveyor))
          resolve(true)
        })
        .catch((e) => {
          dispatch(
            saveConveyorSectionData({
              conveyorId,
              sectionId,
              sectionData: oldSectionData,
            })
          )
          debugger
          reject(e)
        })
    }
  })
}

export const cloneConveyorSection = (conveyorId, sectionId) => async (dispatch) => {
  const conveyorsections = _.cloneDeep(
    store.getState().ConveyorReducer?.conveyors?.[conveyorId]?.conveyorsections || {}
  )
  const accessoriesOptions = store.getState().ProjectReducer.currentlistsettings.accessories
  const progenitorSection = conveyorsections?.[sectionId]
  delete progenitorSection.id

  const progenitorOverrideSection = store.getState().EstimatorReducer?.sections?.[sectionId] || {}

  const lastSectionNumber = Object.keys(conveyorsections).length
  const payload = {
    ...progenitorSection,
    sectionnumber: lastSectionNumber + 1,
    type: progenitorSection.type,
  }

  dispatch(setLoading({ loading: true, loadingMessage: 'Copying section...' }))

  try {
    const newSectionData = await ConveyorSectionService.createConveyorSection(conveyorId, payload)
    const { id: newSectionId } = newSectionData
    const { conveyortorquemasterupdate, ...restData } = newSectionData
    const conveyor = await formatNewConveyorSectionData(conveyorId, newSectionData)
    conveyor.conveyorsections[newSectionId] = {
      ...conveyor.conveyorsections[newSectionId],
      ...restData,
    }

    await dispatch(fetchMaterialsForConveyorSection(conveyor.id, newSectionId))
    await dispatch(fetchAccessoriesForConveyorSection(conveyor.id, newSectionId))

    batch(() => {
      dispatch(addConveyorSectionMetadata([conveyor]))
      dispatch(saveConveyorToConveyors(conveyor))
    })
    dispatch(
      selectSection({
        conveyorId,
        selectedSectionId: newSectionId,
      })
    )

    const { accessories = {}, materials = {} } = progenitorOverrideSection
    batch(() => {
      Object.entries(materials).forEach(([itemName, sectionData]) => {
        dispatch(
          addMaterialOrAccessoryToConveyorSection({
            materialsOrAccessories: 'materials',
            itemName,
            conveyorId,
            sectionId: newSectionId,
            payload: sectionData,
          })
        )
      })
      Object.entries(accessories).forEach(([itemName, sectionData]) => {
        if ('left' in sectionData && 'right' in sectionData) {
          dispatch(
            addAccessoryWithSidesToConveyorSection({
              sideOptions: accessoriesOptions[itemName].sides,
              sectionId: newSectionId,
              conveyorId: conveyorId,
              itemName,
              defaultData: sectionData,
            })
          )
        } else {
          dispatch(
            addMaterialOrAccessoryToConveyorSection({
              materialsOrAccessories: 'accessories',
              itemName,
              conveyorId,
              sectionId: newSectionId,
              payload: sectionData,
            })
          )
        }
      })
    })

    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))
    debugger
  }
}

//  _____        __              _   _____  _          _
// |_   _|      / _|            | | |  __ \(_)        | |
//   | |  _ __ | |_ ___  ___  __| | | |  | |_ ___  ___| |__   __ _ _ __ __ _  ___
//   | | | '_ \|  _/ _ \/ _ \/ _` | | |  | | / __|/ __| '_ \ / _` | '__/ _` |/ _ \
//  _| |_| | | | ||  __/  __/ (_| | | |__| | \__ \ (__| | | | (_| | | | (_| |  __/
// |_____|_| |_|_| \___|\___|\__,_| |_____/|_|___/\___|_| |_|\__,_|_|  \__, |\___|
//                                                                      __/ |
//                                                                     |___/
export const updateInfeedDischarge = (conveyorId, sectionType, payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await ConveyorSectionService.updateInfeedDischarge(conveyorId, sectionType, payload)
      .then(async (data) => {
        const conveyor = await formatNewConveyorSectionData(conveyorId, data)
        dispatch(updateConveyorInfeedDischarge({
            conveyorId,
            sectionType,
            data,
            conveyor
          }))
        resolve(data)
      })
      .catch((e) => {
        debugger
        reject(false)
      })
  })
}

//  _______                   __
// |__   __|                 / _|
//    | |_ __ __ _ _ __  ___| |_ ___ _ __ ___
//    | | '__/ _` | '_ \/ __|  _/ _ \ '__/ __|
//    | | | | (_| | | | \__ \ ||  __/ |  \__ \
//    |_|_|  \__,_|_| |_|___/_| \___|_|  |___/

export const createTransfer = (conveyorId, sectionType) => async (dispatch) => {
  await ConveyorSectionService.createTransfer(conveyorId, sectionType)
    .then((transferData) => {
      dispatch(saveConveyorTransfersData({ conveyorId, sectionType, transferData }))
    })
    .catch((e) => {
      debugger
    })
}

export const deleteTransfer = (conveyorId, sectionType) => async (dispatch) => {
  const oldTransferData = store.getState().ConveyorReducer.conveyors[conveyorId][sectionType]
    .transfer
  dispatch(deleteConveyorTransfersData({ conveyorId, sectionType }))

  await ConveyorSectionService.deleteTransfer(conveyorId, sectionType).catch((e) => {
    dispatch(saveConveyorTransfersData({ conveyorId, sectionType, transferData: oldTransferData }))
    debugger
  })
}

export const getInfeedDischargeTransfer = (conveyorId, sectionType) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await ConveyorSectionService.getInfeedDischargeTransfer(conveyorId, sectionType)
      .then((transferData) => {
        dispatch(saveConveyorTransfersData({ conveyorId, sectionType, transferData }))
        resolve(transferData)
      })
      .catch((e) => {
        reject(e)
        debugger
        throw e
      })
  })
}

export const updateTransfer = (conveyorId, sectionType, payload) => async (dispatch) => {
  await ConveyorSectionService.updateTransfer(conveyorId, sectionType, payload)
    .then((transferData) => {
      dispatch(saveConveyorTransfersData({ conveyorId, sectionType, transferData }))
    })
    .catch((e) => {
      debugger
    })
}
