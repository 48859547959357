import React from 'react'
import { Icon } from 'shared/components'

interface Props {
  collapsed: boolean
  onClick: React.EventHandler<React.MouseEvent>
}

export default function BuilderCollapseToggle(props: Props) {
  const { collapsed, onClick, ...restProps } = props
  return (
    <button className="collapsed-main__plusminus" onClick={onClick} tabIndex={0} {...restProps}>
      <Icon
        icon={collapsed ? 'plus' : 'minus'}
        size={16}
        colorKey={collapsed ? 'ash' : 'novaBlue'}
      />
    </button>
  )
}
