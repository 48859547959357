import store from 'utils/../reduxStore'
import { createAction } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import ADP from 'awesome-debounce-promise'
import ChainService from '../../../utils/api/chain/ChainService'
import {
  setLoading,
  setPendingResponse,
  deletePendingResponse,
} from '../../../shared/redux/ScreenActions'
import { saveConveyorToConveyors } from '../../Conveyor/redux/ConveyorActions'
import { getNonStandardRowWidthsForConveyor } from './helperFunctions'
import _ from 'lodash'
import { getVersionConveyors } from '../../Version/redux/VersionActions'
import { ValidationErrorInstance } from 'features/Project/components/ProjectSummary/utils/validateProject'
import { IChainConveyor, IRowPattern } from '../shared/types'
import { formatNewConveyorSectionData } from 'utils/helpers'

export const deleteNonStandardRowMeta = createAction<{
  conveyorId: number
  versionId: number
  nonStdRowId: number
}>('DELETE_NONSTANDARD_CHAIN_ROW_META')
export const setChainPreviewConveyorId = createAction<number>('SET_CHAIN_PREVIEW_CONVEYOR_ID')
export const updateConveyorChain = createAction<{
  conveyorId: number
  versionId: number
  chain: IChainConveyor
}>('UPDATE_CONVEYOR_CHAIN')
export const updateNonStandardRowMeta = createAction<{
  conveyorId: number
  metaInformation: Partial<IRowPattern>
  versionId: number
  nonStdRowId: number
}>('UPDATE_NONSTANDARD_CHAIN_ROW_META')
export const updateChainValidationErrors = createAction<{
  conveyorId: number
  versionId: number
  errors: Array<ValidationErrorInstance>
}>('UPDATE_CHAIN_VALIDATION_ERRORS')

export const getChainSerieInfo = (versionId, conveyorId, chain) => (dispatch) => {
  // Sophie when chain series is changed, this action gets called twice, once without
  // the chain info which causes crash. I put this here for now to stops that.
  if (!chain) {
    return null
  }
  return new Promise(async (resolve, reject) => {
    const { chainserieid = null, stdchainwidthid = null } = chain
    if (chainserieid && stdchainwidthid) {
      dispatch(setPendingResponse(`chain#${conveyorId}`))
      await ChainService.getChainSerieInfo(chainserieid, stdchainwidthid)
        .then((chain) => {
          dispatch(
            updateConveyorChain({
              conveyorId,
              chain,
              versionId,
            })
          )
          dispatch(deletePendingResponse(`chain#${conveyorId}`))
          resolve(chain)
        })
        .catch((e) => {
          dispatch(deletePendingResponse(`chain#${conveyorId}`))
          reject(e)
          debugger
        })
    }
  })
}

export const getChainForConveyor = (versionId, conveyorId) => async (dispatch) => {
  await ChainService.getChainForConveyor(conveyorId)
    .then((chain) =>
      dispatch(
        updateConveyorChain({
          conveyorId,
          chain,
          versionId,
        })
      )
    )
    .catch((e) => {
      debugger
      dispatch(setLoading(false))
    })
}

const debouncedUpdateChain = ADP(
  (dispatch, conveyor, key, chainId, payload) => {
    return new Promise(async (resolve, reject) => {
      await ChainService.updateChain(chainId, payload)
        .then(async (data) => {
          const conveyorWithTMAndWarnings = await formatNewConveyorSectionData(conveyor.Id, data)
          dispatch(
            saveConveyorToConveyors({
              ...conveyorWithTMAndWarnings,
              chain: {
                ...data,
                chainpatterns: conveyorWithTMAndWarnings.chain.chainpatterns,
              },
            })
          )
          dispatch(deletePendingResponse(`chain#${conveyor.id}`))
          resolve(data)
        })
        .catch((e) => {
          reject(e)
          debugger
          throw e
        })
    })
  },
  1000,
  {
    key: (...args) => args[2],
  }
)

export const updateChain = (conveyorId, chainId, payload, debounce = false) => async (dispatch) => {
  const conveyor = store.getState().ConveyorReducer.conveyors[conveyorId]
  const key = Object.keys(payload)[0]
  dispatch(setPendingResponse(`chain#${conveyorId}`))

  if (debounce) {
    debouncedUpdateChain(dispatch, conveyor, key, chainId, payload)
  } else {
    dispatch(setLoading({ loading: true, loadingMessage: 'Updating Conveyor Chain...' }))
    await ChainService.updateChain(chainId, payload)
      .then(async (data) => {
        // if (key === 'chainserieid' || key === 'chainstyleid') {
        //   dispatch(getChainSerieInfo(conveyorId, chain))
        // }
        const conveyorWithTMAndWarnings = await formatNewConveyorSectionData(conveyorId, data)
        dispatch(
          saveConveyorToConveyors({
            ...conveyorWithTMAndWarnings,
            chain: data,
          })
        )
        dispatch(deletePendingResponse(`chain#${conveyorId}`))
        dispatch(setLoading(false))
      })
      .catch((e) => {
        debugger
        dispatch(setLoading(false))
      })
  }
}

export const updateChainOptimistic = (conveyorId, chainId, payload) => async (dispatch) => {
  const oldConveyor = store.getState().ConveyorReducer.conveyors[conveyorId]
  const oldConveyorWithNewChain = {
    ...oldConveyor,
    chain: {
      ...oldConveyor.chain,
      ...payload,
    },
  }
  dispatch(setPendingResponse(`chain#${conveyorId}`))
  dispatch(saveConveyorToConveyors(oldConveyorWithNewChain))

  await ChainService.updateChain(chainId, payload)
    .then(() => {
      dispatch(deletePendingResponse(`chain#${conveyorId}`))
    })
    .catch((e) => {
      dispatch(saveConveyorToConveyors(oldConveyor))
      debugger
    })
}

/*
  _  _                  ___   _        _
 | \| |  ___   _ _     / __| | |_   __| |
 | .` | / _ \ | ' \    \__ \ |  _| / _` |
 |_|\_| \___/ |_||_|   |___/  \__| \__,_|

*/

export const initializeAllChainNonStandardRowsMeta = (conveyorId, versionId) => async (
  dispatch
) => {
  const conveyor = store.getState().ConveyorReducer.conveyors[conveyorId]
  const listReducer = store.getState().ListReducer
  const {
    chain: { chainpatterns, chainserieid },
  } = conveyor

  const { allNonStandardRowWidthsForConveyor, firstRowWidth } = getNonStandardRowWidthsForConveyor({
    chainPatterns: chainpatterns,
    unit: conveyor.unit,
    selectedChainSeries: listReducer.chains[chainserieid],
  })

  chainpatterns.forEach((cp) => {
    const rowWidth = allNonStandardRowWidthsForConveyor[cp.id]
    dispatch(
      updateNonStandardRowMeta({
        versionId,
        conveyorId: conveyor.id,
        nonStdRowId: cp.id,
        metaInformation: {
          totalWidth: rowWidth,
          widthDifference: firstRowWidth - rowWidth,
          firstRowWidth,
        },
      })
    )
  })
}

export const addNonStandardChainRow = (conveyorId) => async (dispatch) => {
  const conveyor = { ...store.getState().ConveyorReducer.conveyors[conveyorId] }
  const {
    chain: { id: chainId, chainpatterns },
  } = conveyor
  const { versionId } = store.getState().VersionReducer
  const patternIndex = { patternindex: chainpatterns.length + 1 }

  dispatch(setLoading({ loading: true, loadingMessage: 'Adding Chain Row...' }))
  await ChainService.addNewChainPatternRow(chainId, patternIndex)
    .then((chainRowResponseData) => {
      const newConveyor = _.cloneDeep(conveyor)
      newConveyor.chain.chainpatterns.push(chainRowResponseData)
      batch(() => {
        dispatch(saveConveyorToConveyors(newConveyor))
        dispatch(
          updateNonStandardRowMeta({
            conveyorId,
            metaInformation: {
              open: true,
              currentStepIndex: 0,
            },
            versionId,
            nonStdRowId: chainRowResponseData.id,
          })
        )
        dispatch(initializeAllChainNonStandardRowsMeta(conveyorId, versionId))
      })
    })
    .catch((e) => {
      debugger
      throw e
    })
  dispatch(setLoading(false))
}

export const deleteNonStandardChainRow = (conveyorId, nonStdRowId) => async (dispatch) => {
  const conveyor = _.cloneDeep(store.getState().ConveyorReducer.conveyors[conveyorId])
  const {
    chain: { id: chainId, chainpatterns: chainpatternsWithRow },
  } = conveyor
  const chainpatternsWithoutRow = chainpatternsWithRow.filter((r) => r.id !== nonStdRowId)
  const { versionId } = store.getState().VersionReducer
  conveyor.chain.chainpatterns = chainpatternsWithoutRow
  batch(() => {
    dispatch(saveConveyorToConveyors(conveyor))
    dispatch(initializeAllChainNonStandardRowsMeta(conveyorId, versionId))
  })

  await ChainService.deleteChainPatternRow(chainId, nonStdRowId)
    .then(() => {
      dispatch(getChainForConveyor(versionId, conveyorId))
      dispatch(deleteNonStandardRowMeta({ conveyorId, nonStdRowId, versionId }))
    })
    .catch((e) => {
      conveyor.chain.chainpatterns = chainpatternsWithRow
      batch(() => {
        dispatch(saveConveyorToConveyors(conveyor))
        dispatch(initializeAllChainNonStandardRowsMeta(conveyorId, versionId))
      })
      throw e
    })
}

export const duplicateNonStandardChainRow = (conveyorId, nonStdRowId) => async (dispatch) => {
  const conveyor = _.cloneDeep(store.getState().ConveyorReducer.conveyors[conveyorId])
  const {
    chain: { id: chainId, chainpatterns },
  } = conveyor
  const { versionId } = store.getState().VersionReducer
  const nonStdRowCopy = _.cloneDeep(chainpatterns.find((c) => c.id === nonStdRowId))
  delete nonStdRowCopy.id
  nonStdRowCopy.centerelements.forEach((e) => delete e.id)

  dispatch(setLoading({ loading: true, loadingMessage: 'Duplicating Chain Row...' }))
  await ChainService.addNewChainPatternRow(chainId, { ...nonStdRowCopy })
    .then((chainRowResponseData) => {
      conveyor.chain.chainpatterns.push(chainRowResponseData)
      batch(() => {
        dispatch(saveConveyorToConveyors(conveyor))
        dispatch(initializeAllChainNonStandardRowsMeta(conveyorId, versionId))
      })
    })
    .catch((e) => {
      debugger
      throw e
    })
  dispatch(setLoading(false))
}

export const updateNonStandardChainRow = (
  conveyorId,
  rowId,
  updatedChainRow,
  saveToBackend = false
) => async (dispatch) => {
  const conveyor = store.getState().ConveyorReducer.conveyors[conveyorId]
  const newConveyor = _.cloneDeep(conveyor)
  const { versionId } = store.getState().VersionReducer

  const rowIndex = conveyor.chain.chainpatterns.findIndex((cr) => cr.id === rowId)
  newConveyor.chain.chainpatterns[rowIndex] = updatedChainRow

  if (saveToBackend) {
    dispatch(setLoading({ loading: true, loadingMessage: 'Updating Conveyor Chain...' }))
    await ChainService.updateNonStandardChainRow(conveyor.chain.id, rowId, updatedChainRow)
  }
  batch(() => {
    dispatch(saveConveyorToConveyors(newConveyor))
    dispatch(
      updateNonStandardRowMeta({
        conveyorId,
        metaInformation: {
          dirty: !saveToBackend,
        },
        versionId,
        nonStdRowId: rowId,
      })
    )
    // TODO: if its not the first row, only calculate the one row
    // so all rows do not rerender. If its the first row, all rows
    // have to rerender because they have props calculated from the
    // first row's width.
    dispatch(initializeAllChainNonStandardRowsMeta(conveyorId, versionId))
    dispatch(setLoading({ loading: false }))
  })
}

export const saveCurrentNonStandardRowState = (conveyorId, patternId) => (dispatch) => {
  const conveyor = store.getState().ConveyorReducer.conveyors[conveyorId]
  const {
    chain: { id: chainId, chainpatterns },
  } = conveyor
  const chainRow = chainpatterns.find((p) => p.id === patternId)
  const { versionId } = store.getState().VersionReducer

  return new Promise(async (resolve, reject) => {
    dispatch(setLoading({ loading: true, loadingMessage: 'Updating Conveyor Chain...' }))
    await ChainService.updateNonStandardChainRow(chainId, patternId, chainRow)
    dispatch(getVersionConveyors())
    dispatch(
      updateNonStandardRowMeta({
        conveyorId,
        metaInformation: {
          dirty: false,
        },
        versionId,
        nonStdRowId: chainRow.id,
      })
    )
    dispatch(setLoading({ loading: false }))
    resolve(true)
  })
}
