import { ValidationErrorInstance } from '../validateProject';

export function canValidateWithoutRequiredInputs(errors: ValidationErrorInstance[]): boolean {
  for (const error of errors) {
    const { 
      field = '',
      tab = ''
    } = error
    if (tab === 'Estimator' && field === 'Case Size') return false
  }

  return true
}