import React, { useCallback } from 'react'
import { ColumnProps, FilterDropdownProps } from 'antd/lib/table'
import { Button, Input, Select } from 'shared/components'
import { IPartsUpdateResponse, ElementUnit } from 'shared/types/swagger'
import { UpdatePart } from '../types'
import { ScreenStateKey } from 'shared/types/Screen'
import moment from 'moment'

export default function useTableColumns({
  handleChange,
  screenState,
}: {
  handleChange: UpdatePart
  screenState: ScreenStateKey
}): ColumnProps<IPartsUpdateResponse>[] {
  const handleSearch = useCallback((_, confirm) => {
    confirm()
  }, [])

  const handleReset = useCallback((clearFilters) => {
    clearFilters()
  }, [])

  const editing = ['EDITING', 'VALIDATING'].includes(screenState)

  const columns: ColumnProps<IPartsUpdateResponse>[] = [
    {
      dataIndex: 'partnumber',
      title: 'Item Number',
      width: 120,
      sorter: (a, b) => a.partnumber.localeCompare(b.partnumber),
      filterDropdown: function renderFilterDropdown({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        return (
          <div className="table-filter-box">
            <Input
              className="table-filter-box__search"
              placeholder="Search Item Number"
              value={selectedKeys[0]}
              small
              onChange={(value) => setSelectedKeys(value ? [value as string] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
            />
            <div className="table-filter-box__actions">
              <Button secondary onClick={() => handleReset(clearFilters)} small text="Reset" />
              <Button
                onClick={() => handleSearch(selectedKeys, confirm)}
                icon="search"
                small
                text="Search"
              />
            </div>
          </div>
        )
      },
      onFilter: (value, record) => {
        return record?.partnumber?.toLowerCase?.()?.includes?.(value?.toLowerCase?.())
      },
      ...(editing
        ? {
            render: function InputItemNumber(value, record: IPartsUpdateResponse) {
              return (
                <Input
                  error={!value}
                  small
                  defaultValue={record.partnumber}
                  onChange={(value: string) =>
                    handleChange(record.partnumberguid, 'partnumber', value)
                  }
                />
              )
            },
          }
        : {}),
    },
    {
      title: 'Item Description',
      dataIndex: 'description',
      width: 200,
      sorter: (a, b) => a.description.localeCompare(b.description),
      ...(editing
        ? {
            render: function InputDescription(value, record: IPartsUpdateResponse) {
              return (
                <Input
                  small
                  error={!value}
                  defaultValue={record.description}
                  onChange={(value: string) =>
                    handleChange(record.partnumberguid, 'description', value)
                  }
                />
              )
            },
          }
        : {}),
    },
    {
      title: 'Unit of Measurement',
      dataIndex: 'unit',
      width: 200,
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      ...(editing
        ? {
            render: function SelectUM(_, record) {
              return (
                <Select
                  small
                  defaultValue={record.unit}
                  options={Object.values(ElementUnit).map((u) => ({ value: u, label: u }))}
                  onChange={(value: string) => handleChange(record.partnumberguid, 'unit', value)}
                />
              )
            },
          }
        : {}),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: 120,
      sorter: (a, b) => a.source.localeCompare(b.source),
      ...(editing
        ? {
            render: function InputSource(value, record: IPartsUpdateResponse) {
              return (
                <Input
                  small
                  error={!value}
                  pattern="^(M|P|m|p)?$"
                  required
                  placeholder="Enter M or P"
                  inputTextTransform='uppercase'
                  defaultValue={record.source}
                  onChange={(value: string) => handleChange(record.partnumberguid, 'source', value.toUpperCase())}
                />
              )
            },
          }
        : {}),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updateddate',
      width: 120,
      render: (text) => moment(text).format('MM/DD/YY LT'),
      sorter: (a, b) => new Date(a.updateddate).getTime() - new Date(b.updateddate).getTime(),
    },
  ]

  return columns
}
