import {
  setDefaultAccessoryDataForConveyor,
  runUpdateDefaultMaterialOrAccessoryForConveyor,
} from '../../../../redux/EstimatorActions'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializesidewalls = (conveyorId, sidewallsData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoryDataForConveyor({
      conveyorId,
      accessoryKey: 'sidewalls',
      accessoryData: sidewallsData,
    })
  )
}

export const updateSidewalls = ({ conveyorId, updatedSidewallsFields, sideId, sideName }) => (
  dispatch
) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'sidewalls',
      updatedFields: updatedSidewallsFields,
      type: 'accessories',
      sideId,
      sideName,
    })
  )
}
