import React, { Component } from 'react'
export default class PasteIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="0 0 32 32"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M23.6196593,5 C24.2674102,5 24.7925161,5.52510585 24.7925161,6.17285674 L24.7925161,9.83216978 L28.8271433,9.83216978 C29.4748941,9.83216978 30,10.3572756 30,11.0050265 L30,26.4241832 C30,27.0719341 29.4748941,27.5970399 28.8271433,27.5970399 L8.38034069,27.5970399 C7.73258979,27.5970399 7.20748394,27.0719341 7.20748394,26.4241832 L7.20748394,22.7648701 L3.17285674,22.7648701 C2.52510585,22.7648701 2,22.2397643 2,21.5920134 L2,6.17285674 C2,5.52510585 2.52510585,5 3.17285674,5 L23.6196593,5 Z M27.6542865,12.1778833 L9.55319743,12.1778833 L9.55319743,25.2513264 L27.6542865,25.2513264 L27.6542865,12.1778833 Z M18.6858284,13.5985019 C19.2376933,13.5985019 19.7055785,14.0042965 19.7886492,14.6213256 L19.7886492,14.6213256 L19.7890525,17.6071497 L22.8450927,17.6120837 C23.391411,17.6901291 23.7972057,18.1580143 23.7972057,18.7098792 C23.7972057,19.261744 23.391411,19.7296292 22.774382,19.8126999 L22.774382,19.8126999 L19.7876382,19.8119087 L19.7836239,22.8691434 C19.711582,23.3734373 19.3073689,23.757995 18.8115519,23.8141737 L18.8115519,23.8141737 L18.6858284,23.8212564 C18.1339635,23.8212564 17.6660784,23.4154618 17.5830077,22.7984328 L17.5830077,22.7984328 L17.5835864,19.8112016 L14.6679855,19.8076747 C14.2813905,19.8629025 13.8977694,19.7106356 13.653975,19.4134723 L13.653975,19.4134723 L13.5780768,19.3091372 C13.3435966,18.9440375 13.3435966,18.4757209 13.5780768,18.1106211 C13.8125569,17.7455214 14.2384356,17.5507194 14.5972748,17.6070584 L14.5972748,17.6070584 L17.5835864,17.6064426 L17.5880329,14.5506149 C17.6600749,14.046321 18.064288,13.6617633 18.560105,13.6055846 L18.560105,13.6055846 Z M22.4468026,7.34571349 L4.34571349,7.34571349 L4.34571349,20.4191567 L7.20748394,20.4191567 L7.20748394,11.0050265 C7.20748394,10.3572756 7.73258979,9.83216978 8.38034069,9.83216978 L22.4546216,9.83216978 L22.4468026,7.34571349 Z"
            fill={color}
          ></path>
        </g>
      </svg>
    )
  }
}
