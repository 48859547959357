import { formatIdAsKey } from 'utils/helpers'
import _ from 'lodash'
import { IOrganizedSettingsList, IListResponse } from './types'
import { ISytelineResponse } from 'shared/types/swagger'

export const organizeSettingsList = (lists: IListResponse[]): IOrganizedSettingsList => {
  const listObj: IOrganizedSettingsList = {}
  lists.forEach((list) => {
    const listElements = formatIdAsKey(list.listelements)
    _.set(listObj, list.name, listElements)
  })

  listObj.gearmotor.drivetypes = listObj.drivetypes
  listObj.gearmotor.gearboxes = listObj.gearboxs

  const {
    InputVoltage,
    Inverter,
    movimotspeedcontroller,
    ...rest
  } = listObj.eurodrive.variablespeedoptions

  listObj.gearmotor.eurodrive = {
    ...listObj.eurodrive,
    variablespeed: {
      InputVoltage,
      Inverter,
      movimotspeedcontroller,
      options: rest,
    },
  }

  delete listObj.drivetypes
  delete listObj.gearboxs
  delete listObj.eurodrive

  // Format Section Infeed
  listObj.sections.infeed = {
    drive: listObj.sections.infeeds.driveOrientationOnlys,
    idler: listObj.sections.infeeds.idlertypes,
    types: listObj.sections.infeeds.types,
  }
  delete listObj.sections.infeeds

  // Format Section Discharge
  listObj.sections.discharge = {
    drive: listObj.sections.discharges.driveOrientation,
    idler: listObj.sections.discharges.idlertypes,
    types: listObj.sections.discharges.types,
  }
  delete listObj.sections.discharges

  return listObj
}

const countDecimals = (val) => (val % 1 === 0 ? 0 : val.toString().split('.')[1].length)
const truncateValueto4 = (val) => (countDecimals(val) > 4 ? parseFloat(val.toFixed(4)) : val)

export function fixSitelineFloats(data: ISytelineResponse[]): ISytelineResponse[] {
  return data.map((record) => {
    return {
      ...record,
      updatedcost: truncateValueto4(record.updatedcost),
      freightcost: truncateValueto4(record.freightcost),
      dutycost: truncateValueto4(record.dutycost),
    }
  })
}
