import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

/**
 * @typedef {Object} LegendFields
 * @property {number} [id]
 * @property {number} [biDirectional]
 * @property {number} [crossBraces]
 * @property {number} [returnChainSupport]
 * @property {string} [earsModified]
 * @property {string} [sprocketsModified]
 * @property {string} [transferOnDrive]
 * @property {string} [transferOnSerialNumber]
 * @property {string} [transferOffDrive]
 * @property {string} [transferOffSerialNumber]
 * @property {string} [tocTo2ndRail]
 * @property {string} [topKeeper]
 * @property {number} [infeedElevation]
 * @property {number} [dischargeElevation]
 * @property {number} [washdownDegree]
 * @property {string} [gearmotorMounting]
 * @property {string} [motorHz]
 * @property {string} [gearmotorNumber]
 * @property {number} [torque]
 * @property {number} [gearmotorRatio]
 * @property {number} [gearmotorRPM]
 * @property {number} [speed60Hz]
 * @property {number} [speed33Hz]
 * @property {number} [speed133Hz]
 * @property {string} [controllerMounting]
 * @property {string} [controllerWired]
 * @property {string} [remoteMount]
 * @property {string} [remoteMountType]
 * @property {number} [remoteDrivenSprk]
 */

/** @type {Object<string, LegendFields>} */
const INITIAL_STATE = {}

export default createReducer(INITIAL_STATE, {
  ADD_LEGEND_FIELDS: (state, action) => {
    const { conveyorId, fields } = action.payload
    state[conveyorId] = fields
  },
  UPDATE_LEGEND_FIELDS: (state, action) => {
    const { conveyorId, fields } = action.payload
    state[conveyorId] = {
      ...state[conveyorId],
      ...fields,
    }
  },
  [PURGE]: () => INITIAL_STATE,
})
