/**
 * @typedef {Object} SectionConfigItem
 * @property {number} size
 * @property {string} title
 * @property {string} [category]
 * 
 * @typedef {Array<Array<SectionConfigItem>>} SectionConfig
 */

/** @type {SectionConfig} */
export const engineeringAssemblyLMSectionsConfig = [
  [
    {
      size: 12,
      title: 'Conveyor MUF',
      category: 'conveyor'
    },
    {
      size: 12,
      title: 'Options MUF',
      category: 'options'
    }
  ],
  [
    {
      size: 24,
      title: 'Labor/Machine Costs',
      category: 'lmcosts'
    }
  ]
]

/** @type {SectionConfig} */
export const costImportSectionsConfig = [
  [
    {
      size: 24,
      title: 'Cost Import Options',
      category: 'all'
    }
  ]
]

/** @type {SectionConfig} */
export const miscSectionsConfig = [
  [
    {
      size: 24,
      title: 'Miscellaneous',
      category: 'all'
    }
  ],
]