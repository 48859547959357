import http from 'utils/api/Client'
import { formatIdAsKey } from 'utils/helpers'
import { formatConveyorsAndConveyorSections } from '../conveyor/helpers'
import PDFService from 'utils/api/pdf/PDFService'
import { IVersionNote, IVersion, IConveyor, IVersionResponse } from 'shared/types/swagger'
import { AxiosResponse } from 'axios'
import { DownloadTypeKey } from 'features/Version/types'
import { IVersionWithAutorizedActions } from 'features/Version/shared/types'
import { IStdChainDescriptionResponse } from 'shared/types/StdChainDescription'
import {
  AccessoryCeilingSupports,
  AccessoryFloorSupports,
} from 'features/Estimator/redux/EstimatorReducer'

type Id = string | number
interface IConveyorWithAllDetails extends IConveyor {
  stdchaindescriptions: Array<IStdChainDescriptionResponse>;
  floorsupportdetails: Array<unknown>;
  accessories: unknown;
  materials: unknown;
  ceilingsupports: Partial<AccessoryCeilingSupports>;
  ceilingsupportdetails: Array<unknown>;
  floorsupports: Partial<AccessoryFloorSupports>;
}

const VersionService = {
  async cancelUpdateConveyorsCosts(versionId: Id) {
    return await http.post(`/Versions/${versionId}/CancelUpdateConveyorsCosts`, {})
  },
  async cloneVersion(projectId: Id, versionId: Id) {
    return await http
      .post<null, AxiosResponse<IVersion>>(`/Projects/${projectId}/Versions/${versionId}/Clone`, {})
      .then(({ data }) => data)
  },
  async deleteVersion(versionId: Id) {
    return await http.delete(`/Versions/${versionId}`).then(({ data }) => data)
  },
  async downloadVersionFile(versionId: Id, downloadType: DownloadTypeKey, projectId: Id) {
    if (downloadType === 'Bend') {
      await PDFService.bendProject(versionId, projectId)
    } else {
      await http.get(`/Versions/${versionId}/Documents/${downloadType}`).then(({ data }) => {
        const link = document.createElement('a')
        link.download = data.filename
        link.href = data.filecontent
        link.click()
      })
    }
  },
  async getVersion(versionId: Id) {
    return await http.get<IVersionResponse>(`/Versions/${versionId}`).then(({ data }) => data)
  },
  async getVersionWithAllDetails(versionId: Id) {
    return await http
      .get<IVersion & { conveyors: Array<IConveyorWithAllDetails> }>(
        `/Versions/${versionId}/withalldetails`
      )
      .then(({ data }) => data)
  },
  async getVersions(projectId: Id) {
    return await http
      .get<Array<IVersion>>(`/Projects/${projectId}/versions`)
      .then(({ data }) => formatIdAsKey(data))
  },
  async setMasterVersion(projectId: Id, versionId: Id) {
    return await http
      .post(`/Projects/${projectId}/MasterVersion/${versionId}`, {})
      .then(({ data }) => data)
  },
  async updateVersion(projectId: Id, versionId: Id, payload: Partial<IVersion>) {
    return await http
      .put<IVersion, AxiosResponse<IVersion>>(
        `/Projects/${projectId}/Versions/${versionId}`,
        payload
      )
      .then(({ data }) => data)
  },
  async updateVersionStatus(versionId: Id, statusId: Id) {
    return await http
      .post<null, AxiosResponse<IVersionWithAutorizedActions>>(
        `/Versions/${versionId}/Action/${statusId}`,
        {}
      )
      .then(({ data }) => data)
  },
  async updateConveyorsCosts(versionId: Id) {
    return await http
      .post<null, AxiosResponse<Array<IConveyor>>>(
        `/Versions/${versionId}/UpdateConveyorsCosts`,
        {}
      )
      .then(({ data }) => formatConveyorsAndConveyorSections(data))
  },
  async updateInactivationDate(versionId: Id, newDate: string) {
    return await http
      .put(`/Versions/${versionId}/UpdateInactivationDate/${newDate}`, {})
      .then(({ data }) => data)
  },
  async updateDiscount(versionId: Id, discount) {
    return await http
      .post(`/Versions/${versionId}/ValidationPrices/SetDiscount/${discount}`)
      .then(({ data }) => data)
  },
  async getVersionNotes(versionId: Id) {
    return await http
      .get<Array<IVersionNote>>(`/Versions/${versionId}/VersionNotes`)
      .then(({ data }) => formatIdAsKey(data))
  },
  async addVersionNote(versionId: Id, note: string) {
    return await http.post(`/Versions/${versionId}/VersionNote`, { note }).then(({ data }) => data)
  },
  async updateVersionNote(versionId: Id, noteId: Id, note: string) {
    return await http
      .put(`/Versions/${versionId}/VersionNote/${noteId}`, { note })
      .then(({ data }) => data)
  },
  async deleteVersionNote(versionId: Id, noteId: Id) {
    return await http
      .delete(`/Versions/${versionId}/VersionNote/${noteId}`)
      .then(({ status }) => status === 200)
  },
  async syncToSyteline(versionId: Id) {
    return await http
      .post<null, AxiosResponse<boolean>>(`/Versions/${versionId}/SyncToSyteline`, {})
      .then(({ data }) => data)
  },
}

export default VersionService
