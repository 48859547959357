import React, { memo } from 'react'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'

/**
 * @typedef {Object} Props
 * @prop {boolean} isActive
 */

/** @param {Props} props */
function ExpiredMessage({ isActive }) {
  if (isActive) return null

  return (
    <div className="expired-quote">
      <Icon icon="caution" color={Colors.bourbon} size={21} />
      <div className="expired-quote__text">
        <strong>Expired Quote.</strong> This version&apos;s price is no longer valid. You may only
        view this version.
      </div>
    </div>
  )
}

export default memo(ExpiredMessage)
