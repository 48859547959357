import * as React from 'react'
import './ProjectSearching.scss'
import { Button } from 'shared/components'
import { useLoadingDots } from 'shared/hooks'

/**
 * @typedef {Object} Props
 * @prop {boolean} show
 * @prop {number} searchTimestamp
 * @prop {function} getProjects
 * @prop {function} cancelGetProjects
 */

/** @param {Props} props */
export default function ProjectSearching(props) {
  const { show, searchTimestamp, getProjects, cancelGetProjects } = props

  const dots = useLoadingDots(true)

  const updatedDateTimeString = (() => {
    if (!searchTimestamp) return null
    const updatedDate = new Date(searchTimestamp)
    const updatedDateString = new Intl.DateTimeFormat('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    }).format(updatedDate)
    const updatedTimeString = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(updatedDate)
    return `${updatedDateString} ${updatedTimeString}`
  })()

  return (
    <>
      <div className="project-searching__meta">
        <span className="project-searching__meta-dt">
          {updatedDateTimeString ? `Last Updated: ${updatedDateTimeString}` : null}
        </span>
        <span className="project-searching__meta-actions">
          <Button
            tertiary
            text={show ? 'Cancel refresh' : 'Refresh'}
            onClick={show ? () => cancelGetProjects() : () => getProjects()}
          />
        </span>
      </div>
      <div hidden={!show} className="project-searching">
        <span aria-hidden="true" style={{ marginLeft: 'auto' }} />
        <span>Fetching All Projects</span>
        <span aria-hidden="true" className="project-searching__dots">
          {[...Array(dots).keys()].map((number => (
            <span className="project-searching__dot" key={number}>.</span>
          )))}
        </span>
      </div>
    </>
  )
}
