import Colors from 'shared/constants/colors'

const projectStatusLabels = {
  AccountManager: {
    name: 'Account Managers',
    fill: Colors.bourbon,
  },
  Client: {
    name: 'Client',
    fill: Colors.positive,
  },
  Designer: {
    name: 'Designers',
    fill: Colors.navy,
  },
  Estimator: {
    name: 'Estimators',
    fill: Colors.novaBlue,
  },
  Manager: {
    name: 'Managers',
    fill: Colors.gold,
  },
  CustomerService: {
    name: 'Customer Service',
    fille: Colors.negative
  }
}

export default (projectStatusData = []) => {
  return projectStatusData.map((data) => {
    const { department, amount, count, countpercentage } = data
    return {
      name: projectStatusLabels[department].name,
      department,
      count,
      countpercentage,
      amount,
      fill: projectStatusLabels[department].fill,
    }
  })
}
