export default function formatPayload(newValues, prevValues) {
  const entries = Object.entries(newValues)
  const payload = {
    generalcost: {},
    mufupdates: []
  }
  const tempLaborcostsettings = {}

  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i]
    if (key.includes('-')) {
      const [lcsid, perhr] = key.split('-')
      const prevValue = prevValues.lcs[lcsid]
      tempLaborcostsettings[lcsid] = {
        ...prevValue,
        ...(tempLaborcostsettings?.[lcsid] || {}),
        [perhr]: parseFloat(value)
      }
    } else if (Number.isNaN(parseFloat(key))) {
      payload.generalcost[key] = parseFloat(value)
    } else {
      payload.mufupdates.push({ ...prevValues[key], value: parseFloat(value) })
    }
  }

  if (Object.keys(tempLaborcostsettings).length) {
    payload.generalcost.laborcostsettings = Object.values(tempLaborcostsettings)
  }

  return payload
}
