import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { steps } from './constants'
import { addNonStandardChainRow, updateNonStandardChainRow } from '../../redux/ChainActions'
import { Icon } from 'shared/components'
import ChainBuilderRow from './row'
import './chainBuilder.scss'
import { captureSentryError } from 'utils/helpers'

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result.map((ele, position) => ({
    ...ele,
    position,
  }))
}

/**
 * @typedef {import('react-router').RouteComponentProps} RouteComponentProps
 *
 * @typedef {Object} Props
 * @prop {number} conveyorId
 * @prop {string} unitSuffix
 */

/**
 * @extends {Component<Props & ChainBuilderConnectProps & RouteComponentProps>}
 */
class ChainBuilder extends Component {
  state = {
    updatesAllowed: true,
    loading: false,
    increment: 1,
    hasChanges: false,
  }

  shouldComponentUpdate() {
    return this.state.updatesAllowed
  }

  renderAddChainRow = () => {
    const { addNonStandardChainRow, conveyorId } = this.props
    const { pendingAddRow } = this.state
    return (
      <div className="add-row-wrapper">
        <div
          role="button"
          id="add-nonstandard-row"
          className={`add-row-container ${pendingAddRow ? 'disabled' : ''}`}
          onClick={() => addNonStandardChainRow(conveyorId)}
        >
          <Icon icon="plus" size={23} style={{ marginRight: '8px', marginBottom: '2px' }} />
          Add a Row
        </div>
      </div>
    )
  }

  onDragEnd = (startIndex, newIndex, pattern) => {
    const { conveyorId, updateNonStandardChainRow } = this.props
    let newCenterElements = [...pattern.centerelements]
    newCenterElements = reorder(newCenterElements, startIndex, newIndex)
    updateNonStandardChainRow(conveyorId, pattern.id, {
      ...pattern,
      centerelements: newCenterElements,
    })
  }

  // clicking a link's X to remove it
  deleteLink = (link, spot, pattern) => {
    const { conveyorId, updateNonStandardChainRow } = this.props
    if (spot === 'center') {
      var centerelements = [...pattern.centerelements].filter((_, i) => i !== link.position)

      const newPattern = {
        ...pattern,
        centerelements: centerelements
          .sort((a, b) => a.position - b.position)
          .map((ce, i) => ({ ...ce, position: i })),
      }

      updateNonStandardChainRow(conveyorId, newPattern.id, newPattern)
    } else {
      pattern[`${spot}elementid`] = null

      const newPattern = {
        ...pattern,
      }

      newPattern[`${spot}elementid`] = null

      updateNonStandardChainRow(conveyorId, newPattern.id, newPattern)
    }
  }

  // clicking an availablelink to add it on to the end of the pattern (or replace left/right)
  addLinkFromClick = (link, spot, pattern) => {
    // spot is left right or center
    const { chainPatterns, conveyorId, updateNonStandardChainRow } = this.props
    const { id } = link

    var stateCopy = [...chainPatterns]
    var patternIdx = stateCopy.findIndex((p) => p.id === pattern.id)

    const newLink = {
      elementid: id,
      tempId: Date.now(),
      position: pattern.centerelements.length,
      ...(link.manualwidthmm ? { manualwidthmm: link.manualwidthmm } : {}),
    }

    if (spot === 'center') {
      var centerelements = [...pattern.centerelements]

      centerelements.push(newLink)

      const newPattern = {
        ...pattern,
        centerelements,
      }

      stateCopy.splice(patternIdx, 1, newPattern)

      updateNonStandardChainRow(conveyorId, pattern.id, newPattern)
    } else {
      const newPattern = {
        ...pattern,
        [`${spot}elementid`]: link.id,
      }

      updateNonStandardChainRow(conveyorId, pattern.id, newPattern)
    }
  }

  render() {
    const {
      chainId,
      chainMetaInformation,
      chainPatterns,
      conveyorId,
      isactive,
      islocked,
      selectedChainSeries,
      unitSuffix,
    } = this.props

    // ! availableSteps is the steps a chain series allows...
    // there are one or two that do not have left or right and just center
    const availableSteps = steps.filter((step) => {
      return selectedChainSeries && selectedChainSeries[`${step}elements`]
        ? selectedChainSeries[`${step}elements`].length
        : false
    })

    return (
      <div className="inner-container-chain" title="Chain Row Container">
        {chainPatterns && chainPatterns.length
          ? chainPatterns.map((pattern, index) => {
              if (!chainMetaInformation[pattern.id]) {
                return <div>loading...</div>
              }

              return (
                <ChainBuilderRow
                  key={`chainbuilderrow-${conveyorId}-${index}`}
                  rowIndex={index}
                  availableSteps={availableSteps}
                  conveyorId={conveyorId}
                  chainId={chainId}
                  addLinkFromClick={this.addLinkFromClick}
                  onDragEnd={this.onDragEnd}
                  deleteLink={this.deleteLink}
                  unitSuffix={unitSuffix}
                />
              )
            })
          : null}
        {isactive && !islocked ? this.renderAddChainRow() : null}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { ListReducer, ChainReducer, ConveyorReducer, ProjectReducer } = state
    const { match, conveyorId } = props
    const {
      params: { versionId },
    } = match
    const { chain } = ConveyorReducer.conveyors[conveyorId]
    const { isactive, islocked } = ProjectReducer.versions[versionId]

    if (!ChainReducer?.[versionId]?.[conveyorId]?.nonStandardRowMeta) {
      return {
        loading: true,
        isactive,
        islocked,
      }
    }

    return {
      selectedChainSeries: ListReducer.chains[chain.chainserieid],
      chainId: chain.id,
      chainPatterns: chain.chainpatterns,
      chainMetaInformation: ChainReducer[versionId][conveyorId].nonStandardRowMeta,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  addNonStandardChainRow,
  updateNonStandardChainRow,
}

/**
 * @typedef {ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps} ChainBuilderConnectProps
 */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChainBuilder))
