import React, { Component } from 'react'
export default class SyncIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            fill={color}
            d="M11.1830296,6.00521435 C11.2150563,6.00220007 11.2463295,6.00521435 11.2779794,6.00521435 L12.4098416,6.00521435 L12.4098416,13.2847009 C12.4098416,13.9094105 11.9038193,14.415056 11.2794866,14.415056 C10.6551538,14.415056 10.1491315,13.9094105 10.1491315,13.2847009 L10.1491315,9.2384066 C8.10318886,10.9478802 6.88881074,13.4828898 6.88881074,16.2209866 C6.88881074,19.6339053 8.77160548,22.7333388 11.8024642,24.3098074 C12.3563382,24.5976711 12.5718592,25.2800288 12.2836186,25.8339028 C12.0824154,26.2212378 11.6882983,26.4431641 11.2798634,26.4431641 C11.1042815,26.4431641 10.9260622,26.4020946 10.7595232,26.3150572 C6.97735522,24.3486162 4.62810063,20.4805412 4.62810063,16.2209866 C4.62810063,13.1874904 5.82476985,10.3570813 7.87749463,8.26592447 L5.13035506,8.26592447 C4.5056455,8.26592447 4,7.75990219 4,7.13556941 C4,6.51123663 4.5056455,6.00521435 5.13035506,6.00521435 L11.1830296,6.00521435 Z M26.8696449,24.1773674 C27.4939777,24.1773674 28,24.6833897 28,25.3077225 C28,25.9320553 27.4939777,26.4380775 26.8696449,26.4380775 L20.7623366,26.4380775 C20.7480187,26.4384543 20.7337009,26.4433525 20.7193831,26.4433525 C20.7065724,26.4433525 20.6937617,26.4384543 20.6813278,26.4380775 L19.5901584,26.4380775 L19.5901584,19.158591 C19.5901584,18.5338814 20.0958039,18.0282359 20.7205134,18.0282359 C21.3448462,18.0282359 21.8508685,18.5338814 21.8508685,19.158591 L21.8508685,23.2048853 C23.8964344,21.4954117 25.1111893,18.9604021 25.1111893,16.2223053 C25.1111893,12.8093866 23.2283945,9.70995305 20.197159,8.13386132 C19.6429083,7.84562078 19.427764,7.16326311 19.7156278,6.60938913 C20.0034915,6.05513837 20.6847189,5.83961734 21.2401,6.12785788 C25.022268,8.09467568 27.3718994,11.9627507 27.3718994,16.2223053 C27.3718994,19.2554247 26.1748534,22.0862106 24.1225054,24.1773674 L26.8696449,24.1773674 Z"
          ></path>
        </g>
      </svg>
    )
  }
}
