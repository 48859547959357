import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Icon } from 'shared/components'
import './OverviewCard.scss'
import Colors from 'shared/constants/colors'
import { formatStatisticsValues } from 'utils/helpers'
import { captureSentryError } from 'utils/helpers'

class OverviewCard extends Component {
  render() {
    const { data, endDate, periodType, startDate } = this.props
    const { diff, icon, label, value } = data

    const diffValues =
      diff >= 0
        ? {
            color: Colors.positive,
            label: 'up',
            icon: 'trendUp',
          }
        : {
            color: Colors.negative,
            label: 'down',
            icon: 'trendDown',
          }

    const start = moment(startDate)
    const end = moment(endDate)
    const numberOfMonths = end.diff(start, 'months') + 1
    const customMonthsText = `${numberOfMonths} month${numberOfMonths > 1 ? 's' : ''}`

    return (
      <div className="overview-card">
        <div className="overview-card__top">
          <div className="overview-card__top__left">
            <div className="overview-card__top__left__label">{label}</div>
            <div className="overview-card__top__left__value">
              {label.includes('Estimate') ? <span>$</span> : <span></span>}
              {formatStatisticsValues(value, false)}
            </div>
          </div>
          <div className="overview-card__top__right">
            <div className="overview-card__top__right__icon-container">
              <Icon icon={icon} size={32} color={Colors.white} />
            </div>
          </div>
        </div>
        <div className="overview-card__bottom">
          <div className="overview-card__bottom__text">
            <Icon icon={diffValues.icon} color={diffValues.color} size={19} />
            {periodType.label === 'custom' ? null : (
              <span style={{ color: diffValues.color }}>{Math.abs(diff)}% </span>
            )}
            {`${diff >= 0 ? 'up' : 'down'} from last ${
              periodType.label === 'custom' ? customMonthsText : periodType.label
            }`}
          </div>
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const { endDate, periodType, startDate } = state.AdminStatsReducer
    return {
      endDate,
      periodType,
      startDate,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default connect(mapStateToProps, null)(OverviewCard)
