import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { DatePicker } from 'antd'
import { Button, Icon, Select } from 'shared/components'
import {
  downloadVersionFile,
  updateVersionStatus,
  updateVersionConveyorsCosts,
  updateInactivationDate,
} from 'features/Version/redux/VersionActions'
import { versionDownloadOptions } from 'shared/constants/actionMenuOptions'
import Colors from 'shared/constants/colors'
import { setModalContent, openConfirmModal } from 'shared/redux/ScreenActions'
import { displayValueOrDashes, showDateTimeEST, captureSentryError } from 'utils/helpers'
import './ProjectSummary.scss'
import ExpiredMessage from './components/ExpiredMessage'
import CollapseButton from './components/CollapseButton'
import SwitchVersionButton from './components/SwitchVersionButton'
import EditProjectDetailsButton from './components/EditProjectDetailsButton'
import CollapseConveyorsButton from './components/CollapseConveyorsButton'
import ValidatePriceButton from './components/ValidatePriceButton'
import GetLABOMButton from './components/GetLABOMButton'
import ProjetStatusActions from './components/ProjectStatusActions'
import ProjectService from 'utils/api/project/ProjectService'
import cn from 'classnames'
import { RootState } from 'reduxStore'
import { RouteComponentProps } from 'react-router'
import SytelineSyncButton from './components/SytelineSyncButton'
import { IProject } from 'shared/types/swagger'
import { LabeledValue } from 'antd/lib/select'
import projectHasNoEstimator from './utils/helper/projectHasNoEstimator'
import ProjectOwnerButton from './components/ProjectOwnerButton'
import { getCustomerUsers } from 'shared/redux/ListActions'

type State = {
  collapsed: boolean;
  downloadType: string | number | LabeledValue;
  project: ReduxProps['project'];
  checkedForEstimator: boolean;
}

class ProjectSummary extends Component<ReduxProps, State> {
  state = {
    collapsed: true,
    downloadType: 'Quote',
    project: this.props.project,
    checkedForEstimator: false,
  }

  async componentDidMount() {
    const { permissions, setModalContent, getCustomerUsers } = this.props
    const { checkedForEstimator, project } = this.state
    const { customerid } = project
    if (customerid) { await getCustomerUsers(customerid) }

    // checks project for estimator and raises modal if no estimator
    if (
      permissions.includes('check_estimator_assigned') &&
      project &&
      projectHasNoEstimator(project) &&
      !checkedForEstimator
    ) {
      setModalContent('noEstimator')
      this.setState({ checkedForEstimator: true })
    }
  }

  componentDidUpdate(prevProps: ReduxProps, prevState: State) {
    const { permissions, setModalContent } = this.props
    const { checkedForEstimator, project = {} as IProject } = this.state

    const shouldUpdateProjectState: boolean =
      ('modifieddatetime' in project &&
        prevState?.project?.modifieddatetime !== project?.modifieddatetime) ||
      ('issyncedwithsyteline' in project &&
        prevState?.project?.issyncedwithsyteline !== project.issyncedwithsyteline)

    if (shouldUpdateProjectState) {
      this.setState({ project })
    }

    // checks project for estimator and raises modal if no estimator
    if (
      permissions.includes('check_estimator_assigned') &&
      project &&
      projectHasNoEstimator(project) &&
      !checkedForEstimator
    ) {
      setModalContent('noEstimator')
      this.setState({ checkedForEstimator: true })
    }
  }

  renderDownloadOptions = () => {
    const {
      version: { id, validated },
      openConfirmModal,
      projectId,
      permissions,
      islaproject,
      roles,
    } = this.props
    const { downloadType } = this.state

    return (
      <div className="project-summary__thirdrow__download">
        <Select
          small
          defaultValue="Quote"
          options={versionDownloadOptions(permissions, islaproject, roles)}
          width={200}
          onSelect={(value) => this.setState({ downloadType: value as string })}
        />
        <Button
          className="download-button"
          small
          icon="download"
          text="Download"
          onClick={() => {
            if (!validated && downloadType !== 'Legend' && downloadType !== 'MasterBOM' && downloadType !== 'Bend') {
              openConfirmModal({
                headerText: 'Download Unavailable',
                bodyText:
                  "The version needs to be validated before files can be downloaded.\nPlease go to 'Get Quote' and validate the price before trying again.",
                confirmButtonText: 'OK',
                cancelButton: false,
              })
            } else {
              this.props.downloadVersionFile(id, downloadType, projectId)
            }
          }}
        />
      </div>
    )
  }

  renderExpirationCalendar = (clname) => {
    const {
      updateInactivationDate,
      version: { activationperiods, isactive, islocked },
    } = this.props

    const expirationDate = moment(
      activationperiods.length > 0
        ? activationperiods[activationperiods.length - 1].inactivationdate
        : new Date()
    )
    const thirtyDaysLater = moment(expirationDate).add(30, 'days').format('YYYY-MM-DD')
    const disabled = true

    return (
      <div className={cn(clname, { disabled })}>
        <div className="text">Expiration Date:</div>
        {!isactive || islocked ? (
          <Icon
            icon="caution"
            style={{width:'0px'}}
            color={Colors.bourbon}
            svgStyle={{ position: 'absolute', right: '6px', width: '14px', top:'0px' }}
          />
        ) : null}
        <DatePicker
          allowClear={false}
          showToday={false}
          disabled={disabled}
          disabledDate={(current) => current && current < moment().endOf('day')}
          defaultValue={expirationDate}
          format="MM/DD/YYYY"
          value={expirationDate}
          onChange={(date, dateString) => {
            const selectedDate = moment(date).format('YYYY-MM-DD')
            updateInactivationDate(selectedDate)
          }}
          renderExtraFooter={() => {
            return (
              <button
                className="extend-30days"
                onClick={() => updateInactivationDate(thirtyDaysLater)}
              >
                +30 Days
              </button>
            )
          }}
        />
      </div>
    )
  }

  render() {
    const { versioncostsvalid, version, roles, userType, islaproject } = this.props
    const { project, collapsed } = this.state

    if (!version) return null

    const { index, isactive, islocked, netprice, validateddatetime, id: versionId } = version

    const collapseibleId = `collapse-${versionId}`

    const showProjectOwnerSelect = userType === 'Internal' && Array.isArray(roles) && roles.includes('Admin')
    const noCustomerId = !project?.customerid

    return (
      <div id="project-summary">
        <ExpiredMessage isActive={isactive} />
        <div className="project-summary__firstrow">
          { islaproject ? null : (
            <CollapseButton
              collapsed={collapsed}
              onClick={() => this.setState({ collapsed: !collapsed })}
              aria-controls={collapseibleId}
              aria-expanded={!collapsed}
            />
          )}

          <div className="project-summary__firstrow__name">{project?.name}</div>
          <div className="project-summary__firstrow__number">{project?.number}</div>
          <div className="project-summary__firstrow__version-number">v{index}</div>

          <div className="secondary-options-container">
            <SwitchVersionButton handleClick={() => this.props.setModalContent('versionSelect')} />
            <EditProjectDetailsButton
              hide={false}
              handleClick={() => this.props.setModalContent('projectDetails')}
            />
            <CollapseConveyorsButton versionId={versionId} />
            {project.isexternalproject ? this.renderExpirationCalendar('project-summary__firstrow__calendar') : null}
          </div>

          {islaproject ? (
              <div className="project-summary__firstrow__labom">
                <div className="project-summary__firstrow__labom-generation">
                  <GetLABOMButton
                    versionId={versionId}
                    projectId={project?.id}
                    versioncostsvalid={versioncostsvalid}
                    isactive={isactive}
                    islocked={islocked}
                  />
                </div>
                <div
                  className={"project-summary__firstrow__labom-generation-status" + (!versioncostsvalid ? "-invalid" : "")}
                  data-testid="validationmsg"
                >
                  {versioncostsvalid
                    ? `Last Generated on ${showDateTimeEST(validateddatetime)}`
                    : 'LA BOM needs to be (re)-generated'}
                </div>
              </div>
            ) : (
            <div className="project-summary__firstrow__price">
              <div className="project-summary__firstrow__price-validation">
                <ValidatePriceButton
                  versionId={versionId}
                  projectId={project?.id}
                  isactive={isactive}
                  islocked={islocked}
                />
                <div className="project-summary__firstrow__price-validation-est">
                  {versioncostsvalid ? displayValueOrDashes(netprice) : '$ - - -'}
                </div>
              </div>
              <div
                className="project-summary__firstrow__price-validation-status"
                data-testid="validationmsg"
              >
                {versioncostsvalid
                  ? `Last Validated on ${showDateTimeEST(validateddatetime)}`
                  : 'Estimate incomplete - price not validated'}
              </div>
            </div>
          )}
        </div>
        <div
          id={collapseibleId}
          hidden={collapsed}
          style={{ visibility: collapsed ? 'hidden' : 'visible' }}
        >
          {(
            <>
              {project.isexternalproject ? null : (
                <div className="project-summary__secondrow">
                  <ProjetStatusActions version={version} />
                  <div style={{ display: 'flex', marginRight: 'auto', paddingRight: '16px' }}>
                    <button
                      className="project-summary__secondrow__full-status-history"
                      onClick={() => this.props.setModalContent('statusHistory')}
                      onKeyDown={(e) => {
                        if (e.key === ' ' || e.key === 'Enter') {
                          this.props.setModalContent('statusHistory')
                        }
                      }}
                    >
                      <Icon icon="history" size={16} color={Colors.novaBlue} />
                      Status History
                    </button>
                    {project.isexternalproject ? null : this.renderExpirationCalendar('project-summary__secondrow__calendar')}
                  </div>
                  <p className="project-summary__lastmodified">
                    <strong>Last Modified:</strong>
                    <span>
                      {showDateTimeEST(project?.modifieddatetime || new Date().toISOString())}
                    </span>
                  </p>
                  {/* <div className='project-summary__secondrow__syteline-cost-date'>Syteline Cost Date: <span>{sytelineCostDate}</span></div> */}
                </div>
              )}
              <div className="project-summary__thirdrow">
                {this.renderDownloadOptions()}
                {versioncostsvalid ? (
                  <SytelineSyncButton
                    issyncedwithsyteline={project?.issyncedwithsyteline}
                    versionId={version.id}
                  />
                ) : null}
                {showProjectOwnerSelect && <div className='project-summary__firstrow__project-owner'>
                  {noCustomerId
                    ? <div className='project-summary__thirdrow__label'>Select customer to see/assign project owner</div>
                    :<ProjectOwnerButton
                      project={project}
                      version={version}
                    />
                  }
                </div>}
              </div>
            </>
          )}

          {/* <h3 className='project-summary__thirdrow-heading'>Price Validation</h3> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ projectId: string; versionId: string }>
) => {
  try {
    const { versionId } = props.match.params
    const { selectedProject, versions } = state.ProjectReducer
    const version = versions[versionId]
    const { versioncostsvalid } = state.VersionReducer.validatePrice
    return {
      permissions: state.UserReducer.permissions,
      islaproject: selectedProject.isexternalproject && selectedProject.externalprojecttype == 'la',
      project:selectedProject,
      versioncostsvalid,
      version,
      projectId:selectedProject.id,
      userType: state.UserReducer.userType,
      roles: state.UserReducer.roles,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  downloadVersionFile,
  openConfirmModal,
  setModalContent,
  updateInactivationDate,
  updateVersionConveyorsCosts,
  updateVersionStatus,
  getCustomerUsers,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>
const connectedProjectSummary = connector(ProjectSummary)

export default withRouter(connectedProjectSummary)
