import { AlertProps } from 'antd/lib/alert'
import StaticPartsRowStyle from 'features/Admin/components/StaticPartManagement/components/StaticPartsRowStyle'
import StaticPartsTable from 'features/Admin/components/StaticPartManagement/components/StaticPartsTable'
import useEditedState from 'features/Admin/components/StaticPartManagement/hooks/useEditedState'
import { UpdatePart } from 'features/Admin/components/StaticPartManagement/types'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, PartsTableLayout } from 'shared/components'
import { useAppDispatch } from 'shared/hooks/app'
import { setUnsavedStaticParts } from 'shared/redux/SettingsActions'
import { ScreenStateKey } from 'shared/types/Screen'
import { IPartsUpdateResponse } from 'shared/types/swagger'
import ListService from 'utils/api/list/ListService'
import { typedUseSelector } from 'utils/helpers'

const NON_NULL_FIELDS: ['description', 'partnumber', 'source'] = [
  'description',
  'partnumber',
  'source',
]

export default function StaticPartsScreen() {
  const dispatch = useAppDispatch()
  const unsavedStaticParts = typedUseSelector((state) => state.SettingsReducer.unsavedStaticParts)

  const [screenState, setScreenState] = useState<ScreenStateKey>('LOADING')
  const [data, setData] = useState<Record<string, IPartsUpdateResponse>>(null)
  const loading = ['LOADING', 'SAVING'].includes(screenState)
  const disableActions = ['ERROR', 'LOADING', 'SAVING'].includes(screenState)
  const updating = ['EDITED', 'EDITING', 'SAVING'].includes(screenState)
  const editedGuids = useEditedState(data, unsavedStaticParts)
  const tableDataObj = unsavedStaticParts || data || {}
  const tableData: Array<IPartsUpdateResponse> = Object.values(tableDataObj).sort((a, b) =>
    a.partnumber.localeCompare(b.partnumber)
  )
  const [errors, setErrors] = useState<Array<string>>(
    tableData.reduce((acc, cur) => {
      for (let i = 0; i < NON_NULL_FIELDS.length; i++) {
        const fieldKey = NON_NULL_FIELDS[i]
        if (!cur[fieldKey]) {
          acc.push(`${cur.partnumberguid}.${fieldKey}`)
        }
      }
      return acc
    }, [] as Array<string>)
  )

  useEffect(() => {
    (async () => {
      try {
        await getCurrentPartSettings()
      } catch (error) {
        console.log(error)
        setScreenState('ERROR')
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function getCurrentPartSettings() {
    const data = await ListService.getCurrentPartSettings()
    setData(data)
    setScreenState(unsavedStaticParts ? 'EDITING' : 'DEFAULT')
  }

  function cancelEdit() {
    dispatch(setUnsavedStaticParts(null))
    setErrors([])
    setScreenState('DEFAULT')
  }

  async function saveChanges() {
    if (!unsavedStaticParts) return
    if (errors.length > 0) {
      setScreenState('VALIDATING')
      return
    }
    try {
      setScreenState('SAVING')
      const payload = Object.values(unsavedStaticParts || {})
      const response = await ListService.updateCurrentPartSettings(payload)
      dispatch(setUnsavedStaticParts(null))
      setScreenState('EDITED')
      setData(response)
    } catch (error) {
      setScreenState('ERROR')
      console.log(error)
    }
  }

  function editParts() {
    dispatch(setUnsavedStaticParts(data))
    setScreenState('EDITING')
  }

  const updatePart = useCallback(
    debounce<UpdatePart>((id, key, value) => {
      const costs = unsavedStaticParts || data
      const errorId = `${id}.${key}`
      const hadError = errors.includes(errorId)
      if (!value && !hadError) {
        setErrors((_errors) => [..._errors, errorId])
      }
      if (value && hadError) {
        setErrors((_errors) => _errors.filter((errId) => errId !== errorId))
      }

      dispatch(
        setUnsavedStaticParts({
          ...costs,
          [id]: {
            ...costs[id],
            [key]: value,
            isupdated: value !== costs[id][key],
          },
        })
      )
    }, 200),
    [unsavedStaticParts, data]
  )

  const editing = ['EDITING', 'VALIDATING'].includes(screenState)

  return (
    <PartsTableLayout
      title="Static Part Management"
      showAlert={
        ['EDITED', 'ERROR'].includes(screenState) ||
        (screenState === 'VALIDATING' && errors.length > 0)
      }
      top={
        editing ? (
          <>
            <Button
              small
              antIcon="stop"
              secondary
              text="Cancel"
              onClick={cancelEdit}
              disabled={disableActions}
            />
            <Button
              small
              antIcon="save"
              text="Save Changes"
              onClick={saveChanges}
              disabled={disableActions}
            />
          </>
        ) : (
          <>
            <Button
              small
              text="Edit Parts"
              antIcon="edit"
              onClick={editParts}
              disabled={disableActions}
            />
          </>
        )
      }
      alertProps={
        (() => {
          if (screenState === 'EDITED') {
            return {
              message: false,
              type: 'success',
              showIcon: true,
              description: 'Static Parts Updated',
              closable: true,
              onClose: () => setScreenState('DEFAULT'),
            }
          } else if (screenState === 'ERROR') {
            return {
              message: 'Error Fetching or Updating Static Parts',
              type: 'error',
              showIcon: true,
              description: 'Try refreshing the page or contacting support',
            }
          } else if (screenState === 'VALIDATING' && errors.length) {
            return {
              message: 'Error Validating Static Parts',
              type: 'error',
              showIcon: true,
              description: 'Please fix all validations errors and try again',
              onClose: () => setScreenState('EDITING'),
            }
          }
          return {}
        })() as AlertProps
      }
    >
      <>
        <StaticPartsRowStyle updating={updating} editedKeys={editedGuids} />
        <StaticPartsTable
          screenState={screenState}
          updatePart={updatePart}
          loading={loading}
          data={tableData}
        />
      </>
    </PartsTableLayout>
  )
}
