import { Tooltip } from 'antd'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Icon } from 'shared/components'
import { setModalContent } from 'shared/redux/ScreenActions'
import './ChainPreview.scss'
import { captureSentryError } from 'utils/helpers'

class ChainPreview extends Component {
  renderNonStandardChainRowImages = () => {
    const { chain, chainpatterns } = this.props

    if (chain && chainpatterns.length) {
      return chainpatterns.map((chainpattern, i) => {
        const leftLink = chain.leftelements.find((el) => el.id === chainpattern.leftelementid)
        const rightLink = chain.rightelements.find((el) => el.id === chainpattern.rightelementid)
        const centerLinks = /** @type {import('shared/types/ChainPatternCenterElement').ChainPatternCenterElement[]}*/ ([
          ...chainpattern.centerelements,
        ])
          .sort((a, b) => a.position - b.position)
          .map((el, i) => {
            const centerLink = chain.centerelements.find((ele) => ele.id === el.elementid)
            const title =
              centerLink?.whitepartlabel?.replace?.(
                /{widthmm}/g,
                el?.manualwidthmm || centerLink.widthmm
              ) || 'N/A'
            return (
              <Tooltip key={i} title={title}>
                <img className="link" src={centerLink.image} alt={title} />
              </Tooltip>
            )
          })

        const rows = []
        for (let i = 0; i < chainpattern.repeat; i++) {
          rows.push(
            <div key={i} className="chain-preview__row-container">
              <Tooltip title={leftLink ? leftLink.whitepartlabel : 'N/A'}>
                {leftLink ? (
                  <img className="link" src={leftLink.image} alt={leftLink.whitepartlabel} />
                ) : (
                  <div className="link placeholder" />
                )}
              </Tooltip>
              {centerLinks ? centerLinks : null}
              <Tooltip title={rightLink ? rightLink.whitepartlabel : 'N/A'}>
                {rightLink ? (
                  <img className="link" src={rightLink.image} alt={rightLink.whitepartlabel} />
                ) : (
                  <div className="link placeholder" />
                )}
              </Tooltip>
            </div>
          )
        }
        return rows
      })
    } else {
      return <div className="no-rows">No Rows Available</div>
    }
  }

  render() {
    const { setModalContent } = this.props
    return (
      <div className="chain-preview">
        <div className="chain-preview__top-container">
          <h1>Chain Preview</h1>
          <Icon icon="x" size={30} onClick={() => setModalContent(null)} />
        </div>
        <div className="chain-preview__bottom-container">
          {this.renderNonStandardChainRowImages()}
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const conveyorId = state.ChainReducer.chainPreviewConveyorId
    const { chains } = state.ListReducer
    let chainpatterns = []
    let chainserieid = 6

    if (state.ConveyorReducer.conveyors.hasOwnProperty(conveyorId)) {
      chainpatterns = state.ConveyorReducer.conveyors[conveyorId].chain.chainpatterns
      chainserieid = state.ConveyorReducer.conveyors[conveyorId].chain.chainserieid
    }

    return {
      chain: chains[chainserieid],
      chainpatterns,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { setModalContent }

export default connect(mapStateToProps, mapDispatchToProps)(ChainPreview)
