import { createAction } from '@reduxjs/toolkit'
import store from '../../reduxStore'
import { history } from '../../App'
import { openConfirmModal } from '../../shared/redux/ScreenActions'
import axios from 'axios'
import { log } from '../helpers'
import { persistor } from 'index'

export const updateVersionCostsValid = createAction<boolean>('UPDATE_VERSION_COSTS_VALID')

// Header when 401 expired token: www-authenticate: Bearer error="invalid_token", error_description="The token expired at '02/11/2021 21:32:58'"

async function requestInterceptorSuccess(config) {
  log({ message: `[Req][${config.method.toUpperCase()}][${config.url}]`, params: config.params })
  const token = await localStorage?.getItem?.('token')
  if (token) config.headers.Authorization = `Bearer ${token}`

  const { exp } = store.getState().UserReducer
  if (exp && Date.now() > exp * 1000) {
    await persistor.purge()
  }

  return config
}

function requestInterceptorError(error) {
  if (error.config) {
    log({
      message: `[Req][${error.config.method.toUpperCase()}][${error.config.url}]`,
      params: '',
      error: true,
    })
  } else {
    log({ message: `Node error ${error}` })
  }
  return Promise.reject(error.request || new Error(error))
}
function responseInterceptorSuccess(config) {
  if (config.headers.popupmessage) {
    store.dispatch(
      openConfirmModal({
        headerText: 'Alert',
        bodyText: config.headers.popupmessage,
        cancelButton: false,
        confirmButtonText: 'OK',
      })
    )
  }
  if (config.headers.versioncostsvalid) {
    const versioncostsvalid = config.headers.versioncostsvalid === 'True'
    store.dispatch(updateVersionCostsValid(versioncostsvalid))
  }
  return config
}
function responseInterceptorError(error) {
  if (error?.config?.dontlog) return Promise.reject(error)
  if (error.config) {
    log({
      message: `[Err][${error.config.method.toUpperCase()}][${error.config.url}]`,
      params: '',
      error: true,
    })
  } else {
    log({ message: `Node error ${error}` })
  }
  if (error.status === 401) {
    if (!error?.config?.headers?.error_description.contains('token expired')) {
      store.dispatch(
        openConfirmModal({
          headerText: 'Unauthorized',
          bodyText: 'Could not complete action',
          cancelButton: false,
          confirmButtonText: 'OK',
        })
      )
    }
  } else if (error.status === 409 && window.location.pathname !== '/') {
    history.push('/')
    store.dispatch(
      openConfirmModal({
        headerText: 'Alert',
        bodyText: error.data.Message,
        cancelButton: false,
        confirmButtonText: 'OK',
      })
    )
  }
  return Promise.reject(error)
}

const isTest = process.env.NODE_ENV === 'test'

const axiosConfig = {
  baseURL: isTest ? `${process.env.REACT_APP_LOCAL_PROXY}/api` : '/api',
  headers: {
    'cache-control': 'no-cache',
  },
}

const instance = axios.create(axiosConfig)
instance.interceptors.request.use(requestInterceptorSuccess, requestInterceptorError)
instance.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError)

export default instance
