import { createSelector } from '@reduxjs/toolkit'
import { Store } from 'srcReducer'
import { isEZGuideOption } from '../helpers'

export const selectVersionId = (state: Store) => state.VersionReducer.versionId
export const selectOptions = (state: Store) => state.ConveyorReducer.options
const selectConveyorMeta = (state: Store) => state.ConveyorMetaReducer

export const selectVersionConveyorIds = createSelector(
  selectConveyorMeta,
  selectVersionId,
  (ConveyorMetaReducer, versionId) => Object.keys(ConveyorMetaReducer[versionId])
)

export const selectHasEZGuideOption = createSelector(selectOptions, (options) =>
  Object.values(options).some((option) => isEZGuideOption(option))
)
