import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Form } from 'antd'
import { Button, Icon, Input } from 'shared/components'
import changePasswordConfig from './changePasswordConfig'
import './ChangePassword.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { changePassword } from '../../redux/UserActions'
import PropTypes from 'prop-types'

/**
 * @extends Component<{setModalContent: function, changePassword: function, form: Object}>
 */
class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: true,
      passwords: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    }
  }

  static propTypes = {
    changePassword: PropTypes.func,
  }

  handleSubmit = () => {
    const { changePassword, form } = this.props
    const { oldPassword, newPassword } = this.state.passwords
    this.validateFields().then(() => changePassword(oldPassword, newPassword, form.setFields))
  }

  renderPasswordFields = () => {
    const { getFieldDecorator, setFieldsValue } = this.props.form
    const { passwords } = this.state
    return changePasswordConfig(passwords).map((input, i) => {
      return (
        <Form.Item key={i} label={input.label}>
          {getFieldDecorator(input.key, {
            rules: input.rules,
          })(
            <Input
              placeholder={input.placeholder}
              onChange={(e) => {
                this.setState({
                  passwords: {
                    ...passwords,
                    [input.key]: e,
                  },
                })
                setFieldsValue({ [input.key]: e })
                if (input.key.includes('new')) {
                  this.validateFields()
                }
              }}
              type="password"
            />
          )}
        </Form.Item>
      )
    })
  }

  validateFields = () => {
    const { validateFields } = this.props.form
    return new Promise((resolve, reject) => {
      validateFields((errors, values) => {
        if (errors) {
          this.setState({ error: true })
          reject()
        } else {
          this.setState({ error: false })
          resolve()
        }
      })
    })
  }

  render() {
    const { setModalContent } = this.props
    const { error, passwords } = this.state
    const disabled = _.includes(passwords, '') || error
    return (
      <div id="change-password">
        <h1 className="change-password__title">Change Password</h1>
        <Icon icon="x" size={30} onClick={() => setModalContent(null)} />
        <Form>{this.renderPasswordFields()}</Form>
        <div className="change-password__button">
          <Button
            className="cancel"
            small
            secondary
            text="Cancel"
            onClick={() => setModalContent(null)}
          />
          <Button small disabled={disabled} text="Change Password" onClick={this.handleSubmit} />
        </div>
      </div>
    )
  }
}

const ChangePasswordForm = Form.create({ name: 'change-password' })(ChangePassword)

const mapDispatchToProps = { changePassword, setModalContent }

export default connect(null, mapDispatchToProps)(ChangePasswordForm)
