import { FormConfigItem } from 'shared/types/formConfig'

const config: Array<FormConfigItem> = [
  {
    key: 'projectprefix',
    type: 'input',
    label: 'Default Project Number Prefix',
  },
  {
    key: 'spareprojectprefix',
    type: 'input',
    label: 'Spare Parts Project Number Prefix',
  },
  {
    key: 'customerserviceprefix',
    type: 'input',
    label: 'Customer Service Project Number Prefix',
  },
  {
    key: 'lauserprefix',
    type: 'input',
    label: 'LA Program Project Number Prefix',
  },
  {
    key: 'externaluserprefix',
    type: 'input',
    label: 'External User Project Number Prefix',
  },
  {
    key: 'deactivationafterdays',
    type: 'input',
    label: 'Project expiration in days',
    rules: [
      {
        pattern: /^[\d]*$/g,
        message: 'Project expiration must be an integer value.',
      },
    ]
  },
]

export default config
