import * as React from 'react'

const DOTS_MIN = 0
const DOTS_MAX = 3
const DELAY = 750

interface ILoadingConfig {
  min?: number
  max?: number
  delay?: number
}

export default function useLoadingDots(loading: boolean, config: ILoadingConfig = {}) {
  const { min = DOTS_MIN, max = DOTS_MAX, delay = DELAY } = config

  const [dots, setDots] = React.useState(min)
  const interval = React.useRef(null)

  React.useEffect(() => {
    if (loading) {
      interval.current = setInterval(() => {
        setDots((_dots) => {
          const newDots = _dots + 1
          if (newDots === max + 1) return min
          return newDots
        })
      }, delay)
    } else {
      setDots(min)
      if (interval.current) clearInterval(interval.current)
    }
  }, [delay, loading, max, min])

  return dots
}
