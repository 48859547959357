import React, { PureComponent } from 'react'
import cn from 'classnames'

/**
 * @typedef {import('react').RefObject} RefObject
 * @typedef {import('shared/redux/ScreenActions')['setNotesScreenPreferences']} setNotesScreenPreferences
 *
 * @typedef Props
 * @prop {"x"|"y"|"xy"} direction - xy is for top left only, to user botom right
 *    add a yx option and add the modifier class to ProjectNotes.scss
 * @prop {RefObject} containerElement
 * @prop {setNotesScreenPreferences} setPrefs
 */

/** @extends {PureComponent<Props & React.HTMLProps>} */
class ProjectNotesResizer extends PureComponent {
  state = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  }

  /** @param {MouseEvent} e */
  move = (e) => {
    const { containerElement, direction } = this.props
    const el = containerElement.current

    if (direction.includes('x')) {
      const { width, x } = this.state
      el.style.width = `${width - (e.clientX - x)}px`
    }

    if (direction.includes('y')) {
      const { height, y } = this.state
      el.style.height = `${height - (e.clientY - y)}px`
    }
  }

  /** @param {React.MouseEvent} e */
  handleMouseDown = (e) => {
    const { containerElement } = this.props
    const elStyle = containerElement?.current
      ? window.getComputedStyle(containerElement.current)
      : { width: '800', height: ((window?.innerHeight || 0) / 2).toString() }
    this.setState(
      {
        width: parseFloat(elStyle.width),
        height: parseFloat(elStyle.height),
        x: e.clientX,
        y: e.clientY,
      },
      () => {
        window.addEventListener('mousemove', this.move)
        window.addEventListener('mouseup', this.handleMouseUp)
      }
    )
  }

  /** @param {MouseEvent} e */
  handleMouseUp = (e) => {
    const { setPrefs, containerElement } = this.props
    const elStyle = window.getComputedStyle(containerElement.current)
    setPrefs({
      notesWidth: parseFloat(elStyle.width),
      notesHeight: parseFloat(elStyle.height),
    })
    window.removeEventListener('mousemove', this.move)
  }

  render() {
    const { className, direction } = this.props
    return (
      <div
        onMouseDown={this.handleMouseDown}
        className={cn('project-notes-resizer', `project-notes-resizer--${direction}`, className)}
      />
    )
  }
}

export default ProjectNotesResizer
