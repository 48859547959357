import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import _ from 'lodash'
import { setCanvasRotation, selectSection } from '../../redux/ConveyorBuilderActions'
import './BuilderCanvas.scss'
import { captureSentryError, typedUseSelector } from 'utils/helpers'
import { SVG_CONTAINER_CLASSNAME } from './constants'
import { Store } from 'reduxStore'
import { MeasuringUnit } from 'shared/types/MeasuringUnit'
import { BuilderCanvasSectionData } from './types/types'
import { ConveyorRenderer } from './components/'
import { prepareConveyorSectionsToSectionData } from './helpers/prepareConveyorSectionsForSvg'
import { selectConveyorSVGWidth } from '../../redux/selectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'

export interface BuilderCanvasProps extends PropsFromRedux {
  conveyorId: number;
}

class BuilderCanvas extends Component<
  BuilderCanvasProps,
  { sectionData: BuilderCanvasSectionData }
> {
  state = {
    sectionData: {} as BuilderCanvasSectionData,
  }

  componentDidMount() {
    this.registerSections()
  }

  componentDidUpdate(prevProps: BuilderCanvasProps, prevState) {
    const { conveyor, conveyorId, fullScreenId, rotateCanvas, shouldRender } = this.props
    if (
      conveyor !== prevProps.conveyor ||
      (fullScreenId !== prevProps.fullScreenId && fullScreenId === conveyorId) ||
      rotateCanvas !== prevProps.rotateCanvas
    ) {
      this.setState({ sectionData: {} })
    }

    // run registerSections when state.sectionData size has changed
    const sectionDataSizeChanged = Object.keys(prevState.sectionData).length !== Object.keys(this.state.sectionData).length
    const shouldRenderChange = (prevProps.shouldRender !== shouldRender) && shouldRender
    if (sectionDataSizeChanged || shouldRenderChange) {
      this.registerSections()
    }
  }

  registerSections = () => {
    if (!this.props.shouldRender) return
    prepareConveyorSectionsToSectionData({
      conveyorId: this.props.conveyorId,
      rotateCanvas: this.props.rotateCanvas,
      unit: this.props.unit,
      conveyor: this.props.conveyor,
      chainwidth: this.props.chainwidth,
      sectionData: this.state.sectionData,
      setSectionData: (sectionData) => this.setState({ sectionData }),
      conveyorWidth: this.props.conveyorWidth,
    })
  }

  handleRotateKeydown = (e: React.KeyboardEvent) => {
    e.stopPropagation()
    const { conveyorId, rotateCanvas } = this.props
    if (e.key === ' ' || e.key === 'Enter') {
      this.props.setCanvasRotation({ conveyorId, rotateCanvas: !rotateCanvas })
    }
  }

  render() {
    const { conveyorId, rotateCanvas, shouldRender } = this.props
    if (!shouldRender) return null
    return (
      <div className={SVG_CONTAINER_CLASSNAME}>
        <UncontrolledReactSVGPanZoom
          className="viewer"
          width="100%"
          height={window.innerHeight * 0.8}
          background="#101735"
          tool="auto"
          scaleFactorMax={20}
          scaleFactor={0.12}
          scaleFactorMin={0.02}
          miniatureProps={{ position: 'none' }}
          toolbarProps={{ position: 'none' }}
          onClick={(e) => {
            const selectedSectionId: string = e.originalEvent.target.id
            this.props.selectSection({
              conveyorId,
              selectedSectionId,
            })
          }}
        >
          <div>
            <svg id="builder" width="200000" height="200000" data-conveyorid={conveyorId}>
              <ConveyorRenderer sectionData={this.state.sectionData} conveyorId={conveyorId} />
            </svg>
          </div>
        </UncontrolledReactSVGPanZoom>
        <div
          role="button"
          className="rotate-canvas-button"
          onClick={() => this.props.setCanvasRotation({ conveyorId, rotateCanvas: !rotateCanvas })}
          onKeyDown={this.handleRotateKeydown}
        >
          {rotateCanvas ? 'View Vertical' : 'View Horizontal'}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: Store, props: RouteComponentProps<{ projectId: string; versionId: string }> & { conveyorId: number }) => {
  try {
    const { 
      conveyorId,      
      match: { params: { versionId } } 
    } = props
    const conveyor = state.ConveyorReducer.conveyors[conveyorId]
    const { chain, unit } = conveyor
    const { conveyorbuilder, currentTabIndex } = state.EstimatorMetaReducer[conveyorId]
    const { rotateCanvas, selectedSectionId } = conveyorbuilder
    const { currentlistsettings } = state.ProjectReducer
    const metalDetectorTypes = currentlistsettings.sections.metaldetectors.types
    const intermediateDriveOrientations = currentlistsettings.sections?.intermediate?.driveOrientation
    const conveyorWidth = selectConveyorSVGWidth(state, conveyorId)
    const conveyorMeta = state.ConveyorMetaReducer[versionId][conveyorId]
    const shouldRender = !conveyorMeta.collapsed && conveyorMeta.currentTab === 'estimator' && currentTabIndex === 2

    return {
      chainwidth: unit === ('Metric' as MeasuringUnit) ? chain.widthmetric : chain.widthenglish,
      conveyor,
      fullScreenId: null,
      metalDetectorTypes,
      rotateCanvas,
      shouldRender,
      selectedSectionId,
      unit,
      intermediateDriveOrientations,
      conveyorWidth,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { setCanvasRotation, selectSection }

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default withRouter(connector(BuilderCanvas))
