import React, { Component } from 'react'
export default class MinusIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="-2 -2 28 28"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-106.000000, -460.000000)">
            <g transform="translate(82.000000, 427.000000)">
              <g transform="translate(24.000000, 24.000000)">
                <g transform="translate(0.000000, 9.000000)">
                  <rect fill={color} x="0" y="0" width="24" height="24" rx="2"></rect>
                  <path
                    d="M6.72,11 L17.28,11 C17.677645,11 18,11.322355 18,11.72 L18,12.155 C18,12.552645 17.677645,12.875 17.28,12.875 L6.72,12.875 C6.32235498,12.875 6,12.552645 6,12.155 L6,11.72 C6,11.322355 6.32235498,11 6.72,11 Z"
                    fill="#FFFFFF"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
