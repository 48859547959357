import React from 'react'
import { DEFAULT_CONVEYOR_WIDTH, DEFAULT_PART_COLOR, SELECTED_PART_COLOR } from '../../../constants'
import { DriveTypeTitle, TransferTypeKey } from '../../../types/types'
interface Props {
  selected: boolean;
  typeKey: TransferTypeKey;
  anchorAngleDeg: number;
  driveboxLocation: 'Right' | 'Left';
  driveTypeTitle: DriveTypeTitle;
  width: number;
}

const SVG_HEIGHT = 641
const SVG_WIDTH = 1038
const SF_PER_CHAIN_WIDTH = 4.265

const translationMap = (
  widthChange: number
): Record<'Idler' | 'Drive', Record<TransferTypeKey, string>> => ({
  Idler: {
    infeed: '-779, -106',
    discharge: `612, ${-532 - widthChange}`,
  },
  Drive: {
    infeed: '-779, -106',
    discharge: `612, ${-532 - widthChange}`,
  },
})

export default function TransferGravityRollerSVG(props: Props) {
  const { selected, typeKey, anchorAngleDeg, driveTypeTitle, width } = props
  const isInfeed: boolean = typeKey === 'infeed'
  const widthChange = (width - DEFAULT_CONVEYOR_WIDTH) * SF_PER_CHAIN_WIDTH
  const strokeWidth = 4 * SF_PER_CHAIN_WIDTH
  const rotation = (isInfeed ? -90 : 90) - anchorAngleDeg
  const translation = translationMap(widthChange)[driveTypeTitle][typeKey]

  return (
    <g width={SVG_WIDTH} height={SVG_HEIGHT} viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}>
      <title>Transfer - Gravity Roller</title>
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g transform={`scale(${1 / SF_PER_CHAIN_WIDTH}), rotate(${rotation})`}>
          <g
            strokeWidth={strokeWidth}
            stroke={selected ? SELECTED_PART_COLOR : DEFAULT_PART_COLOR}
            transform={`translate(${translation})`}
          >
            <line y1={1} y2={1} x1={0} x2={1034}></line>
            <line y1={57} y2={57} x1={597} x2={1022}></line>

            <line y1={582 + widthChange} y2={582 + widthChange} x1={597} x2={1022}></line>
            <line y1={637 + widthChange} y2={637 + widthChange} x1={0} x2={1034}></line>

            {/* top, bottom bars */}
            <line x1={597} y1={2} x2={597} y2={636 + widthChange}></line>
            <line x1={1023} y1={2} x2={1023} y2={636 + widthChange}></line>

            {/* connection point */}
            <line x1={778} y1={106} x2={778} y2={532 + +widthChange}></line>

            {/* little crossbars */}
            <line x1={675} y1={2} x2={675} y2={56}></line>
            <line x1={733} y1={2} x2={733} y2={56}></line>
            <line x1={892} y1={2} x2={892} y2={56}></line>
            <line x1={950} y1={2} x2={950} y2={56}></line>
            <line x1={675} y1={582 + widthChange} x2={675} y2={636 + widthChange}></line>
            <line x1={733} y1={582 + widthChange} x2={733} y2={636 + widthChange}></line>
            <line x1={892} y1={582 + widthChange} x2={892} y2={636 + widthChange}></line>
            <line x1={950} y1={582 + widthChange} x2={950} y2={636 + widthChange}></line>
          </g>
        </g>
      </g>
    </g>
  )
}
