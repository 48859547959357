import { ISytelineResponse } from 'shared/types/swagger'
import { ALL_COST_IMPORT_ROWS } from '../constants'

export enum SCREEN_STATE {
  LOADING,
  DEFAULT,
  EDITING,
  SAVING,
  EDITED,
  ERROR,
}

export enum DOWNLOADING_STATES {
  DEFAULT,
  LOADING,
  SUCCESS,
  ERROR,
}

export type ScreenStateKey = keyof typeof SCREEN_STATE
export type DownloadingStateKey = keyof typeof DOWNLOADING_STATES

export interface ITempSyteline {
  calcdatetime?: number
  currenttotalcost?: number
  currentdutycost?: number
  currentfreightcost?: number
}

export type SytelineResponsePlus = ISytelineResponse & ITempSyteline

export type UpdateCost = (
  id: SytelineResponsePlus['id'] | typeof ALL_COST_IMPORT_ROWS,
  value: string | boolean,
  property: 'updatedcost' | 'dutycost' | 'freightcost' | 'excluded'
) => void
