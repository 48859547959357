export default function getInfoIconSVGPath(type: string) {
  switch (type) {
    case 'Angle':
      return 'M12.125,95.225h43.371c-0.66,1.761-1.43,3.548-2.283,5.391c-1.403,2.971-0.138,6.52,2.833,7.921			c0.825,0.385,1.679,0.577,2.559,0.577c2.228,0,4.373-1.265,5.391-3.41c1.705-3.603,3.08-7.096,4.125-10.479h28.877			c3.301,0,5.941-2.667,5.941-5.94c0-3.271-2.668-5.939-5.941-5.939H70.43c0.522-7.397-0.853-14.081-4.208-19.994			c-2.503-4.429-5.886-7.948-9.599-10.753l43.838-42.465c2.311-2.337,2.282-6.105-0.056-8.416c-2.337-2.312-6.105-2.283-8.415,0.055			l-46.04,44.691c-9.048-3.878-17.271-4.73-17.876-4.786c-3.273-0.302-6.161,2.063-6.491,5.335c-0.33,3.273,2.063,6.188,5.335,6.518			c0.083,0,4.456,0.496,9.874,2.23L7.888,85.104c-1.678,1.705-2.173,4.263-1.265,6.49C7.56,93.794,9.731,95.225,12.125,95.225z			 M48.208,61.149c3.025,2.091,5.776,4.758,7.673,8.113c2.256,3.987,3.107,8.69,2.586,14.054H26.342L48.208,61.149z'

    case 'Caution':
      return 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'

    case 'Length':
      return 'M0,10.369v358.083h135.641v-222.41h243.18V10.369H0z M113.123,345.928H22.516v-20.546h29.91v-22.516h-29.91v-22.512h52.422       V257.84H22.516v-22.521h29.91v-22.527h-29.91v-22.515h29.91v-22.512h-29.91v-21.723h90.607V345.928z M356.309,123.514h-20.517V93.6      h-22.514v29.914h-22.523V71.08h-22.547v52.435h-22.512V93.6h-22.52v29.914h-22.512V93.6h-22.512v29.914h-22.512V71.08h-22.514 v52.435h-22.516V93.6H88.096v29.914H65.553V93.6H43.027v29.914H22.516V32.897h333.793V123.514z'

    case 'Incline':
      return 'm192 0c-105.871094 0-192 86.136719-192 192s86.128906 192 192 192c105.863281 0 192-86.136719 192-192s-86.136719-192-192-192zm0 352c-36.175781 0-69.480469-12.214844-96.3125-32.535156l101.761719-42.402344 29.535156-12.300781 80.960937-33.738281 14.320313-5.824219-.050781-.128907 28.191406-11.742187c-10.476562 78.152344-77.429688 138.671875-158.40625 138.671875zm4.921875-159.382812 66.324219-27.640626 17.386718 42.757813-65.953124 27.480469zm113.246094 2.816406-29.414063-72.410156-125.675781 52.359374 30.066406 72.144532-115 47.914062c-23.738281-27.921875-38.144531-64.007812-38.144531-103.441406 0-88.222656 71.777344-160 160-160 83.601562 0 152.3125 64.472656 159.304688 146.289062zm0 0'

    case 'Decline':
      return 'm192 0c-105.871094 0-192 86.136719-192 192s86.128906 192 192 192c105.863281 0 192-86.136719 192-192s-86.136719-192-192-192zm0 352c-36.175781 0-69.480469-12.214844-96.3125-32.535156l101.761719-42.402344 29.535156-12.300781 80.960937-33.738281 14.320313-5.824219-.050781-.128907 28.191406-11.742187c-10.476562 78.152344-77.429688 138.671875-158.40625 138.671875zm4.921875-159.382812 66.324219-27.640626 17.386718 42.757813-65.953124 27.480469zm113.246094 2.816406-29.414063-72.410156-125.675781 52.359374 30.066406 72.144532-115 47.914062c-23.738281-27.921875-38.144531-64.007812-38.144531-103.441406 0-88.222656 71.777344-160 160-160 83.601562 0 152.3125 64.472656 159.304688 146.289062zm0 0'

    default:
      return 'M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'
  }
}
