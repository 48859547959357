import React, { PureComponent } from 'react'
import { Form, Button, Alert } from 'shared/components'
import { Row, Col, Skeleton } from 'antd'
import { isEmpty } from 'lodash'
import PCFInputRow from './components/PCFInputRow'

/**
 * @typedef {import('antd/lib/form').FormComponentProps} FormComponentProps
 * @typedef {import('../priceConfigFormConfig').PCFormConfigItem} PCFormConfigItem
 * @typedef {import('../priceConfigSectionsConfig').SectionConfig} SectionConfig
 *
 * @typedef {Object} Props
 * @prop {Object} values
 * @prop {function} handleSave
 * @prop {boolean} downloading
 * @prop {Array<PCFormConfigItem>} formConfig
 * @prop {SectionConfig} sectionsConfig
 * @prop {(newValues: {}, prevValues: {}) => {}} transformPayload
 */

/** @extends {React.PureComponent<Props & FormComponentProps>} */
class PriceConfigurationForm extends PureComponent {
  state = {
    saving: false,
    error: false,
    showAlert: false,
  }

  timeout

  componentDidUpdate(_, prevState) {
    const { saving, error } = this.state
    const prevSaving = /** @type {typeof this.state['saving']} */(prevState.saving)
    const changedSavingState = saving !== prevSaving
    if (changedSavingState && !saving && !error) {
      // show success alert
      this.setState({ showAlert: true }, () => {
        this.timeout = setTimeout(() => { this.setState({ showAlert: false })}, 8000)
      })
    } else if (changedSavingState && !saving && error) {
      // show error
      this.setState({ showAlert: true }, () => {
        this.timeout = setTimeout(() => { this.setState({ showAlert: false, error: false })}, 8000)
      })
    }
  }

  /** @param {string} category */
  renderPriceConfigurationFields = (category) => {
    const { formConfig, form, values } = this.props
    const { saving } = this.state

    if (!values || !Object.keys(values).length) return null
    const fields = formConfig.filter((val) => val.category === category)

    if (category === 'lmcosts') {
      return (
        <Row type="flex" gutter={16}>
          {fields.map((field) => {
            const { key, cols = 24, inputProps = {} } = field
            const data = Object.values(values?.[key] || {})
            return <React.Fragment key={key}>
              {data
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((lcs) => {
                const laborKey = `${lcs.id}-laborperhr`
                const machineKey = `${lcs.id}-machineperhr`
                const hrKey = `${lcs.id}-setupperhr`

                return (
                  <React.Fragment key={lcs.id}>
                    <PCFInputRow
                      form={form}
                      name={laborKey}
                      label={`${lcs.description} Labor Cost`}
                      value={lcs.laborperhr}
                      cols={cols}
                      disabled={saving}
                      inputProps={inputProps}
                    />
                    <PCFInputRow
                      form={form}
                      name={machineKey}
                      label={`${lcs.description} Machine Cost`}
                      value={lcs.machineperhr}
                      cols={cols}
                      disabled={saving}
                      inputProps={inputProps}
                    />
                    <PCFInputRow
                      form={form}
                      name={hrKey}
                      label={`${lcs.description} Setup Time`}
                      value={lcs.setupperhr}
                      cols={cols}
                      disabled={saving}
                      inputProps={{ ...inputProps, addonAfter: 'hr' }}
                    />
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          })}
        </Row>
      )
    } else {
      return (
        <Row type="flex" gutter={16}>
          {fields.map(this.renderPriceConfigurationField)}
        </Row>
      )
    }
  }

  renderPriceConfigurationField = (field) => {
    const { form, values } = this.props
    const { saving } = this.state
    const { key, label, cols = 24, inputProps = {} } = field
    const storedValue = values?.[key]?.value || values?.[key]

    return (
      <PCFInputRow
        form={form}
        name={key}
        label={label}
        value={storedValue}
        cols={cols}
        inputProps={inputProps}
        key={key}
        disabled={saving}
      />
    )
  }

  /** @type {React.FormEventHandler} */
  handleSave = (e) => {
    e.preventDefault()
    const { form, handleSave, values: prevValues, downloading, transformPayload } = this.props
    const { saving } = this.state
    const disableActions = isEmpty(prevValues) || downloading || saving
    if (disableActions) return

    form.validateFields((errors, values) => {
      if (errors) {
        this.setState({ error: true })
        return
      }
      try {
        if (this.timeout) clearTimeout(this.timeout)
        this.setState({ saving: true }, async () => {
          const payload = transformPayload ? transformPayload(values, prevValues) : values
          await handleSave(payload)
          this.setState({ saving: false })
        })
      } catch (error) {
        this.setState({ error: true, saving: false })
      }
    })
  }

  handleCancel = () => {
    const { form } = this.props
    form.resetFields()
  }

  render() {
    const { values, downloading, sectionsConfig } = this.props
    const { saving, error, showAlert } = this.state
    const disableActions = isEmpty(values) || downloading || saving
    const loading = !Object.keys(values).length

    return (
      <Form onSubmit={this.handleSave}>
        {sectionsConfig.map((sections, i) => {
          return <Row type="flex" gutter={16} key={i}>
            {sections.map((section) => (
              <Col sm={section.size} key={section.title}>
                <div className="pcf__section">
                  <h4 className="pcf__section-title">{section.title}</h4>
                  {loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> : this.renderPriceConfigurationFields(section.category)}
                </div>
              </Col>
            ))}
          </Row>
        })}
        {showAlert ? ( 
          <div className="pcf__alert">
            <Alert
              message={false}
              type={error ? 'error' : 'success'}
              showIcon
              description={error ? 'Error saving values.' : 'All values have been saved successfully.'}
              closable
              onClose={() => {
                if (this.timeout) clearTimeout(this.timeout)
                this.setState({ showAlert: false, error: false })
              }}
            />
          </div>
        ) : null}
        <div className="pcf__actions">
          <Button
            disabled={disableActions}
            small
            secondary
            text="Cancel"
            className="pcf__action-btn"
            onClick={this.handleCancel}
          />
          <Button disabled={disableActions} small type="submit" text="Save Changes" />
        </div>
      </Form>
    )
  }
}

/**
 * @type {React.ComponentClass<Pick<FormComponentProps & Props, any>, any>}}
 */
const connectedForm = Form.create({ name: 'price-config' })(PriceConfigurationForm)

export default connectedForm
