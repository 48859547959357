import _ from 'lodash'
import { formatIdAsKey } from 'utils/helpers'
import store from 'utils/../reduxStore'
import { IConveyorWithModifiedInfeedDischarge } from '../../features/Conveyor/shared/types'

export default function  formatNewConveyorSectionData(conveyorId, data) {
    return new Promise<IConveyorWithModifiedInfeedDischarge>((resolve, reject) => {
      const {
        conveyortorquemasterupdate: {
          infeed,
          discharge,
          conveyorsections,
          accumulatedtorque,
          cautiontorque,
          failtorque,
          requiredtorque,
          lengthwithunit
        },
      } = data
  
      let conveyor = _.cloneDeep(store.getState().ConveyorReducer.conveyors[conveyorId])
  
      conveyor = {
        ...conveyor,
        infeed: {
          ...conveyor.infeed,
          ...infeed,
        },
        discharge: {
          ...conveyor.discharge,
          ...discharge,
        },
        accumulatedtorque,
        cautiontorque,
        failtorque,
        requiredtorque,
        lengthwithunit,
      }
  
      const formattedConveyorSections = conveyorsections.map((s) => {
        return {
          ...conveyor.conveyorsections[s.id],
          ...s,
        }
      })
  
      conveyor.conveyorsections = formatIdAsKey(formattedConveyorSections)
      resolve(conveyor)
    })
  }