const Colors = {
  ash: '#9196A1',
  bourbon: '#DDA200',
  cobalt: '#28479B',
  cloud: '#F0EEEE',
  darkAsh: '#6B707D',
  deepNavy: '#0E183C',
  gold: '#FEBF10',
  lightCloud: '#F9F8F8',
  lightSilver: '#EEEEF0',
  mediumCloud: '#FAF9F9',
  navy: '#173261',
  novaBlue: '#006BB6',
  negative: '#DD1C1A',
  positive: '#39AA03',
  silver: '#C0C5CF',
  white: '#FFF',
} as const

export default Colors
