import { IRadioOption } from 'shared/components/Radio/types'
import { IListElement } from 'shared/types/swagger'
import { ListElementRecord } from 'utils/api/list/types'
import isVisible from './isVisible'
import sortListItems from './sortListItems'

export default function getRadioOptions(
  list: ListElementRecord,
  isExternal?: boolean,
  sortBy?: keyof IListElement
): Array<IRadioOption> {
  const values = Object.values(list)
  const sortedValues = sortListItems(values, sortBy)
  return sortedValues.map((listitem) => ({
    title: listitem.title,
    id: listitem.id,
    visible: isVisible(listitem, isExternal),
  }))
}
