import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

/**
 * @typedef {Object} UserState
 * @property {boolean} [authenticated=false]
 * @property {number} [exp]
 * @property {string} token
 * @property {string} firstName
 * @property {string} userId
 * @property {Array<string>} roles
 * @property {string} username
 * @property {string} email
 * @property {string} lastName
 * @property {string} userType
 * @property {Array<string>} permissions
 * @property {Array<string>} authorizedexternalprojecttypes
 * @property {boolean} issparepartuser
 */

/** @type {UserState} */
const INITIAL_STATE = {
  authenticated: false,
  exp: null,
  token: '',
  firstName: '',
  userId: '',
  roles: [],
  username: '',
  email: '',
  lastName: '',
  userType: '',
  permissions: [],
  authorizedexternalprojecttypes: [],
  issparepartuser: false,
}

export default createReducer(INITIAL_STATE, {
  LOGIN_SUCCESS: (state, action) => {
    return action.payload
  },
  [PURGE]: () => INITIAL_STATE,
})
