import React, { Component } from 'react'
export default class HomeIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <path
          fill={color}
          d="M25.7518578,27.1048342 L20.7314593,27.1048342 L20.7314593,17.0654152 C20.7314593,16.4946179 20.2688276,16.0319861 19.6980303,16.0319861 L12.3021228,16.0319861 C11.7313255,16.0319861 11.2686938,16.4946179 11.2686938,17.0654152 L11.2686938,27.1048342 L6.18077794,27.1048342 L6.25001768,9.98401469 L15.9997321,4.23332635 L25.8207531,10.0260408 L25.7518578,27.1048342 Z M31.4915229,10.970595 L27.5696595,8.65778071 C27.4587382,8.55168199 27.3233589,8.47245243 27.1735117,8.42422574 L16.5250585,2.14338828 C16.2005618,1.95220391 15.7989024,1.95220391 15.4747501,2.14338828 L0.50863021,10.970595 C0.0170624454,11.2606441 -0.146563825,11.8941361 0.143485269,12.3857039 C0.33639203,12.7126119 0.680868389,12.894151 1.03464561,12.894151 C1.21308436,12.894151 1.39393445,12.8479911 1.55893863,12.7508488 L4.17799239,11.2062168 L4.10978607,28.1341296 C4.10875264,28.4086772 4.21691821,28.6728906 4.4108584,28.8675197 C4.60479859,29.0624934 4.86832301,29.1716924 5.14321514,29.1716924 L12.2621636,29.1716924 C12.2755982,29.1723813 12.2883438,29.1758261 12.3021228,29.1758261 C12.8729202,29.1758261 13.3355519,28.7131943 13.3355519,28.142397 L13.3355519,18.0988443 L18.6646012,18.0988443 L18.6646012,28.142397 C18.6646012,28.7131943 19.1272329,29.1758261 19.6980303,29.1758261 C19.7118093,29.1758261 19.7245549,29.1723813 19.7379895,29.1716924 L26.7811532,29.1716924 C27.3505726,29.1716924 27.8121709,28.7114719 27.8145822,28.142397 L27.883133,11.2420424 L30.4412145,12.7508488 C30.9334712,13.0415869 31.5666187,12.8772716 31.8566678,12.3857039 C32.1463724,11.8941361 31.9830907,11.2606441 31.4915229,10.970595 L31.4915229,10.970595 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g mask="url(#mask-2)" fill="none">
            <rect x="0" y="0" width="32" height="32"></rect>
          </g>
        </g>
      </svg>
    )
  }
}
