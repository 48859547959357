const gearmotorFields = ['speed60or50hz', 'speed33hz', 'speed133hz', 'gearmotorrpm']

export default (props) => {
  const { legendFields, gearmotor } = props

  const defaultInput = (key, label, rest = {}) => ({
    key,
    prettyName: label,
    type: 'input',
    value: gearmotorFields.includes(key)
      ? gearmotor?.[key]?.toString?.() || ''
      : legendFields?.[key],
    // placeholder: `Enter ${ label }`,
    ...rest,
  })

  const defaultText = (key, label) => ({
    key,
    prettyName: label,
    type: 'text',
    value: gearmotorFields.includes(key) ? gearmotor?.[key] || '' : legendFields?.[key],
  })

  const defaultTextarea = (key, label, rest = {}) => ({
    key,
    prettyName: label,
    type: 'textarea',
    value: gearmotorFields.includes(key)
      ? gearmotor?.[key]?.toString?.() || ''
      : legendFields?.[key],
    // placeholder: `Enter ${ label }`,
    ...rest,
  })

  return [
    {
      sectionTitle: 'Customer',
      fields: [defaultInput('customernumber', 'Customer Number')],
    },
    {
      sectionTitle: 'Bed Sections',
      fields: [
        defaultInput('bidirectional', 'Bi-Directional'),
        // defaultInput('crossbraces', 'Cross Braces'),
        // defaultInput('returnchainsupport', 'Return Chain Supp'),
      ],
    },
    {
      sectionTitle: 'Drive & Idler',
      fields: [
        defaultInput('earsmodified', 'Ears Modified'),
        defaultInput('sprocketsmodified', 'Sprockets Modified'),
      ],
    },
    {
      sectionTitle: 'Infeed Transfer',
      fields: [
        defaultInput('transferondrive', 'Tfer On Drive'),
        defaultInput('transferonserialnumber', 'Tfer On Serial #'),
      ],
    },
    {
      sectionTitle: 'Discharge Transfer',
      fields: [
        defaultInput('transferoffdrive', 'Tfer Off Drive'),
        defaultInput('transferoffserialnumber', 'Tfer Off Serial #'),
      ],
    },
    {
      sectionTitle: 'Guiderails',
      fields: [
        defaultInput('tocto2ndrail', 'TOC to 2nd Rail'),
        defaultInput('topkeeper', 'Top Keeper'),
      ],
    },
    {
      sectionTitle: 'Floor Supports',
      fields: [
        defaultInput('infeedelevation', 'Infeed Elev.'),
        defaultInput('dischargeelevation', 'Discharge Elev.'),
      ],
    },
    {
      sectionTitle: 'Powertrain',
      fieldSize: 8,
      fields: [
        defaultInput('washdowndegree', 'Washdown Degree'),
        defaultInput('gearmotormounting', 'Gearmotor Mounting'),
        defaultInput('motorhz', 'Motor Hz'),
        defaultTextarea('gearmotornumber', 'Gearmotor #'),
        defaultInput('torque', 'Torque'),
        defaultInput('gearmotorspec', 'Motor HP'),
        defaultInput('gearmotorratio', 'Gearmotor Ratio'),
        defaultInput('gearmotorrpm', 'Gearmotor RPM'),
        defaultText('speed60or50hz', 'Speed @ 60 Hz'),
        defaultText('speed33hz', 'Speed @ 33% Hz'),
        defaultText('speed133hz', 'Speed @ 133% Hz'),
        defaultInput('controllermounting', 'Controller Mounting'),
        defaultInput('controllerwired', 'Controller Wired'),
        defaultInput('remotemount', 'Remote Mount'),
        defaultInput('remotemounttype', 'Remote Mount Type'),
        defaultInput('remotedrivensprk', 'Remote Driven Sprk'),
      ],
    },
  ]
}
