import React, { Component } from 'react'

import { Input, InputNumber, Form, Checkbox } from 'antd'
import { Select } from 'shared/components'

import _ from 'lodash'

const FormItem = Form.Item
export default (Context) =>
  class EditableCell extends Component {
    getInput = () => {
      switch (this.props.inputType) {
        case 'number':
          return <InputNumber />
        case 'select':
          return (
            <Select width="114px">
              {_.map(this.props.options, (o, i) => {
                return (
                  <Select.Option key={i} value={o.id}>
                    {o.title}
                  </Select.Option>
                )
              })}
            </Select>
          )
        case 'checkbox':
          return <Checkbox>{this.props.label}</Checkbox>
        default:
          return <Input />
      }
    }

    render() {
      const {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        required,
        index,
        preText,
        postText,
        ...restProps
      } = this.props
      const defaultValue =
        restProps && restProps.options
          ? _.find(restProps.options, (option) => option.isdefault)
          : null
      return (
        <Context.Consumer>
          {(form) => {
            const { getFieldDecorator } = form
            const { editable, render, rules, ...wantedProps } = restProps
            return (
              <td {...wantedProps}>
                {editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {getFieldDecorator(dataIndex, {
                      rules: [
                        {
                          required: required === undefined ? true : required,
                          message: `Please Input ${title}!`,
                        },
                        ...(rules ? rules : []),
                      ],
                      initialValue:
                        record[dataIndex] !== ''
                          ? record[dataIndex]
                          : defaultValue
                          ? defaultValue.value
                          : '',
                      ...(inputType === 'checkbox' && { valuePropName: 'checked' }),
                    })(this.getInput())}
                  </FormItem>
                ) : (
                  restProps.children
                )}
              </td>
            )
          }}
        </Context.Consumer>
      )
    }
  }
