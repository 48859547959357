import React, { Component } from 'react'
export default class CancelIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="1 1 22 22"
      >
        <path
          fill={color}
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
        ></path>
      </svg>
    )
  }
}
