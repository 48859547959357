import React, { useCallback, useState } from 'react'
import ModalContent from 'features/Modal/components/ModalContent'
import { setLoading, setModalContent } from 'shared/redux/ScreenActions'
import { selectUserId } from 'features/User/redux/UserSelectors'
import { submitProject } from '../../redux/ProjectOperations'
import './EstimatorModal.scss'
import { RequestStatus } from './types'
import IdleContent from './components/IdleContent'
import SuccessContent from './components/SuccessContent'
import ErrorContent from './components/ErrorContent'
import { useAppDispatch } from 'shared/hooks/app'
import { typedUseSelector } from 'utils/helpers'

export default function EstimatorModal() {
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState<RequestStatus>('idle')
  const userId = typedUseSelector(selectUserId)

  const close = useCallback(() => dispatch(setModalContent(null)), [dispatch])

  const addUserToProjectAsEstimator = useCallback(async () => {
    try {
      setStatus('pending')
      const response = await dispatch(submitProject({ payload: { estimatorid: userId } }))
      if ('error' in response) throw response
      setStatus('success')
    } catch (e) {
      setStatus('error')
    } finally {
      dispatch(setLoading(false))
    }
  }, [userId, dispatch])

  function renderContent() {
    switch (status) {
      case 'idle': return <IdleContent close={close} addUserToProjectAsEstimator={addUserToProjectAsEstimator} />
      case 'success': return <SuccessContent close={close} />
      case 'error': return <ErrorContent close={close} addUserToProjectAsEstimator={addUserToProjectAsEstimator} />
      default: return <></>
    }
  }

  // dispatch has dedicated loader (spinning gear w/ backdrop) during 'pending'
  if (status === 'pending') return <></>

  return (
    <ModalContent
      title='Assign an estimator'
      id="no-estimator"
      width='460px'
      height='auto'
    >
      {renderContent()}
    </ModalContent>)
}