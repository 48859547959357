import { formatIdAsKey } from 'utils/helpers'
import http from 'utils/api/Client'
import { AxiosResponse, CancelToken } from 'axios'
import type { IGeneralSettingsRequest, IProject, IQueueResponse } from 'shared/types/swagger'
import { ISearchResponseHeaders } from 'features/Project/shared/types'

export interface IRejectWithMessage extends Error {
  status: number
  message: string
  error: true
}

const ProjectService = {
  async cloneProject(projectId, cloneWithoutCustomer) {
    const { data } = await http.post<unknown, AxiosResponse<IProject>>(
      `/Projects/${projectId}/Clone${cloneWithoutCustomer ? 'ChangeCustomer' : ''}`,
      {}
    )
    return data
  },
  async createProject(
    payload: Partial<IProject>,
    config = {}
  ): Promise<Partial<IProject> | Promise<IRejectWithMessage>> {
    // Remove from here
    // await delay(1000)
    // const e = createError('testing', {}, '', {}, { status: 422, headers: { popupmessage: 'someprojectnumber' } })
    // throw e
    // to here when done testing
    const { data } = await http.post<Partial<IProject>, AxiosResponse<IProject>>(
      '/Projects',
      payload,
      config
    )
    return data
  },
  async deleteProject(projectId) {
    const { data } = await http.delete<unknown, AxiosResponse<boolean>>(`/Projects/${projectId}`)
    return data
  },
  async getProject(projectId) {
    return await http.get<IProject>(`/Projects/${projectId}`).then(({ data }) => data)
  },
  async getProjects(cancelTokenSource) {
    return await http
      .get('/Projects', { cancelToken: cancelTokenSource })
      .then(({ data }) => {
        return formatIdAsKey(data)
      })
      .catch((e) => {
        throw e
      })
  },
  async getQueue(cancelToken: CancelToken, pageNumber: number, filterTerm: string, currentstatusname: string) {
    try {
      const response = await http.get<Array<IQueueResponse>>(`/Queue?pagesize=30&page=${pageNumber}&columncontains=name,number,processflow,customernumber,creadtedbyname,estimatorname,designername,modifiedbyname,accountmamangername&contains=${filterTerm},${filterTerm},${filterTerm},${filterTerm},${filterTerm},${filterTerm},${filterTerm},${filterTerm},${filterTerm}&columnSearchOperator=Or&statusNameSearch=${currentstatusname}&columnsort=modifieddatetime&dirsort=desc`, {
        cancelToken: cancelToken,
      })
      const { data } = response
      const filterResults = data.map((d) => d.project)
      const paginationData = JSON.parse(
        response.headers?.['x-pagination'] || '{"totalpage": 1}'
      ) as ISearchResponseHeaders

      return {
        searchResults: filterResults,
        filterTerm,
        currentFilterPage: pageNumber,
        totalFilterPages: paginationData.totalpage,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async searchProjects(pageNumber: number, searchTerm: string) {
    return await http
      .get<Array<IQueueResponse>>(
        `/Projects/WithOneVersion?pagesize=30&page=${pageNumber}&columncontains=name,number,createdbyname,customernumber&contains=${searchTerm},${searchTerm},${searchTerm},${searchTerm}&columnsort=modifieddatetime&dirsort=desc&columnSearchOperator=Or`
      )
      .then((res) => {
        const searchResults = res.data.map((result) => result.project)
        const paginationData = JSON.parse(
          res.headers?.['x-pagination'] || '{"totalpage": 1}'
        ) as ISearchResponseHeaders

        return {
          searchResults: Object.values(formatIdAsKey(searchResults)),
          searchTerm,
          currentSearchPage: pageNumber,
          totalSearchPages: paginationData.totalpage,
        }
      })
  },
  async updateProject(projectId, payload) {
    return await http
      .put<IProject>(`/Projects/${projectId}`, payload)
      .then(({ data }) => ({ [data.id]: data }))
      .catch((e) => { 
        return null
      })
  },
  async generateProjectName(optionalexternaltype) {
    return await http.get('/Projects/GenerateProjectName'+ (optionalexternaltype !== null ? '?externalProjectType='+optionalexternaltype : '')).then(({ data }) => data)
  },
  async getProjectPrefix() {
    return await http.get('/Settings/ProjectPrefix').then(({ data }) => data)
  },
  async getGeneralSettings() {
    return await http.get<IGeneralSettingsRequest>('/Settings/GeneralSettings').then(({ data }) => data)
  },
  async updateGeneralSettings(body) {
    return await http
      .post<IGeneralSettingsRequest, AxiosResponse<IGeneralSettingsRequest>>('/Settings/GeneralSettings', body)
      .then(({ data }) => data)
  },
  async updateProjectPrefix(prefix) {
    return await http.post(`/Settings/ProjectPrefix/${prefix}`).then(({ data }) => data)
  },
  async getSparePartProjectPrefix() {
    return await http.get('/Settings/SparePartPrefix').then(({ data }) => data)
  },
  async updateSparePartProjectPrefix(prefix) {
    return await http.post(`/Settings/SparePartPrefix/${prefix}`).then(({ data }) => data)
  },
}

export default ProjectService
