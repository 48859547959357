import { MS_WORD_WIDTH, SVG_CONTAINER_CLASSNAME, SVG_G_SIZE_ID, SVG_NS, SVG_PADDING, SVG_PADDING_FIX, SVG_XLINK } from '../constants'
import Colors from 'shared/constants/colors'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import { IConveyorWithModifiedInfeedDischarge } from 'features/Conveyor/shared/types'

export async function getConveyorAndUploadSvgs(
  conveyors: Partial<Record<string, IConveyorWithModifiedInfeedDischarge>>
) {
  const blobs = Object.values(conveyors).map(c => getConveyorImageBlob(c))

  try {
    const promises = blobs.map(async (blob, index) => {
      const png = await SVGtoPNG(blob.file, blob.options)
      // downloadSVGImageFromBlob(blob.file, `${blob.conveyorId}-${index}.svg`)
      const form = new FormData()
      form.append(
        'ConveyorBuilderImage', 
        dataURLtoBlob(png), 
        `${blob.conveyorId}-conveyor-builder-preview.png`
      )
      return ConveyorService.uploadConveyorBuilderImage(blob.conveyorId, form)
    })

    return await Promise.all(promises)
  } catch (e) {
    if (e instanceof Error) {
      throw new Error('Upload failed')
    }
  }
}

export type SvgBlobObject = {
  conveyorId: number;
  file: Blob;
  options: {
    height: number;
    width: number;
  };
}

function getConveyorImageBlob(conveyor: IConveyorWithModifiedInfeedDischarge): SvgBlobObject {  
  const builder = document.querySelector(`svg#builder[data-conveyorid='${conveyor.id}']`)
  const svg = builder.cloneNode(true) as SVGSVGElement
  // NOTE: Fetching from document is important for gSizeContainer. 
  // gSizeContainer.getBoundingClientRect returns 0 if not from document
  const gSizeContainer = document.querySelector(`#${SVG_G_SIZE_ID}[data-conveyorid='${conveyor.id}']`) as SVGGElement
  // persist getBoundingClientRect's behavior
  const persistBoundingClientRect: DOMRect = persistRect(gSizeContainer.getBoundingClientRect())
  // normalize coordinates, only height and width are accurate and should be used as truth of sources
  const gSizeDimensions = normalizeRect(persistBoundingClientRect)
  const { width, height } = gSizeDimensions

  // related to section.setAttribute('transform') below
  // affects overall padding
    // SVGs
  const chainwidth = conveyor?.chain.widthenglish

  const padding = findPaddingSizeWithChainWidth(chainwidth)
  const adjustedHeight = height + padding
  const adjustedWidth = width + padding
  svg.setAttribute('xmlns', SVG_NS)
  svg.setAttribute('xmlns:xlink', SVG_XLINK)
  svg.setAttribute('width', adjustedWidth.toString())
  svg.setAttribute('height', adjustedHeight.toString())

  const strokeColor = Colors.deepNavy
  // used to reset all svgs to origin
  let firstSectionTranslateX: string
  let firstSectionTranslateY: string
  
  // sections from DOM
  const builderSections = builder.querySelectorAll('[id^=section]')
  const sectionRects: DOMRect[] = Array.from(builderSections)
    .map((section) => {
      const persistedRect: DOMRect = persistRect(section.getBoundingClientRect())
      return normalizeRect(persistedRect)
    })

  // sections from clone
  const sections = svg.querySelectorAll('[id^=section]')
    /** [[X, Y], [X,Y], ...] source of truth for section origins*/
  const sectionsTransform = [] as Array<[number, number]>
  /** cloned sections */
  sections.forEach((section: SVGGeometryElement, i) => {
    // shifts the sections back from 10000, related to adjustedHeight and adjustedWidth
    // affects top and left padding
    const transform = section.getAttribute('transform') ?? ''
    const [, x, y] = transform.match(/translate\(([^ ]*) ([^ ]*)\)/) ?? ['', null, null]
    if (x && y) {
      const translateX = `${(Number(x)) - SVG_PADDING}`
      const translateY = `${(Number(y)) - SVG_PADDING}`
      if (i === 0) { firstSectionTranslateX = translateX; firstSectionTranslateY = translateY }
      // reset origin anchor back to (0,0) by subtracting initial X and Y from all x and y's
      // adjust this to move anchor point w/ padding
      const sectionTranslateX = Number(translateX) - Number(firstSectionTranslateX)
      const sectionTranslateY = Number(translateY) - Number(firstSectionTranslateY)
      section.setAttribute('transform', `translate(${sectionTranslateX} ${sectionTranslateY})`)
      // memoize transformations for source of truth
      sectionsTransform.push([sectionTranslateX, sectionTranslateY])
    }
    // colors all parts in green
    const strokeParts = section.querySelectorAll('[stroke]')
    for (let i = 0; i < strokeParts.length; i++) {
      const strokePart = strokeParts[i]
      strokePart.setAttribute('stroke', strokeColor)
      if (strokePart.nodeName === 'path') strokePart.setAttribute('fill', strokeColor)
    }

    // color all rects to green
    const rects = section.querySelectorAll('rect')
    rects.forEach(el => { el.style.stroke = strokeColor })

    // all paths
    const paths = section.querySelectorAll('path')
    paths.forEach(el => { el.style.stroke = strokeColor })

    // removes colors
    section.setAttribute('fill', 'transparent')
  })

  // adjust new section container (GSizeContainer)
  const newGSizeContainer = svg.querySelector(`#${SVG_G_SIZE_ID}`)

  const { transformShiftX, transformShiftY } = findGTagTransformShift(padding, sectionsTransform, sectionRects)
  // cumulative shift to account for SVG moving in different directions
  // conveyor sections are combined by translations (seperate sections )
  newGSizeContainer.setAttribute('transform', `translate(${transformShiftX} ${transformShiftY})`)
  newGSizeContainer.setAttribute('data-getBoundingClientRect-height', height.toString())
  newGSizeContainer.setAttribute('data-getBoundingClientRect-width', width.toString())

  // add background color
  const rect = document.createElementNS('SVG_NS', 'rect')
  rect.setAttribute('fill', 'transparent')
  rect.setAttribute('height', '100%')
  rect.setAttribute('width', '100%')
  newGSizeContainer.prepend(rect)
  
  // create properly sized svg for fit in MS Word
  const outerSvg = document.createElementNS(SVG_NS, 'svg')
  const outerG = document.createElementNS(SVG_NS, 'g')
  const outerSvgWidth = MS_WORD_WIDTH.toString()
  const outerSvgHeight = ((adjustedHeight / adjustedWidth) * MS_WORD_WIDTH).toString()

  outerSvg.setAttribute('xmlns', SVG_NS)
  outerSvg.setAttribute('xmlns:xlink', SVG_XLINK)
  outerSvg.setAttribute('width', outerSvgWidth)
  outerSvg.setAttribute('height', outerSvgHeight)
  outerSvg.setAttribute('viewBox', `0 0 ${adjustedWidth.toString()} ${adjustedHeight.toString()}`)
  outerG.append(svg)
  outerSvg.appendChild(outerG)

  const content = `<?xml version="1.0" encoding="UTF-8"?>\r${outerSvg.outerHTML}`
  const svgBlob = new Blob([content], { type: 'image/svg+xml' })

  return {
    conveyorId:conveyor.id,
    file: svgBlob,
    options: {
      height: parseInt(outerSvgHeight),
      width: parseInt(outerSvgWidth)
    }
  }
}

export function downloadSVGImageFromBlob(blob: Blob, name?: string) {
  const svgUrl = URL.createObjectURL(blob)
  const downloadLink = document.createElement('a')
  downloadLink.href = svgUrl
  downloadLink.download = name ?? `ConveyorImage${new Date().toLocaleTimeString()}`
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

async function SVGtoPNG(svg: Blob, options: { height: number; width: number }): Promise<string> {
  return new Promise((res, rej) => {
    try {
      const img = document.createElement('img')
      const url = URL.createObjectURL(svg)
      img.src = url
      img.setAttribute('style', 'position:fixed;left:-200vw;')
      img.onload = function onload() {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const { width, height } = options
        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)
        const src = canvas.toDataURL('image/png')
        img.remove()
        URL.revokeObjectURL(url)
        res(src)
      }
      document.body.appendChild(img)
    } catch (e) {
      rej(e)
    }
  })
}

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

function normalizeRect(rect: DOMRect): DOMRect {
  return Object.keys(rect).reduce((acc, key) => {
        if (key === 'height' || key === 'width') 
          return {
            ...acc,
            [key]: rect[key]
          }
        return {
          ...acc,
          [key]: rect[key] - SVG_PADDING
        }
      }, {} as DOMRect)
}

function persistRect(rect: DOMRect): DOMRect {
  return {
    top: rect.top,
    left: rect.left,
    right: rect.right,
    bottom: rect.bottom,
    height: rect.height,
    width: rect.width,
    x: rect.x,
    y: rect.y,
    toJSON: () => undefined
  }
}

/** This expects freedom units (inches) */
function findPaddingSizeWithChainWidth(chain: number, defaultPadding = 900) {
  switch (true) {
    case chain <= 15:
      return defaultPadding*2
    case chain <= 30:
      return defaultPadding*3
    case chain <= 45:
      return defaultPadding*4
    case chain > 45:
      return defaultPadding*5
    default:
      return defaultPadding
  }
}

/** We are looking for the LEFT most and TOP most of the different sections
 * This is due to the way the section is created. 
 */
function findGTagTransformShift(padding: number, sectionsTransform: [number, number][], sectionRects: DOMRect[]) {
  let transformShiftX = padding/2
  let transformShiftY = padding/2

  // Most left and most top 
  /** [X, Y] */
  let leftMostSvgIndex = 0
  let topMostSvgIndex = 0
  // Max Left (X) and Max Top (Y)
  const minSection: [number, number] = [0, 0]
  sectionsTransform.forEach((sect, i) => {
    // most left
    if (sect[0] < minSection[0]) {
      minSection[0] = sect[0]
      leftMostSvgIndex = i
    }
    // most top
    if (sect[1] < minSection[1]){
      minSection[1] = sect[1]
      topMostSvgIndex = i
    }
  })

  // svg goes top and left
  if (minSection[1] < 0 && minSection[0] < 0) {
    // divide by two to accommodate for shift in 
    transformShiftX += Math.abs(minSection[0]) + (sectionRects[leftMostSvgIndex].width / 2)
    transformShiftY += Math.abs(minSection[1]) + (sectionRects[topMostSvgIndex].height / 2)
  }
  // svg goes left
  else if (minSection[0] < 0) {
    transformShiftX += Math.abs(minSection[0]) + (sectionRects[leftMostSvgIndex].width / 2)   
  }
  // svg goes top
  else if (minSection[1] < 0) {
    transformShiftY += Math.abs(minSection[1]) + (sectionRects[topMostSvgIndex].height / 2)
  }

  return {
    transformShiftX,
    transformShiftY
  }
}