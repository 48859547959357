import { MeasuringUnit } from 'shared/types/MeasuringUnit'
import getMultipartLength from '../../../helpers/getMultipartLength'
import { MM_IN_IN_RATIO } from '../constants'
import { ConveyorSectionWithStringId, IBuilderSectionData } from '../types/types'

export const magicalCalculation = (
    section: ConveyorSectionWithStringId,
    transformedPoint: DOMPoint,
    prevSection: Partial<IBuilderSectionData>,
    unit: MeasuringUnit
  ): IBuilderSectionData => {
    const { angle, id, incline, lengthinputmm, radius, type } = section
    const nextSectionAngle =
      prevSection.type !== 'HorizontalCurve'
        ? 0 + prevSection.anchorAngleDeg
        : prevSection.angle + prevSection.anchorAngleDeg
    return {
      id,
      anchorPosition: { x: transformedPoint.x, y: transformedPoint.y },
      anchorAngleDeg: nextSectionAngle,
      angle: angle || 0,
      radius: radius || 0,
      length: unit === 'Metric' ? lengthinputmm || 0 : getMultipartLength(section) * MM_IN_IN_RATIO,
      type,
      incline: incline || 0,
    }
  }