import React, { Component } from 'react'
import { Table, Form, Popconfirm, Tooltip } from 'antd'
import EditableTableCell from './EditableTableCell'
import { Button, Icon } from '..'
import _ from 'lodash'
import './EditableTable.scss'
import Colors from '../../constants/colors'

const EditableRow = (Context) =>
  function EditableRowConsumer({ form, index, ...props }) {
    return (
      <Context.Provider value={form}>
        <tr {...props} />
      </Context.Provider>
    )
  }

const EditableFormRow = (Context) => Form.create({ name: 'editable-row' })(EditableRow(Context))

class TableOption extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editingKey: '',
      localData: _.cloneDeep(props.data),
    }
  }

  componentDidMount = () => {
    if (Array.isArray(this.state.localData) && this.state.localData.length < 1) {
      this.addLocalRow()
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({
        localData: _.cloneDeep(this.props.data),
      })
    }
  }

  setEditingKey = (editingKey) => {
    this.setState({
      editingKey,
    })
  }

  cancel = () => {
    this.setEditingKey('')
  }

  edit = (key) => {
    this.setEditingKey(key)
  }

  getActions = () => ({
    title: 'Actions',
    dataIndex: 'operation',
    editable: false,
    viewPermission: 'all',
    render: (text, record) => {
      const editable = this.isEditing(record)
      const { context } = this.props
      return (
        <div>
          {editable || record.tempId ? (
            <span style={{ display: 'flex', position: 'relative' }}>
              <context.Consumer>
                {(form) =>
                  this.renderActionIcon('Save', 'save', () => {
                    this.save(form, record)
                  })
                }
              </context.Consumer>
              <Popconfirm
                title={`${
                  record.tempId
                    ? 'Are you sure you want to cancel before saving? This row will be removed.'
                    : 'Are you sure you want to cancel editing?'
                }`}
                onConfirm={() => {
                  if (record.tempId) {
                    // TODO: splice the row from local state, it was never saved
                    this.cancelWithoutSave(record)
                  } else {
                    this.cancel(record.key)
                  }
                }}
              >
                {this.renderActionIcon('Cancel', 'cancel')}
              </Popconfirm>
            </span>
          ) : (
            <span style={{ display: 'flex', position: 'relative' }}>
              {this.renderActionIcon('Edit', 'editLine', () => {
                if (this.props.editingKey === '' || this.props.editingKey === undefined) {
                  this.edit(record.id)
                }
              })}
              <Popconfirm
                title="Are you sure you want to delete this option?"
                onConfirm={() => {
                  if (record.tempId) {
                    // TODO: splice the row from local state, it was never saved
                    this.deleteLocalOnly(record)
                  } else {
                    this.props.onDeleteRowConfirm(record)
                    this.setEditingKey('')
                  }
                }}
              >
                {this.props.editingKey === '' || this.props.editingKey === undefined
                  ? this.renderActionIcon('Delete', 'delete')
                  : null}
              </Popconfirm>
            </span>
          )}
        </div>
      )
    },
  })
  // this determines wether a cell renders in edit or not
  // the editingKey in state is updated to match the id of the row being edited.
  isEditing = (record) => {
    return record.tempId || record.id === this.state.editingKey
  }

  renderActionIcon = (toolTip, iconType, onClick, record = null) => {
    const disabled = (toolTip === 'Edit' || toolTip === 'Delete') && this.state.editingKey !== ''
    return (
      <Tooltip title={toolTip}>
        <Icon
          icon={iconType}
          onClick={onClick}
          color={
            disabled
              ? Colors.ash
              : iconType === 'cancel' || iconType === 'delete'
              ? Colors.negative
              : Colors.novaBlue
          }
          size={15}
          style={{ marginRight: '3px' }}
          disabled={disabled}
        />
      </Tooltip>
    )
  }

  addLocalRow = async () => {
    const copy = _.cloneDeep(this.state.localData)
    const tempId = Date.now()
    const key = `${this.state.localData.tableDataPropName}-row-temp-${tempId}`
    const newRow = {}
    this.props.columnConfig.forEach((c) => {
      // Preconfigure default values for table
      if (c.key === 'bracket') {
        // Floor Support Brackets
        newRow[c.dataIndex] = _.map(c.options, (b) => b)[0].id
      } else if (c.key === 'bracketid') {
        // Ceiling Support Brackets
        newRow[c.dataIndex] = _.map(c.options, (b) => b)[1].id
      } else {
        newRow[c.dataIndex] = ''
      }
    })
    newRow.tempId = tempId
    copy.push(newRow)
    this.setState({
      editingKey: key,
      localData: copy,
    })
  }

  save(form, record) {
    form.validateFields(async (errors, rowData) => {
      if (errors) {
        this.props.onSaveValidationError(errors)
        return
      }
      const copy = _.cloneDeep(this.state.localData)
      const index = copy.findIndex((item) => {
        return record.tempId === item.tempId || record.id === item.id
      })
      const item = copy[index]
      if (item.tempId) {
        // This is for new rows being added. If you optimistically update, make sure
        // to delete the tempId and replace it with the id returned by the backend.

        try {
          this.props.onSaveNewRow({
            id: this.state.localData[index].id,
            ...rowData,
          })
          this.setEditingKey('')
        } catch (e) {
          this.props.onTableSaveError(e)
        }
      } else {
        try {
          this.props.onSaveRow({
            id: record.id,
            ...rowData,
          })
          this.setEditingKey('')
        } catch (e) {
          this.props.onTableSaveError(e)
        }
      }
    })
  }

  delete = async (record) => {
    await window.API.deleteSupport(this.props.conveyor.id, this.state.localData.apiPath, record.id)
    const newData = _.cloneDeep(this.state.localData)
    const index = newData.findIndex((item) => record.id === item.id)
    newData.splice(index, 1)
    this.setEditingKey('')
    this.props.setSupportData(this.state.localData.tableDataPropName, newData)
  }

  cancelWithoutSave = (record) => {
    const newData = _.cloneDeep(this.state.localData)
    const index = newData.findIndex((item) => record.tempId === item.tempId)
    newData.splice(index, 1)
    this.setEditingKey('')
    this.setState({
      localData: newData,
    })
  }

  getEditableColumns = () => {
    const { columnConfig, disabled } = this.props
    const columns = disabled ? columnConfig : [...columnConfig, this.getActions()]

    return columns.map((c) => {
      if (c.editable) {
        return {
          ...c,
          onCell: (record) => ({
            ...c,
            record,
            editable: true,
            editing: this.isEditing(record),
          }),
        }
      } else {
        return c
      }
    })
  }

  render() {
    const { disabled, style = {} } = this.props

    const components = {
      body: {
        row: EditableFormRow(this.props.context),
        cell: EditableTableCell(this.props.context),
      },
    }

    return (
      <div style={style}>
        <Table
          rowKey={(record) => record.id || record.tempId}
          editingKey={this.state.editingKey}
          components={components}
          pagination={{
            position: 'none',
          }}
          dataSource={this.state.localData}
          columns={this.getEditableColumns()}
          rowClassName="editable-row"
        />
        {disabled ? null : (
          <Button
            disabled={this.state.editingKey !== ''}
            onClick={this.addLocalRow}
            text="Add Support"
            small
            margin="10px 0 0 0"
          />
        )}
      </div>
    )
  }
}

export default TableOption
