import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'antd'
import { Icon } from 'shared/components'
import colors from 'shared/constants/colors'
import cn from 'classnames'
import { captureSentryError } from 'utils/helpers'

/**
 * @typedef {import('react-redux').ConnectedProps<connector>} SimpleLinkConnectProps
 *
 * @typedef {Object} OwnProps
 * @prop {Function} onClick
 * @prop {Function} onClickDelete
 * @prop {boolean} active
 *
 * @typedef {OwnProps & SimpleLinkConnectProps} Props
 */

/** @param {Props} props */
function SimpleLink(props) {
  const {
    link,
    onClick,
    onClickDelete,
    active,
    currentStep,
    originalPattern,
    bottomlink,
    className,
    disabled,
    isactive = false,
    islocked,
    item,
    linkTitle,
  } = props

  const [showX, setShowX] = useState(false)
  const handleClick = () => {
    if (active && onClickDelete) {
      onClickDelete(link, currentStep, originalPattern)
    } else if (bottomlink) {
      onClick(link, currentStep, originalPattern)
    }
  }

  if (disabled) return null
  const title =
    link?.whitepartlabel?.replace?.(/{widthmm}/g, item?.manualwidthmm || link.widthmm) || 'N/A'

  return (
    <Tooltip title={title} mouseEnterDelay={0.3}>
      <div
        className={cn('chainbuilder-link', className, { active, bottomlink, hovered: showX })}
        onMouseEnter={() => {
          if (isactive && !islocked && !['Left', 'Right'].includes(linkTitle)) {
            setShowX(true)
          }
        }}
        onMouseLeave={() => {
          setShowX(false)
        }}
        onClick={handleClick}
        role="button"
      >
        {link?.image ? (
          <img src={link.image} alt={title} />
        ) : (
          <div className="empty-side-link" title={title} />
        )}
        <div className={`${active ? 'remove' : 'add'}-link-icon`} hidden={!showX}>
          <Icon
            icon="x"
            size={12}
            color={active ? colors.negative : colors.novaBlue}
            svgStyle={{
              strokeWidth: '1px',
              stroke: active ? colors.negative : colors.novaBlue,
              transform: active ? 'none' : 'rotate(45deg)',
            }}
          />
        </div>
      </div>
    </Tooltip>
  )
}

/** @param {import('srcReducer').Store} state */
function mapStateToProps(state, props) {
  try {
    const { versionId } = props.match.params
    const { isactive, islocked } = state.ProjectReducer.versions[versionId]
    return {
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const connector = connect(mapStateToProps)

export default withRouter(connector(SimpleLink))
