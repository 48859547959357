import React, { Component } from 'react'
import { Icon } from '../'
import { IconProps } from 'antd'
import './Button.scss'
import Colors from '../../constants/colors'
import cn from 'classnames'

/**
 * @typedef {import('react').ButtonHTMLAttributes} ButtonHTMLAttributes
 * @typedef {Icon['props']['antIcon']} antIconKey
 *
 * @typedef {Object} Props
 * @prop {string} [className]
 * @prop {boolean} [danger]
 * @prop {boolean} [disabled]
 * @prop {boolean} [extraSmall]
 * @prop {keyof import('shared/components/Icon/iconsMap')['default']} [icon]
 * @prop {antIconKey} [antIcon]
 * @prop {string} [margin]
 * @prop {Function} onClick
 * @prop {boolean} [secondary]
 * @prop {boolean} [tertiary]
 * @prop {boolean} [small]
 * @prop {object} [style]
 * @prop {string|React.ReactChild} [text]
 * @prop {boolean} [uppercase]
 * @prop {string|number} [width]
 * @prop {boolean} [link]
 * @prop {'button'|'submit'|'reset'} [type]
 * @prop {string} [href]
 * @prop {IconProps} [antIconProps]
 *
 * @extends {Component<Props & ButtonHTMLAttributes>}
 */
export default class Button extends Component {
  static defaultProps = {
    className: '',
    danger: false,
    icon: '',
    margin: '',
    onClick: () => {},
    style: {},
    text: 'Button',
    type: 'button',
    width: 'auto',
    secondary: false,
    tertiary: false,
    disabled: false,
    extraSmall: false,
    small: false,
    uppercase: false,
    link: false,
  }

  render() {
    const {
      className,
      danger,
      disabled,
      extraSmall,
      icon,
      antIcon,
      margin,
      onClick,
      secondary,
      tertiary,
      small,
      style,
      text,
      uppercase,
      width,
      type,
      link,
      antIconProps,
      ...restProps
    } = this.props

    const themeClass = (() => {
      if (secondary) return 'secondary'
      if (tertiary) return 'tertiary'
      return 'primary'
    })()
    const classname = cn(
      'tm-button',
      `tm-button__${themeClass}`,
      { 'tm-button__icon': icon || antIcon },
      { disabled: disabled },
      { small: small },
      { 'extra-small': extraSmall },
      { uppercase: uppercase },
      { danger: danger },
      className
    )
    const renderIcon = icon || antIcon ? (
      <Icon
        icon={icon}
        antIcon={antIcon}
        color={Colors[tertiary ? 'novaBlue' : 'white']}
        size={tertiary ? 18 : 22}
        {...antIconProps}
      />
    ) : null

    if (link) {
      return (
        <a aria-disabled={disabled} className={classname} {...restProps}>
          {renderIcon}
          {text}
        </a>
      )
    }

    return (
      <button
        type={type}
        onClick={(e) => {
          if (!disabled) onClick(e)
        }}
        aria-disabled={disabled}
        style={{ margin, ...style, width }}
        className={classname}
        {...restProps}
        tabIndex={disabled ? -1 : 0}
      >
        {renderIcon}
        {text}
      </button>
    )
  }
}
