import React, { Component } from 'react'
export default class ExternalAltIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 18 22"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-457.000000, -546.000000)" fill="#173261">
            <g transform="translate(182.000000, 520.000000)">
              <g>
                <g transform="translate(272.000000, 23.000000)">
                  <g transform="translate(0.000000, 2.000000)">
                    <path
                      fill={color}
                      d="M6.93011304,6.75738696 L12.1875,1.5 L17.4447127,6.75721271 C17.7942233,7.10672328 17.7942139,7.67328994 17.4446917,8.0228121 C17.0951696,8.37233425 16.5286029,8.37234364 16.1790924,8.02283307 L13.082222,4.9259627 L13.0822997,12.9623078 C13.0822956,13.2095216 12.9820986,13.4333234 12.8203102,13.5951119 C12.6583108,13.7571113 12.4347199,13.8570973 12.1875061,13.8571014 C11.6932896,13.8573206 11.292734,13.456765 11.2927422,12.9623375 L11.2926645,4.92599236 L8.19569144,8.02296537 C7.84616929,8.37248753 7.27960263,8.37249692 6.93009206,8.02298635 C6.58058149,7.67347578 6.58059088,7.10690912 6.93011304,6.75738696 Z M4.83284754,9.92729537 C5.08710293,9.47625566 5.65885757,9.31673046 6.10989727,9.57098585 C6.56093698,9.82524124 6.72046218,10.3969959 6.46620679,10.8480356 C5.9173495,11.8216882 5.625,12.9211492 5.625,14.0652758 C5.625,17.6896445 8.56313133,20.6277758 12.1875,20.6277758 C15.8118687,20.6277758 18.75,17.6896445 18.75,14.0652758 C18.75,12.9212554 18.4577049,11.8218918 17.9089446,10.8483041 C17.6547104,10.3972525 17.8142625,9.82550532 18.2653142,9.57127115 C18.7163659,9.31703699 19.288113,9.47658909 19.5423472,9.92764076 C20.2484783,11.1804293 20.625,12.596581 20.625,14.0652758 C20.625,18.7251784 16.8474026,22.5027758 12.1875,22.5027758 C7.52759742,22.5027758 3.75,18.7251784 3.75,14.0652758 C3.75,12.5964444 4.12659178,11.1801671 4.83284754,9.92729537 Z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
