import {
  setAccessoryDataForConveyorSection,
  runUpdateMaterialOrAccessoryForConveyorSection,
} from '../../../../redux/SectionOverrideActions'
import { fetchDefaultAccessoryForConveyor } from '../../../../redux/EstimatorActions'

export const initializesectiondriptray = (driptrayData, sectionId) => async (dispatch) => {
  dispatch(
    setAccessoryDataForConveyorSection({
      sectionId,
      accessoryName: 'driptray',
      accessoryData: driptrayData,
    })
  )
}

export const updateDriptray = ({
  conveyorId,
  updatedDriptrayFields,
  sideName,
  sectionId,
}) => async (dispatch) => {
  await dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      sectionId,
      materialKey: 'driptray',
      updatedFields: updatedDriptrayFields,
      type: 'accessories',
      sideName,
    })
  )
  await fetchDefaultAccessoryForConveyor(conveyorId, 'driptray')
}
