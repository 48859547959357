import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SectionOverrideHeader } from '../../../shared/Headers'
import { updateDriptray } from '../../redux/sectionOverrides/DriptrayActions'
import {
  addMaterialOrAccessoryToConveyorSection,
  updateSectionOverrideMaterialAcessoriesMetaData,
} from '../../../../redux/SectionOverrideActions'
import { Radio } from 'antd'
import { captureSentryError } from 'utils/helpers'

class Driptray extends PureComponent {
  render() {
    const {
      addMaterialOrAccessoryToConveyorSection,
      conveyorId,
      sectionId,
      driptrayData,
      driptrayMeta = { open: false },
      updateDriptray,
      driptrayAccessoriesData,
      updateSectionOverrideMaterialAcessoriesMetaData,
    } = this.props
    const overrideDataExists = Boolean(driptrayData)
    const hasOverride = Boolean(driptrayData?.parameteractive)
    const { open } = driptrayMeta

    return (
      <div className="material-accessory-item">
        <SectionOverrideHeader
          showSingleCheckbox
          checked={driptrayData?.parameteractive || false}
          open={open}
          title="Drip Tray"
          type="accessories"
          conveyorId={conveyorId}
          fieldKey="driptray"
          onClickCheck={async (e) => {
            if (overrideDataExists) {
              const { checked } = e.target
              await updateDriptray({
                sectionId,
                conveyorId,
                updatedDriptrayFields: { parameteractive: checked },
              })
              if (checked) {
                await updateSectionOverrideMaterialAcessoriesMetaData({
                  conveyorId,
                  sectionId,
                  type: 'accessories',
                  name: 'driptray',
                  updatedFields: {
                    open: true,
                  },
                })
              }
            } else {
              await addMaterialOrAccessoryToConveyorSection({
                materialsOrAccessories: 'accessories',
                sectionId: sectionId,
                conveyorId: conveyorId,
                itemName: 'driptray',
              })
            }
          }}
          onClickOpen={() => {
            updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'accessories',
              name: 'driptray',
              updatedFields: {
                open: !open,
              },
            })
          }}
        >
          <div className="builder-tile__expanded__content__wrapper">
            <div className="radio-title">Drip Tray on this section?</div>
            <Radio.Group
              onChange={(e) => {
                const { value } = e.target
                const removedforsection = value.toString() !== 'true'
                updateDriptray({
                  sectionId,
                  conveyorId,
                  updatedDriptrayFields: { removedforsection },
                })
              }}
              defaultValue={
                hasOverride && driptrayData.parameteractive
                  ? (!driptrayData.removedforsection).toString()
                  : driptrayAccessoriesData?.parameteractive?.toString?.() || 'false'
              }
            >
              <Radio value="true" key="true">
                Yes
              </Radio>
              <Radio value="false" key="false">
                No
              </Radio>
            </Radio.Group>
          </div>
        </SectionOverrideHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, sectionId, match } = props
    const {
      params: { versionId },
    } = match

    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const driptrayData = EstimatorReducer?.sections?.[sectionId]?.accessories?.driptray
    const driptrayAccessoriesData = EstimatorReducer?.[conveyorId]?.accessories?.driptray
    const driptrayMeta = sectionMetadata?.materialsAccessoriesMetadata?.accessories?.driptray || {
      open: false,
    }

    if (!driptrayData) {
      return { loading: true, driptrayAccessoriesData }
    }

    return {
      versionId,
      conveyorId,
      sectionId,
      driptrayMeta,
      driptrayData,
      driptrayAccessoriesData,
      driptrayOptions: ProjectReducer.currentlistsettings.accessories.driptray,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateDriptray,
    addMaterialOrAccessoryToConveyorSection,
    updateSectionOverrideMaterialAcessoriesMetaData,
  })(Driptray)
)
