import React from 'react'

export default (props) => {
  const {
    floorsupportsOptions: { materials, mountinglocations, styles, brackets },
    floorsupportsData: {
      materialid: selectedMaterialId,
      supportmountingid: selectedSupportMounting,
    },
    isMetric,
  } = props

  return {
    materials: {
      dataKey: 'materialid',
      required: true,
      prettyName: 'Support Material',
      type: 'radio',
      vertical: true,
      rules: [
        {
          required: true,
          message: 'A Mounting Location is required.',
        },
      ],
      options: materials,
    },
    materialPreview: () => {
      return materials.find[selectedMaterialId]
    },
    supportmounting: {
      dataKey: 'supportmountingid',
      required: true,
      vertical: true,
      prettyName: 'Support Material',
      type: 'radio',
      margin: '0px 20px',
      rules: [
        {
          required: true,
          message: 'A Mounting Location is required.',
        },
      ],
      options: mountinglocations,
    },
    supportMountingPreview: () => {
      return mountinglocations[selectedSupportMounting].image
    },
    floorsupportsTable: {
      required: true,
      type: 'table',
      label: 'Floor Support Specifications',
      key: null,
      tableDataPropName: 'floorSupports',
      context: React.createContext(),
      apiPath: 'FloorSupports',
      columns: [
        {
          title: 'Quantity',
          key: 'quantity',
          dataIndex: 'quantity',
          editable: true,
          inputType: 'number',
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.quantity,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Quantity must be a number and greater than 0',
            },
          ]
        },
        {
          title: `Elevation (${isMetric ? 'mm' : 'in'})`,
          key: 'elevation',
          dataIndex: 'elevation',
          editable: true,
          inputType: 'number',
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.elevation,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Elevation must be a number and greater than 0',
            },
          ]
        },
        {
          title: 'Style',
          key: 'styleid',
          dataIndex: 'styleid',
          editable: true,
          inputType: 'select',
          options: styles,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: styles[row.styleid] ? styles[row.styleid].title : '',
            }
          },
        },
        {
          title: 'Bracket',
          key: 'bracket',
          dataIndex: 'bracket',
          editable: true,
          inputType: 'select',
          options: brackets,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: brackets[row.bracket] ? brackets[row.bracket].title : '',
            }
          },
        },
        {
          title: 'Locking Collars',
          key: 'lockcollars',
          dataIndex: 'lockcollars',
          editable: true,
          inputType: 'number',
          required: false,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.lockcollars,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Locking Collars must be a number and greater than 0',
            },
          ]
        },
        {
          title: `Common Width (${isMetric ? 'mm' : 'in'})`,
          key: 'chainwidth',
          dataIndex: 'chainwidth',
          editable: true,
          inputType: 'number',
          required: false,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.chainwidth,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Chain Width must be a number and greater than 0',
            },
          ]
        },
        ...([170, 171].includes(selectedMaterialId)
          ? [
              {
                title: 'Thick Walls',
                key: 'thickwalls',
                dataIndex: 'thickwalls',
                editable: true,
                inputType: 'checkbox',
                label: 'Yes',
                required: false,
                render: (text, row, index) => {
                  const yesOrNo = row?.thickwalls ? 'Yes' : 'No'
                  return {
                    children: row ? yesOrNo : 'N/A',
                  }
                },
              },
            ]
          : []),
      ],
    },
  }
}
