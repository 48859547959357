import booleanOptions from 'shared/constants/booleanOptions'
import getTransfersConfig from './getTransfersConfig'
import { DRIVE_SELECTION_DISABLED_REASON } from '../../helpers'
import { IInfeedDischargeConfigData } from '../../types'
import { FormConfigItem } from 'shared/types/formConfig'

export default function builderInfeedDischargeConfig(
  props: IInfeedDischargeConfigData,
  noneSelected: boolean
) {
  const {
    displayLowProfileIdler,
    displayTransfers,
    infeedDischargeList,
    item,
    isDrive,
    hasIntermediateDrive,
  } = props

  const transfersConfig = getTransfersConfig(props)

  const infeedDischargeConfig: Array<FormConfigItem> = [
    {
      key: 'feedtypeid',
      prettyName: `${item.type} Type`,
      type: 'select',
      width: '200px',
      placeholder: `Please select the ${item.type} type`,
      options: Object.values(infeedDischargeList?.types || {}).map((option) => ({
        label: option.title,
        value: option.id,
        ...(() => {
          if (option.title === 'Drive' && hasIntermediateDrive) {
            return {
              disabled: true,
              disabledReason: DRIVE_SELECTION_DISABLED_REASON.HAS_INTERMEDIATE,
            }
          }
          return {}
        })(),
      })),
      value: item.feedtypeid,
    },
    {
      key: 'warnings',
      type: 'warning',
      width: '200px',
      placeholder: 'Please fix the warnings',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (value != '') {
                throw new Error(value)
              } 
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      value: item.warnings.join('<br/>'),
    },
  ]

  if (!noneSelected) {
    infeedDischargeConfig.push(
      {
        key: isDrive ? 'feeddriveandidlertypeid' : 'feedidlertypeid',
        prettyName: `${isDrive ? 'Drive & ' : ''}Idler Type`,
        type: 'radio',
        rules: [
          {
            required: true,
            message: 'Please select a type.',
          },
        ],
        value: item[`feed${isDrive ? 'driveand' : ''}idlertypeid`],
        options: Object.values(
          infeedDischargeList?.[`${isDrive ? 'driveidlertypes' : 'idler'}`] || {}
        )
          .filter((option) => !(option.textvalue === 'LP' && !displayLowProfileIdler))
          .map((option) => ({
            value: option.id,
            label: option.title,
            image: option.image
              ? {
                  src: option.image,
                  alt: option.title,
                }
              : undefined,
          })),
      },
      {
        key: isDrive ? 'feeddriveorientationid' : null,
        prettyName: 'Drive Orientation',
        type: 'radio',
        rules: [
          {
            required: true,
            message: 'Please select an orientation.',
          },
        ],
        value: item.feeddriveorientationid,
        options: Object.values(infeedDischargeList?.drive || {})
          .filter((option) => {
            const selectedDriveIdlerType =
              infeedDischargeList?.driveidlertypes?.[item?.feeddriveandidlertypeid]?.textvalue
            return option.textvalue === selectedDriveIdlerType
          })
          .map((option) => ({
            value: option.id,
            label: option.title,
            image: option.image
              ? {
                  src: option.image,
                  alt: option.title,
                }
              : undefined,
          })),
      },
      {
        key: displayTransfers ? 'hastransfer' : null,
        prettyName: 'Transfers',
        type: 'checkbox',
        value: item.hastransfer,
        label: `Add a Transfer to ${item.type}`,
        options: booleanOptions,
      },
      {
        key: 'feedtierod',
        prettyName: isDrive ? 'Tierod for Drive' : 'Tierod for Infeed End',
        type: 'radio',
        rules: [
          {
            required: true,
            message: 'Please select a type.',
          },
        ],
        value: item.feedtierod,
        options: booleanOptions,
      },
      {
        key: 'angle',
        prettyName: 'Angle',
        type: 'input',
        chipText: '°',
        rules: [
          {
            transform: (value) => Number(value),
            validator: (rule, value, cb) => {
              try {
                const match = Boolean(/^[-]?\d+(?:\.\d+)?$/.exec(value))
                if (!match) {
                  throw new Error('Value must be a number.')
                }
                cb()
              } catch (error) {
                cb(error)
              }
            },
          },
        ],
        value: item.angle,
      },
      {
        key: isDrive ? 'feedtorquearmbracket' : null,
        type: 'checkbox',
        value: item.feedtorquearmbracket,
        label: 'Include Torque Arm Bracket',
        options: booleanOptions,
      }
    )
  }

  const transferIndex = infeedDischargeConfig.findIndex((c) => c.key === 'hastransfer')

  if (item.hastransfer && transferIndex > 0) {
    infeedDischargeConfig.splice(transferIndex + 1, 0, ...transfersConfig)
  }

  return infeedDischargeConfig
}
