import React, { Component } from 'react'
import { Radio as AntRadio, Form } from 'antd'
import _ from 'lodash'
import './Radio.scss'
import { IRadioOption } from './types'

/** @typedef {import('antd/lib/form').FormComponentProps} FormComponentProps */

/**
 * @typedef {object} Props
 * @prop {string} [className]
 * @prop {string} dataKey
 * @prop {*} initialValue
 * @prop {boolean} disabled
 * @prop {string} prettyName
 * @prop {string} margin
 * @prop {Function} onChange
 * @prop {Array<IRadioOption>} options
 * @prop {Array<Object>} [rules=[]]
 * @prop {Object} [style={}]
 * @prop {boolean} [vertical=false]
 * @prop {string|number} [width]
 * @prop {Object} [labelStyles]
 * @prop {import('react').Children} [children]
 * @prop {boolean|string} [tinyImages]
 * @prop {boolean} [doNotShowImages]
 * @prop {Object} [optionStyle]
 * @prop {Object} [titleStyles]
 *
 * @extends {Component<React.ComponentProps<'input'> & FormComponentProps & Props>}
 */

class Radio extends Component {
  componentDidMount() {
    const { validateFields } = this.props.form
    validateFields()
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, initialValue } = this.props
    const { resetFields } = form
    if (prevProps.initialValue !== initialValue) {
      resetFields()
    }
  }

  onChange = (e) => {
    const { form, onChange } = this.props
    const { setFieldsValue, validateFields } = form
    const { name, value } = e.target
    setFieldsValue({ [name]: value })
    validateFields((errors, values) => {
      onChange(value, errors, values)
    })
  }

  renderImage = (image, title, selected) => {
    const { disabled, tinyImages } = this.props

    return (
      <div
        key={`${title}-image`}
        className={`
          tm-radio__image
          ${disabled ? 'disabled' : ''}
          ${selected ? 'selected' : ''}
          ${tinyImages ? 'tiny' : ''}
        `}
      >
        <img
          src={image}
          alt={title}
          style={typeof tinyImages === 'string' ? { width: tinyImages, height: tinyImages } : {}}
        />
      </div>
    )
  }

  renderOptions = () => {
    const {
      dataKey,
      disabled,
      form,
      options = [],
      optionStyle = { fontWeight: 400 },
      tinyImages,
      doNotShowImages,
      titleStyles,
    } = this.props
    const { getFieldValue } = form
    const value = getFieldValue(dataKey)

    return _.map(options, (option, i) => {
      if (option.isdeleted) {
        console.log(option)
      }
      // Don't check undefined
      if (option.visible === false) return null
      if (option.isdeleted === true) return null
      
      const selected = option.id === value
      const image = option.image ? option.image : null
      const showImages = !doNotShowImages && image
      const radioInner = [
        <span className="title" style={{ ...(titleStyles || {}) }} key={option.title}>
          {option.title}
        </span>,
      ]
      if (showImages) {
        radioInner[tinyImages ? 'unshift' : 'push'](this.renderImage(image, option.title, selected))
      }
      return (
        <div key={i} className="tm-radio-option-wrapper">
          <AntRadio
            name={dataKey}
            disabled={disabled}
            style={optionStyle}
            value={option.id}
            className={showImages && !tinyImages ? 'tm-radio--haslrgimage' : undefined}
          >
            {radioInner}
          </AntRadio>
        </div>
      )
    })
  }

  render() {
    const {
      className = '',
      dataKey,
      initialValue,
      disabled,
      form,
      prettyName,
      margin = '',
      onChange,
      options,
      rules = [],
      style = {},
      vertical = false,
      width,
      labelStyles = {},
    } = this.props

    const { getFieldDecorator } = form
    return (
      <Form>
        <div className="tm-radio" style={{ margin }}>
          {prettyName ? (
            <div style={{ ...labelStyles }} className="tm-radio__label">
              {prettyName}
            </div>
          ) : null}
          <Form.Item>
            {getFieldDecorator(dataKey, {
              rules: options && !disabled ? rules : [],
              initialValue,
            })(
              <AntRadio.Group
                className={`
                  ${className ? className : ''}
                  ${disabled ? 'disabled' : ''}
                  ${vertical ? 'vertical' : ''}
                  `}
                name={dataKey}
                style={{ ...style, margin, width: width ? `${width}px` : 'auto' }}
                onChange={onChange ? this.onChange : null}
              >
                {this.renderOptions()}
              </AntRadio.Group>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }
}

/**
 * @type {React.ComponentClass<Pick<FormComponentProps & Props, any>, any>}
 */
const radioForm = Form.create({ name: 'radio' })(Radio)

export default radioForm
