import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'shared/components'
import UsersTable from 'features/Admin/components/UsersTable'
import './UserManagementScreen.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'

class UserManagementScreen extends Component {
  componentDidMount() {
    const { admin, history } = this.props
    if (!admin) {
      history.push('/')
    }
  }

  render() {
    const { setModalContent } = this.props

    return (
      <div id="user-management">
        <div className="user-management__title">
          <h1>Internal User Management</h1>
          <Button
            small
            data-testid="openummodal"
            text="Add a New Internal User"
            onClick={() => setModalContent('addUser')}
          />
        </div>
        <UsersTable />
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      admin: state.UserReducer.roles.includes('Admin'),
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { setModalContent }

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementScreen)
