import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Spin, Icon } from 'antd'
import {
  Underguard,
  Driptray,
  FixedEndstop,
  GuideRails,
  Sidewalls,
  FloorSupports,
  CeilingSupports,
} from './components'
import { captureSentryError } from 'utils/helpers'

class Accessories extends Component {
  render() {
    const { fetchingInitialAccessoriesData, conveyorId, isLAProject } = this.props    

    return (
      <div className="accessories-container">
        {fetchingInitialAccessoriesData ? (
          <Spin
            style={{ marginTop: '5px' }}
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        ) : (
          <>
            <GuideRails conveyorId={conveyorId} />
            {/* <Underguard conveyorId={conveyorId} /> */} {/** Removed as of 6/7 per Dave's request */}
            { isLAProject ? null : (<Driptray conveyorId={conveyorId} />)}
            { isLAProject ? null : (<Sidewalls conveyorId={conveyorId} />)}
            { isLAProject ? null : (<FixedEndstop conveyorId={conveyorId} />)}
            { isLAProject ? null : (<FloorSupports conveyorId={conveyorId} />)}
            <CeilingSupports conveyorId={conveyorId} />
          </>
        )}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, ProjectReducer } = state
    const {
      conveyorId,
      match: {
        params: { versionId, projectId },
      },
    } = props

    const isLAProject = ProjectReducer.currentexternalprojecttype == 'la'

    if (!EstimatorReducer[conveyorId] || !EstimatorReducer[conveyorId].accessories) {
      return {
        fetchingInitialAccessoriesData: true,
      }
    }
    return {
      projectId,
      versionId,
      conveyorId,
      isLAProject
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(connect(mapStateToProps, null)(Accessories))
