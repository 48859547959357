import * as React from 'react'
import cn from 'classnames'
import { Radio } from 'antd'
import radioStyle from '../../../../builderInfeedDischargeConfig/radioStyle'
import { FormItemOption } from 'shared/types/formConfig'

type Props = FormItemOption & { selectedOptionValue: FormItemOption['value'] }
export default function BuilderTileRadioOption(props: Props) {
  const { image, value, label, selectedOptionValue } = props
  return (
    <div className="builder-radio">
      <Radio style={radioStyle} value={value}>
        <div className="builder-radio__label">{label}</div>
        {image ? (
          <div
            className={cn('builder-radio__image', {
              selected: value === selectedOptionValue,
            })}
          >
            <img src={image.src} alt={image.alt} />
          </div>
        ) : null}
      </Radio>
    </div>
  )
}
