import { ColumnProps } from 'antd/lib/table'
import { IVersion } from 'shared/types/swagger'
import { formatCurrency, ifDecimalRoundToNum } from 'utils/helpers'

interface IBreakdownData {
  'project-total-breakdown': string
  avgmuf: number
  cost: number
  discountpercentage: number
  listprice: number
  netprice: number
}

type ConfigProps = { permissions: Array<string> }
type ColumnPropsWithVisible = ColumnProps<IBreakdownData> & { visible: boolean }

export const projectTotalBreakdownConfig = (
  props: ConfigProps
): Array<ColumnProps<IBreakdownData>> => {
  const { permissions } = props
  const projectTotalConfig: Array<ColumnPropsWithVisible> = [
    {
      title: 'Project Total Breakdown',
      dataIndex: 'project-total-breakdown',
      key: 'project-total-breakdown',
      visible: true,
      align: 'left',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      visible: permissions.includes('view_cost'),
      render: (value) => {
        return {
          children: formatCurrency(value),
        }
      },
      align: 'right',
    },
    {
      title: 'Average MUF',
      dataIndex: 'avgmuf',
      key: 'muf',
      visible: permissions.includes('view_muf'),
      render: (value) => {
        return {
          children: ifDecimalRoundToNum(value),
        }
      },
      align: 'right',
    },
    {
      title: 'List Price',
      dataIndex: 'listprice',
      key: 'listprice',
      visible: true,
      render: (value) => {
        return {
          children: formatCurrency(value),
        }
      },
      align: 'right',
    },
    {
      title: 'Discount',
      dataIndex: 'discountpercentage',
      key: 'discountpercentage',
      visible: permissions.includes('view_discountpercentage'),
      render: (value) => {
        return {
          children: formatCurrency(value),
        }
      },
      align: 'right',
    },
    {
      title: 'Price',
      dataIndex: 'netprice',
      key: 'netprice',
      visible: true,
      render: (value) => {
        return {
          children: formatCurrency(value),
        }
      },
      align: 'right',
    },
  ]

  return projectTotalConfig.filter((c) => c.visible)
}

export const projectTotalBreakdownData = (props: { version: IVersion }): Array<IBreakdownData> => {
  const { avgmuf, cost, discountpercentage, listprice, netprice } = props.version
  return [
    {
      'project-total-breakdown': '',
      avgmuf,
      cost,
      discountpercentage: listprice * (discountpercentage / 100),
      listprice,
      netprice,
    },
  ]
}
