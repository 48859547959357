import _ from 'lodash'
import React, { Component } from 'react'
import { Select } from 'shared/components'

class AvailableLinks extends Component {
  state = {
    selectedCategory: null,
  }

  componentDidMount() {
    const { currentStep, selectedChainSeries } = this.props

    const defaultCategory = selectedChainSeries[`${currentStep}elementdefaultcategory`]

    if (defaultCategory !== '') {
      this.setState({
        selectedCategory: defaultCategory,
      })
    } else {
      const cat = selectedChainSeries[`${currentStep}elements`][0]
      this.setState({
        selectedCategory: cat ? cat.category : '',
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentStep, selectedChainSeries } = this.props

    const prevStep = prevProps.currentStep

    if (prevStep !== currentStep) {
      const defaultCategory = selectedChainSeries[`${currentStep}elementdefaultcategory`]
      if (defaultCategory !== '') {
        this.setState({
          selectedCategory: defaultCategory,
        })
      } else {
        const firstLink = selectedChainSeries[`${currentStep}elements`][0]
        this.setState({
          selectedCategory: firstLink ? firstLink.category : '',
        })
      }
    }
  }

  getCatergoryOptions = (links = []) => {
    const linksByCategory = {}

    links.forEach((link) => {
      if (!linksByCategory[link.category]) {
        linksByCategory[link.category] = []
      }
    })

    _.forEach(linksByCategory, (val, key) => {
      links.forEach((l) => {
        if (l.category === key) {
          linksByCategory[key].push(l)
        }
      })
    })
    return !Object.keys(linksByCategory).length ? [] : linksByCategory
  }

  render() {
    const { currentStep } = this.props
    const categoryOptions = this.getCatergoryOptions(
      this.props.selectedChainSeries[`${currentStep}elements`]
    )

    return (
      <div className="availablesection" key={`ogele-${currentStep}-${this.props.rowIndex}`}>
        <h2>Series {currentStep.charAt(0).toUpperCase() + currentStep.substr(1)} Links</h2>
        <Select
          onSelect={(e) => this.setState({ selectedCategory: e })}
          options={Object.keys(categoryOptions).map((cgo) => ({ value: cgo, label: cgo }))}
          small
          width={300}
          value={this.state.selectedCategory}
          placeholder="Select Link Category"
        />
        <div className="availablelinks-grid-container">
          {_.map(categoryOptions[this.state.selectedCategory], (ele, i) => {
            return this.props.render(ele, i)
          })}
        </div>
      </div>
    )
  }
}

export default AvailableLinks
