import _ from 'lodash'
import { FormConfigItem } from 'shared/types/formConfig'
import { IExternalUserReduced } from 'shared/types/user'
import { DerivedUser } from './helpers/getDerivedUser'

export default (
  user: IExternalUserReduced,
  { contacts, customers, contactid }: DerivedUser,
  password: string
): FormConfigItem[] => [
  {
    key: 'customerid',
    type: 'select_search',
    width: '529px',
    label: 'Select an Existing Customer',
    rules: [
      {
        required: true,
        message: 'At least one Customer is required.',
      },
    ],
    value: user?.customerid,
    options: _.take(
      _.map(customers, (customer) => ({
        value: customer.id,
        label: customer.name,
      })),
      50
    ),
    onSelect: (value: string) => ({ key: 'customerid', value: customers[value] }),
  },
  {
    key: 'contactid',
    type: 'select',
    width: '529px',
    label: 'Select an Existing Customer Contact',
    placeholder: 'Type to Search',
    rules: [
      {
        required: true,
        message: 'At least one Contact is required.',
      },
    ],
    options: _.map(contacts, (contact) => ({
      value: contact.id,
      label: `${contact?.firstname || ''} ${contact.lastname}`.trimStart(),
    })),
    value: contactid,
  },
  // {
  //   key: 'username',
  //   type: 'input',
  //   width: 'calc(33.33% - 25px)',
  //   label: 'Username',
  //   placeholder: 'Enter Username',
  //   value: user.username,
  // },
  {
    width: 'calc(33.33% - 25px)',
    type: 'radio',
    key: 'projecttype',
    label: 'Allowed project type',
    options: [
      { label: 'Regular', value: 'regular' },
      { label: 'LA Program', value: 'la' },
      { label: 'Both', value: 'all' },
    ],
    value: user.laproject ? (user.regularproject ? 'all' : 'la') : 'regular',
  },
  {
    key: 'password',
    type: 'input',
    width: 'calc(33.33% - 25px)',
    label: 'Change User Password',
    placeholder: 'Enter New Password',
    rules: [
      {
        required: password !== '',
        validator: (rule, value = '', cb) => {
          try {
            if (value !== '' && value.length < 4) {
              throw new Error('Password must have at least 4 characters.')
            }
          } catch (error) {
            cb(error)
          }
          cb()
        },
      },
    ],
    value: '',
  },
  {
    key: 'password_confirm',
    type: 'input',
    width: 'calc(33.33% - 25px)',
    label: 'Confirm Password',
    placeholder: 'Re-enter New Password',
    rules: [
      {
        required: password !== '',
        validator: (rule, value, cb) => {
          try {
            if (password !== '') {
              if (value === '' || value === undefined) {
                throw new Error('Please re-enter the new password.')
              } else if (value !== password) {
                throw new Error('Passwords must match.')
              }
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
]
