import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { PURGE } from 'redux-persist'
import { IGearmotor, IPriceValidation, IVFDSelection } from 'shared/types/swagger'
import {
  DischargeWithTransfer,
  IConveyorWithModifiedInfeedDischarge,
  InfeedWithTransfer,
} from '../shared/types'

export interface IConveyorState {
  conveyors: Partial<Record<string, IConveyorWithModifiedInfeedDischarge>>;
  VFDOptions: Partial<Record<string, IVFDSelection[]>>;
  options: Partial<Record<string, IPriceValidation>>;
  optionsOrder: Array<IPriceValidation['id']>;
}

const INITIAL_STATE: IConveyorState = {
  conveyors: {},
  options: {},
  optionsOrder: [],
  VFDOptions: {},
}

export default createReducer(INITIAL_STATE, {
  RESET_CONVEYOR_STORE: (state, action) => {
    state.conveyors = {}
    state.options = {}
  },
  SAVE_VERSION_CONVEYORS: (state, action) => {
    state.conveyors = action.payload
  },
  SAVE_VERSION_OPTIONS: (state, action) => {
    state.options = action.payload
  },
  SAVE_VERSION_OPTIONS_ORDER: (state, action) => {
    state.optionsOrder = action.payload
  },
  DELETE_ONE_FROM_VERSION_OPTIONS_ORDER: (state, action) => {
    const index = state.optionsOrder.findIndex(id => id === action.payload)
    if (index !== -1) state.optionsOrder.splice(index, 1)
  },
  SAVE_CONVEYOR_TO_CONVEYORS: (state, action) => {
    const { discharge, infeed, id } = action.payload
    state.conveyors[id] = {
      ...action.payload,
      infeed: {
        ...infeed,
        transfer: state.conveyors[id] ? state.conveyors[id].infeed?.transfer : undefined,
      },
      discharge: {
        ...discharge,
        transfer: state.conveyors[id] ? state.conveyors[id].discharge.transfer : undefined,
      },
    }
  },
  SAVE_CONVEYOR_ORDER: (state, action) => {
    const { id, order } = action.payload
    state.conveyors[id] = {
      ...state.conveyors[id],
      order,
    }
  },
  SAVE_OPTION_TO_OPTIONS: (state, action) => {
    state.options[action.payload.id] = action.payload
  },
  SAVE_OPTION_TO_CONVEYOR_OPTIONS: (state, action) => {
    const { conveyorId, data, optionType } = action.payload
    state.conveyors[conveyorId].pricevalidations[optionType] = {
      ...state.conveyors[conveyorId].pricevalidations[optionType],
      ...data,
    }
    if (optionType === 'sharedmuf') {
      state.conveyors[conveyorId].muf = undefined
    }
  },
  UPDATE_CONVEYOR_CHAIN: (state, action) => {
    const { chain, conveyorId } = action.payload
    if (!state.conveyors[conveyorId]) return
    state.conveyors[conveyorId].chain = {
      ...state.conveyors?.[conveyorId]?.chain,
      ...chain,
    }
  },
  UPDATE_CONVEYOR_INFEED_DISCHARGE: (
    state,
    action: {
      payload: {
        conveyorId: string;
        sectionType: 'infeed' | 'discharge';
        data: InfeedWithTransfer | DischargeWithTransfer;
        conveyor: IConveyorWithModifiedInfeedDischarge;
      };
    }
  ) => {
    const { discharge, infeed } = action.payload.conveyor
    const { conveyorId, sectionType, data } = action.payload

    state.conveyors[conveyorId] = {
      ...action.payload.conveyor,
      infeed: {
        ...infeed,
        transfer: state.conveyors[conveyorId] ? state.conveyors[conveyorId].infeed?.transfer : undefined,
      },
      discharge: {
        ...discharge,
        transfer: state.conveyors[conveyorId] ? state.conveyors[conveyorId].discharge.transfer : undefined,
      },
    }

    state.conveyors[conveyorId][sectionType] = {
      ...state.conveyors[conveyorId][sectionType],
      transfer: state.conveyors[conveyorId][sectionType].transfer,
      ...data,
    }
  },
  UPDATE_GEARMOTOR_INFO: (
    state,
    action: { payload: { conveyorId: string | number; gearmotorInfo: Partial<IGearmotor> } }
  ) => {
    const { conveyorId, gearmotorInfo } = action.payload
    _.set(state.conveyors[conveyorId], 'gearmotor', gearmotorInfo)
  },
  UPDATE_OPTIONS: (state, action) => {
    state.options = action.payload
  },
  UPDATE_VFD_OPTION: (state, action: { payload: { conveyorId: number; VFDOptions: IVFDSelection[] } }) => {
    const { conveyorId, VFDOptions } = action.payload
    state.VFDOptions[conveyorId] = VFDOptions
  },
  // Conveyor Section
  SAVE_CONVEYOR_SECTION_DATA: (state, action) => {
    const { conveyorId, sectionId, sectionData } = action.payload
    state.conveyors[conveyorId].conveyorsections[sectionId] = sectionData
  },
  SAVE_ALL_CONVEYOR_SECTIONS_DATA: (state, action) => {
    const { conveyorId, allSectionsData } = action.payload
    state.conveyors[conveyorId].conveyorsections = allSectionsData
  },
  DELETE_CONVEYOR_TRANSFERS_DATA: (state, action) => {
    const { conveyorId, sectionType } = action.payload
    state.conveyors[conveyorId][sectionType].transfer = null
    state.conveyors[conveyorId][sectionType].hastransfer = false
  },
  SAVE_CONVEYOR_TRANSFERS_DATA: (state, action) => {
    const { conveyorId, sectionType, transferData } = action.payload
    if (state.conveyors?.[conveyorId]?.[sectionType]) {
      state.conveyors[conveyorId][sectionType].transfer = transferData
      state.conveyors[conveyorId][sectionType].hastransfer = true
    }
  },
  [PURGE]: () => INITIAL_STATE,
})
