export const LENGTH_TO_INFEED_BOTTOM_LEFT = 641
export const DEFAULT_CONVEYOR_WIDTH = 100
export const VERTICAL_CURVE_INCLINE_COEFFICIENT = 331.724 // R13.06
export const VERTICAL_CURVE_DECLINE_COEFFICIENT = 468.376 // R18.44
export const VERTICAL_CURVE_ANCHOR = { x: 0, y: 0 }
export const MM_IN_FT = 304.8
export const MM_IN_IN = 25.4
export const MM_IN_1_16_IN = MM_IN_IN / 16 // 1.5875
export const MM_IN_IN_RATIO = 1 / MM_IN_IN

export const SELECTED_COLOR = '#5457676c'
export const DEFAULT_COLOR = '#101735d8'
export const SELECTED_PART_COLOR = '#ffffffff'
export const DEFAULT_PART_COLOR = '#ffffff80'

export const SVG_CONTAINER_CLASSNAME = 'builder-canvas'
export const SVG_G_SIZE_ID = 'builder-canvas-size-g-tag' as const

// used in creating svgs
export const SVG_NS = 'http://www.w3.org/2000/svg' as const
export const SVG_XLINK = 'http://www.w3.org/1999/xlink' as const
export const MS_WORD_WIDTH = 3400.32 as const
export const SVG_PADDING = 40000 as const
export const SVG_PADDING_FIX = 145 as const
export const SVG_EXTRA_ADJUSTMENT = 50 as const
