import * as React from 'react'
import { Switch } from 'antd'
import {
  GuideRails,
  Underguard,
  Driptray,
  SideWalls,
} from 'features/Estimator/components/Accessories/components/sectionOverrides'
import { typedUseSelector } from 'utils/helpers'
import { FormConfigItem } from 'shared/types/formConfig'

interface Props {
  itemId: number
  isactive: boolean
  islocked: boolean
  field: FormConfigItem
  conveyorId: number
}

export default function AccessoriesToggle(props: Props) {
  const { itemId, isactive, islocked, field, conveyorId } = props

  const hasOneActive = typedUseSelector(({ EstimatorReducer }) => {
    if (itemId in EstimatorReducer.sections) {
      return Object.values(EstimatorReducer?.sections?.[itemId]?.accessories || {}).some(
        (o) => o.parameteractive || o?.left?.parameteractive || o?.right?.parameteractive
      )
    }
    return false
  })

  const [toggled, setToggled] = React.useState(hasOneActive)
  React.useLayoutEffect(() => {
    setToggled(hasOneActive)
  }, [hasOneActive])

  const collapseibleId = `accessories-toggle-${itemId}`

  if (!(toggled || isactive) || islocked) return null
  return (
    <div
      className="builder-tile__expanded__content__wrapper"
      key={`form-item-${field.key}-${itemId}`}
    >
      <div className="option-title">{field.prettyName}</div>
      <div className="option-label toggle">{field.label}</div>
      <Switch
        checked={toggled}
        disabled={hasOneActive}
        onClick={(checked) => {
          if (!hasOneActive) setToggled(checked)
        }}
        aria-controls={collapseibleId}
        aria-expanded={toggled}
      />
      <div
        id={collapseibleId}
        hidden={!toggled}
        style={{ visibility: toggled ? 'visible' : 'hidden' }}
      >
        <GuideRails conveyorId={conveyorId} sectionId={itemId} builder showSingleCheckbox />
        <Underguard conveyorId={conveyorId} sectionId={itemId} />
        <Driptray conveyorId={conveyorId} sectionId={itemId} />
        <SideWalls conveyorId={conveyorId} sectionId={itemId} builder />
      </div>
    </div>
  )
}
