import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import { PURGE } from 'redux-persist'

/**
 * @typedef {Object} Filter
 * @property {string} type
 * @property {number} value
 * @property {string} displayValue
 *
 * @typedef {Object} StatisticTotal
 * @property {number} estimates
 * @property {number} sold
 *
 * @typedef {Object} Period
 * @property {string} periodtitle
 * @property {number} soldcount
 * @property {number} soldamount
 * @property {number} estimatescount
 * @property {number} estimatesamount
 * @property {number} quotedcount
 * @property {number} quotedamount
 * @property {number} totalproject
 * @property {number} soldratio
 * @property {number} quotedratio
 * @property {number} order
 *
 * @typedef {Object} StatisticsData
 * @property {Array<{label: string, value: string, icon: string, diff: number}>} [overviewData]
 * @property {Array<Period>} [perioddetail]
 * @property {{revenue: StatisticTotal, quantity: StatisticTotal, closeration: StatisticTotal}} [statisticsTotals]
 *
 * @typedef {Object} ProjectByStatusData
 *
 * @typedef {Object} ProjectByStatusDetailData
 *
 * @typedef {Object} AdminStatsState
 * @property {import('shared/constants/userRoles').RoleOption} [department]
 * @property {Filter} [filter]
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} calendarLabel
 * @property {string} dateType
 * @property {string} periodType
 * @property {string} selectedChart
 * @property {StatisticsData} statisticsData
 * @property {ProjectByStatusData} projectByStatusData
 * @property {ProjectByStatusDetailData} projectByStatusDetailData
 */

/** @type {AdminStatsState} */
const INITIAL_STATE = {
  department: undefined,
  filter: null,
  startDate: moment().startOf('year').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  calendarLabel: 'Current YTD',
  dateType: 'ytd',
  periodType: 'month',
  selectedChart: 'revenue',
  statisticsData: {},
  projectByStatusData: {},
  projectByStatusDetailData: {},
}

export default createReducer(INITIAL_STATE, {
  GET_STATISTICS_SUCCESS: (state, action) => {
    return {
      ...state,
      ...action.payload,
    }
  },
  SELECT_CHART: (state, action) => {
    return {
      ...state,
      selectedChart: action.payload,
    }
  },
  SELECT_DEPARTMENT: (state, action) => {
    return {
      ...state,
      department: action.payload,
    }
  },
  UPDATE_DATES: (state, action) => {
    return {
      ...state,
      ...action.payload,
    }
  },
  UPDATE_FILTER: (state, action) => {
    const { type, value, displayValue } = action.payload
    return {
      ...state,
      filter: {
        type,
        value,
        displayValue,
      },
    }
  },
  [PURGE]: () => INITIAL_STATE,
})
