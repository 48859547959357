import {
  setAccessoryDataForConveyorSection,
  runUpdateMaterialOrAccessoryForConveyorSection,
} from '../../../../redux/SectionOverrideActions'

export const initializesectionsidewalls = (sidewallsData, sectionId) => async (dispatch) => {
  dispatch(
    setAccessoryDataForConveyorSection({
      sectionId,
      accessoryName: 'sidewalls',
      accessoryData: sidewallsData,
    })
  )
}

export const updateSidewalls = ({
  conveyorId,
  updatedSidewallsFields,
  sideName,
  sectionId,
  sideId,
}) => (dispatch) => {
  dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      sectionId,
      materialKey: 'sidewalls',
      updatedFields: updatedSidewallsFields,
      type: 'accessories',
      sideName,
      sideId,
    })
  )
}
