import React, { memo } from 'react'
import { typedUseSelector } from 'utils/helpers'
import { IBuilderSectionData } from '../../types/types'
import { SELECTED_COLOR, DEFAULT_COLOR } from '../../constants'
import getAnnularSVGPath from './helpers/getAnnularSVGPath'

interface Props extends Partial<IBuilderSectionData> {
  color: string;
  conveyorId: number;
  width: number;
}

function HorizontalCurve(props: Props) {
  const { anchorAngleDeg, anchorPosition, angle, color, conveyorId, id, radius, width } = props

  const selectedSectionId = typedUseSelector(
    (state) => state.EstimatorMetaReducer[conveyorId].conveyorbuilder.selectedSectionId
  )

  return (
    <g
      id={`section-${id}`}
      className="draggable"
      transform={`translate(${anchorPosition.x} ${anchorPosition.y})`}
      fill={selectedSectionId === id.toString() ? SELECTED_COLOR : DEFAULT_COLOR}
    >
      <path
        id={id as string}
        d={getAnnularSVGPath(0, angle, radius, width)}
        style={{ stroke: color, strokeWidth: '4px' }}
        transform={`rotate(${angle < 0 ? 90 - anchorAngleDeg : anchorAngleDeg * -1})`}
      />
    </g>
  )
}

export default memo(HorizontalCurve)
