import http from 'utils/api/Client'
import { formatStatisticsData } from './StatisticsModel'

const StatisticsService = {
  async getStatistics(periodTypeApi, startDate, endDate, customerId) {
    return await http
      .get(
        `/Admin/Stats/${periodTypeApi}/${startDate}/${endDate}${customerId ? `/${customerId}` : ''}`
      )
      .then(({ data }) => formatStatisticsData(data))
      .catch((e) => {
        throw new Error('Could not get statistics')
      })
  },
  async getProjectByStatus(startDate, endDate, filterType = 'All', filterValue = 0) {
    return await http
      .get(`/Admin/ProjectByStatus/${startDate}/${endDate}/${filterType}/${filterValue}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw new Error('Could not get projects by status')
      })
  },
  async getProjectByStatusDetail(
    startDate,
    endDate,
    department,
    filterType = 'All',
    filterValue = 0
  ) {
    return await http
      .get(
        `/Admin/ProjectByStatusDetail/${startDate}/${endDate}/${department}/${filterType}/${filterValue}`
      )
      .then(({ data }) => data)
      .catch((e) => {
        throw new Error('Could not get projects by status detail')
      })
  },
  async getSFProjectLog() {
    try {
      const response = await http.get('/integrations/Salesforce/export')
      const { data } = response
      const link = document.createElement('a')
      link.href = data.filecontent
      link.setAttribute('download', data.filename)
      link.click()
      return true
    } catch (error) {
      return false
    }
  },
}

export default StatisticsService
