import BuilderTile from './BuilderTile'
import { Form } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import {
  collapseSection,
  selectSection,
  validateSection,
} from '../../../../redux/ConveyorBuilderActions'
import {
  deleteConveyorSection,
  updateConveyorSectionOptimistic,
  cloneConveyorSection,
} from '../../../../redux/ConveyorSectionActions'
import {
  fetchMaterialsForConveyorSection,
  fetchAccessoriesForConveyorSection,
} from '../../../../../../redux/SectionOverrideActions'
import '../BuilderTile.scss'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'
import {
  selectHasInfeedDrive,
  selectHasDischargeDrive,
  getAllowIntermediateDrives,
} from '../../../../redux/selectors'
import { Store } from 'reduxStore'

export const mapStateToProps = (state: Store, props) => {
  try {
    const { conveyorId, itemId } = props
    const {
      ConveyorReducer,
      EstimatorMetaReducer,
      ListReducer,
      EstimatorReducer,
      VersionReducer,
    } = state
    const { sections } = EstimatorReducer
    const { versionId } = VersionReducer
    const conveyor = ConveyorReducer.conveyors[conveyorId]
    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[itemId]
    const sectionMaterialsData = sections?.[itemId]?.materials
    const sectionAccessoriesData = sections?.[itemId]?.accessories
    const hasSectionMaterialsData = Boolean(sectionMaterialsData)
    const hasSectionAccessoriesData = Boolean(sectionAccessoriesData)
    const { isactive, islocked } = state.ProjectReducer.versions[versionId]
    const { currentexternalprojecttype, currentlistsettings } = state.ProjectReducer
    const hasInfeedDrive = selectHasInfeedDrive(state, conveyorId)
    const hasDischargeDrive = selectHasDischargeDrive(state, conveyorId)
    const allowIntermediatedrives = getAllowIntermediateDrives(state, conveyorId)
    const hasIntermediateDrive = Object.values(conveyor?.conveyorsections || {}).some(
      (section) => section.type === 'IntermediateDrive'
    )

    return {
      collapsed: sectionMetadata ? sectionMetadata.collapsed : false,
      conveyor,
      isactive,
      externalprojecttype: currentexternalprojecttype,
      islocked,
      isMultiSpanClosedTop:
        ListReducer?.chains?.[conveyor?.chain?.chainserieid]?.name === 'MultiSpan Closed-Top',
      item: conveyor.conveyorsections[itemId],
      sectionFirstValidation: sectionMetadata ? sectionMetadata.sectionFirstValidation : false,
      sectionsList: currentlistsettings.sections,
      selectedSectionId: EstimatorMetaReducer?.[conveyorId]?.conveyorbuilder?.selectedSectionId,
      unit: conveyor.unit,
      validated: sectionMetadata ? sectionMetadata.validated : false,
      sectionMaterialsData,
      sectionAccessoriesData,
      hasSectionMaterialsData,
      hasSectionAccessoriesData,
      versionId,
      permissions: state.UserReducer.permissions,
      hasInfeedDrive,
      hasDischargeDrive,
      allowIntermediatedrives,
      hasIntermediateDrive,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  fetchMaterialsForConveyorSection,
  fetchAccessoriesForConveyorSection,
  collapseSection,
  deleteConveyorSection,
  openConfirmModal,
  selectSection,
  updateConveyorSectionOptimistic,
  validateSection,
  cloneConveyorSection,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
const connectedBuilderTile = connector(Form.create({ name: 'buildertile' })(BuilderTile))

export default connectedBuilderTile
