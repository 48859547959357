import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import ADP from 'awesome-debounce-promise'
import { Select } from 'shared/components'
import { searchCustomers } from 'shared/redux/ListActions'
import { updateFilter } from '../../redux/AdminStatsActions'
import './CustomerSelectDropdown.scss'
import { captureSentryError } from 'utils/helpers'

class CustomerSelectDropdown extends Component {
  state = {
    customerIdToSearch: null,
    customers: this.props.customers,
    loading: false,
    selectedCustomerId: undefined,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers !== this.props.customers) {
      this.setState({
        customers: this.props.customers,
      })
    }
  }

  customerOptions = () => {
    const { customers, customerIdToSearch } = this.state
    return _.take(
      _.map(
        _.filter(customers, (c) => {
          const searchString = c.name + c.number
          return _.toLower(searchString).indexOf(_.toLower(customerIdToSearch)) !== -1
        }),
        (customer) => {
          return (
            <Select.Option
              key={customer.id}
              id={`customer-${customer.id}`}
              value={customer.id}
              name={customer.name}
              number={customer.number}
            >
              {customer.name}
            </Select.Option>
          )
        }
      ),
      50
    )
  }

  searchCustomers = (value) => {
    const { searchCustomers } = this.props
    this.setState({ loading: true }, async () => {
      await searchCustomers(value)
      this.setState({ loading: false })
    })
  }

  debouncedSearchCustomers = ADP(this.searchCustomers, 1000)

  render() {
    const { loading } = this.state
    const {
      filter,
      onSelectCustomer = (value, option) => {
        if (value) {
          console.log(
            `No onSelectCustomer prop was provided.\nSelected Customer: #${value} - ${option.props.name}`
          )
        }
      },
      updateFilter,
      width = '200px',
    } = this.props

    return (
      <Select
        className="customer-select-dropdown"
        allowClear={!loading}
        width={width}
        loading={loading}
        showSearch
        value={filter.type === 'Customer' ? filter.displayValue : undefined}
        filterOption={false}
        placeholder="Filter by Customer"
        onChange={(value, options) => {
          this.setState(
            {
              selectedCustomerId: value,
            },
            async () => {
              await updateFilter({
                type: value ? 'Customer' : undefined,
                value: value,
                displayValue: value ? options.props.name : undefined,
              })
              onSelectCustomer(value, options)
            }
          )
        }}
        onSearch={(value) => {
          if (_.trim(value)) {
            this.setState(
              {
                customerIdToSearch: value,
              },
              () => this.debouncedSearchCustomers(value)
            )
          }
        }}
      >
        {this.customerOptions()}
      </Select>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      admin: state.UserReducer.roles.includes('Admin'),
      customers: state.ListReducer.customers,
      filter: state.AdminStatsReducer.filter || {},
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { searchCustomers, updateFilter }

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelectDropdown)
