import _ from 'lodash'
import { Form, Tabs } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from 'features/Estimator/components/shared/Headers'
import { updateGuiderails } from 'features/Estimator/components/Accessories/redux/default/GuideRailsActions'
import {
  updateDefaultMaterialAcessoriesMetaData,
  cloneMaterialOrAccessoryWithSidesDefaults,
} from 'features/Estimator/redux/EstimatorActions'
import GuideRailsConfig from './GuideRailsConfig'
import './GuideRails.scss'
import colors from 'shared/constants/colors'
import { Radio, InputWithValidation, Select, Icon } from 'shared/components'
import { setLoading } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'
import { getGuideRailsOptionsByTextvalue, getTextValue } from './helpers'
import FIELD_TYPES from './helpers/fieldTypes'

const { TabPane } = Tabs

const titleStyles = {
  maxWidth: '120px',
}

/**
 * @typedef {import('utils/api/list/types').AccessoriesList['guiderails']} GuiderailsOptions
 * @typedef {import('shared/types/GuideRailsSideResponse').GuideRailsSideResponse} GuideRailsSideResponse
 * @typedef {import('shared/types/formConfig.d').FormConfigItem} FormConfigItem
 * @typedef {import('./GuideRailsConfig').GRFormConfig} GRFormConfig
 * @typedef {import('antd/lib/form').FormComponentProps} FormComponentProps
 * @typedef {import('shared/types/formConfig.d').FormItemOption} FormItemOption
 * @typedef {'left' | 'right'} SideName
 * @typedef {import('shared/types/Conveyor').Conveyor} Conveyor
 */

/**
 * @typedef {Object} Props
 * @prop {GuiderailsOptions} guiderailsOptions
 * @prop {boolean} isactive
 * @prop {boolean} islocked
 * @prop {number} versionId
 * @prop {number} conveyorId
 * @prop {{open: boolean}} guiderailsMeta
 * @prop {GuideRailsSideResponse} left
 * @prop {GuideRailsSideResponse} right
 * @prop {boolean} isMetric,
 * @prop {boolean} [loading]
 * @prop {typeof updateGuiderails} updateGuiderails
 * @prop {typeof cloneMaterialOrAccessoryWithSidesDefaults} cloneMaterialOrAccessoryWithSidesDefaults
 * @prop {typeof setLoading} setLoading
 * @prop {typeof updateDefaultMaterialAcessoriesMetaData} updateDefaultMaterialAcessoriesMetaData
 * @prop {Conveyor} conveyor
 * @prop {boolean} isLAProject,
 *
 * @extends {React.Component<Props & FormComponentProps>}
 */
class Guiderails extends Component {
  fields = []

  state = {
    bothSidesAreSameValue: false,
    activeTab: 'left-tab',
  }

  componentDidUpdate(prevProps) {
    const currentProps = this.props
    const sides = ['right', 'left']
    let touched = false
    for (let i = 0; i < sides.length; i++) {
      const sideName = sides[i]
      const side = currentProps[sideName]
      const sidePropertyKeys = Object.keys(side)
      for (let j = 0; j < sidePropertyKeys.length; j++) {
        const sidePropertyKey = sidePropertyKeys[j]
        if (typeof side[sidePropertyKey] === 'number' && side[sidePropertyKey] !== prevProps[sideName][sidePropertyKey]) {
          touched = true
        }
      }
    }

    if (touched) this.props.form.resetFields()
  }

  /**
   * 
   * @param {import('shared/types/GuideRailsSideResponse').GuideRailsSideResponse} side 
   * @param {'left' | 'right'} sideName 
   * @returns {React.ReactElement}
   */
  renderFields = (side, sideName) => {
    const {
      right,
      left,
      isactive,
      islocked,
      isMetric,
      updateGuiderails,
      conveyorId,
      guiderailsOptions,
      conveyor,
      form,
      isLAProject
    } = this.props
    const { copiedFromSide } = this.state
    const sideId = side.id
    
    const config = GuideRailsConfig(guiderailsOptions, side,isLAProject)
    const disabled = !isactive || islocked
    const showBothValueButton = right.parameteractive && left.parameteractive

    return (
      <>
        {disabled || !showBothValueButton ? null : this.renderUseValueOnBothSidesButton(sideName)}
        {Object.values(config).map((fieldConfig) => {
          const { dataKey, prettyName, options, rules, vertical, type, className } = fieldConfig
          const key = `default-accessories-guiderails-${dataKey}${sideId}${this.props.conveyorId}`

          if (type === FIELD_TYPES.RADIO) {
            return (
              <div key={key} className={className || ''}>
                <Radio
                  ref={(node) => (this.node = node)}
                  disabled={disabled}
                  dataKey={dataKey}
                  prettyName={prettyName}
                  margin="0 0 10px 0"
                  options={options}
                  onChange={(val) => {
                    this.setState({ copiedFromSide: null })

                    const bracketSelection =
                      guiderailsOptions.brackets?.[
                        dataKey === 'bracketsid' ? val : side?.bracketsid
                      ]
                    const railSelection =
                      guiderailsOptions.railspreferredchoices?.[
                        dataKey === 'railschoiceid' ? val : side?.railschoiceid
                      ]
                    const textvalueSearch = getTextValue(railSelection, bracketSelection)

                    const distancebetweenrailslistOptions = getGuideRailsOptionsByTextvalue(
                      textvalueSearch,
                      guiderailsOptions?.distancebetweenrails
                    )
                    const distancetoclistOptions = getGuideRailsOptionsByTextvalue(
                      textvalueSearch,
                      guiderailsOptions?.distancetocs
                    )
                    const distancebetweenrailslistOptionsDefaultsValues = distancebetweenrailslistOptions
                      .filter((opt) => opt.default)
                      .map((opt) => opt.value)
                    const defaultDistancebetweenrailsAccepted = distancebetweenrailslistOptionsDefaultsValues.includes(
                      side.distancebetweenrailsid
                    )
                    const distancetoclistOptionsDefaultsValues = distancetoclistOptions
                      .filter((opt) => opt.default)
                      .map((opt) => opt.value)
                    const defaultDistancetoclistAccepted = distancetoclistOptionsDefaultsValues.includes(
                      side.distancetocid
                    )

                    updateGuiderails({
                      conveyorId,
                      sideId: side.id,
                      sideName,
                      updatedGuiderailsFields: {
                        [dataKey]: val,
                        ...(!defaultDistancetoclistAccepted && distancetoclistOptionsDefaultsValues.length
                          ? { distancetocid: distancetoclistOptionsDefaultsValues[0] }
                          : {}),
                        ...(!defaultDistancebetweenrailsAccepted && distancebetweenrailslistOptionsDefaultsValues.length
                          ? {
                              distancebetweenrailsid:
                                distancebetweenrailslistOptionsDefaultsValues[0],
                            }
                          : {}),
                      },
                    })

                    if (this.state.bothSidesAreSameValue) {
                      this.handleClone(sideName)
                    }
                  }}
                  rules={rules}
                  vertical={vertical}
                  tinyImages="70px"
                  initialValue={_.get(side, dataKey)}
                />
              </div>
            )
          }
          if (type === FIELD_TYPES.RAILS) {
            return (
              <div
                className="guiderails-railsfield indented"
                key={key}
              >
                <label>Rails</label>
                {this.renderGuiderailRailFields(
                  sideName,
                  side,
                  config.railschoiceid.railspreferredchoices
                )}
              </div>
            )
          }
          if (type === FIELD_TYPES.DISTANCEBETWEENRAILS) {
            const distanceOptions = /**@type {FormItemOption[]}*/(config.distancebetweenrailslist.options)
            const value = side?.distancebetweenrailsid
            if (Array.isArray(distanceOptions) && distanceOptions.length) {
              return (
                <div
                  className="guiderails-inline"
                  key={key}
                >
                  <label>Distance Between Rails</label>
                  {form.getFieldDecorator(dataKey, { initialValue: value })(
                    <Select
                      disabled={disabled}
                      small
                      className="status-actions"
                      options={distanceOptions}
                      placeholder="Please Select"
                      width={220}
                      onSelect={(val) => {
                        updateGuiderails({
                          conveyorId,
                          sideId,
                          sideName,
                          updatedGuiderailsFields: { distancebetweenrailsid: val },
                        })

                        if (this.state.bothSidesAreSameValue) {
                          this.handleClone(sideName)
                        }
                      }}
                    />
                  )}
                </div>
              )
            } else {
              return (
                <InputWithValidation
                  key={key}
                  disabled={disabled}
                  label="Distance Between Rails"
                  id="distancebetweenrailsmanual"
                  name="distancebetweenrailsmanual"
                  small
                  containerStyle={{
                    width: '200px',
                    display: 'inline-block',
                    marginRight: '30px',
                  }}  
                  addonAfter={isMetric ? 'mm' : 'in'}
                  rules={[
                    {
                      required: true,
                      message: 'Distance Between Rails',
                    },
                    {
                      type: 'number',
                      transform: (val) => Number(val),
                      message: 'Distance between rails must be a number',
                    },
                  ]}
                  type="text"
                  defaultValue={side?.distancebetweenrailsmanual}
                  onChange={(value) => {
                    updateGuiderails({
                      sideId,
                      sideName,
                      conveyorId,
                      updatedGuiderailsFields: { distancebetweenrailsmanual: value },
                    })

                    if (this.state.bothSidesAreSameValue) {
                      this.handleClone(sideName)
                    }
                  }}
                />
              )
            }
          }
          if (type === FIELD_TYPES.DISTANCETOC) {
            const tocOptions = /**@type {FormItemOption[]}*/(config.distancetoclist.options)
            const value = side?.distancetocid

            if (Array.isArray(tocOptions) && tocOptions.length) {
              return (
                <div
                  className="guiderails-inline"
                  key={key}
                >
                  <label className="guiderails-label">Distance TOC to Centerline of T Rail</label>
                  {form.getFieldDecorator(dataKey, { initialValue: value })(
                    <Select
                      small
                      disabled={disabled}
                      className="status-actions"
                      options={tocOptions}
                      key={key}
                      placeholder="Please Select"
                      width={220}
                      onSelect={(val) => {
                        updateGuiderails({
                          conveyorId,
                          sideId,
                          sideName,
                          updatedGuiderailsFields: { distancetocid: val },
                        })

                        if (this.state.bothSidesAreSameValue) {
                          this.handleClone(sideName)
                        }
                      }}
                    />
                  )}
                </div>
              )
            } else {
              return (
                <InputWithValidation
                  label="Distance TOC to Centerline of T Rail"
                  placeholder="Enter Distance TOC"
                  disabled={disabled}
                  id="distancetocmanual"
                  name="distancetocmanual"
                  small
                  containerStyle={{
                    width: '200px',
                    display: 'inline-block',
                  }}
                  addonAfter={isMetric ? 'mm' : 'in'}
                  rules={[
                    {
                      required: true,
                      message: 'Distance TOC is required',
                    },
                    {
                      type: 'number',
                      transform: (val) => Number(val),
                      message: 'Distance TOC must be a number',
                    },
                  ]}
                  type="text"
                  defaultValue={side.distancetocmanual}
                  onChange={(value) => {
                    updateGuiderails({
                      sideId,
                      sideName,
                      conveyorId,
                      updatedGuiderailsFields: { distancetocmanual: value },
                    })

                    if (this.state.bothSidesAreSameValue) {
                      this.handleClone(sideName)
                    }
                  }}
                />
              )
            }
          }
          if (type === FIELD_TYPES.ROD) {
            const rodOptions = config.rods.options
            return (rodOptions.length) ? (
              <div className="guiderails-inline" key={key}>
                <label className="guiderails-label">Length of Rods</label>
                {form.getFieldDecorator(dataKey, { initialValue: side?.rodid })(
                  <Select
                    small
                    key={key}
                    disabled={disabled}
                    className="status-actions"
                    options={rodOptions}
                    placeholder="Please Select"
                    width={220} 
                    onSelect={(val) => {
                      updateGuiderails({
                        conveyorId,
                        sideId,
                        sideName,
                        updatedGuiderailsFields: { rodid: val },
                      })

                      if (this.state.bothSidesAreSameValue) {
                        this.handleClone(sideName)
                      }
                    }}
                  />
                )}
              </div>
            ) : null
          }
          if (type === FIELD_TYPES.DISPLAY) {
            return (
              <div
                className={className || ''}
                key={key}
              >
                <div className="tm-radio">
                  <div className="tm-radio__label">{prettyName}</div>
                  <Form.Item>
                    <span className="guiderails-formitem-display">
                      Guide Rail Backing Is Stainless Steel
                    </span>
                  </Form.Item>
                </div>
              </div>
            )
          }
          if (type === FIELD_TYPES.SELECT) {
            const isEZ = dataKey === 'ezguideid'

            return <div className="guiderails-inline" key={`ezguide-${sideId}`}>
              <label className="guiderails-label">{prettyName}</label>
              {form.getFieldDecorator(dataKey, { initialValue: side?.ezguideid })(
                <Select
                  small
                  disabled={disabled}
                  className="status-actions"
                  options={options}
                  width={220}
                  onSelect={async (val) => {
                    const optionLabel = /**@type {import('./GuideRailsConfig').SelectOption[]}*/(options).find(opt => opt?.value === val)?.label
                    const payload = { [dataKey]: val }
                    if (isEZ && optionLabel === 'Adjustable') {
                      payload.distancebetweenrailsmanual = conveyor.unit === 'English'
                        ? conveyor.chain.widthenglish
                        : conveyor.chain.widthmetric
                    }

                    this.setState({ copiedFromSide: null })
                    await updateGuiderails({
                      conveyorId,
                      sideId,
                      sideName,
                      updatedGuiderailsFields: payload,
                      cb: () => {
                        if (isEZ || this.state.bothSidesAreSameValue) {
                          this.handleClone(sideName)
                        }
                      }
                    })
                  }}
                />
              )}
            </div>
          }
          return null
        })}
      </>
    )
  }

  /**
   * @param {string} sideName
   * @param {GuideRailsSideResponse} side
   * @param {GRFormConfig} config
   */
  renderGuiderailRailFields = (sideName, side, config) => {
    const { dataKey, prettyName, options, rules, vertical } = config
    const { conveyorId, updateGuiderails, isactive, islocked, guiderailsOptions } = this.props

    return (
      <Radio
        ref={`rails-radio-${config.shortname}`}
        disabled={!isactive || islocked}
        key={
          'default-accessories-guiderails' +
          dataKey +
          sideName +
          this.props.conveyorId +
          'preferred'
        }
        dataKey={dataKey}
        prettyName={prettyName}
        labelStyles={{
          fontWeight: 400,
        }}
        margin="0 0 10px 0"
        titleStyles={titleStyles}
        options={options}
        onChange={(val) => {

          const bracketSelection =
            guiderailsOptions.brackets?.[dataKey === 'bracketsid' ? val : side?.bracketsid]
          const railSelection =
            guiderailsOptions.railspreferredchoices?.[
              dataKey === 'railschoiceid' ? val : side?.railschoiceid
            ]
          const textvalueSearch = getTextValue(railSelection, bracketSelection)

          const distancebetweenrailslistOptions = getGuideRailsOptionsByTextvalue(
            textvalueSearch,
            guiderailsOptions?.distancebetweenrails
          )
          const distancetoclistOptions = getGuideRailsOptionsByTextvalue(
            textvalueSearch,
            guiderailsOptions?.distancetocs
          )
          const distancebetweenrailslistOptionsDefaultsValues = distancebetweenrailslistOptions
            .filter((opt) => opt.default)
            .map((opt) => opt.value)
          const defaultDistancebetweenrailsAccepted = distancebetweenrailslistOptionsDefaultsValues.includes(
            side.distancebetweenrailsid
          )
          const distancetoclistOptionsDefaultsValues = distancetoclistOptions
            .filter((opt) => opt.default)
            .map((opt) => opt.value)
          const defaultDistancetoclistAccepted = distancetoclistOptionsDefaultsValues.includes(
            side.distancetocid
          )

          updateGuiderails({
            conveyorId,
            sideId: side.id,
            sideName,
            updatedGuiderailsFields: {
              [dataKey]: val,
              ...(!defaultDistancetoclistAccepted && distancetoclistOptionsDefaultsValues.length
                ? { distancetocid: distancetoclistOptionsDefaultsValues[0] }
                : {}),
              ...(!defaultDistancebetweenrailsAccepted &&
              distancebetweenrailslistOptionsDefaultsValues.length
                ? { distancebetweenrailsid: distancebetweenrailslistOptionsDefaultsValues[0] }
                : {}),
            },
          })

          if (this.state.bothSidesAreSameValue) {
            this.handleClone(sideName)
          }
        }}
        rules={rules}
        vertical={vertical}
        initialValue={side?.[dataKey]}
        value={side?.[dataKey]}
        tinyImages="75px"
        mapPropsToFields={(props) => ({
          railschoiceid: Form.createFormField({
            value: side?.railschoiceid,
          }),
        })}
      />
    )
  }

  /** @param {SideName} sideName */
  handleClone = (sideName) => {
    const { conveyorId, cloneMaterialOrAccessoryWithSidesDefaults, setLoading } = this.props
    const otherSideName = sideName === 'left' ? 'right' : 'left'

    cloneMaterialOrAccessoryWithSidesDefaults({
      conveyorId: conveyorId,
      materialKey: 'guiderails',
      type: 'accessories', // 'materials' or 'accessories'
      fromSideId: this.props[sideName].id,
      toSideId: this.props[otherSideName].id,
      sideName,
    })
  }

  handleUseDifferentValues = () => {
    this.setState(state => {
      let resetActiveTab = {}
      // when we are using same values from both sides, switch to the left tab.
      if (!state.bothSidesAreSameValue){
       resetActiveTab = { activeTab: 'left-tab' }
       this.handleClone('left')
      }
      return {
        bothSidesAreSameValue: !state.bothSidesAreSameValue,
        ...resetActiveTab
      }
    })
  }

  /** @param {'left-tab' | 'right-tab'} tabKey */
  handleTabClick = (tabKey) => {
    this.setState({ activeTab: tabKey })
  }

  /** @param {SideName} sideName */
  renderUseValueOnBothSidesButton = (sideName) => {
    const otherSideName = sideName === 'left' ? 'right' : 'left'
    return (
      <div
        className="copy-guiderails-button"
        onClick={this.handleUseDifferentValues}
      >
        <Icon icon="clone" color={colors.novaBlue} size={14} />
        <div className="copy-guiderails-button__text">
          {this.state.bothSidesAreSameValue ? `Use different values for the ${_.capitalize(otherSideName)} Side` : 'Use the same value for both sides'}
        </div>
      </div>
    )
  }

  render() {
    if (this.props.loading) return null

    const {
      left,
      right,
      guiderailsMeta: { open },
      updateGuiderails,
      conveyorId,
      updateDefaultMaterialAcessoriesMetaData,
    } = this.props

    const rightChecked = right.parameteractive
    const leftChecked = left.parameteractive

    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="accessories"
          fieldKey="guiderails"
          leftCheck={{
            leftId: left.id,
            leftChecked,
          }}
          rightCheck={{
            rightId: right.id,
            rightChecked,
          }}
          isLeftRight
          onClickCheck={(e, sideId, sideName) => {
            this.setState({ copiedFromSide: null })
            if (e.target.checked) {
              updateDefaultMaterialAcessoriesMetaData({
                conveyorId,
                type: 'accessories',
                name: 'guiderails',
                updatedFields: {
                  open: true,
                },
              })
            } else {
              updateDefaultMaterialAcessoriesMetaData({
                conveyorId,
                type: 'accessories',
                name: 'guiderails',
                updatedFields: {
                  open: (leftChecked || rightChecked) && open,
                },
              })
            }
            const parameteractive = sideName === 'left' ? !leftChecked : !rightChecked
            updateGuiderails({
              conveyorId,
              sideId,
              sideName,
              updatedGuiderailsFields: { parameteractive },
            })

            // if right tab is the first opened, open right tab
            if (sideName === 'right' && parameteractive && !leftChecked) {
              this.setState({
                activeTab: 'right-tab'
              })
            // if both sides are open
            } else if (sideName === 'right' && leftChecked) {
              this.setState({
                activeTab: 'left-tab'
              })
            } else if (sideName === 'left' && parameteractive) {
              this.setState({
                activeTab: 'left-tab'
              })
            } else if (sideName === 'left' && !parameteractive && rightChecked) {
              this.setState({
                activeTab: 'right-tab'
              })
            }

            // if both sides are open, use same value as default
            if ((sideName === 'right' && parameteractive && leftChecked) || (sideName === 'left' && parameteractive && rightChecked)) {
              this.setState({ bothSidesAreSameValue: true })
            } else {
              this.setState({ bothSidesAreSameValue: false })
            }

          }}
          checked={leftChecked || rightChecked}
          open={open}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'guiderails',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Guide Rails"
        >
          <Tabs onTabClick={this.handleTabClick} activeKey={this.state.activeTab} type="card">
            {leftChecked ? (
              <TabPane style={{ margin: '0px 20px' }} tab="Left" key='left-tab'>
                {this.renderFields(left, 'left')}
              </TabPane>
            ) : null}
            {rightChecked ? (
              <TabPane style={{ margin: '0px 20px' }} tab="Right" key='right-tab' disabled={this.state.bothSidesAreSameValue}>
                {this.renderFields(right, 'right')}
              </TabPane>
            ) : null}
          </Tabs>
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      ConveyorReducer,
      EstimatorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
    } = state
    const { conveyorId, match } = props
    const {
      params: { versionId },
    } = match
    const guiderailsData = EstimatorReducer?.[conveyorId]?.accessories?.guiderails
    const guiderailsMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.guiderails
    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isMetric = ConveyorReducer.conveyors[conveyorId].unit === 'Metric'
    const isLAProject = ProjectReducer.currentexternalprojecttype == 'la'

    if (!guiderailsData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      conveyor: state.ConveyorReducer.conveyors[conveyorId],
      guiderailsMeta,
      left: guiderailsData?.left,
      right: guiderailsData?.right,
      guiderailsOptions: ProjectReducer.currentlistsettings.accessories.guiderails,
      isactive,
      islocked,
      isMetric,
      isLAProject
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateGuiderails,
    updateDefaultMaterialAcessoriesMetaData,
    cloneMaterialOrAccessoryWithSidesDefaults,
    setLoading,
  })(Form.create()(Guiderails))
)
