import React from 'react'
import { Table } from 'antd'
import { VTComponents } from 'virtualizedtableforantd'
import useTableColumns from '../hooks/useTableColumns'
import cn from 'classnames'
import useTableScrollY from '../hooks/useTableScrollY'
import { IPartsUpdateResponse } from 'shared/types/swagger'
import { UpdatePart } from '../types'
import { ScreenStateKey } from 'shared/types/Screen'

type Props = {
  screenState: ScreenStateKey
  data: Array<IPartsUpdateResponse>
  updatePart: UpdatePart
  loading: boolean
}

export default function StaticPartsTable(props: Props) {
  const { screenState, data, updatePart, loading } = props

  // const handleChange = (...args: Parameters<UpdatePart>) => {
  //   const [, key, value] = args
  //   if (!value) {
  //     console.log(`${key} needs a value. throw an error`)
  //   }
  //   updatePart(...args)
  // }
  const columns = useTableColumns({ handleChange: updatePart, screenState })
  const scrollY = useTableScrollY(screenState)

  const scrollX = columns.reduce((acc, cur) => {
    const _acc = acc + (Number(cur.width) || 0)
    return _acc
  }, 0)

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        dataSource={loading ? [] : data}
        components={VTComponents({ id: 1000 })}
        pagination={false}
        loading={loading}
        onRow={(record: IPartsUpdateResponse) => ({
          className: `rk${record.partnumberguid.replace(/[\s]+/g, '-')}`,
        })}
        rowKey={(record: IPartsUpdateResponse) => record.partnumberguid}
        className={cn({ 'cost-import__table--editing': false })}
        scroll={{ x: scrollX, y: scrollY }}
      />
    </div>
  )
}
