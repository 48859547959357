import {
  setDefaultAccessoryDataForConveyor,
  runUpdateDefaultMaterialOrAccessoryForConveyor,
} from '../../../../redux/EstimatorActions'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializeunderguard = (conveyorId, underguardData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoryDataForConveyor({
      conveyorId,
      accessoryKey: 'underguard',
      accessoryData: underguardData,
    })
  )
}

export const updateUnderguard = ({ conveyorId, updatedUnderguardFields }) => (dispatch) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'underguard',
      updatedFields: updatedUnderguardFields,
      type: 'accessories',
    })
  )
}
