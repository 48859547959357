import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import './ActiveProjectsScreen.scss'
import UsersByRole from 'features/Admin/components/UsersByRole'
import { Select } from 'shared/components'
import { userTypes } from 'features/Admin/components/UsersByRole/usersByRoleConfig'
import { getUsersList } from 'shared/redux/ListActions'
import {
  getProjectByStatusDetail,
  selectDepartment,
  updateFilter,
} from 'features/Admin/redux/AdminStatsActions'
import CustomDatePicker from 'features/Admin/components/CustomDatePicker'
import CustomerSelectDropdown from 'features/Admin/components/CustomerSelectDropdown'
import { captureSentryError } from 'utils/helpers'

const { Option } = Select

class ActiveProjectsScreen extends Component {
  state = {
    projectStatusData: null,
    selectedDepartment: this.props.department,
    selectedEmployeeId: undefined,
  }

  async componentDidMount() {
    const {
      admin,
      calendarLabel,
      getProjectByStatusDetail,
      getUsersList,
      history,
      startDate,
      endDate,
    } = this.props
    if (!admin) {
      history.push('/')
    }
    await getProjectByStatusDetail([startDate, endDate], calendarLabel)
    await getUsersList()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.department !== this.props.department) {
      this.setState({
        selectedDepartment: this.props.department,
      })
    }
  }

  renderDropdowns = () => {
    const { selectedDepartment } = this.state
    const { filter, selectDepartment, updateFilter, users } = this.props

    const assigneeOptions = userTypes.map((user) => {
      return (
        <Option key={user.key} value={user.key}>
          {user.title}
        </Option>
      )
    })

    const employeeOptions = _.map(_.sortBy(users, ['lastname']), (user) => {
      return (
        <Option key={user.id} value={user.id}>
          {`${user.firstname} ${user.lastname}`}
        </Option>
      )
    })

    return (
      <div className="active-projects__top__dropdowns-container">
        <CustomDatePicker activeProjects />
        <Select
          allowClear
          style={{ width: '200px', marginRight: '16px' }}
          width={200}
          placeholder="Filter by Assignee"
          defaultValue={selectedDepartment}
          onChange={(value) => {
            this.setState({ selectedDepartment: value }, () => {
              selectDepartment(value)
            })
          }}
        >
          {assigneeOptions}
        </Select>

        <Select
          allowClear
          style={{ width: '200px', marginRight: '16px' }}
          width={200}
          placeholder="Filter by Employee"
          defaultValue={filter.type === 'Employee' ? filter.value : undefined}
          onChange={(value) => {
            this.setState({ selectedEmployeeId: value }, () => {
              updateFilter({
                type: value ? 'Employee' : undefined,
                value,
              })
            })
          }}
        >
          {_.sortBy(employeeOptions, ['lastname'])}
        </Select>

        <CustomerSelectDropdown />
      </div>
    )
  }

  render() {
    return (
      <div id="active-projects">
        <div className="active-projects__top">
          <div className="active-projects__top__title">Active Projects</div>
          {this.renderDropdowns()}
        </div>
        <div className="active-projects__by-role">
          <UsersByRole />
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const {
      calendarLabel,
      department,
      endDate,
      filter,
      startDate,
      statisticsData,
    } = state.AdminStatsReducer

    return {
      admin: state.UserReducer.roles.includes('Admin'),
      calendarLabel,
      department,
      endDate,
      filter,
      startDate,
      statisticsData,
      users: state.ListReducer.users,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  getProjectByStatusDetail,
  getUsersList,
  selectDepartment,
  updateFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveProjectsScreen)
