import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import { updateSideframes } from '../redux/default/SideframeActions'
import { updateDefaultMaterialAcessoriesMetaData } from '../../../redux/EstimatorActions'
import sideframesConfig from './SideframesConfig'
import { Radio, InputWithValidation } from 'shared/components'
import { captureSentryError } from 'utils/helpers'

class Sideframes extends Component {
  renderSideframesMaterialCoreSpecsField = (config) => {
    const { dataKey, prettyName, options, rules } = config.material
    const { updateSideframes, conveyorId, sideframesData, isactive, islocked } = this.props
    return (
      <Radio
        key="default-materials-sideframes-material"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 30px 0px 0"
        options={options}
        onChange={(val) =>
          updateSideframes({ conveyorId, updatedSideframesFields: { materialid: val } })
        }
        rules={rules}
        initialValue={sideframesData.materialid}
        vertical
      />
    )
  }

  renderSideframesCleanoutTypeField = (config) => {
    const { dataKey, prettyName, options, rules } = config.cleanouttype
    const { updateSideframes, conveyorId, sideframesData, isactive, islocked } = this.props
    return (
      <Radio
        key="default-materials-sideframes-cleanouttype"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 0 10px 0"
        options={options}
        onChange={(val) =>
          updateSideframes({ conveyorId, updatedSideframesFields: { cleanouttypeid: val } })
        }
        rules={rules}
        initialValue={sideframesData.cleanouttypeid}
        vertical
      />
    )
  }

  renderSideframesPaintedSideframeColorField = (config) => {
    const { dataKey, prettyName, options, rules } = config.standardcolors
    const {
      updateSideframes,
      conveyorId,
      sideframesData: { manualcolor },
      sideframesData,
      isactive,
      islocked,
    } = this.props

    return (
      <>
        <Radio
          key="default-materials-sideframes-standardcolor"
          disabled={!isactive || islocked}
          dataKey={dataKey}
          prettyName={prettyName}
          margin="0 0 10px 0"
          options={options}
          onChange={(val) => {
            updateSideframes({
              conveyorId,
              updatedSideframesFields: {
                [val === '' ? 'manualcolor' : 'standardcolorid']: val,
              },
            })
          }}
          rules={rules}
          initialValue={manualcolor !== undefined ? '' : sideframesData.standardcolorid}
          tinyImages
        />
        {manualcolor !== undefined ? this.renderSideframesRALColorField() : null}
      </>
    )
  }

  renderSideframesRALColorField = () => {
    const { sideframesData, conveyorId, updateSideframes, isactive, islocked } = this.props
    return (
      <span>
        <InputWithValidation
          disabled={!isactive || islocked}
          label="Enter Manual Color"
          id="manualcolor"
          name="manualcolor"
          small
          width="150px"
          type="text"
          defaultValue={sideframesData.manualcolor}
          onChange={(value) => {
            updateSideframes({
              conveyorId,
              updatedSideframesFields: { manualcolor: value },
            })
          }}
        />
      </span>
    )
  }

  renderSideframesPreviewImage = (config) => {
    const previewImage = config.getPreviewImage()
    return previewImage ? <img src={previewImage} alt="Side frames" /> : 'No image'
  }

  render() {
    if (this.props.loading) {
      return null
    }

    const {
      showSideFrames,
      sideframesData: { parameteractive },
      sideframesMeta: { open },
      updateSideframes,
      conveyorId,
      updateDefaultMaterialAcessoriesMetaData,
      isLAProject
    } = this.props

    const config = sideframesConfig(this.props)

    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="materials"
          fieldKey="sideframes"
          checked={parameteractive}
          open={parameteractive && open}
          onClickCheck={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'materials',
              name: 'sideframes',
              updatedFields: {
                open: !parameteractive,
              },
            })
            updateSideframes({
              conveyorId,
              updatedSideframesFields: { parameteractive: !parameteractive },
            })
          }}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'materials',
              name: 'sideframes',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Sideframes"
        >
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.renderSideframesMaterialCoreSpecsField(config)}
              {!isLAProject ? this.renderSideframesCleanoutTypeField(config) : null}
              {this.renderSideframesPreviewImage(config)}
            </div>
            {showSideFrames ? this.renderSideframesPaintedSideframeColorField(config) : null}
          </>
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      ConveyorReducer,
      EstimatorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
    } = state
    const { conveyorId, match } = props
    const {
      params: { versionId },
    } = match
    const sideframesData = EstimatorReducer?.[conveyorId]?.materials?.sideframes
    const sideframesMeta =
      EstimatorMetaReducer?.[conveyorId]?.materialsAccessoriesMetadata?.materials?.sideframes || {}

    if (!sideframesData || !sideframesMeta) {
      return { loading: true }
    }
    const conveyor = ConveyorReducer.conveyors[conveyorId]
    const showSideFrames =
    ProjectReducer.currentlistsettings.materials.sideframes.materials[
        EstimatorReducer[conveyor.id].materials.sideframes.materialid
      ].textvalue === 'PC'

    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isLAProject = ProjectReducer.currentexternalprojecttype == 'la'

    return {
      versionId,
      conveyorId,
      showSideFrames,
      sideframesMeta,
      sideframesData,
      sideframesOptions: ProjectReducer.currentlistsettings.materials.sideframes,
      isactive,
      islocked,
      isLAProject
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateSideframes,
    updateDefaultMaterialAcessoriesMetaData,
  })(Sideframes)
)
