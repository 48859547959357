import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store from './reduxStore'
import { persistStore } from 'redux-persist'
import App from './App'
import './shared/styles/base.scss'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e2de85a7f543479cad4d28ae95956877@o28148.ingest.sentry.io/5264702',
    ignoreErrors: [/Request failed with status code 401$/],
  })
}

export const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
