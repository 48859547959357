import http from 'utils/api/Client'

const ProductService = {
  async createProduct(conveyorId, payload) {
    return await http
      .post(`/Conveyors/${conveyorId}/Product`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateProduct(conveyorId, productId, updatedFields) {
    const patchPayload = Object.entries(updatedFields).map(([key, value]) => ({
      op: 'replace',
      path: `/${key}`,
      value,
    }))
    return await http
      .patch(`/Conveyors/${conveyorId}/Product/${productId}`, patchPayload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async deleteProduct(conveyorId, productId) {
    return await http
      .delete(`/Conveyors/${conveyorId}/Product/${productId}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async getProduct(conveyorId, productId) {
    return await http
      .get(`/Conveyors/${conveyorId}/Product/${productId}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async getProducts(conveyorId) {
    return await http
      .get(`/Conveyors/${conveyorId}/Product`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
}

export default ProductService
