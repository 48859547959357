import {
  setAccessoryDataForConveyorSection,
  runUpdateMaterialOrAccessoryForConveyorSection,
} from '../../../../redux/SectionOverrideActions'

export const initializesectionguiderails = (guiderailsData, sectionId) => async (dispatch) => {
  dispatch(
    setAccessoryDataForConveyorSection({
      sectionId,
      accessoryName: 'guiderails',
      accessoryData: guiderailsData,
    })
  )
}

export const updateGuiderails = ({
  conveyorId,
  updatedGuiderailsFields,
  sideId,
  sideName,
  sectionId,
}) => (dispatch) => {
  dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      sectionId,
      materialKey: 'guiderails',
      updatedFields: updatedGuiderailsFields,
      type: 'accessories',
      sideId,
      sideName,
    })
  )
}
