/**
 * @param {{title?: string, id: number, isdefault: boolean}} option
 * @returns {{label: string, value: number, default: boolean}}
 */
export default function mapDataToOption(option) {
  return {
    label: option?.title || '',
    value: option.id,
    default: option.isdefault,
  }
}
