import { createAction } from '@reduxjs/toolkit'
import { IGearmotor } from 'shared/types/swagger'
import store, { AppDispatch } from 'utils/../reduxStore'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import getNewDefaultId from '../helpers/getNewDefaultId'
import isDSSA47 from '../helpers/isDSSA47'
import { DesiredUnitOptions } from '../types'

export type GearmotorUpdatePayload = Omit<Partial<IGearmotor>, 'id'>

export const updateGearmotorInfo = createAction<{
  conveyorId: string | number;
  gearmotorInfo: Partial<IGearmotor>;
}>('UPDATE_GEARMOTOR_INFO')
export const updateGearmotorDesiredUnitOptions = createAction<{
  conveyorId: number;
  desiredUnitOptions: DesiredUnitOptions;
}>('UPDATE_GEARMOTOR_DESIRED_UNIT_OPTIONS')

export const getGearmotorDesiredUnitOptions = (conveyorId: number) => async (
  dispatch: AppDispatch
) => {
  return new Promise((resolve, reject) => {
    ConveyorService.getGearmotorDesiredUnitOptions(conveyorId).then((desiredUnitOptions) => {
      dispatch(
        updateGearmotorDesiredUnitOptions({
          conveyorId,
          desiredUnitOptions,
        })
      )
      resolve(true)
    })
  })
}

export const getGearmotorForConveyor = (conveyorId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    ConveyorService.getGearmotor(conveyorId)
      .then((gearmotorInfo) => {
        dispatch(
          updateGearmotorInfo({
            conveyorId,
            gearmotorInfo,
          })
        )
        dispatch(getGearmotorDesiredUnitOptions(conveyorId))
        resolve(gearmotorInfo)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          ConveyorService.addGearmotor(conveyorId)
            .then((res) => dispatch(getGearmotorForConveyor(conveyorId)))
            .catch((e) => reject(e))
        }
        resolve(e)
      })
  })
}

export const updateGearmotor = (conveyorId, key, value) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const payload = [
      {
        op: 'replace',
        path: `/${key}`,
        value,
      },
    ]
    ConveyorService.updateGearmotor(conveyorId, payload)
      .then((gearmotorInfo) => {
        dispatch(
          updateGearmotorInfo({
            conveyorId,
            gearmotorInfo,
          })
        )
        resolve(gearmotorInfo)
      })
      .catch((e) => {
        reject(new Error('Failed to update desired unit'))
        throw e
      })
  })
}

export const updateGearmotorOptimistic = (
  conveyorId: number | string,
  data: GearmotorUpdatePayload
) => async (dispatch) => {
  const oldGearmotorData = store.getState().ConveyorReducer.conveyors[conveyorId].gearmotor

  const newGearmotorData: Partial<IGearmotor> = {
    ...oldGearmotorData,
    ...data,
  }

  dispatch(
    updateGearmotorInfo({
      conveyorId,
      gearmotorInfo: newGearmotorData,
    })
  )

  const payload = Object.entries(data).map(([key, value]) => ({
    op: 'replace',
    path: `/${key}`,
    value,
  }))

  await ConveyorService.updateGearmotor(conveyorId, payload).catch((e) => {
    dispatch(
      updateGearmotorInfo({
        conveyorId,
        gearmotorInfo: oldGearmotorData,
      })
    )
    debugger
    throw e
  })
}

export const updateGearmotorByBrandSelection = (
  conveyorId: number,
  gearmotortypeid: number
) => async (dispatch: AppDispatch): Promise<Array<string>> => {
  const gearmotorList = store.getState().ProjectReducer.currentlistsettings.gearmotor
  const {
    ConveyorReducer: { conveyors },
  } = store.getState()

  const conveyor = conveyors[conveyorId]
  const { gearmotor } = conveyor
  const listElement = gearmotorList.drivetypes[gearmotortypeid]
  const _isDSSA47 = isDSSA47(listElement)
  const updatePayload: GearmotorUpdatePayload = {}

  if (_isDSSA47) {
    // Mounting Type
    const newMountingTypeId = getNewDefaultId(
      gearmotorList.eurodrive.selection.mountingtypes,
      gearmotor?.mountingtypeid,
      'mountingtypeid'
    )
    if (newMountingTypeId) updatePayload.mountingtypeid = newMountingTypeId

    // Motor Voltage
    const newMotorVoltageId = getNewDefaultId(
      gearmotorList.motoroutputvoltage,
      gearmotor?.motoroutputvoltageid,
      'motoroutputvoltageid'
    )
    if (newMotorVoltageId) updatePayload.motoroutputvoltageid = newMotorVoltageId

    // Horsepower
    const newHorsepowerId = getNewDefaultId(
      gearmotorList.eurodrive.variablespeed.Inverter,
      gearmotor?.vaconxseriesinverterid,
      'vaconxseriesinverterid'
    )
    if (newHorsepowerId) updatePayload.vaconxseriesinverterid = newHorsepowerId
  }

  const updateKeys = Object.keys(updatePayload)
  const hasDataToUpdate = updateKeys.length > 0

  if (hasDataToUpdate) {
    dispatch(updateGearmotorOptimistic(conveyorId, updatePayload))
  }

  return updateKeys
}
