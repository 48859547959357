import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SectionOverrideHeader } from '../../../shared/Headers'
import { updateUnderguard } from '../../redux/sectionOverrides/UnderguardActions'
import {
  addMaterialOrAccessoryToConveyorSection,
  updateSectionOverrideMaterialAcessoriesMetaData,
} from '../../../../redux/SectionOverrideActions'
import { Radio } from 'antd'
import { captureSentryError } from 'utils/helpers'

class Underguard extends PureComponent {
  render() {
    const {
      addMaterialOrAccessoryToConveyorSection,
      conveyorId,
      sectionId,
      underguardData,
      underguardMeta = { open: false },
      updateUnderguard,
      underguardAccessoriesData,
      updateSectionOverrideMaterialAcessoriesMetaData,
    } = this.props
    const overrideDataExists = Boolean(underguardData)
    const hasOverride = Boolean(underguardData?.parameteractive)
    const { open } = underguardMeta

    return (
      <div className="material-accessory-item">
        <SectionOverrideHeader
          showSingleCheckbox
          checked={underguardData?.parameteractive || false}
          open={open}
          title="Underguard"
          type="accessories"
          conveyorId={conveyorId}
          fieldKey="underguard"
          onClickCheck={async (e) => {
            if (overrideDataExists) {
              const { checked } = e.target
              await updateUnderguard({
                sectionId,
                conveyorId,
                updatedUnderguardFields: { parameteractive: checked },
              })
              if (checked) {
                await updateSectionOverrideMaterialAcessoriesMetaData({
                  conveyorId,
                  sectionId,
                  type: 'accessories',
                  name: 'underguard',
                  updatedFields: {
                    open: true,
                  },
                })
              }
            } else {
              await addMaterialOrAccessoryToConveyorSection({
                materialsOrAccessories: 'accessories',
                sectionId: sectionId,
                conveyorId: conveyorId,
                itemName: 'underguard',
              })
            }
          }}
          onClickOpen={() => {
            updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'accessories',
              name: 'underguard',
              updatedFields: {
                open: !open,
              },
            })
          }}
        >
          <div className="builder-tile__expanded__content__wrapper">
            <div className="radio-title">Underguard on this section?</div>
            <Radio.Group
              onChange={(e) => {
                const { value } = e.target
                const removedforsection = value.toString() !== 'true'
                updateUnderguard({
                  sectionId,
                  conveyorId,
                  updatedUnderguardFields: { removedforsection },
                })
              }}
              defaultValue={
                hasOverride
                  ? (!underguardData.removedforsection).toString()
                  : underguardAccessoriesData?.parameteractive?.toString?.() || 'false'
              }
            >
              <Radio value="true" key="true">
                Yes
              </Radio>
              <Radio value="false" key="false">
                No
              </Radio>
            </Radio.Group>
          </div>
        </SectionOverrideHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, sectionId, match } = props
    const {
      params: { versionId },
    } = match

    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const underguardData = EstimatorReducer?.sections?.[sectionId]?.accessories?.underguard
    const underguardAccessoriesData = EstimatorReducer?.[conveyorId]?.accessories?.underguard
    const underguardMeta = sectionMetadata?.materialsAccessoriesMetadata?.accessories
      ?.underguard || {
      open: false,
    }

    if (!underguardData) {
      return { loading: true, underguardAccessoriesData }
    }

    return {
      versionId,
      conveyorId,
      sectionId,
      underguardMeta,
      underguardData,
      underguardAccessoriesData,
      underguardOptions: ProjectReducer.currentlistsettings.accessories.underguard,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateUnderguard,
    addMaterialOrAccessoryToConveyorSection,
    updateSectionOverrideMaterialAcessoriesMetaData,
  })(Underguard)
)
