import { FormConfigItem } from 'shared/types/formConfig';

export function createSupportConfig({
  key,
  prettyName,
  value,
  message
}: {
  key: string,
  prettyName: string,
  value: number,
  message: string,
}): FormConfigItem {
  const supportConfig: FormConfigItem =
    {
      key,
      type: 'input',
      prettyName,
      value,
      rules: [
        {
          required: true,
          message,
          validator: (rule, value, cb) => {
            try {
              if (value === 0) {
                throw new Error(message)
              }
            } catch (error) {
              cb(error)
              return
            }
            cb()
          }
        },
      ]
    }

  return supportConfig
}