import React from 'react'
import cn from 'classnames'

interface ModalFooterProps {
  children: React.ReactNode;
  direction?: 'vertical' | 'left' | 'right';
  className?: string;
}

function ModalFooter({
  children,
  direction = 'right',
  className = '',
  ...props
}: ModalFooterProps) {
  return (
    <div className={cn('modal-footer', className, `modal-footer--${direction}`)} {...props}>
      {children}
    </div>
  )
}

export default ModalFooter
