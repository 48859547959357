import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { SytelineResponsePlus } from 'features/Admin/components/CostImport/types'
import { PURGE } from 'redux-persist'
import { IPartsUpdateResponse } from 'shared/types/swagger'

export interface ISettingsState {
  unsavedCostUpdate: Record<string, SytelineResponsePlus>
  unsavedStaticParts: Record<string, IPartsUpdateResponse>
}

const INITIAL_STATE: ISettingsState = {
  unsavedCostUpdate: null,
  unsavedStaticParts: null,
}

export default createReducer(INITIAL_STATE, {
  SET_UNSAVED_COST_UPDATE: (state, action: PayloadAction<Record<string, SytelineResponsePlus>>) => {
    state.unsavedCostUpdate = action.payload
  },
  SET_UNSAVED_STATIC_PARTS_UPDATE: (
    state,
    action: PayloadAction<Record<string, IPartsUpdateResponse>>
  ) => {
    state.unsavedStaticParts = action.payload
  },
  [PURGE]: () => INITIAL_STATE,
})
