import * as React from 'react'
import { Dropdown, Menu } from 'antd'
import './ActionMenu.scss'
import { Icon } from '../'
import Colors from '../../constants/colors'
import { capitalize } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import iconsMap from 'shared/components/Icon/iconsMap'

export interface ActionMenuOption {
  className?: string
  icon?: keyof typeof iconsMap
  label?: string
  handleSelect: () => void
  type?: string
  disabled?: boolean
}

interface Props {
  options: Array<ActionMenuOption>
  menuTitle?: string
  menuIcon?: keyof typeof iconsMap
  menuIconPosition?: 'right' | 'left'
  noTitle?: boolean
  className?: string
  type?: string
}

export default function ActionMenu(props: Props) {
  const {
    options,
    menuTitle = 'Actions',
    menuIcon = 'chevronDown',
    menuIconPosition = 'right',
    noTitle = false,
    className,
    type,
  } = props
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const popupContainer = React.useRef(null)
  const previousSelectedElement = React.useRef(null)
  const id = React.useRef(`a${uuidv4()}`)

  React.useEffect(() => {
    if (!popupContainer?.current) {
      const dropdownContainer = document.createElement('DIV')
      document.body.appendChild(dropdownContainer)
      popupContainer.current = dropdownContainer
    }
  }, [])

  React.useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('click', handleDocumentClick)
    } else {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [dropdownVisible])

  function handleDocumentClick(e) {
    if (!popupContainer.current.contains(e.target)) {
      setDropdownVisible(false)
    }
  }

  function goToMenuItem({
    direction,
    first,
    last,
  }: {
    direction?: -1 | 1
    first?: boolean
    last?: boolean
  }) {
    const ul = popupContainer?.current?.querySelector?.(`#${id.current}`)
    if (!ul || !ul?.children?.length) return
    const currentIndex = Array.from(ul?.children || []).indexOf(document.activeElement) || 0

    const liCount = ul.children.length || 0
    const atStart = currentIndex === 0
    const atEnd = currentIndex === liCount - 1

    if (first) {
      ul.children[0].focus()
    } else if (last) {
      ul.children[liCount - 1].focus()
    } else if (atStart && direction === -1) {
      ul.children[liCount - 1].focus()
    } else if (atEnd && direction === 1) {
      ul.children[0].focus()
    } else {
      ul.children[currentIndex + direction].focus()
    }
  }

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    e.stopPropagation()

    if (e.key === 'Escape') {
      setDropdownVisible(false)
    }
    if (e.key === ' ' || e.key === 'Enter') {
      setDropdownVisible((_visible) => {
        if (popupContainer.current) {
          setTimeout(() => {
            const ul = popupContainer?.current?.querySelector?.(`#${id.current}`)
            if (!ul) return
            if (!_visible) {
              previousSelectedElement.current = document.activeElement
              ul.children[0].focus()
            } else {
              if (previousSelectedElement.current) {
                previousSelectedElement.current.focus()
              }
            }
          })
        }
        return !_visible
      })
    }
  }, [])

  return (
    <Dropdown
      overlay={
        <Menu id={id.current} selectable={false}>
          {options.map(
            (action, i): React.ReactNode => {
              const {
                className = '',
                icon,
                label = 'Please add a label',
                handleSelect,
                type,
                disabled,
              } = action

              return (
                <Menu.Item
                  key={i.toString()}
                  className={`action-menu-item ${className}`}
                  onClick={() => {
                    if (!disabled) handleSelect()
                  }}
                  tabIndex={0}
                  disabled={disabled}
                  onKeyDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (disabled) return
                    switch (e.key) {
                      case ' ':
                      case 'Enter':
                        handleSelect()
                        setDropdownVisible(false)
                        break
                      case 'Escape':
                        setDropdownVisible(false)
                        if (previousSelectedElement?.current) {
                          previousSelectedElement.current.focus()
                        }
                        break
                      case 'Home':
                        goToMenuItem({ first: true })
                        break
                      case 'End':
                        goToMenuItem({ last: true })
                        break
                      case 'ArrowUp':
                      case 'ArrowLeft':
                        goToMenuItem({ direction: -1 })
                        break
                      case 'ArrowDown':
                      case 'ArrowRight':
                        goToMenuItem({ direction: 1 })
                        break
                      case 'Tab':
                        goToMenuItem({ direction: e.shiftKey ? -1 : 1 })
                        break
                      default:
                        break
                    }
                  }}
                >
                  <span
                    className={`action-menu-item__link ${type === 'warning' ? 'warning' : ''}`}
                    title={`Action Menu Item ${label}`}
                  >
                    {icon ? (
                      <Icon
                        className="action-menu-item__icon"
                        icon={icon}
                        color={type === 'warning' ? Colors.negative : Colors.ash}
                      />
                    ) : null}
                    {label}
                  </span>
                </Menu.Item>
              )
            }
          )}
        </Menu>
      }
      placement="bottomRight"
      trigger={['hover']}
      onClick={(e) => e.stopPropagation()}
      className={className}
      visible={dropdownVisible}
      onVisibleChange={(visible) => {
        setDropdownVisible(visible)
      }}
      getPopupContainer={() => popupContainer.current}
    >
      <div
        role="button"
        className="action-menu-dropdown"
        title={`${capitalize(type)} Action Menu`}
        tabIndex={0}
        id={`dd-${id.current}`}
        onKeyDown={handleKeyDown}
        aria-controls={id.current}
        aria-expanded={dropdownVisible}
        aria-label="Show"
        aria-labelledby={`dd-${id.current} ${id.current}`}
      >
        {menuIconPosition === 'left' ? (
          <Icon className={menuIcon} icon={menuIcon} color={Colors.novaBlue} size={18} />
        ) : null}
        {noTitle ? null : <span className="action-menu-label">{menuTitle}</span>}
        {menuIconPosition === 'right' ? (
          <Icon className={menuIcon} icon={menuIcon} color={Colors.novaBlue} size={20} />
        ) : null}
      </div>
    </Dropdown>
  )
}
