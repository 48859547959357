import React, { Component } from 'react'
export default class PlusCircleIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="3 3 18 18"
      >
        <path
          fill={color}
          d="M11.7428571,3 C14.061607,3 16.2853869,3.9211198 17.9249907,5.56072357 C19.5645945,7.20032734 20.4857143,9.42410726 20.4857143,11.7428571 C20.4857143,16.5714038 16.5714038,20.4857143 11.7428571,20.4857143 C6.91431047,20.4857143 3,16.5714038 3,11.7428571 C3,6.91431047 6.91431047,3 11.7428571,3 Z M11.7428571,18.5571429 C13.5501181,18.5571429 15.2833583,17.8392112 16.5612848,16.5612848 C17.8392112,15.2833583 18.5571429,13.5501181 18.5571429,11.7428571 C18.5571429,8.98673585 16.8968954,6.502 14.3505714,5.44727804 C11.8042473,4.39255608 8.87330156,4.97555745 6.92442951,6.92442951 C4.97555745,8.87330156 4.39255608,11.8042473 5.44727804,14.3505714 C6.502,16.8968954 8.98673585,18.5571429 11.7428571,18.5571429 Z M15.2785714,10.7785714 C15.8111317,10.7785714 16.2428571,11.2102968 16.2428571,11.7428571 C16.2428571,12.2754174 15.8111317,12.7071429 15.2785714,12.7071429 L12.7071429,12.7071429 L12.7071429,15.2785714 C12.7071429,15.8111317 12.2754174,16.2428571 11.7428571,16.2428571 C11.2102968,16.2428571 10.7785714,15.8111317 10.7785714,15.2785714 L10.7785714,12.7071429 L8.20714286,12.7071429 C7.67458256,12.7071429 7.24285714,12.2754174 7.24285714,11.7428571 C7.24285714,11.2102968 7.67458256,10.7785714 8.20714286,10.7785714 L10.7785714,10.7785714 L10.7785714,8.20714286 C10.7785714,7.67458256 11.2102968,7.24285714 11.7428571,7.24285714 C12.2754174,7.24285714 12.7071429,7.67458256 12.7071429,8.20714286 L12.7071429,10.7785714 L15.2785714,10.7785714 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-614.000000, -1554.000000)">
            <g transform="translate(82.000000, 1532.000000)">
              <g transform="translate(529.000000, 17.000000)">
                <g transform="translate(0.000000, 2.000000)">
                  <g mask="url(#mask-2)" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
