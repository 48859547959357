import React, { Component } from 'react'
export default class LockIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="1 1 30 30"
      >
        <path
          fill={color}
          d="M7.4375,26.5625 L24.3395313,26.5625 L24.3395313,16.313625 L7.4375,16.313625 L7.4375,26.5625 Z M10.82075,10.5619375 C10.82075,7.73646875 13.094125,5.4375 15.8887187,5.4375 C18.6833125,5.4375 20.9562812,7.73646875 20.9562812,10.5619375 L20.9562812,13.876125 L10.82075,13.876125 L10.82075,10.5619375 Z M25.5582813,13.876125 L23.3937812,13.876125 L23.3937812,10.5619375 C23.3937812,6.39259375 20.0267812,3 15.8887188,3 C11.75025,3 8.38325,6.39259375 8.38325,10.5619375 L8.38325,13.876125 L6.21875,13.876125 C5.54559375,13.876125 5,14.4217188 5,15.094875 L5,27.78125 C5,28.4544062 5.54559375,29 6.21875,29 L25.5582813,29 C26.2318437,29 26.7770313,28.4544062 26.7770313,27.78125 L26.7770313,15.094875 C26.7770313,14.4217188 26.2318437,13.876125 25.5582813,13.876125 L25.5582813,13.876125 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g mask="url(#mask-2)" fill="none">
            <rect x="0" y="0" width="32" height="32"></rect>
          </g>
        </g>
      </svg>
    )
  }
}
