export enum SCREEN_STATE {
  LOADING = 'LOADING',
  DEFAULT = 'DEFAULT',
  EDITING = 'EDITING',
  SAVING = 'SAVING',
  EDITED = 'EDITED',
  ERROR = 'ERROR',
  VALIDATING = 'VALIDATING',
}

export type ScreenStateKey = keyof typeof SCREEN_STATE
