import React, { Component } from 'react'
export default class SaveIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M16.000048,22.6733838 C17.4115988,22.6733838 18.5599547,21.5250279 18.5599547,20.1134771 C18.5599547,18.701446 17.4115988,17.5530901 16.000048,17.5530901 C14.5884972,17.5530901 13.4401413,18.701446 13.4401413,20.1134771 C13.4401413,21.5250279 14.5884972,22.6733838 16.000048,22.6733838 Z M16.000048,15.6319594 C18.4711024,15.6319594 20.4810854,17.6419424 20.4810854,20.1134771 C20.4810854,22.5840512 18.4711024,24.5945145 16.000048,24.5945145 C13.5289936,24.5945145 11.5190106,22.5840512 11.5190106,20.1134771 C11.5190106,17.6419424 13.5289936,15.6319594 16.000048,15.6319594 Z M27.1183039,27.4204017 L27.1183039,10.2656649 L23.4446217,6.59198271 L23.4446217,11.6349509 C23.4446217,12.1651829 23.0142884,12.5955162 22.4840563,12.5955162 L9.51642395,12.5955162 C8.98571159,12.5955162 8.55585859,12.1651829 8.55585859,11.6349509 L8.55585859,4.88169608 L4.88169608,4.88169608 L4.88169608,27.4204017 L27.1183039,27.4204017 Z M10.4769893,10.6743855 L21.523491,10.6743855 L21.523491,4.88169608 L10.4769893,4.88169608 L10.4769893,10.6743855 Z M29.5778315,8.65047428 C29.8482307,8.92039315 30,9.28684883 30,9.66915384 L30,28.8612498 C30,29.6570782 29.3549804,30.3020978 28.559152,30.3020978 L3.44084804,30.3020978 C2.64501964,30.3020978 2,29.6570782 2,28.8612498 L2,3.44084804 C2,2.64501964 2.64501964,2 3.44084804,2 L22.3308462,2 C22.7131512,2 23.0796069,2.15224961 23.3495257,2.42216848 L29.5778315,8.65047428 Z"
            fill={color}
          ></path>
        </g>
      </svg>
    )
  }
}
