import React, { Component } from 'react'
export default class DeleteIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M12,0.342857143 C15.0916665,0.342857143 18.0567064,1.57101688 20.2428448,3.75715524 C22.4289831,5.94329359 23.6571429,8.90833349 23.6571429,12 C23.6571429,18.4380622 18.4380622,23.6571429 12,23.6571429 C5.56193777,23.6571429 0.342857143,18.4380622 0.342857143,12 C0.342857143,5.56193777 5.56193777,0.342857143 12,0.342857143 Z M12,21.0857143 C14.4096813,21.0857143 16.7206682,20.1284721 18.4245702,18.4245702 C20.1284721,16.7206682 21.0857143,14.4096813 21.0857143,12 C21.0857143,8.3251716 18.8720511,5.01219048 15.4769523,3.60589453 C12.0818536,2.19959859 8.17392589,2.97693374 5.57542982,5.57542982 C2.97693374,8.17392589 2.19959859,12.0818536 3.60589453,15.4769523 C5.01219048,18.8720511 8.3251716,21.0857143 12,21.0857143 Z M16.2428571,7.75714286 C16.732898,8.25708451 16.732898,9.0572012 16.2428571,9.55714286 L13.8,12 L16.2428571,14.4428571 C16.6267589,14.9547261 16.5758555,15.6709905 16.123423,16.123423 C15.6709905,16.5758555 14.9547261,16.6267589 14.4428571,16.2428571 L12,13.8 L9.55714286,16.2428571 C9.2552951,16.6453208 8.74644567,16.8347631 8.25489522,16.727678 C7.76334477,16.6205928 7.37940718,16.2366552 7.27232203,15.7451048 C7.16523687,15.2535543 7.35467918,14.7447049 7.75714286,14.4428571 L10.2,12 L7.75714286,9.55714286 C7.37324114,9.0452739 7.42414448,8.32900951 7.876577,7.876577 C8.32900951,7.42414448 9.0452739,7.37324114 9.55714286,7.75714286 L12,10.2 L14.4428571,7.75714286 C14.9427988,7.26710202 15.7429155,7.26710202 16.2428571,7.75714286 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(4.000000, 4.000000)">
            <g mask="url(#mask-2)" fill="none">
              <g transform="translate(-4.000000, -4.000000)">
                <rect x="0" y="0" width="32" height="32"></rect>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
