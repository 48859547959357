import { formatCurrency } from '../../../../utils/helpers'

export const usersByRoleConfig = [
  {
    title: 'Project Name',
    dataIndex: 'projectname',
    key: 'projectname',
    width: '150px',
    ellipsis: true,
    textWrap: 'word-break',
  },
  {
    title: 'Employee',
    dataIndex: 'employeename',
    key: 'employeename',
    width: '120px',
  },
  {
    title: 'Status',
    dataIndex: 'statuslabel',
    key: 'statuslabel',
    width: '140px',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    className: 'price',
    align: 'right',
    render: (v) => formatCurrency(v),
  },
]

export const userTypes = [
  {
    key: 'AccountManager',
    title: 'Account Managers',
    icon: 'accountManager',
  },
  {
    key: 'Estimator',
    title: 'Estimators',
    icon: 'estimator',
  },
  {
    key: 'Designer',
    title: 'Designers',
    icon: 'designer',
  },
  {
    key: 'Manager',
    title: 'Managers',
    icon: 'manager',
  },
]
