import { roleOptions } from 'shared/constants/userRoles'

export default (newUserInfo) => [
  {
    key: 'firstname',
    type: 'input',
    cols: 12,
    label: 'First Name',
    placeholder: 'Enter First Name',
    rules: [
      {
        required: true,
        message: 'First Name is required.',
      },
    ],
  },
  {
    key: 'lastname',
    type: 'input',
    cols: 12,
    label: 'Last Name',
    placeholder: 'Enter Last Name',
    rules: [
      {
        required: true,
        message: 'Last Name is required.',
      },
    ],
  },
  {
    key: 'username',
    type: 'input',
    cols: 12,
    label: 'Username',
    placeholder: 'Enter Username',
    rules: [
      {
        required: true,
        message: 'Username is required.',
      },
    ],
  },
  {
    key: 'email',
    type: 'input',
    cols: 12,
    label: 'Email Address',
    placeholder: 'Enter Email Address',
  },
  {
    key: 'roles',
    type: 'select',
    cols: 12,
    label: 'User Type',
    placeholder: 'Select User Type',
    rules: [
      {
        required: true,
        message: 'At least one User Type is required.',
      },
    ],
    options: roleOptions,
  },
  {
    cols: 12,
    type: 'radio',
    key: 'issparepartuser',
    label: 'Spare Parts User',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    value: 'false',
  },
  {
    key: 'password',
    type: 'input',
    cols: 12,
    label: 'Password',
    placeholder: 'Enter Password to be used',
    rules: [
      {
        required: true,
        validator: (rule, value, cb) => {
          try {
            if (value === '') {
              throw new Error('Please enter the password to be used.')
            } else if (value.length < 4) {
              throw new Error('Password must have at least 4 characters.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
  {
    key: 'password_check',
    type: 'input',
    cols: 12,
    label: 'Re-enter Password',
    placeholder: 'Enter Password to be used',
    rules: [
      {
        required: true,
        validator: (rule, value, cb) => {
          try {
            if (value === '') {
              throw new Error('Please re-enter the password.')
            } else if (value !== newUserInfo.password) {
              throw new Error('Passwords do not match.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
]
