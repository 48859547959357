import * as React from 'react'
import { captureSentryError } from 'utils/helpers'
import './ScreenErrorBoundary.scss'
import { Button, Icon, Form } from 'shared/components'
import { Input } from 'antd'
import { debounce } from 'lodash'
import * as Sentry from '@sentry/browser'
import localforage from 'localforage'

const INITIAL_STATE = {
  hasError: false,
  errorMessage: '',
}

/**
 * @typedef {import('react-router-dom').RouteProps['location']} RouteLocation
 *
 * @typedef {Object} Props
 * @prop {React.ReactChild|React.ReactChild[]} children
 * @prop {RouteLocation} location
 */

/**
 * Log error and display information to the user
 *
 * @extends {React.Component<Props>}
 */
class ScreenErrorBoundary extends React.Component {
  state = INITIAL_STATE

  static getDerivedStateFromError(error) {
    console.log(error)
    return {
      hasError: true,
      errorMessage: error.message,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(INITIAL_STATE)
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo)
    captureSentryError(error)
  }

  render() {
    const { hasError, errorMessage } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <main className="error-boundary">
          <h1 className="error-boundary__heading">
            <Icon
              icon="caution"
              className="error-boundary__heading-icon"
              size={56}
              colorKey="bourbon"
            />
            <span>Something&apos;s gone wrong</span>
          </h1>
          <p className="error-boundary__desc">
            Our web team has been notified and will work to fix the issue.
          </p>
          <p className="error-boundary__desc">
            Try reloading the page or logging out of your account. If the problem still persists,
            please contact support.
          </p>

          <div className="error-boundary__actions">
            <Button
              small
              text="Reload Page"
              onClick={() => {
                window.location.href = this.props.location.pathname
              }}
            />
            <Button
              secondary
              small
              text="Log Out"
              onClick={() => {
              localforage.clear(() => {
                window.location = window.location.host
              })
            }}
            />
            <Button
              secondary
              small
              link
              text="Contact Support"
              href="https://spantechconveyors.com/contact-us/"
            />
          </div>

          <div className="error-boundary__log">
            <pre>{errorMessage}</pre>
          </div>
        </main>
      )
    }

    return children
  }
}

export default ScreenErrorBoundary
