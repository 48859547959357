export default function getInfoIconViewbox(type: string) {
  switch (type) {
    case 'Angle':
      return '0 0 120 120'

    case 'Radius':
      return '0 0 150 150'

    case 'Length':
      return '0 0 350 450'

    case 'Incline':
      return '0 0 400 380'

    case 'Decline':
      return '0 0 400 380'

    default:
      return '0 0 512 512'
  }
}
