export default {
  legend: {
    soldcount: 'Sold Qty',
    soldamount: 'Sold Orders',
    estimatescount: 'Estimate Qty',
    estimatesamount: 'Estimates',
    soldratio: 'Percent Sold',
    quotedratio: 'Percent Quoted',
  },
  tooltip: {
    soldcount: 'Sold Orders',
    soldamount: 'Sold',
    estimatescount: 'Estimated Orders',
    estimatesamount: 'Estimated',
    soldratio: 'Sold',
    quotedratio: 'Quoted',
  },
}
