import { createAction } from '@reduxjs/toolkit'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import ConveyorSectionService from 'utils/api/conveyor/ConveyorSectionService'
import { batch } from 'react-redux'
import store from 'utils/../reduxStore'
import _ from 'lodash'
import {
  fetchDefaultAccessoryForConveyor,
  fetchDefaultMaterialForConveyor,
} from './EstimatorActions'
import * as sectionMaterialInitializeActions from '../components/Materials/redux/sectionOverrides'
import * as sectionAccessoryInitializeActions from '../components/Accessories/redux/sectionOverrides'

//  __  __         _
// |  \/  |  ___  | |_   __ _
// | |\/| | / -_) |  _| / _` |
// |_|  |_| \___|  \__| \__,_|

export const initializeSectionOverrideMaterialsAcessoriesMetadata = createAction(
  'INITIALIZE_SECTION_OVERRIDE_MATERIAL_ACCESSORIES_METADATA'
)
export const updateSectionOverrideMaterialAcessoriesMetaData = createAction(
  'UPDATE_SECTION_OVERRIDE_MATERIAL_ACCESSORIES_META_DATA'
)

//  ___           _
// |   \   __ _  | |_   __ _
// | |) | / _` | |  _| / _` |
// |___/  \__,_|  \__| \__,_|

export const setMaterialDataForConveyorSection = createAction(
  'SET_MATERIAL_DATA_FOR_CONVEYOR_SECTION'
)
export const setAccessoryDataForConveyorSection = createAction(
  'SET_ACCESSORY_DATA_FOR_CONVEYOR_SECTION'
)
export const updateMaterialOrAccessoryFieldForConveyorSection = createAction(
  'UPDATE_MATERIAL_OR_ACCESSORY_FIELD_FOR_CONVEYOR_SECTION'
)
export const updateMaterialOrAccessoryForConveyorSection = createAction(
  'UPDATE_MATERIAL_OR_ACCESSORY_FOR_CONVEYOR_SECTION'
)

export const fetchMaterialsForConveyorSection = (conveyorId, sectionId) => async (dispatch) => {
  const sectionMaterials = await ConveyorService.getMaterialsForConveyorSection(sectionId)

  batch(() => {
    _.forEach(sectionMaterials, (materialData, materialName) => {
      const actionName = `initializesection${materialName}`
      if (sectionMaterialInitializeActions[actionName]) {
        dispatch(sectionMaterialInitializeActions[actionName](materialData, sectionId))
      }
    })
  })
}

export const fetchAccessoriesForConveyorSection = (conveyorId, sectionId) => async (dispatch) => {
  const sectionAccessories = await ConveyorService.getAccessoriesForConveyorSection(sectionId)

  batch(() => {
    _.forEach(sectionAccessories, (accessoryData, accessoryName) => {
      const actionName = `initializesection${accessoryName}`
      if (sectionAccessoryInitializeActions[actionName]) {
        dispatch(sectionAccessoryInitializeActions[actionName](accessoryData, sectionId))
      }
    })
  })
}

export const addMaterialOrAccessoryToConveyorSection = ({
  materialsOrAccessories,
  itemName,
  conveyorId,
  sectionId,
  payload,
}) => async (dispatch) => {
  let itemAdded
  const isMaterials = materialsOrAccessories === 'materials'
  if (isMaterials) {
    itemAdded = await ConveyorSectionService.addSectionMaterial(sectionId, itemName, payload)
  } else {
    itemAdded = await ConveyorSectionService.addSectionAccessory(sectionId, itemName, payload)
  }
  const actionName = `initializesection${itemName}`
  const collection = isMaterials
    ? sectionMaterialInitializeActions
    : sectionAccessoryInitializeActions
  batch(() => {
    dispatch(collection[actionName](itemAdded, sectionId))
    dispatch(
      initializeSectionOverrideMaterialsAcessoriesMetadata({
        conveyorId,
        sectionId,
      })
    )
    dispatch(
      updateSectionOverrideMaterialAcessoriesMetaData({
        conveyorId,
        sectionId,
        type: materialsOrAccessories,
        name: itemName,
        updatedFields: {
          open: true,
        },
      })
    )
    if (isMaterials) {
      dispatch(fetchDefaultMaterialForConveyor(conveyorId, itemName))
    }
  })
}

export const addAccessoryWithSidesToConveyorSection = ({
  itemName,
  conveyorId,
  sectionId,
  sideOptions,
  defaultSides = { L: true, R: true },
  defaultData = { left: {}, right: {} },
}) => async (dispatch) => {
  const leftRequest = ConveyorSectionService.addSectionAccessory(sectionId, itemName, {
    ...defaultData.left,
    parameteractive: defaultSides.L,
    removedforsection: !defaultData?.left?.parameteractive,
    sideid: _.find(sideOptions, (so) => so.title === 'Left').id,
  })

  const rightRequest = ConveyorSectionService.addSectionAccessory(sectionId, itemName, {
    ...defaultData.right,
    parameteractive: defaultSides.R,
    removedforsection: !defaultData?.right?.parameteractive,
    sideid: _.find(sideOptions, (so) => so.title === 'Right').id,
  })

  const newAccessory = {}

  Promise.all([leftRequest, rightRequest]).then((values) => {
    const [leftResponse, rightResponse] = values
    newAccessory.left = leftResponse
    newAccessory.right = rightResponse

    const actionName = `initializesection${itemName}`

    batch(() => {
      const collection = sectionAccessoryInitializeActions

      dispatch(collection[actionName](newAccessory, sectionId))
      dispatch(
        initializeSectionOverrideMaterialsAcessoriesMetadata({
          conveyorId,
          sectionId,
        })
      )
      dispatch(fetchDefaultAccessoryForConveyor(conveyorId, itemName))
    })
  })
}

export const runUpdateMaterialOrAccessoryForConveyorSection = ({
  conveyorId,
  sectionId,
  materialKey,
  updatedFields,
  type, // 'materials' or 'accessories'
  sideId = null, // optional sideId for guiderails and sidewalls. Needed for the api call for items with sides.
  sideName = null, // optional sideName for guiderails and sidewalls. Needed for patching backend redux state.
  saveToBackend = true,
}) => async (dispatch) => {
  const oldState = store.getState().EstimatorReducer.sections[sectionId][type][materialKey]

  batch(() => {
    _.forEach(updatedFields, (value, key) => {
      dispatch(
        updateMaterialOrAccessoryFieldForConveyorSection({
          key,
          value,
          conveyorId,
          sectionId,
          materialKey,
          type,
          sideName,
        })
      )
    })
  })
  if (saveToBackend) {
    try {
      const response = await ConveyorService.updateMaterialOrAccessoryForConveyorSection(
        sectionId,
        materialKey,
        updatedFields,
        type,
        sideId
      )
      dispatch(
        updateMaterialOrAccessoryForConveyorSection({
          sectionId,
          type,
          materialKey,
          sideName,
          updatedFields: response,
        })
      )
    } catch (e) {
      dispatch(
        updateMaterialOrAccessoryFieldForConveyorSection({
          conveyorId,
          sectionId,
          materialKey,
          oldState,
          type,
          sideName,
        })
      )
    }
  }
}

export const updateAccessoryWithSides = ({
  sectionId,
  conveyorId,
  checked,
  materialKey,
  updatedFields,
  sideIds,
}) => async (dispatch) => {
  await dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      sectionId,
      materialKey,
      updatedFields,
      type: 'accessories',
      sideId: sideIds.left,
      sideName: 'left',
    })
  )
  await dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      sectionId,
      materialKey,
      updatedFields,
      type: 'accessories',
      sideId: sideIds.right,
      sideName: 'right',
    })
  )
  if (checked) {
    await updateSectionOverrideMaterialAcessoriesMetaData({
      conveyorId,
      sectionId,
      type: 'accessories',
      name: materialKey,
      updatedFields: {
        open: true,
      },
    })
  }
  await fetchDefaultAccessoryForConveyor(conveyorId, materialKey)
}
