import { runUpdateDefaultMaterialOrAccessoryForConveyor } from '../../../../redux/EstimatorActions'

export const updateSideframes = ({ conveyorId, updatedSideframesFields }) => (dispatch) => {
  if (updatedSideframesFields.standardcolorid) {
    updatedSideframesFields.manualcolor = undefined
  }
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'sideframes',
      updatedFields: updatedSideframesFields,
      type: 'materials',
    })
  )
}
