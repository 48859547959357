import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Icon, Button } from '../../shared/components'
import { closeConfirmModal } from '../../shared/redux/ScreenActions'
import colors from '../../shared/constants/colors'
import './ConfirmModal.scss'
import { captureSentryError } from 'utils/helpers'
import { Store } from 'srcReducer'

const focusableElements = [
  'button',
  '[href]',
  'input',
  'select',
  'textarea',
  '[tabindex]:not([tabindex="-1"]',
]

type ConfirmModalProps = PropsFromRedux

class ConfirmModal extends Component<ConfirmModalProps> {
  prevActiveElement: HTMLElement = null
  firstFocusableElement: HTMLElement = null
  lastFocusableElement: HTMLElement = null
  modalRef = React.createRef<HTMLDivElement>()

  handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.closeModal()
    } else if (e.key === 'Tab') {
      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === this.firstFocusableElement) {
          this.lastFocusableElement.focus() // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === this.lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          this.firstFocusableElement.focus() // add focus for the first focusable element
          e.preventDefault()
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.bodyText !== this.props.bodyText ||
      prevProps.bodyInnerHTML !== this.props.bodyInnerHTML
    ) {
      if (this.props.bodyText || this.props.bodyInnerHTML) {
        // type casting is required here as activeElement returns type Element which can technically include XML.
        // however type casting should be safe here as we are only using HTML in the modal
        this.prevActiveElement = document.activeElement as HTMLElement
        const focusables: HTMLElement[] = Array.from(
          this.modalRef?.current?.querySelectorAll?.(focusableElements.join(',')) || []
        )
        if (!focusables.length) {
          console.warn('Accessibility Warning: No focusable elements on modal')
          return
        }
        this.firstFocusableElement = focusables[0]
        this.lastFocusableElement = focusables[focusables.length - 1];
        this.firstFocusableElement.focus()

        document.addEventListener('keydown', this.handleKeydown)
      } else {
        document.removeEventListener('keydown', this.handleKeydown)
      }
    }
  }

  renderBodyText = () => {
    const { bodyText, bodyInnerHTML } = this.props
    if (bodyInnerHTML) {
      return (
        <section
          className="spantech__confirm-modal__body"
          dangerouslySetInnerHTML={{ __html: bodyInnerHTML }}
          id="dialogDesc"
        />
      )
    }
    if (typeof bodyText === 'string') {
      const lines = bodyText.split('\n')
      return lines.map((line, i) => {
        return (
          <p
            key={i}
            className="spantech__confirm-modal__body-text"
            data-testid="modalbodytext"
            id="dialogDesc"
          >
            {line}
            {i === lines.length - 1 ? <span id="confirmation-modal-last-line"></span> : null}
          </p>
        )
      })
    }
    if (Array.isArray(bodyText)) {
      return (
        <ul className="spantech__confirm-modal__body-text--ul" id="dialogDesc">
          {bodyText.map((text, i) => (
            <li key={i}>{text}</li>
          ))}
        </ul>
      )
    }
    return null
  }

  closeModal = () => {
    if (this.prevActiveElement) {
      this.prevActiveElement.focus()
    }
    this.props.closeConfirmModal()
  }

  render() {
    const {
      bodyText,
      bodyInnerHTML,
      headerText = 'Confirm',
      onCancel,
      onConfirm,
      cancelButton,
      cancelButtonText = 'Cancel, Keep',
      confirmButtonText = 'Yes, Delete',
      closeButton,
      disableCancel = false,
      disableConfirm = false,
    } = this.props

    if (!bodyText && !bodyInnerHTML) {
      return null
    }

    const showSecondaryColor = confirmButtonText !== 'OK' 
      && confirmButtonText !== 'Continue' 
      && confirmButtonText !== 'Save'

    const unsupportedBrowser = headerText === 'Unsupported Browser'
    return (
      <div
        className="spantech__confirm-modal"
        role="dialog"
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDesc"
        ref={this.modalRef}
      >
        <div className="spantech__confirm-modal__container">
          {closeButton && !unsupportedBrowser && (
            <Icon
              icon="x"
              color={colors.novaBlue}
              size={20}
              className="spantech__confirm-modal__container__close"
              onClick={() => {
                if (onCancel) {
                  onCancel()
                }
                this.closeModal()
              }}
            />
          )}
          <h2
            className="spantech__confirm-modal__header-text"
            data-testid="modalheadertext"
            id="dialogTitle"
          >
            {unsupportedBrowser && <Icon icon="caution" size={42} color={colors.bourbon} />}
            {headerText}
          </h2>
          {this.renderBodyText()}
          <div className="spantech__confirm-modal__container__buttons">
            {cancelButton ? (
              <Button
                text={cancelButtonText}
                small
                disabled={disableCancel}
                secondary
                data-testid="modalcancel"
                onClick={() => {
                  if (onCancel) {
                    onCancel()
                  }
                  this.closeModal()
                }}
              />
            ) : null}
            {!unsupportedBrowser && (
              <Button
                text={confirmButtonText}
                small
                disabled={disableConfirm}
                secondary={showSecondaryColor}
                danger={showSecondaryColor}
                data-testid="modalconfirm"
                onClick={() => {
                  if (onConfirm) {
                    onConfirm()
                  }
                  this.closeModal()
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: Store) => {
  try {
    const { ScreenReducer } = state
    return { ...ScreenReducer.confirmModal }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const connector = connect(mapStateToProps, { closeConfirmModal })
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ConfirmModal)
