import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { Checkbox, Radio, InputWithValidation, Select } from 'shared/components'
import { gearmotorConfig, availableDrivesConfig } from './gearmotorConfig'
import './Gearmotor.scss'
import { updateConveyorOptimistic } from '../../../../Conveyor/redux/ConveyorActions'
import {
  getGearmotorForConveyor,
  updateGearmotor,
  updateGearmotorOptimistic,
} from './redux/GearmotorActions'
import { typedUseSelector } from 'utils/helpers'
import { useAppDispatch } from 'shared/hooks/app'

type GearmotorProps = {
  conveyorId: number
}

function Gearmotor(props: GearmotorProps) {
  const { conveyorId } = props
  const conveyor = typedUseSelector((state) => state.ConveyorReducer.conveyors?.[conveyorId])
  const { gearmotor } = conveyor || {}
  const { versionId } = useParams<{ versionId: string }>()
  const { isactive, islocked } = typedUseSelector(
    (state) => state.ProjectReducer.versions[versionId]
  )
  const gearmotorList = typedUseSelector((state) => state.ProjectReducer.currentlistsettings.gearmotor)
  const gearmotorTitle = gearmotorList?.['drivetypes']?.[conveyor.gearmotortypeid]?.title
  const displayGearboxesOnly = gearmotorTitle === 'Get Torque Arm/Extr. Kit/Drive Shaft Only'
  const displayBrandOnly = gearmotorTitle === 'Other'
  const desiredUnitOptions = _.sortBy(
    typedUseSelector(
      (state) =>
        state.EstimatorMetaReducer?.[conveyorId]?.gearmotor?.desiredUnitOptions?.[
          conveyor?.gearmotor?.desiredunitid
        ]?.options || []
    ),
    (o) => o.listorder
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getGearmotorForConveyor(conveyorId))
  }, [conveyorId, dispatch])

  async function onChange(value, errors, values) {
    const key = Object.keys(values)[0]
    if (key === 'gearmotortypeid' || key === 'gearboxid') {
      dispatch(updateConveyorOptimistic(conveyorId, values))
    } else {
      if (key === 'desiredunitid') {
        dispatch(updateGearmotor(conveyorId, key, value))
      } else {
        dispatch(updateGearmotorOptimistic(conveyorId, key, value))
      }
    }
  }

  function renderForm(config) {
    const disabled = !isactive || islocked

    const formFields = config({
      conveyor,
      displayGearboxesOnly,
      displayBrandOnly,
      gearmotor,
      gearmotorList,
      desiredUnitOptions,
    }).map((field, i) => {
      if (field.visible) {
        switch (field.type) {
          case 'inputs':
            return (
              <div key={`${field.type}-${i}`} className="inputs">
                {field.inputs.map((field) => (
                  <InputWithValidation
                    key={field.dataKey}
                    disabled={disabled}
                    dataKey={field.dataKey}
                    name={field.dataKey}
                    label={field.prettyName}
                    onChange={onChange}
                    rules={field.rules}
                    width={300}
                    margin="0 30px 15px 0"
                    defaultValue={field.value}
                    placeholder={field.placeholder}
                  />
                ))}
              </div>
            )

          case 'radio':
            return (
              <Radio
                key={field.dataKey}
                disabled={disabled}
                dataKey={field.dataKey}
                prettyName={field.prettyName}
                margin="0 0 10px 0"
                options={field.options}
                onChange={onChange}
                rules={field.rules}
                vertical={field.vertical}
                initialValue={field.value}
              />
            )

          case 'checkbox':
            return (
              <Checkbox
                key={field.dataKey}
                disabled={disabled}
                dataKey={field.dataKey}
                label={field.prettyName}
                onChange={onChange}
                checked={field.value}
                extra={field.extra}
              />
            )

          case 'checkbox_list': {
            const checkboxList = _.map(field.options, (o, i) => {
              return (
                <Checkbox
                  key={i}
                  disabled={disabled}
                  className="checkbox-list__item"
                  dataKey={field.dataKey}
                  label={o.label}
                  onChange={() => onChange(o.id, null, { [field.dataKey]: o.id })}
                  initialValue={field.value === o.id}
                  checked={field.value === o.id}
                />
              )
            })
            return field.options.length ? (
              <div key={field.dataKey} className="checkbox-list">
                {checkboxList}
              </div>
            ) : null
          }
          case 'select':
            return (
              <Select
                key={field.dataKey}
                disabled={disabled}
                label={field.prettyName}
                small
                defaultValue={field.value}
                width={200}
                placeholder={field.placeholder || 'Select Option'}
                options={field.options}
                onSelect={(v) => onChange(v, null, { [field.dataKey]: v })}
              />
            )

          default:
            return null
        }
      }
      return null
    })
    formFields.filter((v) => v)
    return formFields
  }

  const showBottom = !(displayGearboxesOnly || displayBrandOnly)

  if (!gearmotor) return null

  return (
    <div className={`gearmotor-container ${isactive && !islocked ? '' : 'disabled'}`}>
      {renderForm(gearmotorConfig)}
      {showBottom ? (
        <>
          <div className="subtitle">Available Drives</div>
          <div className="gm-meta-info">
            <div>
              <span>Required Shaft Torque:</span>
              <span>
                {conveyor.requiredtorque} {conveyor.unit === 'English' ? 'in-lbs' : 'n-ms'}
              </span>
            </div>
            <div>
              <span>Speed:</span>
              <span>
                {conveyor.speed} {conveyor.unit === 'English' ? 'ft/min' : 'm/min'}
              </span>
            </div>
          </div>
          {renderForm(availableDrivesConfig)}
        </>
      ) : null}
    </div>
  )
}

export default Gearmotor
