import * as React from 'react'
import { Icon } from 'shared/components'
import { Tooltip } from 'antd'
import cn from 'classnames'

interface Props {
  disabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabledReason?: string
  children: React.ReactNode
}

export default function AddSectionButton(props: Props) {
  const { disabled, onClick, children, disabledReason } = props

  function handleClick(e) {
    if (!disabled) onClick(e)
  }

  const inner = (
    <button
      className={cn('section-type-buttons__button', { disabled })}
      disabled={disabled}
      onClick={handleClick}
    >
      <Icon icon="add" size={16} colorKey={disabled ? 'silver' : 'darkAsh'} />
      {children}
    </button>
  )

  // Add Tooltip wrapper to explain the disabled state
  if (disabled) {
    return (
      <Tooltip mouseEnterDelay={0} mouseLeaveDelay={0} overlay={disabledReason}>
        {inner}
      </Tooltip>
    )
  }

  return inner
}
