import React, { Component } from 'react'
export default class DownloadIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="2 2 28 28"
        fill={color}
      >
        <g>
          <path
            d="M16,23.1L7.9,15c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0l4.8,4.8V5.4C14.6,4.6,15.3,4,16,4c0.8,0,1.4,0.6,1.4,1.4
            v12.4l4.8-4.8c0.5-0.5,1.4-0.5,1.9,0c0.5,0.5,0.5,1.4,0,1.9L16,23.1z M24.7,25.1c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4H7.4
            c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4H24.7z"
          />
        </g>
      </svg>
    )
  }
}
