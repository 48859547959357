import React from 'react'
import { IVersionWithAutorizedActions } from 'features/Version/shared/types'
import { IProject } from 'shared/types/swagger'

import { Select } from 'shared/components'

import { typedUseSelector } from 'utils/helpers'
import { useMemo } from 'react'
import { useAppDispatch } from 'shared/hooks/app'
import { updateVersion } from 'features/Project/redux/ProjectOperations'

type ProjectOwnerButtonProps = {
  project: IProject,
  version: IVersionWithAutorizedActions,
}

export default function ProjectOwnerButton({ project, version }: ProjectOwnerButtonProps) {
  const dispatch = useAppDispatch()
  const customerUsers = typedUseSelector(state => state.ListReducer.customerUsers)
  const userOptions = useMemo(() => customerUsers?.map((option) => ({
    value: option.id,
    label: `${option.firstname} ${option.lastname}`,
  })), [customerUsers])

  async function updateProjectOwner(value: string) {
    await dispatch(updateVersion({ payload: { userid: value }, versionId: version.id }))
  }
  if (!project?.isexternalproject || !version) return null

  return <Select
    small
    label='Project Owner'
    options={userOptions}
    loading={false}
    value={version.userid}
    width={200}
    onSelect={updateProjectOwner}
  />
}