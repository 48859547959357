export default (props) => {
  const { fixedEndstopOptions } = props
  return {
    mountinglocation: {
      dataKey: 'mountinglocationid',
      required: true,
      prettyName: 'Mounting Location',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'A Mounting Location is required.',
        },
      ],
      options: fixedEndstopOptions.mountinglocations,
    },
  }
}
