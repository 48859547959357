import * as React from 'react'
import { Input } from 'shared/components'

interface Props {
  id: number
  defaultValue: string | boolean | number
  handleChange: (string) => void
  excluded: boolean
}

function MonetaryCell(props: Props) {
  const { id, defaultValue, handleChange, excluded } = props
  const [value, setValue] = React.useState(defaultValue)

  return (
    <Input
      prefix="$"
      small
      key={id}
      type="text"
      value={value as string}
      disabled={excluded}
      pattern="^(?:[0-9]+(?:[.][0-9]{0,4})?|[.][0-9]{0,4})?$"
      className="cost-import__input"
      onChange={(val) => {
        setValue(val as string)
        handleChange(val)
      }}
      onBlur={() => {
        if (!value || value === '.') setValue(0)
      }}
    />
  )
}

export default React.memo(MonetaryCell)
