import React from 'react'
import _ from 'lodash'
import { BuilderInfeedDischarge, BuilderTile } from '.'
import getItemStyle from '../helpers/getItemStyle'
import { Draggable } from 'react-beautiful-dnd'
import { Element } from 'react-scroll'
import { IConveyorSection } from 'shared/types/swagger'
import { IConveyorWithModifiedInfeedDischarge } from 'features/Conveyor/shared/types'

interface Props {
  conveyor: IConveyorWithModifiedInfeedDischarge;
  type?: string;
}

export default function SectionsList(props: Props) {
  const { conveyor, type } = props

  let sections: Array<Partial<IConveyorSection>> = _.sortBy(
    conveyor.conveyorsections,
    (s) => s.sectionnumber
  )
  if (type === 'Infeed') {
    sections = [conveyor.infeed]
  } else if (type === 'Discharge') {
    sections = [conveyor.discharge]
  }

  return (
    <React.Fragment>
      {sections.map((item, index) => {
        const isInfeed = item.type === 'Infeed'
        const isDischarge = item.type === 'Discharge'

        if (isInfeed || isDischarge) {
          return (
            <div
              key={`section-infeed-discharge-tile-${type}-${conveyor.id}`}
              style={getItemStyle()}
            >
              <BuilderInfeedDischarge
                key={`conveyor-builder-infeed-discharge-${item.id}`}
                conveyorId={conveyor.id}
                type={item.type.toLowerCase() as 'infeed' | 'discharge'}
              />
            </div>
          )
        }

        if (!item.sectionnumber) return null

        return (
          <Draggable
            key={`section-tile-${item.sectionnumber}`}
            draggableId={item.sectionnumber.toString()}
            index={index}
          >
            {(provided, snapshot) => {
              const onMouseDown = (() => {
                return (event) => {
                  if (event.target.classList.contains('collapsed-main__drag')) {
                    provided.dragHandleProps.onMouseDown(event)
                  }
                }
              })()

              return (
                <Element name={`builder-tile-${item.id}`}>
                  <div
                    role="button"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseDown={onMouseDown}
                    style={getItemStyle({
                      isDragging: snapshot.isDragging,
                      draggableStyle: provided.draggableProps.style,
                    })}
                  >
                    <BuilderTile
                      key={`conveyor-builder-tile-${item.id}`}
                      conveyorId={conveyor.id}
                      itemId={item.id}
                    />
                  </div>
                </Element>
              )
            }}
          </Draggable>
        )
      })}
    </React.Fragment>
  )
}
