import React from 'react'
import { DEFAULT_CONVEYOR_WIDTH, DEFAULT_PART_COLOR, SELECTED_PART_COLOR } from '../../../constants'
import { TransferTypeKey } from '../../../types/types'

interface Props {
  selected: boolean;
  isRHS: boolean;
  anchorAngleDeg: number;
  typeKey: TransferTypeKey;
  width: number;
}

/** border-radius for the svg paths */
const ROUNDING = 1
export const SF_PER_CHAIN_WIDTH = 2.3256
/** y dimension for the svg. This value came from the file given by the designer */
const SVG_HEIGHT = 614
/** x dimension for the svg. This value came from the file given by the designer */
const SVG_WIDTH = 1043
const SECTION_OFFSET = 187

function DriveSVG({ selected, isRHS, anchorAngleDeg, typeKey, width }: Props) {
  const strokeWidth = 4 * SF_PER_CHAIN_WIDTH
  const rotation = 90 - anchorAngleDeg
  const widthChange = (width - DEFAULT_CONVEYOR_WIDTH) * SF_PER_CHAIN_WIDTH

  return (
    <g
      width={SVG_WIDTH}
      height={SVG_HEIGHT}
      viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
      preserveAspectRatio="none"
    >
      <title>drive-standard</title>
      <g
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={`rotate(${rotation}), scale(${1 / SF_PER_CHAIN_WIDTH})`}
      >
        <g stroke={selected ? SELECTED_PART_COLOR : DEFAULT_PART_COLOR}>
          <g
            transform={`translate(${typeKey === 'infeed' ? SECTION_OFFSET : 0}, -233)`}
            strokeWidth={strokeWidth}
          >
            <rect
              x={typeKey === 'infeed' ? -4 : 495.32}
              y={isRHS ? 10.85 - widthChange : -375.85 - widthChange}
              width="77.5"
              height={599 + widthChange}
              rx={ROUNDING}
            ></rect>
            <line x1={0} y1={233} x2={325 * SF_PER_CHAIN_WIDTH - SECTION_OFFSET} y2={233}></line>
            <line x1={276} y1={233} x2={276} y2={0.5 - widthChange}></line>
            <line
              x1={0}
              y1={0.5 - widthChange}
              x2={325 * SF_PER_CHAIN_WIDTH - SECTION_OFFSET}
              y2={0.5 - widthChange}
            ></line>
          </g>
        </g>
      </g>
    </g>
  )
}

export default DriveSVG
