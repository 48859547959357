import React, { Component } from 'react'
import TWEEN from '@tweenjs/tween.js'
import { connect } from 'react-redux'
import { captureSentryError } from 'utils/helpers'

/**
 * @typedef {import('../redux/ProductReducer').Product} Product
 */

/**
 * @typedef {object} Props
 * @prop {boolean} boxNeedsUpdating
 * @prop {boolean} autorotate
 * @prop {Function} updateBoxFxn
 * @prop {('mm'|'in')} unit
 * @prop {string} boxHeight
 *
 * @extends {Component<Product & Props>}
 */

class Box extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { THREE } = window

    const width = this.canvas.clientWidth
    const height = this.canvas.clientHeight
    this.clock = new THREE.Clock()

    //ADD SCENE for CUBE
    this.scene = new THREE.Scene()

    // ADD SCENE for CSS3D Object
    this.scene2 = new THREE.Scene()

    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 10000)
    this.camera.position.x = 200
    this.camera.position.y = 100
    this.camera.position.z = 200

    //ADD WEBGL RENDERER
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      logarithmicDepthBuffer: true,
      alpha: true,
    })
    this.renderer.setClearColor(0xffffff, 0)
    this.renderer.setSize(width, height)
    this.canvas.appendChild(this.renderer.domElement)

    //CREATE HTML TAGS
    const widthLabel1 = document.createElement('div')
    widthLabel1.innerHTML = '┝──'
    widthLabel1.className = 'width width-label1'
    const widthLabel2 = document.createElement('div')
    widthLabel2.innerHTML = 'Width'
    widthLabel2.className = 'width width-label2'
    const widthLabel3 = document.createElement('div')
    widthLabel3.innerHTML = '──┥'
    widthLabel3.className = 'width width-label3'

    const heightLabel1 = document.createElement('div')
    heightLabel1.innerHTML = '┝──'
    heightLabel1.className = 'height height-label1'
    const heightLabel2 = document.createElement('div')
    heightLabel2.innerHTML = 'Height'
    heightLabel2.className = 'height height-label2'
    const heightLabel3 = document.createElement('div')
    heightLabel3.innerHTML = '──┥'
    heightLabel3.className = 'height height-label3'

    const lengthLabel1 = document.createElement('div')
    lengthLabel1.innerHTML = '┝──'
    lengthLabel1.className = 'length length-label1'
    const lengthLabel2 = document.createElement('div')
    lengthLabel2.innerHTML = 'Length'
    lengthLabel2.className = 'length length-label2'
    const lengthLabel3 = document.createElement('div')
    lengthLabel3.innerHTML = '──┥'
    lengthLabel3.className = 'length length-label3'

    //ADD CSS OBJECT
    const widthDiv1 = new THREE.CSS3DObject(widthLabel1)
    const widthDiv3 = new THREE.CSS3DObject(widthLabel3)
    const heightDiv1 = new THREE.CSS3DObject(heightLabel1)
    const heightDiv3 = new THREE.CSS3DObject(heightLabel3)
    const lengthDiv1 = new THREE.CSS3DObject(lengthLabel1)
    const lengthDiv3 = new THREE.CSS3DObject(lengthLabel3)
    this.widthDiv2 = new THREE.CSS3DObject(widthLabel2)
    this.heightDiv2 = new THREE.CSS3DObject(heightLabel2)
    this.lengthDiv2 = new THREE.CSS3DObject(lengthLabel2)

    widthDiv1.position.x = -40
    widthDiv1.position.y = -58
    widthDiv1.position.z = 50
    this.widthDiv2.position.x = 0
    this.widthDiv2.position.y = -58
    this.widthDiv2.position.z = 50
    widthDiv3.position.x = 40
    widthDiv3.position.y = -58
    widthDiv3.position.z = 50

    heightDiv1.position.x = 58
    heightDiv1.position.y = -40
    heightDiv1.position.z = 50
    heightDiv1.rotateZ(Math.PI / 2)
    this.heightDiv2.position.x = 58
    this.heightDiv2.position.y = 0
    this.heightDiv2.position.z = 50
    this.heightDiv2.rotateZ(Math.PI / 2)
    heightDiv3.position.x = 58
    heightDiv3.position.y = 40
    heightDiv3.position.z = 50
    heightDiv3.rotateZ(Math.PI / 2)

    lengthDiv1.position.x = 50
    lengthDiv1.position.y = -58
    lengthDiv1.position.z = 40
    lengthDiv1.rotateY(Math.PI / 2)
    this.lengthDiv2.position.x = 50
    this.lengthDiv2.position.y = -58
    this.lengthDiv2.position.z = 0
    this.lengthDiv2.rotateY(Math.PI / 2)
    lengthDiv3.position.x = 50
    lengthDiv3.position.y = -58
    lengthDiv3.position.z = -40
    lengthDiv3.rotateY(Math.PI / 2)

    this.scene2.add(widthDiv1)
    this.scene2.add(this.widthDiv2)
    this.scene2.add(widthDiv3)

    this.scene2.add(heightDiv1)
    this.scene2.add(this.heightDiv2)
    this.scene2.add(heightDiv3)

    this.scene2.add(lengthDiv1)
    this.scene2.add(this.lengthDiv2)
    this.scene2.add(lengthDiv3)

    //ADD ARROW
    const directionText = document.createElement('div')
    directionText.innerHTML = '⬅︎ Direction of Product Flow'
    directionText.className = 'threejs-direction-text'
    this.directionTextDiv = new THREE.CSS3DObject(directionText)
    this.directionTextDiv.position.x = 0
    this.directionTextDiv.position.y = 68
    this.directionTextDiv.position.z = 10
    this.directionTextDiv.rotateY(Math.PI / 2)
    this.scene2.add(this.directionTextDiv)

    //CSS3D Renderer
    this.renderer2 = new THREE.CSS3DRenderer()
    this.renderer2.setSize(width, height)
    this.renderer2.domElement.className = 'CSS3DRenderer'
    this.renderer2.domElement.style.position = 'absolute'
    this.renderer2.domElement.style.top = 0
    this.canvas.appendChild(this.renderer2.domElement)

    //ADD CAMERA CONTROLS
    this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement)
    this.controls2 = new THREE.OrbitControls(this.camera, this.renderer2.domElement)

    //ADD CUBE
    const geometry = new THREE.EdgesGeometry(new THREE.BoxGeometry(100, 100, 100))
    const material = new THREE.LineBasicMaterial({ color: '#61678e', linewidth: 3 })
    this.cube = new THREE.LineSegments(geometry, material)

    const whiteGeo = new THREE.BoxGeometry(100, 100, 100)
    const whiteMesh = new THREE.MeshBasicMaterial({ color: 'white' })
    this.whiteBox = new THREE.Mesh(whiteGeo, whiteMesh)
    this.scene.add(this.whiteBox)

    //ADD GROUP
    this.group = new THREE.Group()
    this.group.add(widthDiv1)
    this.group.add(widthDiv3)
    this.group.add(heightDiv1)
    this.group.add(heightDiv3)
    this.group.add(lengthDiv1)
    this.group.add(lengthDiv3)
    // this.group.add(this.cube)
    this.group2 = new THREE.Group()
    this.group2.add(this.widthDiv2)
    this.group2.add(this.heightDiv2)
    this.group2.add(this.lengthDiv2)
    this.group2.add(this.directionTextDiv)

    this.scene2.add(this.group)
    this.scene2.add(this.group2)
    this.scene.add(this.cube)

    this.start()
    this.props.updateBoxFxn(true)
  }

  componentDidUpdate(prevProps, prevState) {
    const widthUpdated = prevProps.width !== this.props.width
    const lengthUpdated = prevProps.length !== this.props.length
    const heightUpdated = prevProps.height !== this.props.height

    const dimensionUpdated = widthUpdated || lengthUpdated || heightUpdated
    const autorotateUpdated = prevProps.autorotate !== this.props.autorotate

    if (dimensionUpdated) {
      this.changeBox()
    } else if (this.props.boxNeedsUpdating) {
      this.timeout = setTimeout(() => {
        this.changeBox()
        this.props.updateBoxFxn(false)
      }, 500)
    } else if (autorotateUpdated) {
      this.rotate(this.props.autorotate)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    this.stop()
    this.canvas.removeChild(this.renderer.domElement)
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
      this.resize()
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = () => {
    var delta = this.clock.getDelta()
    requestAnimationFrame(this.animate)
    this.rotate(this.props.autorotate)
    this.controls.update(delta)
    this.controls2.update(delta)
    this.renderer2.render(this.scene2, this.camera)
    this.renderer.render(this.scene, this.camera)
    //UPDATE TWEEN
    TWEEN.update()
  }

  rotate = (rotating) => {
    if (rotating) {
      this.cube.rotation.y += (-0.1 * Math.PI) / 180
      this.whiteBox.rotation.y += (-0.1 * Math.PI) / 180
      this.group.rotation.y += (-0.1 * Math.PI) / 180
      this.group2.rotation.y += (-0.1 * Math.PI) / 180
    } else {
      this.cube.rotation.y = 0
      this.whiteBox.rotation.y = 0
      this.group.rotation.y = 0
      this.group2.rotation.y = 0
    }
  }

  resize = () => {
    const width = this.canvas.clientWidth
    const height = this.canvas.clientHeight

    this.renderer.setSize(width, height)
    this.camera.aspect = width / height
    // this.controls.handleResize();
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  changeBox = () => {
    const { THREE } = window
    const { height = 1, length = 1, width = 1, unit } = this.props

    let _height = height
    let _length = length
    let _width = width

    const divisor = Math.min(width, height, length)

    if (document.getElementsByClassName('width-label2')[0]) {
      document.getElementsByClassName('width-label2')[0].innerHTML = `width ${_width}${unit}`
      document.getElementsByClassName('height-label2')[0].innerHTML = `height ${_height}${unit}`
      document.getElementsByClassName('length-label2')[0].innerHTML = `length ${_length}${unit}`

      _width = _width / divisor
      _height = _height / divisor
      _length = _length / divisor

      this.animateVector3(this.cube.scale, new THREE.Vector3(_width, _height, _length))
      this.animateVector3(this.whiteBox.scale, new THREE.Vector3(_width, _height, _length))
      this.animateVector3(this.group.scale, new THREE.Vector3(_width, _height, _length))
      this.animateVector3(
        this.widthDiv2.position,
        new THREE.Vector3(0, -58 * _height, 50 * _length)
      )
      this.animateVector3(this.heightDiv2.position, new THREE.Vector3(58 * _width, 0, 50 * _length))
      this.animateVector3(
        this.lengthDiv2.position,
        new THREE.Vector3(50 * _width, -58 * _height, 0)
      )
      this.animateVector3(
        this.camera.position,
        new THREE.Vector3(this.camera.position.x, this.camera.position.y, _length * 200)
      )
    }
  }

  getInputElementValue = (type) => {
    const { id } = this.props
    const input = /** @type {HTMLInputElement} */ (document.getElementById(`product${type}${id}`))
    return Number(input.value) || 1
  }

  resetBox = () => {
    this.controls.reset()
    this.controls2.reset()
    let width = this.getInputElementValue('width')
    let height = this.getInputElementValue('height')
    let length = this.getInputElementValue('length')
    const divisor = Math.min(width, height, length)

    width = width / divisor
    height = height / divisor
    length = length / divisor

    this.camera.position.x = width * 200
    this.camera.position.y = height * 100
    this.camera.position.z = length * 200
  }

  animateVector3 = (vectorToAnimate, target, optionss) => {
    const options = optionss || {}
    // get targets from options or set to defaults
    const to = target || window.THREE.Vector3()
    // @ts-ignore
    const easing = options.easing || TWEEN.Easing.Quadratic.In
    const duration = options.duration || 1000
    // create the tween
    // @ts-ignore
    const tweenVector3 = new TWEEN.Tween(vectorToAnimate)
      .to({ x: to.x, y: to.y, z: to.z }, duration)
      .easing(easing)
      .onUpdate(function(d) {
        if (options.update) {
          options.update(d)
        }
      })
      .onComplete(function() {
        if (options.callback) options.callback()
      })
    // start the tween
    tweenVector3.start()
    // return the tween in case we want to manipulate it later on
    return tweenVector3
  }

  render() {
    const { boxHeight } = this.props

    return (
      <div className="box-wrapper" style={{ height: boxHeight }}>
        <div
          ref={(canvas) => {
            this.canvas = canvas
          }}
          className="box-container"
        ></div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { conveyorId } = props
    const conveyor = state.ConveyorReducer.conveyors[conveyorId]

    return {
      unit: conveyor.unit === 'Metric' ? 'mm' : 'in',
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Box)
