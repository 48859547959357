import { VERTICAL_CURVE_ANCHOR as anchor } from '../../../constants'
import getVerticalCurveDiagonalLinePoints from './getVerticalCurveDiagonalLinePoints'
import getVerticalCurveSectionLength from './getVerticalCurveSectionLength'

export default function getVerticalCurveSVGPath(incline: number, width: number) {
  const isIncline = incline >= 0
  const length = getVerticalCurveSectionLength(incline)

  const path = [
    /* Start Box */
    [anchor.x, anchor.y],
    [anchor.x + width, anchor.y],
    [anchor.x + width, anchor.y + length],
    [anchor.x, anchor.y + length],
    [anchor.x, anchor.y],
    /* End Box */
    ...getVerticalCurveDiagonalLinePoints(width, length, isIncline),
  ]
  const commands = path.map((p, i) => `${i === 0 ? 'M' : 'L'}${p.join()}`)

  return commands.join(' ')
}
