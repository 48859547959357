import React, { memo } from 'react'
import { Icon } from 'shared/components'

/**
 * @typedef {Object} Props
 * @prop {boolean} collapsed
 */

/** @param {React.ComponentProps<'button'> & Props} props */
function CollapseButton(props) {
  const { collapsed, ...restProps } = props

  /** @type {{icon: 'plus'|'minus', size: number, colorKey: 'ash'|'novaBlue'}} */
  const iconProps = {
    icon: collapsed ? 'plus' : 'minus',
    size: 16,
    colorKey: collapsed ? 'ash' : 'novaBlue',
  }

  return (
    <button
      className="project-summary__firstrow__plus-minus"
      title="Expand Project Details"
      tabIndex={0}
      {...restProps}
    >
      <Icon {...iconProps} />
    </button>
  )
}

export default memo(CollapseButton)
