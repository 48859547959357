import React, { PureComponent } from 'react'
import { Icon } from 'shared/components'
import cn from 'classnames'

const DTFOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
}
const DTF = Intl.DateTimeFormat('en-US', DTFOptions)

/**
 * @param {Date} date
 * @returns {string}
 */
function formatDateTime(date) {
  const dt = DTF.format(date)
  const splitIndex = dt.lastIndexOf(',')
  return `${dt.slice(0, splitIndex)} ${dt.slice(splitIndex + 1)}`
}

/**
 * @typedef {import('utils/api/version/VersionService').ProjectNote} Note
 *
 * @callback handleNote
 * @param {number} noteId
 * @returns {void}
 *
 * @typedef {Object} Props
 * @prop {Note} note
 * @prop {handleNote} handleDelete
 * @prop {handleNote} handleEdit
 * @prop {boolean} editing
 * @prop {boolean} canModify
 */

/** @extends PureComponent<Props> */
class ProjectNote extends PureComponent {
  renderButtons = () => {
    const { canModify, handleDelete, handleEdit, editing, note } = this.props
    if (!canModify) return null
    return (
      <div className="project-note__meta-actions">
        <button
          disabled={editing}
          onClick={() => handleEdit(note.id)}
          className="project-note__meta-action project-note__meta-action--blue"
        >
          <Icon icon="editLine" size={14} colorKey={editing ? 'ash' : 'novaBlue'} /> Edit
        </button>
        <button
          disabled={editing}
          onClick={() => handleDelete(note.id)}
          className="project-note__meta-action project-note__meta-action--red"
        >
          <Icon icon="delete" size={14} colorKey={editing ? 'ash' : 'negative'} /> Delete
        </button>
      </div>
    )
  }

  render() {
    const { editing, note } = this.props
    const updateDatetime = new Date(note.updateddatetime)
    const formattedUpdateDatetime = formatDateTime(updateDatetime)
    const createdDatetime = new Date(note.createddatetime)
    const formattedCreatedDatetime = formatDateTime(createdDatetime)
    const edited = formattedUpdateDatetime !== formattedCreatedDatetime

    return (
      <div className="project-note">
        <p
          className={cn('project-note__note', { 'project-note--editing': editing })}
          dangerouslySetInnerHTML={{ __html: note.note.replace(/\n/g, '<br>') }}
        />
        <div className="project-note__meta">
          <span className={cn('project-note__meta-username', { 'project-note--editing': editing })}>
            <b>{note.userfullname}</b>
          </span>
          <time
            className={cn('project-note__meta-date', { 'project-note--editing': editing })}
            dateTime={edited ? updateDatetime.toISOString() : createdDatetime.toISOString()}
          >
            {edited ? formattedUpdateDatetime : formattedCreatedDatetime}
          </time>
          <span className={cn({ 'project-note--editing': editing })}>{edited ? 'Edited' : ''}</span>
          {this.renderButtons()}
        </div>
      </div>
    )
  }
}

export default ProjectNote
