import * as React from 'react'
import { Form, Input } from 'antd'
import { FormConfigItem } from 'shared/types/formConfig'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { IConveyorSection } from 'shared/types/swagger'

type LengthPart = 'lengthinputfeetpart' | 'lengthinputinchespart' | 'lengthinput116inchespart'
function getInputPartValue(partName: LengthPart, id: number): number {
  const inputNode = document.querySelector(
    `#lengthinputid-${id} [name=${partName}]`
  ) as HTMLInputElement
  return parseFloat(inputNode.value) || 0
}

function getInputPartValues(id) {
  return {
    feet: getInputPartValue('lengthinputfeetpart', id),
    inches: getInputPartValue('lengthinputinchespart', id),
    inches116: getInputPartValue('lengthinput116inchespart', id),
  }
}

interface Props {
  isactive: boolean
  islocked: boolean
  field: FormConfigItem
  form: WrappedFormUtils
  item: IConveyorSection
  customValidateFields: () => unknown
  updateSection: (payload: { [key: string]: unknown }) => void
}

export default function EnglishLengthInputs(props: Props) {
  const { form, field, item, isactive, islocked, customValidateFields, updateSection } = props
  const disabled = !isactive || islocked

  /**
   * The three english length input fields are not a part of the form and cannot be validated.
   * To work around this, update the hidden 'length' field and validate that field.
   */
  function handlePartChange() {
    const inputVals = getInputPartValues(item.id)

    // Calculate the total value (conveyor length in mm) to be validated via AntD form props
    // prettier-ignore
    const total = (inputVals.feet * 304.8) + (inputVals.inches * 25.4) + (inputVals.inches116 * 1.5875)

    // Set the hidden length field value
    form.setFieldsValue({ length: total })
    customValidateFields()
    updateSection({
      lengthinputfeetpart: inputVals.feet.toString(),
      lengthinputinchespart: inputVals.inches.toString(),
      lengthinput116inchespart: inputVals.inches116.toString(),
    })
  }

  return (
    <div
      className="builder-tile__expanded__content__wrapper"
      key={`form-item-${field.key}-${item.id}`}
      style={{ position: 'relative' }}
    >
      <Form.Item label={field.prettyName} className={`option-label option-label__${field.key}`}>
        {form.getFieldDecorator(field.key, {
          initialValue: field.value ? field.value : null,
          rules: field.rules,
        })(
          <div style={{ display: 'none' }}>
            <Input className="option-input" />
          </div>
        )}
        <div className="straight-three-inputs" id={`lengthinputid-${item.id}`}>
          <Input
            disabled={disabled}
            defaultValue={
              item.lengthinputfeetpart !== 0 ? item?.lengthinputfeetpart?.toString?.() : undefined
            }
            name="lengthinputfeetpart"
            addonAfter="Ft"
            onChange={handlePartChange}
          />
          <Input
            disabled={disabled}
            defaultValue={item?.lengthinputinchespart?.toString?.()}
            name="lengthinputinchespart"
            addonAfter="in"
            onChange={handlePartChange}
          />
          <Input
            disabled={disabled}
            defaultValue={item?.lengthinput116inchespart?.toString?.()}
            name="lengthinput116inchespart"
            addonAfter="1/16 in"
            onChange={handlePartChange}
          />
        </div>
      </Form.Item>
    </div>
  )
}
