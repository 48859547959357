import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _, { debounce } from 'lodash'
import { Table, Dropdown, Menu } from 'antd'
import { deleteUser, getUsersList } from 'shared/redux/ListActions'
import UserDetail from '../UserDetail'
import usersTableConfig from './usersTableConfig'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import './UsersTable.scss'
import { ExpandIcon, Input, Icon } from 'shared/components'

/**
 * @typedef {import('shared/types/user').UserResponseItem} User
 *
 * @typedef {Object} UserData
 * @property {User['id']} id
 * @property {User['id']} key
 * @property {User['firstname']} firstname
 * @property {User['lastname']} lastname
 * @property {User['email']} email
 * @property {User['username']} username
 * @property {User['roles']} roles
 * @property {User['issparepartuser']} issparepartuser
 */

function UsersTable() {
  const dispatch = useDispatch()
  const users = useSelector(
    (/** @type {import('srcReducer').Store} */ state) => state.ListReducer.users
  )
  const searchTerm = useSelector(
    (/** @type {import('srcReducer').Store} */ state) => state.ListReducer.searchTermInternalUsers
  )

  useEffect(() => {
    searchInternalUsers(searchTerm)
  }, [dispatch])

  const searchInternalUsers = debounce((...args) => { dispatch(getUsersList(...args)) }, 1000)

  /** @param {UserData} record */
  function renderExpandedRow(record) {
    return <UserDetail user={record} />
  }

  function renderDelete(user) {
    const onClick = () => {
      dispatch(
        openConfirmModal({
          bodyText: `You are about to remove user ${user.firstname} ${user.lastname}. This action is irreversible. Are you sure you wish to proceed?`,
          onConfirm: () => {
            dispatch(deleteUser(user.key))
            searchInternalUsers(searchTerm)
          },
        })
      )
    }
    const menu = (
      <Menu onClick={onClick}>
        <Menu.Item className="delete-user">Delete User</Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} placement="bottomRight">
        <div data-testid={`userrowactionsmenu${user.username}`}>
          <Icon icon="settings" size={20} />
        </div>
      </Dropdown>
    )
  }

  /** @returns {Array<UserData>} */
  function getUsersData() {
    const sortedUsers = _.sortBy(users, ['lastname', 'firstname'])
    return sortedUsers.map(
      ({ id, firstname, lastname, email, username, roles, issparepartuser }) => ({
        id,
        key: id,
        firstname,
        lastname,
        email,
        username,
        roles,
        issparepartuser,
      })
    )
  }

  function handleSearchInput(e) {
    searchInternalUsers(e.trim())
  }

  return (
    <div>
      <Input
        icon="search"
        placeholder="Search by Name"
        small
        onChange={handleSearchInput}
        allowClear
        width={600}
        defaultValue={searchTerm}
        data-testid="searchforinternalusers"
      />
      <br/>
      <Table
        className="users-table"
        columns={usersTableConfig(renderDelete)}
        expandIcon={ExpandIcon}
        expandedRowRender={(record) => renderExpandedRow(record)}
        dataSource={getUsersData()}
        data-testid="userstable"
        {...(process.env.NODE_ENV === 'test' && { pagination: false })}
      />
    </div>
  )
}

export default UsersTable
