import React, { Component } from 'react'
export default class CautionIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <g transform="translate(0.000000, 2.000000)">
          <g>
            <path
              fill={color}
              d="M16,2.5c-0.7,0-1.3,0.4-1.7,1L2.8,23.5c-0.3,0.6-0.3,1.3,0,1.9c0.3,0.6,1,1,1.7,1h23.2c0.7,0,1.3-0.4,1.7-1
              c0.3-0.6,0.3-1.3,0-1.9L17.7,3.4C17.3,2.8,16.7,2.5,16,2.5 M27.6,28.9H4.4c-1.6,0-3-0.8-3.8-2.2c-0.8-1.4-0.8-3,0-4.4L12.2,2.2
              C13,0.8,14.4,0,16,0s3,0.8,3.8,2.2l11.6,20.1c0.8,1.4,0.8,3,0,4.4C30.6,28.1,29.2,28.9,27.6,28.9z M16,18.5c0.7,0,1.2-0.6,1.2-1.2
              V9c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2v8.3C14.8,17.9,15.3,18.5,16,18.5z M16,23.3c0.7,0,1.2-0.6,1.2-1.2v-0.9
              c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2v0.9C14.8,22.8,15.3,23.3,16,23.3z"
            />
          </g>
        </g>
      </svg>
    )
  }
}
