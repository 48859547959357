import { useDispatch } from 'react-redux'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { selectProjectFromQueueOrSearch } from 'features/Project/redux/ProjectActions'
import { cloneProject, deleteProject } from 'features/Project/redux/ProjectOperations'
import { checkProjectLock } from 'features/User/redux/UserActions'
import { downloadVersionFile } from 'features/Version/redux/VersionActions'
import { ActionMenu, Icon } from 'shared/components'
import { projectDetailsActionMenu } from 'shared/constants/actionMenuOptions'
import Colors from 'shared/constants/colors'
import { setModalContent } from 'shared/redux/ScreenActions'
import { formatCurrency, showDateTimeEST } from 'utils/helpers'
import './ProjectCard.scss'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 *
 * @typedef {Object} Props
 * @prop {import('shared/types/Project').Project} project
 * @prop {string[]} roles
 * @prop {string} tab
 */

/** @param {Props & RouteComponentProps} props */
function ProjectCard(props) {
  const { project, history, roles, tab } = props

  const dispatch = useDispatch()

  function handleActionMenuSelect(type) {
    dispatch(selectProjectFromQueueOrSearch(project.id))
    switch (type) {
      case 'edit':
        dispatch(setModalContent('projectDetails'))
        return
      case 'select':
        dispatch(setModalContent('versionSelect'))
        return
      case 'clone':
        dispatch(cloneProject({ projectId:project.id,sourcetab:tab, cloneWithoutCustomer:false }))
        return
      case 'cloneWithoutCustomer':
        dispatch(cloneProject({ projectId:project.id,sourcetab:tab, cloneWithoutCustomer:true }))
        return
      case 'download':
        dispatch(downloadVersionFile(project.masterversion.id, 'Quote'))
        return
      case 'delete':
        dispatch(deleteProject(project.id))
        return
      default:
        return
    }
  }

  async function projectClick(e) {
    const eventNotFromActionMenu = !(e?.target?.className || '').includes('action-menu')
    if (eventNotFromActionMenu) {
      const isLocked = await dispatch(checkProjectLock(project.id))
      if (!isLocked) {
        history.push(`/project/${project.id}/${project.masterversion.id}`)
      }
    }
  }

  if (!project.masterversion) return null

  const {
    createdbyname,
    customer,
    customernumber,
    id,
    masterversion: { currentstatusname, index, netprice, validated },
    name,
    number,
    modifieddatetime,
    externalprojecttype,
    isexternalproject
  } = project
  const isLaProject = isexternalproject && externalprojecttype == 'la'

  return (
    <div
      id={`project-row-${id}`}
      className="projects-rows-container"
      onClick={projectClick}
      role="listitem"
      tabIndex={0}
    >
      <div className="first-row">
        <h2 className="project-name">{name}</h2>
        <h4 className="project-number">{number}</h4>
        <div className="project-version">
          <h3 className="version-index">v{index}</h3>
        </div>
        <h4 className="last-updated">
          Last Modified: {showDateTimeEST(modifieddatetime || new Date().toISOString())}
        </h4>
        <h4 className="cost-estimation">{isLaProject ? 'LA Project' : (validated ? formatCurrency(netprice) : '$ - - -')}</h4>
        <div className="action-menu">
          <ActionMenu
            noTitle
            options={projectDetailsActionMenu(project, roles, handleActionMenuSelect,isexternalproject,isLaProject)}
          />
        </div>
      </div>

      <div className="second-row">
        {isexternalproject ? null : (
          <h4 className="project-status">
            <Icon
              icon="circle"
              size={10}
              svgStyle={{ stroke: Colors.novaBlue, strokeWidth: 2, verticalAlign: 'inherit' }}
            />
            <span>{currentstatusname}</span>
          </h4>)}
        <h4 className="project-info createdby">Creator: {createdbyname}</h4>
        <h4 className="project-info customername">Customer: {customer ? customer.name : 'N/A'}</h4>
        <h4 className="project-info customerid"> Customer #: {customernumber ? customernumber : 'N/A'}</h4>
      </div>
    </div>
  )
}

export default withRouter(ProjectCard)
