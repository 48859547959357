import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import './SignatureInfo.scss'

const SignatureInfo = (props) => {
  const { filename } = props
  return (
    <div className="sig-info">
      <Icon type="paper-clip" className="sig-info__icon" />
      <span className="sig-info__filename" data-testid="sigfilename">
        {filename}
      </span>
      {/* add close if needed className="sig-info__close" */}
    </div>
  )
}

SignatureInfo.propTypes = {
  filename: PropTypes.string,
}

SignatureInfo.defaultProps = {
  filename: '',
}

export default SignatureInfo
