/**
 * @typedef {'left'|'right'|'center'} ALIGNMENTS
 *
 * @typedef {Object} ALIGN
 * @property {"left"} left
 * @property {"right"} right
 * @property {"center"} center
 */

/** @type {ALIGN} */
const ALIGN = {
  left: 'left',
  right: 'right',
  center: 'center',
}

export default ALIGN
