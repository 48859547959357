import React, { Component } from 'react'
export default class CheckIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 20"
      >
        <path
          fill={color}
          d="M9.33784204,19.5674415 C8.90002585,19.5674415 8.46220966,19.4056143 8.1214898,19.0813714 L0.54856437,11.8803543 C-0.157590772,11.2089185 -0.185836977,10.0914279 0.486187333,9.38468434 C1.15938857,8.67794073 2.27570215,8.65145991 2.9818573,9.3223073 L9.33901896,15.3669953 L25.0191939,0.484775697 C25.7271144,-0.18666015 26.8446049,-0.156648556 27.5154523,0.550095048 C28.1862997,1.25742712 28.1568766,2.37432916 27.450133,3.04576501 L10.5530173,19.0825483 C10.2122975,19.4062027 9.77506976,19.5674415 9.33784204,19.5674415"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(2.000000, 6.000000)">
            <g mask="url(#mask-2)" fill="none">
              <g transform="translate(-2.000000, -6.000000)">
                <rect x="0" y="0" width="32" height="32"></rect>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
