import { useState, useEffect } from 'react'
import { IPartsUpdateResponse } from 'shared/types/swagger'

export default function useEditedState(
  data: Record<string, IPartsUpdateResponse>,
  unsavedStaticParts: Record<string, IPartsUpdateResponse>
) {
  const [editedGuids, setEditedGuids] = useState([])

  useEffect(() => {
    if (data && unsavedStaticParts) {
      const unsavedValues: IPartsUpdateResponse[] = Object.values(unsavedStaticParts || {})
      const _editedGuids = []
      for (let i = 0; i < unsavedValues.length; i++) {
        const unsaved = unsavedValues[i]
        const original = data[unsaved.partnumberguid]
        if (!unsaved || !original) return
        if (
          unsaved.partnumber !== original.partnumber ||
          unsaved.description !== original.description ||
          unsaved.unit !== original.unit ||
          unsaved.source !== original.source
        ) {
          _editedGuids.push(unsaved.partnumberguid)
        }
      }
      setEditedGuids(_editedGuids)
    }
  }, [data, unsavedStaticParts])

  return editedGuids
}
