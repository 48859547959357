import React, { Component } from 'react'
import { connect } from 'react-redux'
import './TubeWidth.scss'
import { Button, Icon, Input } from 'shared/components'
import { setModalContent, setModalData } from 'shared/redux/ScreenActions'
import { debounce } from 'lodash'
import { Form } from 'antd'
import { captureSentryError } from 'utils/helpers'

class TubeWidth extends Component {
  state = {
    width: '',
  }

  handleChange = debounce((value) => {
    this.setState({ width: value })
  }, 24)

  handleCloseClick = () => this.props.setModalContent(null)

  handleSubmit = (e, ...args) => {
    e.preventDefault()
    // const { width } = this.state;
    const { modalData, setModalData } = this.props
    const { addLinkFromClick, link, spot, chainPattern } = modalData

    const newLink = {
      ...link,
      manualwidthmm: Number(this.state.width),
    }
    addLinkFromClick(newLink, spot, chainPattern)

    setModalData({})
    this.handleCloseClick()
  }

  render() {
    return (
      <Form className="tube-width" onSubmit={this.handleSubmit}>
        <div className="tube-width__top">
          <div className="tube-width__top-container">
            <h1>Enter Tube Width</h1>
            <Icon icon="x" size={30} onClick={this.handleCloseClick} />
          </div>
        </div>
        <Form.Item className="tube-width__middle-container">
          <Input
            label="Tube Width"
            onChange={this.handleChange}
            small
            pattern="^[\d.]*$"
            placeholder="Enter Tube Width"
            addonAfter="mm"
          />
        </Form.Item>
        <div className="tube-width__bottom-container">
          <Button
            small
            secondary
            text="Cancel"
            className="u-mr-16"
            onClick={this.handleCloseClick}
          />
          <Button small text="Set Tube Width" type="submit" />
        </div>
      </Form>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      modalData: state.ScreenReducer.modalData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  setModalContent,
  setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(TubeWidth)
