import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Pie, PieChart, Tooltip, Legend, Sector } from 'recharts'
import { Button } from 'shared/components'
import './ProjectStatusPieChart.scss'
import config from './projectStatusPieChartConfig'
import { formatCurrency, ifDecimalRoundToNum } from 'utils/helpers'
import { selectDepartment, updateFilter } from '../../redux/AdminStatsActions'
import { captureSentryError } from 'utils/helpers'

class ProjectStatusPieChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: null,
    }
  }

  onPieEnter = (data, index) => this.setState({ activeIndex: index })

  renderActiveShape = (props) => {
    const { history, selectDepartment, updateFilter } = this.props
    const { cx, cy, fill, innerRadius, outerRadius, startAngle, endAngle, department } = props

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius + 8}
          outerRadius={outerRadius + 8}
          startAngle={startAngle + 1}
          endAngle={endAngle - 1}
          fill={fill}
          onClick={async () => {
            await selectDepartment(department)
            await updateFilter({ type: undefined, value: undefined })
            history.push('/admin/active-projects')
          }}
        />
      </g>
    )
  }

  toolTipContent = (content) => {
    const { payload } = content

    return payload
      ? payload.map((point, i) => {
          const { dataKey, payload } = point
          const countpercentage = payload[dataKey]
          const projectCount = payload.count

          return (
            <div key={i} className="project-status-pie-chart__tooltip">
              <div className="project-status-pie-chart__tooltip__header">{payload.name}</div>
              <p className="project-status-pie-chart__tooltip__content">
                {`${projectCount} Project${projectCount > 1 ? 's' : ''} | ${ifDecimalRoundToNum(
                  countpercentage,
                  1
                )}%`}
              </p>
            </div>
          )
        })
      : null
  }

  renderPieChart = () => {
    const { totalamount, totalcount, projectstatusbydepartment } = this.props.projectByStatusData
    return (
      <>
        <div className="project-status-pie-chart__pie__inner">
          <div className="title">Active Projects</div>
          <div className="count">{totalcount}</div>
          <div className="total-cost">{formatCurrency(totalamount)}</div>
        </div>
        <PieChart
          className="project-status-pie-chart__pie"
          width={270}
          height={270}
          onMouseLeave={() => this.setState({ activeIndex: null })}
        >
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={this.renderActiveShape}
            data={config(projectstatusbydepartment)}
            innerRadius="60%"
            outerRadius="100%"
            dataKey="countpercentage"
            onMouseEnter={this.onPieEnter}
          />
          <Legend
            align="left"
            verticalAlign="middle"
            iconType="circle"
            iconSize={8}
            formatter={(v, e) => `${v} | ${ifDecimalRoundToNum(e.payload.countpercentage, 1)}%`}
          />
          <Tooltip content={this.toolTipContent} />
        </PieChart>
      </>
    )
  }

  render() {
    const { filter, history, selectDepartment, updateFilter } = this.props
    return (
      <div id="project-status-pie-chart">
        <div className="projects-by-status">
          Projects by Status
          <Button
            small
            text="View Details"
            width={130}
            onClick={async () => {
              await selectDepartment(undefined)
              await updateFilter({
                type: filter.type === 'Customer' ? filter.type : undefined,
                value: filter.type === 'Customer' ? filter.value : undefined,
                displayValue: filter.type === 'Customer' ? filter.displayValue : undefined,
              })
              history.push('/admin/active-projects')
            }}
          />
        </div>
        {this.renderPieChart()}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const { filter, projectByStatusData } = state.AdminStatsReducer
    return {
      filter: filter || {},
      projectByStatusData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { selectDepartment, updateFilter }

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatusPieChart)
