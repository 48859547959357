import { IListElement } from 'shared/types/swagger'
import { ListItem } from '../types'

export default function sortListItems(values: Array<ListItem>, by: keyof IListElement) {
  return by
    ? values.sort((a, b) => {
        if (typeof a[by] === 'string') return (a[by] as string).localeCompare(b[by] as string)
        if (typeof a[by] === 'number') return (a[by] as number) - (b[by] as number)
        return 0
      })
    : values
}
