import { IConveyorWithModifiedInfeedDischarge } from '../shared/types'

export const getConveyorsOrders = (
  conveyors: Partial<Record<string, IConveyorWithModifiedInfeedDischarge>>
) => {
  return Object.entries(conveyors).map(([conveyorId, { order }]) => {
    return { conveyorid: Number(conveyorId), order }
  })
}

export const getConveyorsOrdersSorted = (
  conveyors: Partial<Record<string, IConveyorWithModifiedInfeedDischarge>>
) => {
  return getConveyorsOrders(conveyors).sort((a, b) => a.order - b.order)
}
