import store from 'reduxStore'
import conveyorSectionTypes from 'shared/constants/conveyorSectionTypes'
import chainFormConfig from 'features/Chain/components/chainConfig'
import * as productFormConfig from 'features/Product/productConfig'
import {
  mapStateToProps as infeedDischargeMSTP,
  TRANSFER_TYPE_MAP,
} from 'features/Estimator/components/ConveyorBuilder/components/BuilderList/components/BuilderInfeedDischarge'
import { mapStateToProps as tileMSTP } from 'features/Estimator/components/ConveyorBuilder/components/BuilderList/components/BuilderTile'
import infeedDischargeConfig from 'features/Estimator/components/ConveyorBuilder/components/BuilderList/components/builderInfeedDischargeConfig'
import builderTileConfig from 'features/Estimator/components/ConveyorBuilder/components/BuilderList/components/builderTileConfig'
import { updateCompletedTabs } from 'features/Conveyor/redux/ConveyorActions'
import _ from 'lodash'
import { batch } from 'react-redux'
import { gearmotorConfig, ConfigProps as GearmotorConfigProps } from 'features/Estimator/components/Gearmotor/gearmotorConfig'
import { IInfeedDischargeConfigData } from 'features/Estimator/components/ConveyorBuilder/components/BuilderList/types'
import { IBuilderTileProps } from 'features/Estimator/components/ConveyorBuilder/components/BuilderList/components/BuilderTile/BuilderTile'
import { ITransfersList } from 'utils/api/list/types/transfers'
import { FormConfigItem } from 'shared/types/formConfig'
import { IConveyor } from 'shared/types/swagger'
import { IConveyorWithModifiedInfeedDischarge } from 'features/Conveyor/shared/types'
import { createSupportConfig } from 'features/Estimator/components/Accessories/util/accessoriesConfig'

export interface ValidationErrorInstance {
  tab: string;
  conveyorId: number;
  conveyorName: string;
  field?: string;
  section?: string;
  errormessage?: string;
  HTMLMessage: string;
}
export type validateTab = (versionId: number, updateTab?: boolean) => ValidationErrorInstance[]
type Conveyor = IConveyor | IConveyorWithModifiedInfeedDischarge

const getHTMLMessageLabel = (text) => `<b>${text}</b>`

export class ValidationError implements ValidationErrorInstance {
  tab: string;
  conveyorId: number;
  conveyorName: string;
  field: string;
  section: string;
  errormessage: string;
  HTMLMessage: string;

  constructor({ tab, conveyor, field, section, errormessage }: { 
    tab: string; 
    conveyor: Conveyor; 
    field: string; 
    section: string;
    errormessage: string;
  }) {
    this.tab = tab
    this.conveyorName = conveyor.name
    this.conveyorId = conveyor.id
    this.field = field
    this.section = section
    this.errormessage = errormessage.includes('<br/>') ? '<br/>'+errormessage : errormessage
    this.HTMLMessage = [
      `${this.tab}`, 
      `${this.section ? `${this.section}` : ''}`,
      `${this.field ? `${this.field}` : ''}`,
      `${this.errormessage ? `${this.errormessage}` : ''}`,
    ]
      .filter(Boolean)
      .join(' / ')
  }
} 

function validateFormItems(
  formItems: Array<FormConfigItem>, 
  tab: string, 
  conveyor: Conveyor,
  section?: string
): ValidationErrorInstance[] {
  const errors = []

  formItems.forEach((item) => {
    const { rules, value, visible, key } = item
    if (!rules || visible === false || !key) return
    const isRequired = rules.some((rule) => rule.required)
    const hasValidator = rules.some((rule) => Boolean(rule.validator))
    const hasTransform = rules.some((rule) => Boolean(rule.transform))

    const reportError = (errormessage) => {
      errors.push(new ValidationError({ tab, conveyor, field: item.prettyName, section, errormessage }))
    }

    const transformedValue = hasTransform
      ? rules.find((rule) => Boolean(rule.transform))?.transform(value)
      : value

    const validator = rules.find((rule) => Boolean(rule.validator))?.validator

    if (hasValidator) {
      const result = validateFormItem(transformedValue, validator)
      if (result !== '') {
        reportError(result)
        return
      }
    }

    if (isRequired && (value === undefined || value === null)) {
      reportError('Required')
    }
  })

  return errors
}

function  validateFormItem(
  value: string|number|boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: (a: any, b: string|number|boolean, c: (...params: any[]) => any) => Error
): string
function validateFormItem(value, validator) {
  let result = ''
  validator(null, value, (error) => {
    if (error instanceof Error) result = error.message
  })

  return result
}

const validateProjectChain: validateTab = function(versionId, updateTab = false) {
  const {
    UserReducer,
    ListReducer,
    ConveyorReducer,
    ChainReducer,
    ConveyorMetaReducer,
    ProjectReducer
  } = store.getState()
  const chainList = ListReducer.chains
  const chainSerieData = ChainReducer[versionId]
  const conveyorsIds = Object.keys(ConveyorMetaReducer?.[versionId] || {})
  const { permissions } = UserReducer
  const conveyors = Object.values(ConveyorReducer.conveyors).filter((conv) =>
    conveyorsIds.includes(conv.id.toString())
  )
  const isLAProject = ProjectReducer.currentexternalprojecttype == 'la'

  const errors = []
  const conveyorsCompletion = []

  conveyors.forEach((conveyor) => {
    const isMultispan = chainList?.[conveyor.chain.chainserieid?.toString?.()]?.name === 'MultiSpan'
    const formItems = chainFormConfig(
      { conveyor, chainList, chainSerieData, permissions },
      isMultispan,
      isLAProject
    )

    const _errors = validateFormItems(formItems, 'Chain', conveyor)
    errors.push(..._errors)

    if (updateTab) {
      conveyorsCompletion.push([conveyor.id, !_errors.length])
    }
  })

  if (updateTab) {
    batch(() => {
      for (let i = 0; i < conveyorsCompletion.length; i++) {
        const [id, isComplete] = conveyorsCompletion[i]
        store.dispatch(
          updateCompletedTabs({
            versionId: versionId,
            conveyorId: id,
            completedTabs: {
              ...ConveyorMetaReducer[versionId][id].completedTabs,
              chain: isComplete,
            },
          })
        )
      }
    })
  }

  return errors
}

const validateProjectProduct: validateTab = function(versionId, updateTab = false) {
  const { ListReducer, ConveyorReducer, ConveyorMetaReducer, ProductReducer, ProjectReducer } = store.getState()
  const conveyorsIds = Object.keys(ConveyorMetaReducer?.[versionId] || {})
  const conveyors = Object.values(ConveyorReducer.conveyors).filter((conv) =>
    conveyorsIds.includes(conv.id.toString())
  )
  const { materialtypes } = ProjectReducer.currentlistsettings.product

  const errors = []
  const conveyorsCompletion = []
  const tabName = 'Product'

  conveyors.forEach((conveyor) => {
    const { meta, ...products } = ProductReducer?.[conveyor.id] || {}

    const contaminantsFormItems = productFormConfig.contaminantsConfig({ conveyor })
    const generalSettingsFormItems = productFormConfig.generalSettingsConfig({ conveyor })
    const conditionsFormItems = productFormConfig.conditionsConfig({ conveyor })

    const _contaminantsErrors = validateFormItems(contaminantsFormItems, tabName, conveyor)
    const _generalSettingsErrors = validateFormItems(generalSettingsFormItems, tabName, conveyor)
    const _conditionsErrors = validateFormItems(conditionsFormItems, tabName, conveyor)

    const _errors = [..._contaminantsErrors, ..._generalSettingsErrors, ..._conditionsErrors]
    errors.push(..._errors)

    let oneProductIsValid = false

    for (const id in products) {
      const product = products[id]

      const productDimensionsFormItems = productFormConfig.productDimensionsConfig(
        { conveyor },
        product
      )
      const productDetailsFormItems = productFormConfig.productDetailsConfig(
        { conveyor, materialtypes },
        product
      )

      const _productDimensionsErrors = validateFormItems(
        productDimensionsFormItems,
        tabName,
        conveyor,
        'Dimensions'
      )
      const _productDetailsErrors = validateFormItems(
        productDetailsFormItems,
        tabName,
        conveyor,
        'Details'
      )

      const _productErrors = [..._productDimensionsErrors, ..._productDetailsErrors]

      if (updateTab) {
        if (!_productErrors.length) {
          oneProductIsValid = true
        }
      }

      errors.push(..._productErrors)
    }

    if (updateTab) {
      conveyorsCompletion.push([conveyor.id, !_errors.length && oneProductIsValid])
    }
  })

  if (updateTab) {
    batch(() => {
      for (let i = 0; i < conveyorsCompletion.length; i++) {
        const [id, isComplete] = conveyorsCompletion[i]
        store.dispatch(
          updateCompletedTabs({
            versionId: versionId,
            conveyorId: id,
            completedTabs: {
              ...ConveyorMetaReducer[versionId][id].completedTabs,
              product: isComplete,
            },
          })
        )
      }
    })
  }

  return errors
}

const validateProjectEstimator: validateTab = function(versionId) {
  const state = store.getState()
  const { ConveyorReducer, ConveyorMetaReducer, ListReducer, EstimatorReducer, EstimatorMetaReducer, UserReducer, ProjectReducer } = state
  const conveyorsIds = Object.keys(ConveyorMetaReducer?.[versionId] || {})
  const conveyors = Object.values(ConveyorReducer.conveyors).filter((conv) =>
    conveyorsIds.includes(conv.id.toString())
  )
  const tabName = 'Estimator'

  const errors = []

  conveyors.forEach((conveyor) => {
    const ends = ['infeed', 'discharge'] as const
    let dischargeErrors = null
    ends.forEach((end) => {
      const props = infeedDischargeMSTP(state, { conveyorId: conveyor.id, type: end })
      const { item, infeedDischargeList } = props
      const { transfer } = item
      const transfersList = infeedDischargeList.transfers
      const transfermaterialid = transfer?.transfermaterialid
      const transfertypeid = transfer?.transfertypeid
      // slugify the titles: e.g. Micro Span -> microspan. See TRANSFER_TYPE_MAP
      const transferType = transfertypeid
        ? transfersList.types[transfertypeid].title.split(' ').join('').toLowerCase()
        : null
      const transferMaterial = TRANSFER_TYPE_MAP[transferType] || {
        key: null,
        label: 'Transfer Material',
      }
      const transferTypeOptions = transfersList[transferType]
      const wireMeshSelected =
        transferType === 'microspan' &&
        transferTypeOptions?.materials?.[transfermaterialid]?.title === 'Wire Mesh'
      const microspan6mmSelected =
        transferType === 'microspan' &&
        transferTypeOptions?.materials?.[transfermaterialid]?.title.startsWith('Microspan 6mm')
      const noneId = _.find(infeedDischargeList?.types, { title: 'None' })?.id
      const noneSelected = item.feedtypeid === noneId

      const formConfigVars: IInfeedDischargeConfigData = {
        ...props,
        transfersList,
        transferType: transferType as keyof ITransfersList, // not sure if this is true, but good enough to satisfy TS, did not change previous code
        wireMeshSelected,
        microspan6mmSelected,
        transferMaterial,
        isDrive: infeedDischargeList?.types?.[item.feedtypeid]?.title === 'Drive',
      }
      const endFormConfig = infeedDischargeConfig(formConfigVars, noneSelected)

      const _errors = validateFormItems(endFormConfig, tabName, conveyor, end.replace(/^./, end[0].toUpperCase()))

      if (end == 'infeed')
      {
        errors.push(..._errors)
      }
      else{
        dischargeErrors = _errors
      }
    })

    Object.values(
      conveyor != null 
        ? _.sortBy(conveyor.conveyorsections,(s) => s.sectionnumber)
        : {})
      .forEach((section) => {
        const tileProps: Partial<IBuilderTileProps> = tileMSTP(state, { conveyorId: conveyor.id, itemId: section.id })
        const tileFormConfig = builderTileConfig(tileProps)
        const _errors = validateFormItems(
          tileFormConfig,
          tabName,
          conveyor,
          section.sectionnumber+1 +'-'+conveyorSectionTypes[section.type]
        )
        errors.push(..._errors)
      })

    if (dischargeErrors != null)
    {
      errors.push(...dischargeErrors)
    }

    const estimator = EstimatorReducer[conveyor.id]
    const supportConfig: FormConfigItem[] = []
    // Accessories validation
    if (estimator.accessories?.ceilingsupports?.parameteractive
      && Array.isArray(estimator.accessories.ceilingsupports.details)
      && estimator.accessories.ceilingsupports.details.length < 1
    ) {
      supportConfig.push(createSupportConfig({
        key: 'ceilingsupports',
        prettyName: 'Ceiling Support',
        value: estimator.accessories?.ceilingsupports?.details?.length,
        message: 'Please add and save a ceilling support item.',
      }))
    }

    if (estimator.accessories?.floorsupports?.parameteractive
      && Array.isArray(estimator.accessories.floorsupports.details)
      && estimator.accessories.floorsupports.details.length < 1
    ) {
      supportConfig.push(createSupportConfig({
        key: 'floorsupports',
        prettyName: 'Floor Support',
        value: estimator.accessories?.floorsupports?.details?.length,
        message: 'Please add and save a floor support item.',
      }))
    }

    const supportErrors = validateFormItems(
        supportConfig,
        tabName,
        conveyor
      )
    errors.push(...supportErrors)

    const hasNewGearmotor = ProjectReducer.versions?.[versionId]?.hasnewgearmotor
    // validate gearMotorConfig
    if (hasNewGearmotor) {
      const { gearmotor } = conveyor
      const gearmotorList = ProjectReducer.currentlistsettings.gearmotor
      const gearmotorTitle = gearmotorList?.['drivetypes']?.[conveyor.gearmotortypeid]?.title
      const displayBrandOnly = gearmotorTitle === 'Other'
      const desiredUnitOptions = _.sortBy(EstimatorMetaReducer?.[conveyor.id]?.gearmotor?.desiredUnitOptions?.[
        conveyor?.gearmotor?.desiredunitid
      ]?.options || [], (o) => o.listorder)
      const isExternal = UserReducer.userType === 'External'
      const VFDOptions = ConveyorReducer.VFDOptions[conveyor.id]
      const gearMotorVars: GearmotorConfigProps = {
        conveyor,
        displayBrandOnly,
        gearmotor,
        gearmotorList,
        desiredUnitOptions,
        isExternal,
        VFDOptions,
      }
      const gearMotorConfig = gearmotorConfig(gearMotorVars)
      const _errors = validateFormItems(
        gearMotorConfig,
        tabName,
        conveyor
      )
      errors.push(..._errors)
    }
  })

  return errors
}

const validateAllTabs: validateTab = function(versionId, updateTab = false) {
  return [
    ...validateProjectChain(versionId, updateTab),
    ...validateProjectProduct(versionId, updateTab),
    ...validateProjectEstimator(versionId),
  ]
}

const validateProject = {
  chains: validateProjectChain,
  products: validateProjectProduct,
  estimators: validateProjectEstimator,
  all: validateAllTabs,
}

export function validateProjectAndUpdateTabs(versionId: number) {
  return validateAllTabs(versionId, true)
}

export default validateProject
