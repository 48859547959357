import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Button, Icon, Input, Select, TMIcon } from './'
import Colors from '../constants/colors'
import './SharedComponents.scss'
import { setLoading } from '../redux/ScreenActions'

const boxStyle = { width: '100px', height: '150px', margin: '0 5px 5px 0' }
const divStyle = { display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '15px' }
const textStyle = { fontFamily: 'Eurostile', fontSize: '18px' }
const titleStyle = {
  fontFamily: 'Eurostile',
  fontSize: '35px',
  fontWeight: 900,
  color: Colors.novaBlue,
  marginTop: '10px',
}
const iconsList = [
  'add',
  'accountManager',
  'cancel',
  'caution',
  'check',
  'chevronDown',
  'chevronLeft',
  'chevronRight',
  'circle',
  'circleDot',
  'clone',
  'customers',
  'dashboard',
  'delete',
  'designer',
  'dotsH',
  'dotsV',
  'dollar',
  'download',
  'drag',
  'editSquare',
  'editLine',
  'envelope',
  'estimator',
  'expand',
  'external',
  'history',
  'home',
  'info',
  'infoOutline',
  'internal',
  'manager',
  'minimize',
  'minus',
  'minusOutline',
  'password',
  'paste',
  'plus',
  'reload',
  'search',
  'user',
  'save',
  'settings',
  'star',
  'starOutline',
  'startEstimate',
  'sync',
  'tag',
  'trendDown',
  'trendUp',
  'x',
  'zoomIn',
]
const options = [
  {
    label: 'Option 1',
    value: 1,
  },
  {
    label: 'Option 2',
    value: 2,
  },
]

class SharedComponents extends Component {
  componentDidMount() {
    this.props.setLoading(false)
  }

  divider = () => (
    <div
      style={{ width: '100%', height: '2px', backgroundColor: Colors.cloud, margin: '30px 0' }}
    />
  )

  renderColors = () => {
    return _.map(Colors, (v, k) => {
      return (
        <div style={{ ...boxStyle, backgroundColor: v, textAlign: 'center' }}>
          <div style={{ textAlign: 'center', backgroundColor: Colors.white, ...textStyle }}>
            {k}
            <p>{v}</p>
          </div>
        </div>
      )
    })
  }

  renderFonts = () => {
    return (
      <div>
        <div className="shared-h1">H1 Text (h1) Eurostile / 45px / 900</div>
        <div className="shared-h2">H2 Text (h2) Eurostile / 28px / 900</div>
        <div className="shared-h3">H3 Text (h3) Eurostile / 35px / 900</div>
        <div className="shared-h4">H4 Text (h4) Museo-Sans / 20px / normal</div>
        <div className="shared-h5">H5 Text (h5) Museo-Sans / 16px / normal</div>
        <div className="shared-h6">H6 Text (h6) Eurostile / 14px / 900</div>
        <div className="shared-b">Body Text (b) Museo-Sans / 18px / normal</div>
        <div className="shared-b-bold">Body Bold Text (b-bold) Museo-Sans / 18px / 900</div>
        <div className="shared-sm">Small Text (sm) Eurostile / 16px / normal</div>
        <div className="shared-sm-med">Small Medium Text (sm-med) Eurostile / 16px / 700</div>
        <div className="shared-sm-bold">Small Bold Text (sm-bold) Eurostile / 16px / 900</div>
      </div>
    )
  }

  renderIcons = () => {
    return iconsList.map((icon, i) => {
      return (
        <div style={{ ...divStyle, position: 'relative' }} key={`${i}-${icon}`}>
          <span style={{ ...textStyle, marginLeft: '10px' }}>{icon}</span>
          <Icon
            key={i}
            icon={icon}
            size={24}
            color={Colors.navy}
            style={{
              position: 'absolute',
              left: '150px',
            }}
          />
        </div>
      )
    })
  }

  renderTMIcons = () => {
    return _.map(TMIcon, (v, k) => {
      return (
        <div style={divStyle}>
          <span style={{ ...textStyle, margin: '0 10px' }}>{k}</span>
          {v}
        </div>
      )
    })
  }

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <div>
          <h1 style={titleStyle}>Colors</h1>
          <div style={divStyle}>{this.renderColors()}</div>
        </div>

        {this.divider()}

        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '300px' }}>
            <h1 style={titleStyle}>Icons</h1>
            {this.renderIcons()}
          </div>

          <div>
            <h1 style={titleStyle}>Fonts</h1>
            {this.renderFonts()}
          </div>
        </div>

        {this.divider()}

        <div>
          <h1 style={titleStyle}>TM Icons</h1>
          {this.renderTMIcons()}
        </div>

        {this.divider()}

        <div>
          <h1 style={titleStyle}>Buttons</h1>
          <div style={divStyle}>
            <Button margin="0 5px 5px 0" text="Primary" primary uppercase />
            <Button margin="0 5px 5px 0" text="Primary Disabled" primary uppercase disabled />
            <Button margin="0 5px 5px 0" text="Primary Danger" primary uppercase danger />
          </div>
          <div style={divStyle}>
            <Button margin="0 5px 5px 0" text="Primary • icon" primary icon="cancel" uppercase />
            <Button
              margin="0 5px 5px 0"
              text="Primary • icon"
              primary
              icon="cancel"
              uppercase
              disabled
            />
            <Button
              margin="0 5px 5px 0"
              text="Primary • icon"
              primary
              icon="cancel"
              uppercase
              danger
            />
          </div>

          <div style={divStyle}>
            <Button margin="0 5px 5px 0" text="Secondary" secondary uppercase />
            <Button margin="0 5px 5px 0" text="Secondary Disabled" secondary uppercase disabled />
            <Button margin="0 5px 5px 0" text="Secondary Danger" secondary uppercase danger />
          </div>
          <div style={divStyle}>
            <Button margin="0 5px 5px 0" text="Small" primary small uppercase />
            <Button margin="0 5px 5px 0" text="Small Disabled" primary small uppercase disabled />
            <Button margin="0 5px 5px 0" text="Small Danger" primary small uppercase danger />
          </div>
          <div style={divStyle}>
            <Button margin="0 5px 5px 0" text="Small Secondary" secondary small uppercase />
            <Button
              margin="0 5px 5px 0"
              text="Small Secondary Disabled"
              secondary
              small
              uppercase
              disabled
            />
            <Button
              margin="0 5px 5px 0"
              text="Small Secondary Danger"
              secondary
              small
              uppercase
              danger
            />
          </div>
          <div style={divStyle}>
            <Button margin="0 5px 5px 0" text="Extra Small Primary" extraSmall />
            <Button margin="0 5px 5px 0" text="Extra Small Secondary" extraSmall secondary />
          </div>
        </div>
        {this.divider()}
        <div>
          <h1 style={titleStyle}>Inputs & Select</h1>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Large Input"
              large
              width={200}
              placeholder="Placeholder Text"
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input"
              large
              width={200}
              value="Entered Text"
              allowClear
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input"
              large
              width={200}
              disabled
              value="Disabled Text"
              allowClear
            />
          </div>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Large Input • icon"
              large
              width={200}
              placeholder="Placeholder Text"
              icon="search"
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input • icon"
              large
              width={200}
              value="Entered Text"
              icon="search"
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input • icon"
              large
              width={200}
              value="Disabled Text"
              icon="search"
              disabled
            />
          </div>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Large Input • addonBefore"
              large
              width={200}
              placeholder="Placeholder Text"
              addonBefore="$"
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input • addonAfter"
              large
              width={200}
              placeholder="Placeholder Text"
              addonAfter="inches"
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input • allowClear"
              large
              width={200}
              value="Entered Text"
              allowClear
            />
            <Input
              margin="0 15px 0 0"
              label="Large Input • allowClear and icon"
              large
              width={200}
              placeholder="Allow Clear"
              allowClear
              icon="search"
            />
          </div>
          <div style={divStyle}>
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Large Select"
              large
              width={200}
              placeholder="Placeholder Text"
            />
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Large Select"
              large
              width={200}
              value="Selected Text"
            />
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Large Select"
              large
              width={200}
              value="Disabled Text"
              disabled
            />
          </div>
          {this.divider()}
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Medium Input"
              width={200}
              placeholder="Placeholder Text"
            />
            <Input margin="0 15px 0 0" label="Medium Input" width={200} value="Entered Text" />
            <Input
              margin="0 15px 0 0"
              label="Medium Input"
              width={200}
              disabled
              value="Disabled Text"
            />
          </div>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Medium Input • icon"
              width={200}
              placeholder="Placeholder Text"
              icon="search"
            />
            <Input
              margin="0 15px 0 0"
              label="Medium Input • icon"
              width={200}
              value="Entered Text"
              icon="search"
            />
            <Input
              margin="0 15px 0 0"
              label="Medium Input • icon"
              width={200}
              value="Disabled Text"
              icon="search"
              disabled
            />
          </div>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Medium Input • addonBefore"
              width={200}
              placeholder="Placeholder Text"
              addonBefore="$"
            />
            <Input
              margin="0 15px 0 0"
              label="Medium Input • addonAfter"
              width={200}
              placeholder="Placeholder Text"
              addonAfter="inches"
            />
            <Input
              margin="0 15px 0 0"
              label="Medium Input • allowClear"
              width={200}
              value="Entered Text"
              allowClear
            />
            <Input
              margin="0 15px 0 0"
              label="Medium Input • allowClear and icon"
              width={200}
              placeholder="Allow Clear"
              allowClear
              icon="search"
            />
          </div>
          <div style={divStyle}>
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Medium Select"
              width={200}
              placeholder="Placeholder Text"
            />
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Medium Select"
              width={200}
              value="Selected Text"
            />
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Medium Select"
              width={200}
              value="Disabled Text"
              disabled
            />
          </div>
          {this.divider()}
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Small Input"
              small
              width={200}
              placeholder="Placeholder Text"
            />
            <Input margin="0 15px 0 0" label="Small Input" small width={200} value="Entered Text" />
            <Input
              margin="0 15px 0 0"
              label="Small Input"
              small
              width={200}
              disabled
              value="Disabled Text"
            />
          </div>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Small Input • icon"
              small
              width={200}
              placeholder="Placeholder Text"
              icon="search"
            />
            <Input
              margin="0 15px 0 0"
              label="Small Input • icon"
              small
              width={200}
              value="Entered Text"
              icon="search"
            />
            <Input
              margin="0 15px 0 0"
              label="Small Input • icon"
              small
              width={200}
              value="Disabled Text"
              icon="search"
              disabled
            />
          </div>
          <div style={divStyle}>
            <Input
              margin="0 15px 0 0"
              label="Small Input • addonBefore"
              small
              width={200}
              placeholder="Placeholder Text"
              addonBefore="$"
            />
            <Input
              margin="0 15px 0 0"
              label="Small Input • addonAfter"
              small
              width={200}
              placeholder="Placeholder Text"
              addonAfter="inches"
            />
            <Input
              margin="0 15px 0 0"
              label="Small Input • allowClear"
              small
              width={200}
              value="Entered Text"
              allowClear
            />
            <Input
              margin="0 15px 0 0"
              label="Small Input • allowClear and icon"
              small
              width={200}
              placeholder="Allow Clear"
              allowClear
              icon="search"
            />
          </div>
          <div style={divStyle}>
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Small Select"
              small
              width={200}
              placeholder="Placeholder Text"
            />
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Small Select"
              small
              width={200}
              value="Selected Text"
            />
            <Select
              margin="0 15px 0 0"
              options={options}
              label="Small Select"
              small
              width={200}
              value="Disabled Text"
              disabled
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = { setLoading }

export default connect(null, mapDispatchToProps)(SharedComponents)
