import { useState, useEffect } from 'react'
import { getPartNumber } from 'features/Admin/components/CostImport/utils'
import { SytelineResponsePlus } from '../types'

export default function useEditedState(sytelineCosts, unsavedCostUpdate) {
  const [editedIds, setEditedIds] = useState([])
  const [editedPartNumbers, setEditedPartNumbers] = useState([])

  useEffect(() => {
    if (sytelineCosts && unsavedCostUpdate) {
      const unsavedValues: SytelineResponsePlus[] = Object.values(unsavedCostUpdate || {})
      const _editedIds = []
      const _editedPartNumbers = []
      for (let i = 0; i < unsavedValues.length; i++) {
        const unsaved = unsavedValues[i]
        const original = sytelineCosts[unsaved.id]
        if (!unsaved || !original) return
        if (
          unsaved.updatedcost !== original.updatedcost ||
          unsaved.dutycost !== original.dutycost ||
          unsaved.freightcost !== original.freightcost ||
          unsaved.totalcost !== original.totalcost
        ) {
          _editedPartNumbers.push(getPartNumber(unsaved))
          _editedIds.push(unsaved.id)
        }
      }
      setEditedIds(_editedIds)
      setEditedPartNumbers(_editedPartNumbers)
    }
  }, [sytelineCosts, unsavedCostUpdate])

  return [editedIds, editedPartNumbers]
}
