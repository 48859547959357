import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import _, { debounce } from 'lodash'
import {
  searchProjectsPurge,
} from 'features/Project/redux/ProjectActions'
import { searchProjects } from 'features/Project/redux/ProjectOperations'
import { getProjects } from 'features/Project/redux/ProjectGetProjectsOperations'
import ProjectList from '../ProjectList'
import { Input, Select } from 'shared/components'
import { Pagination } from 'antd'
import './ProjectTab.scss'
import { typedUseSelector } from 'utils/helpers'
import axios from 'axios'

const defaultPageSize = 30

function ProjectTab(props) {
  const { tab } = props

  const currentFilterStatus = typedUseSelector(state => state.ProjectReducer.currentFilterStatus)

  const statusOptions = typedUseSelector(state => {
    const _statusOptions = state.ListReducer.statuses.map((s) => ({ label: s.name, value: s.name }))
    _statusOptions.unshift({ label: 'All Statuses', value: 'All' })
    return _statusOptions
  })
  const currentPage = tab === 'queue' 
    ? typedUseSelector(state => state.ProjectReducer.currentFilterPage)
    : typedUseSelector(state => state.ProjectReducer.currentSearchPage)
  const searchTerm = tab === 'queue' 
    ? typedUseSelector(state => state.ProjectReducer.filterTerm)
    : typedUseSelector(state => state.ProjectReducer.searchTerm)
  const totalPages = tab === 'queue' 
    ? typedUseSelector(state => state.ProjectReducer.totalFilterPages)
    : typedUseSelector(state => state.ProjectReducer.totalSearchPages)

  let [selectedStatus, setSelectedStatus] = useState('All')

  if (tab === 'queue')
  {
    selectedStatus =  (currentFilterStatus == null || currentFilterStatus == '') ? 'All' : currentFilterStatus
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (tab === 'search' && searchTerm !== '')
    {
       searchProjectsDb(currentPage, searchTerm)
    }
 
    return () => {
      searchQueue.cancel()
      searchProjectsDb.cancel()
    }
  }, [])

  const searchQueue = debounce((...args) => { dispatch(getProjects(...args)) }, 1000)
  const searchProjectsDb = debounce((...args) => { 
    if (args[1] === '') {
      dispatch(searchProjectsPurge())
    }
    else {
      dispatch(searchProjects(...args)) 
    }
  }, 1000)

  const handlePaginationClick = async (pageNumber) => {
    if (tab === 'queue')
    {
      searchQueue({ cancelTokenSource:axios.CancelToken.source(), currentFilterPage:pageNumber, filterTerm:searchTerm,statusFilter:selectedStatus })
    }
    else
    {
      searchProjectsDb(pageNumber, searchTerm)
    }  
  }

  const handleSearchInput = async (e) => {
    const text = e.trim()

    if (tab === 'queue') {
      searchQueue({ cancelTokenSource:axios.CancelToken.source(), currentFilterPage:currentPage, filterTerm:text,statusFilter:selectedStatus })
    } else {
      searchProjectsDb(currentPage, text)
    }
  }

  const handleSelectedStatusChange = async (e) => {
    const text = e.trim()

    if (tab === 'queue') {
      searchQueue({ cancelTokenSource:axios.CancelToken.source(), currentFilterPage:currentPage, filterTerm:searchTerm,statusFilter:text })
    }

    setSelectedStatus(e)
  }

  const renderPagination = () => {
    return (
      <Pagination
        defaultPageSize={defaultPageSize}
        current={currentPage}
        total={totalPages * defaultPageSize}
        onChange={(page) => handlePaginationClick(page)}
      />
    )
  }

  const tabName = tab === 'queue' ? 'Filter' : 'Search'
  return (
    <div className="project-tab-container">
      <div className="input-bar">
        <div className="input-bar__inputs">
          <Input
            icon="search"
            placeholder={`${tabName} by Project Number, Project Name, Creator or Customer Number`}
            small
            defaultValue={searchTerm}
            onChange={handleSearchInput}
            allowClear
            width={600}
            data-testid={`searchforprojects${tab}`}
          />
          <Select
            small
            width={400}
            options={statusOptions}
            onSelect={handleSelectedStatusChange}
            value={selectedStatus}
          />
        </div>
      </div>
      <ProjectList selectedStatus={selectedStatus} />
      {renderPagination()}
    </div>
  )
}

export default ProjectTab
