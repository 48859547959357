import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Row, Col, Radio, Form as AntForm } from 'antd'
import { Button, Form, Input } from 'shared/components'
import addExternalUserConfig from './addExternalUserConfig'
import './AddExternalUser.scss'
import { createUser, getExternalUsersList, saveLists } from 'shared/redux/ListActions'
import SignatureButton from 'features/Admin/components/Signature/components/SignatureButton'
import SignatureInfo from 'features/Admin/components/Signature/components/SignatureInfo'
import { uploadSignature } from 'features/User/redux/UserActions'
import {
  customerInfoConfig,
  contactInfoConfig,
} from 'features/Project/components/ProjectDetails/projectDetailsConfig'
import ProjectForm from 'features/Project/components/ProjectForm'
import ModalContent from 'features/Modal/components/ModalContent'
import { setModalContent } from 'shared/redux/ScreenActions'
import { captureSentryError } from 'utils/helpers'
import { Store } from 'reduxStore'

interface Props extends PropsFromRedux {
  form: AntForm['props']['form']
}

interface State {
  error?: boolean
  signatureFile?: File
  newUserInfo: {
    username: string
    password: string
    password_check: string
    projecttype: string
    roles: Array<string>
  }
}

class AddExternalUser extends Component<Props, State> {
  state: Readonly<State> = {
    error: null,
    newUserInfo: {
      username: '',
      password: '',
      password_check: '',
      projecttype:'regular',
      roles: ['ExternalUser'],
    },
    signatureFile: null,
  }

  customerInfo: AntForm
  contactInfo: AntForm

  componentDidMount() {
    const { saveLists } = this.props
    saveLists({
      contacts: [],
      customers: [],
    })
  }

  handleSubmit = () => {
    const { newUserInfo, signatureFile } = this.state

    const { createUser } = this.props

    let errors = false
    this.customerInfo.props.form.validateFieldsAndScroll((err) => {
      errors = Boolean(err)
    })
    this.contactInfo.props.form.validateFields((err) => {
      errors = Boolean(err)
    })

    if (errors) return

    const customerFormValues = this.customerInfo.props.form.getFieldsValue()
    const contactFormValues = this.contactInfo.props.form.getFieldsValue()
    const formValues = {
      ...newUserInfo,
      regularproject:newUserInfo.projecttype == 'regular' || newUserInfo.projecttype == 'all',
      laproject:newUserInfo.projecttype == 'la' || newUserInfo.projecttype == 'all',
      ...(customerFormValues?.customerid
        ? {
            customerid: customerFormValues.customerid,
          }
        : {}),
      ...(contactFormValues?.contactid
        ? {
            ...contactFormValues,
            contactid: contactFormValues.contactid,
          }
        : {}),
    }

    this.validateFields().then(async () => {
      delete formValues.password_check
      delete formValues.projecttype
      createUser(formValues)
        .then((userId) => {
          if (signatureFile && userId) {
            this.props
              .uploadSignature(userId, signatureFile)
              .then(() => {
                console.log('success')
              })
              .catch((e) => {
                console.log('error', e)
              })
          }
          this.props.getExternalUsersList(this.props.searchTermExternalUsers)
        })
        .catch((e) => this.setState({ error: e }))
    })
  }

  renderUserInfoFields = () => {
    const { getFieldDecorator } = this.props.form
    return addExternalUserConfig(this.state).map((field, i) => {
      return (
        <Col span={field.cols || 12} key={i}>
          {(() => {
            switch (field.type) {
              case 'input': {
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, {
                      rules: field.rules,
                    })(
                      <Input
                        placeholder={field.placeholder}
                        onChange={(val) =>
                          this.setState(({ newUserInfo }) => ({
                            error: null,
                            newUserInfo: {
                              ...newUserInfo,
                              [field.key]: val,
                            },
                          }))
                        }
                        type={field.key.includes('password') ? 'password' : 'text'}
                      />
                    )}
                  </Form.Item>
                )
              }
              case 'radio': {
                return (
                  <Form.Item label={field.label}>
                    {getFieldDecorator(field.key, { initialValue: field.value })(
                      <Radio.Group
                        name={field.key}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e
                          const optionValues = field?.options?.map((opt) => opt.value) || []
                          const isStringBool =
                            optionValues.includes('true') && optionValues.includes('false')
                          this.setState(({ newUserInfo }) => ({
                            error: null,
                            newUserInfo: {
                              ...newUserInfo,
                              [field.key]: isStringBool ? value === 'true' : value,
                            },
                          }))
                        }}
                        options={field.options}
                      />
                    )}
                  </Form.Item>
                )
              }
              default:
                return null
            }
          })()}
        </Col>
      )
    })
  }

  validateFields = () => {
    const { validateFields } = this.props.form
    return new Promise((resolve, reject) => {
      validateFields((errors, values) => {
        if (errors) {
          reject()
        } else {
          resolve(values)
        }
      })
    })
  }

  resetFields = (form) => {
    if (form === 'customerid') {
      this.customerInfo.props.form.setFieldsValue({
        name: '',
        number: '',
        streetaddress1: '',
        streetaddress2: '',
        city: '',
        state: '',
        zipcode: '',
        phonenumber: '',
        faxnumber: '',
      })
    }
  }

  handleSignatureSelect = (file: File) => {
    this.setState({ signatureFile: file })
  }

  render() {
    const {
      error,
      newUserInfo: { roles, ...requiredInfo },
      signatureFile,
    } = this.state
    const { setModalContent } = this.props
    const generalInfoErrors = !Object.values(requiredInfo).every(Boolean)
    const customerInfoErrors = Object.values(
      this.customerInfo?.props?.form?.getFieldsError?.() || {}
    ).some(Boolean)
    const contactInfoErrors = Object.values(
      this.contactInfo?.props?.form?.getFieldsError?.() || {}
    ).some(Boolean)
    const disabled = generalInfoErrors || customerInfoErrors || contactInfoErrors

    return (
      <ModalContent
        title={<>Add New External User {error ? <p className="error">{error}</p> : null}</>}
        id="add-ext-user"
        width="628px"
        height="90vh"
      >
        <ModalContent.Section title="User Info" className="u-mb-4">
          <Form className="add-ext-user__form">
            <Row type="flex" gutter={16} className="ant-row-relative">
              {this.renderUserInfoFields()}
            </Row>
          </Form>
          <div className="add-ext-user__signature">
            <div className="ant-col ant-form-item-label">
              <label title="Upload Email Signature" htmlFor="sig-upload">
                Upload Email Signature
              </label>
            </div>
            <SignatureButton handleFile={this.handleSignatureSelect} />
            {signatureFile ? (
              <SignatureInfo filename={(signatureFile || { name: '' }).name} />
            ) : null}
          </div>
        </ModalContent.Section>

        <ModalContent.Section title="Customer Info">
          <ProjectForm
            wrappedComponentRef={(node) => {
              this.customerInfo = node
            }}
            config={customerInfoConfig}
            resetFields={this.resetFields}
            type="user"
          />
        </ModalContent.Section>

        <ModalContent.Section title="Contact Info">
          <ProjectForm
            wrappedComponentRef={(node) => {
              this.contactInfo = node
            }}
            config={contactInfoConfig}
            resetFields={this.resetFields}
            type="user"
          />
        </ModalContent.Section>

        <ModalContent.Footer>
          <Button small secondary text="Cancel" onClick={() => setModalContent(null)} />
          <Button
            small
            disabled={disabled}
            text="Submit"
            onClick={this.handleSubmit}
            data-testid="submitnewexternaluserbtn"
          />
        </ModalContent.Footer>
      </ModalContent>
    )
  }
}

const AddExternalUserForm = Form.create({ name: 'add-ext-user' })(AddExternalUser)

const mapStateToProps = (state: Store) => {
  try {
    return {
      contacts: state.ListReducer.contacts,
      searchTermExternalUsers: state.ListReducer.searchTermExternalUsers,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  createUser,
  setModalContent,
  uploadSignature,
  saveLists,
  getExternalUsersList,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AddExternalUserForm)
