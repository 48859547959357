import { initializeunderguard } from './UnderguardActions'
import { initializedriptray } from './DriptrayActions'
import { initializefixedendstop } from './FixedEndstopActions'
import { initializeguiderails } from './GuideRailsActions'
import { initializesidewalls } from './SideWallsActions'
import { initializeceilingsupports } from './CeilingSupportsActions'
import { initializefloorsupports } from './FloorSupportsActions'
import { setDefaultAccessoriesDataForConveyor } from '../../../../redux/EstimatorActions'

const initializeaccessories = (conveyorId, accessoryData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoriesDataForConveyor({
      conveyorId,
      accessoryData,
    })
  )
}

export {
  initializeunderguard,
  initializedriptray,
  initializefixedendstop,
  initializeguiderails,
  initializesidewalls,
  initializeceilingsupports,
  initializefloorsupports,
  initializeaccessories,
}
