import { IConveyorWithKeyedSections } from 'shared/types/Conveyor'
import { IConveyor, IConveyorSection } from 'shared/types/swagger'
import { formatIdAsKey } from 'utils/helpers'

function mergeConveyorSections(
  originalSections: Record<string, IConveyorSection>,
  newSections: Array<IConveyorSection>
): Array<IConveyorSection> {
  return Object.entries(originalSections).map(([id, section]) => ({
    ...section,
    ...newSections.find((s) => s.id.toString() === id),
  }))
}

export default function updateConveyorTorquemasterValues(
  conveyorData: IConveyorWithKeyedSections,
  tmData: IConveyor
): Omit<IConveyorWithKeyedSections, 'conveyorsections'> & {
  conveyorsections: Record<string, IConveyorSection>
} {
  const { infeed, discharge, conveyorsections, ...rest } = conveyorData
  const {
    infeed: tmInfeed,
    discharge: tmDischarge,
    conveyorsections: tmConveyorsections,
    pricevalidations,
    ...tmRest
  } = tmData

  const newConveyorSections = mergeConveyorSections(conveyorsections, tmConveyorsections)

  return {
    ...{ ...rest, ...tmRest },
    infeed: { ...infeed, ...tmInfeed },
    discharge: { ...discharge, ...tmDischarge },
    conveyorsections: formatIdAsKey(newConveyorSections),
  }
}
