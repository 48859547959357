import React, { Component } from 'react'
import _ from 'lodash'
import { Steps } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateNonStandardRowMeta } from '../../../redux/ChainActions'
import { captureSentryError } from 'utils/helpers'

const { Step } = Steps
const steps = ['left', 'center', 'right']

class RowSteps extends Component {
  renderSteps = () => {
    return this.props.availableSteps.map((s, i) => {
      return (
        <Step key={`row-steps-step-${i}`} title={`${_.capitalize(s)} Links`} />
      ) /* eslint-disable-line */
    })
  }
  render() {
    return (
      <Steps size="small" current={steps.indexOf(this.props.currentStep)}>
        {this.renderSteps()}
      </Steps>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { ChainReducer } = state
    const { conveyorId, match } = props
    const {
      params: { versionId },
    } = match

    return {
      versionId,
      conveyorId,
      chainMetaInformation: ChainReducer[versionId][conveyorId].nonStandardRowMeta,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  updateNonStandardRowMeta,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RowSteps))
