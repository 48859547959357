import React, { Component } from 'react'
import * as Scroll from 'react-scroll'
import ADP from 'awesome-debounce-promise'
import { Checkbox, Form } from 'antd'
import { Button, GrabZone } from 'shared/components'
import SectionTypes from 'shared/constants/conveyorSectionTypes'
import builderTileConfig from '../builderTileConfig'
import BuilderTileHeading from './components/BuilderTileHeading'
import BuilderTileSubheading from './components/BuilderTileSubheading'
import AccessoriesToggle from './components/AccessoriesToggle'
import MaterialsToggle from './components/MaterialsToggle'
import BuilderTileInput from './components/BuilderTileInput'
import BuilderTileRadio from './components/BuilderTileRadio'
import BuilderTileSelect from './components/BuilderTileSelect'
import { PropsFromRedux } from './index'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { getCautionsWarnings } from '../../helpers'

export interface Props extends PropsFromRedux {
  conveyorId: number;
  form: WrappedFormUtils;
}
export interface IBuilderTileProps extends Props {
  incline?: number;
}

class BuilderTile extends Component<Props> {
  async componentDidMount() {
    const { validated } = this.props
    if (!validated) {
      this.collapseSection(false)
    }
    if (!this.props.hasSectionMaterialsData) {
      this.props.fetchMaterialsForConveyorSection(this.props.conveyorId, this.props.item.id)
    }
    if (!this.props.hasSectionAccessoriesData) {
      this.props.fetchAccessoriesForConveyorSection(this.props.conveyorId, this.props.item.id)
    }
    this.customValidateFields()
  }

  componentDidUpdate(prevProps) {
    const { conveyorId, collapsed, item, selectedSectionId, form } = this.props
    const builderDataContainer = `builder-list-${conveyorId}`

    if (!collapsed && prevProps.collapsed !== collapsed) {
      this.customValidateFields()
      if (
        selectedSectionId === item.id.toString() &&
        selectedSectionId !== prevProps.selectedSectionId
      ) {
        Scroll.scroller.scrollTo(`builder-tile-${selectedSectionId}`, {
          duration: 1000,
          smooth: 'easeOut',
          offset:
            (document.getElementById(builderDataContainer).getBoundingClientRect().y + 15) * -1,
          containerId: builderDataContainer,
        })
      }
    }
    if (!this.props.hasSectionMaterialsData && prevProps.collapsed && !this.props.collapsed) {
      this.props.fetchMaterialsForConveyorSection(this.props.conveyorId, this.props.item.id)
    }
    if (!this.props.hasSectionAccessoriesData && prevProps.collapsed && !this.props.collapsed) {
      this.props.fetchAccessoriesForConveyorSection(this.props.conveyorId, this.props.item.id)
    }
    if (item.type !== prevProps.item.type) {
      form.resetFields()
    }
  }

  collapseSection = (boolean, collapseOthers = false) => {
    const { collapsed, collapseSection, conveyor, item } = this.props
    collapseSection({
      collapsed: boolean ? boolean : !collapsed,
      collapseOthers,
      conveyorId: conveyor.id,
      sectionId: item.id,
    })
  }

  customValidateFields = () => {
    const {
      conveyor,
      form,
      item,
      sectionFirstValidation,
      validateSection,
      permissions,
    } = this.props
    const canOverrideBendRadius = permissions.includes('bypass_minimum_radius')

    return new Promise(async (resolve, reject) => {
      form.validateFields((errors, values) => {
        let validated = true

        if (errors) {
          const errorKeys = Object.keys(errors)
          const errorIsRadius = errorKeys.includes('radius') && errorKeys.length === 1
          const errorIsWarning = errorKeys.includes('warnings')
          if (!(errorIsRadius && canOverrideBendRadius) && !errorIsWarning) {
            validated = false
            console.log('validation failure', { errors, values, errorKeys, errorIsRadius })
          }
        }

        validateSection({
          validated: validated,
          conveyorId: conveyor.id,
          sectionId: item.id,
        })
        resolve(validated)
      })

      if (!sectionFirstValidation) {
        this.collapseSection(true)
      }
    })
  }

  deleteSection = () => {
    const { conveyorId, deleteConveyorSection, item, openConfirmModal } = this.props
    openConfirmModal({
      bodyText: `Are you sure you want to delete Section ${item.sectionnumber}: ${
        SectionTypes[item.type]
      }?`,
      onConfirm: () => deleteConveyorSection(conveyorId, item.id),
    })
  }

  renderContent = () => {
    const { isactive, islocked, item, unit, conveyor, form } = this.props
    const configArgs =
      item.type === 'HorizontalCurve'
        ? { ...this.props, incline: form.getFieldValue('incline') }
        : this.props
    const disabled = !isactive || islocked

    return builderTileConfig(configArgs).map((field) => {
      if (field.key === null) return null

      switch (field.type) {
        case 'select': {
          return (
            <BuilderTileSelect
              form={form}
              item={item}
              field={field}
              updateSection={this.updateConveyorSection}
              isactive={isactive}
              islocked={islocked}
            />
          )
        }
        case 'input': {
          return (
            <BuilderTileInput
              form={form}
              item={item}
              conveyor={conveyor}
              unit={unit}
              field={field}
              isactive={isactive}
              islocked={islocked}
              customValidateFields={this.customValidateFields}
              updateSection={this.debouncedUpdateConveyorSection}
            />
          )
        }
        case 'materials': {
          return (
            <MaterialsToggle
              itemId={item.id}
              isactive={isactive}
              islocked={islocked}
              field={field}
              conveyorId={conveyor.id}
            />
          )
        }
        case 'accessories': {
          return (
            <AccessoriesToggle
              itemId={item.id}
              isactive={isactive}
              islocked={islocked}
              field={field}
              conveyorId={conveyor.id}
            />
          )
        }
        case 'checkbox': {
          return (
            <div
              className="builder-tile__expanded__content__wrapper"
              key={`form-item-${field.key}-${item.id}`}
            >
              <div className="radio-title">{field.prettyName}</div>
              <Form.Item>
                {form.getFieldDecorator(field.key, {
                  initialValue: field.value,
                  rules: field.rules,
                })(
                  <Checkbox
                    disabled={disabled}
                    checked={Boolean(field.value)}
                    style={{ width: '230px' }}
                    onChange={(e) => {
                      this.updateConveyorSection({ [field.key]: e.target.checked })
                    }}
                  >
                    {field.label}
                  </Checkbox>
                )}
              </Form.Item>
            </div>
          )
        }
        case 'radio': {
          return (
            <BuilderTileRadio
              form={form}
              item={item}
              field={field}
              isactive={isactive}
              islocked={islocked}
              updateSection={this.updateConveyorSection}
            />
          )
        }
        default:
          return null
      }
    })
  }

  debouncedUpdateConveyorSection = ADP(
    (payload) => {
      this.updateConveyorSection(payload)
    },
    1500,
    {
      key: (...args) => Object.keys(args[0])[0],
    }
  )

  updateConveyorSection = async (payload, debounce = false) => {
    const { conveyor, form, item, updateConveyorSectionOptimistic } = this.props

    await this.customValidateFields().then((validated) => {
      if (validated || Object.keys(payload).includes('type')) {
        let fields = form.getFieldsValue()

        if (Object.keys(payload).includes('type')) {
          fields = payload
        }

        if (item.type === 'HorizontalCurve') {
          fields.angle = fields.angle * fields.direction
        }

        updateConveyorSectionOptimistic(conveyor.id, item.id, {
          type: item.type,
          ...fields,
          ...payload,
        }).then(() => {
          form.resetFields()
          this.customValidateFields()
        })
      }
    })
  }

  render() {
    const {
      collapsed,
      isactive,
      islocked,
      item,
      versionId,
      conveyor,
      sectionAccessoriesData,
      sectionMaterialsData,
      validated,
      unit,
    } = this.props

    const collapseibleId = `collapse-${versionId}`
    const { warnings, cautions } = getCautionsWarnings(item)
  
    return (
      <div
        id={`builder-tile-${item.id}`}
        className={`builder-tile ${isactive && !islocked ? '' : 'disabled'}`}
      >
        <div className="builder-tile__collapsed">
          <div className="collapsed-main">
            <BuilderTileHeading
              collapsed={collapsed}
              handleCollapse={() => this.collapseSection(null, true)}
              type={item.type}
              sectionnumber={item.sectionnumber}
              handleClone={() => this.props.cloneConveyorSection(conveyor.id, item.id)}
              hasOverrides={
                sectionAccessoriesData && sectionMaterialsData
                  ? Object.entries({ ...sectionAccessoriesData, ...sectionMaterialsData }).some(
                      ([key, val]) => {
                        if ('parameteractive' in val) {
                          return val.parameteractive
                        }
                        return val?.left?.sectionoverride || val?.right?.sectionoverride
                      }
                    )
                  : false
              }
              aria-controls={collapseibleId}
              aria-expanded={!collapsed}
              disabled={!isactive || islocked}
            />
            {collapsed && isactive && !islocked ? (
              <GrabZone className="collapsed-main__drag" />
            ) : null}
          </div>
          {collapsed ? (
            <BuilderTileSubheading item={item} validated={validated} unit={unit} warnings={warnings} cautions={cautions}/>
          ) : <div>
            {cautions.map((caution) => (<div className="caution-sections">{caution}</div>))}
            {warnings.map((warning) => (<div className="warning-sections">{warning}</div>))}
          </div>}
        </div>
        <div
          id={collapseibleId}
          hidden={collapsed}
          style={{ visibility: collapsed ? 'hidden' : 'visible' }}
        >
          {this.renderContent()}
          {isactive && !islocked ? (
            <div className="save-delete">
              <Button
                small
                danger
                className="delete-section"
                text="Delete Section"
                onClick={this.deleteSection}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default BuilderTile
