import { IConveyorSection } from 'shared/types/swagger'
import { MM_IN_1_16_IN, MM_IN_FT, MM_IN_IN } from '../components/BuilderCanvas/constants'
import { ConveyorSectionWithStringId } from '../components/BuilderCanvas/types/types'

export default function getMultipartLength(
  section: IConveyorSection | ConveyorSectionWithStringId
) {
  return (
    (section.lengthinputfeetpart || 0) * MM_IN_FT +
    (section.lengthinputinchespart || 0) * MM_IN_IN +
    (section.lengthinput116inchespart || 0) * MM_IN_1_16_IN
  )
}
