export default (props, sideId, sideName) => {
  const {
    // sidewallsData: {
    //   [ sideName ]: {
    //     ...sidewallsData
    //   }
    // },
    sidewallsOptions: { materials },
  } = props

  const orders = {
    '12 Gauge Stainless': 1,
    '14 Gauge Stainless': 2,
    '1/2" (12.7mm) UHMW': 3,
    '0.177" (4.50mm) Lexan': 4,
  }

  return {
    sidewallmaterial: {
      dataKey: 'materialid',
      required: true,
      prettyName: 'Side Wall Material',
      type: 'radio',
      vertical: true,
      rules: [
        {
          required: true,
          message: 'A selection is required.',
        },
      ],
      options: Object.values(materials).sort(
        (a, b) => (orders[a.title] || a.id) - (orders[b.title] || a.id)
      ),
    },
    distancetoc: {
      type: 'distancetoc',
    },
  }
}
