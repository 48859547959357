import * as React from 'react'
import { Switch } from 'antd'
import {
  Sideframes,
  Wearstrips,
} from 'features/Estimator/components/Materials/components/sectionOverrides'
import { typedUseSelector } from 'utils/helpers'
import { FormConfigItem } from 'shared/types/formConfig'

interface Props {
  itemId: number
  isactive: boolean
  islocked: boolean
  field: FormConfigItem
  conveyorId: number
}

export default function MaterialsToggle(props: Props) {
  const { itemId, isactive, islocked, field, conveyorId } = props
  const hasOneActive = typedUseSelector(({ EstimatorReducer }) => {
    return Object.values(EstimatorReducer?.sections?.[itemId]?.materials || {}).some(
      (o) => o.parameteractive
    )
  })
  const [toggled, setToggled] = React.useState(hasOneActive)
  React.useLayoutEffect(() => {
    setToggled(hasOneActive)
  }, [hasOneActive])

  const collapseibleId = `materials-toggle-${itemId}`

  if (!(toggled || isactive) || islocked) return null
  return (
    <div
      className="builder-tile__expanded__content__wrapper"
      key={`form-item-${field.key}-${itemId}`}
    >
      <div className="option-title">{field.prettyName}</div>
      <div className="option-label toggle">{field.label}</div>
      <Switch
        checked={toggled}
        disabled={hasOneActive}
        onClick={(checked) => {
          if (!hasOneActive) setToggled(checked)
        }}
        aria-controls={collapseibleId}
        aria-expanded={toggled}
      />
      <div
        id={collapseibleId}
        hidden={!toggled}
        style={{ visibility: toggled ? 'visible' : 'hidden' }}
      >
        <Sideframes conveyorId={conveyorId} sectionId={itemId} />
        <Wearstrips conveyorId={conveyorId} sectionId={itemId} />
      </div>
    </div>
  )
}
