import { createSelector } from '@reduxjs/toolkit'
import { Store } from 'srcReducer'
import { selectVersionConveyorIds } from 'features/Version/redux/VersionSelectors'

const selectEstimatorReducer = (state: Store) => state.EstimatorReducer
export const selectEstimatorMetaReducer = (state: Store) => state.EstimatorMetaReducer

export const selectGuideRailsData = createSelector(
  selectEstimatorReducer,
  selectVersionConveyorIds,
  (EstimatorReducer, conveyorIds) =>
    conveyorIds.map((id) => EstimatorReducer?.[id]?.accessories?.guiderails).filter(Boolean)
)

export const selectGuideRailsMeta = createSelector(
  selectEstimatorMetaReducer,
  selectVersionConveyorIds,
  (EstimatorMetaReducer, conveyorIds) =>
    conveyorIds
      .map((id) => EstimatorMetaReducer[id].materialsAccessoriesMetadata.accessories.guiderails)
      .filter(Boolean)
)
