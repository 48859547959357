import React from 'react'
import _ from 'lodash'
import { TMIcon } from 'shared/components'
import { ifDecimalRoundToNum } from 'utils/helpers'

export const normalTransport = (unit) => [
  {
    title: '',
    dataIndex: '',
    key: 'results',
    width: 40,
    render: (record) => {
      const {
        bottomforceaccumstate,
        bottomtempaccumstate,
        topforceaccumstate,
        toptempaccumstate,
        ...rest
      } = record
      const counter = {
        warnings: 0,
        cautions: 0,
      }
      _.mapValues(rest, (v) => {
        if (v === 'Caution') {
          counter.cautions = counter.cautions + 1
        } else if (v === 'Warning') {
          counter.warnings = counter.warnings + 1
        }
      })
      const status = counter.warnings ? 'Warning' : counter.cautions ? 'Caution' : 'OK'
      return TMIcon[status]
    },
  },
  {
    title: 'Section #',
    dataIndex: 'sectionnumber',
    key: 'sectionnumber',
    width: 100,
    render: (value) => value + 1,
  },
  {
    title: 'Section Type',
    dataIndex: 'type',
    key: 'type',
    width: 150,
    render: (value) => {
      return value === 'HorizontalCurve'
        ? 'Horizontal Curve'
        : value === 'VerticalCurve'
        ? 'Vertical Curve'
        : value
    },
  },
  {
    title: 'Length/Radius',
    key: 'length',
    width: 150,
    render: (record) => {
      const {
        lengthinputmm,
        lengthinputfeetpart,
        lengthinputinchespart,
        lengthinput116inchespart,
        radius,
        type,
      } = record

      const length =
        (lengthinputfeetpart * 12 || 0) +
        (lengthinputinchespart || 0) +
        (lengthinput116inchespart / 16 || 0)
      return ifDecimalRoundToNum(
        type === 'HorizontalCurve' || type === 'VerticalCurve'
          ? radius
          : type === 'Infeed' || type === 'Discharge'
          ? record.length
          : unit === 'Metric'
          ? lengthinputmm
          : length
      )
    },
  },
  {
    title: 'Angle',
    dataIndex: 'angle',
    key: 'angle',
    width: 150,
    render: (value, record) =>
      (record.type === 'VerticalCurve' ? record.incline : value ? value : 0) + '°',
  },
  {
    title: 'Bottom of Conveyor',
    className: 'two-column-span',
    children: [
      {
        title: 'Force',
        dataIndex: 'bottomforce',
        key: 'bottomforce',
        className: 'force',
        render: function ForceCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.bottomforcestate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
      {
        title: 'Temp',
        dataIndex: 'bottomtemp',
        key: 'bottomtemp',
        className: 'temp',
        render: function TempCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.bottomtempstate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
    ],
  },
  {
    title: 'Top of Conveyor',
    className: 'two-column-span',
    children: [
      {
        title: 'Force',
        dataIndex: 'topforce',
        key: 'topforce',
        className: 'force',
        render: function TopForceCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.topforcestate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
      {
        title: 'Temp',
        dataIndex: 'toptemp',
        key: 'toptemp',
        className: 'temp',
        render: function TopTempCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.toptempstate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
    ],
  },
]

export const productAccum = (unit) => [
  {
    title: '',
    dataIndex: '',
    key: 'results',
    width: 40,
    render: (record) => {
      const { bottomforcestate, bottomtempstate, topforcestate, toptempstate, ...rest } = record
      const counter = {
        warnings: 0,
        cautions: 0,
      }
      _.mapValues(rest, (v) => {
        if (v === 'Caution') {
          counter.cautions = counter.cautions + 1
        } else if (v === 'Warning') {
          counter.warnings = counter.warnings + 1
        }
      })
      const status = counter.warnings ? 'Warning' : counter.cautions ? 'Caution' : 'OK'
      return TMIcon[status]
    },
  },
  {
    title: 'Section #',
    dataIndex: 'sectionnumber',
    key: 'sectionnumber',
    width: 100,
    render: (value) => value + 1,
  },
  {
    title: 'Section Type',
    dataIndex: 'type',
    key: 'type',
    width: 150,
    render: (value) => {
      return value === 'HorizontalCurve'
        ? 'Horizontal Curve'
        : value === 'VerticalCurve'
        ? 'Vertical Curve'
        : value
    },
  },
  {
    title: 'Length/Radius',
    key: 'length',
    width: 150,
    render: (record) => {
      const {
        lengthinputmm,
        lengthinputfeetpart,
        lengthinputinchespart,
        lengthinput116inchespart,
        radius,
        type,
      } = record

      const length =
        (lengthinputfeetpart * 12 || 0) +
        (lengthinputinchespart || 0) +
        (lengthinput116inchespart / 16 || 0)
      return ifDecimalRoundToNum(
        type === 'HorizontalCurve' || type === 'VerticalCurve'
          ? radius
          : type === 'Infeed' || type === 'Discharge'
          ? record.length
          : unit === 'Metric'
          ? lengthinputmm
          : length
      )
    },
  },
  {
    title: 'Angle',
    dataIndex: 'angle',
    key: 'angle',
    width: 150,
    render: (value, record) =>
      (record.type === 'VerticalCurve' ? record.incline : value ? value : 0) + '°',
  },
  {
    title: 'Bottom of Conveyor',
    className: 'two-column-span',
    children: [
      {
        title: 'Force',
        dataIndex: 'bottomforceaccum',
        key: 'bottomforceaccum',
        className: 'force',
        render: function BottomForceCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.bottomforceaccumstate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
      {
        title: 'Temp',
        dataIndex: 'bottomtempaccum',
        key: 'bottomtempaccum',
        className: 'temp',
        render: function BottomTempCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.bottomtempaccumstate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
    ],
  },
  {
    title: 'Top of Conveyor',
    className: 'two-column-span',
    children: [
      {
        title: 'Force',
        dataIndex: 'topforceaccum',
        key: 'topforceaccum',
        className: 'force',
        render: function TopForceAccumCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.topforceaccumstate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
      {
        title: 'Temp',
        dataIndex: 'toptempaccum',
        key: 'toptempaccum',
        className: 'temp',
        render: function TopTempAccumCell(value, record) {
          return (
            <React.Fragment>
              {TMIcon[record.toptempaccumstate]}
              {value.toFixed(2)}
            </React.Fragment>
          )
        },
      },
    ],
  },
]
