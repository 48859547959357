import {
  setAccessoryDataForConveyorSection,
  runUpdateMaterialOrAccessoryForConveyorSection,
} from '../../../../redux/SectionOverrideActions'
import { fetchDefaultAccessoryForConveyor } from '../../../../redux/EstimatorActions'

export const initializesectionunderguard = (underguardData, sectionId) => async (dispatch) => {
  dispatch(
    setAccessoryDataForConveyorSection({
      sectionId,
      accessoryName: 'underguard',
      accessoryData: underguardData,
    })
  )
}

export const updateUnderguard = ({
  conveyorId,
  updatedUnderguardFields,
  sideName,
  sectionId,
}) => async (dispatch) => {
  await dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      sectionId,
      materialKey: 'underguard',
      updatedFields: updatedUnderguardFields,
      type: 'accessories',
      sideName,
    })
  )
  await fetchDefaultAccessoryForConveyor(conveyorId, 'underguard')
}
