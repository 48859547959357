import http from 'utils/api/Client'
import { formatIdAsKey } from 'utils/helpers'

/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

const ConveyorSectionService = {
  async createConveyorSection(conveyorId, payload) {
    return await http
      .post(`/Conveyors/${conveyorId}/ConveyorSections`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async deleteConveyorSection(conveyorId, sectionId) {
    return await http
      .delete(`/Conveyors/${conveyorId}/ConveyorSections/${sectionId}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateConveyorSection(conveyorId, sectionId, payload) {
    return await http
      .put(`/Conveyors/${conveyorId}/ConveyorSections/${sectionId}`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateAllConveyorSections(conveyorId, payload) {
    return await http
      .put(`/Conveyors/${conveyorId}/ConveyorSections`, payload)
      .then(({ data }) => formatIdAsKey(data))
      .catch((e) => {
        throw e
      })
  },
  async addSectionAccessory(sectionId, itemName, payload) {
    return await http
      .post(
        `/ConveyorSections/${sectionId}/Accessories/${itemName}`,
        payload || { parameteractive: true }
      )
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async addSectionMaterial(sectionId, itemName, payload) {
    return await http
      .post(
        `/ConveyorSections/${sectionId}/Materials/${itemName}`,
        payload || { parameteractive: true }
      )
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateInfeedDischarge(conveyorId, sectionType, payload) {
    return await http
      .put(`/Conveyors/${conveyorId}/${sectionType}`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async createTransfer(conveyorId, sectionType) {
    return await http
      .post(`/Conveyors/${conveyorId}/Transfers/${sectionType}`, {})
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async deleteTransfer(conveyorId, sectionType) {
    return await http
      .delete(`/Conveyors/${conveyorId}/Transfers/${sectionType}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async getInfeedDischargeTransfer(conveyorId, sectionType) {
    return await http
      .get(`/Conveyors/${conveyorId}/Transfers/${sectionType}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateTransfer(conveyorId, sectionType, payload) {
    return await http
      .put(`/Conveyors/${conveyorId}/Transfers/${sectionType}`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
}

export default ConveyorSectionService
