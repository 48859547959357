import { createAction } from '@reduxjs/toolkit'
import { formatIdAsKey } from 'utils/helpers'
import store from 'utils/../reduxStore'
import ProductService from 'utils/api/product/ProductService'
import { getConveyor } from 'features/Conveyor/redux/ConveyorActions'

const addProducts = createAction('ADD_PRODUCTS')
const setProductData = createAction('UPDATE_PRODUCT')
const addProduct = createAction('ADD_PRODUCT')
const removeProduct = createAction('REMOVE_PRODUCT')
export const setProductValidity = createAction('SET_PRODUCT_VALIDITY')
export const setProductCollapsibleOpen = createAction('SET_PRODUCT_COLLAPSIBLE_OPEN')
/** @type {ActionCreatorWithPayload<Array<{conveyorIds: Array<number>}>, 'REMOVE_CONVEYORS_FOR_OTHER_PROJECTS'>} */
export const removeConveyorsProductsForOtherProjects = createAction(
  'REMOVE_CONVEYORS_FOR_OTHER_PROJECTS'
)

/** @typedef {import('./ProductReducer').Product} Product */

export const getProductsForConveyor = (conveyorId, products) => async (dispatch) => {
  try {
    // const products = await ProductService.getProducts(conveyorId)
    const oldProducts = { ...store.getState().ProductReducer[conveyorId] }
    const validateFields = ['height', 'length', 'materialtypeid', 'rate', 'weight', 'width']
    const productsObject = formatIdAsKey(products)
    dispatch(
      addProducts({
        conveyorId,
        products: productsObject,
        meta: products.reduce((acc, product, i) => {
          const valid = validateFields.every((field) => Boolean(product[field]))
          acc[product.id] = {
            ...oldProducts?.[product.id]?.meta,
            valid,
            ...(i === 0 ? { open: true } : {}),
          }
          return acc
        }, {}),
      })
    )
  } catch (error) {
    console.error(error)
    debugger
  }
}

/**
 * @param {number|string} conveyorId
 * @param {Product} data
 */
export const createProduct = (conveyorId, data = {}, meta = { open: true }) => async (dispatch) => {
  try {
    const product = await ProductService.createProduct(conveyorId, data)
    await dispatch(addProduct({ conveyorId, product, meta }))
    return product
  } catch (error) {
    console.error('create product error', error)
    debugger
  }
}

export const updateProduct = (conveyorId, productId, data = {}) => async (dispatch) => {
  const oldProduct = store.getState().ProductReducer?.[conveyorId]?.[productId]
  try {
    if (!oldProduct) throw new Error("product doesn't exist")
    dispatch(
      setProductData({
        conveyorId,
        productId,
        data,
      })
    )
    const responseData = await ProductService.updateProduct(conveyorId, productId, data)
    dispatch(
      setProductData({
        conveyorId,
        productId,
        data: responseData,
      })
    )
    dispatch(getConveyor(conveyorId))
  } catch (error) {
    console.error(error)
    dispatch(
      setProductData({
        conveyorId,
        productId,
        data: oldProduct,
      })
    )
  }
}

export const cloneProduct = (conveyorId, productId) => async (dispatch) => {
  try {
    const progenitor = store.getState().ProductReducer?.[conveyorId]?.[productId]
    const { id, ...progenitorData } = progenitor
    const progenitorMeta = {
      ...store.getState().ProductReducer?.[conveyorId]?.meta?.[productId],
      open: true,
    }
    dispatch(createProduct(conveyorId, progenitorData, progenitorMeta))
  } catch (error) {
    console.error('clone product error', error)
    debugger
  }
}

export const deleteProduct = (conveyorId, productId) => async (dispatch) => {
  try {
    const responseData = await ProductService.deleteProduct(conveyorId, productId)
    await dispatch(removeProduct({ conveyorId, productId }))
    return responseData
  } catch (error) {
    console.error('delete product error', error)
    debugger
  }
}
