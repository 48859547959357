import { ListElementRecord } from 'utils/api/list/types'
import { DSSA47_VALID_TITLES } from '../constants'

export default function getNewDefaultId(
  list: ListElementRecord,
  value: number | string,
  key: string
) {
  const valueIsWithinAllowed = DSSA47_VALID_TITLES[key].includes(list?.[value]?.title)

  if (!valueIsWithinAllowed) {
    return Object.values(list).find((listitem) => listitem.isdefault)?.id || null
  }
  return null
}
