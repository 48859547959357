import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'shared/components'
import { withRouter } from 'react-router-dom'
import VersionService from 'utils/api/version/VersionService'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import { updateVersionConveyorsCosts, validateVersion } from 'features/Version/redux/VersionActions'
import validateProject from '../utils/validateProject'
import { captureSentryError } from 'utils/helpers'
import { canValidateWithoutRequiredInputs } from '../utils/helper/canValidateWithoutRequiredInputs'

/**
 * @typedef {import('shared/redux/ScreenActions')['openConfirmModal']} openConfirmModal
 * @typedef {import('features/Version/redux/VersionActions')['updateVersionConveyorsCosts']} updateVersionConveyorsCosts
 * @typedef {import('features/Version/redux/VersionActions')['validateVersion']} validateVersion
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 *
 * @typedef {Object} ComponentProps
 * @prop {Number} versionId
 * @prop {Number} projectId
 * @prop {String[]} permissions
 * @prop {boolean} isactive
 * @prop {boolean} islocked
 * @prop {openConfirmModal} openConfirmModal
 * @prop {updateVersionConveyorsCosts} updateVersionConveyorsCosts
 * @prop {validateVersion} validateVersion
 *
 * @typedef {ComponentProps & RouteComponentProps} Props
 */

/** @extends Component<Props> */
class ValidatePriceButton extends Component {
  handleValidationSuccess = () => {
    window.cancelCompleteMuf = false
    this.props.openConfirmModal({
      headerText: 'Generate and Update Conveyors Cost',
      bodyText: 'Fetching Updated Costs for Conveyors',
      confirmButtonText: 'Cancel Request',
      onConfirm: this.cancelUpdateConveyorsCosts,
      cancelButton: false,
      closeButton: false,
    })
    this.handleCompleteMufButtonClick()
  }

  handleClick = () => {
    const { isactive, islocked, versionId, isexternaluser } = this.props

    if (!isactive || islocked) {
      this.handleValidationSuccess()
      return
    }

    const errors = validateProject.all(versionId)
    const canForceValidation = canValidateWithoutRequiredInputs(errors) && !isexternaluser

    if (errors.length) {
      let todisplay = ''
      Map.groupBy(errors,(error)=> error.conveyorId).forEach((_value, key) => {
        todisplay += `<li><span class='text--red'>${_value[0].conveyorName}<ul>${_value.map((err) => `<li><span class='subtext--red'>${err.HTMLMessage}</span>`).join('')}</ul></span></li>`
      })
      
      this.props.openConfirmModal({
        headerText: 'Error',
        bodyInnerHTML: `
          <h3>Could not get Quote due to validation errors in:</h3>
          <ul>
            ${todisplay}
          </ul>
        `,
        confirmButtonText: 'OK',
        cancelButtonText: 'Continue',
        cancelButton: canForceValidation,
        onCancel: this.handleValidationSuccess,
      })
    } else {
      this.handleValidationSuccess()
    }
  }

  cancelUpdateConveyorsCosts = () => {
    const { versionId } = this.props
    window.cancelCompleteMuf = true
    VersionService.cancelUpdateConveyorsCosts(versionId)
  }

  handleCompleteMufButtonClick = async (retry422=false) => {
    const { history, openConfirmModal, projectId, versionId } = this.props
    
    if (!window.cancelCompleteMuf) {
      try {
        await this.props.updateVersionConveyorsCosts(versionId)

        if (retry422)
        {
          // if this method was called again, that means that the costs 
          // were regenerated
          await this.props.validateVersion(versionId)

          // we force the page to refresh so the validation state
          // would be refreshed on next screen
          history.replace(`/project/${projectId}`)
          history.replace(`/project/${projectId}/${versionId}`)
        }

        history.push(`/project/${projectId}/${versionId}/validate`)
      } catch (e) {
        if (!window.cancelCompleteMuf) {
          // If user did not press Cancel Button during pending request
          if (e?.response?.status === 422) {
            // Backend job still processing
            window.handle422error = setTimeout(() => {
              this.handleCompleteMufButtonClick(true)
            }, 10000)
            const bodyText = e.response.headers.popupmessage.split('. ').join('.\n')
            openConfirmModal({
              headerText: 'Generate and Update Conveyors Cost',
              bodyText,
              confirmButtonText: 'Cancel Request',
              onConfirm: () => {
                clearTimeout(window.handle422error)
                this.cancelUpdateConveyorsCosts()
              },
              cancelButton: false,
              closeButton: false,
              disableConfirm: false,
            })
          } else {
            openConfirmModal({
              headerText: 'Error',
              bodyText: 'Could not get updated conveyors costs.',
              cancelButton: false,
              confirmButtonText: 'OK',
            })
          }
        }
      }
    }
  }

  render() {
    const { permissions, isactive, islocked } = this.props

    return (
      <Button
        className="validate-price"
        small
        icon="tag"
        text={`${isactive && !islocked ? 'Get' : 'View'} Quote`}
        disabled={!permissions.includes('validate_prices')}
        onClick={this.handleClick}
      />
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      permissions: state.UserReducer.permissions,
      VFDOptions: state.ConveyorReducer.VFDOptions,
      isexternaluser: state.UserReducer.userType == 'External'
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  openConfirmModal,
  updateVersionConveyorsCosts,
  validateVersion
}

const connectedValidatePriceButton = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ValidatePriceButton)
)

export default connectedValidatePriceButton
