import React, { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Dropdown, Menu } from 'antd'
import { deleteUser, getExternalUsersList } from 'shared/redux/ListActions'
import ExternalUserDetail from '../ExternalUserDetail'
import extUsersTableConfig from './extUsersTableConfig'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import './ExternalUsersTable.scss'
import { ExpandIcon, Input, Icon } from 'shared/components'
import _, { debounce } from 'lodash'

function ExternalUsersTable() {
  const dispatch = useDispatch()
  const users = useSelector(
    (/** @type {import('srcReducer').Store} */ state) => state.ListReducer.externalUsers
  )
  const searchTerm = useSelector(
    (/** @type {import('srcReducer').Store} */ state) => state.ListReducer.searchTermExternalUsers
  )

  useEffect(() => {
    searchExternalUsers(searchTerm)
  }, [dispatch])

  const searchExternalUsers = debounce((...args) => { dispatch(getExternalUsersList(...args)) }, 1000)

  /** @param {UserData} record */
  function renderExpandedRow(record) {
    return <ExternalUserDetail user={record} />
  }

  function renderDelete(user) {
    const onClick = () => {
      dispatch(
        openConfirmModal({
          bodyText: `You are about to remove user ${user.firstname} ${user.lastname}. This action is irreversible. Are you sure you wish to proceed?`,
          onConfirm: () => {
            dispatch(deleteUser(user.key))
            searchExternalUsers(searchTerm)
          },
        })
      )
    }
    const menu = (
      <Menu onClick={onClick}>
        <Menu.Item className="delete-user">Delete User</Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} placement="bottomRight">
        <div data-testid={`userrowactionsmenu${user.username}`}>
          <Icon icon="settings" size={20} />
        </div>
      </Dropdown>
    )
  }

  /** @returns {Array<UserData>} */
  function getUsersData() {
    const sortedUsers = _.sortBy(users, ['lastname', 'firstname'])
    return _.map(sortedUsers, (user) => {
      const { id, username, roles, contact, customer, customerid, contactid, regularproject, laproject } = user
      const firstname = user.firstname || contact?.firstname
      const lastname = user.lastname || contact?.lastname
      const email = user.emailaddress || contact?.emailaddress || user.email
      return {
        key: id,
        regularproject,
        laproject,
        firstname,
        lastname,
        email,
        username,
        roles,
        contact,
        customer,
        customerid,
        contactid,
      }
    })
  }

  function handleSearchInput(e) {
    searchExternalUsers(e.trim())
  }

  return (
    <div>
      <Input
        icon="search"
        placeholder="Search by Name"
        small
        onChange={handleSearchInput}
        allowClear
        width={600}
        defaultValue={searchTerm}
        data-testid="searchforexternalusers"
      />
      <br/>
      <Table
        className="ext-users-table"
        columns={extUsersTableConfig(renderDelete)}
        expandIcon={ExpandIcon}
        expandedRowRender={(record) => renderExpandedRow(record)}
        dataSource={getUsersData()}
        data-testid="externaluserstable"
        {...(process.env.NODE_ENV === 'test' && { pagination: false })}
      />
    </div>
  )
}

export default ExternalUsersTable
