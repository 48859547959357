import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector as unTypedUseSelector } from 'react-redux'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import {
  createConveyorSection,
  updateAllConveyorSections,
} from 'features/Estimator/components/ConveyorBuilder/redux/ConveyorSectionActions'
import conveyorSectionTypes from 'shared/constants/conveyorSectionTypes'
import { AddSectionButton, SectionsList } from './components'
import './BuilderList.scss'
import { DRIVE_SELECTION_DISABLED_REASON, listStyle } from './helpers'
import {
  selectHasInfeedDrive,
  selectHasDischargeDrive,
  getAllowIntermediateDrives,
} from '../../redux/selectors'
import { selectVersionId } from 'features/Version/redux/VersionSelectors'
import { typedUseSelector } from 'utils/helpers'
import { IConveyorSection } from 'shared/types/swagger'

interface Props {
  conveyorId: number
}

function BuilderList(props: Props) {
  const { conveyorId } = props

  const dispatch = useDispatch()
  const conveyor = typedUseSelector((state) => state.ConveyorReducer.conveyors[conveyorId])
  const versionId = typedUseSelector(selectVersionId)
  const isactive = typedUseSelector(
    (state) => state.ProjectReducer?.versions?.[versionId]?.isactive
  )
  const islocked = typedUseSelector(
    (state) => state.ProjectReducer?.versions?.[versionId]?.islocked
  )
  const hasInfeedDrive = typedUseSelector((state) => selectHasInfeedDrive(state, conveyorId))
  const hasDischargeDrive = typedUseSelector((state) => selectHasDischargeDrive(state, conveyorId))
  const allowIntermediatedrives = typedUseSelector((state) =>
    getAllowIntermediateDrives(state, conveyorId)
  )
  const chainsList = typedUseSelector((state) => state.ListReducer.chains)
  const isMultiSpanClosedTop =
    chainsList[conveyor.chain.chainserieid].name === 'MultiSpan Closed-Top'
  const hasIntermediateDrive = Object.values(conveyor?.conveyorsections || {}).some(
    (section) => section.type === 'IntermediateDrive'
  )

  function getDisabledStatus(key: string) {
    let disabledReason = ''
    let disabled = false

    if (key === 'HorizontalCurve') {
      if (isMultiSpanClosedTop) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.MULTISPAN_CLOSEDTOP
      }
    } else if (key === 'IntermediateDrive') {
      if (!allowIntermediatedrives) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.CHAIN_STYLE_DOESNT_ALLOW_INTERMEDIATE
      } else if (hasIntermediateDrive) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.HAS_INTERMEDIATE
      } else if (hasInfeedDrive || hasDischargeDrive) {
        disabled = true
        disabledReason = DRIVE_SELECTION_DISABLED_REASON.HAS_INFEED_OR_DISCHARGE
      }
    }

    return { disabled, disabledReason }
  }

  function handleAddSection(sectionType) {
    dispatch(createConveyorSection(conveyor.id, sectionType))
  }

  function onDragEnd(result: DropResult): void {
    const sections = reorder(
      Object.values(conveyor.conveyorsections),
      result.source.index,
      result.destination.index
    )
    dispatch(updateAllConveyorSections(conveyor.id, sections))
  }

  function reorder(
    list: Array<IConveyorSection>,
    startIndex: number,
    endIndex: number
  ): Array<IConveyorSection> {
    const result = _.sortBy(list, (s) => s.sectionnumber)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    const reorderedSections = result.map((section, index) => {
      return { ...section, sectionnumber: index + 1 }
    })
    return reorderedSections
  }

  return (
    <div
      id={`builder-list-${conveyorId}`}
      className="builder-list"
      style={{ height: window.innerHeight * 0.8 }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <SectionsList conveyor={conveyor} type="Infeed" />
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={listStyle}>
              <SectionsList conveyor={conveyor} />
              {isactive && !islocked ? (
                <div className="add-a-section">
                  <div className="text">Add a Section</div>
                  <div className="section-type-buttons">
                    {Object.entries(conveyorSectionTypes).map(([k, sectionType]) => {
                      const { disabled, disabledReason } = getDisabledStatus(k)

                      return (
                        <AddSectionButton
                          key={k}
                          onClick={() => handleAddSection(k)}
                          disabled={disabled}
                          disabledReason={disabledReason}
                        >
                          {sectionType}
                        </AddSectionButton>
                      )
                    })}
                  </div>
                </div>
              ) : null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <SectionsList conveyor={conveyor} type="Discharge" />
      </DragDropContext>
    </div>
  )
}

export default BuilderList
