/** @typedef {import('shared/types/formConfig.d').FormConfigItem} FormConfigItem */

export const productDimensionsConfig = (props, product) => {
  const { conveyor } = props
  const metric = conveyor.unit === 'Metric'
  const addonAfter = metric ? 'mm' : 'in'
  const minsize = metric ? conveyor.chain.minproductsizemm : conveyor.chain.minproductsizeenglish
  return [
    {
      key: 'length',
      prettyName: 'Length',
      type: 'input',
      value: product.length,
      placeholder: 'Enter Length',
      visible: true,
      addonAfter,
      inputType: Number,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Length is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              } else if (value < minsize) {
                throw new Error('Min length is '+minsize+addonAfter)
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'width',
      prettyName: 'Width',
      type: 'input',
      value: product.width,
      placeholder: 'Enter Width',
      visible: true,
      addonAfter,
      inputType: Number,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Width is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              } else if (value < minsize) {
                throw new Error('Min width is '+minsize+addonAfter)
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'height',
      prettyName: 'Height',
      type: 'input',
      value: product.height,
      placeholder: 'Enter Height',
      visible: true,
      addonAfter,
      inputType: Number,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Height is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'weight',
      prettyName: 'Product Weight',
      type: 'input',
      value: product.weight,
      placeholder: 'Enter Product Weight',
      visible: true,
      addonAfter: metric ? 'Kg' : 'Lbs',
      inputType: Number,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Product Weight is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
  ]
}

/** @returns {FormConfigItem[]} */
export const generalSettingsConfig = (props) => {
  const { conveyor } = props
  const metric = conveyor.unit === 'Metric'

  return [
    {
      key: 'speed',
      prettyName: 'Speed',
      type: 'input',
      value: conveyor.speed,
      placeholder: 'Enter Speed',
      visible: true,
      addonAfter: metric ? 'M/Min' : 'Ft/min',
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Speed is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'temperature',
      prettyName: 'Temperature',
      type: 'input',
      value: conveyor.temperature,
      placeholder: 'Enter Temperature',
      visible: true,
      addonAfter: metric ? '°C' : '°F',
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Temperature is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
  ]
}

/** @returns {FormConfigItem[]} */
export const productDetailsConfig = (props, product) => {
  const { conveyor, materialtypes } = props
  const metric = conveyor.unit === 'Metric'
  return [
    {
      key: 'load',
      prettyName: 'Product Load',
      type: 'text',
      value: product.load,
      placeholder: 'Enter Product Load',
      visible: true,
      addonAfter: metric ? ' Kg/M' : ' Lbs/Ft',
    },
    {
      key: 'materialtypeid',
      prettyName: 'Material Type',
      type: 'select',
      value: product.materialtypeid || undefined,
      placeholder: 'Select Material Type',
      visible: true,
      rules: [
        {
          required: true,
          message: 'Material Type is required.',
        },
      ],
      options: Object.values(materialtypes || {}).map((option) => ({
        value: option.id,
        label: option.title,
      })),
    },
    {
      key: 'rate',
      prettyName: 'Product Rate',
      type: 'input',
      value: product.rate,
      placeholder: 'Enter Product Rate',
      visible: true,
      addonAfter: 'PPM',
      inputType: Number,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Product Rate is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'description',
      prettyName: 'Product Description',
      type: 'textarea',
      value: product.description,
      visible: true,
    },
  ]
}

/** @returns {FormConfigItem[]} */
export const contaminantsConfig = (props) => {
  const { conveyor } = props
  return [
    {
      key: 'frictiontop',
      prettyName: 'Top',
      type: 'input',
      value: conveyor.frictiontop,
      placeholder: 'Enter Friction Top',
      visible: true,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Friction Top is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'frictionload',
      prettyName: 'Load',
      type: 'input',
      value: conveyor.frictionload,
      placeholder: 'Enter Friction Load',
      visible: true,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
    {
      key: 'frictionbottom',
      prettyName: 'Bottom',
      type: 'input',
      value: conveyor.frictionbottom,
      placeholder: 'Enter Friction Bottom',
      visible: true,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Friction Bottom is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
    },
  ]
}

/** @returns {FormConfigItem[]} */
export const conditionsConfig = (props) => {
  const { conveyor } = props
  return [
    {
      key: 'cleanroom',
      prettyName: 'Clean room?',
      type: 'radio',
      value: conveyor?.cleanroom?.toString?.() || 'false',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      visible: true,
    },
    {
      key: 'contaminantspresent',
      prettyName: 'Contaminants present?',
      type: 'radio',
      value: conveyor?.contaminantspresent?.toString?.() || 'true',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      visible: true,
    },
    {
      key: 'sourceofcontaminants',
      prettyName: 'Source of Contaminants',
      type: 'input',
      value: conveyor.sourceofcontaminants,
      visible: true,
    },
  ]
}

export const contaminantsText = [
  {
    text: 'Set to Light Contamination: (0.18)',
    value: 0.18,
  },
  {
    text: 'Set to Medium Contamination: (0.23)',
    value: 0.23,
  },
  {
    text: 'Set to Heavy Contamination: (0.28)',
    value: 0.28,
  },
]

export const legendFields = (props, product) => {
  return [
    {
      key: 'numberoflanes',
      prettyName: '# Prod Lanes',
      type: 'input',
      value: product.numberoflanes,
      visible: true,
    },
    {
      key: 'material',
      prettyName: 'Prod Material',
      type: 'input',
      value: product.material,
      visible: true,
    },
    {
      key: 'tempurature',
      prettyName: 'Prod Temperature',
      type: 'input',
      value: product.tempurature,
      visible: true,
      addonAfter: '°F',
    },
    {
      key: 'accum',
      prettyName: 'Prod Accum',
      type: 'input',
      value: product.accum,
      visible: true,
    },
    {
      key: 'accumlength',
      prettyName: 'Accum Length',
      type: 'input',
      value: product.accumlength,
      visible: true,
    },
  ]
}
