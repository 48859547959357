export const getNonStandardRowWidthsForConveyor = ({
  chainPatterns,
  unit,
  selectedChainSeries,
}) => {
  const allNonStandardRowWidthsForConveyor = {}
  const totalWidthsByIndex = []
  const steps = ['left', 'right', 'center']

  const availableSteps = steps.filter((step) => {
    return selectedChainSeries && selectedChainSeries[`${step}elements`]
      ? selectedChainSeries[`${step}elements`].length
      : false
  })

  chainPatterns.forEach((pattern) => {
    let accumulatedWidth = 0

    pattern['centerelements'].forEach((addedLink) => {
      if (addedLink?.manualwidthmm) {
        // if (unit === 'English') {
        //   accumulatedWidth += Number((addedLink.manualwidthmm / 25.4))
        // } else {
        //   accumulatedWidth += addedLink.manualwidthmm
        // }
        accumulatedWidth += addedLink?.manualwidthmm
      } else {
        const ogLink = selectedChainSeries['centerelements'].find(
          (beLink) => beLink.id === addedLink?.elementid
        )
        // if (unit === 'English') {
        //   accumulatedWidth += ogLink.widthmm / 25.4
        // } else {
        //   accumulatedWidth += ogLink.widthmm
        // }
        accumulatedWidth += ogLink?.widthmm
      }
    })

    if (availableSteps.indexOf('left') !== -1) {
      if (pattern.leftelementid) {
        const ogLink = selectedChainSeries['leftelements'].find(
          (beLink) => beLink.id === pattern.leftelementid
        )
        // if (unit === 'English') {
        //   accumulatedWidth += ogLink.widthmm / 25.4
        // } else {
        //   accumulatedWidth += ogLink.widthmm
        // }
        accumulatedWidth += ogLink.widthmm
      }
    }

    if (availableSteps.indexOf('right') !== -1) {
      if (pattern.rightelementid) {
        const ogLink = selectedChainSeries['rightelements'].find(
          (beLink) => beLink.id === pattern.rightelementid
        )
        // if (unit === 'English') {
        //   accumulatedWidth += ogLink.widthmm / 25.4
        // } else {
        //   accumulatedWidth += ogLink.widthmm
        // }
        accumulatedWidth += ogLink.widthmm
      }
    }

    allNonStandardRowWidthsForConveyor[pattern.id] = accumulatedWidth
    totalWidthsByIndex.push(accumulatedWidth)
  })

  return {
    allNonStandardRowWidthsForConveyor,
    firstRowWidth: totalWidthsByIndex[0],
  }
}

export const findLinkFromElementId = (chainSeries, elementid) => {
  let link = chainSeries.centerelements.find((l) => l.id === elementid)
  if (!link) {
    link = chainSeries.leftelements.find((leftlink) => leftlink.id === elementid)
  }
  if (!link) {
    link = chainSeries.rightelements.find((rightlink) => rightlink.id === elementid)
  }
  return link
}
