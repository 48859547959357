export default function isInputValueTypeValid(value, type) {
  if (!type) return true
  if (type === Number) {
    return !Number.isNaN(Number(value))
  } else if (type === String) {
    return value.constructor === 'string' || Number.isNaN(Number(value))
  } else if (type === Boolean) {
    return typeof value === 'boolean' || value === 'true' || value === 'false'
  } else {
    return false
  }
}
