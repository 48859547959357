import mapDataToOption from './mapDataToOption'

/**
 * @typedef {import('shared/types/ListElement').ListElement} ListElement
 */

/**
 * Get an array of select options values on the selected rail and bracket textvalue
 * `textvalue` is a concatenation of the rail title and bracket title, joined by `''`
 *
 * @param {*} textvalue
 * @param {Object<string, ListElement>} options
 * @returns {Array<{label: string, value: number, default: boolean}>}
 */
export default function getGuideRailsOptionsByTextvalue(textvalue, options = {}) {
  if (!textvalue) return []

  /** @type {Array<ListElement>} */
  const values = Object.values(options)

  return values.filter((option) => option.textvalue === textvalue).map(mapDataToOption)
}
