import { createAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import store from 'utils/../reduxStore'
import { setLoading } from '../../../shared/redux/ScreenActions'
import StatisticsService from '../../../utils/api/statistics/StatisticsService'
import { userTypes } from '../components/UsersByRole/usersByRoleConfig'
import { checkTypeOfDateRange } from '../components/CustomDatePicker/datePickerHelpers'

export const getStatisticsSuccess = createAction('GET_STATISTICS_SUCCESS')
export const selectDepartment = createAction('SELECT_DEPARTMENT')
export const selectChart = createAction('SELECT_CHART')
export const updateDates = createAction('UPDATE_DATES')
export const updateFilter = createAction('UPDATE_FILTER')

export const getStatistics = (dateStrings, customLabel, customerId) => (dispatch) => {
  const config = checkTypeOfDateRange(dateStrings)

  const { calendarLabel, dateType, periodType, startDate, endDate } = config

  return new Promise(async (resolve, reject) => {
    dispatch(
      setLoading({
        loading: true,
        loadingMessage: 'Retrieving statistical data...',
      })
    )
    return await StatisticsService.getStatistics(
      periodType.apiValue,
      startDate,
      endDate,
      customerId
    )
      .then((statisticsData) => {
        dispatch(
          getStatisticsSuccess({
            calendarLabel: customLabel ? customLabel : calendarLabel,
            dateType,
            endDate,
            periodType,
            startDate,
            statisticsData,
          })
        )
        dispatch(setLoading(false))
        resolve(statisticsData)
      })
      .catch((e) => {
        dispatch(setLoading(false))
        reject(e)
      })
  })
}

export const getProjectsByStatus = () => (dispatch) => {
  const { endDate, filterType, filterValue, startDate } = _.cloneDeep(
    store.getState().AdminStatsReducer
  )
  return new Promise(async (resolve, reject) => {
    dispatch(
      setLoading({
        loading: true,
        loadingMessage: 'Retrieving active projects data...',
      })
    )
    return await StatisticsService.getProjectByStatus(startDate, endDate, filterType, filterValue)
      .then((projectByStatusData) => {
        dispatch(
          getStatisticsSuccess({
            projectByStatusData,
          })
        )
        dispatch(setLoading(false))
        resolve(projectByStatusData)
      })
      .catch((e) => {
        dispatch(setLoading(false))
        reject(e)
      })
  })
}

export const getProjectByStatusDetail = (dateStrings, customLabel) => (dispatch) => {
  const config = checkTypeOfDateRange(dateStrings)

  const { calendarLabel, dateType, periodType, startDate, endDate } = config
  const { filterType, filterValue } = _.cloneDeep(store.getState().AdminStatsReducer)

  dispatch(
    setLoading({
      loading: true,
      loadingMessage: 'Refreshing Active Projects Data...',
    })
  )

  const promises = userTypes.map(
    async (user) =>
      await StatisticsService.getProjectByStatusDetail(
        startDate,
        endDate,
        user.key,
        filterType,
        filterValue
      )
  )

  Promise.all(promises).then((data) => {
    dispatch(
      getStatisticsSuccess({
        calendarLabel: customLabel ? customLabel : calendarLabel,
        dateType,
        endDate,
        periodType,
        startDate,
        projectByStatusDetailData: {
          AccountManager: data[0],
          Estimator: data[1],
          Designer: data[2],
          Manager: data[3],
        },
      })
    )
    dispatch(setLoading(false))
  })
}
