import { IHasId } from 'utils/helpers/formatIdAsKey';

type ObjectWithId = IHasId & {
  // turned off no-explicit-any for flexibility and laziness
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type OptionData<I extends ObjectWithId = ObjectWithId> = { key: string; optionId: number } & Omit<I, 'id'>

export default function formatOptionData<
  I extends ObjectWithId = ObjectWithId
>(
  options: Record<string, I>, 
  order?: Array<IHasId['id']>
): Array<OptionData<I>> {

  // when there is order, return by specified ID order
  if (order) {
    return order
      .map(id => {
        return individualFormatOptionData<I>([
        typeof id === 'string' ? id : id.toString(),
        options[id] 
      ])})
      .filter(o => o) // cleans up undefined
  }

  // if there is no order
  return Object.entries(options)
    .map((keyEntryTuple) => individualFormatOptionData<I>(keyEntryTuple))
    .filter(o => o) // cleans up undefined
}

/** @returns undefined if no id exists for option */
function individualFormatOptionData<
  I extends ObjectWithId = ObjectWithId,
>([key, option]: [string, I]): OptionData<I> | undefined {
  if (!option?.id) return

  const { id, ...restOption } = option
  return {
    key,
    optionId: typeof id === 'string' ? parseInt(id) : id,
    ...restOption,
  }
}
