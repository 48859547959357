import React, { PureComponent } from 'react'
import { Select as AntSelect, Tooltip } from 'antd'
import './Select.scss'
import _ from 'lodash'
import cn from 'classnames'

/**
 * @typedef {import('antd/lib/select').SelectProps} SelectProps
 * @typedef {import('shared/types/formConfig.d').FormItemOption} FormItemOption
 *
 * @typedef {Object} Props
 * @prop {string} [label]
 * @prop {boolean} [large]
 * @prop {string} [margin='']
 * @prop {FormItemOption[]} [options]
 * @prop {boolean} [small=false]
 * @prop {string|number} [width]
 * @prop {boolean} [wrap=true]
 *
 * @extends {PureComponent<Props & SelectProps>}
 */
class Select extends PureComponent {
  state = {
    value: this.props.value ? this.props.value : this.props?.defaultValue,
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.options, this.props.options) || this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value || this.props.defaultValue || undefined,
      })
    }
  }

  /** @param {Props['options']} options */
  renderOptions = (options) => {
    return options.map((option, i) => {
      if (typeof option === 'string') {
        return <AntSelect.Option key={option}>{option}</AntSelect.Option>
      }
      const { value, label, disabled, disabledReason, ...restProps } = option

      return (
        <AntSelect.Option key={value} value={value} disabled={disabled} {...restProps}>
          {disabled ? (
            <Tooltip mouseEnterDelay={0} mouseLeaveDelay={0} overlay={disabledReason}>
              <span style={{ display: 'block', width: '100%' }}>{label}</span>
            </Tooltip>
          ) : (
            <span>{label}</span>
          )}
        </AntSelect.Option>
      )
    })
  }

  handleSelect = (value, option) => {
    const { onSelect } = this.props
    if (onSelect) onSelect(value, option)
    this.setState({ value })
  }

  render() {
    const {
      children,
      className,
      disabled = false,
      defaultValue,
      label,
      large,
      margin = '',
      onSelect,
      options,
      style,
      small = true,
      width,
      wrap = true,
      ...restProps
    } = this.props

    const value = (() => {
      const _defaultValue = this.props.value || defaultValue
      if (!options) return _defaultValue
      if (Array.isArray(this.state.value)) return this.state.value
      if (options.find((f) => f.value === this.state.value)) return this.state.value
      return this.props.value || undefined
    })()

    const classNames = cn(
      {
        small,
        disabled,
        'has-label': Boolean(label),
      },
      className
    )
    const dropdownClassNames = cn('tm-select__dropdown', {
      small: small && !large,
      large: !small && large,
      medium: !(small || large),
    })

    const inner = (
      <AntSelect
        {...restProps}
        className={classNames}
        disabled={disabled}
        dropdownClassName={dropdownClassNames}
        onSelect={this.handleSelect}
        style={{ ...style, width: width || '100%' }}
        value={value}
        dropdownRender={(menu) => <div style={{ minWidth: width || '250px' }}>{menu}</div>}
      >
        {options ? this.renderOptions(options) : children}
      </AntSelect>
    )

    if (wrap) {
      return (
        <div className="tm-select" style={{ margin }}>
          {label ? <div className="tm-select__label">{label}</div> : null}
          {inner}
        </div>
      )
    }

    return inner
  }
}

Select.Option = AntSelect.Option

export default Select
