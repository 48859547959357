/**
 * @typedef ChainPart
 * @property {string} title
 * @property {string} dataIndex
 * @property {string} key
 */

/** @type {ChainPart[]} */
export const partsTableConfig = [
  {
    title: 'Part Number',
    dataIndex: 'partnumber',
    key: 'partnumber',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Unit Weight',
    dataIndex: 'unitweight',
    key: 'unitweight',
  },
  {
    title: 'Unit Labor',
    dataIndex: 'unitlinklabor',
    key: 'unitlinklabor',
  },
  {
    title: 'Total Weight',
    dataIndex: 'totalweight',
    key: 'totalweight',
  },
  {
    title: 'Total Labor',
    dataIndex: 'totallinklabor',
    key: 'totallinklabor',
  },
]
