import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'reduxStore'
import { selectSections } from 'shared/redux/ListSelectors'
import { DriveTypeTitle } from '../types/types'

type TypeKey = 'infeed' | 'discharge'

export const selectHasLPIdler = createSelector(
  selectSections,
  (state: RootState, typeKey: TypeKey) => typeKey,
  (state: RootState, typeKey: TypeKey, feedidlertypeid: number) => feedidlertypeid,
  (sections, typeKey, feedidlertypeid) =>
    sections[typeKey]?.idler?.[feedidlertypeid]?.textvalue === 'LP'
)

export const selectDriveTypeTitle = createSelector(
  selectSections,
  (state: RootState, typeKey: TypeKey) => typeKey,
  (state: RootState, typeKey: TypeKey, feedtypeid: number) => feedtypeid,
  (sections, typeKey, feedtypeid) => sections[typeKey]?.types[feedtypeid].title as DriveTypeTitle
)

type SelectIsFeedDriveRHSArgs = [
  state: RootState,
  typeKey: TypeKey,
  feeddriveorientationid: number,
  driveTypeTitle: DriveTypeTitle
]
export const selectIsFeedDriveRHS = createSelector(
  selectSections,
  (...args: SelectIsFeedDriveRHSArgs) => args[1],
  (...args: SelectIsFeedDriveRHSArgs) => args[2],
  (...args: SelectIsFeedDriveRHSArgs) => args[3],
  (sections, typeKey, feeddriveorientationid, driveTypeTitle) =>
    driveTypeTitle !== 'Drive'
      ? null
      : sections[typeKey].drive[feeddriveorientationid]?.title?.includes?.('Right')
)

export const selectAllConveyors = (state: RootState) => state.ConveyorReducer.conveyors
