import React, { ReactElement } from 'react'
import './ProjectNumberPrefixScreen.scss'
import PrefixesForm from 'features/Project/components/Prefixes/components/PrefixesForm'
import usePrefixes from 'features/Project/components/Prefixes/hooks/usePrefixes'

export default function ProjectNumberPrefixScreen(): ReactElement {
  const { loading, prefixes, handleSave } = usePrefixes()

  return (
    <main id="pnp" className="pnp">
      <section className="pnp__title">
        <h1>Project settings</h1>
      </section>
      <section className="pnp__body">
        <PrefixesForm values={prefixes} loading={loading} handleSave={handleSave} />
      </section>
    </main>
  )
}
