import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import { updateUnderguard } from '../redux/default/UnderguardActions'
import { captureSentryError } from 'utils/helpers'

class Underguard extends Component {
  render() {
    if (this.props.loading) {
      return null
    }

    const {
      underguardData: { parameteractive },
      underguardMeta: { open },
      updateUnderguard,
      conveyorId,
    } = this.props
    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="accessories"
          fieldKey="underguard"
          checked={parameteractive}
          noExpandAllowed
          onClickCheck={() => {
            updateUnderguard({
              conveyorId,
              updatedUnderguardFields: { parameteractive: !parameteractive },
            })
          }}
          open={open}
          onClickOpen={() => {}}
          title="Underguard"
        />
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer } = state
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props

    const underguardData =
      EstimatorReducer[conveyorId] &&
      EstimatorReducer[conveyorId].accessories &&
      EstimatorReducer[conveyorId].accessories.underguard
    const underguardMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.underguard

    if (!underguardData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      underguardMeta,
      underguardData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateUnderguard,
  })(Underguard)
)
