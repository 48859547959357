import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Input } from 'shared/components'
import { login } from 'features/User/redux/UserActions'
import './LoginScreen.scss'
import { setLoading, closeConfirmModal } from 'shared/redux/ScreenActions'
import { logout } from 'features/User/redux/UserActions'

function LoginScreen() {
  const dispatch = useDispatch()
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      dispatch(logout())
    }
    dispatch(closeConfirmModal())
    dispatch(setLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleLogin(e) {
    e.preventDefault()
    if (password !== '' && username !== '') {
      dispatch(login({ username, password }))
    }
  }

  return (
    <div className="login-container">
      <div className="login-left">
        <img
          className="login-left__background"
          alt="Artistic blueprint designs of spantech's conveyors and parts"
          src={`${process.env.PUBLIC_URL}/img/login_background.jpg`}
        />
        <img
          className="login-left__logo"
          alt="Logo. Spantech. Outlast, Outperform, Outdeliver"
          src={`${process.env.PUBLIC_URL}/img/spantech_logo.png`}
        />
      </div>
      <main className="login-right">
        <div className="login-right__container">
          <img
            className="login-right__logo"
            src={`${process.env.PUBLIC_URL}/img/ez_suite_logo.png`}
            alt="EZ Suite logo showing the words EZ Suite inside a gear"
          />
          <h1 className="login-right__heading">LOG IN</h1>
          <h2 className="login-right__subheading">Outlast. Outperform. Outdeliver.</h2>
          <p>
            Span Tech’s EZ Suite helps you design innovative conveyor solutions for your needs. We
            are revolutionizing the conveyor industry, one link at a time.
          </p>
          <form className="login-right__form" onSubmit={handleLogin}>
            <label htmlFor="username">Username</label>
            <Input
              id="username"
              name="username"
              large
              type="text"
              value={username}
              onChange={(value) => setUsername(value)}
            />
            <label htmlFor="password">Password</label>
            <Input
              id="password"
              name="password"
              large
              type="password"
              value={password}
              onChange={(value) => setPassword(value)}
            />
            <section className="login-right__form-bottom">
              <Button
                disabled={[username, password].some((v) => !v)}
                text="LOG IN"
                aria-label="Click to submit the form and sign in"
                type="submit"
                className="login-right__submit"
              />
              {/* <a className="login-link" href="#">
                Forgot your username or password?
              </a> */}
            </section>
          </form>

          {/* <section>
            <h3 className="login-right__subsub">Not Registered?</h3>
            <a className="login-link" href="#">
              Request access to get started
            </a>
          </section> */}
        </div>
      </main>
    </div>
  )
}

export default LoginScreen
