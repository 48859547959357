import * as React from 'react'
import { Tooltip, Switch, Icon as AntIcon } from 'antd'
import { Button, Input } from 'shared/components'
import {
  getABCSorter,
  getNumSorter,
  getPartNumber,
  getLocaleDateString,
  getDiff,
  getDiffValue,
} from '.'
import MonetaryInput from 'shared/components/MonetaryInput'
import MonetaryText from 'shared/components/MonetaryText'
import cn from 'classnames'
import ALIGN from 'shared/constants/tableAlign'
import { ALL_COST_IMPORT_ROWS } from '../constants'

/**
 * @typedef {keyof typeof import('shared/constants/screenStates').default} ScreenState
 * @typedef {import('screens/CostImportScreen').SytelineResponsePlus} SytelineResponsePlus
 * @typedef {import('antd/lib/table').ColumnProps<SytelineResponsePlus>} TableColumn
 * @typedef {TableColumn[]} TableColumns
 * @typedef {import('../components/CostImportTable').Props} Props
 *
 * @typedef {{
 *   currenttotalcost: TableColumn,
 *   totalcost: TableColumn,
 *   lock: TableColumn,
 *   calc: TableColumn,
 *   partnumber: TableColumn,
 *   unit: TableColumn,
 *   source: TableColumn,
 *   currentcost: TableColumn,
 *   updatedcost: TableColumn,
 *   freightcost: TableColumn,
 *   dutycost: TableColumn,
 *   currentfreightcost: TableColumn,
 *   currentdutycost: TableColumn,
 *   diff: TableColumn,
 *   lastupdated: TableColumn,
 * }} Columns
 */

const SWITCH_ID = 'lockswitch'

function renderMonetaryText(text) {
  return <MonetaryText text={text} />
}
function createRenderMonetaryInput(key, updateCost) {
  return function renderMonetaryInput(_, record) {
    return (
      <MonetaryInput
        excluded={record.excluded}
        id={record.id}
        defaultValue={record[key]}
        handleChange={(value) => updateCost(record.id, value, key)}
      />
    )
  }
}

/**
 * @param {Props['screenState']} [screenState]
 * @param {Props['updateCost']} [updateCost]
 * @param {function} [handleSearch]
 * @param {function} [handleReset]
 * @param {function} [handleCalculate]
 * @param {Array<number>} [editedIds]
 * @param {boolean} [areAllRowsLocked]
 * @returns {TableColumns}
 */
export default function getColumns(
  screenState,
  updateCost,
  handleSearch,
  handleReset,
  handleCalculate,
  editedIds,
  areAllRowsLocked
) {
  const isEditing = ['EDITING', 'SAVING'].includes(screenState)

  /** @type {Columns} */
  const column = {
    currenttotalcost: {
      dataIndex: isEditing ? 'currenttotalcost' : 'totalcost',
      title: 'Total',
      width: 120,
      align: ALIGN.right,
      sorter: getNumSorter('currenttotalcost'),
      render: function renderTotal(text) {
        return <MonetaryText text={text} />
      },
    },
    totalcost: {
      dataIndex: 'totalcost',
      title: 'New Total',
      width: 130,
      align: ALIGN.right,
      sorter: getNumSorter('totalcost'),
      render: function renderTotal(_, record) {
        return (
          <MonetaryText
            text={(record.updatedcost + record.dutycost + record.freightcost).toString()}
          />
        )
      },
    },
    lock: {
      key: 'lock',
      dataIndex: 'excluded',
      title: function renderAllLock(params) {
        return (
          <AntIcon
            type={areAllRowsLocked ? 'lock' : 'unlock'}
            className={cn('cost-import-icon', {
              'cost-import-icon--lock': areAllRowsLocked,
              'cost-import-icon--disabled': !isEditing,
            })}
            title='Lock all rows'
            aria-label='Lock all rows'
            onClick={() => {
              if (isEditing) updateCost(ALL_COST_IMPORT_ROWS, !areAllRowsLocked, 'excluded')
            }}
            role="button"
          />
        )
      },
      width: 75,
      filterDropdown: function renderFilterDropdown({ setSelectedKeys, selectedKeys, confirm }) {
        return (
          <div className="table-filter-box table-filter-box--130">
            <label className="table-filter-box__label" htmlFor={SWITCH_ID}>
              Show Locked
            </label>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              // @ts-ignore
              id={SWITCH_ID}
              checked={selectedKeys?.length ? selectedKeys[0] === 'Y' : true}
              small
              onChange={(checked) => {
                setSelectedKeys([checked ? 'Y' : 'N'])
                confirm()
              }}
            />
          </div>
        )
      },
      render: function renderLock(excluded, record) {
        return (
          <AntIcon
            type={excluded ? 'lock' : 'unlock'}
            className={cn('cost-import-icon', {
              'cost-import-icon--lock': excluded,
              'cost-import-icon--disabled': !isEditing,
            })}
            title={`Lock row ${getPartNumber(record)}`}
            aria-label={`Lock row ${getPartNumber(record)}`}
            onClick={() => {
              if (isEditing) updateCost(record.id, !excluded, 'excluded')
            }}
            role="button"
          />
        )
      },
      filterMultiple: false,
      onFilter: (showExcluded, record) => {
        const _showExcluded = showExcluded === 'Y'
        if (_showExcluded) return true
        return !record.excluded
      },
    },
    calc: {
      key: 'calculate',
      title: '',
      width: 40,
      render: function renderCalc(_, record) {
        return (
          <AntIcon
            type="calculator"
            className={cn('cost-import-icon', { 'cost-import-icon--disabled': record.excluded })}
            title={`Calculate Freight and Duties for ${getPartNumber(record)}`}
            onClick={() => {
              if (!record.excluded) handleCalculate(record)
            }}
            role="button"
            aria-label={`Calculate Freight and Duties for ${getPartNumber(record)}`}
          />
        )
      },
    },
    partnumber: {
      dataIndex: 'partnumber',
      title: 'Item Number',
      sorter: (a, b) => getPartNumber(a)?.localeCompare?.(getPartNumber(b)),
      width: 200,
      render: function renderItemNumber(_, record) {
        return (
          <Tooltip
            title={record.description}
            placement="rightTop"
            overlayClassName="cost-import-desc-tooltip"
          >
            <span>{getPartNumber(record)}</span>
          </Tooltip>
        )
      },
      filterDropdown: function renderFilterDropdown({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) {
        return (
          <div className="table-filter-box">
            <Input
              className="table-filter-box__search"
              placeholder="Search Item Number"
              value={selectedKeys[0]}
              small
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, 'partnumber')}
            />
            <div className="table-filter-box__actions">
              <Button secondary onClick={() => handleReset(clearFilters)} small text="Reset" />
              <Button
                onClick={() => handleSearch(selectedKeys, confirm, 'partnumber')}
                icon="search"
                small
                text="Search"
              />
            </div>
          </div>
        )
      },
      onFilter: (value, record) => {
        return getPartNumber(record).toString().toLowerCase().includes(value.toLowerCase())
      },
    },
    unit: {
      dataIndex: 'unit',
      title: 'U/M',
      width: 90,
      sorter: getABCSorter('unit'),
    },
    source: {
      dataIndex: 'source',
      title: 'Source',
      width: 110,
      sorter: getABCSorter('source'),
    },
    currentcost: {
      dataIndex: 'currentcost',
      title: 'Material',
      width: 120,
      align: ALIGN.right,
      sorter: getNumSorter('currentcost'),
      render: renderMonetaryText,
    },
    currentfreightcost: {
      dataIndex: isEditing ? 'currentfreightcost' : 'freightcost',
      title: 'Freight',
      width: 120,
      align: ALIGN.right,
      sorter: getNumSorter('currentfreightcost'),
      render: renderMonetaryText,
    },
    currentdutycost: {
      dataIndex: isEditing ? 'currentdutycost' : 'dutycost',
      title: 'Duties',
      width: 120,
      align: ALIGN.right,
      sorter: getNumSorter('currentdutycost'),
      render: renderMonetaryText,
    },
    updatedcost: {
      dataIndex: 'updatedcost',
      title: 'New Material',
      sorter: getNumSorter('updatedcost'),
      align: 'right',
      width: 150,
      render: createRenderMonetaryInput('updatedcost', updateCost),
    },
    freightcost: {
      dataIndex: 'freightcost',
      title: 'New Freight',
      align: 'right',
      width: 150,
      sorter: getNumSorter('freightcost'),
      render: createRenderMonetaryInput('freightcost', updateCost),
    },
    dutycost: {
      dataIndex: 'dutycost',
      title: 'New Duties',
      align: 'right',
      width: 150,
      sorter: getNumSorter('dutycost'),
      render: createRenderMonetaryInput('dutycost', updateCost),
    },
    diff: {
      dataIndex: 'costdiff',
      title: '% Diff',
      width: 120,
      render: (_, record) => getDiff(record, editedIds.includes(record.id)),
      sorter: (a, b) => {
        const [aVal, bVal] = [a, b].map((record) => {
          const {
            updatedcost,
            freightcost,
            dutycost,
            totalcost,
            currentcost,
            sytelinecost,
          } = record
          const _totalcost = totalcost - sytelinecost + currentcost
          const newTotal = updatedcost + freightcost + dutycost
          if (!totalcost && newTotal) return 100
          if ((!totalcost && !newTotal) || _totalcost === newTotal) {
            return -99999999999
          }
          return getDiffValue(newTotal, _totalcost)
        })
        return aVal - bVal
      },
    },
    lastupdated: {
      dataIndex: 'updateddate',
      title: 'Last Updated',
      sorter: getABCSorter('updateddate'),
      width: 150,
      render: (text) => getLocaleDateString(text),
    },
  }

  if (isEditing) {
    return [
      column.lock,
      column.calc,
      column.partnumber,
      column.unit,
      column.source,
      column.currentcost,
      column.updatedcost,
      column.currentfreightcost,
      column.freightcost,
      column.currentdutycost,
      column.dutycost,
      column.currenttotalcost,
      column.totalcost,
      column.diff,
      column.lastupdated,
    ]
  } else {
    return [
      column.lock,
      column.partnumber,
      column.unit,
      column.source,
      column.currentcost,
      column.currentfreightcost,
      column.currentdutycost,
      column.currenttotalcost,
      column.lastupdated,
    ]
  }
}
