import React, { memo } from 'react'
import { typedUseSelector } from 'utils/helpers'
import { SELECTED_COLOR, DEFAULT_COLOR } from '../../constants'
import { IBuilderSectionData } from '../../types/types'
import getVerticalCurveSVGPath from './helpers/getVerticalCurveSVGPath'

interface Props extends Partial<IBuilderSectionData> {
  conveyorId: number;
  color: string;
  width: number;
}

function VerticalCurve(props: Props) {
  const { anchorAngleDeg, anchorPosition, incline, color, id, conveyorId, width } = props
  const selectedSectionId = typedUseSelector(
    (state) => state.EstimatorMetaReducer[conveyorId].conveyorbuilder.selectedSectionId
  )

  return (
    <g
      id={`section-${id}`}
      className="draggable"
      fill={selectedSectionId === id.toString() ? SELECTED_COLOR : DEFAULT_COLOR}
      transform={`translate(${anchorPosition.x} ${anchorPosition.y})`}
    >
      <path
        id={id as string}
        d={getVerticalCurveSVGPath(incline, width)}
        style={{ stroke: color, strokeWidth: '4px' }}
        transform={`rotate(${anchorAngleDeg * -1})`}
      />
    </g>
  )
}

export default memo(VerticalCurve)
