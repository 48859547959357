import React, { Component } from 'react'
export default class InternalAltIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 18 22"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-365.000000, -665.000000)" fill="#173261">
            <g transform="translate(182.000000, 520.000000)">
              <g transform="translate(0.000000, 118.000000)">
                <g transform="translate(180.000000, 24.000000)">
                  <g transform="translate(0.000000, 2.000000)">
                    <path
                      fill={color}
                      d="M6.93011304,8.9705351 C6.58059088,8.62101294 6.58058149,8.05444628 6.93009206,7.70493571 C7.27960263,7.35542514 7.84616929,7.35543453 8.19569144,7.70495669 L11.2926645,10.8019297 L11.2927422,2.76558455 C11.292734,2.27115706 11.6932896,1.8706015 12.1875061,1.87082062 C12.4347199,1.87082472 12.6583108,1.97081077 12.8203102,2.13281018 C12.9820986,2.29459865 13.0822956,2.51840047 13.0822997,2.76561421 L13.082222,10.8019594 L16.1790924,7.70508899 C16.5286029,7.35557842 17.0951696,7.35558781 17.4446917,7.70510997 C17.7942139,8.05463212 17.7942233,8.62119878 17.4447127,8.97070935 L12.1875,14.2279221 L6.93011304,8.9705351 Z M4.83284754,9.92729537 C5.08710293,9.47625566 5.65885757,9.31673046 6.10989727,9.57098585 C6.56093698,9.82524124 6.72046218,10.3969959 6.46620679,10.8480356 C5.9173495,11.8216882 5.625,12.9211492 5.625,14.0652758 C5.625,17.6896445 8.56313133,20.6277758 12.1875,20.6277758 C15.8118687,20.6277758 18.75,17.6896445 18.75,14.0652758 C18.75,12.9212554 18.4577049,11.8218918 17.9089446,10.8483041 C17.6547104,10.3972525 17.8142625,9.82550532 18.2653142,9.57127115 C18.7163659,9.31703699 19.288113,9.47658909 19.5423472,9.92764076 C20.2484783,11.1804293 20.625,12.596581 20.625,14.0652758 C20.625,18.7251784 16.8474026,22.5027758 12.1875,22.5027758 C7.52759742,22.5027758 3.75,18.7251784 3.75,14.0652758 C3.75,12.5964444 4.12659178,11.1801671 4.83284754,9.92729537 Z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
