import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import {
  updateCeilingsupports,
  addCeilingSupport,
  updateCeilingSupport,
  deleteCeilingSupport,
} from '../redux/default/CeilingSupportsActions'
import ceilingsupportsConfig from './CeilingSupportsConfig'
import { Radio, EditableTable, Checkbox } from 'shared/components'
import { updateDefaultMaterialAcessoriesMetaData } from '../../../redux/EstimatorActions'
import { captureSentryError } from 'utils/helpers'

class Ceilingsupports extends Component {
  renderSupportMaterialField = (config) => {
    const { dataKey, prettyName, options, rules } = config.materials
    const {
      updateCeilingsupports,
      conveyorId,
      ceilingsupportsOptions,
      isactive,
      islocked,
    } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '178px', marginRight: '90px' }}>
        <Radio
          key="default-accessories-ceilingsupports-material"
          disabled={!isactive || islocked}
          dataKey={dataKey}
          prettyName={prettyName}
          margin="0 0 10px 0"
          options={options}
          doNotShowImages
          onChange={(val) =>
            updateCeilingsupports({ conveyorId, updatedCeilingsupportsFields: { [dataKey]: val } })
          }
          rules={rules}
          vertical
          initialValue={this.props.ceilingsupportsData.materialid}
        />
        <img
          src={ceilingsupportsOptions.materials[this.props.ceilingsupportsData.materialid].image}
          alt="mounting location"
        />
      </div>
    )
  }

  renderDoubleStandoff = () => {
    const {
      conveyorId,
      ceilingsupportsData: { doublestandoff },
      isactive,
      islocked,
      updateCeilingsupports,
    } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          key="doublestandoff"
          disabled={!isactive || islocked}
          dataKey="doublestandoff"
          label="Double Standoff (All supports per Conveyor)"
          onChange={(val) =>
            updateCeilingsupports({
              conveyorId,
              updatedCeilingsupportsFields: { doublestandoff: val },
            })
          }
          checked={doublestandoff}
        />
      </div>
    )
  }

  render() {
    if (this.props.loading) {
      return null
    }

    const {
      ceilingsupportsData: { parameteractive, details },
      ceilingsupportsMeta: { open },
      conveyorId,
      isactive,
      islocked,
      updateCeilingsupports,
      updateDefaultMaterialAcessoriesMetaData,
    } = this.props

    const config = ceilingsupportsConfig(this.props)

    return (
      <div className="material-accessory-item">
        <DefaultHeader
          noReset
          conveyorId={conveyorId}
          fieldKey="ceilingsupports"
          checked={parameteractive}
          onClickCheck={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'ceilingsupports',
              updatedFields: {
                open: !parameteractive,
              },
            })
            updateCeilingsupports({
              conveyorId,
              updatedCeilingsupportsFields: { parameteractive: !parameteractive },
            })
          }}
          open={open}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'ceilingsupports',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Ceiling Supports"
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            {this.renderSupportMaterialField(config)}
            {this.renderDoubleStandoff()}
          </div>
          <EditableTable
            disabled={!isactive || islocked}
            data={details || []}
            columnConfig={config.ceilingsupportsTable.columns}
            context={React.createContext()}
            onSaveRow={(rowData) => this.props.updateCeilingSupport(conveyorId, rowData)}
            onSaveNewRow={(rowData) => this.props.addCeilingSupport(conveyorId, rowData)}
            onDeleteRowConfirm={(rowData) =>
              this.props.deleteCeilingSupport(conveyorId, rowData.id)
            }
            onSaveValidationError={(errors) => console.warn(errors)}
            onTableSaveError={(error) => {
              throw new Error(error)
            }}
          />
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      ConveyorReducer,
      EstimatorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
    } = state
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props

    const ceilingsupportsData =
      EstimatorReducer[conveyorId] &&
      EstimatorReducer[conveyorId].accessories &&
      EstimatorReducer[conveyorId].accessories.ceilingsupports
    const ceilingsupportsMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.ceilingsupports

    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isMetric = ConveyorReducer.conveyors[conveyorId].unit === 'Metric'

    if (!ceilingsupportsData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      ceilingsupportsMeta,
      ceilingsupportsData,
      ceilingsupportsOptions: ProjectReducer.currentlistsettings.accessories.ceilingsupports,
      isactive,
      islocked,
      isMetric,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateCeilingsupports,
    updateDefaultMaterialAcessoriesMetaData,
    addCeilingSupport,
    updateCeilingSupport,
    deleteCeilingSupport,
  })(Ceilingsupports)
)
