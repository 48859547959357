import React, { PureComponent } from 'react'
import { Tabs, Checkbox, Radio as AntRadio } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SectionOverrideHeader } from '../../../shared/Headers'
import { updateSidewalls } from '../../redux/sectionOverrides/SidewallsActions'
import { Radio, InputWithValidation } from 'shared/components'
import {
  updateSectionOverrideMaterialAcessoriesMetaData,
  addAccessoryWithSidesToConveyorSection,
  updateAccessoryWithSides,
} from '../../../../redux/SectionOverrideActions'
import { fetchDefaultAccessoryForConveyor } from 'features/Estimator/redux/EstimatorActions'
import SideWallsConfig from '../SideWallsConfig'
import _ from 'lodash'
import sidewallsGuiderailsConfig from './sidewallsGuiderailsConfig'
import { captureSentryError } from 'utils/helpers'

const { TabPane } = Tabs

class Sidewalls extends PureComponent {
  renderFields = (sideId, sideName) => {
    const config = SideWallsConfig(this.props, sideId, sideName)
    return _.map(config, (fieldConfig) => {
      const { dataKey, prettyName, options, rules, vertical, type } = fieldConfig
      const { sectionId } = this.props
      const {
        sidewallsData,
        updateSidewalls,
        conveyorId,
        sidewallsOptions: { materials },
      } = this.props
      if (type === 'radio') {
        const { image } = materials[Object.keys(materials)[0]]
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              key="default-materials-wearstrips-lubricated"
              dataKey={dataKey}
              prettyName={prettyName}
              margin="0 0 10px 0"
              options={options}
              onChange={(val) =>
                updateSidewalls({
                  sectionId,
                  conveyorId,
                  sideId,
                  sideName,
                  updatedSidewallsFields: { [dataKey]: val },
                })
              }
              rules={rules}
              vertical={vertical}
              initialValue={_.get(sidewallsData[sideName], dataKey)}
              doNotShowImages
            />
            <img style={{ marginLeft: '40px' }} src={image} alt="mounting location" />
          </div>
        )
      }
      if (type === 'distancetoc') {
        return this.renderSidewallDistanceTOC(sideName, sideId)
      }
      return null
    })
  }

  renderSidewallDistanceTOC = (sideName, sideId) => {
    const { sidewallsData, conveyorId, updateSidewalls, sectionId } = this.props
    return (
      <span>
        <InputWithValidation
          label="Distance TOC to Top of Side Wall"
          placeholder="Enter Distance TOC"
          id="distancetoc"
          name="distancetoc"
          small
          required
          containerStyle={{
            width: '200px',
          }}
          rules={[
            {
              required: true,
              message: 'Distance TOC is required',
            },
            {
              type: 'number',
              transform: (val) => Number(val),
              message: 'Distance TOC must be a number',
            },
          ]}
          type="text"
          defaultValue={sidewallsData[sideName].distancetoc}
          onChange={(value) => {
            updateSidewalls({
              sectionId,
              conveyorId,
              updatedSidewallsFields: { distancetoc: value },
              sideName,
              sideId,
            })
          }}
        />
      </span>
    )
  }

  renderOverrideFields = (showBottom) => {
    const { sidewallsData, updateSidewalls, conveyorId, sectionId, isactive, islocked } = this.props
    const {
      left: { parameteractive: leftChecked, removedforsection: leftRemovedforsection, id: leftId },
      right: {
        parameteractive: rightChecked,
        removedforsection: rightRemovedforsection,
        id: rightId,
      },
    } = sidewallsData
    const disabled = !isactive || islocked

    const config = sidewallsGuiderailsConfig(this.props, 'sidewalls')
    return config.map((fieldConfig) => {
      const { dataKey, prettyName, options, type, value } = fieldConfig

      const key = `default-accessories-guiderails-${dataKey}${this.props.conveyorId}`

      if (type === 'radio') {
        return (
          <div className="builder-tile__expanded__content__wrapper" key={key}>
            <div className="radio-title">{prettyName}</div>
            <AntRadio.Group
              disabled={disabled}
              onChange={(e) => {
                const { value } = e.target
                const sidewallsOnSection = value.toString() === 'true'
                updateSidewalls({
                  sectionId,
                  conveyorId,
                  sideId: leftId,
                  sideName: 'left',
                  updatedSidewallsFields: { removedforsection: !sidewallsOnSection },
                })
                updateSidewalls({
                  sectionId,
                  conveyorId,
                  sideId: rightId,
                  sideName: 'right',
                  updatedSidewallsFields: { removedforsection: !sidewallsOnSection },
                })
              }}
              defaultValue={value}
            >
              {options.map((opt) => (
                <AntRadio value={opt.id} key={opt.id}>
                  {opt.title}
                </AntRadio>
              ))}
            </AntRadio.Group>
          </div>
        )
      }

      if (type === 'checkbox') {
        if (!showBottom) return null

        return (
          <div className="builder-tile__expanded__content__wrapper" key={key}>
            <div className="radio-title">{prettyName}</div>
            {options.map((opt) => {
              const { title, id } = opt
              const isLeft = id === 'L'
              const checked = isLeft
                ? leftChecked && !leftRemovedforsection
                : rightChecked && !rightRemovedforsection

              return (
                <Checkbox
                  disabled={disabled}
                  checked={checked}
                  key={`${key}-${id}`}
                  onChange={(e) => {
                    updateSidewalls({
                      sectionId,
                      conveyorId,
                      sideId: isLeft ? leftId : rightId,
                      sideName: title.toLowerCase(),
                      updatedSidewallsFields: {
                        removedforsection: !e.target.checked,
                        parameteractive: e.target.checked,
                      },
                    })
                  }}
                >
                  {title}
                </Checkbox>
              )
            })}
          </div>
        )
      }
      return null
    })
  }

  render() {
    const {
      sidewallsData,
      sidewallsMeta = { open: false },
      updateAccessoryWithSides,
      conveyorId,
      updateSectionOverrideMaterialAcessoriesMetaData,
      sectionId,
    } = this.props

    const hassidewallsData =
      Boolean(sidewallsData) && Boolean(sidewallsData.left && sidewallsData.right)

    if (!hassidewallsData) {
      return (
        <SectionOverrideHeader
          checked={false}
          open={sidewallsMeta.open}
          onClickCheck={async () => {
            const { sidewallsAccessoriesData } = this.props
            await this.props.addAccessoryWithSidesToConveyorSection({
              sideOptions: this.props.sidewallsOptions.sides,
              sectionId: this.props.sectionId,
              conveyorId: this.props.conveyorId,
              itemName: 'sidewalls',
              defaultData: sidewallsAccessoriesData,
            })
            await updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'accessories',
              name: 'sidewalls',
              updatedFields: {
                open: true,
              },
            })
          }}
          title="Side Walls"
          type="accessories"
          conveyorId={this.props.conveyorId}
          fieldKey="sidewalls"
        />
      )
    }

    const {
      left: {
        parameteractive: leftChecked,
        removedforsection: leftRemovedforsection,
        sectionoverride: leftOverridden,
        id: leftId,
      },
      right: {
        parameteractive: rightChecked,
        removedforsection: rightRemovedforsection,
        sectionoverride: rightOverridden,
        id: rightId,
      },
    } = sidewallsData
    const { open } = sidewallsMeta

    const showLeft = leftChecked && !leftRemovedforsection
    const showRight = rightChecked && !rightRemovedforsection
    const showBottom = showLeft || showRight

    return (
      <div className="material-accessory-item">
        <SectionOverrideHeader
          isLeftRight
          showSingleCheckbox
          checked={leftOverridden || rightOverridden || leftChecked || rightChecked}
          onClickCheck={(e) => {
            const { checked } = e.target
            updateAccessoryWithSides({
              sectionId,
              conveyorId,
              checked,
              materialKey: 'sidewalls',
              updatedFields: { parameteractive: checked },
              sideIds: { left: leftId, right: rightId },
            })
          }}
          open={open}
          onClickOpen={() => {
            updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'accessories',
              name: 'sidewalls',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Side Walls"
          type="accessories"
          conveyorId={conveyorId}
          fieldKey="sidewalls"
        >
          {this.renderOverrideFields(showBottom)}
          {showBottom ? (
            <Tabs onChange={() => {}} type="card">
              {showLeft ? (
                <TabPane style={{ margin: '0px 20px' }} tab="Left" key={`sidetab-${leftId}`}>
                  {this.renderFields(leftId, 'left')}
                </TabPane>
              ) : null}
              {showRight ? (
                <TabPane style={{ margin: '0px 20px' }} tab="Right" key={`sidetab-${rightId}`}>
                  {this.renderFields(rightId, 'right')}
                </TabPane>
              ) : null}
            </Tabs>
          ) : null}
        </SectionOverrideHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, sectionId, match } = props
    const {
      params: { versionId },
    } = match

    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const sidewallsData = EstimatorReducer?.sections?.[sectionId]?.accessories?.sidewalls
    const sidewallsAccessoriesData = EstimatorReducer?.[conveyorId]?.accessories?.sidewalls
    const sidewallsMeta = sectionMetadata?.materialsAccessoriesMetadata?.accessories?.sidewalls || {
      open: false,
    }

    const { isactive, islocked } = ProjectReducer.versions[versionId]

    if (!sidewallsData) {
      return {
        loading: true,
        sidewallsOptions: ProjectReducer.currentlistsettings.accessories.sidewalls,
        sidewallsAccessoriesData,
      }
    }

    return {
      versionId,
      sectionId,
      conveyorId,
      sidewallsMeta,
      sidewallsData,
      sidewallsAccessoriesData,
      sidewallsOptions: ProjectReducer.currentlistsettings.accessories.sidewalls,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateSidewalls,
    updateSectionOverrideMaterialAcessoriesMetaData,
    addAccessoryWithSidesToConveyorSection,
    fetchDefaultAccessoryForConveyor,
    updateAccessoryWithSides,
  })(Sidewalls)
)
