import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

export default function useTableScrollY(screenState) {
  const [scrollY, setScrollY] = useState(window.innerHeight - 301)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(setTBodySizeBaseOnSurroundings)
  }, [screenState])

  function setTBodySizeBaseOnSurroundings() {
    const midEl = document.querySelector<HTMLElement>('.cost-import__mid')
    if (!midEl) return
    setScrollY(window.innerHeight - midEl.offsetTop - 101)
  }

  const handleResize = debounce(setTBodySizeBaseOnSurroundings, 100)

  return scrollY
}
