import http from 'utils/api/Client'
import { generateHTMLString } from './PDFModel'
import moment from 'moment'

const getFileName = (projectId, versionId) =>
  `${moment().format('MMDDYYYYhhmmss')}_${projectId}_${versionId}HorizontalBend.pdf`

const PDFService = {
  async bendConveyor(conveyorId, projectId, versionId) {
    try {
      const response = await http.get(`/Conveyors/${conveyorId}/HorizontalBendDetails`)
      const payload = generateHTMLString(response.data)
      const filename = getFileName(projectId, versionId)
      return this.getTestPDF({ html: payload, orientation: 'landscape' }, filename)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async bendProject(versionId, projectId) {
      const response = await http.get(`/Versions/${versionId}/HorizontalBendDetails`)
      const payload = generateHTMLString(response.data?.[0] || [])
      const filename = getFileName(projectId, versionId)
      return this.getTestPDF({ html: payload, orientation: 'landscape' }, filename)
  },
  async getTestPDF(payload, filename) {
    return new Promise((resolve, reject) => {
      const baseURL = (() => {
        switch (process.env.NODE_ENV) {
          case 'production':
            return process.env.REACT_APP_PDF_BASE
          case 'test':
            return `${process.env.REACT_APP_PDF_BASE}/dev`
          default:
            return '/dev'
        }
      })()
      return http({
        baseURL,
        url: '/pdfGeneration',
        method: 'POST',
        data: payload,
        responseType: 'blob',
      })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' })
          )
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', filename)
          link.click()
          resolve()
        })
        .catch((e) => {
          console.log(e)
          reject(e)
        })
    })
  },
}

export default PDFService
