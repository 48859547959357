import React, { Component } from 'react'
import { Icon, Spin } from 'antd'
import Colors from 'shared/constants/colors'
import './LoadingSpinner.scss'

const spinGear = <Icon type="setting" style={{ fontSize: 80, color: Colors.silver }} spin />

export default class LoadingSpinner extends Component {
  render() {
    const { loadingMessage } = this.props
    return (
      <div id="loading-spinner">
        <Spin indicator={spinGear} />
        <div className="loading-message">{loadingMessage ? loadingMessage : ''}</div>
      </div>
    )
  }
}
