import { Store } from 'srcReducer'
import * as Sentry from '@sentry/browser'
import { ScopeContext } from '@sentry/types'

export default function captureSentryError(error: Error, state: Store) {
  const contexts: ScopeContext['contexts'] = {}

  if (state) {
    contexts.state = {
      type: 'Redux',
      value: {
        AdminStatsReducer: state.AdminStatsReducer,
        ChainReducer: state.ChainReducer,
        ConveyorReducer: state.ConveyorReducer,
        ConveyorMetaReducer: state.ConveyorMetaReducer,
        EstimatorMetaReducer: state.EstimatorMetaReducer,
        ScreenReducer: state.ScreenReducer,
        UserReducer: state.UserReducer,
        ProductReducer: state.ProductReducer,
        EstimatorReducer: state.EstimatorReducer,
        VersionReducer: state.VersionReducer,
        ProjectReducer: {
          ...state.ProjectReducer,
          projectQueueResults: {},
        },
      },
    }
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error, { contexts })
  } else {
    console.log('error caught in mstp', error)
  }
}
