import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AdminDashboardScreen.scss'
import OverviewCard from 'features/Admin/components/OverviewCard'
import { setLoading } from 'shared/redux/ScreenActions'
import { Button } from 'shared/components'
import {
  getStatistics,
  selectChart,
  getProjectsByStatus,
  updateFilter,
} from 'features/Admin/redux/AdminStatsActions'
import StatisticsService from 'utils/api/statistics/StatisticsService'
import StatisticsChart from 'features/Admin/components/StatisticsChart'
import CustomDatePicker from 'features/Admin/components/CustomDatePicker'
import ProjectStatusPieChart from 'features/Admin/components/ProjectStatusPieChart'
import CustomerSelectDropdown from 'features/Admin/components/CustomerSelectDropdown'
import { captureSentryError } from 'utils/helpers'

class AdminDashboardScreen extends Component {
  state = {
    activeIndex: 0,
    loading: false,
    searchcustomerid: null,
    isDownloading: false,
  }

  async componentDidMount() {
    const { admin, history, setLoading } = this.props
    if (!admin) {
      history.push('/')
    }
    await this.updateStatistics()
    setLoading(false)
  }

  onSelectChart = (selectedChart) => {
    const { selectChart } = this.props
    selectChart(selectedChart)
  }

  renderOverviewCards = () => {
    const { periodType, overviewData } = this.props
    return overviewData.map((data, i) => <OverviewCard key={i} data={{ ...data, periodType }} />)
  }

  updateStatistics = async () => {
    const {
      calendarLabel,
      customerId,
      endDate,
      getStatistics,
      getProjectsByStatus,
      startDate,
    } = this.props
    const statistics = await getStatistics([startDate, endDate], calendarLabel, customerId)
    const projectsByStatus = await getProjectsByStatus()
    await Promise.all([statistics, projectsByStatus])
  }

  render() {
    const { history, overviewData } = this.props
    const { isDownloading } = this.state

    return (
      <div id="admin-dashboard">
        <div className="admin-dashboard__header">
          <div className="admin-dashboard__header__title">Overview</div>
          <div className="admin-dashboard__header__dropdowns">
            <Button
              text="Export all Projects"
              small
              disabled={isDownloading}
              antIcon={isDownloading ? 'loading' : 'file-excel'}
              onClick={async () => {
                this.setState({ isDownloading: true })
                await StatisticsService.getSFProjectLog()
                this.setState({ isDownloading: false })
              }}
            />
            <CustomDatePicker />
            <CustomerSelectDropdown onSelectCustomer={() => this.updateStatistics()} />
          </div>
        </div>
        <div className="admin-dashboard__container">
          <div className="admin-dashboard__container__top">
            <div className="overview-cards">{overviewData ? this.renderOverviewCards() : null}</div>
            <div className="active-projects">
              <ProjectStatusPieChart history={history} />
            </div>
          </div>

          <div className="admin-dashboard__container__bottom">
            <div className="graphs-container">
              <div className="total-sold">
                <StatisticsChart onSelectChart={this.onSelectChart} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const {
      calendarLabel,
      endDate,
      filter,
      startDate,
      selectedChart,
      statisticsData,
    } = state.AdminStatsReducer

    return {
      admin: state.UserReducer.roles.includes('Admin'),
      calendarLabel,
      customerId: filter?.type === 'Customer' ? filter?.value : undefined,
      endDate,
      filter,
      startDate,
      selectedChart,
      overviewData: statisticsData.overviewData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  getProjectsByStatus,
  getStatistics,
  selectChart,
  setLoading,
  updateFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardScreen)
