import React, { Component } from 'react'
import cn from 'classnames'
import { Icon } from 'shared/components'
import ProjectNoteForm from './components/ProjectNoteForm'
import ProjectNote from './components/ProjectNote'
import ProjectNotesResizer from './components/ProjectNotesResizer'
import VersionService from 'utils/api/version/VersionService'
import { openConfirmModal, setNotesScreenPreferences } from 'shared/redux/ScreenActions'
import { connect } from 'react-redux'
import { captureSentryError } from 'utils/helpers'

/**
 * @typedef {import('shared/redux/ScreenActions')['openConfirmModal']} openConfirmModal
 * @typedef {import('utils/api/version/VersionService').ProjectNote} Note
 *
 * @typedef {Object} Props
 * @prop {boolean} show
 * @prop {Function} closeNotes
 * @prop {string} versionId
 * @prop {openConfirmModal} openConfirmModal
 * @prop {typeof setNotesScreenPreferences} setNotesScreenPreferences
 * @prop {string} userId
 * @prop {import('shared/redux/ScreenReducer').Preferences} preferences
 */

/** @extends Component<Props> */
class ProjectNotesDisplay extends Component {
  state = {
    animating: false,
    animatingDirection: /** @type {('ine'|'out')} */ 'out',
    notes: /** @type {Object<string, Note>} */ {},
    editing: false,
    editingNoteId: null,
    initStyle: { height: '', width: '' },
  }

  ref = React.createRef()

  componentDidMount() {
    const { preferences } = this.props
    this.fetchNotes()
    if (preferences?.notesHeight && preferences?.notesWidth) {
      this.ref.current.style.height = `${preferences.notesHeight}px`
      this.ref.current.style.width = `${preferences.notesWidth}px`
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      const showing = this.props.show
      setTimeout(() => {
        this.setState({ animating: true, animatingDirection: showing ? 'in' : 'out' }, () => {
          setTimeout(() => {
            this.setState({ animating: false })
          }, 300)
        })
      }, 24)
    }
  }

  fetchNotes = async () => {
    const { versionId } = this.props
    const notes = await VersionService.getVersionNotes(versionId)
    this.setState({ notes })
  }

  handleSave = async (note, value) => {
    const { versionId } = this.props
    let responseNote

    try {
      if (note) {
        responseNote = await VersionService.updateVersionNote(versionId, note.id, value)
      } else {
        responseNote = await VersionService.addVersionNote(versionId, value)
      }
      this.setState(({ notes }) => ({
        notes: { ...notes, [responseNote.id]: responseNote },
        editing: false,
        editingNoteId: null,
      }))

      return true
    } catch (error) {
      console.log(error)

      return false
    }
  }

  handleEdit = (id) => {
    const { notes } = this.state
    if (id in notes) {
      this.setState({ editing: true, editingNoteId: id })
    }
  }

  handleDelete = (id) => {
    const { versionId } = this.props
    this.props.openConfirmModal({
      headerText: 'Delete Note',
      bodyText: 'Are you sure you want to delete this note?',
      // bodyInnerHTML: `<div>
      //   <p class='spantech__confirm-modal__body-text'>Are you sure you want to delete this note?<p>
      //   <pre>${notes[id].note}</pre>
      // <div>`,
      onConfirm: async () => {
        try {
          const success = await VersionService.deleteVersionNote(versionId, id)
          if (success) {
            this.setState(({ notes }) => {
              const newNotes = { ...notes }
              delete newNotes[id]
              return { notes: newNotes }
            })
          }
        } catch (error) {
          console.log(error)
          debugger
        }
      },
    })
  }

  handleCancel = (callback = () => {}) => {
    this.setState({ editingNoteId: null, editing: false }, callback)
  }

  /** @param {Note} note */
  renderNote = (note) => {
    const { userId } = this.props
    const { editingNoteId } = this.state

    return (
      <ProjectNote
        key={note.id}
        editing={editingNoteId === note.id}
        note={note}
        handleDelete={this.handleDelete}
        handleEdit={this.handleEdit}
        canModify={note.userid === userId}
      />
    )
  }

  render() {
    const { animating, animatingDirection, notes, editing, editingNoteId } = this.state
    const { show, closeNotes, setNotesScreenPreferences } = this.props

    const hidden = !show

    return (
      <aside
        aria-hidden={hidden}
        className={cn('project-notes-display', `project-notes-display--${animatingDirection}`, {
          'project-notes-display--anim': animating,
          'project-notes-display--hidden': hidden,
        })}
        ref={this.ref}
      >
        <header className="project-notes-display__header">
          <h3>Project Notes</h3>
          <Icon icon="x" onClick={closeNotes} size={24} colorKey="navy" />
        </header>

        <ProjectNotesResizer
          containerElement={this.ref}
          direction="xy"
          setPrefs={setNotesScreenPreferences}
        />
        {/* <ProjectNotesResizer containerElement={this.ref} direction="x" /> */}
        {/* <ProjectNotesResizer containerElement={this.ref} direction="y" /> */}

        <div className="project-notes-display__divider" />

        <div className="project-notes">
          {Object.values(notes).map(this.renderNote)}

          <div className="project-notes-display__divider" />

          <ProjectNoteForm
            editing={editing}
            handleSave={this.handleSave}
            note={notes?.[editingNoteId]}
            handleCancel={this.handleCancel}
          />
        </div>
      </aside>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      userId: state.UserReducer.userId,
      preferences: state.ScreenReducer.preferences,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default connect(mapStateToProps, { openConfirmModal, setNotesScreenPreferences })(
  ProjectNotesDisplay
)
