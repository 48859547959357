import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import localForage from 'localforage'
import AdminStatsReducer, { AdminStatsState } from './features/Admin/redux/AdminStatsReducer'
import ChainReducer, { IChainState } from './features/Chain/redux/ChainReducer'
import ConveyorReducer, { IConveyorState } from './features/Conveyor/redux/ConveyorReducer'
import ConveyorMetaReducer, {
  IConveyorMetaState,
} from './features/Conveyor/redux/ConveyorMetaReducer'
import EstimatorMetaReducer, {
  EstimatorMetaState,
} from './features/Estimator/redux/EstimatorMetaReducer'
import ListReducer from './shared/redux/ListReducer'
import SettingsReducer from './shared/redux/SettingsReducer'
import ProjectReducer, { IProjectState } from './features/Project/redux/ProjectReducer'
import ScreenReducer from './shared/redux/ScreenReducer'
import UserReducer, { UserState } from './features/User/redux/UserReducer'
import ProductReducer, { ProductState } from './features/Product/redux/ProductReducer'
import EstimatorReducer, { EstimatorState } from './features/Estimator/redux/EstimatorReducer'
import VersionReducer, { VersionState } from './features/Version/redux/VersionReducer'
import LegendReducer from './features/Estimator/components/Legend/redux/LegendReducer'
import { IScreenState } from './shared/redux/ScreenReducer'
import { IListState } from './shared/redux/ListReducer'
import { ISettingsState } from './shared/redux/SettingsReducer'

export interface Store {
  ScreenReducer: IScreenState;
  ListReducer: IListState;
  SettingsReducer: ISettingsState;
  UserReducer: UserState;
  ChainReducer: IChainState;
  ConveyorReducer: IConveyorState;
  ConveyorMetaReducer: IConveyorMetaState;
  ProductReducer: ProductState;
  ProjectReducer: IProjectState;
  AdminStatsReducer: AdminStatsState;
  EstimatorMetaReducer: EstimatorMetaState;
  VersionReducer: VersionState;
  EstimatorReducer: EstimatorState;
}

export const reducers = {
  AdminStatsReducer,
  ChainReducer,
  ConveyorReducer,
  ConveyorMetaReducer,
  EstimatorMetaReducer,
  ListReducer,
  ProjectReducer,
  ScreenReducer,
  UserReducer,
  ProductReducer,
  EstimatorReducer,
  VersionReducer,
  LegendReducer,
  SettingsReducer,
}

const combinedReducers = combineReducers(reducers)

export default combinedReducers
