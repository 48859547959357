/**
 * @typedef {import('shared/types/swagger').IConveyorSection} IConveyorSection
 * @typedef {import('features/Conveyor/shared/types').InfeedWithTransfer} InfeedWithTransfer
 * @typedef {import('features/Conveyor/shared/types').DischargeWithTransfer} DischargeWithTransfer
 *
 * @typedef {Object} CautionsWarnings
 */

/**
 * @param {IConveyorSection | InfeedWithTransfer | DischargeWithTransfer} [item]
 * @returns {CautionsWarnings}
 */
export default function getCautionsWarnings(item) {
    const data = {
        warnings: [],
        cautions: [],
      }
  
      let cautionCount=0
      let warningCount=0
      const { length, ...rest } = item
      Object.values(rest).forEach((value) => {
        if (value === 'Caution' && data.cautions.length == 0) {
          cautionCount++
        }
        if (value === 'Warning' && data.warnings.length == 0) {
          warningCount++
        }
      })

      if (warningCount>0)
      {
        data.warnings.push('TorqueMaster Status')
      } else if (cautionCount> 0)
      {
        data.cautions.push('TorqueMaster Status')
      }

      item.warnings.forEach((warning) => data.warnings.push(warning))

      return data
  }