export default (state) => [
  {
    key: 'username',
    type: 'input',
    cols: 12,
    label: 'Username',
    placeholder: 'Enter Username',
    rules: [
      {
        required: true,
        message: 'Username is required.',
      },
    ],
  },
  {
    cols: 12,
    type: 'radio',
    key: 'projecttype',
    label: 'Allowed project type',
    options: [
      { label: 'Regular', value: 'regular' },
      { label: 'LA Program', value: 'la' },
      { label: 'Both', value: 'all' },
    ],
    value: 'regular',
  },
  {
    key: 'password',
    type: 'input',
    cols: 12,
    label: 'Password',
    placeholder: 'Enter Password to be used',
    rules: [
      {
        required: true,
        validator: (rule, value, cb) => {
          try {
            if (value === '') {
              throw new Error('Please enter the password to be used.')
            } else if (value.length < 4) {
              throw new Error('Password must have at least 4 characters.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
  {
    key: 'password_check',
    type: 'input',
    cols: 12,
    label: 'Re-enter Password',
    placeholder: 'Enter Password to be used',
    rules: [
      {
        required: true,
        validator: (rule, value, cb) => {
          try {
            if (value === '') {
              throw new Error('Please re-enter the password.')
            } else if (value !== state?.newUserInfo?.password) {
              throw new Error('Passwords do not match.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
]
