/**
 * @interface
 */
const FIELD_TYPES = {
  RADIO: 'radio',
  RAILS: 'rails',
  DISTANCEBETWEENRAILS: 'distancebetweenrailsid',
  DISTANCETOC: 'distancetocid',
  ROD: 'rodid',
  DISPLAY: 'display',
  SELECT: 'select'
}

export default FIELD_TYPES