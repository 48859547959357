import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import VersionCard from '../VersionCard'
import { Icon } from 'shared/components'
import './VersionSelect.scss'
import { setModalContent } from 'shared/redux/ScreenActions'
import { getVersions } from 'features/Project/redux/ProjectOperations'
import ProjectService from 'utils/api/project/ProjectService'
import { typedUseSelector } from 'utils/helpers'

function VersionSelect(props) {
  const {  selectedProject, versions } = typedUseSelector(
    (state) => state.ProjectReducer
  )
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      if (selectedProject?.id) {
        await dispatch(getVersions(selectedProject.id))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { roles, userId } = typedUseSelector((state) => state.UserReducer)

  const renderVersionCards = () => {
    return _.map(versions, (version, i) => {
          if (!roles.includes('ExternalUser') || version.userid == userId)
          {
            return <VersionCard key={i} version={version} project={selectedProject} />
          }
          else{
            return null
          }
        })
  }

  return (
    <div className="select-version">
      <div className="select-version__top-container">
        <h1>Select a Version</h1>
        <Icon icon="x" size={30} onClick={() => dispatch(setModalContent(null))} />
      </div>
      {renderVersionCards()}
    </div>
  )
}

export default VersionSelect
