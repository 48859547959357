import React, { Component } from 'react'
export default class StartEstimateIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="0 0 40 36"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-285.000000, -250.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(83.000000, 209.000000)">
              <g transform="translate(184.000000, 20.000000)">
                <g transform="translate(18.000000, 21.000000)">
                  <path d="M39.7802198,27.2967033 L34.2857143,21.4945055 L34.2857143,0.879120879 C34.2857143,0.393595824 33.8921185,0 33.4065934,0 L0.879120879,0 C0.393595824,0 0,0.393595824 0,0.879120879 L0,27.2527473 C0,27.7382723 0.393595824,28.1318681 0.879120879,28.1318681 L26.2417582,28.1318681 L25.978022,32.2637363 C25.9660413,32.6239251 26.1751065,32.9549451 26.5054945,33.0989011 L26.8571429,33.1868132 C27.0890279,33.1835103 27.310215,33.0887158 27.4725275,32.9230769 L31.6043956,28.8351648 L33.8021978,34.8571429 C33.9320609,35.2048108 34.2662651,35.4334769 34.6373626,35.4286488 L34.9450549,35.4286488 C35.168419,35.3494364 35.3503768,35.1834798 35.4496802,34.9683224 C35.5489836,34.753165 35.5572219,34.5070302 35.4725275,34.2857143 L33.2747253,28.2637363 L39.032967,28.7472527 C39.400793,28.7804338 39.7501404,28.5801814 39.9073956,28.2460142 C40.0646507,27.911847 39.996281,27.5150218 39.7362637,27.2527473 L39.7802198,27.2967033 Z M26.6813187,20.6593407 L6.15384615,20.6593407 L6.15384615,18.021978 L26.8571429,18.021978 L26.6813187,20.6593407 Z M1.75824176,26.3736264 L1.75824176,1.75824176 L32.5274725,1.75824176 L32.5274725,19.4725275 L28.5714286,15.2967033 C28.333083,15.0494226 27.9725592,14.9635836 27.6483516,15.0769231 L27.4285714,15.0769231 C27.173728,15.2231686 27.0087947,15.4870619 26.989011,15.7802198 L26.989011,16.2637363 L5.27472527,16.2637363 C4.78920022,16.2637363 4.3956044,16.6573321 4.3956044,17.1428571 L4.3956044,21.5384615 C4.3956044,22.0239866 4.78920022,22.4175824 5.27472527,22.4175824 L26.5934066,22.4175824 L26.5054945,24.1318681 L26.3736264,26.3736264 L1.75824176,26.3736264 Z M32.043956,26.3736264 L31.6483516,26.3736264 L31.5164835,26.4615385 L31.4725275,26.4615385 L31.3406593,26.5494505 L31.3406593,26.5494505 L27.8681319,29.978022 L28.043956,27.2967033 L28.043956,27.2087912 L28.2197802,24.043956 L28.5714286,17.978022 L36.8791209,26.8131868 L32.043956,26.3736264 Z"></path>
                  <path d="M12.967033,7.47252747 L28.8791209,7.47252747 C29.3646459,7.47252747 29.7582418,7.07893165 29.7582418,6.59340659 C29.7582418,6.10788154 29.3646459,5.71428571 28.8791209,5.71428571 L12.967033,5.71428571 C12.4815079,5.71428571 12.0879121,6.10788154 12.0879121,6.59340659 C12.0879121,7.07893165 12.4815079,7.47252747 12.967033,7.47252747 Z"></path>
                  <path d="M5.58241758,12.7472527 L9.97802198,12.7472527 C10.463547,12.7472527 10.8571429,12.3536569 10.8571429,11.8681319 C10.8571429,11.3826068 10.463547,10.989011 9.97802198,10.989011 L5.58241758,10.989011 C5.09689253,10.989011 4.7032967,11.3826068 4.7032967,11.8681319 C4.7032967,12.3536569 5.09689253,12.7472527 5.58241758,12.7472527 L5.58241758,12.7472527 Z"></path>
                  <path d="M5.58241758,7.47252747 L9.97802198,7.47252747 C10.463547,7.47252747 10.8571429,7.07893165 10.8571429,6.59340659 C10.8571429,6.10788154 10.463547,5.71428571 9.97802198,5.71428571 L5.58241758,5.71428571 C5.09689253,5.71428571 4.7032967,6.10788154 4.7032967,6.59340659 C4.7032967,7.07893165 5.09689253,7.47252747 5.58241758,7.47252747 L5.58241758,7.47252747 Z"></path>
                  <path d="M12.967033,12.7472527 L28.8791209,12.7472527 C29.3646459,12.7472527 29.7582418,12.3536569 29.7582418,11.8681319 C29.7582418,11.3826068 29.3646459,10.989011 28.8791209,10.989011 L12.967033,10.989011 C12.4815079,10.989011 12.0879121,11.3826068 12.0879121,11.8681319 C12.0879121,12.3536569 12.4815079,12.7472527 12.967033,12.7472527 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
