import * as AntIcons from '@ant-design/icons'

/**
 * @typedef {(
 *   'account-book' |
 *   'alipay-circle' |
 *   'alipay-square' |
 *   'aliwangwang' |
 *   'alert' |
 *   'android' |
 *   'amazon-circle' |
 *   'api' |
 *   'appstore' |
 *   'amazon-square' |
 *   'audio' |
 *   'apple' |
 *   'backward' |
 *   'bank' |
 *   'bell' |
 *   'behance-circle' |
 *   'book' |
 *   'behance-square' |
 *   'box-plot' |
 *   'bug' |
 *   'build' |
 *   'calculator' |
 *   'bulb' |
 *   'calendar' |
 *   'car' |
 *   'caret-down' |
 *   'caret-left' |
 *   'carry-out' |
 *   'caret-right' |
 *   'caret-up' |
 *   'check-square' |
 *   'check-circle' |
 *   'chrome' |
 *   'clock-circle' |
 *   'ci-circle' |
 *   'close-square' |
 *   'close-circle' |
 *   'cloud' |
 *   'code-sandbox-square' |
 *   'code' |
 *   'camera' |
 *   'codepen-circle' |
 *   'code-sandbox-circle' |
 *   'compass' |
 *   'contacts' |
 *   'container' |
 *   'copy' |
 *   'copyright-circle' |
 *   'credit-card' |
 *   'crown' |
 *   'codepen-square' |
 *   'customer-service' |
 *   'dashboard' |
 *   'diff' |
 *   'delete' |
 *   'dingtalk-circle' |
 *   'database' |
 *   'dingtalk-square' |
 *   'dislike' |
 *   'dollar-circle' |
 *   'control' |
 *   'down-circle' |
 *   'down-square' |
 *   'dribbble-square' |
 *   'dribbble-circle' |
 *   'environment' |
 *   'dropbox-circle' |
 *   'exclamation-circle' |
 *   'euro-circle' |
 *   'experiment' |
 *   'eye-invisible' |
 *   'dropbox-square' |
 *   'eye' |
 *   'facebook' |
 *   'edit' |
 *   'fast-backward' |
 *   'fast-forward' |
 *   'file-excel' |
 *   'file-add' |
 *   'file-image' |
 *   'file-markdown' |
 *   'file-exclamation' |
 *   'file-ppt' |
 *   'file-unknown' |
 *   'file-zip' |
 *   'file-pdf' |
 *   'file-word' |
 *   'file' |
 *   'fire' |
 *   'flag' |
 *   'folder-add' |
 *   'folder' |
 *   'folder-open' |
 *   'filter' |
 *   'forward' |
 *   'frown' |
 *   'file-text' |
 *   'funnel-plot' |
 *   'gift' |
 *   'github' |
 *   'golden' |
 *   'google-plus-circle' |
 *   'google-circle' |
 *   'google-plus-square' |
 *   'gitlab' |
 *   'google-square' |
 *   'hdd' |
 *   'fund' |
 *   'highlight' |
 *   'home' |
 *   'hourglass' |
 *   'html5' |
 *   'idcard' |
 *   'ie-square' |
 *   'heart' |
 *   'info-circle' |
 *   'ie-circle' |
 *   'insurance' |
 *   'interaction' |
 *   'interation' |
 *   'layout' |
 *   'left-circle' |
 *   'left-square' |
 *   'like' |
 *   'lock' |
 *   'linkedin' |
 *   'mail' |
 *   'medicine-box' |
 *   'medium-square' |
 *   'meh' |
 *   'message' |
 *   'minus-circle' |
 *   'minus-square' |
 *   'mobile' |
 *   'medium-circle' |
 *   'money-collect' |
 *   'pay-circle' |
 *   'pause-circle' |
 *   'notification' |
 *   'instagram' |
 *   'phone' |
 *   'picture' |
 *   'pie-chart' |
 *   'play-square' |
 *   'play-circle' |
 *   'pound-circle' |
 *   'printer' |
 *   'plus-circle' |
 *   'profile' |
 *   'project' |
 *   'pushpin' |
 *   'property-safety' |
 *   'qq-circle' |
 *   'plus-square' |
 *   'qq-square' |
 *   'question-circle' |
 *   'reconciliation' |
 *   'red-envelope' |
 *   'reddit-circle' |
 *   'reddit-square' |
 *   'right-circle' |
 *   'rest' |
 *   'read' |
 *   'rocket' |
 *   'safety-certificate' |
 *   'schedule' |
 *   'right-square' |
 *   'security-scan' |
 *   'setting' |
 *   'shop' |
 *   'sketch-circle' |
 *   'skin' |
 *   'slack-circle' |
 *   'sketch-square' |
 *   'shopping' |
 *   'skype' |
 *   'slack-square' |
 *   'smile' |
 *   'sliders' |
 *   'save' |
 *   'snippets' |
 *   'sound' |
 *   'star' |
 *   'step-forward' |
 *   'stop' |
 *   'step-backward' |
 *   'tags' |
 *   'switcher' |
 *   'taobao-circle' |
 *   'tablet' |
 *   'thunderbolt' |
 *   'trademark-circle' |
 *   'taobao-square' |
 *   'tool' |
 *   'trophy' |
 *   'twitter-circle' |
 *   'twitter-square' |
 *   'unlock' |
 *   'up-square' |
 *   'usb' |
 *   'video-camera' |
 *   'warning' |
 *   'up-circle' |
 *   'wechat' |
 *   'weibo-circle' |
 *   'windows' |
 *   'wallet' |
 *   'weibo-square' |
 *   'tag' |
 *   'youtube' |
 *   'zhihu-square' |
 *   'yuque' |
 *   'zhihu-circle' |
 *   'account-book' |
 *   'alert' |
 *   'yahoo' |
 *   'alipay-circle' |
 *   'aliwangwang' |
 *   'android' |
 *   'api' |
 *   'audio' |
 *   'appstore' |
 *   'apple' |
 *   'backward' |
 *   'bank' |
 *   'bell' |
 *   'book' |
 *   'behance-square' |
 *   'box-plot' |
 *   'bulb' |
 *   'calculator' |
 *   'calendar' |
 *   'camera' |
 *   'car' |
 *   'caret-down' |
 *   'build' |
 *   'bug' |
 *   'caret-right' |
 *   'caret-left' |
 *   'carry-out' |
 *   'check-square' |
 *   'check-circle' |
 *   'chrome' |
 *   'cloud' |
 *   'close-circle' |
 *   'caret-up' |
 *   'close-square' |
 *   'code' |
 *   'container' |
 *   'clock-circle' |
 *   'control' |
 *   'copy' |
 *   'compass' |
 *   'crown' |
 *   'credit-card' |
 *   'customer-service' |
 *   'dashboard' |
 *   'diff' |
 *   'delete' |
 *   'contacts' |
 *   'database' |
 *   'dislike' |
 *   'down-circle' |
 *   'down-square' |
 *   'edit' |
 *   'dribbble-square' |
 *   'experiment' |
 *   'exclamation-circle' |
 *   'codepen-circle' |
 *   'environment' |
 *   'facebook' |
 *   'fast-backward' |
 *   'eye-invisible' |
 *   'fast-forward' |
 *   'file-add' |
 *   'eye' |
 *   'file-image' |
 *   'file-exclamation' |
 *   'file-excel' |
 *   'file-pdf' |
 *   'file-text' |
 *   'file-ppt' |
 *   'file-word' |
 *   'file-unknown' |
 *   'file-zip' |
 *   'file' |
 *   'file-markdown' |
 *   'filter' |
 *   'fire' |
 *   'flag' |
 *   'folder-add' |
 *   'folder' |
 *   'frown' |
 *   'forward' |
 *   'folder-open' |
 *   'fund' |
 *   'gift' |
 *   'funnel-plot' |
 *   'github' |
 *   'gitlab' |
 *   'hdd' |
 *   'heart' |
 *   'hourglass' |
 *   'highlight' |
 *   'idcard' |
 *   'info-circle' |
 *   'instagram' |
 *   'html5' |
 *   'home' |
 *   'interaction' |
 *   'interation' |
 *   'layout' |
 *   'left-circle' |
 *   'left-square' |
 *   'linkedin' |
 *   'like' |
 *   'lock' |
 *   'medicine-box' |
 *   'mail' |
 *   'meh' |
 *   'message' |
 *   'minus-circle' |
 *   'insurance' |
 *   'minus-square' |
 *   'mobile' |
 *   'pay-circle' |
 *   'pause-circle' |
 *   'notification' |
 *   'phone' |
 *   'picture' |
 *   'play-square' |
 *   'money-collect' |
 *   'plus-circle' |
 *   'play-circle' |
 *   'profile' |
 *   'pie-chart' |
 *   'project' |
 *   'plus-square' |
 *   'printer' |
 *   'pushpin' |
 *   'property-safety' |
 *   'reconciliation' |
 *   'rest' |
 *   'read' |
 *   'rocket' |
 *   'right-square' |
 *   'right-circle' |
 *   'safety-certificate' |
 *   'red-envelope' |
 *   'save' |
 *   'schedule' |
 *   'security-scan' |
 *   'setting' |
 *   'shop' |
 *   'shopping' |
 *   'skin' |
 *   'skype' |
 *   'slack-square' |
 *   'sliders' |
 *   'snippets' |
 *   'question-circle' |
 *   'smile' |
 *   'sound' |
 *   'step-backward' |
 *   'stop' |
 *   'switcher' |
 *   'star' |
 *   'tablet' |
 *   'step-forward' |
 *   'taobao-circle' |
 *   'tags' |
 *   'tool' |
 *   'thunderbolt' |
 *   'trophy' |
 *   'unlock' |
 *   'tag' |
 *   'usb' |
 *   'up-square' |
 *   'video-camera' |
 *   'up-circle' |
 *   'wechat' |
 *   'wallet' |
 *   'yahoo' |
 *   'warning' |
 *   'windows' |
 *   'yuque' |
 *   'youtube' |
 *   'align-center' |
 *   'align-left' |
 *   'align-right' |
 *   'alipay' |
 *   'alibaba' |
 *   'aliyun' |
 *   'ant-cloud' |
 *   'amazon' |
 *   'weibo-square' |
 *   'apartment' |
 *   'area-chart' |
 *   'arrow-down' |
 *   'arrow-left' |
 *   'arrow-up' |
 *   'arrow-right' |
 *   'ant-design' |
 *   'audit' |
 *   'bar-chart' |
 *   'bars' |
 *   'barcode' |
 *   'behance' |
 *   'bg-colors' |
 *   'weibo-circle' |
 *   'block' |
 *   'bold' |
 *   'border-left' |
 *   'border-outer' |
 *   'arrows-alt' |
 *   'border-bottom' |
 *   'border-inner' |
 *   'border-right' |
 *   'border-horizontal' |
 *   'border-top' |
 *   'border-verticle' |
 *   'branches' |
 *   'ci' |
 *   'close' |
 *   'cloud-sync' |
 *   'cloud-download' |
 *   'cloud-server' |
 *   'cloud-upload' |
 *   'cluster' |
 *   'check' |
 *   'codepen' |
 *   'code-sandbox' |
 *   'colum-height' |
 *   'column-width' |
 *   'border' |
 *   'coffee' |
 *   'column-height' |
 *   'copyright' |
 *   'dash' |
 *   'desktop' |
 *   'dingding' |
 *   'deployment-unit' |
 *   'disconnect' |
 *   'double-left' |
 *   'dot-chart' |
 *   'double-right' |
 *   'down' |
 *   'dollar' |
 *   'download' |
 *   'dribbble' |
 *   'ellipsis' |
 *   'enter' |
 *   'dropbox' |
 *   'euro' |
 *   'exception' |
 *   'drag' |
 *   'exclamation' |
 *   'export' |
 *   'file-done' |
 *   'fall' |
 *   'file-protect' |
 *   'file-sync' |
 *   'file-search' |
 *   'font-size' |
 *   'fork' |
 *   'file-jpg' |
 *   'fullscreen-exit' |
 *   'font-colors' |
 *   'fullscreen' |
 *   'gateway' |
 *   'gold' |
 *   'google' |
 *   'heat-map' |
 *   'google-plus' |
 *   'history' |
 *   'ie' |
 *   'import' |
 *   'inbox' |
 *   'info' |
 *   'form' |
 *   'global' |
 *   'key' |
 *   'italic' |
 *   'left' |
 *   'laptop' |
 *   'link' |
 *   'line-height' |
 *   'line-chart' |
 *   'loading' |
 *   'login' |
 *   'logout' |
 *   'loading-3-quarters' |
 *   'medium' |
 *   'medium-workmark' |
 *   'man' |
 *   'menu-unfold' |
 *   'menu-fold' |
 *   'menu' |
 *   'minus' |
 *   'monitor' |
 *   'number' |
 *   'ordered-list' |
 *   'more' |
 *   'pause' |
 *   'percentage' |
 *   'paper-clip' |
 *   'line' |
 *   'pic-left' |
 *   'pic-right' |
 *   'plus' |
 *   'issues-close' |
 *   'pound' |
 *   'pull-request' |
 *   'radar-chart' |
 *   'qq' |
 *   'poweroff' |
 *   'question' |
 *   'radius-bottomleft' |
 *   'radius-bottomright' |
 *   'radius-upleft' |
 *   'radius-setting' |
 *   'radius-upright' |
 *   'qrcode' |
 *   'reddit' |
 *   'redo' |
 *   'reload' |
 *   'retweet' |
 *   'pic-center' |
 *   'rise' |
 *   'rollback' |
 *   'right' |
 *   'robot' |
 *   'safety' |
 *   'search' |
 *   'scissor' |
 *   'share-alt' |
 *   'scan' |
 *   'shopping-cart' |
 *   'select' |
 *   'shake' |
 *   'sketch' |
 *   'shrink' |
 *   'slack' |
 *   'small-dash' |
 *   'solution' |
 *   'sort-descending' |
 *   'swap-left' |
 *   'swap-right' |
 *   'strikethrough' |
 *   'sort-ascending' |
 *   'stock' |
 *   'sync' |
 *   'table' |
 *   'team' |
 *   'taobao' |
 *   'to-top' |
 *   'swap' |
 *   'trademark' |
 *   'twitter' |
 *   'transaction' |
 *   'undo' |
 *   'unordered-list' |
 *   'up' |
 *   'underline' |
 *   'user-add' |
 *   'user-delete' |
 *   'usergroup-add' |
 *   'user' |
 *   'upload' |
 *   'usergroup-delete' |
 *   'vertical-align-middle' |
 *   'vertical-left' |
 *   'vertical-align-top' |
 *   'weibo' |
 *   'vertical-align-bottom' |
 *   'wifi' |
 *   'vertical-right' |
 *   'woman' |
 *   'zhihu' |
 *   'zoom-in' |
 *   'account-book' |
 *   'api' |
 *   'alert' |
 *   'appstore' |
 *   'audio' |
 *   'bank' |
 *   'bell' |
 *   'book' |
 *   'box-plot' |
 *   'bug' |
 *   'bulb' |
 *   'calculator' |
 *   'build' |
 *   'camera' |
 *   'calendar' |
 *   'car' |
 *   'carry-out' |
 *   'check-circle' |
 *   'clock-circle' |
 *   'cloud' |
 *   'check-square' |
 *   'close-circle' |
 *   'close-square' |
 *   'code' |
 *   'compass' |
 *   'contacts' |
 *   'container' |
 *   'copy' |
 *   'control' |
 *   'credit-card' |
 *   'customer-service' |
 *   'delete' |
 *   'crown' |
 *   'diff' |
 *   'dashboard' |
 *   'dislike' |
 *   'down-circle' |
 *   'database' |
 *   'down-square' |
 *   'environment' |
 *   'edit' |
 *   'exclamation-circle' |
 *   'experiment' |
 *   'eye' |
 *   'eye-invisible' |
 *   'file-add' |
 *   'file-exclamation' |
 *   'file-excel' |
 *   'file-image' |
 *   'file-markdown' |
 *   'file-pdf' |
 *   'file-ppt' |
 *   'file-text' |
 *   'file-unknown' |
 *   'file-word' |
 *   'file-zip' |
 *   'filter' |
 *   'fire' |
 *   'zoom-out' |
 *   'file' |
 *   'flag' |
 *   'folder' |
 *   'folder-add' |
 *   'folder-open' |
 *   'frown' |
 *   'fund' |
 *   'funnel-plot' |
 *   'gift' |
 *   'heart' |
 *   'hdd' |
 *   'highlight' |
 *   'home' |
 *   'hourglass' |
 *   'html5' |
 *   'info-circle' |
 *   'idcard' |
 *   'interation' |
 *   'interaction' |
 *   'left-square' |
 *   'layout' |
 *   'like' |
 *   'lock' |
 *   'meh' |
 *   'message' |
 *   'left-circle' |
 *   'medicine-box' |
 *   'minus-square' |
 *   'minus-circle' |
 *   'mail' |
 *   'money-collect' |
 *   'notification' |
 *   'pause-circle' |
 *   'phone' |
 *   'picture' |
 *   'pie-chart' |
 *   'play-circle' |
 *   'mobile' |
 *   'play-square' |
 *   'insurance' |
 *   'plus-square' |
 *   'plus-circle' |
 *   'pound-circle' |
 *   'printer' |
 *   'project' |
 *   'profile' |
 *   'property-safety' |
 *   'pushpin' |
 *   'reconciliation' |
 *   'question-circle' |
 *   'red-envelope' |
 *   'rest' |
 *   'right-circle' |
 *   'rocket' |
 *   'safety-certificate' |
 *   'schedule' |
 *   'save' |
 *   'security-scan' |
 *   'setting' |
 *   'shopping' |
 *   'right-square' |
 *   'shop' |
 *   'skin' |
 *   'smile' |
 *   'sliders' |
 *   'snippets' |
 *   'sound' |
 *   'stop' |
 *   'tablet' |
 *   'switcher' |
 *   'tag' |
 *   'star' |
 *   'tags' |
 *   'thunderbolt' |
 *   'tool' |
 *   'trademark-circle' |
 *   'unlock' |
 *   'up-circle' |
 *   'up-square' |
 *   'trophy' |
 *   'usb' |
 *   'video-camera' |
 *   'warning' |
 *   'wallet' |
 *   'ci' |
 *   'copyright' |
 *   'euro' |
 *   'dollar' |
 *   'gold' |
 *   'canlendar'
 * )} AntIconsKeys
 */

/** @type {AntIconsKeys[]} */
const AntIconsKeys = Object.keys(AntIcons).map((key) => AntIcons[key].name)

export default AntIconsKeys
