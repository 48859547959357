import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Timeline } from 'antd'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import { showDateTimeEST } from 'utils/helpers'
import { setModalContent } from 'shared/redux/ScreenActions'
import './ProjectStatusHistory.scss'
import { captureSentryError } from 'utils/helpers'

class ProjectStatusHistory extends Component {
  renderHistoryList = () => {
    const { statushistory } = this.props.version
    if (!statushistory?.length) return null
    return statushistory.map((e, i) => {
      return (
        <Timeline.Item
          key={i}
          color={i === statushistory.length - 1 ? Colors.novaBlue : Colors.positive}
        >
          <p>{e.statusname}</p>
          <p>{`${e.statussentence} ${e.username ? e.username : 'System'} on ${showDateTimeEST(
            e.datetime
          )}`}</p>
        </Timeline.Item>
      )
    })
  }

  render() {
    const { setModalContent, version } = this.props
    return (
      <div className="project-status-history">
        <div className="project-status-history__top">
          <h1>Project Status History</h1>
          <Icon icon="x" size={30} onClick={() => setModalContent(null)} />
        </div>
        <div className="project-status-history__bottom">
          <div className="project-status-history__bottom__summary">
            <h1>Current Project Status:</h1>
            <p>
              <Icon
                icon="circle"
                size={15}
                svgStyle={{ stroke: Colors.novaBlue, strokeWidth: 2, verticalAlign: 'inherit' }}
              />
              {version.currentstatusname}
            </p>
          </div>
          <div className="project-status-history__bottom__summary">
            <h1>Last Viewed By:</h1>
            <p>
              {version.lastmodifiedbyname} on {showDateTimeEST(version.lastmodifieddatetime)}
            </p>
          </div>
        </div>
        <Timeline>{this.renderHistoryList()}</Timeline>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const versionId = window.location.pathname.split('/')[3]
    return {
      version: state.ProjectReducer.versions[versionId],
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { setModalContent }

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatusHistory)
