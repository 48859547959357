import { IDischargeTransfer, IInfeedTransfer } from 'shared/types/swagger'
import { ITransfersList } from 'utils/api/list/types/transfers'

export default function getTransferTypeKey(
  transfersList: Partial<ITransfersList>,
  transfer: IInfeedTransfer | IDischargeTransfer
): keyof ITransfersList {
  if (!transfersList || !transfer) return null
  const title = transfersList.types?.[transfer?.transfertypeid]?.title

  if (!title) return null
  return title.split(' ').join('').toLowerCase() as keyof ITransfersList
}
