import {
  setDefaultAccessoryDataForConveyor,
  runUpdateDefaultMaterialOrAccessoryForConveyor,
} from '../../../../redux/EstimatorActions'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializefixedendstop = (conveyorId, fixedendstopData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoryDataForConveyor({
      conveyorId,
      accessoryKey: 'fixedendstop',
      accessoryData: fixedendstopData,
    })
  )
}

export const updateFixedendstop = ({ conveyorId, updatedFixedendstopFields }) => (dispatch) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'fixedendstop',
      updatedFields: updatedFixedendstopFields,
      type: 'accessories',
    })
  )
}
