import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table } from 'antd'
import {
  statisticsColumnConfig,
  statisticsRowConfig,
  statisticsTableTotals,
} from './statisticsTableConfig'
import './StatisticsTable.scss'
import { captureSentryError } from 'utils/helpers'

class StatisticsTable extends Component {
  renderTable = () => {
    const { selectedChart, perioddetail } = this.props
    switch (selectedChart) {
      case 'revenue':
        return (
          <Table
            key="revenue"
            rowKey={(r, i) => i}
            columns={statisticsColumnConfig(perioddetail)}
            dataSource={statisticsRowConfig('revenue', perioddetail)}
            pagination={false}
            style={{ width: '1150px' }}
          />
        )
      case 'quantity':
        return (
          <Table
            key="quantity"
            rowKey={(r, i) => i}
            columns={statisticsColumnConfig(perioddetail)}
            dataSource={statisticsRowConfig('quantity', perioddetail)}
            pagination={false}
            style={{ width: '1150px' }}
          />
        )
      case 'closeratio':
        return (
          <Table
            key="closeratio"
            rowKey={(r, i) => i}
            columns={statisticsColumnConfig(perioddetail)}
            dataSource={statisticsRowConfig('closeratio', perioddetail)}
            pagination={false}
            style={{ width: '1150px' }}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { perioddetail, selectedChart, statisticsTotals } = this.props

    return (
      <div id="statistics-table">
        {perioddetail ? this.renderTable() : null}
        <div className="statistics-table__totals">
          {statisticsTableTotals(selectedChart, statisticsTotals)}
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const { selectedChart, statisticsData } = state.AdminStatsReducer
    const { perioddetail, statisticsTotals } = statisticsData

    return {
      selectedChart,
      perioddetail,
      statisticsTotals,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTable)
