const INFEED_PATH = (ln: number, w: number) =>
  `M-2,0 L${w},0 Q${w},0 ${w},0 L${w},${ln} L0,${ln} L0,0 Q0,0 ${0},0`
const DISCHARGE_PATH = (ln: number, w: number) =>
  `M0,0 L${w},0 L${w},${ln} ${w},${ln} L${w},${ln} L${0},${ln} 0,${ln} L0,0`

export default function getSVGPathByType(
  type: 'Infeed' | 'Discharge',
  length: number,
  width: number
) {
  if (type === 'Discharge') return DISCHARGE_PATH(length, width)
  return INFEED_PATH(length, width)
}
