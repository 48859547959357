import React, { ReactNode } from 'react'
import { Form as AntForm } from 'antd'
import './Form.scss'
import { FormProps } from 'antd/lib/form'

interface Props extends FormProps {
  className?: string
  children: ReactNode
}

function Form(props: Props) {
  const { children, className, ...restProps } = props
  return (
    <AntForm colon={false} {...restProps} className={`ant-form-styled ${className}`}>
      {children}
    </AntForm>
  )
}

Form.Item = AntForm.Item
Form.create = AntForm.create
Form.createFormField = AntForm.createFormField

export default Form
