import { IFileUpload } from 'shared/types/FileUpload'
import { IExternalUserReduced } from 'shared/types/user'

export const getDerivedUser = (user: IExternalUserReduced) => ({
  firstname: user.firstname,
  lastname: user.lastname,
  email: user.email,
  roles: user.roles,
  password: '',
  signature: {} as Partial<IFileUpload>,
  customerid: user?.customerid,
  contactid: user?.contactid,
  customers: user?.customer
    ? {
        [user.customer.id]: user.customer,
      }
    : {},
  contacts: user?.contact
    ? {
        [user.contact.id]: user.contact,
      }
    : {},
})

export type DerivedUser = ReturnType<typeof getDerivedUser>
