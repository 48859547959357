type DSSA47TitleMapKeys =
  | 'mountingtypeid'
  | 'motoroutputvoltageid'
  | 'vaconxseriesinverterid'
  | 'gearboxid'
type SEWorTAEKDSMapKeys = 'gearboxid'
type NordMapKeys = 'gearboxid'

type TitleMap<MapKeys extends string> = Record<MapKeys, Array<string>>

export const DSSA47_VALID_TITLES: TitleMap<DSSA47TitleMapKeys> = {
  mountingtypeid: ['Shaft Mounted'],
  motoroutputvoltageid: ['230 VAC 60 Hz', '460 VAC 60 Hz'],
  vaconxseriesinverterid: ['1 HP'],
  gearboxid: ['SA47'],
}

export const SEW_VALID_TITLES: TitleMap<SEWorTAEKDSMapKeys> = {
  gearboxid: [
    'WA20',
    'WA30',
    'SA47',
    'SA57',
    'SA67',
    'SA77',
    'KA37',
    'KA47',
    'KA57',
    'KA67',
    'KA77',
    'WF20',
    'WF30',
    'SF47',
    'SF57',
    'SF67',
    'KF37',
    'KF47',
    'KF57',
    'KF67',
  ],
}

export const SEW_VALID_TITLES_SHAFT = {
  gearboxid: [
    ...SEW_VALID_TITLES.gearboxid.filter((value) => !value.includes('F')),
  ]
}

export const NORD_VALID_TITLES: TitleMap<NordMapKeys> = {
  gearboxid: ['2050'],
}

export const TAEKDS_VALID_TITLES = {
  gearboxid: [
    ...SEW_VALID_TITLES.gearboxid.filter((value) => !value.includes('F')),
    ...NORD_VALID_TITLES.gearboxid
  ]
}

export const GEARBOX_DEFAULT = 'SA47'

export const TAEKDS_VALID_TITLES_EXTERNAL = {
  gearboxid: [
    GEARBOX_DEFAULT,
    ...NORD_VALID_TITLES.gearboxid
  ]
}

