import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SectionOverrideHeader } from '../../../shared/Headers'
import { updateWearstrips } from '../../redux/sectionOverrides/WearstripsActions'
import {
  updateSectionOverrideMaterialAcessoriesMetaData,
  addMaterialOrAccessoryToConveyorSection,
} from '../../../../redux/SectionOverrideActions'
import wearStripsConfig from '../WearStripsConfig'
import { Radio } from 'shared/components'
import { captureSentryError } from 'utils/helpers'

class Wearstrips extends PureComponent {
  renderWearstripMaterialOptions = (config) => {
    const { dataKey, prettyName, options, rules, vertical } = config.materials
    const { updateWearstrips, conveyorId, sectionId, isactive, islocked } = this.props
    return (
      <Radio
        key="default-materials-wearstrips-material"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 0 10px 0"
        options={options}
        onChange={(val) =>
          updateWearstrips({ sectionId, conveyorId, updatedWearstripsFields: { [dataKey]: val } })
        }
        rules={rules}
        vertical={vertical}
        initialValue={this.props.wearstripsData.materialid}
      />
    )
  }

  renderWearstripLubricatedOptions = (config) => {
    const { dataKey, prettyName, options, rules, vertical } = config.lubricated
    const { updateWearstrips, conveyorId, sectionId, isactive, islocked } = this.props
    return (
      <Radio
        key="default-materials-wearstrips-lubricated"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 0 10px 0"
        options={options}
        onChange={(val) =>
          updateWearstrips({ sectionId, conveyorId, updatedWearstripsFields: { [dataKey]: val } })
        }
        rules={rules}
        vertical={vertical}
        initialValue={this.props.wearstripsData.lubricated}
      />
    )
  }

  render() {
    if (!this.props.wearstripsData) {
      return (
        <SectionOverrideHeader
          checked={false}
          open={false}
          onClickCheck={() => {
            this.props.addMaterialOrAccessoryToConveyorSection({
              materialsOrAccessories: 'materials',
              sectionId: this.props.sectionId,
              conveyorId: this.props.conveyorId,
              itemName: 'wearstrips',
            })
          }}
          title="Wearstrips"
          type="materials"
          conveyorId={this.props.conveyorId}
          fieldKey="wearstrips"
        />
      )
    }

    const {
      wearstripsData: { parameteractive },
      wearstripsMeta: { open },
      updateWearstrips,
      conveyorId,
      updateSectionOverrideMaterialAcessoriesMetaData,
      sectionId,
    } = this.props

    const config = wearStripsConfig(this.props)

    return (
      <div className="material-accessory-item">
        <SectionOverrideHeader
          checked={parameteractive}
          onClickCheck={() => {
            const checked = !parameteractive
            updateWearstrips({
              sectionId,
              conveyorId,
              updatedWearstripsFields: { parameteractive: checked, removedforsection: checked },
            })
          }}
          open={parameteractive && open}
          onClickOpen={() => {
            updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'materials',
              name: 'wearstrips',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Wearstrips"
          type="materials"
          conveyorId={this.props.conveyorId}
          fieldKey="wearstrips"
        >
          <div className="builder-tile__expanded__content__wrapper">
            {this.renderWearstripMaterialOptions(config)}
            {this.renderWearstripLubricatedOptions(config)}
          </div>
        </SectionOverrideHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, sectionId, match } = props
    const {
      params: { versionId },
    } = match

    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const wearstripsData = EstimatorReducer?.sections?.[sectionId]?.materials?.wearstrips
    const wearstripsMeta =
      sectionMetadata?.materialsAccessoriesMetadata?.materials?.wearstrips || {}

    const { isactive, islocked } = ProjectReducer.versions[versionId]

    if (!wearstripsData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      sectionId,
      wearstripsMeta,
      wearstripsData,
      wearstripsOptions: ProjectReducer.currentlistsettings.materials.wearstrips,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateWearstrips,
    updateSectionOverrideMaterialAcessoriesMetaData,
    addMaterialOrAccessoryToConveyorSection,
  })(Wearstrips)
)
