import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

/**
 * @typedef {Object} Product
 * @property {number} [conveyorid]
 * @property {string} [description]
 * @property {number} [height]
 * @property {number} [id]
 * @property {number} [length]
 * @property {number} [load]
 * @property {number} [materialtypeid]
 * @property {number} [rate]
 * @property {number} [weight]
 * @property {number} [width]
 * @property {string|number} [numberOfLanes]
 * @property {string|number} [material]
 * @property {string|number} [tempurature]
 * @property {string|number} [accum]
 * @property {string|number} [accumLength]
 *
 * @typedef {Object<string, Object<string, Product>>} ProductState
 */

/** @type {ProductState} */
const INITIAL_STATE = {}

export default createReducer(INITIAL_STATE, {
  ADD_PRODUCTS: (state, action) => {
    const { conveyorId, products = {}, meta = {} } = action.payload
    state[conveyorId] = {
      ...products,
      meta: meta,
    }
  },
  ADD_PRODUCT: (state, action) => {
    const { conveyorId, product, meta } = action.payload
    if (!product || !meta) return
    if (!state[conveyorId]) {
      state[conveyorId] = {}
    }
    state[conveyorId][product.id] = product
    if (!state[conveyorId].meta) {
      state[conveyorId].meta = {}
    }
    state[conveyorId].meta[product.id] = meta
  },
  REMOVE_PRODUCT: (state, action) => {
    const { conveyorId, productId } = action.payload
    delete state[conveyorId][productId]
    delete state[conveyorId].meta[productId]
  },
  UPDATE_PRODUCT: (state, action) => {
    const { conveyorId, productId, data } = action.payload
    state[conveyorId][productId] = {
      ...state[conveyorId]?.[productId],
      ...data,
    }
  },
  SET_PRODUCT_VALIDITY: (state, action) => {
    const { conveyorId, productId, valid } = action.payload
    state[conveyorId].meta = {
      ...state[conveyorId].meta,
      [productId]: {
        ...(state[conveyorId].meta?.[productId] || {}),
        valid,
      },
    }
  },
  SET_PRODUCT_COLLAPSIBLE_OPEN: (state, action) => {
    const { conveyorId, productId, open } = action.payload
    state[conveyorId].meta = {
      ...state[conveyorId].meta,
      [productId]: {
        ...(state[conveyorId].meta?.[productId] || {}),
        open,
      },
    }
  },
  REMOVE_CONVEYORS_FOR_OTHER_PROJECTS: (state, action) => {
    const { conveyorIds } = action.payload
    const otherProjectsConveyorIds = Object.keys(state).filter((id) => !conveyorIds.includes(id))
    for (let i = 0; i < otherProjectsConveyorIds.length; i++) {
      delete state[otherProjectsConveyorIds[i]]
    }
  },
  [PURGE]: () => INITIAL_STATE,
})
