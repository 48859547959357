export default (passwords) => [
  {
    key: 'oldPassword',
    label: 'Existing Password',
    placeholder: 'Enter Existing Password',
    rules: [
      {
        required: true,
        message: 'Please enter the existing password.',
      },
    ],
  },
  {
    key: 'newPassword',
    label: 'New Password',
    placeholder: 'Enter New Password',
    rules: [
      {
        required: true,
        validator: (rule, value, cb) => {
          try {
            if (value === '') {
              throw new Error('Please enter the new password to be used.')
            } else if (value.length < 4) {
              throw new Error('Password must have at least 4 characters.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
  {
    key: 'newPasswordConfirm',
    label: 'Confirm New Password',
    placeholder: 'Re-enter New Password',
    rules: [
      {
        required: true,
        validator: (rule, value, cb) => {
          try {
            if (value === '') {
              throw new Error('Please re-enter the new password.')
            } else if (value !== passwords.newPassword) {
              throw new Error('Passwords do not match.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
]
