import * as React from 'react'
import { Table } from 'antd'
import { partsTableConfig } from './partsTableConfig'
import './PartsTable.scss'

export default class PartsTable extends React.PureComponent {
  arrangePartsData = () => {
    const { chainparts } = this.props
    return chainparts.map((part) => {
      const {
        id,
        partnumber,
        description,
        qty,
        unitprice,
        unitweight,
        unitlinklabor,
        totalprice,
        totalweight,
        totallinklabor,
      } = part
      return {
        key: id,
        partnumber,
        description,
        qty: qty ? qty.toFixed(4) : undefined,
        unitprice: unitprice ? unitprice.toFixed(4) : undefined,
        unitweight: unitweight ? unitweight.toFixed(4) : undefined,
        unitlinklabor: unitlinklabor ? unitlinklabor.toFixed(4) : undefined,
        totalprice: totalprice ? totalprice.toFixed(4) : undefined,
        totalweight: totalweight ? totalweight.toFixed(4) : undefined,
        totallinklabor: totallinklabor ? totallinklabor.toFixed(4) : undefined,
      }
    })
  }

  render() {
    const { chainparts, ...restProps } = this.props
    return (
      <div className="parts-table" id="parts-table" tabIndex={-1} role="region" {...restProps}>
        <Table columns={partsTableConfig} dataSource={this.arrangePartsData()} pagination={false} />
      </div>
    )
  }
}
