import _ from 'lodash'
import { ConveyorSectionType } from 'shared/types/swagger'
import { BuilderCanvasProps } from '..'
import { SVG_PADDING } from '../constants'
import { BuilderCanvasSectionData, ConveyorSectionWithStringId } from '../types/types'
import { findConnectingPoint } from './findConnectingPoint'
import { magicalCalculation } from './magicalCalculation'

type PrepareConveyorSectionsToSectionDataParams = {
  setSectionData: (sectionData: BuilderCanvasSectionData) => void;
  sectionData: BuilderCanvasSectionData;
  conveyorWidth: number;
} & Pick<BuilderCanvasProps, 'conveyorId' | 'rotateCanvas' | 'unit' | 'conveyor' | 'chainwidth'>

/** Creates sectionData to be used for ConveyerBuilder. */
export function prepareConveyorSectionsToSectionData({
  conveyorId,
  rotateCanvas,
  unit,
  chainwidth,
  conveyor: { conveyorsections },
  sectionData,
  setSectionData,
  conveyorWidth,
}: PrepareConveyorSectionsToSectionDataParams) {
  const rotateCanvasXYValue = rotateCanvas
    ? { x: SVG_PADDING + 70, y: SVG_PADDING + 380 }
    : { x: SVG_PADDING + 70, y: SVG_PADDING + 80 }

  // Add infeed and everything in between
  for (let i = 0; i <= Object.keys(conveyorsections).length; i++) {
    if (!sectionData[i]) {
      const stateCopy = { ...sectionData }
      const currentSection = _.find(conveyorsections, (section) => section.sectionnumber === i)

      if (i === 0) {
        stateCopy[i] = {
          id: `infeed-${conveyorId}`,
          anchorPosition: rotateCanvasXYValue,
          anchorAngleDeg: 0,
          angle: 0,
          radius: 0,
          type: 'Infeed',
        }
      } else if (!currentSection) {
        break
      } else if (currentSection.type === 'Straight' && !currentSection.length) {
        return
      } else {
        const prevSection = sectionData[i - 1]
        // use document to find previous element, this tightly couples rendering to preparing all section data
        const path = (document.getElementById(
          prevSection.id.toString()
        ) as unknown) as SVGGeometryElement
        const transformedPoint = findConnectingPoint(prevSection, chainwidth, path, conveyorWidth)
        stateCopy[i] = magicalCalculation(currentSection, transformedPoint, prevSection, unit)
      }

      setSectionData(stateCopy)
      break
    }
  }

  // Add discharge
  if (Object.keys(sectionData).length === Object.keys(conveyorsections).length + 1) {
    const lastSectionId = Object.keys(sectionData).length - 1
    const lastSection = sectionData[lastSectionId]

    const dischargeSection: ConveyorSectionWithStringId = {
      id: `${conveyorId}-discharge`,
      sectionnumber: lastSectionId + 1,
      type: 'Discharge' as ConveyorSectionType,
    }

    const stateCopy = { ...sectionData }
    const path = (document.getElementById(
      lastSection.id.toString()
    ) as unknown) as SVGGeometryElement
    const transformedPoint = findConnectingPoint(lastSection, chainwidth, path, conveyorWidth)
    stateCopy[lastSectionId + 1] = magicalCalculation(
      dischargeSection,
      transformedPoint,
      lastSection,
      unit
    )

    setSectionData(stateCopy)
  }

  return sectionData
}
