export default function formatCurrency(value: number, fractionDigits: number = null) {
  if (fractionDigits && value) {
    // 12.34567 -> $12.34 (when fractionDigits = 2)
    return `$ ${value.toFixed(fractionDigits).toLocaleString()}` || `$ ${value}`
  } else if (value) {
    // 10000 -> $10,000
    return `$ ${Math.round(value).toLocaleString()}` || `$ ${value}`
  }
  return '$ 0'
}
