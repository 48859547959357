import { createAction } from '@reduxjs/toolkit'
import { SytelineResponsePlus } from 'features/Admin/components/CostImport/types'
import { IPartsUpdateResponse } from 'shared/types/swagger'

export const setUnsavedCostUpdate = createAction<Record<number, SytelineResponsePlus>>(
  'SET_UNSAVED_COST_UPDATE'
)
export const setUnsavedStaticParts = createAction<Record<string, IPartsUpdateResponse>>(
  'SET_UNSAVED_STATIC_PARTS_UPDATE'
)
