import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { IChainConveyor } from '../shared/types'

// This reducer consists of chain style options for a conveyor and
// also the metadata for it's non standard rows, if any.

/**
 * @typedef {Object<string, Object<string, ChainConveyor>> & {chainPreviewConveyorId?: number}} ChainState
 * @example
 * [ versionId ]: {
 *   [ conveyorId ]: {
 *     alternaterowchoice: false,
 *     askfeetlengthinsteadofrodnumber: false,
 *     chainStyles: {}, // available chainStyles based on chainSerie+chainWidthId,
 *     showbluelinkswarning: false,
 *     showmovingsideguidesoptions: false,
 *     // any metadata for chain - includes UI state for chainbuilder
 *     nonStandardRowMeta: {
 *       [ rowPatternId ]: { // = chainPatternId, unique per non-standard chain row
 *         currentStepIndex: 0, // array of available steps changes per series. Length of array may be 1 - 3
 *         collapsed: true,
 *         dirty: true // when changes are made to a row, it is marked as 'dirty' until the user successfully saves
 *                        which then dispatches an update for dirty: false
 *       }
 *     },
 *     validationErrors: []
 *   }
 * }
 */

export type IChainState = { chainPreviewConveyorId?: string } & {
  [versionId: string]: {
    [conveyorId: string]: Partial<IChainConveyor>
  }
}

const INITIAL_STATE: IChainState = {
  chainPreviewConveyorId: null,
}

export default createReducer(INITIAL_STATE, {
  // *** this will never happen, conveyors must always have a chain
  // DELETE_CONVEYOR_CHAIN: (state, action) => {
  //   const { conveyorId, versionId } = action.payload
  //   delete state[ versionId ][ conveyorId ]
  // },
  SET_CHAIN_PREVIEW_CONVEYOR_ID: (state, action) => {
    state.chainPreviewConveyorId = action.payload
  },
  UPDATE_CONVEYOR_CHAIN: (
    state,
    action: { payload: { conveyorId: string; chain: IChainConveyor; versionId: string } }
  ) => {
    const { conveyorId, chain, versionId } = action.payload

    if (!state[versionId]) {
      state[versionId] = {}
      if (!state[versionId][conveyorId]) {
        state[versionId][conveyorId] = {}
      }
    }
    const conveyorChain: IChainConveyor = {
      ...state[versionId][conveyorId],
      ...chain,
    }

    /** @type {Object<number, Object<number, ChainConveyor>>} */
    state[versionId] = {
      ...state[versionId],
      [conveyorId]: conveyorChain,
    }
  },
  DELETE_NONSTANDARD_CHAIN_ROW_META: (
    state,
    action: { payload: { conveyorId: number; versionId: number; nonStdRowId: number } }
  ) => {
    const { conveyorId, versionId, nonStdRowId } = action.payload
    delete state[versionId][conveyorId].nonStandardRowMeta[nonStdRowId]
  },
  UPDATE_NONSTANDARD_CHAIN_ROW_META: (state, action) => {
    const { conveyorId, metaInformation, versionId, nonStdRowId } = action.payload
    if (!state[versionId]) {
      state[versionId] = {}
    }
    if (!state[versionId][conveyorId]) {
      state[versionId][conveyorId] = {}
    }
    if (!state[versionId][conveyorId].nonStandardRowMeta) {
      state[versionId][conveyorId].nonStandardRowMeta = {}
    }

    state[versionId][conveyorId].nonStandardRowMeta[nonStdRowId] = {
      ...state[versionId][conveyorId].nonStandardRowMeta[nonStdRowId],
      ...metaInformation,
    }
  },
  UPDATE_CHAIN_VALIDATION_ERRORS: (state, action) => {
    const { conveyorId, versionId, errors } = action.payload
    state[versionId][conveyorId].validationErrors = errors
  },
  [PURGE]: () => INITIAL_STATE,
})
