import React from 'react'
import { DEFAULT_CONVEYOR_WIDTH, DEFAULT_PART_COLOR, SELECTED_PART_COLOR } from '../../../constants'
import { DriveTypeTitle, TransferTypeKey } from '../../../types/types'

interface Props {
  selected: boolean;
  typeKey: TransferTypeKey;
  anchorAngleDeg: number;
  driveboxLocation: 'Left' | 'Right';
  driveTypeTitle: DriveTypeTitle;
  width: number;
}

const translationMap = (
  widthChange: number
): Record<'Idler' | 'Drive', Record<TransferTypeKey, Record<'Left' | 'Right', string>>> => ({
  Idler: {
    infeed: { Left: `-480, ${580.5 + widthChange}`, Right: '-480, -337.5' },
    discharge: { Left: `315, ${-580.5 - widthChange}`, Right: '315, 337.5' },
  },
  Drive: {
    infeed: { Left: `-480, ${580.5 + widthChange}`, Right: '-480, -337.5' },
    discharge: { Left: `315, ${-580.5 - widthChange}`, Right: '315, 337.5' },
  },
})

const SVG_HEIGHT = 667
const SVG_WIDTH = 1043
const SF_PER_CHAIN_WIDTH = 2.44

export default function TransferMicrospanSvG({
  selected,
  typeKey,
  anchorAngleDeg,
  driveboxLocation,
  driveTypeTitle,
  width,
}: Props) {
  const rotation = (typeKey === 'infeed' ? -90 : 90) - anchorAngleDeg
  const widthChange = (width - DEFAULT_CONVEYOR_WIDTH) * SF_PER_CHAIN_WIDTH
  const strokeWidth = 4 * SF_PER_CHAIN_WIDTH
  const translation = translationMap(widthChange)[driveTypeTitle][typeKey][
    driveboxLocation ?? 'Left'
  ]
  const transform =
    (typeKey === 'infeed' && driveboxLocation === 'Left') ||
    (typeKey === 'discharge' && driveboxLocation === 'Right')
      ? 'scale(1, -1)'
      : ''
  console.log({ widthChange })

  return (
    <g width={SVG_WIDTH} height={SVG_HEIGHT} viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}>
      <title>Transfer - Microspan</title>
      <g
        fill="none"
        fillRule="evenodd"
        transform={`scale(${1 / SF_PER_CHAIN_WIDTH}), rotate(${rotation})`}
      >
        <g transform={`translate(${translation})`}>
          <g
            stroke={selected ? SELECTED_PART_COLOR : DEFAULT_PART_COLOR}
            strokeWidth={strokeWidth}
            transform={transform}
            strokeLinecap="round"
          >
            <line x1={247} x2={247} y1={0} y2={269}></line>
            <rect x={388} y={22.5} width={263} height={126}></rect>
            <line x1={0} x2={963} y1={0} y2={0}></line>
            <line x1={0} x2={1028} y1={269.5} y2={269.5}></line>
            <line x1={519} x2={938} y1={283} y2={283}></line>
            <line x1={481} x2={963} y1={255.5} y2={255.5}></line>

            <rect x={368} y={337.5} width={671} height={244 + widthChange}></rect>
            <line id="1" x1={0} x2={1028} y1={649 + widthChange} y2={649 + widthChange}></line>
            <line id="2" x1={519} x2={938} y1={634.5 + widthChange} y2={634.5 + widthChange}></line>
            <line id="3" x1={481} x2={963} y1={663.5 + widthChange} y2={663.5 + widthChange}></line>
            <line id="4" x1={386} x2={386} y1={269} y2={649 + widthChange}></line>
            <line id="5" x1={963} x2={963} y1={0} y2={649 + widthChange}></line>
            <line id="6" x1={817} x2={817} y1={0} y2={649 + widthChange}></line>
            <line id="7" x1={691} x2={691} y1={269.5} y2={649 + widthChange}></line>
            <line id="8" x1={1028} x2={1028} y1={269.5} y2={649 + widthChange}></line>
            <line id="9" x1={646} x2={646} y1={337.5} y2={580.5 + widthChange}></line>
            <line id="10" x1={631} x2={631} y1={337.5} y2={580.5 + widthChange}></line>
            <line id="11" x1={616} x2={616} y1={337.5} y2={580.5 + widthChange}></line>
            <line id="12" x1={601} x2={601} y1={337.5} y2={580.5 + widthChange}></line>
            <line id="13" x1={480} x2={480} y1={337.5} y2={580.5 + widthChange}></line>
            <line id="14" x1={791} x2={791} y1={337.5} y2={580.5 + widthChange}></line>
            <line id="15" x1={769} x2={769} y1={337.5} y2={580.5 + widthChange}></line>

            <path
              d={`M434.5,269.5 A23,33 1 0 0 ${434.5 - 23},${269.5 + 33} L${434.5 - 23},${
                649 + widthChange - 33
              } A23,33 1 0 0 434.5,${649 + widthChange} L434.5,${649 + widthChange} L434.5,${
                649 + widthChange
              }`}
            ></path>
          </g>
        </g>
      </g>
    </g>
  )
}
