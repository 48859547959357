import React, { Component } from 'react'
export default class CloneIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="0 0 32 32"
      >
        <path
          fill={color}
          d="M28.8271433,5 L8.38034069,5 C7.73258979,5 7.20748394,5.52510585 7.20748394,6.17285674 L7.20748394,9.83216978 L3.17285674,9.83216978 C2.52510585,9.83216978 2,10.3572756 2,11.0050265 L2,26.4241832 C2,27.0719341 2.52510585,27.5970399 3.17285674,27.5970399 L23.6196593,27.5970399 C24.2674102,27.5970399 24.7925161,27.0719341 24.7925161,26.4241832 L24.7925161,22.7648701 L28.8271433,22.7648701 C29.4748941,22.7648701 30,22.2397643 30,21.5920134 L30,6.17285674 C30,5.52510585 29.4748941,5 28.8271433,5 L28.8271433,5 Z M22.4468026,25.2513264 L4.34571349,25.2513264 L4.34571349,12.1778833 L7.20748394,12.1778833 L7.20748394,21.5920134 C7.20748394,22.2397643 7.73258979,22.7648701 8.38034069,22.7648701 L22.4546216,22.7648701 L22.4468026,25.2513264 Z M27.6542865,20.4191567 L9.55319743,20.4191567 L9.55319743,7.34571349 L27.6542865,7.34571349 L27.6542865,20.4191567 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g mask="url(#mask-2)" fill="none">
            <rect x="0" y="0" width="32" height="32"></rect>
          </g>
        </g>
      </svg>
    )
  }
}
