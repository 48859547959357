import { createAction } from '@reduxjs/toolkit'
import { IGearmotor, IVFDSelection } from 'shared/types/swagger'
import store, { AppDispatch } from 'utils/../reduxStore'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import { DesiredUnitOptions } from '../types'

export const updateGearmotorInfo = createAction<{
  conveyorId: string | number
  gearmotorInfo: Partial<IGearmotor>
}>('UPDATE_GEARMOTOR_INFO')
export const updateGearmotorDesiredUnitOptions = createAction<{
  conveyorId: number
  desiredUnitOptions: DesiredUnitOptions
}>('UPDATE_GEARMOTOR_DESIRED_UNIT_OPTIONS')
export const updateVFDOption = createAction<{conveyorId: number, VFDOptions: IVFDSelection[]}>('UPDATE_VFD_OPTION')

export const getVFDOptions = (conveyorId: number) => async (
  dispatch: AppDispatch
) => {
  ConveyorService.getVFDSelection(conveyorId).then((VFDOptions) => {
      dispatch(updateVFDOption({ conveyorId, VFDOptions }))
    }).catch((e) => {
    debugger
    throw e
  })
}

export const getGearmotorDesiredUnitOptions = (conveyorId: number) => async (
  dispatch: AppDispatch
) => {
  return new Promise((resolve, reject) => {
    ConveyorService.getGearmotorDesiredUnitOptions(conveyorId).then((desiredUnitOptions) => {
      dispatch(
        updateGearmotorDesiredUnitOptions({
          conveyorId,
          desiredUnitOptions,
        })
      )
      resolve(true)
    })
  })
}

export const getGearmotorForConveyor = (conveyorId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    ConveyorService.getGearmotor(conveyorId)
      .then((gearmotorInfo) => {
        dispatch(
          updateGearmotorInfo({
            conveyorId,
            gearmotorInfo,
          })
        )
        dispatch(getGearmotorDesiredUnitOptions(conveyorId))
        resolve(gearmotorInfo)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          ConveyorService.addGearmotor(conveyorId)
            .then((res) => dispatch(getGearmotorForConveyor(conveyorId)))
            .catch((e) => reject(e))
        }
        resolve(e)
      })
  })
}

export const updateGearmotor = (conveyorId, key, value) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const payload = [
      {
        op: 'replace',
        path: `/${key}`,
        value,
      },
    ]
    ConveyorService.updateGearmotor(conveyorId, payload)
      .then((gearmotorInfo) => {
        dispatch(
          updateGearmotorInfo({
            conveyorId,
            gearmotorInfo,
          })
        )
        resolve(gearmotorInfo)
      })
      .catch((e) => {
        reject(new Error('Failed to update desired unit'))
        throw e
      })
  })
}

export const updateGearmotorOptimistic = (conveyorId, key, value) => async (dispatch) => {
  const oldGearmotorData = store.getState().ConveyorReducer.conveyors[conveyorId].gearmotor

  const newGearmotorData = {
    ...oldGearmotorData,
    [key]: value,
  }

  dispatch(
    updateGearmotorInfo({
      conveyorId,
      gearmotorInfo: newGearmotorData,
    })
  )

  const payload = [
    {
      op: 'replace',
      path: `/${key}`,
      value,
    },
  ]

  ConveyorService.updateGearmotor(conveyorId, payload).catch((e) => {
    dispatch(
      updateGearmotorInfo({
        conveyorId,
        gearmotorInfo: oldGearmotorData,
      })
    )
    debugger
    throw e
  })
}
