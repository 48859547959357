import React from 'react'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'

/**
 * @typedef {Object} Props
 * @prop {boolean} hide
 * @prop {*} handleClick
 */

/** @param {Props} props */
function EditProjectDetailsButton({ hide, handleClick }) {
  if (hide) return null
  return (
    <button className="project-summary__firstrow__edit-project" onClick={handleClick}>
      <Icon icon="editLine" size={16} color={Colors.novaBlue} />
      Edit Project Details
    </button>
  )
}

export default EditProjectDetailsButton
