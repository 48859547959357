import React, { PureComponent } from 'react'
import Colors from '../../constants/colors'
import './Icon.scss'
import IconsMap from './iconsMap'
import cn from 'classnames'
import { Icon as AntIcon } from 'antd'
import AntIconsKeys from './antIconsKeys'

/**
 * @typedef {import('./antIconsKeys').AntIconsKeys} AntIconsKey
 *
 * @typedef {Object} Props
 * @prop {String} [id='']
 * @prop {String} [className]
 * @prop {string} [color=Colors.ash]
 * @prop {keyof Colors} [colorKey]
 * @prop {Boolean} [disabled=false]
 * @prop {Function} [onClick]
 * @prop {Object} [style={}]
 * @prop {Number} [size=10]
 * @prop {Object} [svgStyle={}]
 * @prop {keyof IconsMap} [icon]
 * @prop {AntIconsKey} [antIcon]
 *
 * @extends {PureComponent<PureComponent['props'] & Props>}
 */
class Icon extends PureComponent {
  components = IconsMap

  render() {
    const {
      id = '',
      className,
      color = Colors.ash,
      colorKey,
      disabled = false,
      onClick,
      style = {},
      icon = 'star',
      antIcon,
      size = 10,
      svgStyle = {},
      ...restProps
    } = this.props

    if (!(icon in IconsMap) && AntIconsKeys.includes(antIcon)) {
      return <AntIcon className={className} style={svgStyle} {...restProps} type={antIcon} />
    }

    const IconProp = this.components[icon]

    return (
      <div
        id={id}
        className={cn(
          'icon-component',
          className,
          icon,
          { clickable: Boolean(onClick) },
          { disabled }
        )}
        style={{ width: `${size}px`, ...style }}
        {...(onClick
          ? {
              onClick: () => {
                if (!disabled && onClick) {
                  onClick()
                }
              },
            }
          : {})}
        {...restProps}
      >
        <IconProp
          size={size}
          color={colorKey ? Colors[colorKey] : color}
          style={{ width: '100%', height: '100%', verticalAlign: 'middle', ...svgStyle }}
        />
      </div>
    )
  }
}

export const TMIcon = {
  OK: <IconsMap.check size={18} color={Colors.positive} style={{ verticalAlign: 'middle' }} />,
  Caution: (
    <IconsMap.caution size={18} color={Colors.bourbon} style={{ verticalAlign: 'middle' }} />
  ),
  Warning: <IconsMap.x size={18} color={Colors.negative} style={{ verticalAlign: 'middle' }} />,
}

export default Icon
