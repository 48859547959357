import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import { updateDriptray } from '../redux/default/DriptrayActions'
import { captureSentryError } from 'utils/helpers'

class Driptray extends Component {
  render() {
    if (this.props.loading) {
      return null
    }

    const {
      driptrayData: { parameteractive },
      driptrayMeta: { open },
      updateDriptray,
      conveyorId,
    } = this.props
    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="accessories"
          fieldKey="driptray"
          checked={parameteractive}
          noExpandAllowed
          onClickCheck={() => {
            updateDriptray({
              conveyorId,
              updatedDriptrayFields: { parameteractive: !parameteractive },
            })
          }}
          open={open}
          onClickOpen={() => {}}
          title="Driptray"
        />
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer } = state
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props
    const driptrayData =
      EstimatorReducer[conveyorId] &&
      EstimatorReducer[conveyorId].accessories &&
      EstimatorReducer[conveyorId].accessories.driptray
    const driptrayMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.driptray

    if (!driptrayData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      driptrayMeta,
      driptrayData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  updateDriptray,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Driptray))
