import React, { PureComponent } from 'react'
import { Icon } from 'shared/components'
import cn from 'classnames'

/**
 * @typedef {Object} Props
 * @prop {function(React.MouseEvent): void} handleClick
 * @prop {boolean} show
 */

/** @extends {PureComponent<Props>} */
export default class ProjectNotesToggle extends PureComponent {
  render() {
    const { handleClick, show } = this.props

    return (
      <button
        className={cn('project-notes-toggle', { 'project-notes-toggle--active': show })}
        onClick={handleClick}
      >
        <Icon
          icon="editSquare"
          colorKey="white"
          size={36}
          svgStyle={{ transform: 'translateY(2px)' }}
        />
      </button>
    )
  }
}
