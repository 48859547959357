import moment from 'moment'

export const customDatePickerConfig = {
  calendarLabel: {
    ytd: 'Current YTD',
    mtd: 'Current MTD',
    wtd: 'Current WTD',
    quarter: 'Last Quarter',
    lastyear: 'Last Year',
    more30: 'Custom Date Range',
    less30: 'Custom Date Range',
  },
  periodType: {
    ytd: {
      apiValue: 'month',
      label: 'year',
    },
    mtd: {
      apiValue: 'week',
      label: 'month',
    },
    quarter: {
      apiValue: 'month',
      label: 'quarter',
    },
    lastyear: {
      apiValue: 'month',
      label: 'year',
    },
    custom_month: {
      apiValue: 'week',
      label: 'custom',
    },
    custom_monthrange: {
      apiValue: 'month',
      label: 'custom',
    },
  },
}

export const checkTypeOfDateRange = (dateStrings) => {
  const startDate = dateStrings[0]
  const endDate = dateStrings[1]
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)

  const customDateRangeDays =
    startDateMoment.format('YYYY-MM') === endDateMoment.format('YYYY-MM')
      ? 'custom_month'
      : 'custom_monthrange'

  const dateType =
    startDate === moment().startOf('year').format('YYYY-MM-DD') &&
    endDate === moment().format('YYYY-MM-DD')
      ? 'ytd'
      : startDate === moment().startOf('month').format('YYYY-MM-DD') &&
        endDate === moment().format('YYYY-MM-DD')
      ? 'mtd'
      : startDate === moment().startOf('week').format('YYYY-MM-DD') &&
        endDate === moment().format('YYYY-MM-DD')
      ? 'wtd'
      : startDate === moment().startOf('quarter').subtract(1, 'quarter').format('YYYY-MM-DD') &&
        endDate === moment().endOf('quarter').subtract(1, 'quarter').format('YYYY-MM-DD')
      ? 'quarter'
      : startDate === moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD') &&
        endDate === moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD')
      ? 'lastyear'
      : customDateRangeDays

  const calendarLabel = customDatePickerConfig.calendarLabel[dateType]
  const periodType = customDatePickerConfig.periodType[dateType]

  return {
    calendarLabel,
    dateType,
    periodType,
    startDate,
    endDate,
  }
}

export const customDatePickerRanges = {
  ytd: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  mtd: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  quarter: [
    moment().startOf('quarter').subtract(1, 'quarter').format('YYYY-MM-DD'),
    moment().endOf('quarter').subtract(1, 'quarter').format('YYYY-MM-DD'),
  ],
  lastyear: [
    moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
    moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
  ],
}
