import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, LineChart } from 'recharts'
import { Select } from 'shared/components'
import Colors from 'shared/constants/colors'
import { ifDecimalRoundToNum, formatStatisticsValues } from 'utils/helpers'
import StatisticsTable from '../StatisticsTable'
import chartLabels from './statisticsChartLabels'
import './StatisticsChart.scss'
import { captureSentryError } from 'utils/helpers'

class StatisticsChart extends Component {
  selectChartDropdown = () => {
    const { onSelectChart, selectedChart } = this.props
    const options = [
      {
        label: 'Projects by Revenue',
        value: 'revenue',
      },
      {
        label: 'Projects by Quantity',
        value: 'quantity',
      },
      {
        label: 'Projects by Close Ratio',
        value: 'closeratio',
      },
    ]
    return (
      <Select
        className="statistics-chart__chart-select-dropdown"
        small
        width={230}
        defaultValue={selectedChart}
        options={options}
        onSelect={onSelectChart}
      />
    )
  }

  toolTipContent = (content) => {
    const { selectedChart } = this.props
    const { label, payload } = content

    const values = payload
      ? payload.map((point, i) => {
          const { dataKey, color, payload } = point
          const value = payload[dataKey]

          return (
            <div key={i} className="statistics-chart__tooltip__content">
              <div
                className="statistics-chart__tooltip__content__circle"
                style={{ backgroundColor: color }}
              ></div>
              {selectedChart === 'revenue'
                ? `$${Math.round(value).toLocaleString()} ${chartLabels.tooltip[dataKey]}`
                : selectedChart === 'quantity'
                ? `${value} ${chartLabels.tooltip[dataKey]}`
                : `${ifDecimalRoundToNum(value)}% ${chartLabels.tooltip[dataKey]}`}
            </div>
          )
        })
      : null

    return (
      <div className="statistics-chart__tooltip">
        <div className="statistics-chart__tooltip__header">{label}</div>
        {values}
      </div>
    )
  }

  renderChart = () => {
    const { selectedChart, perioddetail } = this.props

    switch (selectedChart) {
      case 'revenue':
        return (
          <>
            <h3 className="statistics-chart__title">Projects by Revenue</h3>
            <LineChart
              className="statistics-chart__line"
              width={1150}
              height={400}
              data={perioddetail}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                axisLine={false}
                dataKey="periodtitle"
                padding={{ left: 135, right: 35 }}
                tickMargin={32}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(v) => formatStatisticsValues(v)}
              />
              <Tooltip content={this.toolTipContent} />
              <Line
                dataKey="estimatesamount"
                stroke={Colors.bourbon}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                dataKey="soldamount"
                stroke={Colors.novaBlue}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </>
        )
      case 'quantity':
        return (
          <>
            <h3 className="statistics-chart__title">Projects by Quantity</h3>
            <BarChart
              className="statistics-chart__line"
              width={1150}
              height={400}
              data={perioddetail}
              barSize={20}
              barGap={0}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                axisLine={false}
                dataKey="periodtitle"
                padding={{ left: 95, right: -5 }}
                tickMargin={32}
                tickLine={false}
              />
              <YAxis axisLine={false} tickLine={false} tickFormatter={(v) => v.toLocaleString()} />
              <Tooltip content={this.toolTipContent} />
              <Bar dataKey="estimatescount" fill={Colors.bourbon} />
              <Bar dataKey="soldcount" fill={Colors.novaBlue} />
            </BarChart>
          </>
        )
      case 'closeratio':
        return (
          <>
            <h3 className="statistics-chart__title">Projects by Close Ratio</h3>
            <LineChart
              className="statistics-chart__line"
              width={1150}
              height={400}
              data={perioddetail}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                axisLine={false}
                dataKey="periodtitle"
                padding={{ left: 135, right: 35 }}
                tickMargin={32}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(v) => formatStatisticsValues(v)}
              />
              <Tooltip content={this.toolTipContent} />
              <Line
                dataKey="quotedratio"
                stroke={Colors.bourbon}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                dataKey="soldratio"
                stroke={Colors.novaBlue}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </>
        )
      default:
        return null
    }
  }
  render() {
    const { perioddetail } = this.props
    return (
      <div id="statistics-chart">
        {this.selectChartDropdown()}
        {perioddetail ? this.renderChart() : null}
        {perioddetail ? <StatisticsTable /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  try {
    const { selectedChart, statisticsData } = state.AdminStatsReducer
    return {
      selectedChart,
      perioddetail: statisticsData.perioddetail,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default connect(mapStateToProps)(StatisticsChart)
