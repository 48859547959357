import React, { memo } from 'react'
import { typedUseSelector } from 'utils/helpers'
import { SELECTED_COLOR, DEFAULT_COLOR } from '../constants'
import { IBuilderSectionData } from '../types/types'

interface Props extends Partial<IBuilderSectionData> {
  conveyorId: number;
  color: string;
  width: number;
}

function Straight(props: Props) {
  const { anchorAngleDeg, anchorPosition, length, id, conveyorId, color, width } = props
  const selectedSectionId = typedUseSelector(
    (state) => state.EstimatorMetaReducer[conveyorId].conveyorbuilder.selectedSectionId
  )

  return (
    <g
      id={`section-${id}`}
      className="draggable"
      fill={selectedSectionId === id.toString() ? SELECTED_COLOR : DEFAULT_COLOR}
      transform={`translate(${anchorPosition.x} ${anchorPosition.y})`}
    >
      <rect
        id={id as string}
        width={width}
        height={length}
        style={{ stroke: color, strokeWidth: '4px' }}
        transform={`rotate(${anchorAngleDeg * -1})`}
      />
    </g>
  )
}

export default memo(Straight)
