import React, { Component } from 'react'
export default class UserIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="1.5 3 29 29"
      >
        <path
          fill={color}
          d="M16.3513043,15.6869565 C13.1240404,15.6869565 10.5078261,13.0707422 10.5078261,9.84347826 C10.5078261,6.61621433 13.1240404,4 16.3513043,4 C19.5785683,4 22.1947826,6.61621433 22.1947826,9.84347826 C22.1947826,11.3932651 21.5791322,12.8795766 20.4832675,13.9754414 C19.3874027,15.0713061 17.9010911,15.6869565 16.3513043,15.6869565 Z M16.3513043,6.54608696 C14.5071535,6.54608696 13.0121739,8.04106657 13.0121739,9.88521739 C13.0121739,11.7293682 14.5071535,13.2243478 16.3513043,13.2243478 C18.1954552,13.2243478 19.6904348,11.7293682 19.6904348,9.88521739 C19.6904348,8.99962494 19.3386346,8.15030405 18.7124261,7.52409562 C18.0862177,6.89788719 17.2368968,6.54608696 16.3513043,6.54608696 Z M15.7669565,28 C12.4972154,27.9942175 9.3022678,27.0212108 6.58434783,25.2034783 L6,24.8278261 L6,22.9078261 C6,19.7356522 8.58782609,17.2730435 11.8434783,17.2730435 L20.066087,17.2730435 C23.28,17.2730435 25.6173913,19.6521739 25.6173913,22.9078261 L25.6173913,24.7026087 L25.1582609,25.0782609 C22.8626087,26.9147826 19.3565217,28 15.7669565,28 Z M8.50434783,23.4504348 C10.6928658,24.7781795 13.2072306,25.4717973 15.7669565,25.453913 C18.5217391,25.453913 21.2765217,24.7026087 23.1130435,23.4504348 L23.1130435,22.9078261 C23.1130435,21.0295652 21.9026087,19.7773913 20.066087,19.7773913 L11.8434783,19.7773913 C9.92347826,19.7773913 8.50434783,21.1130435 8.50434783,22.9078261 L8.50434783,23.4504348 Z"
        ></path>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g mask="url(#mask-2)" fill="none">
            <rect x="0" y="0" width="32" height="32"></rect>
          </g>
        </g>
      </svg>
    )
  }
}
