import { useEffect, useState } from 'react'
import { IGeneralSettingsRequest } from 'shared/types/swagger'
import ProjectService from 'utils/api/project/ProjectService'

export default function usePrefixes() {
  const [loading, setLoading] = useState<boolean>(true)
  const [prefixes, setPrefixes] = useState<IGeneralSettingsRequest>()
  const [error, setError] = useState<string>()

  async function handleSave(values: IGeneralSettingsRequest) {
    try {
      setLoading(true)
      const response = await ProjectService.updateGeneralSettings(values)
      setPrefixes(response)
      setLoading(false)
      return true
    } catch (error) {
      setError(error)
      setLoading(false)
      return false
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const _prefixes = await ProjectService.getGeneralSettings()
        setPrefixes(_prefixes)
        setLoading(false)
      } catch (error) {
        setError(error)
      }
    })()
  }, [])

  return { loading, error, prefixes, handleSave }
}
