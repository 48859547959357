import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import { updateWearstrips } from '../redux/default/WearstripsActions'
import { updateDefaultMaterialAcessoriesMetaData } from '../../../redux/EstimatorActions'
import wearStripsConfig from './WearStripsConfig'
import { Radio } from 'shared/components'
import { captureSentryError } from 'utils/helpers'

class Washdown extends Component {
  renderWearstripMaterialOptions = (config) => {
    const { dataKey, prettyName, options, rules, vertical } = config.materials
    const { updateWearstrips, conveyorId, isactive, islocked } = this.props
    return (
      <Radio
        key="default-materials-wearstrips-material"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 0 10px 0"
        options={options}
        onChange={(val) =>
          updateWearstrips({ conveyorId, updatedWearstripFields: { [dataKey]: val } })
        }
        rules={rules}
        vertical={vertical}
        initialValue={this.props.wearstripsData.materialid}
      />
    )
  }

  renderWearstripLubricatedOptions = (config) => {
    const { dataKey, prettyName, options, rules, vertical } = config.lubricated
    const { updateWearstrips, conveyorId, isactive, islocked } = this.props
    return (
      <Radio
        key="default-materials-wearstrips-lubricated"
        disabled={!isactive || islocked}
        dataKey={dataKey}
        prettyName={prettyName}
        margin="0 0 10px 0"
        options={options}
        onChange={(val) =>
          updateWearstrips({ conveyorId, updatedWearstripFields: { [dataKey]: val } })
        }
        rules={rules}
        vertical={vertical}
        initialValue={this.props.wearstripsData.lubricated}
      />
    )
  }

  render() {
    if (this.props.loading) {
      return null
    }
    const {
      wearstripsData: { parameteractive },
      wearstripsMeta: { open },
      updateWearstrips,
      conveyorId,
      updateDefaultMaterialAcessoriesMetaData,
      isLAProject
    } = this.props

    const config = wearStripsConfig(this.props)

    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="materials"
          fieldKey="wearstrips"
          checked={parameteractive}
          onClickCheck={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'materials',
              name: 'wearstrips',
              updatedFields: {
                open: !parameteractive,
              },
            })
            updateWearstrips({
              conveyorId,
              updatedWearstripFields: { parameteractive: !parameteractive },
            })
          }}
          open={parameteractive && open}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'materials',
              name: 'wearstrips',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Wearstrips"
        >
          {this.renderWearstripMaterialOptions(config)}
          { !isLAProject ? this.renderWearstripLubricatedOptions(config) : null}
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, match } = props
    const {
      params: { versionId },
    } = match
    const wearstripsData = EstimatorReducer?.[conveyorId]?.materials?.wearstrips
    const wearstripsMeta =
      EstimatorMetaReducer?.[conveyorId]?.materialsAccessoriesMetadata?.materials?.wearstrips || {}

    if (!wearstripsData) {
      return { loading: true }
    }

    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isLAProject = ProjectReducer.currentexternalprojecttype == 'la'

    return {
      versionId,
      conveyorId,
      wearstripsMeta,
      wearstripsData,
      wearstripsOptions: ProjectReducer.currentlistsettings.materials.wearstrips,
      isactive,
      islocked,
      isLAProject
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateWearstrips,
    updateDefaultMaterialAcessoriesMetaData,
  })(Washdown)
)
