type SVGScalingConfig = {
  length: number;
  scaleFactorPerChainWidth: number;
  svgLength: number;
  height: number;
  width: number;
}

type SVGScalingUtils = {
  scaleHeight: (value: number) => string;
  scaleWidth: (value: number) => string;
  /**
   * Scale the stroke width based on the chain width without exceeding or
   * supassing a min and max width.
   * @param multiplier default: 1. Higher = thicker
   */
  getStrokeWidth: (multiplier?: number) => number;
  /**
   * This is a WIP
   */
  scaleYByHeight: (position: number, height: number, scaleFactor: number) => number;
}

export default function useSVGScaling({
  length,
  scaleFactorPerChainWidth,
  svgLength,
  height,
  width,
}: SVGScalingConfig): SVGScalingUtils {
  const Y_SCALE_FACTOR = scaleFactorPerChainWidth / (svgLength / length)
  const X_SCALE_FACTOR =
    scaleFactorPerChainWidth / ((height * scaleFactorPerChainWidth) / (height * Y_SCALE_FACTOR))

  const scaleHeight = (value: number): string =>
    (value * X_SCALE_FACTOR * scaleFactorPerChainWidth).toFixed(4)
  const scaleWidth = (value: number): string =>
    (value * Y_SCALE_FACTOR * scaleFactorPerChainWidth).toFixed(4)
  const getStrokeWidth = (multiplier?: number) =>
    Math.min(
      Math.max(2, 4 * Math.pow(scaleFactorPerChainWidth, 2) * Y_SCALE_FACTOR) * (multiplier ?? 1),
      4 / Y_SCALE_FACTOR
    )
  const scaleYByHeight = (position: number, height: number, scaleFactor: number) =>
    position + height - Number(scaleHeight(height))

  return { scaleHeight, scaleWidth, getStrokeWidth, scaleYByHeight }
}
