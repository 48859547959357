import _ from 'lodash'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { message, Radio, Tooltip } from 'antd'
import {
  cloneConveyor,
  collapseConveyor,
  deleteConveyor,
  downloadConveyorFile,
  updateConveyor,
  updateCurrentTab,
  collapseAllConveyors,
} from 'features/Conveyor/redux/ConveyorActions'
import Chain from 'features/Chain'
import Product from 'features/Product'
import Estimator from 'features/Estimator'
import {
  fetchDefaultMaterialsForConveyor,
  fetchDefaultAccessoriesForConveyor,
} from 'features/Estimator/redux/EstimatorActions'
import { setCopiedProduct } from 'features/Version/redux/VersionActions'
import Colors from 'shared/constants/colors'
import { setFullScreenId, setLoading, openConfirmModal } from 'shared/redux/ScreenActions'
import { ActionMenu, Icon, TMIcon, GrabZone, InlineEdit } from 'shared/components'
import PDFService from 'utils/api/pdf/PDFService'
import { formatCurrency } from 'utils/helpers'
import './ConveyorCard.scss'
import cn from 'classnames'
import { captureSentryError } from 'utils/helpers'

class ConveyorCard extends Component {
  componentDidMount() {
    if (this.props.error) return

    const {
      conveyorId,
      metadata: { collapsed, conveyorFirstValidation },
      versionId,
    } = this.props

    if (!conveyorFirstValidation) {
      this.props.collapseConveyor({
        conveyorId,
        versionId,
        collapsed: true,
      })
    }

    if (!collapsed) {
      // have to get these up front for sections to know wether they have defaults or not :/
      if (!this.props.materialsData) {
        this.props.fetchDefaultMaterialsForConveyor(conveyorId)
      }
      if (!this.props.accessoriesData) {
        this.props.fetchDefaultAccessoriesForConveyor(conveyorId)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error) return

    const {
      conveyorId,
      metadata: { collapsed },
      materialsData,
      accessoriesData,
      fetchDefaultMaterialsForConveyor,
    } = this.props
    const {
      metadata: { collapsed: wasCollapsed },
    } = prevProps

    if (wasCollapsed && !collapsed) {
      if (!materialsData) {
        fetchDefaultMaterialsForConveyor(conveyorId)
      }
      if (!accessoriesData) {
        fetchDefaultAccessoriesForConveyor(conveyorId)
      }
    }
  }

  componentWillUnmount() {
    const { setFullScreenId } = this.props
    setFullScreenId(null)
  }

  coreSpecRadioButtons = () => {
    const { corespecmaterials, conveyor, updateConveyor, isactive, islocked } = this.props
    const materials = _.map(corespecmaterials, (material) => (
      <Radio key={material.id} value={material.id}>
        {material.title}
      </Radio>
    ))

    return (
      <div className="conveyor-card__header__firstrow__unit">
        <Radio.Group
          onChange={(e) => updateConveyor(conveyor.id, { materialid: e.target.value })}
          value={conveyor.materialid}
          name={`conveyormaterialid${conveyor.id}`}
          disabled={!isactive || islocked}
        >
          {materials}
        </Radio.Group>
      </div>
    )
  }

  renderTMIcon = () => {
    const { conveyorsections } = this.props.conveyor
    const data = {
      warnings: 0,
      cautions: 0,
    }
    _.forEach(conveyorsections, (section) => {
      const { length, ...rest } = section
      _.forEach(rest, (value) => {
        if (value === 'Caution') {
          data.cautions = data.cautions + 1
        }
        if (value === 'Warning') {
          data.warnings = data.warnings + 1
        }
      })
    })
    if (data.warnings > 0) {
      return TMIcon.Warning
    } else if (data.cautions > 0) {
      return TMIcon.Caution
    }
    return TMIcon.OK
  }

  handlePDFClick = async () => {
    const { conveyorId, versionId, projectId } = this.props
    this.props.setLoading({ loading: true, loadingMessage: 'Downloading...' })
    await PDFService.bendConveyor(conveyorId, projectId, versionId)
      .then(() => this.props.setLoading(false))
      .catch((e) => {
        this.props.setLoading(false)
        console.log(e)
      })
  }

  checkForDownloadValidation = (cb) => {
    const { validated, openConfirmModal } = this.props
    if (!validated) {
      openConfirmModal({
        headerText: 'Download Unavailable',
        bodyText:
          "The version needs to be validated before files can be downloaded.\nPlease go to 'Get Quote' and validate the price before trying again.",
        confirmButtonText: 'OK',
        cancelButton: false,
      })
    } else {
      cb()
    }
  }

  /** @returns {import('shared/components/ActionMenu').ActionMenuOption[]} */
  conveyorActionMenu = () => {
    const {
      conveyor: {
        name,
        productlength,
        productwidth,
        productheight,
        productweight,
        productrate,
        productmaterialtypeid,
        speed,
        temperature,
        frictiontop,
        frictionbottom,
        frictionload,
      },
      copiedProduct,
      conveyorId,
      collapseConveyor,
      collapseAllConveyors,
      cloneConveyor,
      deleteConveyor,
      downloadConveyorFile,
      isactive,
      islocked,
      setCopiedProduct,
      updateConveyor,
      versionId,
      permissions,
    } = this.props

    const canDownloadDocuments = permissions.includes('download_documents')

    /** @type {import('shared/components/ActionMenu').ActionMenuOption[]} */
    const availableOptions = canDownloadDocuments
      ? [
          {
            label: 'Master BOM',
            icon: 'download',
            handleSelect: () => {
              downloadConveyorFile(conveyorId, 'MasterBOM')
            },
          },
          {
            label: 'Preliminary BOM',
            icon: 'download',
            handleSelect: () => {
              this.checkForDownloadValidation(() => downloadConveyorFile(conveyorId, 'PrelimBOM'))
            },
          },
          {
            label: 'Horizontal Bend',
            icon: 'download',
            handleSelect: () => {
              this.handlePDFClick()
            },
          },
        ]
      : []

    if (isactive && !islocked) {
      availableOptions.unshift({
        label: 'Copy Product Information',
        icon: 'designer',
        handleSelect: () => {
          const payload = {
            productlength,
            productwidth,
            productheight,
            productweight,
            productrate,
            productmaterialtypeid,
            speed,
            temperature,
            frictiontop,
            frictionbottom,
            frictionload,
          }
          setCopiedProduct({ conveyorId, payload })
          message.success(`Copied "${name}" Product Information`, 5)
          collapseAllConveyors(versionId)
        },
      })
      availableOptions.unshift({
        label: 'Clone Conveyor',
        icon: 'clone',
        handleSelect: () => cloneConveyor(conveyorId),
      })
      availableOptions.push({
        label: 'Remove Conveyor',
        icon: 'delete',
        type: 'warning',
        handleSelect: () => deleteConveyor(conveyorId),
      })

      if (copiedProduct) {
        if (copiedProduct.conveyorId === conveyorId) {
          availableOptions.splice(1, 1)
        } else {
          availableOptions.splice(2, 0, {
            label: 'Paste Product Information',
            handleSelect: () => {
              const key = 'pasteRequest'
              message.loading({
                content: 'Pasting Copied Product Information...',
                key,
                duration: 0,
              })
              updateConveyor(conveyorId, copiedProduct.payload, false, false)
                .then(() => {
                  message.success({
                    content: 'Successfully Pasted Product Information',
                    key,
                    duration: 3,
                  })
                  collapseConveyor({ conveyorId, versionId, collapsed: false })
                  setCopiedProduct(null)
                })
                .catch(() =>
                  message.error({
                    content: 'Error Encountered While Attempting to Paste Product Information',
                    key,
                    duration: 3,
                  })
                )
            },
            icon: 'paste',
          })
        }
      }
    }

    return availableOptions
  }

  renderCurrentTab = () => {
    const { conveyorId, metadata } = this.props
    switch (metadata.currentTab) {
      case 'chain':
        return <Chain conveyorId={conveyorId} />
      case 'product':
        return <Product conveyorId={conveyorId} />
      case 'estimator':
        return <Estimator conveyorId={conveyorId} />
      default:
        return <Chain conveyorId={conveyorId} />
    }
  }

  renderHeaderFirstRow = () => {
    const {
      versioncostsvalid,
      collapseConveyor,
      conveyor,
      fullScreenId,
      metadata,
      updateConveyor,
      versionId,
      isactive,
      islocked,
      currentexternalprojecttype
    } = this.props
    const { collapsed } = metadata

    const collapseConveyorFxn = () =>
      collapseConveyor({
        conveyorId: conveyor.id,
        versionId,
        collapsed: !collapsed,
      })
    /** @param {React.KeyboardEvent} e */
    const handleKeyDown = (e) => {
      e.stopPropagation()
      if (e.key === ' ' || e.key === 'Enter') {
        collapseConveyorFxn()
      }
    }

    return (
      <div className="conveyor-card__header__firstrow">
        {fullScreenId ? null : (
          <Icon
            size={16}
            onClick={collapseConveyorFxn}
            onKeyDown={handleKeyDown}
            icon={collapsed ? 'plus' : 'minus'}
            color={collapsed ? Colors.ash : Colors.novaBlue}
            role="button"
            aria-controls={`cc-${conveyor.id}`}
            aria-expanded={!collapsed}
            tabIndex={0}
          />
        )}
        <div
          className={cn('conveyor-card__header__firstrow__title', {
            disabled: !isactive || islocked,
          })}
        >
          <InlineEdit
            text={conveyor.name}
            handleChange={(name) => updateConveyor(conveyor.id, { name })}
            id={`conveyor-title-${conveyor.id}`}
            disabled={!isactive || islocked}
          />
        </div>
        <div className="conveyor-card__header__firstrow__units">{this.unitRadioButtons()}</div>|
        <div className="conveyor-card__header__firstrow__corespecmaterials">
          {this.coreSpecRadioButtons()}
        </div>
        <div className="conveyor-card__header__firstrow__tm-status">
          <Tooltip title="Torque Master Status">
            {this.renderTMIcon()}
            TorqueMaster Status
          </Tooltip>
        </div>
        <div className="conveyor-card__header__firstrow__conveyor-cost">
          {currentexternalprojecttype == 'la' ? 'LA Project' : (versioncostsvalid ? formatCurrency(conveyor.netprice) : '$ - - -')}
        </div>
        <div className="conveyor-card__header__firstrow__action-menu">
          <ActionMenu options={this.conveyorActionMenu()} noTitle type="conveyor" />
        </div>
      </div>
    )
  }

  renderBottomFirstRow = () => {
    const { isDragDisabled } = this.props
    return (
      <div className="conveyor-card__bottom__firstrow">
        <div className="conveyor-card__bottom__firstrow__tabs">
          {this.renderTabs()}
          {isDragDisabled ? null : this.renderGrabzone()}
          {this.renderFullScreenButton(isDragDisabled)}
        </div>
      </div>
    )
  }

  renderGrabzone = () => {
    return <GrabZone horizontal />
  }

  renderFullScreenButton = (isDragDisabled) => {
    const { collapseConveyor, conveyorId, fullScreenId, setFullScreenId, versionId } = this.props
    return (
      <div
        className="fullscreen-button"
        onClick={() => {
          if (!fullScreenId) {
            setFullScreenId(conveyorId)
            collapseConveyor({
              conveyorId,
              versionId,
              collapsed: false,
            })
          } else {
            setFullScreenId(null)
          }
        }}
        style={isDragDisabled ? { marginLeft: 'auto' } : {}}
      >
        <Icon
          icon={conveyorId === fullScreenId ? 'minimize' : 'expand'}
          size={16}
          color={Colors.novaBlue}
        />
        {conveyorId === fullScreenId ? 'Exit Full Screen' : 'Full Screen'}
      </div>
    )
  }

  renderTabs = () => {
    const { conveyorId, metadata, updateCurrentTab, versionId, conveyor } = this.props
    const tabs = ['chain', 'product', 'estimator']

    const allowedTabs = metadata
      ? metadata.allowedTabs
      : {
          chain: true,
          product: false,
          estimator: false,
        }
    const completedTabs = metadata
      ? metadata.completedTabs
      : {
          chain: true,
          product: false,
          estimator: false,
        }
    const currentTab = metadata ? metadata.currentTab : 'chain'

    const steps = tabs.map((tab, i) => {
      const isCurrent = tab === currentTab
      const isDisabled = !allowedTabs[tab]
      const isCompleted = completedTabs[tab]
      return (
        <div
          key={`conveyor-card-step-${i}-${conveyorId}`}
          className={cn(
            `step ${tab}`,
            { current: isCurrent },
            { disabled: isDisabled },
            { completed: isCompleted }
          )}
          onClick={() => {
            if (allowedTabs[tab]) {
              updateCurrentTab({
                conveyorId,
                currentTab: tab,
                versionId,
              })
            }
          }}
          data-testid={`tab${tab}`}
        >
          <div className="circle">
            {isCompleted && !isCurrent ? (
              <Icon icon="check" color={Colors.novaBlue} size={10} />
            ) : (
              <div className="number">{i + 1}</div>
            )}
          </div>
          {_.capitalize(tab) + 
            (tab=='chain' 
             ? '\u00A0'+(conveyor.unit == 'Metric' ? conveyor.chain.widthmetric+'\u00A0mm' : conveyor.chain.widthenglish+'\u00A0inches') 
             : tab=='estimator' 
               ? '\u00A0'+ conveyor.lengthwithunit.replace(' ','\u00A0').toLowerCase() 
               : '')}
        </div>
      )
    })
    return <div className="custom-steps">{steps}</div>
  }

  unitRadioButtons = () => {
    const { conveyor, conveyorId, updateConveyor, isactive, islocked } = this.props
    const { unit } = conveyor
    const unitOptions = ['English', 'Metric'].map((unit) => (
      <Radio key={unit} value={unit}>
        {unit}
      </Radio>
    ))

    return (
      <div className="conveyor-card__header__firstrow__unit">
        <Radio.Group
          onChange={(e) => updateConveyor(conveyorId, { unit: e.target.value })}
          value={unit}
          name={`conveyorunit${conveyorId}`}
          disabled={!isactive || islocked}
        >
          {unitOptions}
        </Radio.Group>
      </div>
    )
  }

  render() {
    const { conveyorId, fullScreenId, metadata, error } = this.props

    if (error) return null

    const { collapsed } = metadata

    return (
      <div
        className={cn(
          'conveyor-card',
          `conveyor-id-${conveyorId}`,
          { 'full-screen': conveyorId === fullScreenId },
          { 'conveyor-card--open': !collapsed }
        )}
        id={`cc-${conveyorId}`}
        role="region"
        aria-labelledby={`conveyor-title-${conveyorId}`}
        tabIndex={-1}
      >
        <div className="conveyor-card__header">{this.renderHeaderFirstRow()}</div>
        <div className="conveyor-card__bottom">{this.renderBottomFirstRow()}</div>
        <div
          id={`cc-${conveyorId}`}
          hidden={collapsed}
          style={{ visibility: collapsed ? 'hidden' : 'visible' }}
        >
          <div className="conveyor-card__content">{this.renderCurrentTab()}</div>
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { conveyorId, match } = props
    const { versionId } = match.params
    const { projectId, versions, currentlistsettings, currentexternalprojecttype } = state.ProjectReducer

    if (!(versionId in versions)) {
      return { isactive: false, islocked: true, error: true }
    }

    const { isactive, islocked, validated } = versions[versionId]
    const {
      copiedProduct,
      validatePrice: { versioncostsvalid },
    } = state.VersionReducer

    return {
      materialsData:
        state.EstimatorReducer[conveyorId] && state.EstimatorReducer[conveyorId].materials,
      accessoriesData:
        state.EstimatorReducer[conveyorId] && state.EstimatorReducer[conveyorId].accessories,
      copiedProduct,
      conveyor: state.ConveyorReducer.conveyors[conveyorId],
      corespecmaterials: currentlistsettings.materials.conveyor.materials,
      fullScreenId: state.ScreenReducer.fullScreenId,
      isactive,
      islocked,
      metadata: state.ConveyorMetaReducer[versionId][conveyorId],
      versioncostsvalid,
      versionId,
      projectId,
      permissions: state.UserReducer.permissions,
      validated,
      currentexternalprojecttype,
    }
  } catch (error) {
    console.log(error)
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  cloneConveyor,
  collapseConveyor,
  collapseAllConveyors,
  deleteConveyor,
  downloadConveyorFile,
  setCopiedProduct,
  setFullScreenId,
  updateConveyor,
  updateCurrentTab,
  fetchDefaultMaterialsForConveyor,
  fetchDefaultAccessoriesForConveyor,
  setLoading,
  openConfirmModal,
}

const connectedConveyorCard = connect(mapStateToProps, mapDispatchToProps)(ConveyorCard)
export default withRouter(connectedConveyorCard)
