import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import { updateFixedendstop } from '../redux/default/FixedEndstopActions'
import FixedEndstopConfig from './FixedEndstopConfig'
import { Radio, InputWithValidation } from '../../../../../shared/components'
import { updateDefaultMaterialAcessoriesMetaData } from '../../../redux/EstimatorActions'
import { captureSentryError } from 'utils/helpers'

class FixedEndstop extends Component {
  renderFixedEndstopMountingLocationField = (config) => {
    const { dataKey, prettyName, options, rules } = config.mountinglocation
    const { isactive, islocked, updateFixedendstop, conveyorId, fixedEndstopOptions } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Radio
          key="default-accessories-fixedendstop-mountinglocation"
          disabled={!isactive || islocked}
          dataKey={dataKey}
          prettyName={prettyName}
          margin="0 0 10px 0"
          options={options}
          doNotShowImages
          onChange={(val) =>
            updateFixedendstop({ conveyorId, updatedFixedendstopFields: { [dataKey]: val } })
          }
          rules={rules}
          vertical
          initialValue={this.props.fixedendstopData.mountinglocationid}
        />
        <img
          style={{ marginLeft: '40px' }}
          src={
            fixedEndstopOptions.mountinglocations[
              Object.keys(fixedEndstopOptions.mountinglocations)[0]
            ].image
          }
          alt="mounting location"
        />
      </div>
    )
  }

  renderFixedEndstopTOCtoCenterlineField = () => {
    const {
      fixedendstopData,
      conveyorId,
      updateFixedendstop,
      isactive,
      islocked,
      isMetric,
    } = this.props
    return (
      <span>
        <InputWithValidation
          disabled={!isactive || islocked}
          label="Distance TOC to Centerline of T Rail"
          placeholder="Enter Distance TOC"
          id="distancetoc"
          name="distancetoc"
          small
          required
          addonAfter={isMetric ? 'mm' : 'in'}
          containerStyle={{
            width: '200px',
          }}
          rules={[
            {
              required: true,
              message: 'Distance TOC is required',
            },
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Distance TOC must be a number and greater than 0',
            },
          ]}
          type="text"
          defaultValue={fixedendstopData.distancetoc}
          onChange={(value) => {
            updateFixedendstop({
              conveyorId,
              updatedFixedendstopFields: { distancetoc: value },
            })
          }}
        />
      </span>
    )
  }

  render() {
    if (this.props.loading) {
      return null
    }

    const config = FixedEndstopConfig(this.props)
    const {
      fixedendstopData: { parameteractive },
      fixedendstopMeta: { open },
      updateFixedendstop,
      conveyorId,
      updateDefaultMaterialAcessoriesMetaData,
    } = this.props
    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="accessories"
          fieldKey="fixedendstop"
          checked={parameteractive}
          onClickCheck={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'fixedendstop',
              updatedFields: {
                open: !parameteractive,
              },
            })
            updateFixedendstop({
              conveyorId,
              updatedFixedendstopFields: { parameteractive: !parameteractive },
            })
          }}
          open={open}
          onClickOpen={() => {
            updateDefaultMaterialAcessoriesMetaData({
              conveyorId,
              type: 'accessories',
              name: 'fixedendstop',
              updatedFields: {
                open: !open,
              },
            })
          }}
          title="Fixed Endstop"
        >
          {this.renderFixedEndstopMountingLocationField(config)}
          {this.renderFixedEndstopTOCtoCenterlineField()}
        </DefaultHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      ConveyorReducer,
      EstimatorReducer,
      EstimatorMetaReducer,
      ProjectReducer,
    } = state
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props

    const fixedendstopData =
      EstimatorReducer[conveyorId] &&
      EstimatorReducer[conveyorId].accessories &&
      EstimatorReducer[conveyorId].accessories.fixedendstop
    const fixedendstopMeta =
      EstimatorMetaReducer[conveyorId].materialsAccessoriesMetadata.accessories.fixedendstop

    const { isactive, islocked } = ProjectReducer.versions[versionId]
    const isMetric = ConveyorReducer.conveyors[conveyorId].unit === 'Metric'

    if (!fixedendstopData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      fixedendstopMeta,
      fixedendstopData,
      fixedEndstopOptions: ProjectReducer.currentlistsettings.accessories.fixedendstop,
      isactive,
      islocked,
      isMetric,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateFixedendstop,
    updateDefaultMaterialAcessoriesMetaData,
  })(FixedEndstop)
)
