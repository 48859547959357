import React, { Component } from 'react'
import { Input as AntInput } from 'antd'
import { Icon } from '..'
import './Input.scss'
import Colors from '../../constants/colors'
import cn from 'classnames'
import { InputProps } from 'antd/lib/input'
import { IconKey } from '../Icon/iconsMap'
import { TextTransformName } from 'shared/types/TextTransform'
import { transformText } from 'utils/helpers/transformText'

/**
 * for each of these the first value is with a label; the second, without
 */
const iconPosition = {
  withLabel: {
    small: 34,
    medium: 37,
    large: 42,
  },
  withoutLabel: {
    small: 5,
    medium: 6,
    large: 10,
  },
}

interface Props extends Omit<InputProps, 'onChange' | 'defaultValue' | 'value'> {
  allowClear?: boolean
  className?: string
  defaultValue?: unknown
  disabled?: boolean
  icon?: IconKey
  label?: string
  large?: boolean
  small?: boolean
  margin?: string
  width?: number | string
  value?: string | string[] | number
  error?: boolean
  inputTextTransform?: TextTransformName
  onChange?: (value: string | number | boolean) => void
}

export default class Input extends Component<Props> {
  static defaultProps = {
    allowClear: false,
    disabled: false,
    large: false,
    small: false,
    margin: '',
    type: 'text',
  }

  state = {
    value:
      (this.props.value as string | string[] | number) ||
      (this.props?.defaultValue as string | string[] | number),
  }

  getIconTopPosition = () => {
    const { label, large, small } = this.props
    const positions = iconPosition[label ? 'withLabel' : 'withoutLabel']
    if (small) return positions.small
    if (large) return positions.large
    // default is medium
    return positions.medium
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, pattern, inputTextTransform } = this.props
    let { value } = e.target
    if (pattern) {
      const regex = new RegExp(pattern)
      if (!regex.test(value)) {
        return
      }
    }
    
    if (inputTextTransform) value = transformText(value, inputTextTransform)

    if (onChange) {
      onChange(e.target.value)
    }
    this.setState({ value })
  }

  render() {
    const {
      allowClear,
      className,
      defaultValue,
      disabled,
      icon,
      label,
      large,
      small,
      margin,
      onChange,
      style,
      type,
      width,
      addonAfter,
      error,
      ...rest
    } = this.props
    const { value } = this.state

    const top = this.getIconTopPosition()
    const showLabel = Boolean(label)
    const showIcon = Boolean(icon) && !addonAfter && !(allowClear && value)

    return (
      <div className="tm-input" style={{ margin }}>
        {showLabel ? <div className="tm-input__label">{label}</div> : null}
        <AntInput
          allowClear={allowClear}
          className={cn({ small, large, medium: !(small || large), 'has-error': error }, className)}
          defaultValue={defaultValue as string | string[]}
          disabled={disabled}
          onChange={this.handleChange}
          style={{ ...style, width: width || '100%' }}
          type={type}
          addonAfter={addonAfter}
          value={value}
          {...rest}
        />
        {showIcon ? (
          <Icon
            icon={icon}
            color={Colors.deepNavy}
            size={24}
            style={{
              left: width ? `${Number(width) - 30}px` : 'auto',
              right: width ? 0 : '11px',
              top,
            }}
          />
        ) : null}
      </div>
    )
  }
}
