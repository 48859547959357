import React, { PureComponent } from 'react'
import { Form } from 'antd'

/**
 * @typedef {React.ElementType} ReactNode
 * @typedef {import('antd/lib/form').FormItemProps} FormItemProps
 *
 * @typedef {Object} ComponentProps
 * @prop {function} decorator
 * @prop {string|ReactNode} [label]
 *
 * @typedef {ComponentProps & FormItemProps} Props
 */

/**
 * @extends {PureComponent<Props>}
 */
export default class FormItem extends PureComponent {
  render() {
    const { decorator, children, ...props } = this.props

    return <Form.Item {...props}>{decorator(children)}</Form.Item>
  }
}
