import _ from 'lodash'
import { Input, Table } from 'antd'
import React, { Fragment, MouseEvent, useEffect, useState } from 'react'
import { withRouter, useHistory, useLocation, useParams } from 'react-router-dom'
import {
  getVersionOptions,
  downloadVersionFile,
  updateVersionDiscount,
} from 'features/Version/redux/VersionActions'
import { Button } from 'shared/components'
import { getPriceConfiguration } from 'shared/redux/ListActions'
import { formatCurrency, typedUseSelector } from 'utils/helpers'
import { getVersionWithAllDetails } from 'features/Version/redux/VersionActions'
import ValidateConveyors from './components/ValidateConveyors'
import ValidateOptions from './components/ValidateOptions'
import { projectTotalBreakdownConfig, projectTotalBreakdownData } from './projectBreakdownConfig'
import './ValidatePrice.scss'
import { selectIsExternalAndHasEZGuideOption } from './redux/selectors'
import { useAppDispatch } from 'shared/hooks/app'
import ConveyorSvg from 'features/Estimator/components/ConveyorBuilder/components/BuilderCanvas/components/ConveyorSvg'
import { SVG_CONTAINER_CLASSNAME } from 'features/Estimator/components/ConveyorBuilder/components/BuilderCanvas/constants'
import { getConveyorAndUploadSvgs } from 'features/Estimator/components/ConveyorBuilder/components/BuilderCanvas/helpers/exportConveyorImage'
import { updateVersion } from 'features/Project/redux/ProjectOperations'

function ValidatePrice() {
  const history = useHistory()
  const location = useLocation()
  const { projectId, versionId } = useParams<{ projectId: string; versionId: string }>()
  const version = typedUseSelector((state) => state.ProjectReducer.versions[versionId])
  const hasConveyorBuilderImage = typedUseSelector((state) => state.ProjectReducer.selectedProject?.hasconveyorbuilderimage)
  const projectName = typedUseSelector((state) => state.ProjectReducer.selectedProject?.name)
  const projectNumber = typedUseSelector((state) => state.ProjectReducer.selectedProject?.number)
  const validated = version?.validated
  const lastModifiedDate = typedUseSelector((state) => {
    const updateddate = state.ListReducer.mufupdates?.[0]?.updateddate
    return updateddate ? new Date(updateddate).toLocaleDateString() : undefined
  })
  const { conveyors, options } = typedUseSelector((state) => state.ConveyorReducer)
  const isExternalAndHasEZGuideOption = typedUseSelector(selectIsExternalAndHasEZGuideOption)
  const { permissions, roles } = typedUseSelector((state) => state.UserReducer)
  const [projectDiscount, setProjectDiscount] = useState<string>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!version) {
      dispatch(getVersionWithAllDetails(versionId, false)).catch(console.error)
    }
    dispatch(getVersionOptions()).catch(console.error)
    if (roles.includes('Admin')) {
      dispatch(getPriceConfiguration())
    }

    return () => {
      // if image needs to be produce for document, we do it if any conveyors has a flag conveyorbuilderimagesoutdated set to true
      // it is done on the cleanup that way we are sure all the hidden UI elements used for the generation are loaded
      if (Object.values(conveyors).filter((conveyor) => conveyor.conveyorbuilderimagesoutdated).length > 0 
              && hasConveyorBuilderImage) {
        getConveyorAndUploadSvgs(conveyors).catch(console.error)
      }
    }
  }, [dispatch, history, location.pathname, roles, version, versionId, conveyors])

  async function handleProjectDiscount() {
    await dispatch(updateVersionDiscount(projectDiscount))
    setProjectDiscount(null)
  }

  function renderTotals(values, label) {
    let net_price = 0

    _.forEach(values, (value) => {
      const valuenetprice = value.netprice ? value.netprice : 0
      net_price += valuenetprice
    })

    const grandTotal = label === 'GRAND TOTALS'
    const classNameBase = `validate-price__${grandTotal ? 'bottom' : 'middle'}__${
      grandTotal ? 'grand-totals' : 'subtotals'
    }`

    return (
      <div className={classNameBase}>
        <div className={`${classNameBase}__label`}>{label}</div>
        <div className={`${classNameBase}__netprice`}>{formatCurrency(net_price)}</div>
      </div>
    )
  }

  const renderProjectTotalBreakdown = () => {
    return (
      <Table
        rowKey={(record, i) => i.toString()}
        className="project-breakdown"
        rowClassName={(record) => 'project-breakdown-row'}
        columns={projectTotalBreakdownConfig({ permissions })}
        dataSource={projectTotalBreakdownData({ version })}
        pagination={false}
      />
    )
  }

  /** Uploads SVG and then  */
  async function handleValidateClick(e: MouseEvent<HTMLButtonElement>) {
    try {
      const button = e.currentTarget
      button.blur()
      await dispatch(updateVersion({ versionId, payload: { validated: true } }))
    } catch (error) {
      if (error instanceof Error) {
        // update in the event of a failed upload
        if (error.message === 'Upload failed') await dispatch(updateVersion({ versionId, payload: { validated: true } }))
      }
      console.log(`error on validate price click: ${error}`)
    }
  }

  const canExportPrice = permissions.includes('export_prices')
  const showOptions =
    permissions.includes('view_globaldiscountpercentage') || isExternalAndHasEZGuideOption

  if (!version) return null

  return (
    <div className="validate-price">
      <div className="validate-price__top">
        <div className="validate-price__top__left">
          <h1>Validate Price</h1>
          {canExportPrice ? (
            <div className="validate-price__export-price-button">
              <Button
                small
                text="Export Price"
                onClick={() =>
                  dispatch(downloadVersionFile(versionId, 'PriceValidation', projectId))
                }
              />
            </div>
          ) : null}
        </div>
        {version.isactive && !version.islocked ? (
          <div className="validate-price__top__right">
            {permissions.includes('edit_globaldiscountpercentage') &&
            permissions.includes('view_globaldiscountpercentage') ? (
              <div className="customer-discount">
                <label htmlFor="global-discount">Discount</label>
                <Input
                  addonAfter="%"
                  className="project-discount"
                  defaultValue={projectDiscount}
                  value={projectDiscount}
                  id="global-discount"
                  onChange={(e) => setProjectDiscount(e.target.value)}
                />
                <Button
                  small
                  text="Save"
                  disabled={projectDiscount === null}
                  onClick={handleProjectDiscount}
                />
              </div>
            ) : null}
            <div className="validate-price__validate-price-button">
              <Button
                small
                text="Validate Price"
                disabled={validated || !permissions.includes('validate_prices')}
                onClick={handleValidateClick}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="validate-price__topmid">
        <div className="project-name-number">
          <h2 className="project-name">{projectName}</h2>
          <h4 className="project-number">{projectNumber}</h4>
          <div className="version-number">v{version?.index}</div>
        </div>
        <div className="validate-price__topmid-muf">
          {lastModifiedDate ? (
            <h5>
              MUF Last Modified:{' '}
              <span className="validate-price__topmid-mufdate">{lastModifiedDate}</span>
            </h5>
          ) : (
            <h5>&nbsp;</h5>
          )}
        </div>
      </div>
      <div className="validate-price__middle">
        <ValidateConveyors
          totals={() => renderTotals(conveyors, ' Conveyors Sub Totals')}
          versionId={versionId}
        />
        {showOptions ? (
          <ValidateOptions
            totals={() => renderTotals(options, ' Options Sub Totals')}
            versionId={versionId}
          />
        ) : null}
        {renderProjectTotalBreakdown()}
      </div>
      <div className="validate-price__bottom">
        <div className="validate-price__validate-price-button">
          {version.isactive && !version.islocked ? (
            <Button
              small
              text="Validate Price"
              onClick={handleValidateClick}
              disabled={validated || !permissions.includes('validate_prices')}
            />
          ) : null}
        </div>
      </div>
      {/* Used to create svg for download */}
      <div tabIndex={-1} aria-hidden={true} className={`validate-price__invisible-container ${SVG_CONTAINER_CLASSNAME}`}>
        {Object.values(conveyors)?.map((conveyor, key) => (
          <Fragment key={key}>
            <ConveyorSvg conveyorId={conveyor.id} height='20000' width='20000'/>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default withRouter(ValidatePrice)