import React, { memo } from 'react'

interface Props {
  rotateCanvas: boolean;
  hasMicrospan: boolean;
  driveboxLocation?: 'Left' | 'Right';
  width: number;
}

const translationMap = (
  w: number
): {
  [rotateCanvas: string]: {
    [hasMicrospan: string]: {
      Left?: string;
      Right?: string;
      undefined?: string;
    };
  };
} => ({
  true: {
    // rotated
    true: {
      // is microspan
      Left: '-60, 250',
      Right: '-170, 250',
    },
    false: {
      // is not microspan
      undefined: '-60, 250',
      Left: '-60, 250',
      Right: '-60, 250',
    },
  },
  false: {
    // not rotated
    true: {
      // is microspan
      Left: `${w + 160}, 35`,
      Right: `${w + 40}, 35`,
    },
    false: {
      // is not microspan
      undefined: `${w + 40}, 35`,
      Left: `${w + 40}, 35`,
      Right: `${w + 40}, 35`,
    },
  },
})

function InfeedDescription(props: Props) {
  const { rotateCanvas = false, hasMicrospan = false, driveboxLocation, width } = props
  const translation = translationMap(width)[rotateCanvas.toString()][hasMicrospan.toString()][
    driveboxLocation
  ]
  const translationProperty = translation ? `translate(${translation})` : ''
  const transform = rotateCanvas
    ? `rotate(-90) ${translationProperty} scale(0.04)`
    : `${translationProperty} scale(0.04)`
  const textX = (() => {
    if (rotateCanvas) return '48'
    if (driveboxLocation === 'Left' && hasMicrospan) return width + 188
    return width + 68
  })()
  const textY = hasMicrospan && rotateCanvas && driveboxLocation === 'Right' ? '165' : '55'

  return (
    <React.Fragment>
      <g transform={transform}>
        <path
          fill="#FFF"
          stroke="none"
          d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
        ></path>
      </g>
      <text
        x={textX}
        y={textY}
        stroke="none"
        fill="white"
        dominantBaseline="middle"
        style={{ fontSize: '18px', fontFamily: 'Eurostile' }}
      >
        Direction of Product Flow
      </text>
    </React.Fragment>
  )
}

export default memo(InfeedDescription)
