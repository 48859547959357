import React, { Component } from 'react'
import { Input as AntInput, Form } from 'antd'
import { Icon } from '..'
import '../Input/Input.scss'
import Colors from '../../constants/colors'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

class InputWithValidation extends Component {
  componentDidMount() {
    const { validateFields } = this.props.form
    validateFields()
    this.debouncedChange = AwesomeDebouncePromise(
      this.debouncedChange.bind(this),
      700,
      (...args) => {}
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, defaultValue } = this.props
    const { resetFields } = form
    if (prevProps.defaultValue !== defaultValue) {
      resetFields()
    }
  }

  // intentionally not anon function
  debouncedChange(value, errors, values, dataKey) {
    this.props.onChange(value, errors, values, dataKey)
  }

  onChange = (e) => {
    const { form, dataKey, onChange } = this.props
    const { setFieldsValue, validateFields } = form
    const { name, value } = e.target
    setFieldsValue({ [name]: value })
    validateFields((errors, values) => {
      if (!errors && onChange) {
        this.debouncedChange(value, errors, values, dataKey)
      }
    })
  }

  render() {
    const {
      allowClear = false,
      className,
      defaultValue,
      disabled = false,
      form,
      icon,
      label,
      large,
      margin = '',
      name = 'giveInputNamePlease',
      onPressEnter = () => console.log('Input has no onPressEnter!'),
      placeholder,
      preTab,
      addonAfter,
      rules = [],
      small,
      style,
      type = 'input',
      width,
      containerStyle,
    } = this.props

    const { getFieldDecorator, getFieldValue } = form

    const iconPosition = {
      small: label ? 34 : 5,
      medium: label ? 37 : 6,
      large: label ? 42 : 10,
    }

    const top = small ? iconPosition.small : large ? iconPosition.large : iconPosition.medium

    const value = getFieldValue(name)

    return (
      <Form style={containerStyle}>
        <div className="tm-input" style={{ margin }}>
          <Form.Item label={label}>
            {getFieldDecorator(name, {
              rules,
              initialValue: defaultValue,
            })(
              <AntInput
                addonBefore={preTab}
                addonAfter={addonAfter}
                allowClear={allowClear}
                className={`
                ${small ? 'small' : large ? 'large' : 'medium'}
                ${className ? className : ''}
                `}
                name={name}
                disabled={disabled}
                onChange={this.onChange}
                onPressEnter={(e) => {
                  onPressEnter(e.target.value)
                }}
                placeholder={placeholder}
                style={{ ...style, width: width ? width : '100%' }}
                type={type}
              />
            )}
          </Form.Item>
          {icon && !addonAfter ? (
            allowClear && value ? null : (
              <Icon
                icon={icon}
                color={Colors.deepNavy}
                size={24}
                style={{
                  left: width ? `${width - 30}px` : 'auto',
                  right: width ? 0 : '11px',
                  top,
                }}
              />
            )
          ) : null}
        </div>
      </Form>
    )
  }
}
/**
 * @type {React.ComponentClass<Pick<import('antd/lib/form').FormComponentProps, any>, any>}
 */
const formInputWithValidation = Form.create({ name: 'input-w-validation' })(InputWithValidation)

export default formInputWithValidation
