import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'shared/components'
import ModalContent from 'features/Modal/components/ModalContent'
import { setModalContent } from 'shared/redux/ScreenActions'
import ProjectService from 'utils/api/project/ProjectService'
import { useDispatch } from 'react-redux'
import './ProjectSettingsModal.scss'
import projectSettingsConfig from './projectSettingsConfig'

/**
 * @typedef {import('antd/lib/form/Form').WrappedFormInternalProps['form']} FormProp
 *
 * @typedef {Object} Props
 * @prop {FormProp} form
 *
 * @param {Props} props
 */
function ProjectSettings(props) {
  const { form } = props
  const [projectPrefix, setProjectPrefix] = useState('')
  const [sparePartPrefix, setSparePartPrefix] = useState('')
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      try {
        const _projectPrefix = await ProjectService.getProjectPrefix()
        setProjectPrefix(_projectPrefix)
        const _sparePartPrefix = await ProjectService.getSparePartProjectPrefix()
        setSparePartPrefix(_sparePartPrefix)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    })()
  }, [])

  const closeModal = () => dispatch(setModalContent(null))

  /** @param {React.FormEvent} e */
  function handleSubmit(e) {
    e.preventDefault()
    form.validateFields(async (errors, values) => {
      if (errors) return
      setLoading(true)
      try {
        if (values.projectPrefix) {
          await ProjectService.updateProjectPrefix(values.projectPrefix)
        }
        if (values.sparePartPrefix) {
          await ProjectService.updateSparePartProjectPrefix(values.sparePartPrefix)
        }
        closeModal()
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    })
  }

  function renderForm() {
    return projectSettingsConfig({ projectPrefix, sparePartPrefix }).map((field) => {
      switch (field.type) {
        case 'input': {
          return (
            <Form.Item label={field.label}>
              {form.getFieldDecorator(field.key, {
                initialValue: field.value,
                rules: field.rules,
              })(<Input disabled={loading} />)}
            </Form.Item>
          )
        }
        default: {
          return null
        }
      }
    })
  }

  return (
    <ModalContent title="Project Settings" id="project-settings" width="454px" height="auto">
      <Form onSubmit={handleSubmit}>
        <ModalContent.Section title="Project Name" className="psm__section">
          {renderForm()}
        </ModalContent.Section>

        <ModalContent.Footer>
          <Button small secondary text="Cancel" onClick={closeModal} />
          <Button small disabled={loading} text="Save Settings" type="submit" />
        </ModalContent.Footer>
      </Form>
    </ModalContent>
  )
}

export default Form.create({ name: 'project-settings' })(ProjectSettings)
