export default function log({
  message = '',
  params = '',
  error = false,
  type = 'API',
  color = '',
}) {
  if (process.env.NODE_ENV === 'test') return
  const _color = (() => {
    if (color && typeof color === 'string') return color
    if (error) return '#DD1C1A'
    return '#39AA03'
  })()
  console.log(
    `%c${type}`,
    `font-size: 9px;background-color: ${_color};color: white;padding: 0.35em 0.5em 0.2em;border-radius: 0.25em;`,
    message,
    params
  )
}
