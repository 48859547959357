import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Steps } from 'antd'
import { selectEstimatorTab } from './redux/EstimatorActions'
import Accessories from './components/Accessories'
import Materials from './components/Materials'
import ConveyorBuilder from './components/ConveyorBuilder'
import Gearmotor from './components/Gearmotor'
import OldGearmotor from './components/Gearmotor/OldGearmotor'
import Legend from './components/Legend'
import StepHeader from './components/shared/StepHeader'
import './Estimator.scss'
import { captureSentryError } from 'utils/helpers'

const STEPS = [
  { title: 'Materials', component: Materials, innerTitle: 'Global/Default Materials' },
  { title: 'Accessories', component: Accessories, innerTitle: 'Global/Default Accessories' },
  { title: 'Conveyor Builder', component: ConveyorBuilder },
  { title: 'Gearmotor', component: Gearmotor }, 
  { title: 'Legend Fields', component: Legend },
]
const firstStepsIndex = 0
const lastStepsIndex = STEPS.length - 1

class Estimator extends Component {
  goToTab = (index) => {
    const { conveyorId, selectEstimatorTab } = this.props
    selectEstimatorTab({ conveyorId, currentTabIndex: index })
  }

  renderSteps = () => {
    const { currentTabIndex, permissions } = this.props
    const canViewLegend = permissions.includes('edit_legendfields')

    return (
      <Steps current={currentTabIndex || 0} className="estimator-steps">
        {STEPS.map((step, i) => {
          if (step.title === 'Legend Fields' && !canViewLegend) return null
          return (
            <Steps.Step
              key={step.title}
              className="estimator-steps__step"
              title={step.title}
              onClick={() => {
                this.goToTab(i)
              }}
            />
          )
        })}
      </Steps>
    )
  }

  renderEstimatorSubcomponent = () => {
    const { conveyorId, currentTabIndex = 0, permissions, hasnewgearmotor } = this.props    
    const step = STEPS[currentTabIndex]
    const InnerComponent = step.title !== 'Gearmotor' || (step.title === 'Gearmotor' && hasnewgearmotor) 
      ? step.component 
      : OldGearmotor

    const isFirst = currentTabIndex === firstStepsIndex
    const isLast = currentTabIndex === lastStepsIndex
    const goBack = isFirst ? null : () => this.goToTab(currentTabIndex - 1)
    const goForward = isLast ? null : () => this.goToTab(currentTabIndex + 1)

    const title = step.innerTitle || step.title

    if (title === 'Legend Fields' && !permissions.includes('edit_legendfields')) {
      this.goToTab(0)
    }

    return (
      <main>
        <StepHeader title={title} handleBackClick={goBack} handleNextClick={goForward} />
        <InnerComponent conveyorId={conveyorId} />
      </main>
    )
  }

  render() {
    const { isactive, islocked, style } = this.props
    return (
      <div
        className={`estimator-container ${isactive && !islocked ? '' : 'version-inactive'}`}
        style={style}
      >
        {this.renderSteps()}
        {this.renderEstimatorSubcomponent()}
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const {
      conveyorId,
      match: {
        params: { versionId },
      },
    } = props
    const { isactive, islocked, hasnewgearmotor } = state.ProjectReducer.versions[versionId]
    return {
      currentTabIndex: state.EstimatorMetaReducer[conveyorId].currentTabIndex,
      isactive,
      islocked,
      hasnewgearmotor,
      permissions: state.UserReducer.permissions,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = { selectEstimatorTab }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Estimator))
