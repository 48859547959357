import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Select } from 'shared/components'
import { updateVersionStatus } from 'features/Version/redux/VersionActions'
import Colors from 'shared/constants/colors'

/**
 * @typedef {import('features/Version/redux/VersionActions')['updateVersionStatus']} updateVersionStatus
 * @typedef {import('../../../redux/ProjectReducer').Version} Version
 *
 * @typedef {Object} Props
 * @prop {updateVersionStatus} updateVersionStatus
 * @prop {Version} version
 */

/** @extends PureComponent<Props> */
class ProjectStatusActions extends PureComponent {
  state = {
    statusId: null,
  }

  availableStatusOptions = () => {
    const { version } = this.props
    const { authorizedactions } = version
    if (authorizedactions) {
      return authorizedactions.map((action) => {
        return {
          label: action.name,
          value: action.id,
        }
      })
    }
    return []
  }

  updateStatus = async () => {
    const { statusId } = this.state
    this.props.updateVersionStatus(statusId)
    this.setState({ statusId: null })
  }

  componentDidUpdate(prevProps) {
    const prevActions = prevProps?.version?.authorizedactions
    const currentActions = this.props?.version?.authorizedactions
    if (prevActions?.length !== 1 && currentActions?.length === 1) {
      this.setState({ statusId: currentActions[0].id })
    } else if (
      prevActions?.length === 1 &&
      currentActions?.length === 1 &&
      prevActions[0].id !== currentActions[0].id
    ) {
      this.setState({ statusId: currentActions[0].id })
    } else if (prevActions?.length !== currentActions?.length) {
      if (currentActions?.length !== 1) {
        this.setState({ statusId: null })
      } else {
        this.setState({ statusId: currentActions[0].id })
      }
    }
  }

  render() {
    const {
      version: {
        authorizedactions,
        currentstatusname,
        validated,
        isactive,
        islocked,
        activationperiods,
      },
    } = this.props
    const { statusId } = this.state

    const actionsAmount = authorizedactions.length
    const noAuthorizedAction = !actionsAmount
    const authorizedActionText = (() => {
      if (actionsAmount === 1) return authorizedactions[0].name
      return 'Submit'
    })()
    const hasActivationPeriod = activationperiods?.length > 0
    const expirationDate = hasActivationPeriod
      ? new Date(activationperiods[activationperiods.length - 1].inactivationdate)
      : new Date()
    const disabledSelect = (() => {
      if (!isactive) return true
      if (islocked) return true
      if (currentstatusname === 'Sent to Customer') {
        const isExpired = expirationDate.getTime() < new Date().getTime()
        if (hasActivationPeriod && isExpired) {
          return true
        }
      }
      return false
    })()

    return (
      <>
        <div className="project-summary__secondrow__project-status">
          <Icon
            icon="circle"
            size={12}
            color={Colors.navy}
            svgStyle={{
              stroke: Colors.novaBlue,
              strokeWidth: 2,
              verticalAlign: 'inherit',
              marginRight: '4px',
            }}
          />
          {currentstatusname || 'Loading Status...'}
        </div>

        <div className="project-summary__secondrow__status-actions">
          {noAuthorizedAction || actionsAmount === 1 ? null : (
            <Select
              small
              disabled={disabledSelect}
              className="status-actions"
              options={this.availableStatusOptions()}
              placeholder="Please Select"
              width={220}
              style={{ margin: '0 16px 0 0' }}
              onSelect={(id) => {
                this.setState({ statusId: id })
              }}
              value={statusId}
            />
          )}

          {noAuthorizedAction ? null : (
            <Button
              small
              text={authorizedActionText}
              disabled={
                !statusId || (authorizedactions[0].name === 'Complete Estimate' && !validated)
              }
              onClick={this.updateStatus}
            />
          )}
        </div>
      </>
    )
  }
}

/** @param {Props} props */
const connectedProjectStatusActions = connect(null, { updateVersionStatus })(ProjectStatusActions)

export default connectedProjectStatusActions
