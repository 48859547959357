import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import { connect } from 'react-redux'
import { Checkbox } from 'antd'
import { resetMaterialOrAccessorySectionOverridesForConveyor } from '../../redux/EstimatorActions'
import './DefaultHeader.scss'
import './OverridesHeader.scss'
import './MaterialAccessoryItem.scss'
import cn from 'classnames'
import { captureSentryError } from 'utils/helpers'

const noMsg = (
  <div style={{ display: 'flex', fontSize: '14px' }}>
    <Icon icon="circle" size={14} style={{ marginRight: '3px' }} color={Colors.ash} />
    No defaults
  </div>
)
const yesMsg = (
  <div style={{ display: 'flex', fontSize: '14px' }}>
    <Icon icon="circle" size={14} style={{ marginRight: '3px' }} color={Colors.novaBlue} />
    Defaults have been set
  </div>
)
const noMsgOverriden = (
  <div style={{ display: 'flex', fontSize: '14px' }}>
    <Icon icon="caution" size={14} style={{ marginRight: '3px' }} color={Colors.bourbon} />
    This section has overrides
  </div>
)
export const yesMsgOverriden = (
  <div style={{ display: 'flex', fontSize: '14px' }}>
    <Icon icon="caution" size={14} style={{ marginRight: '3px' }} color={Colors.negative} />
    Defaults overridden
  </div>
)

export class DefaultHeaderRender extends Component {
  render() {
    const {
      checked,
      open,
      onClickCheck,
      onClickOpen,
      noExpandAllowed,
      title,
      children,
      isactive,
      islocked,
      isLeftRight,
      leftCheck,
      rightCheck,
      alwaysPresent,
      estimatorData,
      noReset,
      resetMaterialOrAccessorySectionOverridesForConveyor,
      fieldKey,
      conveyorId,
      type,
    } = this.props

    const renderEmpty = () => {
      return (
        <div
          className="reset-defaults"
          onClick={() =>
            resetMaterialOrAccessorySectionOverridesForConveyor({
              conveyorId,
              type,
              fieldKey,
              isLeftRight,
            })
          }
        >
          <Icon icon="delete" size={15} color={Colors.novaBlue} />
          Remove Overrides
        </div>
      )
    }

    const { leftId, leftChecked } = leftCheck || {}
    const { rightId, rightChecked } = rightCheck || {}
    const disabled = !isactive || islocked

    return (
      <>
        <div
          className={`default-header ${
            !noExpandAllowed && checked ? 'default-header--clickable' : ''
          }`}
          onClick={checked ? onClickOpen : undefined}
          style={isLeftRight ? { paddingBottom: '15px' } : {}}
        >
          {isLeftRight ? (
            <>
              <div className="left-label">L</div>
              <Checkbox
                disabled={disabled}
                checked={leftChecked}
                onChange={(val) => onClickCheck(val, leftId, 'left')}
                aria-label={`${title} left`}
              />
              <div className="right-label">R</div>
              <Checkbox
                disabled={disabled}
                checked={rightChecked}
                onChange={(val) => onClickCheck(val, rightId, 'right')}
                aria-label={`${title} right`}
              />
            </>
          ) : (
            <Checkbox
              checked={checked}
              disabled={alwaysPresent || disabled}
              onChange={onClickCheck}
              aria-label={title}
            />
          )}
          <div className="default-header__name">{title}</div>
          {alwaysPresent ? null : (
            <div className="default-header__status">
              <Icon
                size={14}
                icon={
                  estimatorData && estimatorData.sectionoverride
                    ? 'circleDot'
                    : checked
                    ? 'check'
                    : 'circle'
                }
                color={
                  estimatorData && estimatorData.sectionoverride
                    ? Colors.novaBlue
                    : checked
                    ? Colors.positive
                    : Colors.ash
                }
              />
              <div className="default-header__status-string">
                {estimatorData && estimatorData.sectionoverride
                  ? 'Section Overrides selected'
                  : `
                  ${checked ? '' : 'No '}
                  ${title}
                  ${title[title.length - 1] === 's' ? ' have ' : ' has '}
                  been added to this conveyor
                `}
              </div>
            </div>
          )}
          {!noReset && estimatorData.sectionoverride && isactive && !islocked ? renderEmpty() : ''}
          {(!noExpandAllowed && checked) || alwaysPresent ? (
            <Icon
              className="expandbutton"
              icon={open ? 'minus' : 'plus'}
              size={20}
              color={open ? Colors.novaBlue : Colors.ash}
            />
          ) : null}
        </div>
        {checked && open && children ? children : null}
      </>
    )
  }
}

const mapStateToProps2 = (state, props) => {
  const { EstimatorReducer, ProjectReducer } = state
  const { conveyorId, type, fieldKey, match, ...rest } = props
  const {
    params: { versionId },
  } = match
  const { isactive, islocked } = ProjectReducer.versions[versionId]

  return {
    ...rest,
    fieldKey,
    conveyorId,
    type,
    estimatorData: EstimatorReducer?.[conveyorId]?.[type]?.[fieldKey],
    isactive,
    islocked,
  }
}

export const DefaultHeader = withRouter(
  connect(mapStateToProps2, {
    resetMaterialOrAccessorySectionOverridesForConveyor,
  })(DefaultHeaderRender)
)

class SectionOverrideHeaderRender extends Component {
  itemHasDefaults = () => {
    const { estimatorData, isLeftRight, checked, leftCheck, rightCheck } = this.props

    const { leftChecked } = leftCheck || {}
    const { rightChecked } = rightCheck || {}

    if (isLeftRight && estimatorData) {
      if (estimatorData.left.parameteractive || estimatorData.right.parameteractive) {
        if (leftChecked || rightChecked || checked) {
          return yesMsgOverriden
        } else {
          return yesMsg
        }
      }
      if (leftChecked || rightChecked || checked) {
        return noMsgOverriden
      }
      return noMsg
    } else {
      if (estimatorData && estimatorData.parameteractive) {
        if (checked) {
          return yesMsgOverriden
        } else {
          return yesMsg
        }
      }
      if (checked) {
        return noMsgOverriden
      }
      return noMsg
    }
  }

  renderCheckboxes = () => {
    const {
      alwaysPresent,
      checked,
      isactive,
      islocked,
      isLeftRight,
      onClickCheck,
      leftCheck,
      rightCheck,
      showSingleCheckbox,
    } = this.props

    if (alwaysPresent) return null

    const { leftId, leftChecked } = leftCheck || {}
    const { rightId, rightChecked } = rightCheck || {}
    const shouldRenderLandR = isLeftRight && !showSingleCheckbox
    const disabled = !isactive || islocked

    if (shouldRenderLandR) {
      return (
        <>
          <div className="left-label">L</div>
          <Checkbox
            disabled={disabled}
            checked={leftChecked}
            onChange={(val) => onClickCheck(val, leftId, 'left')}
          />
          <div className="right-label">R</div>
          <Checkbox
            disabled={disabled}
            checked={rightChecked}
            onChange={(val) => onClickCheck(val, rightId, 'right')}
          />
        </>
      )
    }

    return (
      <Checkbox
        disabled={disabled}
        className="single-checkbox"
        checked={checked}
        onChange={onClickCheck}
      />
    )
  }

  render() {
    const {
      alwaysPresent,
      checked,
      children,
      noExpandAllowed,
      onClickOpen,
      open,
      title,
    } = this.props
    const clickable = (!noExpandAllowed || alwaysPresent) && checked
    const renderChildrenIfExpanded =
      (!noExpandAllowed || alwaysPresent) && checked && open ? children : null

    return (
      <>
        <div
          className={cn('overrides-header', 'sectionoverride', {
            'overrides-header--clickable': clickable,
          })}
          onClick={alwaysPresent || checked ? onClickOpen : undefined}
        >
          {this.renderCheckboxes()}
          <div className="overrides-header__name">{title}</div>
          <div className="overrides-header__status-string">{this.itemHasDefaults()}</div>
          {!(!noExpandAllowed && checked) ? null : (
            <Icon
              className="expandbutton"
              icon={open ? 'minus' : 'plus'}
              size={17}
              color={open ? Colors.novaBlue : Colors.ash}
            />
          )}
        </div>
        {renderChildrenIfExpanded}
      </>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, ProjectReducer } = state
    const { conveyorId, type, fieldKey, match, ...rest } = props
    const {
      params: { versionId },
    } = match
    const { isactive } = ProjectReducer.versions[versionId]

    return {
      estimatorData: EstimatorReducer?.[conveyorId]?.[type]?.[fieldKey],
      isactive,
      ...rest,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export const SectionOverrideHeader = withRouter(
  connect(mapStateToProps)(SectionOverrideHeaderRender)
)
