import React from 'react'
import { formatStatisticsValues } from '../../../../utils/helpers'

export const statisticsRowConfig = (tableType, perioddetail) => {
  const eClassName = 'statistics-table__legend estimates'
  const sClassName = 'statistics-table__legend sold'
  switch (tableType) {
    case 'revenue':
      const estimates = {
        dataTitle: (
          <>
            <div className={eClassName} />
            Estimates
          </>
        ),
      }
      const soldOrders = {
        dataTitle: (
          <>
            <div className={sClassName} />
            Sold Orders
          </>
        ),
      }
      perioddetail.forEach((p) => {
        estimates[p.periodtitle] = formatStatisticsValues(p.estimatesamount)
        soldOrders[p.periodtitle] = formatStatisticsValues(p.soldamount)
      })
      return [estimates, soldOrders]

    case 'quantity':
      const estimateQty = {
        dataTitle: (
          <>
            <div className={eClassName} />
            Estimates Qty
          </>
        ),
      }
      const soldQty = {
        dataTitle: (
          <>
            <div className={sClassName} />
            Sold Qty
          </>
        ),
      }
      perioddetail.forEach((p) => {
        estimateQty[p.periodtitle] = p.estimatescount
        soldQty[p.periodtitle] = p.soldcount
      })
      return [estimateQty, soldQty]

    case 'closeratio':
      const percentQuoted = {
        dataTitle: (
          <>
            <div className={eClassName} />
            Percent Quoted
          </>
        ),
        align: 'right',
      }
      const percentSold = {
        dataTitle: (
          <>
            <div className={sClassName} />
            Percent Sold
          </>
        ),
      }
      perioddetail.forEach((p) => {
        percentQuoted[p.periodtitle] = `${Math.round(p.quotedratio)}%`
        percentSold[p.periodtitle] = `${Math.round(p.soldratio)}%`
      })
      return [percentQuoted, percentSold]

    default:
      return []
  }
}

export const statisticsColumnConfig = (perioddetail) => {
  const formattedColumns = perioddetail.map((p) => {
    const { periodtitle } = p
    return {
      key: periodtitle,
      title: periodtitle,
      dataIndex: periodtitle,
      ellipsis: true,
      width: `${960 / perioddetail.length}px`,
      align: 'center',
    }
  })

  return [
    {
      key: 'dataTitle',
      title: '',
      dataIndex: 'dataTitle',
      width: '150px',
    },
    ...formattedColumns,
  ]
}

export const statisticsTableTotals = (tableType, statisticsTotals) => {
  const { estimates, sold } = statisticsTotals[tableType]

  switch (tableType) {
    case 'revenue':
      return (
        <>
          <div className="text first">
            Total Estimated: <strong>{formatStatisticsValues(estimates)}</strong>
          </div>
          <div className="text">
            Total Sold: <strong>{formatStatisticsValues(sold)}</strong>
          </div>
        </>
      )
    case 'quantity':
      return (
        <>
          <div className="text first">
            Total Estimated: <strong>{estimates}</strong>
          </div>
          <div className="text">
            Total Sold: <strong>{sold}</strong>
          </div>
        </>
      )
    case 'closeratio':
      return (
        <>
          <div className="text first">
            Total Quoted: <strong>{estimates}%</strong>
          </div>
          <div className="text">
            Total Sold: <strong>{sold}%</strong>
          </div>
        </>
      )
    default:
      return null
  }
}
