import React, { Component } from 'react'
export default class PlusIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="-2 -2 28 28"
      >
        <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
          <g transform="translate(-106.000000, -460.000000)">
            <g transform="translate(82.000000, 427.000000)">
              <g transform="translate(24.000000, 26.797784)">
                <g transform="translate(0.000000, 7.000000)">
                  <rect
                    stroke={color}
                    fill="#FFFFFF"
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="2"
                  ></rect>
                  <path
                    d="M11.4642857,11.4642857 L11.4642857,6.53571429 C11.4642857,6.23984746 11.7041332,6 12,6 C12.2958668,6 12.5357143,6.23984746 12.5357143,6.53571429 L12.5357143,11.4642857 L17.4642857,11.4642857 C17.7601525,11.4642857 18,11.7041332 18,12 C18,12.2958668 17.7601525,12.5357143 17.4642857,12.5357143 L12.5357143,12.5357143 L12.5357143,17.4642857 C12.5357143,17.7601525 12.2958668,18 12,18 C11.7041332,18 11.4642857,17.7601525 11.4642857,17.4642857 L11.4642857,12.5357143 L6.53571429,12.5357143 C6.23984746,12.5357143 6,12.2958668 6,12 C6,11.7041332 6.23984746,11.4642857 6.53571429,11.4642857 L11.4642857,11.4642857 Z"
                    fill={color}
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
