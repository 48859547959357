import * as React from 'react'
import { Breadcrumb } from 'antd'
import cn from 'classnames'
import { Icon } from 'shared/components'
import './Breadcrumbs.scss'
import { Link } from 'react-router-dom'

/**
 * @typedef {Object} Props
 * @prop {import('../utils/getBreadcumbsConfig').CrumbConfig[]} crumbConfig
 */

/** @param {import('antd/lib/breadcrumb').BreadcrumbProps & Props} props */
export default function Breadcrumbs(props) {
  const { crumbConfig } = props

  return (
    <Breadcrumb className="navbar__breadcrumbs">
      {crumbConfig.map(({ icon, text, value }, i) => {
        const isLastCrumb = crumbConfig.length - 1 === i
        return (
          <Breadcrumb.Item key={value}>
            {isLastCrumb ? (
              <span className={cn({ 'breadcrumb--last': isLastCrumb })}>
                {icon ? <Icon colorKey="novaBlue" icon={icon} size={14} /> : null}
                {text}
              </span>
            ) : (
              <Link to={value}>
                {icon ? <Icon colorKey="darkAsh" icon={icon} size={14} /> : null}
                {text}
              </Link>
            )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}
