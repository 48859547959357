import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { PURGE } from 'redux-persist'

/**
 * @typedef {import('shared/types/GuideRailsSideResponse').GuideRailsSideResponse} GuideRailsSideResponse
 *
 * @typedef {Object} EstimatorConveyorMaterial
 * @property {number} id
 * @property {boolean} parameteractive
 * @property {boolean} sectionoverride
 * @property {boolean} removedforsection
 *
 * @typedef {EstimatorConveyorMaterial} EstimatorConveyorSideframes
 * @property {number} materialid
 * @property {number} cleanouttypeid
 * @property {number} standardcolorid
 *
 * @typedef {EstimatorConveyorMaterial} EstimatorConveyorWearstrips
 * @property {number} materialid
 * @property {boolean} lubricated
 *
 * @typedef {Object} EstimatorConveyorMaterials
 * @property {EstimatorConveyorMaterial} washdown
 * @property {EstimatorConveyorSideframes} sideframes
 * @property {EstimatorConveyorWearstrips} wearstrips
 *
 *
 * @typedef {Object} AccessoryGuiderails
 * @property {GuideRailsSideResponse} left
 * @property {GuideRailsSideResponse} right
 * @property {boolean} sectionoverride
 * @property {boolean} guiderailsonsection
 *
 * @typedef {Object} DefaultSection
 * @property {number} id
 * @property {boolean} parameteractive
 * @property {boolean} sectionoverride
 * @property {boolean} removedforsection
 *
 * @typedef {DefaultSection} AccessoryDriptray
 * @property {number} materialid
 *
 * @typedef {DefaultSection} SidewallSide
 * @property {number} sideid
 * @property {number} materialid
 *
 * @typedef {Object} AccessorySidewalls
 * @property {boolean} sectionoverride
 * @property {boolean} sidewallsonsection
 * @property {SidewallSide} left
 * @property {SidewallSide} right
 *
 * @typedef {DefaultSection} AccessoryFixedEndstop
 * @property {number} mountinglocationid
 * @property {number} distancetoc
 * @property {string} distancetocunit
 *
 * @typedef AccessoryCeilingSupports
 * @extends DefaultSection
 * @property {Array} details
 * @property {boolean} doublestandoff
 * @property {number} materialid
 *
 * @typedef AccessoryFloorSupports
 * @extends DefaultSection
 * @property {Array} details
 * @property {boolean} doublestandoff
 * @property {number} materialid
 * @property {number} supportmountingid
 *
 * @typedef {Object} EstimatorConveyorAccessories
 * @property {AccessoryGuiderails} guiderails
 * @property {DefaultSection} underguard
 * @property {AccessoryDriptray} driptray
 * @property {AccessorySidewalls} sidewalls
 * @property {AccessoryFixedEndstop} fixedendstop
 * @property {AccessoryCeilingSupports} ceilingsupports
 * @property {AccessoryFloorSupports} floorsupports
 *
 * @typedef {Object} ConveyorMaterialAccessories
 * @property {EstimatorConveyorMaterials} materials
 * @property {EstimatorConveyorAccessories} accessories
 *
 * @typedef {Object} EstimatorSection
 * @property {import('shared/types/MaterialsDetailedResponse')} materials
 * @property {import('shared/types/AccessoriesDetailedResponse')} accessories
 *
 * @typedef {Object<number, ConveyorMaterialAccessories> & {sections: Object<number, EstimatorSection>}} EstimatorState
 */

/** @type {EstimatorState} */
const INITIAL_STATE = {
  // [ conveyorId ]: {
  //   materials: {
  //     washdown: { id: 4... },
  //     sideframe: { id: 324... },
  //     wearstrips: { id: 123... }
  //   },
  //   accessories: {
  //     underguard: { id: 567... }
  //   }
  // }
  sections: {
    // [ sectionId ]: {
    //   materials: {
    //     washdown: { id: 4... },
    //     sideframe: { id: 324... },
    //     wearstrips: { id: 123... }
    //   },
    //   accessories: {
    //     underguard: { id: 567... }
    //   }
    // }
  },
}

export default createReducer(INITIAL_STATE, {
  ////////////////////
  // DEFAULTS
  ////////////////////

  SET_DEFAULT_MATERIALS_DATA_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, materialData } = action.payload // [{conveyorId, materialKey, materialData}]
    if (!state[conveyorId]) {
      state[conveyorId] = {}
    }
    state[conveyorId].materials = materialData
  },
  SET_DEFAULT_MATERIAL_DATA_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, materialKey, materialData } = action.payload
    if (!state[conveyorId]) {
      state[conveyorId] = {}
    }
    if (!state[conveyorId].materials) {
      state[conveyorId].materials = {}
    }
    state[conveyorId].materials[materialKey] = materialData
  },
  SET_DEFAULT_ACCESSORY_DATA_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, accessoryKey, accessoryData } = action.payload
    if (!state[conveyorId]) {
      state[conveyorId] = {}
    }
    if (!state[conveyorId].accessories) {
      state[conveyorId].accessories = {}
    }
    state[conveyorId].accessories[accessoryKey] = accessoryData
  },
  SET_DEFAULT_ACCESSORIES_DATA_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, accessoryData } = action.payload
    if (!state[conveyorId]) {
      state[conveyorId] = {}
    }
    state[conveyorId].accessories = accessoryData
  },
  SET_DEFAULT_ACCESSORY_DATA_FOR_CONVEYORS: (state, action) => {
    for (let i = 0; i < action.payload.length; i++) {
      const accessoryPayload = action.payload[i]
      const { conveyorId, accessoryKey, accessoryData } = accessoryPayload
      if (!state[conveyorId]) {
        state[conveyorId] = {}
      }
      if (!state[conveyorId].accessories) {
        state[conveyorId].accessories = {}
      }
      state[conveyorId].accessories[accessoryKey] = accessoryData
    }
  },
  UPDATE_DEFAULT_MATERIAL_OR_ACCESSORY_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, materialKey, materialData, type, sideName } = action.payload
    if (sideName) {
      state[conveyorId][type][materialKey][sideName] = materialData
    } else {
      state[conveyorId][type][materialKey] = materialData
    }
  },
  UPDATE_DEFAULT_MATERIAL_OR_ACCESSORY_FIELD_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, materialKey, key, value, type, sideName } = action.payload
    if (sideName) {
      state[conveyorId][type][materialKey][sideName][key] = value
      if (key === 'parameteractive') {
        if (value) {
          state[conveyorId][type][materialKey].parameteractive = true
        } else {
          const oppositeSideName = sideName === 'left' ? 'right' : 'left'
          if (!state[conveyorId][type][materialKey][oppositeSideName].parameteractive) {
            state[conveyorId][type][materialKey].parameteractive = false
          }
        }
      }
    } else {
      state[conveyorId][type][materialKey][key] = value
    }
  },

  ////////////////////
  // SECTION OVERRIDES
  ////////////////////

  SET_MATERIAL_DATA_FOR_CONVEYOR_SECTION: (state, action) => {
    const { sectionId, materialName, materialData } = action.payload
    if (!state.sections[sectionId]) {
      state.sections[sectionId] = {}
    }
    if (!state.sections[sectionId].materials) {
      state.sections[sectionId].materials = {}
    }
    if (!state.sections[sectionId].accessories) {
      state.sections[sectionId].accessories = {}
    }
    state.sections[sectionId].materials[materialName] = materialData
  },
  SET_ACCESSORY_DATA_FOR_CONVEYOR_SECTION: (state, action) => {
    const { sectionId, accessoryName, accessoryData } = action.payload
    if (!state.sections[sectionId]) {
      state.sections[sectionId] = {}
    }
    if (!state.sections[sectionId].materials) {
      state.sections[sectionId].materials = {}
    }
    if (!state.sections[sectionId].accessories) {
      state.sections[sectionId].accessories = {}
    }
    state.sections[sectionId].accessories[accessoryName] = accessoryData
  },
  UPDATE_MATERIAL_OR_ACCESSORY_FOR_CONVEYOR_SECTION: (state, action) => {
    const { sectionId, type, materialKey, sideName, updatedFields } = action.payload
    if (sideName) {
      state.sections[sectionId][type][materialKey][sideName] = updatedFields
    } else {
      state.sections[sectionId][type][materialKey] = updatedFields
    }
  },
  UPDATE_MATERIAL_OR_ACCESSORY_FIELD_FOR_CONVEYOR_SECTION: (state, action) => {
    const { conveyorId, sectionId, materialKey, key, value, type, sideName } = action.payload
    if (sideName) {
      if (value) {
        state.sections[sectionId][type][materialKey][sideName][key] = value
      }
      if (key === 'parameteractive') {
        if (value) {
          state[conveyorId][type][materialKey].sectionoverride = true
        } else {
          const oppositeSideName = sideName === 'left' ? 'right' : 'left'
          if (!state.sections[sectionId][type][materialKey][oppositeSideName].parameteractive) {
            state[conveyorId][type][materialKey].sectionoverride = false
          }
        }
      }
    } else {
      if (value) {
        state.sections[sectionId][type][materialKey][key] = value
      }
      if (key === 'parameteractive') {
        if (value) {
          state[conveyorId][type][materialKey].sectionoverride = true
        } else {
          state[conveyorId][type][materialKey].sectionoverride = false
        }
      }
    }
  },
  DEACTIVATE_ALL_MATERIALS_ACCESSORIES_OVERRIDES_FOR_CONVEYOR: (state, action) => {
    const { conveyorId, fieldKey, isLeftRight, type, sections } = action.payload
    sections.forEach((sectionId) => {
      if (_.hasIn(state.sections[sectionId][type], `${fieldKey}`)) {
        if (isLeftRight) {
          state.sections[sectionId][type][fieldKey].left.parameteractive = false
          state.sections[sectionId][type][fieldKey].left.sectionoverride = false
          state.sections[sectionId][type][fieldKey].right.parameteractive = false
          state.sections[sectionId][type][fieldKey].right.sectionoverride = false
        } else {
          state.sections[sectionId][type][fieldKey].parameteractive = false
          state.sections[sectionId][type][fieldKey].sectionoverride = false
        }
      }
    })
    state[conveyorId][type][fieldKey].sectionoverride = false
  },
  [PURGE]: () => INITIAL_STATE,
})
