import React, { Component } from 'react'
export default class AccountManagerIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="0 0 37 36"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(0.361858, 0.000000)" fill={color}>
            <path d="M29.25,2.25 L6.75,2.25 C6.12773438,2.25 5.625,2.75273437 5.625,3.375 L5.625,32.625 C5.625,33.2472656 6.12773438,33.75 6.75,33.75 L29.25,33.75 C29.8722656,33.75 30.375,33.2472656 30.375,32.625 L30.375,3.375 C30.375,2.75273437 29.8722656,2.25 29.25,2.25 Z M27.84375,31.21875 L8.15625,31.21875 L8.15625,24.1523438 L11.5980469,24.1523438 C12.0058594,25.3054687 12.7230469,26.3425781 13.6757812,27.1300781 C14.8886719,28.1320313 16.425,28.6875 18,28.6875 C19.575,28.6875 21.1113281,28.1355469 22.3242188,27.1300781 C23.2769531,26.3425781 23.9941406,25.3054688 24.4019531,24.1523438 L27.84375,24.1523438 L27.84375,21.9375 L22.6265625,21.9375 L22.44375,22.8058594 C22.021875,24.9082031 20.1515625,26.4375 18,26.4375 C15.8484375,26.4375 13.978125,24.9082031 13.5527344,22.8058594 L13.3699219,21.9375 L8.15625,21.9375 L8.15625,4.78125 L27.84375,4.78125 L27.84375,31.21875 Z M11.25,11.9882812 L24.75,11.9882812 C24.9046875,11.9882812 25.03125,11.8617187 25.03125,11.7070312 L25.03125,10.0195312 C25.03125,9.86484375 24.9046875,9.73828125 24.75,9.73828125 L11.25,9.73828125 C11.0953125,9.73828125 10.96875,9.86484375 10.96875,10.0195312 L10.96875,11.7070312 C10.96875,11.8617187 11.0953125,11.9882812 11.25,11.9882812 Z M11.25,17.6132812 L24.75,17.6132812 C24.9046875,17.6132812 25.03125,17.4867188 25.03125,17.3320312 L25.03125,15.6445312 C25.03125,15.4898438 24.9046875,15.3632812 24.75,15.3632812 L11.25,15.3632812 C11.0953125,15.3632812 10.96875,15.4898438 10.96875,15.6445312 L10.96875,17.3320312 C10.96875,17.4867188 11.0953125,17.6132812 11.25,17.6132812 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}
