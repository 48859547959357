import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import ProjectService, { IRejectWithMessage } from 'utils/api/project/ProjectService'
import VersionService from 'utils/api/version/VersionService'
import { setLoading, openConfirmModal } from 'shared/redux/ScreenActions'
import { formatIdAsKey } from 'utils/helpers'
import { removeNotQueuedMasterVersionsMetadata } from 'features/Conveyor/redux/ConveyorActions'
import { batch } from 'react-redux'
import {
  updateProject,
  updateProjects,
  searchProjectsSuccess,
  selectProjectFromQueueOrSearch,
  updateVersions,
  updateMasterVersion,
  setProjectManually,
} from './ProjectActions'
import { getContacts, getCustomer, getCustomerUsers, saveLists } from 'shared/redux/ListActions'
import { Store } from 'srcReducer'
import { IAction, IContact, ICustomer, IProject, IVersion } from 'shared/types/swagger'
import { IProjectState } from './ProjectReducer'
import axios, { CancelTokenSource, AxiosError } from 'axios'
import { History } from 'history'
import { IVersionWithAutorizedActions } from 'features/Version/shared/types'
import states from 'shared/constants/states'

//   ____            _           _
//  |  _ \ _ __ ___ (_) ___  ___| |_
//  | |_) | '__/ _ \| |/ _ \/ __| __|
//  |  __/| | | (_) | |  __/ (__| |_
//  |_|   |_|  \___// |\___|\___|\__|
//                |__/

export const cloneProject = createAsyncThunk<
  void, 
  { projectId : number, sourcetab : string, cloneWithoutCustomer : boolean}, 
  { state: Store }
>(
  'ProjectReducer/cloneProject',
  async ({ projectId, sourcetab, cloneWithoutCustomer }, { dispatch, getState }) => {
    dispatch(setLoading({ loading: true, loadingMessage: 'Cloning Project...' }))
    await ProjectService.cloneProject(projectId, cloneWithoutCustomer).then((data) => {
      if (sourcetab == 'queue')
      {
        const projectQueueResults = { ...getState().ProjectReducer.projectQueueResults }
        projectQueueResults[data.id]=data
        dispatch(updateProjects({ projectQueueResults }))
      }
      else if (sourcetab == 'search')
      {
        const searchResults = { ...getState().ProjectReducer.searchResults }
        searchResults[data.id]=data
        dispatch(updateProjects({ searchResults }))
      }
    })
    dispatch(setLoading(false))
  }
)

export const deleteProject = createAsyncThunk<void, number, { state: Store }>(
  'ProjectReducer/deleteProject',
  (projectId, { dispatch, getState }) => {
    const queueProjects = { ...getState().ProjectReducer.projectQueueResults }
    const searchResults = { ...getState().ProjectReducer.searchResults }
    const isInQueue = projectId in queueProjects
    const isInSearchResults = projectId in searchResults
    const projectToDeleteName = isInQueue
      ? queueProjects[projectId]?.name
      : searchResults[projectId]?.name
    dispatch(
      openConfirmModal({
        headerText: `Delete Project: ${projectToDeleteName}`,
        bodyText: 'Deleting a project is irreversible. Are you sure?',
        onConfirm: async () => {
          if (isInQueue) {
            const _queueProjectsCopy = { ...queueProjects }
            delete _queueProjectsCopy[projectId]
            dispatch(updateProjects({ projectQueueResults: _queueProjectsCopy }))
          }
          if (isInSearchResults) {
            const _searchResultsCopy = { ...searchResults }
            delete _searchResultsCopy[projectId]
            dispatch(updateProjects({ searchResults: _searchResultsCopy }))
          }

          await ProjectService.deleteProject(projectId).catch((e) =>
          {
            if (isInQueue) {
              dispatch(updateProjects({ projectQueueResults:queueProjects }))
            }
            if (isInSearchResults) {
              dispatch(updateProjects({ searchResults }))
            }
          })
        },
      })
    )
  }
)

export const searchProjects = (currentPage: number, searchTerm: string) => async (dispatch) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Searching Database...' }))
  await ProjectService.searchProjects(currentPage, searchTerm)
    .then((data) =>
    {
      const results = {
        searchResults: formatIdAsKey(data.searchResults),
        searchTerm:data.searchTerm,
        currentSearchPage:data.currentSearchPage,
        totalSearchPages:data.totalSearchPages
      }
      dispatch(searchProjectsSuccess(results))
    })
    .catch((e) => {
      debugger
    })
  dispatch(setLoading(false))
}


export const submitProject = createAsyncThunk<
  Record<string, IProject>,
  { payload: Partial<IProject>; history?: History },
  { state: Store }
>(
  'ProjectReducer/submitProject',
  // WARNING: history below should be of
  // type History OR undefined, however it's only with History
  // read more https://www.typescriptlang.org/tsconfig#strictNullChecks
  async ({ payload, history }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading({ loading: true, loadingMessage: 'Submitting Project Information...' }))
    const {
      ProjectReducer: { selectedProject },
      ListReducer: { customers, contacts },
    } = getState()

    let data, isNewProject
    if (selectedProject) {
      // If existing project, update project
      const response = await ProjectService.updateProject(selectedProject?.id, payload)
      data = Object.values(response)[0]
    } else {
      // If no id, create a new project
      isNewProject = true
      try {
        const response = await ProjectService.createProject(payload)
        data = response as Partial<IProject>
      } catch (error) {
        dispatch(setLoading(false))
        if (axios.isAxiosError(error)) {
          const e = error as AxiosError
          // eslint-disable-next-line no-throw-literal
          return rejectWithValue({
            name: 'error',
            status: e.response.status,
            message: e.response.data as string,
            error: true,
          })
        } else {
          throw error
        }
      }
    }

    if (data as IProject) {
      const project = {
        ...data,
        customer: customers[data.customerid],
        contact: contacts[data.contactid]
      }

      dispatch(updateProject(project))
      if (isNewProject && history) {
        history.push(`/project/${project.id}/${project.masterversion.id}`)
      } else {
        dispatch(setLoading(false))
      }
      return data
    } else {
      dispatch(setLoading(false))
    }
  }
)

export const getProject = createAsyncThunk<IProject, number>(
  'ProjectReducer/getProject',
  (projectId, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const project = await ProjectService.getProject(projectId)

        const hasCustomer = Boolean(project.customerid)
        if (hasCustomer) {
          const customer = await dispatch(getCustomer(project.customerid))
          project.customer = customer
          const contacts = await dispatch(getContacts(project.customerid))
          if (project.contactid) {
            project.contact = contacts[project.contactid]
          }
          await dispatch(getCustomerUsers(project.customerid))
        } else {
          dispatch(saveLists({ customers: {} }))
          dispatch(saveLists({ contacts: {} }))
          dispatch(saveLists({ customerUsers: [] }))
        }

        resolve(project)
      } catch (error) {
        reject()
      }
    })
  }
)

export const loadProject = (projectId) => async (dispatch) => 
  {
   const wrappedProject = await dispatch(getProject(projectId))
   const _project = unwrapResult(wrappedProject)
   dispatch(setProjectManually(_project))
  }

// __     __            _
// \ \   / /__ _ __ ___(_) ___  _ __
//  \ \ / / _ \ '__/ __| |/ _ \| '_ \
//   \ V /  __/ |  \__ \ | (_) | | | |
//    \_/ \___|_|  |___/_|\___/|_| |_|

export const cloneVersion = createAsyncThunk<
  unknown,
  { projectId: number; versionId: number },
  { state: Store }
>('ProjectReducer/cloneVersion', async ({ projectId, versionId }, { dispatch, getState }) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Cloning Version...' }))
  await VersionService.cloneVersion(projectId, versionId)
    .then((data) => {
      const { versions } = getState().ProjectReducer
      dispatch(
        updateVersions({
          ...versions,
          [data.id]: data,
        })
      )
    })
    .catch((e) => {
      debugger
    })
  dispatch(setLoading(false))
})

export const flagSoldClonePublishVersion = createAsyncThunk<
  unknown,
  { projectId: number; versionId: number; soldActionId: number },
  { state: Store }
>('ProjectReducer/cloneVersion', async ({ projectId, versionId, soldActionId }, { dispatch, getState }) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Cloning Version...' }))
  // flag status old version
  await VersionService.updateVersionStatus(versionId, soldActionId)
    .then(async (dataOldVersionUpdate) => { 
      await VersionService.cloneVersion(projectId, versionId)
        .then(async (dataCloned) => {
          // get the new version with authorized actions
          await VersionService.getVersion(dataCloned.id)
          .then(async (dataGetNewVersion) => {
            // flag status new version
            await VersionService.updateVersionStatus(dataGetNewVersion.id, dataGetNewVersion.authorizedactions.find((action) => action.name == 'Project Published').id) 
              .then(async (dataGetNewVersionUpdatedStatus) => {
                // flag comment new version
                await VersionService.updateVersion(projectId, dataGetNewVersion.id, { notes: 'Design Version' }) 
                  .then(async (dataGetNewVersionUpdatedNotes) => {
                    const { versions } = getState().ProjectReducer
                    dispatch(
                      updateVersions({
                        ...versions,
                        [dataGetNewVersionUpdatedNotes.id]: dataGetNewVersionUpdatedNotes,
                        [dataOldVersionUpdate.id]: dataOldVersionUpdate,
                      })
                    )
                  })
                  .catch((e) => {
                    debugger
                  })
                })
              .catch((e) => {
                debugger
              })
            })
          .catch((e) => {
            debugger
          })
        })
        .catch((e) => {
          debugger
        })
      })
    .catch((e) => {
      debugger
    })
  dispatch(setLoading(false))
})

export const deleteVersion = createAsyncThunk<unknown, number, { state: Store }>(
  'ProjectReducer/deleteVersion',
  async (versionId, { dispatch, getState }) => {
    dispatch(
      openConfirmModal({
        bodyText: 'Deleting a project version is irreversible. Are you sure ?',
        onConfirm: async () => {
          const versions = { ...getState().ProjectReducer.versions }
          const versionToDelete = versions[versionId]
          delete versions[versionId]
          dispatch(updateVersions(versions))
          await VersionService.deleteVersion(versionId).catch((e) => {
            dispatch(
              updateVersions({
                ...versions,
                [versionId]: versionToDelete,
              })
            )
            debugger // Add Error Handling?
          })
        },
      })
    )
  }
)

export const getVersions = (projectId) => async (dispatch) => {
  dispatch(setLoading({ loading: true, loadingMessage: 'Loading Versions...' }))
  const data = await VersionService.getVersions(projectId)
  dispatch(updateVersions(data))
  dispatch(setLoading(false))
}

export const setMasterVersion = (projectId, versionId, oldVersionId) => async (dispatch) => {
  dispatch(updateMasterVersion({ projectId, versionId }))
  await VersionService.setMasterVersion(projectId, versionId).catch((e) => {
    dispatch(updateMasterVersion({ projectId, versionId: oldVersionId }))
    debugger
  })
}

// Updates ONE Version
export const updateVersion = createAsyncThunk<
  IVersion,
  { versionId: number | string; payload: Partial<IVersionWithAutorizedActions> },
  { state: Store }
>('ProjectReducer/updateVersion', async ({ versionId, payload }, { dispatch, getState }) => {
  const { selectedProject } = getState().ProjectReducer
  const { versions } = getState().ProjectReducer
  const oldVersion = versions[versionId]
  const updatedVersion = {
    ...oldVersion,
    ...payload,
  }
  dispatch(
    updateVersions({
      ...versions,
      [versionId]: updatedVersion,
    })
  )

  return new Promise(async (resolve, reject) => {
    try {
      const data = await VersionService.updateVersion(selectedProject?.id, versionId, payload)
      resolve(data)
    } catch (error) {
      dispatch(
        updateVersions({
          ...versions,
          [versionId]: oldVersion,
        })
      )
      reject(error)
    }
  })
})
