import React from 'react'
import CSVParser from 'papaparse'
import { SytelineResponsePlus } from '../types'
export { default as getColumns } from './getColumns'

export const getABCSorter = (key) => (a, b) => a?.[key]?.localeCompare?.(b?.[key])
export const getNumSorter = (key) => (a, b) => (a?.[key] || 0) - (b?.[key] || 0)

export const getLocaleDateString = (text) => new Date(text).toLocaleDateString()

export const getPartNumber = (record: SytelineResponsePlus) =>
  record.chainrodpartnumber || record.partnumber || ''

const getDiffString = (value) => `${value > 0 ? '+' : '-'} ${Math.abs(value).toFixed(2)}%`

export const getDiffValue = (cost, totalcost) =>
  totalcost === 0 ? 0 : (cost / totalcost) * 100 - 100

/**
 * @param {SytelineResponsePlus} item
 * @param {boolean} edited
 * @param {boolean} valueOnly returns a string instead of JSX
 * @returns {React.ReactNode}
 */
export function getDiff(item, edited, valueOnly = false) {
  const { updatedcost, freightcost, dutycost, totalcost, currentcost, sytelinecost } = item
  const _totalcost = totalcost - sytelinecost + currentcost
  if (edited) {
    const value = getDiffValue(updatedcost + freightcost + dutycost, _totalcost)
    if (!currentcost && updatedcost) {
      return valueOnly ? '+ 100%' : <b>+ 100%</b>
    }
    const diffString = getDiffString(value)
    return valueOnly ? diffString : <b>{diffString}</b>
  }
  return '-'
}

export function exportCSV(
  data: SytelineResponsePlus[],
  editedPartNumbers: Array<string>,
  editing: boolean
) {
  const csvString = CSVParser.unparse(
    data.map((record) => {
      const partNumber = record.chainrodpartnumber || record.partnumber
      const edited = editedPartNumbers.includes(partNumber)
      return {
        'Item Number': partNumber,
        Description: record.description,
        'U/M': record.unit,
        Source: record.source,
        Materal: `$ ${record.currentcost}`,
        'New Material': `$ ${record.updatedcost}`,
        Freight: `$ ${record.currentfreightcost}`,
        'New Freight': `$ ${record.freightcost}`,
        Duties: `$ ${record.currentdutycost}`,
        'New Duties': `$ ${record.dutycost}`,
        Total: `$ ${record.currenttotalcost}`,
        'New Total': `$ ${
          edited
            ? (record.updatedcost + record.dutycost + record.freightcost).toString()
            : record.totalcost
        }`,
        '% Diff': getDiff(record, edited, true),
        'Last Updated': getLocaleDateString(record.updateddate),
        Locked: record.excluded ? 'Y' : 'N',
      }
    })
  )
  const downloadUrl = window.URL.createObjectURL(new Blob([csvString], { type: 'text/csv' }))
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute(
    'download',
    `CostsExport${new Date().toLocaleDateString().replace(/[_/]/g, '-')}.csv`
  )
  link.click()
}
