import React, { PureComponent } from 'react'
import { Button } from 'shared/components'
import './StepHeader.scss'

/**
 * @typedef {Object} Props
 * @prop {Function} [handleBackClick]
 * @prop {Function} [handleNextClick]
 * @prop {string} title
 *
 * @extends {PureComponent<Props>}
 */
export default class StepHeader extends PureComponent {
  static defaultProps = {
    title: '',
  }

  render() {
    const { handleBackClick, handleNextClick, title } = this.props

    return (
      <section className="step-header">
        <h3 className="estimator-subtitle">{title}</h3>
        <div>
          {handleBackClick ? (
            <Button
              secondary
              small
              text="Back"
              margin="0 16px 0 0"
              width={108}
              onClick={handleBackClick}
            />
          ) : null}
          {handleNextClick ? (
            <Button small text="Next" width={108} onClick={handleNextClick} />
          ) : null}
        </div>
      </section>
    )
  }
}
