import * as React from 'react'
import { Alert as AntAlert } from 'antd'
import './Alert.scss'
import cn from 'classnames'

/** @param {import('antd/lib/alert').AlertProps} props */
export default function Alert(props) {
  const { className, ...restProps } = props
  return (
    <div className="spt-alert__container">
      <AntAlert {...restProps} className={cn(className, 'spt-alert')} />
    </div>
  )
}
