import { setDefaultMaterialsDataForConveyor } from '../../../../redux/EstimatorActions'

const initializematerials = (conveyorId, materialData) => async (dispatch) => {
  dispatch(
    setDefaultMaterialsDataForConveyor({
      conveyorId,
      materialData,
    })
  )
}

export { initializematerials }
