import { createAction, ActionCreatorWithPayload } from '@reduxjs/toolkit'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import { batch } from 'react-redux'
import store from 'utils/../reduxStore'
import _ from 'lodash'
import { initializematerials } from 'features/Estimator/components/Materials/redux/default'
import * as defaultAccessoryInitializeActions from '../components/Accessories/redux/default'
import { setLoading, setPendingResponse, deletePendingResponse } from 'shared/redux/ScreenActions'
import { formatConveyorsAndConveyorSections } from 'utils/api/conveyor/helpers'
import { saveConveyorToConveyors } from '../../Conveyor/redux/ConveyorActions'

/**
 * @typedef {import('features/Estimator/redux/EstimatorMetaReducer').AccMeta} AccMeta
 * @typedef {import('features/Estimator/redux/EstimatorMetaReducer').MatMeta} MatMeta
 * @typedef {import('features/Estimator/redux/EstimatorMetaReducer').MatAccMeta} MatAccMeta
 * @typedef {keyof AccMeta | keyof MatMeta} MatAccKeys
 */

//  ___           _
// |   \   __ _  | |_   __ _
// | |) | / _` | |  _| / _` |
// |___/  \__,_|  \__| \__,_|

export const setDefaultMaterialsDataForConveyor = createAction(
  'SET_DEFAULT_MATERIALS_DATA_FOR_CONVEYOR'
)
export const setDefaultMaterialDataForConveyor = createAction(
  'SET_DEFAULT_MATERIAL_DATA_FOR_CONVEYOR'
)
export const setDefaultAccessoryDataForConveyor = createAction(
  'SET_DEFAULT_ACCESSORY_DATA_FOR_CONVEYOR'
)
/** @type {ActionCreatorWithPayload<Array<{conveyorId: number, accessoryKey: string, accessoryData: any}>, 'SET_DEFAULT_ACCESSORY_DATA_FOR_CONVEYORS'>} */
export const setDefaultAccessoryDataForConveyors = createAction(
  'SET_DEFAULT_ACCESSORY_DATA_FOR_CONVEYORS'
)
export const setDefaultAccessoriesDataForConveyor = createAction(
  'SET_DEFAULT_ACCESSORIES_DATA_FOR_CONVEYOR'
)
export const updateDefaultMaterialOrAccessoryFieldForConveyor = createAction(
  'UPDATE_DEFAULT_MATERIAL_OR_ACCESSORY_FIELD_FOR_CONVEYOR'
)
export const updateDefaultMaterialOrAccessoryForConveyor = createAction(
  'UPDATE_DEFAULT_MATERIAL_OR_ACCESSORY_FOR_CONVEYOR'
)
export const decactivateAllMaterialsAccessoriesOverridesForConveyor = createAction(
  'DEACTIVATE_ALL_MATERIALS_ACCESSORIES_OVERRIDES_FOR_CONVEYOR'
)
export const deleteUnusedCoveyorsMetadata = createAction('REMOVE_NOT_CURRENT_COVEYOR_METADATA')

export const fetchDefaultMaterialsForConveyor = (conveyorId) => async (dispatch) => {
  dispatch(setPendingResponse(`defaultMaterials#${conveyorId}`))
  const defaultMaterials = await ConveyorService.getDefaultMaterialsForConveyor(conveyorId)

  dispatch(initializematerials(conveyorId, defaultMaterials))
  dispatch(deletePendingResponse(`defaultMaterials#${conveyorId}`))
}

export const fetchDefaultMaterialForConveyor = (conveyorId, type) => async (dispatch) => {
  dispatch(setPendingResponse(`defaultMaterials#${conveyorId}`))
  const materialData = await ConveyorService.getDefaultMaterialForConveyor(conveyorId, type)

  dispatch(
    setDefaultMaterialDataForConveyor({
      conveyorId,
      materialData,
      materialKey: type,
    })
  )
  dispatch(deletePendingResponse(`defaultMaterials#${conveyorId}`))
}

export const cloneMaterialOrAccessoryWithSidesDefaults = ({
  conveyorId,
  materialKey,
  type, // 'materials' or 'accessories'
  fromSideId,
  toSideId,
  sideName,
}) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState().EstimatorReducer[conveyorId][type][materialKey]
    let otherSideName
    if (sideName === 'left') {
      otherSideName = 'right'
    } else {
      otherSideName = 'left'
    }
    const oldState = state[otherSideName]
    const sideToCopyState = {
      ...store.getState().EstimatorReducer[conveyorId][type][materialKey][sideName],
    }
    sideToCopyState.sideid = oldState.sideid
    sideToCopyState.id = oldState.id

    dispatch(
      runUpdateDefaultMaterialOrAccessoryForConveyor({
        conveyorId,
        materialKey,
        updatedFields: sideToCopyState,
        type, // 'materials' or 'accessories'
        sideId: toSideId, // optional sideId for guiderails and sidewalls. Needed for the api call for items with sides.
        sideName: otherSideName, // optional sideName for guiderails and sidewalls. Needed for patching backend redux state.
        saveToBackend: false,
      })
    )

    try {
      await ConveyorService.copySideItemDefaultsForConveyor(
        conveyorId,
        materialKey,
        fromSideId,
        toSideId
      )
      dispatch(setLoading(false))
      resolve()
    } catch (error) {
      dispatch(setLoading(false))
      reject(error)
      // dispatch(runUpdateDefaultMaterialOrAccessoryForConveyor({
      //   conveyorId,
      //   materialKey,
      //   oldState,
      //   type, // 'materials' or 'accessories'
      //   sideId: toSideId, // optional sideId for guiderails and sidewalls. Needed for the api call for items with sides.
      //   sideName: otherSideName, // optional sideName for guiderails and sidewalls. Needed for patching backend redux state.
      //   saveToBackend: false
      // }))
    }
  })
}

export const fetchDefaultAccessoriesForConveyor = (conveyorId) => async (dispatch) => {
  const defaultAccessories = ConveyorService.getDefaultAccessoriesForConveyor(conveyorId)
  const ceilingSupports = ConveyorService.getCeilingSupportsForConveyor(conveyorId)
  const floorSupports = ConveyorService.getFloorSupportsForConveyor(conveyorId)
  const ceilingSupportsDetail = ConveyorService.getCeilingSupportsDetailsForConveyor(conveyorId)
  const floorSupportsDetails = ConveyorService.getFloorSupportsDetailsForConveyor(conveyorId)

  dispatch(setPendingResponse(`defaultAcc#${conveyorId}`))

  Promise.all([
    defaultAccessories,
    ceilingSupports,
    floorSupports,
    ceilingSupportsDetail,
    floorSupportsDetails,
  ]).then((data) => {
    const defaultAccessories = data[0]
    const ceilingSupportsData = data[1]
    const floorSupportsData = data[2]
    const ceilingSupportsDetailData = data[3]
    const floorSupportsDetailData = data[4]

    ceilingSupportsData.details = ceilingSupportsDetailData
    floorSupportsData.details = floorSupportsDetailData

    batch(() => {
      _.forEach(defaultAccessories, (accessoryData, accessoryName) => {
        const actionName = `initialize${accessoryName}`
        if (defaultAccessoryInitializeActions[actionName]) {
          dispatch(defaultAccessoryInitializeActions[actionName](conveyorId, accessoryData))
        }
      })
      dispatch(
        defaultAccessoryInitializeActions['initializefloorsupports'](conveyorId, floorSupportsData)
      )
      dispatch(
        defaultAccessoryInitializeActions['initializeceilingsupports'](
          conveyorId,
          ceilingSupportsData
        )
      )
    })

    dispatch(deletePendingResponse(`defaultAcc#${conveyorId}`))
  })
}

export const fetchDefaultAccessoryForConveyor = (conveyorId, type) => async (dispatch) => {
  if (!type) {
    throw new Error('type required for fetching accessory')
  }
  const actionName = `initialize${type}`
  if (!(actionName in defaultAccessoryInitializeActions)) {
    throw new Error('type is not valid')
  }
  await dispatch(setPendingResponse(`defaultAcc#${conveyorId}`))
  const defaultAccessoryData = await ConveyorService.getDefaultAccessoryForConveyor(
    conveyorId,
    type
  )
  await dispatch(defaultAccessoryInitializeActions[actionName](conveyorId, defaultAccessoryData))
  await dispatch(deletePendingResponse(`defaultAcc#${conveyorId}`))
}

export const runUpdateDefaultMaterialOrAccessoryForConveyor = ({
  conveyorId,
  materialKey,
  updatedFields,
  type, // 'materials' or 'accessories'
  sideId = null, // optional sideId for guiderails and sidewalls. Needed for the api call for items with sides.
  sideName = null, // optional sideName for guiderails and sidewalls. Needed for patching backend redux state.
  saveToBackend = true,
  cb // callback
}) => async (dispatch) => {
  const oldState = store.getState().EstimatorReducer[conveyorId][type][materialKey]
  batch(() => {
    _.forEach(updatedFields, (value, key) => {
      dispatch(
        updateDefaultMaterialOrAccessoryFieldForConveyor({
          key,
          value,
          conveyorId,
          materialKey,
          type,
          sideName,
        })
      )
    })
  })
  if (saveToBackend) {
    try {
      const data = await ConveyorService.updateDefaultMaterialOrAccessoryForConveyor(
        conveyorId,
        materialKey,
        updatedFields,
        type,
        sideId
      )
      const hasUpdatedConveyor = 'conveyorupdated' in data
      if (hasUpdatedConveyor) {
        const conveyorData = formatConveyorsAndConveyorSections([data.conveyorupdated])
        await dispatch(saveConveyorToConveyors(conveyorData[conveyorId]))
      }
      const { conveyorupdated, ...restUpdatedData } = data
      await dispatch(updateDefaultMaterialOrAccessoryForConveyor({
        conveyorId,
        materialKey,
        materialData: {
          ...oldState,
          ...restUpdatedData
        },
        type,
        sideName,
      }))
      if (cb) cb()
    } catch (e) {
      dispatch(
        dispatch(updateDefaultMaterialOrAccessoryForConveyor({
          conveyorId,
          materialKey,
          materialData: oldState,
          type,
          sideName,
        }))
      )
    }
  }
}

export const resetMaterialOrAccessorySectionOverridesForConveyor = ({
  conveyorId,
  fieldKey,
  type, // 'materials' or 'accessories'
  isLeftRight,
}) => async (dispatch) => {
  const oldState = store.getState().EstimatorReducer

  ConveyorService.resetOverridesForConveyor({
    conveyorId,
    fieldKey,
    type,
  }).then(() => {
    dispatch(
      decactivateAllMaterialsAccessoriesOverridesForConveyor({
        conveyorId,
        fieldKey,
        type,
        sections: Object.keys(oldState.sections),
        isLeftRight,
      })
    )
  })
}

//  __  __         _
// |  \/  |  ___  | |_   __ _
// | |\/| | / -_) |  _| / _` |
// |_|  |_| \___|  \__| \__,_|

export const selectEstimatorTab = createAction('SELECT_ESTIMATOR_TAB')
/** @type {ActionCreatorWithPayload<{conveyorId: number}, 'INITIALIZE_DEFAULT_MATERIAL_ACCESSORIES_METADATA'>} */
export const initializeDefaultMaterialsAcessoriesMetadata = createAction(
  'INITIALIZE_DEFAULT_MATERIAL_ACCESSORIES_METADATA'
)
/** @type {ActionCreatorWithPayload<{conveyorIds: Array<string>}, 'INITIALIZE_DEFAULT_MATERIAL_ACCESSORIES_METADATAS'>} */
export const initializeDefaultMaterialsAcessoriesMetadatas = createAction(
  'INITIALIZE_DEFAULT_MATERIAL_ACCESSORIES_METADATAS'
)
/** @type {ActionCreatorWithPayload<{conveyorId: number, type: 'materials' | 'accessories', name: MatAccKeys, updatedFields: MatAccMeta}, 'UPDATE_DEFAULT_MATERIAL_ACCESSORIES_META_DATA'>} */
export const updateDefaultMaterialAcessoriesMetaData = createAction(
  'UPDATE_DEFAULT_MATERIAL_ACCESSORIES_META_DATA'
)
