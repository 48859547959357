import React, { ReactNode } from 'react'
import cn from 'classnames'
import { AlertProps } from 'antd/lib/alert'
import { Alert } from '..'
import './PartsTableLayout.scss'

type Props = {
  className?: string
  id?: string
  children: ReactNode
  title: string
  top: ReactNode
  showAlert: boolean
  alertProps?: AlertProps
}

export default function PartsTableLayout(props: Props) {
  const { className, id, children, title, top, showAlert, alertProps } = props
  return (
    <main className={cn('parts-table-layout', className)} id={id}>
      <section className="parts-table-layout__top">
        <h1>{title}</h1>
        {top}
      </section>
      {showAlert ? <Alert {...alertProps} /> : null}
      <section className="parts-table-layout__mid">{children}</section>
    </main>
  )
}
