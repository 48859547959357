import React from 'react'
import ModalContent from 'features/Modal/components/ModalContent'
import { Button } from 'shared/components'
import { copy } from '../constants'
import { StatusContentProps } from '../types'

const IdleContent = ({
  close,
  addUserToProjectAsEstimator
}: StatusContentProps) => <>
  <ModalContent.Section title=''>
    <p className='estimator-modal__status-content'>{copy.idle}</p>
  </ModalContent.Section>
  <ModalContent.Footer>
    <Button 
      small 
      secondary 
      text='No'
      onClick={close}
    />
    <Button 
      small
      text='Yes'
      onClick={addUserToProjectAsEstimator}
      data-testid='no-estimator-add-current-user'
    />
  </ModalContent.Footer>
</>

export default IdleContent