import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Collapse } from 'react-collapse'
import BuilderList from './components/BuilderList'
import BuilderCanvas from './components/BuilderCanvas'
import { Button } from 'shared/components'
import './ConveyorBuilder.scss'
import TMResultsTable from './components/TMResultsTable'
import { Icon } from 'antd'
import Colors from 'shared/constants/colors'
import { getConveyor } from '../../../Conveyor/redux/ConveyorActions'

class ConveyorBuilder extends Component {
  state = {
    pendingRequest: false,
    tmresultsCollapsed: true,
  }

  componentDidMount() {
    const { conveyorId, getConveyor } = this.props
    getConveyor(conveyorId)
  }

  render() {
    const { conveyorId } = this.props
    const { pendingRequest, tmresultsCollapsed } = this.state

    return (
      <div className={`conveyorbuilder conveyor-${conveyorId}`}>
        <div className="conveyorbuilder__container">
          <BuilderList conveyorId={conveyorId} />
          <BuilderCanvas conveyorId={conveyorId} />
        </div>
        <div className="buttons-container">
          <Button
            secondary
            small
            text={`${tmresultsCollapsed ? 'Show' : 'Hide'} Torquemaster Results`}
            onClick={() => this.setState({ tmresultsCollapsed: !tmresultsCollapsed })}
            width={232}
          />
          {pendingRequest ? (
            <Icon className="tm-pending" type="loading" spin style={{ color: Colors.novaBlue }} />
          ) : null}
        </div>
        <Collapse isOpened={!tmresultsCollapsed}>
          <TMResultsTable conveyorId={conveyorId} />
        </Collapse>
      </div>
    )
  }
}

const mapDispatchToProps = { getConveyor }

export default connect(null, mapDispatchToProps)(ConveyorBuilder)
