import { AlertProps } from 'antd/lib/alert'
import { DOWNLOADING_STATES } from 'features/Admin/components/CostImport/types'

export const downloadingAlertProps: Record<DOWNLOADING_STATES, Partial<AlertProps>> = {
  [DOWNLOADING_STATES.ERROR]: {
    type: 'error',
    showIcon: true,
    description: 'Error downloading error log.',
  },
  [DOWNLOADING_STATES.LOADING]: {
    type: 'info',
    showIcon: true,
    description: 'Downloading error log...',
  },
  [DOWNLOADING_STATES.SUCCESS]: {
    type: 'success',
    showIcon: true,
    description: 'Successfully downloaded error log.',
  },
  [DOWNLOADING_STATES.DEFAULT]: {
    type: 'info',
    description: '',
  },
}
