import Colors from 'shared/constants/colors'

/**
 * 
 * @param isSelectedSection i.e. section.id === selectedSectionId 
 * @param cautions use countCautionAndWarnings()
 * @param warnings use countCautionAndWarnings()
 * @returns colors: white, negative, gold, or postive
 */
export function getStatusColor(
  isSelectedSection: boolean, 
  cautions: number, 
  warnings: number, 
) {
  if (isSelectedSection) {
    return Colors.white
  } else if (warnings) {
    return Colors.negative
  } else if (cautions) {
    return Colors.gold
  } else {
    return Colors.positive
  }
}