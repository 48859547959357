import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import ProjectCard from '../ProjectCard'
import './ProjectList.scss'
import { captureSentryError, typedUseSelector } from 'utils/helpers'
import { getProjects } from 'features/Project/redux/ProjectGetProjectsOperations'
import ProjectSearching from '../ProjectSearching'
import axios from 'axios'

const TWO_MINUTES = 120000
let cancelTokenSource

function ProjectList(props) {
  const { selectedStatus } = props
  const dispatch = useDispatch()
  const roles = typedUseSelector(state => state.UserReducer.roles)
  const queueProjects = typedUseSelector(state => state.ProjectReducer.projectQueueResults)
  const filterTerm = typedUseSelector(state => state.ProjectReducer.filterTerm)
  const currentFilterPage = typedUseSelector(state => state.ProjectReducer.currentFilterPage)
  const searchResults = typedUseSelector(state => state.ProjectReducer.searchResults)
  const tab = typedUseSelector(state => state.ProjectReducer.tab)
  const searchPending = typedUseSelector(state => state.ProjectReducer.searchPending)
  const searchTimestamp = typedUseSelector(state => state.ProjectReducer.searchTimestamp)

  useEffect(() => {
    if (tab === 'queue' && new Date().getTime() - searchTimestamp > TWO_MINUTES) {
      cancelTokenSource = axios.CancelToken.source()
      dispatch(getProjects({ cancelTokenSource,currentFilterPage,filterTerm,statusFilter:selectedStatus }))
    }

    if (!searchTimestamp) {
      dispatch(getProjects({ currentFilterPage,filterTerm,statusFilter:selectedStatus }))
    }

    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
        cancelTokenSource = axios.CancelToken.source()
      }
    }
  }, [])

  const renderProjectList = () => {
    let projects = tab === 'queue' ? queueProjects : searchResults

    if (projects) {
      if (tab === 'queue')
      {
        projects = _.filter(
          projects,
          (p) => true
        )
      }  
      else
      {
        projects = _.filter(
          projects,
          (p) => selectedStatus === 'All' || p.masterversion.currentstatusname === selectedStatus
        )
      }

      if (projects.length) {
        projects = _.orderBy(projects, 'modifieddatetime','desc')
        return _.map(projects, (project, i) => (
          <ProjectCard key={i} project={project} roles={roles} tab={tab}/>
        ))
      } else {
        return <p>No Matching Results</p>
      }
    } else if (!searchPending) {
      return <p>No Matching Results</p>
    } else {
      return null
    }
  }

  return (
    <div id="project-list" role="list" title="Projects List" data-tab={tab}>
      {tab === 'queue' ? (
        <ProjectSearching
          show={searchPending}
          searchTimestamp={searchTimestamp}
          getProjects={() => dispatch(getProjects({ cancelTokenSource,currentFilterPage,filterTerm,statusFilter:selectedStatus }))}
          cancelGetProjects={() => {
            cancelTokenSource.cancel()
            cancelTokenSource = axios.CancelToken.source()
          }}
        />
      ) : null}
      {renderProjectList()}
    </div>
  )
}

export default ProjectList
