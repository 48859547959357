import React, { memo, useMemo } from 'react'
import Colors from 'shared/constants/colors'
import { typedUseSelector } from 'utils/helpers'
import { SELECTED_COLOR } from '../../constants'
import { IBuilderSectionData } from '../../types/types'
import InfeedDescription from './components/InfeedDescription'
import getSVGPathByType from './helpers/getSVGPathByType'
import DriveSVG from './components/DriveSVG'
import IdlerSVG from './components/IdlerSVG'
import TransferGravityRollerSVG from './components/TransferGravityRollerSVG'
import { selectDriveTypeTitle, selectIsFeedDriveRHS } from '../../redux/builderCanvasSelectors'
import { selectTransfers } from 'shared/redux/ListSelectors'
import { selectConveyorBuilder } from 'features/Estimator/redux/EstimatorMetaSelectors'
import TransferMicrospanSvG from './components/TransferMicrospanSVG'
import { getDriveBoxLocationType } from 'features/Estimator/components/ConveyorBuilder/helpers/getDriveBoxLocationType'
import { ITransfersListMicrospan } from 'utils/api/list/types/transfers'
import getTransferTypeKey from 'features/Estimator/components/ConveyorBuilder/helpers/getTransferTypeKey'

interface Props extends Partial<IBuilderSectionData> {
  conveyorId: number;
  type: 'Infeed' | 'Discharge';
  width: number;
}

function InfeedDischarge(props: Props) {
  const { anchorAngleDeg, anchorPosition, id, type, conveyorId, length, width } = props
  const typeKey = type.toLowerCase() as 'infeed' | 'discharge'
  const { rotateCanvas, selectedSectionId } = typedUseSelector((state) =>
    selectConveyorBuilder(state, conveyorId)
  )

  const driveBoxLocationType = getDriveBoxLocationType(typeKey)
  const { feedtypeid, feeddriveorientationid, transfer, hastransfer } = typedUseSelector(
    (state) => state.ConveyorReducer.conveyors[conveyorId][typeKey]
  )
  const driveTypeTitle = typedUseSelector((state) =>
    selectDriveTypeTitle(state, typeKey, feedtypeid)
  )
  const { driveboxlocationid } = transfer ?? {}
  const transfers = typedUseSelector(selectTransfers)
  const isRHS = typedUseSelector((state) =>
    selectIsFeedDriveRHS(state, typeKey, feeddriveorientationid, driveTypeTitle)
  )
  const _length = driveTypeTitle !== 'None' ? length : 100
  const selected = selectedSectionId === id.toString()
  const transferType = transfers?.types?.[transfer?.transfertypeid]
  const transferTypeKey = getTransferTypeKey(transfers, transfer)
  const { title } = transferType ?? {}
  const transferTypeOptions = transfers[transferTypeKey] as ITransfersListMicrospan
  const driveboxLocations = transferTypeOptions?.[driveBoxLocationType]
  const driveboxLocation =
    (driveboxLocations?.[driveboxlocationid]?.title as 'Left' | 'Right') ?? 'Left'

  const transferSVG = useMemo(() => {
    if (!hastransfer) return null
    if (!title) return null
    const transferSVGProps = {
      selected,
      typeKey,
      anchorAngleDeg,
      driveboxLocation,
      driveTypeTitle,
      width,
    }
    const Component = title === 'Microspan' ? TransferMicrospanSvG : TransferGravityRollerSVG
    return <Component {...transferSVGProps} />
  }, [
    anchorAngleDeg,
    driveTypeTitle,
    driveboxLocation,
    hastransfer,
    selected,
    title,
    typeKey,
    width,
  ])

  return (
    <g
      id={`section-${id}`}
      fill={selected ? SELECTED_COLOR : 'transparent'}
      transform={`translate(${anchorPosition.x} ${anchorPosition.y})`}
    >
      {driveTypeTitle === 'Idler' && (
        <IdlerSVG
          selected={selected}
          anchorAngleDeg={anchorAngleDeg}
          typeKey={typeKey}
          width={width}
        />
      )}
      {driveTypeTitle === 'Drive' && (
        <DriveSVG
          selected={selected}
          isRHS={isRHS}
          anchorAngleDeg={anchorAngleDeg}
          typeKey={typeKey}
          width={width}
        />
      )}
      <path
        id={id as string}
        d={getSVGPathByType(type, _length, width)}
        style={{
          stroke: selected ? Colors.white : Colors.positive,
          strokeWidth: '4px',
        }}
        transform={`rotate(${anchorAngleDeg * -1})`}
      />
      {typeKey === 'infeed' ? (
        <InfeedDescription
          rotateCanvas={rotateCanvas}
          hasMicrospan={title === 'Microspan'}
          driveboxLocation={driveboxLocation}
          width={width}
        />
      ) : null}
      {driveTypeTitle !== 'None' ? transferSVG : null}
    </g>
  )
}

export default memo(InfeedDischarge)
