import http from 'utils/api/Client'
import { formatIdAsKey } from 'utils/helpers'
import { organizeSettingsList } from './ListModel'
import { getPartNumber } from 'features/Admin/components/CostImport/utils'
import {
  ISytelineResponse,
  IUserDetail,
  ICustomer,
  IContact,
  IDutyAndFreightResponse,
  IChainSerie,
  ITorqueArmBracketAndGussets,
  IPriceConfigrationResponse,
  IMiscellanousPartSelection,
  IPartsUpdateResponse,
} from 'shared/types/swagger'
import { IListResponse, IStatus } from './types'
import { IPriceValidationOptionCategory } from './types/priceValidationOptions'
import { keyBy } from 'lodash'

/**
 * @typedef {import('shared/types/SytelineResponse').SytelineResponse} SytelineResponse
 * @typedef {import('shared/types/swagger').IUserDetail} UserResponseItem
 * @typedef {import('shared/types/swagger').ICustomer} Customer
 * @typedef {import('shared/types/swagger').IContact} Contact
 * @typedef {import('shared/types/DutyAndFreightResponse').DutyAndFreightResponse} DutyAndFreightResponse
 * @typedef {import('shared/types/ChainSerie').ChainSerie} ChainSerie
 * @typedef {import('shared/types/TorqueArmBracketAndGussets').TorqueArmBracketAndGussets} TorqueArmBracketAndGussets
 * @typedef {import('shared/types/PriceConfigrationResponse').PriceConfigrationResponse} PriceConfigrationResponse
 * @typedef {import('shared/types/MiscellanousPartSelection').MiscellanousPartSelection} MiscellanousPartSelection
 */

const ListService = {
  async createUser(payload: unknown) {
    return await http
      .post<unknown, { data: IUserDetail }>('/Users', payload)
      .then(({ data }) => data)
  },
  async deleteUser(userId: string | number): Promise<boolean> {
    return await http.delete(`/Users/${userId}`).then((res) => {
      return res.status === 200
    })
  },
  async updateUser(userId: string, payload: unknown): Promise<boolean> {
    return await http.put(`/Users/${userId}`, payload).then((res) => {
      return res.status === 200
    })
  },
  async users() {
    return await http.get<IUserDetail[]>('/Users').then(({ data }) => formatIdAsKey(data))
  },
  async _externalUsers() {
    return await http
      .get<IUserDetail[]>('/Users/byrole/ExternalUser')
      .then(({ data }) => formatIdAsKey(data))
  },
  async externalUsers(searchTerm: string) {
    var queryString = ''
    if (searchTerm != '')
    {
      queryString = `?columncontains=firstname,lastname,username&contains=${searchTerm},${searchTerm},${searchTerm}&columnSearchOperator=Or`
    }
    return await http
      .get<IUserDetail[]>(`/Users/GetExternalUsers${queryString}`)
      .then(({ data }) => formatIdAsKey(data))
  },
  async internalUsers(searchTerm: string) {
    var queryString = ''
    if (searchTerm != '')
    {
      queryString = `?columncontains=firstname,lastname,username&contains=${searchTerm},${searchTerm},${searchTerm}&columnSearchOperator=Or`
    }
    return await http
      .get<IUserDetail[]>(`/Users/GetNonExternalUsers${queryString}`)
      .then(({ data }) => formatIdAsKey(data))
  },
  /** Gets a list of users for the given customer */
  async customerUsers(customerId: string | number) {
    return await http
      .get<Array<Partial<IUserDetail>>>(`Users/ForCustomer/${customerId}`)
      .then(({ data }) => data)  
  },
  async accountManagers() {
    return await http.get<IUserDetail>('/Users/byrole/AccountManager').then(({ data }) => data)
  },
  async estimators() {
    return await http.get<IUserDetail>('/Users/byrole/Estimator').then(({ data }) => data)
  },
  async designers() {
    return await http.get<IUserDetail>('/Users/byrole/Designer').then(({ data }) => data)
  },
  /** Searches internal users only by searchTerm inclusing in name, number or id */
  async searchCustomers(searchTerm: string) {
    return await http
      .get<ICustomer[]>(`/Customers?columncontains=name,number,id&contains=${searchTerm}`)
      .then(({ data }) => formatIdAsKey(data))
  },
  /** Get list of contacts for the given customer */
  async contacts(customerId: string | number) {
    return await http
      .get<IContact[]>(`/Customers/${customerId}/Contacts`)
      .then(({ data }) => formatIdAsKey(data))
  },
  async customer(customerId: string | number) {
    return await http.get<ICustomer>(`/Customers/${customerId}`).then(({ data }) => data)
  },
  async chains() {
    return await http.get<IChainSerie[]>('/Settings/chainserieswithdetails').then(({ data }) => {
      const dataWithIndex = data.map((chain, index) => ({
        ...chain,
        index,
      }))
      return formatIdAsKey(dataWithIndex)
    })
  },
  async conveyorSettingsRegular() {
    return await http
      .get<IListResponse[]>('/Settings/lists')
      .then(({ data }) => organizeSettingsList(data))
  },
  async conveyorSettingsLA() {
    return await http
      .get<IListResponse[]>('/Settings/listsla')
      .then(({ data }) => organizeSettingsList(data))
  },
  async getList(listname: string) {
    return await http.get<IListResponse>(`/settings/lists/${listname}`).then(({ data }) => data)
  },
  async statuses() {
    return await http.get<IStatus[]>('/Settings/workflows/status').then(({ data }) => data)
  },
  async priceValidationOptions() {
    return await http
      .get<IPriceValidationOptionCategory[]>('/Settings/Pricevalidations')
      .then(({ data }) => data)
  },
  async gearboxes() {
    return await http
      .get<ITorqueArmBracketAndGussets[]>('/Settings/gearboxes')
      .then(({ data }) => data)
  },
  async getPriceConfiguration() {
    return await http
      .get<IPriceConfigrationResponse>('/Settings/GetPriceConfiguration')
      .then(({ data }) => data)
  },
  async updatePriceConfiguration(payload) {
    return await http
      .post<IPriceConfigrationResponse>('Settings/UpdatePriceConfiguration', payload)
      .then(({ data }) => data)
  },
  async getMiscellanousPartSelection() {
    return await http
      .get<IMiscellanousPartSelection>('/Settings/GetMiscellanousPartSelection')
      .then(({ data }) => data)
  },
  async saveMiscellanousPartSelection(payload: unknown) {
    return await http
      .post<unknown, { data: unknown }>('Settings/UpdateMiscellanousPartSelection', payload)
      .then(({ data }) => data)
  },
  async downloadPriceHistory() {
    try {
      const response = await http.get('/Settings/MUFHistoryFile')
      const { data } = response

      const link = document.createElement('a')
      link.download = data.filename
      link.href = data.filecontent
      link.click()

      return true
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getSytelineCosts() {
    const response = await http.get<ISytelineResponse[]>('/Settings/GetCurrentSytelineCost')
    const { data } = response
    return formatIdAsKey(data.sort((a, b) => getPartNumber(a).localeCompare(getPartNumber(b))))
  },
  async getCurrentPartSettings() {
    const response = await http.get<IPartsUpdateResponse[]>('/settings/currentpartsettings')
    const { data } = response
    return keyBy(data, (part) => part.partnumberguid)
  },
  async updateCurrentPartSettings(payload: IPartsUpdateResponse[]) {
    const response = await http.post<string, { data: IPartsUpdateResponse[] }>(
      '/settings/currentpartsettings',
      payload
    )
    const { data } = response
    return keyBy(data, (part) => part.partnumberguid)
  },
  async updateSytelineCosts(payload: ISytelineResponse[]) {
    const response = await http.post<string, { data: ISytelineResponse[] }>(
      '/Settings/UpdateSytelineCost',
      payload
    )
    const { data } = response
    return formatIdAsKey(data.sort((a, b) => getPartNumber(a).localeCompare(getPartNumber(b))))
  },
  async getDutyAndFreight() {
    const response = await http.get<IDutyAndFreightResponse>('/Settings/dutyAndFreight')
    const { data } = response
    return data
  },
  async getSytelineCostUpdates() {
    const response = await http.get<ISytelineResponse[]>('/Settings/GetSytelineCostUpdates')
    const { data } = response
    return formatIdAsKey(data.sort((a, b) => getPartNumber(a).localeCompare(getPartNumber(b))))
  },
  async getCurrentSytelineErrorLog() {
    try {
      const response = await http.get('/Settings/GetCurrentSytelineErrorLog')
      const { data } = response
      const filenameRegex = /filename=([^;]+)/gim
      const [, /* whole match */ filename] = filenameRegex.exec(
        response.headers['content-disposition']
      )
      return [data, filename]
    } catch (error) {
      console.log(error)
      throw error
    }
  },
}

export default ListService
