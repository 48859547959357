import { createReducer } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { IConveyorMeta } from '../shared/types'

export interface IConveyorMetaState {
  [versionId: string]: {
    [conveyordId: string]: IConveyorMeta
  }
}

const INITIAL_STATE: IConveyorMetaState = {}

export default createReducer(INITIAL_STATE, {
  COLLAPSE_ALL_CONVEYORS: (state, action) => {
    const versionId = action.payload
    const conveyorIds = Object.keys(state[versionId])
    conveyorIds.forEach((conveyorId) => {
      state[versionId][conveyorId].collapsed = true
    })
  },
  COLLAPSE_CONVEYOR: (state, action) => {
    const { conveyorId, collapsed, versionId } = action.payload
    state[versionId][conveyorId].collapsed = collapsed
    state[versionId][conveyorId].conveyorFirstValidation = true
  },
  DELETE_CONVEYOR_METADATA: (state, action) => {
    const { conveyorId, versionId } = action.payload
    delete state[versionId][conveyorId]
  },
  UPDATE_CONVEYOR_METADATA: (state, action) => {
    const { conveyorId, metadata, versionId } = action.payload
    state[versionId][conveyorId] = metadata
  },
  UPDATE_COMPLETED_TABS: (state, action) => {
    const { conveyorId, completedTabs, versionId } = action.payload
    state[versionId][conveyorId].completedTabs = completedTabs
    if (completedTabs.chain) {
      state[versionId][conveyorId].allowedTabs.product = true
      if (completedTabs.product) {
        state[versionId][conveyorId].allowedTabs.estimator = true
      } else {
        state[versionId][conveyorId].allowedTabs.estimator = false
      }
    } else {
      state[versionId][conveyorId].allowedTabs.product = false
      state[versionId][conveyorId].allowedTabs.estimator = false
    }
  },
  UPDATE_CURRENT_TAB: (state, action) => {
    const { conveyorId, currentTab, versionId } = action.payload
    state[versionId][conveyorId].currentTab = currentTab
    state[versionId][conveyorId].collapsed = false
  },
  UPDATE_VERSION_METADATA: (state, action) => {
    const { metadata, versionId } = action.payload
    state[versionId] = metadata
  },
  REMOVE_NOT_QUEUED_MASTER_VERSIONS_METADATA: (state, action) => {
    const { versionIds } = action.payload
    const notQueuedMasterVersions = Object.keys(state).filter(
      (id) => !versionIds.includes(Number(id))
    )
    for (let i = 0; i < notQueuedMasterVersions.length; i++) {
      delete state[notQueuedMasterVersions[i]]
    }
  },
  [PURGE]: () => INITIAL_STATE,
})
