import React from 'react'

interface ModalSectionProps {
  children?: React.ReactNode;
  title: string;
  className?: string;
  noDivider?: boolean;
}

function ModalSection(props: ModalSectionProps) {
  const { children, title, className = '', noDivider = false, ...restProps } = props
  return (
    <>
      <section {...restProps} className={`modal-section ${className}`}>
        <h2 className="modal-section__title">{title}</h2>
        {children}
      </section>
      {!noDivider && <div className="modal-section__divider" />}
    </>
  )
}

export default ModalSection
