const PRETTYNAME_MAP = {
  guiderails: 'Guide rails',
  sidewalls: 'Side walls',
}

export default function overridesConfig(props, sectionName) {
  const data = props[`${sectionName}Data`]

  const { left, right } = data

  const leftActive = left.parameteractive
  const leftRemoved = left.removedforsection

  const rightActive = right.parameteractive
  const rightRemoved = right.removedforsection

  const sidesValue = []
  if (leftActive && !leftRemoved) sidesValue.push('L')
  if (rightActive && !rightRemoved) sidesValue.push('R')

  return [
    {
      dataKey: `${sectionName}onsection`,
      required: true,
      prettyName: `${PRETTYNAME_MAP[sectionName]} on this section?`,
      type: 'radio',
      value: Boolean(sidesValue.length).toString(),
      options: [
        { id: 'true', title: 'Yes' },
        { id: 'false', title: 'No' },
      ],
    },
    {
      dataKey: 'sides',
      prettyName: 'Which sides?',
      type: 'checkbox',
      value: sidesValue,
      options: [
        { id: 'L', title: 'Left' },
        { id: 'R', title: 'Right' },
      ],
    },
  ]
}
