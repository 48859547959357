import deg2rad from '../../../helpers/deg2rad'

export default function getAnnularSVGPath(
  startAngleArg: number,
  endAngleArg: number,
  radius: number,
  width: number
): string {
  const clockwise = endAngleArg < 0 ? false : true
  const outerRadius = radius
  const innerRadius = radius - width
  const centerX = clockwise ? outerRadius : 0
  const centerY = clockwise ? 0 : innerRadius

  const startAngle = clockwise
    ? deg2rad(startAngleArg + (180 - Math.abs(endAngleArg)))
    : deg2rad(startAngleArg + 270)
  const endAngle = clockwise ? deg2rad(180) : deg2rad(Math.abs(endAngleArg) + 270)

  const p = [
    [centerX + innerRadius * Math.cos(startAngle), centerY + innerRadius * Math.sin(startAngle)],
    [centerX + outerRadius * Math.cos(startAngle), centerY + outerRadius * Math.sin(startAngle)],
    [centerX + outerRadius * Math.cos(endAngle), centerY + outerRadius * Math.sin(endAngle)],
    [centerX + innerRadius * Math.cos(endAngle), centerY + innerRadius * Math.sin(endAngle)],
  ]

  const angleDiff = endAngle - startAngle,
    largeArc = angleDiff % (Math.PI * 2) > Math.PI ? 1 : 0

  const commands = []

  commands.push('M' + p[0].join())
  commands.push('L' + p[1].join())
  commands.push('A' + [outerRadius, outerRadius].join() + ' 0 ' + largeArc + ' 1 ' + p[2].join())
  commands.push('L' + p[3].join())
  commands.push('A' + [innerRadius, innerRadius].join() + ' 0 ' + largeArc + ' 0 ' + p[0].join())
  commands.push('z')

  return commands.join(' ')
}
