import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form, Input, Row, Col } from 'antd'
import { getLegendsFieldsForConveyor, updateLegendFieldsForConveyor } from './redux/LegendActions'
import { getGearmotorForConveyor } from '../Gearmotor/redux/GearmotorActions'
import ADP from 'awesome-debounce-promise'
import formConfig from './legendFieldsConfig'
import './Legend.scss'

class Legend extends PureComponent {
  state = {
    payload: {},
  }

  componentDidMount() {
    const { conveyorId } = this.props
    this.props.getLegendsFieldsForConveyor(conveyorId)
    this.props.getGearmotorForConveyor(conveyorId)
  }

  lumpUpdateInputs = () => {
    const {
      conveyorId,
      updateLegendFieldsForConveyor,
      form: { validateFields },
    } = this.props

    validateFields((errors, values) => {
      const payload = { ...this.state.payload }
      if (errors) {
        const keys = Object.keys(errors)
        keys.forEach((k) => delete payload[k])
      }
      if (Object.keys(payload).length) {
        updateLegendFieldsForConveyor(conveyorId, payload).then(() => {
          const newPayload = Object.keys(payload).reduce((acc, key) => {
            if (key in acc && acc[key] === payload[key]) {
              delete acc[key]
            }
            return acc
          }, this.state.payload)
          this.setState({ payload: newPayload })
        })
      }
    })
  }

  debouncedInputs = ADP(this.lumpUpdateInputs, 1500)

  renderForm = () => {
    const {
      form: { getFieldDecorator },
      isactive,
      islocked,
    } = this.props
    const disabled = !isactive || islocked
    const fieldSections = formConfig(this.props)

    return fieldSections.map((fieldSection) => (
      <>
        <h3 className="legend-fields-section__title">{fieldSection.sectionTitle}</h3>
        <Row gutter={16} type="flex">
          {fieldSection.fields.map((field, i) => {
            switch (field.type) {
              case 'input': {
                const handleChange = (e) => {
                  e.persist()
                  if (e.target.value === '') return
                  this.setState(
                    ({ payload }) => ({
                      payload: {
                        ...payload,
                        [field.key]: e.target.value,
                      },
                    }),
                    this.debouncedInputs
                  )
                }

                return (
                  <Col span={fieldSection.fieldSize || 6}>
                    <Form.Item key={i} label={field.prettyName}>
                      {getFieldDecorator(field.key, {
                        initialValue: field.value,
                      })(<Input disabled={disabled} name={field.key} onChange={handleChange} />)}
                    </Form.Item>
                  </Col>
                )
              }
              case 'textarea': {
                const handleChange = (e) => {
                  e.persist()
                  if (e.target.value === '') return
                  this.setState(
                    ({ payload }) => ({
                      payload: {
                        ...payload,
                        [field.key]: e.target.value,
                      },
                    }),
                    this.debouncedInputs
                  )
                }

                return (
                  <Col span={fieldSection.fieldSize || 6}>
                    <Form.Item key={i} label={field.prettyName}>
                      {getFieldDecorator(field.key, {
                        initialValue: field.value,
                      })(
                        <Input.TextArea
                          disabled={disabled}
                          name={field.key}
                          onChange={handleChange}
                          autoSize
                        />
                      )}
                    </Form.Item>
                  </Col>
                )
              }
              case 'text': {
                return (
                  <Col span={fieldSection.fieldSize || 6}>
                    <Form.Item key={i} label={field.prettyName}>
                      {getFieldDecorator(field.key, {
                        initialValue: field.value,
                      })(
                        <div className="legend-fields__text-form-item">
                          <div className="value">{field.value}</div>
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                )
              }
              default:
                return null
            }
          })}
        </Row>
      </>
    ))
  }

  render() {
    const { isactive, islocked } = this.props
    return (
      <Form
        className={`legend-fields ${isactive && !islocked ? '' : 'version-inactive'}`}
        colon={false}
      >
        {this.renderForm()}
      </Form>
    )
  }
}

function mapStateToProps(state, props) {
  const { conveyorId, match } = props
  const { versionId } = match.params
  const { isactive, islocked } = state.ProjectReducer.versions[versionId]
  const { gearmotor } = state.ConveyorReducer.conveyors?.[conveyorId] || {}
  const legendFields = state.LegendReducer?.[conveyorId]

  return {
    conveyorId,
    legendFields,
    isactive,
    islocked,
    gearmotor,
  }
}

const mapDispatchToProps = {
  getLegendsFieldsForConveyor,
  updateLegendFieldsForConveyor,
  getGearmotorForConveyor,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'legend' })(Legend))
)
