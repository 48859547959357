import { createReducer } from '@reduxjs/toolkit'
import { ModalKey } from 'features/Modal'
import { ReactNode } from 'react'
import { PURGE } from 'redux-persist'

export interface IPreferences {
  notesWidth?: number
  notesHeight?: number
}
export interface IConfirmData {
  headerText?: string
  bodyText?: string | Array<ReactNode>
  bodyInnerHTML?: string
  onConfirm?: () => void
  onCancel?: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  closeButton?: boolean
  cancelButton?: boolean
  disableCancel?: boolean
  disableConfirm?: boolean
}
export interface IScreenState {
  content?: ModalKey | null
  fullScreenId?: number
  loading?: boolean
  loadingMessage?: string
  loadingOverride?: boolean
  confirmModal: IConfirmData
  pendingApiResponse: Record<string, unknown>
  modalData: Record<string, unknown>
  preferences: IPreferences
}

export const INITIAL_CONFIRM_STATE: IConfirmData = {
  headerText: null,
  bodyText: null,
  bodyInnerHTML: null,
  onConfirm: null,
  onCancel: null,
  cancelButton: true,
  cancelButtonText: null,
  confirmButtonText: null,
  closeButton: true,
  disableCancel: false,
  disableConfirm: false,
}

const INITIAL_STATE: IScreenState = {
  content: null,
  fullScreenId: null,
  loading: false,
  loadingMessage: null,
  loadingOverride: false,
  confirmModal: INITIAL_CONFIRM_STATE,
  pendingApiResponse: {},
  modalData: {},
  preferences: {
    notesHeight: null,
    notesWidth: null,
  },
}

export default createReducer(INITIAL_STATE, {
  CLOSE_CONFIRM_MODAL: (state) => {
    state.confirmModal = INITIAL_CONFIRM_STATE
  },
  OPEN_CONFIRM_MODAL: (state, action: { payload: IConfirmData }) => {
    const { payload } = action
    state.confirmModal.headerText = payload.headerText
    state.confirmModal.bodyText = payload.bodyText
    state.confirmModal.bodyInnerHTML = payload.bodyInnerHTML
    state.confirmModal.onConfirm = payload.onConfirm
    state.confirmModal.onCancel = payload.onCancel
    state.confirmModal.cancelButton =
      payload.cancelButton === undefined ? true : payload.cancelButton
    state.confirmModal.cancelButtonText = payload.cancelButtonText
    state.confirmModal.confirmButtonText = payload.confirmButtonText
    state.confirmModal.closeButton = payload.closeButton
    state.confirmModal.disableCancel = payload.disableCancel
    state.confirmModal.disableConfirm = payload.disableConfirm
  },
  SET_LOADING: (state, action) => {
    if (!state.loadingOverride) {
      if (!action.payload) {
        state.loading = false
        state.loadingMessage = null
      } else {
        state.loading = action.payload.loading
        state.loadingMessage = action.payload.loadingMessage || null
      }
    }
  },
  SET_LOADING_OVERRIDE: (state, action) => {
    const { loadingOverride, loadingMessage = null } = action.payload
    state.loading = loadingOverride
    state.loadingOverride = loadingOverride
    state.loadingMessage = loadingMessage
  },
  SET_FULL_SCREEN_ID: (state, action) => {
    state.fullScreenId = action.payload
  },
  SET_MODAL_CONTENT: (state, action) => {
    state.content = action.payload
  },
  SET_PENDING_RESPONSE: (state, action) => {
    state.pendingApiResponse = {
      ...state.pendingApiResponse,
      [action.payload]: true,
    }
  },
  DELETE_PENDING_RESPONSE: (state, action) => {
    if (action.payload) {
      delete state.pendingApiResponse[action.payload]
    } else {
      state.pendingApiResponse = {}
    }
  },
  SET_MODAL_DATA: (state, action) => {
    state.modalData = action.payload
  },
  SET_NOTES_PREFERENCES: (state, action) => {
    const { notesHeight, notesWidth } = action.payload
    if (!state.preferences) {
      state.preferences = INITIAL_STATE.preferences
    }
    state.preferences.notesHeight = notesHeight
    state.preferences.notesWidth = notesWidth
  },
  [PURGE]: () => INITIAL_STATE,
})
