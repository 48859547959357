import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import ProjectTab from 'features/Project/components/ProjectTab'
import { resetConveyorStore } from 'features/Conveyor/redux/ConveyorActions'
import { selectProjectFromQueueOrSearch, selectProjectTab } from 'features/Project/redux/ProjectActions'
import { resetVersionStore } from 'features/Version/redux/VersionActions'
import { Button } from 'shared/components'
import { setModalContent } from 'shared/redux/ScreenActions'
import './ProjectsScreen.scss'
import Colors from 'shared/constants/colors'
import AuthenticationService from 'utils/api/authentication/AuthenticationService'
import { captureSentryError } from 'utils/helpers'

const { TabPane } = Tabs

class ProjectsScreen extends Component {
  async componentDidMount() {
    const { projectId, resetConveyorStore, resetVersionStore } = this.props

    resetVersionStore()
    resetConveyorStore()

    if (projectId) {
      AuthenticationService.releaseProjectLock(projectId)
    }
  }

  render() {
    const {
      queueProjectsLength,
      selectProjectFromQueueOrSearch,
      selectProjectTab,
      setModalContent,
      tab,
      userType,
      totalFilterPages,
    } = this.props
    const queueTabText = (
      <div className="queue-tab-text">
        Your Queue
        <span
          className="queue-tab-number"
          style={{ backgroundColor: tab === 'queue' ? Colors.novaBlue : Colors.ash }}
        >
          {queueProjectsLength ? queueProjectsLength : 0}{totalFilterPages>1 ? '+':''}
        </span>
      </div>
    )
    return (
      <div className="projects-container">
        <div className="projects-title" data-testid="pagetitle">
          EZ Suite - Projects
          <div className="projects-title-buttons">
            <Button
              small
              text="Start a New Project"
              onClick={() => {
                selectProjectFromQueueOrSearch(null)
                setModalContent('projectDetails')
              }}
            />
          </div>
        </div>
        <Tabs className="project-tabs" onTabClick={selectProjectTab} defaultActiveKey={tab}>
          <TabPane
            className="project-tabs__tab project-tabs__tab--queue"
            tab={queueTabText}
            key="queue"
          >
            <ProjectTab tab="queue" />
          </TabPane>
          {userType === 'Internal' ? (
            <TabPane
              className="project-tabs__tab project-tabs__tab--search"
              tab="Search for a Project"
              key="search"
            >
              <ProjectTab tab="search" />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    const {
      selectedProject,
      projectQueueResults,
      totalFilterPages,
      tab,
      searchTimestamp,
    } = state.ProjectReducer
    return {
      projectId:selectedProject?.id,
      queueProjectsLength: Object.keys(projectQueueResults || {}).length,
      totalFilterPages,
      tab,
      userType: state.UserReducer.userType,
      searchTimestamp,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  resetConveyorStore,
  resetVersionStore,
  selectProjectFromQueueOrSearch: selectProjectFromQueueOrSearch,
  selectProjectTab,
  setModalContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsScreen)
