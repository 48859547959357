import { createSelector } from '@reduxjs/toolkit'
import { selectVersionId } from 'features/Version/redux/VersionSelectors'
import { MeasuringUnit } from 'shared/types/swagger'
import { Store } from 'srcReducer'
import { DEFAULT_CONVEYOR_WIDTH, MM_IN_IN } from '../components/BuilderCanvas/constants'

const getConveyor = (state: Store, conveyorId: number) =>
  state.ConveyorReducer.conveyors[conveyorId]

const getInfeedTypes = (state: Store) =>
  Object.values(state.ProjectReducer?.currentlistsettings?.sections?.infeed?.types || {})

export const selectHasInfeedDrive = createSelector(
  [getInfeedTypes, getConveyor],
  (infeedTypes, conveyor) => {
    const driveId = infeedTypes.find((type) => type.title === 'Drive')?.id
    return driveId === conveyor?.infeed?.feedtypeid
  }
)

const getDischargeTypes = (state: Store) =>
  Object.values(state.ProjectReducer?.currentlistsettings?.sections?.discharge?.types || {})
export const selectHasDischargeDrive = createSelector(
  [getDischargeTypes, getConveyor],
  (dischargeTypes, conveyor) => {
    const driveId = dischargeTypes.find((type) => type.title === 'Drive')?.id
    return driveId === conveyor?.discharge?.feedtypeid
  }
)

const getChainReducer = (state: Store) => state.ChainReducer
const getChain = createSelector(
  [getChainReducer, selectVersionId],
  (ChainReducer, versionId) => ChainReducer?.[versionId]
)

export const getAllowIntermediateDrives = createSelector(
  [getConveyor, getChain],
  (conveyor, chain) => {
    const isStandard = conveyor.chain.standardchainstyle
    if (isStandard) {
      return chain?.[conveyor.id]?.chainStyles?.[conveyor.chain.chainstyleid]?.intermediatedrives
    }
    return true
  }
)

export const selectConveyorSVGWidth = createSelector(getConveyor, (conveyor) => {
  const chainwidth =
    conveyor.unit === ('Metric' as MeasuringUnit)
      ? conveyor.chain.widthmetric / MM_IN_IN
      : conveyor.chain.widthenglish
  return chainwidth * (DEFAULT_CONVEYOR_WIDTH / 3.76)
})
