import { createAction } from '@reduxjs/toolkit'
import ConveyorService from 'utils/api/conveyor/ConveyorService'
import { updateGearmotor } from 'features/Estimator/components/Gearmotor/redux/GearmotorActions'
import store from 'utils/../reduxStore'
import _ from 'lodash'

const addLegendFields = createAction('ADD_LEGEND_FIELDS')
const updateLegendFields = createAction('UPDATE_LEGEND_FIELDS')

export const getLegendsFieldsForConveyor = (conveyorId) => async (dispatch) => {
  const fields = await ConveyorService.getConveyorLegendFields(conveyorId)
  await dispatch(addLegendFields({ conveyorId, fields }))
}

export const updateLegendFieldsForConveyor = (conveyorId, fields) => async (dispatch) => {
  const oldFields = store.getState().LegendReducer[conveyorId]
  let _fields = fields
  try {
    if ('gearmotorrpm' in fields) {
      const { gearmotorrpm, ...rest } = fields
      _fields = rest
      await dispatch(updateGearmotor(conveyorId, 'gearmotorrpm', Number(gearmotorrpm)))
    }
    if (!_.isEmpty(_fields)) {
      await dispatch(updateLegendFields({ conveyorId, _fields }))
      await ConveyorService.updateConveyorLegendFields(conveyorId, _fields)
    }
  } catch (error) {
    dispatch(updateLegendFields({ conveyorId, fields: oldFields }))
  }
}
