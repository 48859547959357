import React, { memo } from 'react'
import RadiusInfoIcon from './components/RadiusInfoIcon'
import getInfoIconColor from './helpers/getInfoIconColor'
import getInfoIconSVGPath from './helpers/getInfoIconSVGPath'
import getInfoIconViewbox from './helpers/getInfoIconViewbox'

interface Props {
  x?: number
  y?: number
  className?: string
  value?: string | number
  type?: string
  width?: number
  height?: number
}

function InfoIcon(props: Props) {
  const {
    x = '20',
    y = '20',
    className = '',
    value = 0,
    type = 'Warning',
    width = '20',
    height = '20',
  } = props
  const fill = getInfoIconColor(type)

  return (
    <g transform={`translate(${x}, ${y})`}>
      <svg
        width={width}
        height={height}
        aria-hidden="true"
        focusable="false"
        className={className}
        role="img"
        viewBox={getInfoIconViewbox(type)}
      >
        {type === 'Radius' ? (
          <RadiusInfoIcon fill={fill} />
        ) : (
          <path fill={fill} stroke="none" d={getInfoIconSVGPath(type)}></path>
        )}
      </svg>
      <text x="30" y="11" stroke="none" fill="white" dominantBaseline="middle">
        {value > 1 && (type === 'Warning' || type === 'Caution') ? `${type}s` : type} : {value}
      </text>
    </g>
  )
}

export default memo(InfoIcon)
