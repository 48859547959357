import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'shared/components'  
import { withRouter } from 'react-router-dom'
import VersionService from 'utils/api/version/VersionService'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import { downloadVersionFile, updateVersionConveyorsCosts, validateVersion } from 'features/Version/redux/VersionActions'
import validateProject from '../utils/validateProject'
import { canValidateWithoutRequiredInputs } from '../utils/helper/canValidateWithoutRequiredInputs'

/**
 * @typedef {import('shared/redux/ScreenActions')['openConfirmModal']} openConfirmModal
 * @typedef {import('features/Version/redux/VersionActions')['updateVersionConveyorsCosts']} updateVersionConveyorsCosts
 * @typedef {import('features/Version/redux/VersionActions')['downloadVersionFile']} downloadVersionFile
 * @typedef {import('features/Version/redux/VersionActions')['validateVersion']} validateVersion
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 *
 * @typedef {Object} ComponentProps
 * @prop {Number} versionId
 * @prop {Number} projectId
 * @prop {boolean} versioncostsvalid
 * @prop {boolean} isactive
 * @prop {boolean} islocked
 * @prop {openConfirmModal} openConfirmModal
 * @prop {updateVersionConveyorsCosts} updateVersionConveyorsCosts
 * @prop {downloadVersionFile} downloadVersionFile
 * @prop {validateVersion} validateVersion
 *
 * @typedef {ComponentProps & RouteComponentProps} Props
 */

/** @extends Component<Props> */
class GetLABOMButton extends Component {

  handleValidationSuccess = () => {
    window.cancelCompleteMuf = false
    this.props.openConfirmModal({
      headerText: 'Generate LA BOM File',
      bodyText: 'Fetching Updated settings for Conveyors',
      confirmButtonText: 'Cancel Request',
      onConfirm: this.cancelGenerateLABOM,
      cancelButton: false,
      closeButton: false,
    })
    this.handleGenerateLABOMButtonClick()
  }

  handleClick = () => {
    const { versioncostsvalid, versionId, isexternaluser } = this.props

    if (versioncostsvalid) {
      this.handleValidationSuccess()
      return
    }

    const errors = validateProject.all(versionId)
    const canForceValidation = canValidateWithoutRequiredInputs(errors) && !isexternaluser

    if (errors.length) {
      let todisplay = ''
      Map.groupBy(errors,(error)=> error.conveyorId).forEach((_value, key) => {
        todisplay += `<li><span class='text--red'>${_value[0].conveyorName}<ul>${_value.map((err) => `<li><span class='subtext--red'>${err.HTMLMessage}</span>`).join('')}</ul></span></li>`
      })
      
      this.props.openConfirmModal({
        headerText: 'Error',
        bodyInnerHTML: `
          <h3>Could not generate LA BOM file due to validation errors in:</h3>
          <ul>
          ${todisplay}
          </ul>
        `,
        confirmButtonText: 'OK',
        cancelButtonText: 'Continue',
        cancelButton: canForceValidation,
        onCancel: this.handleValidationSuccess,
      })
    } else {
      this.handleValidationSuccess()
    }
  }

  cancelGenerateLABOM = () => {
    const { versionId } = this.props
    window.cancelCompleteMuf = true
    VersionService.cancelUpdateConveyorsCosts(versionId)
  }

  handleGenerateLABOMButtonClick = async () => {
    const { history, openConfirmModal, projectId, versionId } = this.props

    if (!window.cancelCompleteMuf) {
      try {
        await this.props.updateVersionConveyorsCosts(versionId)

        await this.props.validateVersion(versionId)

        this.props.downloadVersionFile(versionId, 'LABOM', projectId)

        // we force the page to refresh in case we are on conveyor builder screen
        // as transfer were displayed so they would appear again
        history.replace(`/project/${projectId}`)
        history.replace(`/project/${projectId}/${versionId}`)
      } catch (e) {
        if (!window.cancelCompleteMuf) {
          // If user did not press Cancel Button during pending request
          if (e?.response?.status === 422) {
            // Backend job still processing
            window.handle422error = setTimeout(() => {
              this.handleGenerateLABOMButtonClick()
            }, 10000)
            const bodyText = e.response.headers.popupmessage.split('. ').join('.\n')
            openConfirmModal({
              headerText: 'Generate LA BOM File',
              bodyText,
              confirmButtonText: 'Cancel Request',
              onConfirm: () => {
                clearTimeout(window.handle422error)
                this.cancelGenerateLABOM()
              },
              cancelButton: false,
              closeButton: false,
              disableConfirm: false,
            })
          } else {
            openConfirmModal({
              headerText: 'Error',
              bodyText: 'Could not get updated conveyors costs.',
              cancelButton: false,
              confirmButtonText: 'OK',
            })
          }

          return
        }
      }
    }
  }

  render() {
    const { versioncostsvalid, isactive, islocked } = this.props

    return (
      <Button
        className="get-labomfile"
        small
        icon="tag"
        text={`${!versioncostsvalid ? 'Generate' : 'Download'} LA BOM`}
        disabled={!versioncostsvalid && (!isactive||islocked)}
        onClick={this.handleClick}
      />
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
    return { isexternaluser: state.UserReducer.userType == 'External' }
}

const mapDispatchToProps = {
  openConfirmModal,
  updateVersionConveyorsCosts,
  downloadVersionFile,
  validateVersion
}

const connectedGetLABOMButton = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GetLABOMButton)
)

export default connectedGetLABOMButton
