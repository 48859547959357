import { IChainConveyor } from 'features/Chain/shared/types'
import { IStdChainDescriptionResponse } from 'shared/types/StdChainDescription'
import { formatIdAsKey } from 'utils/helpers'

export default function organizeChainSerieInfo(
  data: Array<IStdChainDescriptionResponse>
): IChainConveyor {
  // Takes data and organizes chainStyles into array
  const { askfeetlengthinsteadofrodnumber, chainstyleserie } = data[0]
  const { id, chainstyle, chainstyleseriesoptions, ...rest } = chainstyleserie

  const chainStyles = []

  data.forEach((s) => {
    const { alternaterowchoice, chainstyle, chainstyleseriesoptions } = s.chainstyleserie
    chainStyles.push({
      alternaterowchoice,
      ...chainstyleseriesoptions[0],
      ...chainstyle,
    })
  })

  return {
    askfeetlengthinsteadofrodnumber,
    ...rest,
    chainStyles: formatIdAsKey(chainStyles),
  }
}
