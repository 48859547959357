import * as React from 'react'
import { Button } from 'shared/components'
import SectionTypes from 'shared/constants/conveyorSectionTypes'
import { yesMsgOverriden } from 'features/Estimator/components/shared/Headers'
import BuilderCollapseToggle from './BuilderCollapseToggle'
import { ConveyorSectionType } from 'shared/types/swagger'

interface Props {
  collapsed: boolean;
  handleCollapse: () => void;
  type: ConveyorSectionType;
  sectionnumber: number;
  handleClone: () => unknown;
  hasOverrides: boolean;
  'aria-controls': string;
  'aria-expanded': boolean;
  disabled: boolean;
}

export default function BuilderTileHeading(props: Props) {
  const {
    collapsed,
    handleCollapse,
    type,
    sectionnumber,
    handleClone,
    hasOverrides,
    disabled,
    ...restProps
  } = props

  return (
    <React.Fragment>
      <BuilderCollapseToggle collapsed={collapsed} onClick={handleCollapse} {...restProps} />
      <div className="collapsed-main__title">
        {sectionnumber + 1}: {SectionTypes[type]}
        {!hasOverrides ? null : (
          <span className="collapsed-main__title-overridemsg">{yesMsgOverriden}</span>
        )}
        {type === 'Straight' && !disabled ? (
          <Button
            tertiary
            text="Copy Section"
            icon="clone"
            className="collapsed-main__title-copybtn"
            onClick={handleClone}
          />
        ) : null}
      </div>
    </React.Fragment>
  )
}
