import {
  setMaterialDataForConveyorSection,
  runUpdateMaterialOrAccessoryForConveyorSection,
} from '../../../../redux/SectionOverrideActions'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializesectionwearstrips = (sideframeData, sectionId) => async (dispatch) => {
  dispatch(
    setMaterialDataForConveyorSection({
      materialName: 'wearstrips',
      materialData: sideframeData,
      sectionId,
    })
  )
}

export const updateWearstrips = ({ conveyorId, updatedWearstripsFields, sectionId }) => (
  dispatch
) => {
  dispatch(
    runUpdateMaterialOrAccessoryForConveyorSection({
      conveyorId,
      materialKey: 'wearstrips',
      updatedFields: updatedWearstripsFields,
      type: 'materials',
      sectionId,
    })
  )
}
