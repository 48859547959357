export const migrations = {
  '-1': (state) => state,
  0: (state) => ({
    ...state,
    ProjectReducer: {
      ...state.ProjectReducer,
      searchTimestamp: null,
      searchError: false,
      searchErrorReason: '',
      searchPending: false,
    },
  }),
  1: (state) => ({
    ...state,
    ListReducer: {
      ...state.ListReducer,
      miscparts: {},
    }
  })
}
