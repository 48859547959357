import { IConveyorWithModifiedInfeedDischarge } from 'features/Conveyor/shared/types'
import { IGearmotor } from 'shared/types/swagger'
import { IGearMotorList, ListElementRecord } from 'utils/api/list/types'
import getRadioOptions from '../helpers/getRadioOptions'
import getSelectOptions from '../helpers/getSelectOptions'
import { OLD_GEARMOTOR_DRIVETYPE_IDS } from './constants'
import { IDesiredUnitOptionsOption } from './types'

type ConfigProps = {
  conveyor: IConveyorWithModifiedInfeedDischarge;
  displayGearboxesOnly: boolean;
  displayBrandOnly: boolean;
  gearmotor: IGearmotor;
  gearmotorList: Partial<IGearMotorList>;
  desiredUnitOptions: Array<IDesiredUnitOptionsOption>;
}

export const gearmotorConfig = (props: ConfigProps) => {
  const { conveyor, displayGearboxesOnly, displayBrandOnly, gearmotor, gearmotorList } = props
  const gearmotorIsNoneOrOther = displayGearboxesOnly || displayBrandOnly
  const driveTypes: ListElementRecord = gearmotorList?.drivetypes && OLD_GEARMOTOR_DRIVETYPE_IDS.reduce(
    (obj, id) => {
      obj[id] = gearmotorList.drivetypes[id]
      return obj
    }
  , {})

  return [
    {
      dataKey: 'gearmotortypeid',
      visible: true,
      prettyName: 'Gearmotor',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Gearmotor is required.',
        },
      ],
      options: getRadioOptions(driveTypes),
      value: conveyor.gearmotortypeid,
    },
    {
      dataKey: 'gearboxid',
      visible: displayGearboxesOnly,
      prettyName: 'Gearbox',
      type: 'select',
      width: '200px',
      placeholder: 'Select a Gearbox',
      rules: [
        {
          required: true,
          message: 'Gearbox is required.',
        },
      ],
      options: getSelectOptions(gearmotorList.gearboxes),
      value: conveyor.gearboxid,
    },
    {
      type: 'inputs',
      visible: displayBrandOnly,
      inputs: [
        {
          dataKey: 'gearmotorbrand',
          prettyName: 'Gearmotor Brand',
          type: 'input',
          value: gearmotor.gearmotorbrand,
        },
      ],
    },
    {
      type: 'inputs',
      visible: !gearmotorIsNoneOrOther,
      inputs: [
        {
          dataKey: 'stopstartsperhour',
          prettyName: 'Stop/Starts per Hour',
          placeholder: 'Enter Stop/Starts per Hour',
          type: 'input',
          rules: [
            {
              required: true,
              transform: (value) => Number(value),
              validator: (rule, value, cb) => {
                try {
                  if (isNaN(value)) {
                    throw new Error('Value must be a number.')
                  } else if (value === 0) {
                    throw new Error('Stop/Starts per Hour is required.')
                  }
                  cb()
                } catch (error) {
                  cb(error)
                }
              },
            },
          ],
          value: gearmotor.stopstartsperhour,
        },
        {
          dataKey: 'hoursperdayofoperation',
          prettyName: 'Hours per day of operation',
          placeholder: 'Enter Hours per day of operation',
          type: 'input',
          rules: [
            {
              required: true,
              transform: (value) => Number(value),
              validator: (rule, value, cb) => {
                try {
                  if (isNaN(value)) {
                    throw new Error('Value must be a number.')
                  } else if (value === 0) {
                    throw new Error('Hours per day of operation is required.')
                  }
                  cb()
                } catch (error) {
                  cb(error)
                }
              },
            },
          ],
          value: gearmotor.hoursperdayofoperation,
        },
      ],
    },
    {
      dataKey: 'mountingtypeid',
      visible: !gearmotorIsNoneOrOther,
      prettyName: 'Mounting Type',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Mounting Type is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.eurodrive.selection.mountingtypes),
      value: gearmotor.mountingtypeid,
    },
    {
      dataKey: 'remotemountflatinclinedeclineflagid',
      visible:
        !gearmotorIsNoneOrOther &&
        gearmotorList.eurodrive.selection.mountingtypes[gearmotor.mountingtypeid].textvalue === 'R',
      prettyName: 'Flat/Incline/Decline',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Flat/Incline/Decline is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.RemoteMountFlatInclineDeclineFlag),
      value: gearmotor.remotemountflatinclinedeclineflagid,
    },
    {
      type: 'inputs',
      visible:
        !gearmotorIsNoneOrOther &&
        gearmotorList.eurodrive.selection.mountingtypes[gearmotor.mountingtypeid].textvalue === 'R',
      inputs: [
        {
          dataKey: 'remotemountangle',
          prettyName: 'Incline/Decline Angle',
          placeholder: 'Enter Incline/Decline Angle',
          type: 'input',
          rules: [
            {
              required: true,
              transform: (value) => Number(value),
              validator: (rule, value, cb) => {
                try {
                  if (isNaN(value)) {
                    throw new Error('Value must be a number.')
                  }
                  cb()
                } catch (error) {
                  cb(error)
                }
              },
            },
          ],
          value: gearmotor.remotemountangle,
        },
        {
          dataKey: 'remotemountelevation',
          prettyName: 'Elevation',
          placeholder: 'Enter Elevation',
          type: 'input',
          rules: [
            {
              required: true,
              transform: (value) => Number(value),
              validator: (rule, value, cb) => {
                try {
                  if (isNaN(value)) {
                    throw new Error('Value must be a number.')
                  } else if (value === 0) {
                    throw new Error('Elevation is required.')
                  }
                  cb()
                } catch (error) {
                  cb(error)
                }
              },
            },
          ],
          value: gearmotor.remotemountelevation,
        },
        {
          dataKey: 'remotemountdrivesprocketsteeth',
          prettyName: 'Drive Sprocket Teeth',
          placeholder: 'Enter Number of Drive Sprocket Teeth',
          type: 'input',
          rules: [
            {
              required: true,
              transform: (value) => Number(value),
              validator: (rule, value, cb) => {
                try {
                  if (isNaN(value)) {
                    throw new Error('Value must be a number.')
                  } else if (value === 0) {
                    throw new Error('Drive Sprocket Teeth is required.')
                  } else if (value < 16 || value > 22) {
                    throw new Error('Value should be between 16 to 22.')
                  }
                  cb()
                } catch (error) {
                  cb(error)
                }
              },
            },
          ],
          value: gearmotor.remotemountdrivesprocketsteeth,
        },
      ],
    },
    {
      dataKey: 'zfanonmotor',
      visible: !gearmotorIsNoneOrOther,
      prettyName: "Include 'Z Fan' on Motor",
      type: 'checkbox',
      value: gearmotor.zfanonmotor,
      extra: "Speed must be at least 125 FPM to select the 'Z Fan'",
    },
    {
      dataKey: 'brakemotor',
      visible: !gearmotorIsNoneOrOther,
      prettyName: 'Include Brake Motor',
      type: 'checkbox',
      value: gearmotor.brakemotor,
    },
  ]
}

export const availableDrivesConfig = (props: ConfigProps) => {
  const { desiredUnitOptions, gearmotor, gearmotorList } = props
  return [
    {
      dataKey: 'motoroutputvoltageid',
      visible: true,
      prettyName: 'Motor Input Voltage',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Motor Input Voltage is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.motoroutputvoltage),
      value: gearmotor.motoroutputvoltageid,
    },
    {
      dataKey: 'desiredunitid',
      visible: true,
      prettyName: 'Select Desired Unit',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Desired Unit is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.desiredunits),
      value: gearmotor.desiredunitid,
    },
    {
      dataKey: 'gearmotorid',
      visible: true,
      type: 'checkbox_list',
      options: desiredUnitOptions || [],
      value: gearmotor.gearmotorid,
    },
    {
      dataKey: 'variablespeedoptionsid',
      visible: true,
      prettyName: 'Vacon or Movimot?',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Please choose between Vacon or Movimot.',
        },
      ],
      options: getRadioOptions(gearmotorList.eurodrive.variablespeed.options),
      value: gearmotor.variablespeedoptionsid,
    },
    {
      dataKey: 'vaconxseriesinverterid',
      visible: /Vaco[nm]{1} Series X Inverter/.test(
        gearmotorList.eurodrive.variablespeed.options[gearmotor.variablespeedoptionsid]?.title
      ),
      prettyName: 'Horsepower',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Horsepower is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.eurodrive.variablespeed.Inverter),
      value: gearmotor.vaconxseriesinverterid,
    },
    {
      dataKey: 'movimotspeedctrlid',
      visible:
        gearmotorList.eurodrive.variablespeed.options[gearmotor.variablespeedoptionsid]?.title ===
        'Movimot speed Controller',
      prettyName: 'Movimot Speed Controller',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Please choose the type of Movimot Speed Controller.',
        },
      ],
      options: getRadioOptions(gearmotorList.eurodrive.variablespeed.movimotspeedcontroller),
      value: gearmotor.movimotspeedctrlid,
    },
    {
      dataKey: 'inputvoltage',
      visible: true,
      prettyName: 'Input Voltage',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Input Voltage is required.',
        },
      ],
      options: getRadioOptions(gearmotorList.eurodrive.variablespeed.InputVoltage),
      value: gearmotor.inputvoltage,
    },
  ]
}
