import { IStdChainDescriptionResponse } from 'shared/types/StdChainDescription'
import http from 'utils/api/Client'
import organizeChainSerieInfo from './helpers/organizeChainSerieInfo'

const ChainService = {
  async getChainForConveyor(conveyorId) {
    return await http
      .get(`/Conveyors/${conveyorId}/Chain`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateChain(chainId, payload) {
    return await http
      .put(`/Chains/${chainId}`, payload)
      .then(({ data }) => {
        return data
      })
      .catch((e) => {
        throw e
      })
  },
  async getChainSerieInfo(chainSeriesId, stdChainWidthId) {
    return await http
      .get<Array<IStdChainDescriptionResponse>>(
        `/Settings/ChainSeries/${chainSeriesId}/StdChainWidth/${stdChainWidthId}/StdChainDescription`
      )
      .then(({ data }) => organizeChainSerieInfo(data))
      .catch((e) => {
        throw e
      })
  },
  // this is only ever done when creating a new conveyor to associate a new
  // chain with that conveyor ever after.
  async addChainToConveyor(conveyorId, payload = {}) {
    return await http
      .post(`/Conveyors/${conveyorId}/Chain`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async addNewChainPatternRow(chainId, payload) {
    return await http
      .post(`/Chains/${chainId}/chainpatterns`, payload)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async deleteChainPatternRow(chainId, nonStdRowId) {
    return await http
      .delete(`/Chains/${chainId}/chainpatterns/${nonStdRowId}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
  async updateNonStandardChainRow(chainId, rowId, chainRow) {
    // this is to ensure the positon key for center links is correct
    const orderedChainRow = {
      ...chainRow,
      centerelements: chainRow.centerelements.map((centerelement, position) => ({
        ...centerelement,
        position,
      })),
    }
    return await http
      .put(`/Chains/${chainId}/chainpatterns/${rowId}`, orderedChainRow)
      .then(({ data }) => data)
      .catch((e) => {
        throw e
      })
  },
}

export default ChainService
