import routeConfig from './routeConfig'
import pathToRegexp from 'path-to-regexp'

/**
 * @typedef {Object} CrumbConfig
 * @property {import('shared/components/Icon').Props['icon']} [icon]
 * @property {string} text
 * @property {string} value
 */

/**
 * @param {string} pathname current path
 * @returns {Array<CrumbConfig>}
 */
export default function getBreadcrumbsConfig(pathname) {
  /** @type {Array<CrumbConfig>} */
  const crumbs = []

  Object.entries(routeConfig).forEach(([path, config]) => {
    const regex = pathToRegexp(path, { end: false })
    const matches = regex.test(pathname)
    if (matches) {
      crumbs.push({
        text: config.text,
        value: regex.exec(pathname)[0],
        icon: config.icon,
      })
    }
  })

  return crumbs
}
