import {
  setDefaultAccessoryDataForConveyor,
  runUpdateDefaultMaterialOrAccessoryForConveyor,
} from '../../../../redux/EstimatorActions'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializeguiderails = (conveyorId, guiderailsData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoryDataForConveyor({
      conveyorId,
      accessoryKey: 'guiderails',
      accessoryData: guiderailsData,
    })
  )
}

export const updateGuiderails = ({ conveyorId, updatedGuiderailsFields, sideId, sideName, cb }) => (
  dispatch
) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'guiderails',
      updatedFields: updatedGuiderailsFields,
      type: 'accessories',
      sideId,
      sideName,
      cb
    })
  )
}
