import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'reduxStore'

const selectEstimatorMeta = (state: RootState) => state.EstimatorMetaReducer

export const selectConveyorBuilder = createSelector(
  selectEstimatorMeta,
  (state: RootState, conveyorId: number) => conveyorId,
  (meta, conveyorId) => meta[conveyorId].conveyorbuilder
)
