import React, { memo } from 'react'
import { typedUseSelector } from 'utils/helpers'
import { SELECTED_COLOR } from '../../constants'
import { IBuilderSectionData } from '../../types/types'
import IntermediateDriveSVG from './components/IntermediateDriveSVG'

interface Props extends Partial<IBuilderSectionData> {
  conveyorId: number;
  pullPosition: string;
  width: number;
}

function IntermediateDrive(props: Props) {
  const { anchorAngleDeg, anchorPosition, length, id, conveyorId, width } = props
  const selectedSectionId = typedUseSelector(
    (state) => state.EstimatorMetaReducer[conveyorId].conveyorbuilder.selectedSectionId
  )
  const selected: boolean = selectedSectionId === id.toString()

  return (
    <g
      id={`section-${id}`}
      className="draggable"
      fill={selected ? SELECTED_COLOR : 'transparent'}
      transform={`translate(${anchorPosition.x} ${anchorPosition.y})`}
    >
      <rect
        id={id as string}
        width={width}
        height={length}
        style={{ stroke: '#2c76d6', strokeWidth: '4px' }}
        transform={`rotate(${anchorAngleDeg * -1})`}
      />
      <IntermediateDriveSVG
        pullPosition={props.pullPosition}
        anchorAngleDeg={anchorAngleDeg}
        selected={selected}
        length={length}
        width={width}
      />
    </g>
  )
}

export default memo(IntermediateDrive)
