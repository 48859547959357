import {
  setDefaultAccessoryDataForConveyor,
  runUpdateDefaultMaterialOrAccessoryForConveyor,
} from '../../../../redux/EstimatorActions'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializedriptray = (conveyorId, driptrayData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoryDataForConveyor({
      conveyorId,
      accessoryKey: 'driptray',
      accessoryData: driptrayData,
    })
  )
}

export const updateDriptray = ({ conveyorId, updatedDriptrayFields }) => (dispatch) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'driptray',
      updatedFields: updatedDriptrayFields,
      type: 'accessories',
    })
  )
}
