import * as React from 'react'
import { connect } from 'react-redux'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Bowser from 'bowser'
import { Login } from './screens'
import LoadingSpinner from 'features/LoadingSpinner'
import Modal from 'features/Modal'
import NavBar from 'features/NavBar'
import ConfirmModal from 'features/ConfirmModal'
import { getStaticLists, getSettingsLists } from 'shared/redux/ListActions'
import { closeConfirmModal, openConfirmModal } from 'shared/redux/ScreenActions'
import { logout } from 'features/User/redux/UserActions'
import routeConfig from 'features/NavBar/utils/routeConfig'
import { captureSentryError } from 'utils/helpers'
import { ScreenErrorBoundary } from 'shared/components'

export const history = createBrowserHistory()
const notTest = process.env.NODE_ENV !== 'test'

class App extends React.Component {
  componentDidMount() {
    const {
      authenticated,
      closeConfirmModal,
      getStaticLists,
      getSettingsLists,
      logout,
      openConfirmModal,
    } = this.props
    const browser = Bowser.getParser(window.navigator.userAgent)

    const validBrowsers = {
      chrome: '>=80.0.3987.87',
      edge: '>=79.0.309.71',
      safari: '>=13',
      firefox: '>=72',
    }

    if (!browser.satisfies(validBrowsers) && notTest) {
      logout()
      setTimeout(
        () =>
          openConfirmModal({
            headerText: 'Unsupported Browser',
            bodyText:
              'Your current browser is not supported. Please make sure you to try with the latest version of the following browsers: Google Chrome, Microsoft Edge or Safari.',
            cancelButton: false,
          }),
        1000
      )
    } else {
      closeConfirmModal()
    }

    if (authenticated) {
      getStaticLists()
      getSettingsLists()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.getStaticLists()
      this.props.getSettingsLists()
    }
  }

  unauthenticatedRoutes = () => (
    <React.Fragment>
      <Redirect from="*" to="/" />
      <Route exact path="/" component={Login} />
    </React.Fragment>
  )

  renderRoutes = () => {
    const { roles } = this.props

    return Object.entries(routeConfig).map(([path, config]) => {
      const userHasAccesToRoute = (config.authority || []).every((role) => roles.includes(role))
      if (userHasAccesToRoute) {
        return <Route key={path} exact={!config.inexact} path={path} component={config.component} />
      }
      return null
    })
  }

  authenticatedRoutes = () => {
    return (
      <React.Fragment>
        <NavBar />
        <Switch>
          {/* @ts-ignore */}
          <ScreenErrorBoundary>{this.renderRoutes()}</ScreenErrorBoundary>
        </Switch>
      </React.Fragment>
    )
  }

  render() {
    const { authenticated, content, loading, loadingMessage } = this.props
    return (
      <div className="app-container">
        <Router history={history}>
          {authenticated && loading ? <LoadingSpinner loadingMessage={loadingMessage} /> : null}
          {authenticated && content ? <Modal /> : null}
          <ConfirmModal />
          {authenticated ? this.authenticatedRoutes() : this.unauthenticatedRoutes()}
        </Router>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state) => {
  try {
    return {
      authenticated: state.UserReducer.authenticated,
      roles: state.UserReducer.roles,
      content: state.ScreenReducer.content,
      loading: state.ScreenReducer.loading,
      loadingMessage: state.ScreenReducer.loadingMessage,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default connect(mapStateToProps, {
  closeConfirmModal,
  getStaticLists,
  getSettingsLists,
  logout,
  openConfirmModal,
})(App)
