import * as React from 'react'
import { IConveyorSection, MeasuringUnit } from 'shared/types/swagger'
import { typedUseSelector } from 'utils/helpers'

interface Props {
  unit: MeasuringUnit
  item: IConveyorSection
}

export default function BuilderTileSubheadingStatus(props: Props) {
  const { item, unit } = props
  const sectionsList = typedUseSelector((state) => state.ProjectReducer.currentlistsettings.sections)
  const metricUnit = unit === 'Metric'
  const helicalBend = item.type === 'HorizontalCurve' && Math.abs(item.incline) > 7

  const text = React.useMemo(() => {
    try {
      switch (item.type) {
        case 'Straight':
          if (metricUnit) {
            const { lengthinputmm } = item
            let lenthtext: number | string = 0
            if (lengthinputmm) {
              lenthtext = lengthinputmm % 1 === 0 ? lengthinputmm : lengthinputmm.toFixed(2)
            }
            return `Straight | ${lenthtext}mm | ${item.angle || 0}°`
          } else {
            const inPart = Number(item.lengthinputinchespart || 0)
            const in116Part = item.lengthinput116inchespart / 16 || 0
            const straightEnglishLengthText =
              (inPart + in116Part) % 1 === 0 ? inPart + in116Part : (inPart + in116Part).toFixed(2)

            return `Straight | ${
              item.lengthinputfeetpart ? `${item.lengthinputfeetpart}Ft` : ''
            } ${straightEnglishLengthText}in | ${item.angle || 0}°`
          }
        case 'HorizontalCurve':
          return `${helicalBend ? 'Helical Bend' : 'Horizontal Curve'} | ${
            Math.abs(item.angle) || 0
          }° ${item.angle > 0 ? 'Left' : 'Right'} | ${item.radius}${metricUnit ? 'mm' : 'in'}`
        case 'VerticalCurve':
          return `Vertical Curve | ${Math.abs(item.incline) || 0}° ${
            item.incline > 0 ? 'Incline' : 'Decline'
          }`
        case 'MetalDetector':
          return item.metaldetectortypeid
            ? (sectionsList.metaldetectors.types[item.metaldetectortypeid].title + ' | ' + 
               (metricUnit ? item.lengthforsectionmm+'mm' : item.lengthforsectioninches+'in') + ' ')
            : 'N/A'
        case 'IntermediateDrive':
          return item.feeddriveorientationid
            ? sectionsList.intermediate.driveOrientation[item.feeddriveorientationid].title +
                ' Drive | ' + (metricUnit ? item.lengthforsectionmm+'mm' : item.lengthforsectioninches+'in') + ' | ' + 
                sectionsList.feeds.bearingtype[item.feedbearingtypeid].title
            : 'N/A'
        default:
          return 'N/A'
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }, [
    helicalBend,
    item,
    metricUnit,
    sectionsList.feeds.bearingtype,
    sectionsList.intermediate.driveOrientation,
    sectionsList.metaldetectors.types,
  ])

  return <span>{text}</span>
}
