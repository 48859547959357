import { IAPIErrorResponse } from 'shared/types/error'
import { IFileUpload } from 'shared/types/FileUpload'
import { IChangePassWordRequest, ILoginModel } from 'shared/types/swagger'
import http from 'utils/api/Client'
import getUserDetailsAndToken from './helpers/getUserDetailsAndToken'

const AuthenticationService = {
  async login(credentials: ILoginModel) {
    return await http
      .post<ILoginModel, { data: { token: string } }>('/auth/login', credentials)
      .then(({ data }) => getUserDetailsAndToken(data))
      .catch((e) => {
        const { status, data } = e.response
        if (status >= 400 && status <= 499) {
          return {
            error: data?.error,
          }
        } else if (status >= 500 && status <= 599) {
          return {
            error:
              'Could not log in due to Server Error. Please contact the system administrator for assistance.',
          }
        }
        // console.log('Error during login: ', e)
        return {
          error: 'Error during login.',
        }
      })
  },
  async changePassword(
    userId: IChangePassWordRequest['id'] | string,
    oldpassword: IChangePassWordRequest['oldpassword'],
    newpassword: IChangePassWordRequest['newpassword']
  ): Promise<unknown> {
    return await http
      .post<IChangePassWordRequest, unknown>(`/Users/${userId}/changepassword`, {
        oldpassword,
        newpassword,
      })
      .then(({ data }) => data)
  },
  async createUpdateSignature(userId: string | number, file: File) {
    return await http
      .post<File, { data: unknown }>(`/Users/${userId}/signature`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => data)
  },
  async getSignature(userId: string | number) {
    return await http
      .get<IFileUpload>(`/Users/${userId}/signature`, {
        dontlog: true,
      })
      .then(({ data }) => data)
  },
  /**
   * Checks if the project is locked. If it is, this returns the username
   * of the person currently using it. If not, username is not returned
   */
  async checkProjectLock(projectId: string | number) {
    return await http
      .get<{ islocked: boolean; username?: string }>(`/Projects/${projectId}/IsLocked`)
      .then(({ data: { islocked, username } }) => ({ islocked, username }))
  },
  async releaseProjectLock(projectId: string | number) {
    return await http.post(`/Projects/${projectId}/ReleaseLock`, {}).catch((e) => {
      console.log(`Could not release lock for Project #${projectId}: ${e}`)
    })
  },
}

export default AuthenticationService
