import React from 'react'
import _ from 'lodash'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import './GrabZone.scss'
import cn from 'classnames'

/**
 * @typedef Props
 * @prop {boolean} [horizontal]
 * @prop {string} [className]
 */

/**
 * @param {Props} props
 */
function GrabZone({ horizontal = false, className }) {
  return (
    <div className={cn('grabzone', `grabzone--${horizontal ? 'x' : 'y'}`, className)} tabIndex={0}>
      {_.times(8, (idx) => (
        <Icon
          key={idx}
          icon="circle"
          size={5}
          color={Colors.silver}
          style={{ alignSelf: 'center', justifySelf: 'center' }}
          svgStyle={{ strokeWidth: '2px' }}
        />
      ))}
    </div>
  )
}

export default GrabZone
