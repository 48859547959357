import React from 'react'

export default function MandatoryCell(props) {
  const { children, ...restProps } = props

  return (
    <td {...restProps}>
      {children}
    </td>
  )
}
