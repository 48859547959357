import React, { useCallback, useState, useEffect } from 'react'
import { Table } from 'antd'
import { VTComponents } from 'virtualizedtableforantd'
import '../CostImportTable.scss'
import { getPartNumber, getColumns } from '../../utils'
import cn from 'classnames'
import { debounce } from 'lodash'
import { SytelineResponsePlus, UpdateCost } from '../../types'
import { ScreenStateKey } from 'shared/types/Screen'

interface Props {
  screenState: ScreenStateKey
  sytelineCosts: Array<SytelineResponsePlus>
  updateCost: UpdateCost
  calculateFreightAndDuties: (SytelineResponsePlus) => void
  loading: boolean
  editedIds: Array<number>
  areAllRowsLocked?: boolean,
}

export default function CostImportTable(props: Props) {
  const {
    screenState,
    sytelineCosts,
    loading,
    updateCost,
    calculateFreightAndDuties,
    editedIds,
    areAllRowsLocked
  } = props

  const [scrollY, setScrollY] = useState(window.innerHeight - 301)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(setTBodySizeBaseOnSurroundings)
  }, [screenState])

  function setTBodySizeBaseOnSurroundings() {
    const midEl = document.querySelector<HTMLElement>('.cost-import__mid')
    if (!midEl) return
    setScrollY(window.innerHeight - midEl.offsetTop - 101)
  }

  const handleResize = debounce(setTBodySizeBaseOnSurroundings, 100)

  const handleSearch = useCallback((_, confirm) => {
    confirm()
  }, [])

  const handleReset = useCallback((clearFilters) => {
    clearFilters()
  }, [])

  const columns = getColumns(
    screenState,
    updateCost,
    handleSearch,
    handleReset,
    calculateFreightAndDuties,
    editedIds,
    areAllRowsLocked
  )

  const scrollX = columns.reduce((acc, cur) => {
    const _acc = acc + (Number(cur.width) || 0)
    return _acc
  }, 0)

  return (
    <div className="cost-import__table">
      <Table
        columns={columns}
        dataSource={loading ? [] : sytelineCosts}
        components={VTComponents({ id: 1001 })}
        pagination={false}
        loading={loading}
        onRow={(record) => ({
          className: `rk${getPartNumber(record).replace(/[\s]+/g, '-')}`,
        })}
        rowKey={(record) => `${getPartNumber(record)}-${record.id}${record.calcdatetime || ''}`}
        className={cn({ 'cost-import__table--editing': screenState === 'EDITING' })}
        scroll={{ x: scrollX, y: scrollY }}
      />
    </div>
  )
}
