import React, { Component } from 'react'
import { Input, Form } from 'antd'

export const EditableContext = React.createContext()

export default class EditableCell extends Component {
  renderCell = ({ getFieldDecorator }) => {
    const {
      dataIndex,
      disabled,
      editable,
      title,
      record = {},
      index,
      children,
      addonAfter = null,
      addonBefore = null,
      onChange = () => console.log('No onChange for this EditableCell'),
      tableType,
      rules,
      ...restProps
    } = this.props

    const initialValue =
      dataIndex === 'pricevalidationoptionname' && 'details' in record
        ? record.details
        : record[dataIndex]

    const _editable = typeof editable === 'function' ? editable(record) : editable

    return (
      <td {...restProps}>
        {_editable && dataIndex ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(
              `${dataIndex} ${
                tableType === 'global_options'
                  ? record.optionId
                  : tableType === 'conveyor_options'
                  ? record.id
                  : record.conveyorId
              }`,
              {
                rules:
                  rules && tableType !== 'conveyor_options'
                    ? rules
                    : [
                        {
                          validator: (rule, value, cb) => {
                            try {
                              if (
                                !rule.field.includes('pricevalidationoptionname') &&
                                isNaN(Number(value))
                              ) {
                                throw new Error('Value must be a number.')
                              } else if (value === '') {
                                throw new Error(`${title} is required`)
                              }
                              cb()
                            } catch (error) {
                              cb(error)
                            }
                          },
                        },
                      ],
                initialValue,
              }
            )(
              <Input
                disabled={disabled || !_editable}
                className={`${addonBefore ? 'left' : addonAfter ? 'right' : ''}`}
                addonAfter={addonAfter}
                addonBefore={addonBefore}
                onChange={(e) => {
                  if (tableType === 'global_options') {
                    onChange(record.pricevalidationoptionid, record.optionId, e.target.value)
                  } else if (tableType === 'conveyor_options') {
                    onChange(record.id, e.target.value)
                  } else {
                    onChange(record.conveyorId, e.target.value)
                  }
                }}
                placeholder={dataIndex === 'muf' && record.conveyorId ? '- - -' : undefined}
              />
            )}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}
