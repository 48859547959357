import { VERTICAL_CURVE_ANCHOR } from '../../../constants'

export default function getVerticalCurveDiagonalLinePoints(
  width: number,
  length: number,
  isIncline: boolean
) {
  return [
    [VERTICAL_CURVE_ANCHOR.x + width, VERTICAL_CURVE_ANCHOR.y + (isIncline ? length : 0)],
    [VERTICAL_CURVE_ANCHOR.x, VERTICAL_CURVE_ANCHOR.y + length],
    [VERTICAL_CURVE_ANCHOR.x, VERTICAL_CURVE_ANCHOR.y],
  ]
}
