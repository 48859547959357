import React from 'react'
import { escapeCSSChars } from 'utils/helpers'

const getHighlightedRowCSS = (className: string) => `
  .rk${className}{background:rgba(0,107,182,0.1);}
  .rk${className}>td.ant-table-column-sort{background:rgba(0,107,182,0.11)!important;}
`

interface Props {
  updating: boolean
  editedKeys?: Array<string>
}

export default function StaticPartsRowStyle(props: Props) {
  const { updating, editedKeys = [] } = props
  let editedRowsCss = ''

  if (updating) {
    editedRowsCss = editedKeys
      .map((pn) => escapeCSSChars(pn.replace(/[\s]+/g, '-')))
      .map(getHighlightedRowCSS)
      .join('')
  }

  return <style>{editedRowsCss}</style>
}
