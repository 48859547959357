import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'shared/components'
import { uploadSignature } from 'features/User/redux/UserActions'
import { connect } from 'react-redux'
import './SignatureButton.scss'

const HALF_MB = 524288

class SignatureButton extends PureComponent {
  static propTypes = {
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleSuccess: PropTypes.func,
    handleError: PropTypes.func,
  }

  static defaultProps = {
    userId: null,
    handleSuccess: () => {},
    handleError: () => {},
  }

  constructor(props) {
    super(props)
    this.input = createRef()
  }

  handleButtonClick = () => this.input.current.click()

  /** @type {React.EventHandler<React.FormEvent>} e */
  handleInputChange = async (e) => {
    const { userId, handleSuccess, handleError, handleFile } = this.props
    const file = /**@type {HTMLInputElement}*/ (e?.nativeEvent?.target)?.files?.[0]
    if (!file) {
      // no file
    }
    if ((file || { size: 0 })?.size >= HALF_MB) {
      // too big
    }
    if (handleFile && file) {
      handleFile(file)
      return
    }
    if (file && userId) {
      this.props.uploadSignature(userId, file).then(handleSuccess).catch(handleError)
    }
  }

  render() {
    return (
      <>
        <input
          type="file"
          aria-hidden
          hidden
          ref={this.input}
          onChange={this.handleInputChange}
          id="sig-upload"
        />
        <Button
          text="Click to Upload"
          small
          icon="upload"
          onClick={this.handleButtonClick}
          className="sig-upload-button"
          data-testid="uploadsigbtn"
        />
      </>
    )
  }
}

export default connect(null, { uploadSignature })(SignatureButton)
