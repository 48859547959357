import {
  VERTICAL_CURVE_DECLINE_COEFFICIENT,
  VERTICAL_CURVE_INCLINE_COEFFICIENT,
} from '../../../constants'

/** See README for illustration of this calculation */
const getCurvePathLength = (R: number, X: number) => R * Math.sin((Math.abs(X) * Math.PI) / 180)

export default function getVerticalCurveSectionLength(incline: number) {
  const radius =
    incline < 0 ? VERTICAL_CURVE_DECLINE_COEFFICIENT : VERTICAL_CURVE_INCLINE_COEFFICIENT

  return getCurvePathLength(radius, incline)
}
