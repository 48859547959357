import booleanOptions from 'shared/constants/booleanOptions'
import { FormConfigItem } from 'shared/types/formConfig'
import { DRIVE_SELECTION_DISABLED_REASON } from '../helpers'
import { IBuilderTileProps } from './BuilderTile/BuilderTile'

export default function(props: Partial<IBuilderTileProps>): Array<FormConfigItem> {
  const {
    conveyor,
    isactive,
    externalprojecttype,
    islocked,
    item,
    sectionsList,
    unit,
    incline,
    isMultiSpanClosedTop,
    hasInfeedDrive,
    hasDischargeDrive,
    allowIntermediatedrives,
    hasIntermediateDrive,
  } = props

  const {
    angle,
    feedbearingtypeid,
    feeddriveorientationid,
    feedtierod,
    feedtorquearmbracket,
    isaccessoriesoverridesopen,
    ismaterialoverridesopen,
    lengthinputmm,
    lengthinputfeetpart,
    lengthinputinchespart,
    lengthinput116inchespart,
    metaldetectortypeid,
    radius,
    type,
    warnings,
  } = item

  return [
    {
      key: isactive && !islocked ? 'type' : null,
      prettyName: 'Section Type',
      type: 'select',
      width: '200px',
      placeholder: 'Please select the section type',
      options: [
        {
          label: 'Bed Section',
          value: 'Straight',
        },
        {
          label: 'Horizontal Curve',
          value: 'HorizontalCurve',
          disabled: isMultiSpanClosedTop,
          disabledReason: DRIVE_SELECTION_DISABLED_REASON.MULTISPAN_CLOSEDTOP,
        },
        {
          label: 'Vertical Curve',
          value: 'VerticalCurve',
        },
        {
          label: 'Metal Detector',
          value: 'MetalDetector',
        },
        {
          label: 'Intermediate Drive',
          value: 'IntermediateDrive',
          ...(() => {
            if (!allowIntermediatedrives) {
              return {
                disabled: true,
                disabledReason:
                  DRIVE_SELECTION_DISABLED_REASON.CHAIN_STYLE_DOESNT_ALLOW_INTERMEDIATE,
              }
            } else if (hasIntermediateDrive) {
              return {
                disabled: true,
                disabledReason: DRIVE_SELECTION_DISABLED_REASON.HAS_INTERMEDIATE,
              }
            } else if (hasInfeedDrive || hasDischargeDrive) {
              return {
                disabled: true,
                disabledReason: DRIVE_SELECTION_DISABLED_REASON.HAS_INFEED_OR_DISCHARGE,
              }
            } else {
              return {}
            }
          })(),
        },
      ],
      value: type,
    },
    {
      key: 'warnings',
      type: 'warning',
      width: '200px',
      placeholder: 'Please fix the warnings',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (value != '') {
                throw new Error(value)
              } 
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      value: warnings.join('<br/>'),
    },
    {
      key: type === 'Straight' ? 'length' : null,
      prettyName: 'Horizontal Length',
      type: 'input',
      chipText: unit === 'Metric' ? 'mm' : null,
      autoFocus: true,
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Length of section is required.')
              } else if (value < 0) {
                throw new Error('Please enter a positive value.')
              } else if (value > 3657.6000000000004) {
                throw new Error('Length must be less than or equal to 12 ft / 3657.6mm.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      value:
        unit === 'Metric'
          ? lengthinputmm
          : (lengthinputfeetpart || 0) * 304.8 +
            (lengthinputinchespart || 0) * 25.4 +
            (lengthinput116inchespart || 0) * 1.5875,
    },
    {
      key: type === 'Straight' || type === 'HorizontalCurve' ? 'angle' : null,
      prettyName: 'Angle',
      type: 'input',
      chipText: '°',
      placeholder: '0',
      rules: [
        {
          required: type === 'HorizontalCurve',
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (type === 'HorizontalCurve') {
                if (value === 0) {
                  throw new Error('Angle of section is required.')
                } else if (value > 359 || value < 0) {
                  throw new Error('Angle must be between 1 to 359 degrees.')
                }
              } else if (value > 90 || value < -90) {
                throw new Error('Angle must be between -90 to 90 degrees.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      value: angle && type === 'HorizontalCurve' ? Math.abs(angle) : angle || 0,
      autoFocus: type === 'HorizontalCurve',
    },
    {
      key: type === 'HorizontalCurve' ? 'direction' : null,
      prettyName: 'Direction of Curve',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Please select the direction of the curve.',
        },
      ],
      value: angle >= 0 ? 1 : -1,
      options: [
        {
          label: 'Left',
          value: 1,
        },
        {
          label: 'Right',
          value: -1,
        },
      ],
    },
    {
      key: type === 'HorizontalCurve' ? 'radius' : null,
      prettyName: 'Radius',
      type: 'input',
      chipText: unit === 'English' ? 'in' : 'mm',
      rules: [
        {
          required: true,
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            const displayUnit = unit === 'English' ? 'in' : 'mm'
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (value === 0) {
                throw new Error('Radius of section is required.')
              } else if (value <= 0) {
                throw new Error('Please enter a valid value.')
              } else if (Math.abs(incline) <= 7 && value < conveyor.radiusminiflat) {
                throw new Error(
                  `Value of radius cannot be less than ${conveyor.radiusminiflat}${displayUnit}.`
                )
              } else if (Math.abs(incline) > 7 && value < conveyor.radiusminihelical) {
                throw new Error(
                  `Value of radius cannot be less than ${conveyor.radiusminihelical}${displayUnit}.`
                )
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      value: radius,
    },
    {
      key: ['Straight', 'MetalDetector', 'IntermediateDrive'].includes(type) || (type =='HorizontalCurve' && externalprojecttype == 'la')  ? null : 'incline',
      prettyName: 'Incline/Decline',
      type: 'input',
      chipText: '°',
      rules: [
        {
          required: type === 'VerticalCurve',
          transform: (value) => (isNaN(Number(value)) ? 0 : Number(value)),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Value must be a number.')
              } else if (type === 'VerticalCurve' && value === 0) {
                throw new Error('Incline/Decline angle is required.')
              } else if (type === 'VerticalCurve' && (value > 90 || value < -90)) {
                throw new Error('Angle must be between -90 to 90 degrees.')
              } else if (type === 'HorizontalCurve')
              {
                if (externalprojecttype != null && (value > conveyor.chain.maxhelicalinclinedecline || value < -conveyor.chain.maxhelicalinclinedecline)) {
                    throw new Error('Angle must be between -'+conveyor.chain.maxhelicalinclinedecline+' to '+conveyor.chain.maxhelicalinclinedecline+' degrees.')
                }
                else if (externalprojecttype == null && (value > 90 || value < -90)) {
                  throw new Error('Angle must be between -90 to 90 degrees.')
                }
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      value: item.incline,
      autoFocus: type === 'VerticalCurve',
    },
    {
      key: type === 'MetalDetector' || type === 'IntermediateDrive' || externalprojecttype == 'la' ? null : 'materials_overrides',
      prettyName: 'Material Overrides',
      type: 'materials',
      value: ismaterialoverridesopen,
    },
    {
      key:
        type === 'MetalDetector' || type === 'IntermediateDrive' || externalprojecttype == 'la' ? null : 'accessories_overrides',
      prettyName: 'Accessory Overrides',
      type: 'accessories',
      value: isaccessoriesoverridesopen,
    },
    {
      key: type === 'MetalDetector' ? 'metaldetectortypeid' : null,
      prettyName: 'Type',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Please select the type of Metal Detector.',
        },
      ],
      value: metaldetectortypeid,
      options: Object.values(sectionsList?.metaldetectors?.types || {}).map((option) => ({
        value: option.id,
        label: option.title,
        image: { src: option.image, alt: option.title },
      })),
    },
    {
      key: type === 'IntermediateDrive' ? 'feeddriveorientationid' : null,
      prettyName: 'Drive Orientation',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Please select the type of Intermediate Drive.',
        },
      ],
      value: feeddriveorientationid,
      options: Object.values(sectionsList?.intermediate?.driveOrientation || {}).map((option) => ({
        value: option.id,
        label: option.title,
        image: { src: option.image, alt: option.title },
      })),
    },
    {
      key: type === 'IntermediateDrive' ? 'feedtorquearmbracket' : null,
      prettyName: 'Torque Arm Bracket',
      type: 'checkbox',
      value: feedtorquearmbracket,
      label: 'Include Torque Arm Bracket',
      options: booleanOptions,
    },
    {
      key: type === 'IntermediateDrive' ? 'feedtierod' : null,
      prettyName: 'Tie Rod',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Please select an option for the tierod.',
        },
      ],
      value: feedtierod,
      options: booleanOptions,
    },
  ]
}
