import React from 'react'
import { Form, Radio } from 'antd'
import BuilderTileRadioOption from './components/BuilderTileRadioOption'
import { FormConfigItem } from 'shared/types/formConfig'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { IConveyorSection } from 'shared/types/swagger'

interface Props {
  isactive: boolean
  islocked: boolean
  field: FormConfigItem
  form: WrappedFormUtils
  item: IConveyorSection
  updateSection: (payload: { [key: string]: unknown }) => void
}

export default function BuilderTileRadio(props: Props) {
  const { field, item, form, isactive, islocked, updateSection } = props
  const { key, rules } = field
  const selectedOptionValue = item[key]

  const initialValue = (() => {
    if (key === 'direction') {
      return item.angle >= 0 ? 1 : -1
    } else {
      return selectedOptionValue
    }
  })()

  /** @param {import('antd/lib/radio').RadioChangeEvent} e */
  function handleChange(e) {
    const { value } = e.target
    if (key === 'direction') {
      updateSection({ angle: Math.abs(item.angle) * Number(value) })
    } else {
      updateSection({ [key]: value })
    }
  }

  return (
    <div className="builder-tile__expanded__content__wrapper" key={`form-item-${key}-${item.id}`}>
      <div className="radio-title">{field.prettyName}</div>
      <Form.Item>
        {form.getFieldDecorator(key, { initialValue, rules })(
          <Radio.Group
            disabled={!isactive || islocked}
            onChange={handleChange}
            className="builder-radiogroup"
          >
            {field.options.map((option, i) => (
              <BuilderTileRadioOption
                {...option}
                key={`option-${i}-${item?.id}-${option.value}`}
                selectedOptionValue={selectedOptionValue}
              />
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    </div>
  )
}
