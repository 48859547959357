import React, { Component } from 'react'
export default class ManagerIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        style={style}
        width={`${size}px`}
        height="100%"
        viewBox="0 0 37 36"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(0.361858, 0.000000)" fill={color}>
            <path d="M33.2632672,8.58184592 L25.6002728,13.3032901 L18.2523039,3.50206032 C18.1923691,3.42207836 18.0982611,3.375 17.9983146,3.375 C17.8983682,3.375 17.8042601,3.42207836 17.7443253,3.50206032 L10.4002943,13.3032901 L2.73336208,8.58184592 C2.50890643,8.44402228 2.21750788,8.62909974 2.25294825,8.89293356 L5.26144141,31.7559066 C5.30475741,32.0669942 5.57252905,32.3072011 5.89149234,32.3072011 L30.1130126,32.3072011 C30.428038,32.3072011 30.6997475,32.070932 30.7391257,31.7559066 L33.7476188,8.89293356 C33.7791214,8.62909974 33.4916606,8.44402228 33.2632672,8.58184592 Z M28.3016152,29.6137336 L7.69501292,29.6137336 L5.57646677,13.4923053 L11.0815365,16.8827669 L18.000283,7.65252069 L24.9190294,16.8827669 L30.4240992,13.4923053 L28.3016152,29.6137336 Z M18.0002832,17.7372733 C15.5548981,17.7372733 13.5662999,19.7258715 13.5662999,22.1712567 C13.5662999,24.6166418 15.5548981,26.60524 18.0002832,26.60524 C20.4456684,26.60524 22.4342666,24.6166418 22.4342666,22.1712567 C22.4342666,19.7258715 20.4456684,17.7372733 18.0002832,17.7372733 Z M18.000284,24.0732229 C16.9528244,24.0732229 16.1022556,23.2226541 16.1022556,22.1712567 C16.1022556,21.123797 16.9528244,20.2692904 18.000284,20.2692904 C19.0477437,20.2692904 19.8983125,21.1198592 19.8983125,22.1712567 C19.8983125,23.2187163 19.0477437,24.0732229 18.000284,24.0732229 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}
