import React from 'react'
import { DEFAULT_CONVEYOR_WIDTH, DEFAULT_PART_COLOR, SELECTED_PART_COLOR } from '../../../constants'
import useSVGScaling from '../../../hooks/useSVGScaling'

interface Props {
  pullPosition: string;
  selected: boolean;
  anchorAngleDeg: number;
  length: number;
  width: number;
}

/**
 * Since height (y axis) of the svg is the shortest and is cross the product flow,
 * the width is considered the length of the svg
 */

/** border-radius for the svg paths */
const ROUNDING = 1
/** x dimension for the svg. This value came from the file given by the designer */
const SVG_WIDTH = 1037
/** y dimension for the svg. This value came from the file given by the designer */
const SVG_HEIGHT = 629
/**
 * The SVG given doesn't fit with the 100px wide conveyor image. This value
 * is used to scale many things based on the chain width.
 *
 * This number was... found... but I don't remember the math involved. If other svgs
 * have a SCALE_FACTOR, simple divide 1 by that number to find this value. Those numbers
 * were found by eyeballing the tranform scale on the svg.
 * (e.g. before refacoring the SCALE_FACTOR was .503. 1 / .503 = 1.9880715)
 */
const SF_PER_CHAIN_WIDTH = 1.994312
/*
 * The scaling "length" value of the section.
 * See the introductory comment for explanation of "length"
 */
const svgLength = SVG_WIDTH * SF_PER_CHAIN_WIDTH

function IntermediateDriveSVG({ pullPosition, selected, anchorAngleDeg, length, width }: Props) {
  const { scaleHeight } = useSVGScaling({
    length,
    scaleFactorPerChainWidth: SF_PER_CHAIN_WIDTH,
    svgLength,
    height: SVG_HEIGHT,
    width: SVG_WIDTH,
  })
  const widthChange = (width - DEFAULT_CONVEYOR_WIDTH) * SF_PER_CHAIN_WIDTH

  const isRHP = pullPosition.includes('Right')
  const rotation = 90 - anchorAngleDeg
  const getDistanceFromSide = (base: number) => base / 2 - Number(scaleHeight(base)) / 2

  return (
    <g width={SVG_WIDTH} height={SVG_HEIGHT} viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}>
      <g fill="none" fillRule="evenodd">
        <g
          stroke={selected ? SELECTED_PART_COLOR : DEFAULT_PART_COLOR}
          strokeWidth={4 * (SF_PER_CHAIN_WIDTH + 2 / SVG_WIDTH)}
          transform={`rotate(${rotation}), skewY(180), scale(${
            1 / (SF_PER_CHAIN_WIDTH + 2 / SVG_WIDTH)
          })`}
        >
          <g transform="translate(0, -303.5)">
            {/* Left Vertical Long Bar */}
            <rect
              x="0"
              y={105 - widthChange}
              height={40}
              width={SVG_WIDTH + 2}
              rx={ROUNDING}
            ></rect>
            {/* 
              <path d="
              M1035,107 
              L1035,149 L4,149 
              C3.44771525,149 2.94771525,148.776142 2.58578644,148.414214 C2.22385763,148.052285 2,147.552285 2,147 L2,147 L2,109 C2,108.447715 2.22385763,107.947715 2.58578644,107.585786 C2.94771525,107.223858 3.44771525,107 4,107 L4,107 L1035,107 Z"></path> */}

            {/* Right Vertical Long Bar */}
            <rect x="0" y="263" height={40} width={SVG_WIDTH + 2} rx={ROUNDING}></rect>
            {/* <path d="M1035,261 L1035,303 L4,303 C3.44771525,303 2.94771525,302.776142 2.58578644,302.414214 C2.22385763,302.052285 2,301.552285 2,301 L2,301 L2,263 C2,262.447715 2.22385763,261.947715 2.58578644,261.585786 C2.94771525,261.223858 3.44771525,261 4,261 L4,261 L1035,261 Z"></path> */}

            {/* Left Vertical Guide Rail */}
            <rect
              x={542}
              y={51.5 - widthChange}
              height={scaleHeight(11)}
              width={SVG_WIDTH - 541}
              rx={ROUNDING}
            ></rect>
            {/* <path d="M1035,53 L1035,64 L543,64 C542.447715,64 541.947715,63.7761424 541.585786,63.4142136 C541.223858,63.0522847 541,62.5522847 541,62 L541,62 L541,55 C541,54.4477153 541.223858,53.9477153 541.585786,53.5857864 C541.947715,53.2238576 542.447715,53 543,53 L543,53 L1035,53 Z"></path> */}

            {/* Right Vertical Guide Rail */}
            <rect
              x={542}
              y={346 - getDistanceFromSide(88)}
              height={scaleHeight(11)}
              rx={ROUNDING}
              width={SVG_WIDTH - 541}
            ></rect>
            {/* <path d="M1035,346 L1035,357 L543,357 C542.447715,357 541.947715,356.776142 541.585786,356.414214 C541.223858,356.052285 541,355.552285 541,355 L541,355 L541,348 C541,347.447715 541.223858,346.947715 541.585786,346.585786 C541.947715,346.223858 542.447715,346 543,346 L543,346 L1035,346 Z"></path> */}

            {/* Drive Shaft */}
            <rect
              x={542}
              y={isRHP ? 115 - widthChange : -214 - widthChange}
              width={67}
              height={510 + widthChange}
              rx={ROUNDING}
            ></rect>

            {/* Top Horizontal Section */}
            <rect
              x={955}
              y={62 - widthChange}
              width={82}
              height={width * SF_PER_CHAIN_WIDTH + Number(scaleHeight(84))}
            ></rect>

            {/* Left Bottom GR Part */}
            <rect x={564} y={31 - widthChange} width={22} height={20} rx={ROUNDING}></rect>
            {/* <path d="M584,33 C584.552285,33 585.052285,33.2238576 585.414214,33.5857864 C585.776142,33.9477153 586,34.4477153 586,35 L586,35 L586,53 L563,53 L563,35 C563,34.4477153 563.223858,33.9477153 563.585786,33.5857864 C563.947715,33.2238576 564.447715,33 565,33 L565,33 Z"></path> */}

            {/* Left Middle GR Part */}
            <rect x={782} y={38 - widthChange} width={51} height={13} rx={ROUNDING}></rect>
            {/* <path d="M831,40 C831.552285,40 832.052285,40.2238576 832.414214,40.5857864 C832.776142,40.9477153 833,41.4477153 833,42 L833,42 L833,53 L781,53 L781,42 C781,41.4477153 781.223858,40.9477153 781.585786,40.5857864 C781.947715,40.2238576 782.447715,40 783,40 L783,40 Z"></path> */}

            {/* Left Top GR Part */}
            <rect x={912} y={1 - widthChange} width={89} height={30} rx={ROUNDING}></rect>
            {/* Left Top GR Part Connector */}
            <rect
              x={946}
              y={30 - widthChange}
              width={34}
              height={scaleHeight(21)}
              rx={ROUNDING}
            ></rect>

            {/* Right Top GR Part Connector*/}
            <rect x={946} y="357" width={34} height={scaleHeight(21)} rx={ROUNDING}></rect>

            {/* Right Top GR Part */}
            <rect x={912} y="378" width={89} height={scaleHeight(30)} rx={ROUNDING}></rect>

            {/* Right Middle GR Part */}
            <rect x={782} y="357" width={51} height={scaleHeight(13)} rx={ROUNDING}></rect>
            {/* <path d="M833,357 L833,368 C833,368.552285 832.776142,369.052285 832.414214,369.414214 C832.052285,369.776142 831.552285,370 831,370 L831,370 L783,370 C782.447715,370 781.947715,369.776142 781.585786,369.414214 C781.223858,369.052285 781,368.552285 781,368 L781,368 L781,357 L833,357 Z"></path> */}

            {/* Right Bottom GR Part */}
            <rect x={564} y={358} width={22} height={scaleHeight(20)} rx={ROUNDING}></rect>
            {/* <path d="M586,358 L586,376 C586,376.552285 585.776142,377.052285 585.414214,377.414214 C585.052285,377.776142 584.552285,378 584,378 L584,378 L565,378 C564.447715,378 563.947715,377.776142 563.585786,377.414214 C563.223858,377.052285 563,376.552285 563,376 L563,376 L563,358 L586,358 Z"></path> */}
          </g>
        </g>
      </g>
    </g>
  )
}

export default IntermediateDriveSVG
