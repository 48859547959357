export default (renderDelete) => {
  return [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Customer Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: renderDelete,
    },
  ]
}
