import { createAction } from '@reduxjs/toolkit'
import { IConfirmData, IPreferences } from './ScreenReducer'
import { persistor } from 'index'
import * as Sentry from '@sentry/browser'
import { ModalKey } from 'features/Modal'

export const purgeAndRefresh = () => async (error: Error) => {
  if (error) {
    Sentry.captureException(error)
  }
  await persistor.purge()
  // eslint-disable-next-line no-self-assign
  // window.location = window.location // refresh
}

export const closeConfirmModal = createAction('CLOSE_CONFIRM_MODAL')

export const openConfirmModal = createAction<IConfirmData>('OPEN_CONFIRM_MODAL')

export const setLoading = createAction<{ loading: boolean; loadingMessage?: string } | boolean>(
  'SET_LOADING'
)
export const setLoadingOverride = createAction<{
  loadingOverride?: boolean
  loadingMessage?: string
}>('SET_LOADING_OVERRIDE')
export const setFullScreenId = createAction('SET_FULL_SCREEN_ID')
export const setModalContent = createAction<ModalKey | null>('SET_MODAL_CONTENT')
export const setPendingResponse = createAction<string>('SET_PENDING_RESPONSE')
export const deletePendingResponse = createAction<string>('DELETE_PENDING_RESPONSE')
export const setModalData = createAction('SET_MODAL_DATA')

export const setNotesScreenPreferences = createAction<IPreferences>('SET_NOTES_PREFERENCES')
