import { roleOptions } from 'shared/constants/userRoles'
import { FormConfigItem } from 'shared/types/formConfig'

export default (user, password): Array<FormConfigItem> => [
  {
    key: 'firstname',
    type: 'input',
    width: '300px',
    label: 'First Name',
    placeholder: 'Enter First Name',
    rules: [
      {
        required: true,
        message: 'First Name is required.',
      },
    ],
    value: user.firstname,
  },
  {
    key: 'lastname',
    type: 'input',
    width: '300px',
    label: 'Last Name',
    placeholder: 'Enter Last Name',
    rules: [
      {
        required: true,
        message: 'Last Name is required.',
      },
    ],
    value: user.lastname,
  },
  {
    key: 'email',
    type: 'input',
    width: '300px',
    label: 'Email Address',
    placeholder: 'Enter Email Address',
    value: user.email,
  },
  {
    key: 'roles',
    type: 'select',
    width: '300px',
    label: 'User Type',
    placeholder: 'Select User Type',
    rules: [
      {
        required: true,
        message: 'At least one User Type is required.',
      },
    ],
    options: roleOptions,
    value: user.roles.filter((role) => role !== 'InternalUser'),
  },
  {
    key: 'password',
    type: 'input',
    width: '300px',
    label: 'Change User Password',
    placeholder: 'Enter New Password',
    rules: [
      {
        required: password !== '',
        validator: (rule, value, cb) => {
          try {
            if (value !== '' && value.length < 4) {
              throw new Error('Password must have at least 4 characters.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
    value: '',
  },
  {
    key: 'passwordConfirm',
    type: 'input',
    width: '300px',
    label: 'Confirm Password',
    placeholder: 'Re-enter New Password',
    rules: [
      {
        required: password !== '',
        validator: (rule, value, cb) => {
          try {
            if (password !== '') {
              if (value === '' || value === undefined) {
                throw new Error('Please re-enter the new password.')
              } else if (value !== password) {
                throw new Error('Passwords must match.')
              }
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      },
    ],
  },
  {
    key: 'signature',
    type: 'signature',
    width: '300px',
    label: 'Signature',
  },
  {
    width: '300px',
    type: 'radio',
    key: 'issparepartuser',
    label: 'Spare Parts User',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    value: user.issparepartuser.toString(),
  },
]
