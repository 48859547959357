import React from 'react'
import { escapeCSSChars } from 'utils/helpers'

const getHighlightedRowCSS = (className: string) => `
  .rk${className}{background:rgba(0,107,182,0.1);}
  .rk${className}>td.ant-table-column-sort{background:rgba(0,107,182,0.11)!important;}
`
const getExcludedRowCSS = (rowKey: string) => `
  tr[data-row-key^="${rowKey}"]{background:rgba(145,150,161,0.1);}
`

interface Props {
  updating: boolean
  editedPartNumbers?: Array<string>
  excludedPartNumbers?: Array<string>
}

export default function CostImportRowStyle(props: Props) {
  const { updating, editedPartNumbers = [], excludedPartNumbers = [] } = props
  let editedRowsCss = ''

  if (updating) {
    editedRowsCss = editedPartNumbers
      .map((pn) => escapeCSSChars(pn.replace(/[\s]+/g, '-')))
      .map(getHighlightedRowCSS)
      .join('')
  }

  return (
    <style>
      {editedRowsCss}
      {excludedPartNumbers.map(getExcludedRowCSS)}
    </style>
  )
}
