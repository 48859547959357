import React, { Component } from 'react'
export default class InfoOutlineIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="2 2 27 27"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M16.0002207,22.5672105 C15.2687207,22.5672105 14.6758392,21.9743291 14.6758392,21.242829 L14.6758392,15.062382 C14.6758392,14.330882 15.2687207,13.7380006 16.0002207,13.7380006 C16.7317208,13.7380006 17.3246022,14.330882 17.3246022,15.062382 L17.3246022,21.242829 C17.3246022,21.9743291 16.7317208,22.5672105 16.0002207,22.5672105 Z M16.0002207,12.963723 C15.2687207,12.963723 14.6758392,12.3708415 14.6758392,11.6393415 L14.6758392,10.7564205 C14.6758392,10.0249204 15.2687207,9.432039 16.0002207,9.432039 C16.7317208,9.432039 17.3246022,10.0249204 17.3246022,10.7564205 L17.3246022,11.6393415 C17.3246022,12.3708415 16.7317208,12.963723 16.0002207,12.963723 Z M16.0002207,6.64876299 C10.8439621,6.64876299 6.64876299,10.8435206 6.64876299,15.9997793 C6.64876299,21.1555964 10.8439621,25.3507955 16.0002207,25.3507955 C21.1564794,25.3507955 25.351237,21.1555964 25.351237,15.9997793 C25.351237,10.8435206 21.1564794,6.64876299 16.0002207,6.64876299 Z M16.0002207,27.9995585 C9.38361078,27.9995585 4,22.6163892 4,15.9997793 C4,9.38272786 9.38361078,4 16.0002207,4 C22.6168307,4 28,9.38272786 28,15.9997793 C28,22.6163892 22.6168307,27.9995585 16.0002207,27.9995585 Z"
            fill={color}
          />
        </g>
      </svg>
    )
  }
}
