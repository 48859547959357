import { ifDecimalRoundToNum, formatStatisticsValues } from 'utils/helpers'

export const formatStatisticsData = (statisticsData) => {
  const {
    activecount,
    activeestimate,
    activeestimatetrend,
    averageestimate,
    averageestimatetrend,
    newprojectcount,
    newprojectcounttrend,
    projectsold,
    projectsoldcount,
    projectsoldcounttrend,
    perioddetail,
    quotedratio,
    soldratio,
  } = statisticsData

  const overviewData = [
    {
      label: 'Total Projects',
      value: newprojectcount.toLocaleString(),
      icon: 'startEstimate',
      diff: ifDecimalRoundToNum(newprojectcounttrend, 1),
    },
    {
      label: 'Average Estimate',
      value: formatStatisticsValues(averageestimate, false),
      icon: 'tag',
      diff: ifDecimalRoundToNum(averageestimatetrend, 1),
    },
    {
      label: 'Active Estimates',
      value: formatStatisticsValues(activeestimate, false),
      icon: 'accountManager',
      diff: ifDecimalRoundToNum(activeestimatetrend, 1),
    },
    {
      label: 'Total Projects Sold',
      value: ifDecimalRoundToNum(projectsoldcount, 1),
      icon: 'dollar',
      diff: ifDecimalRoundToNum(projectsoldcounttrend, 1),
    },
  ]

  const statisticsTotals = {
    revenue: {
      estimates: activeestimate,
      sold: projectsold,
    },
    quantity: {
      estimates: activecount,
      sold: projectsoldcount,
    },
    closeratio: {
      estimates: quotedratio,
      sold: soldratio,
    },
  }

  return {
    overviewData,
    perioddetail,
    statisticsTotals,
  }
}
