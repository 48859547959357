import { getDriveBoxLocationType } from 'features/Estimator/components/ConveyorBuilder/helpers/getDriveBoxLocationType'
import _ from 'lodash'
import booleanOptions from 'shared/constants/booleanOptions'
import { FormConfigItem } from 'shared/types/formConfig'
import {
  HasMaterials,
  HasNumberOfRods,
  ITransfersListMicrospan,
} from 'utils/api/list/types/transfers'
import { IInfeedDischargeConfigData } from '../../types'

export default function getTransfersConfig(
  params: IInfeedDischargeConfigData
): Array<FormConfigItem> {
  const {
    displayTransfers,
    transfersList,
    displayLowProfileTransferOption,
    isDrive,
    type,
    item,
    isStandard,
    transferType: transferTypeKey,
    wireMeshSelected,
    microspan6mmSelected,
    transferMaterial,
  } = params
  const { transfer } = item

  if (!(transfer && displayTransfers)) return []

  const transferTypeOptions = transfersList[transferTypeKey]

  const driveBoxLocationType = getDriveBoxLocationType(type)

  return [
    {
      key: 'transfertypeid',
      isTransfer: true,
      prettyName: 'Transfer Type',
      type: 'radio',
      placeholder: 'Please select a transfer type.',
      rules: [
        {
          required: true,
          message: 'Please select a transfer type.',
        },
      ],
      value: transfer.transfertypeid,
      options: Object.values(transfersList.types)
        .filter((option) => {
          return !displayLowProfileTransferOption || isDrive
            ? option.title !== 'Low Profile'
            : option.title === 'Low Profile'
        })
        .map((option) => ({
          value: option.id,
          label: option.title,
          image: {
            src: option.image,
            alt: option.title,
          },
        })),
    },
    {
      key: transferTypeKey === 'microspan' ? 'nosebarid' : null,
      isTransfer: true,
      prettyName: 'Transfer Nosebar',
      placeholder: 'Please select a Nosebar',
      type: 'select',
      width: '200px',
      options: Object.values(transfersList.microspan.nosebar).map((option) => ({
        label: option.title,
        value: option.id,
      })),
      value:
        transfer.nosebarid ||
        Object.values(transfersList?.microspan?.nosebar || {}).find(
          (nosebarOption) => nosebarOption.isdefault
        )?.id,
    },
    {
      key: transferMaterial.key,
      isTransfer: true,
      prettyName: transferMaterial.label,
      type: 'select',
      width: '200px',
      placeholder: 'Please select a material',
      rules: [
        {
          required: true,
          message: `${transferMaterial.label} is required.`,
        },
      ],
      options: transferTypeKey
        ? _.map((transferTypeOptions as HasMaterials).materials, (option) => ({
            label: option.title,
            value: option.id,
          }))
        : [],
      value: transfer[transferMaterial.key],
    },
    {
      key:
        transferTypeKey && Object.keys(transferTypeOptions).includes('numberofrods')
          ? 'numberofrods'
          : null,
      isTransfer: true,
      prettyName: 'Number of Rods',
      type: 'select',
      width: '200px',
      placeholder: 'Please select the number of rods',
      rules: [
        {
          required: true,
          message: 'Number of Rods is required.',
        },
      ],
      options: transferTypeKey
        ? _.map((transferTypeOptions as HasNumberOfRods).numberofrods, (option) => ({
            label: option.title,
            value: option.numericvalue,
          }))
        : [],
      value: transfer.numberofrods,
    },
    {
      key:
        transferTypeKey && Object.keys(transferTypeOptions).includes('bottomguardtypes')
          ? 'bottomguardtypeid'
          : null,
      isTransfer: true,
      prettyName: 'Bottom Guard Type',
      type: 'select',
      width: '200px',
      placeholder: 'Please select a bottom guard type',
      rules: [
        {
          required: true,
          message: 'Bottom Guard Type is required.',
        },
      ],
      options: transferTypeKey
        ? _.map((transferTypeOptions as ITransfersListMicrospan).bottomguardtypes, (option) => ({
            label: option.title,
            value: option.id,
          }))
        : [],
      value: transfer.bottomguardtypeid,
    },
    {
      key:
        transferTypeKey && Object.keys(transferTypeOptions).includes(driveBoxLocationType)
          ? 'driveboxlocationid'
          : null,
      isTransfer: true,
      prettyName: 'Drive Box Location',
      type: 'radio',
      placeholder: 'Please select a drive box location.',
      rules: [
        {
          required: true,
          message: 'Please select a drive box location.',
        },
      ],
      value: transfer.driveboxlocationid,
      options: transferTypeKey
        ? Object.values(
            (transferTypeOptions as ITransfersListMicrospan)[driveBoxLocationType] || {}
          ).map((option) => ({
            value: option.id,
            label: option.title,
            image: {
              src: option.image,
              alt: option.title,
            },
          }))
        : [],
    },
    {
      key: isStandard ? null : 'plainorraisedid',
      prettyName: 'Plain or Raised',
      type: 'radio',
      isTransfer: true,
      rules: [
        {
          required: true,
          message: 'Please select a Plain or Raised.',
        },
      ],
      value: transfer.plainorraisedid,
      options: Object.values(transfersList.height || {}).map((option) => ({
        value: option.id,
        label: option.title,
      })),
    },
    {
      key: microspan6mmSelected == true ? null : 'fingerontransfer',
      isTransfer: true,
      type: 'checkbox',
      value: transfer.fingerontransfer,
      label: 'Fingers on Transfer?',
      options: booleanOptions,
    },
  ]
}
