import {
  setDefaultAccessoryDataForConveyor,
  runUpdateDefaultMaterialOrAccessoryForConveyor,
} from '../../../../redux/EstimatorActions'
import ConveyorService from '../../../../../../utils/api/conveyor/ConveyorService'
import store from 'utils/../reduxStore'
import _ from 'lodash'

// This action's name is intentionally lowercase in order for the name
// portion of the function name to match the backend response.

export const initializefloorsupports = (conveyorId, floorsupportsData) => async (dispatch) => {
  dispatch(
    setDefaultAccessoryDataForConveyor({
      conveyorId,
      accessoryKey: 'floorsupports',
      accessoryData: floorsupportsData,
    })
  )
}

export const updateFloorsupports = ({ conveyorId, updatedFloorsupportsFields }) => (dispatch) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'floorsupports',
      updatedFields: updatedFloorsupportsFields,
      type: 'accessories',
    })
  )
}

export const addFloorSupport = (conveyorId, row) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const storeState = store.getState()
    // support details come from their own endpoint /accessories/floorsupports/details but we stuff them in with the other data
    // from the /accessories/floorsupports get.
    let { details } = storeState.EstimatorReducer[conveyorId].accessories.floorsupports
    details = _.cloneDeep(details)
    try {
      const data = await ConveyorService.postFloorSupportsForConveyor(conveyorId, row)
      details.push(data)
      dispatch(
        runUpdateDefaultMaterialOrAccessoryForConveyor({
          conveyorId,
          materialKey: 'floorsupports',
          updatedFields: { details },
          type: 'accessories',
          saveToBackend: false,
        })
      )
    } catch (error) {}
  })
}

export const updateFloorSupport = (conveyorId, row) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const storeState = store.getState()
    // support details come from their own endpoint /accessories/floorsupports/details but we stuff them in with the other data
    // from the /accessories/floorsupports get.
    let { details } = storeState.EstimatorReducer[conveyorId].accessories.floorsupports
    details = _.cloneDeep(details)
    const oldState = _.cloneDeep(details)
    try {
      const detailIndex = details.findIndex((detail) => detail.id === row.id)
      details[detailIndex] = row
      dispatch(
        runUpdateDefaultMaterialOrAccessoryForConveyor({
          conveyorId,
          materialKey: 'floorsupports',
          updatedFields: { details },
          type: 'accessories',
          saveToBackend: false,
        })
      )

      const payload = []
      _.forEach(row, (value, key) => {
        if (key !== 'id') {
          payload.push({ op: 'replace', path: `/${key}`, value })
        }
      })

      await ConveyorService.updateFloorSupportsForConveyor(conveyorId, payload, row.id)
    } catch (error) {
      dispatch(
        runUpdateDefaultMaterialOrAccessoryForConveyor({
          conveyorId,
          materialKey: 'floorsupports',
          updatedFields: { details: oldState },
          type: 'accessories',
          saveToBackend: false,
        })
      )
    }
  })
}

export const deleteFloorSupport = (conveyorId, rowId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const storeState = store.getState()
    let { details } = storeState.EstimatorReducer[conveyorId].accessories.floorsupports
    details = _.cloneDeep(details)
    const oldState = _.cloneDeep(details)
    try {
      const detailIndex = details.findIndex((detail) => detail.id === rowId)
      details.splice(detailIndex, 1)
      dispatch(
        runUpdateDefaultMaterialOrAccessoryForConveyor({
          conveyorId,
          materialKey: 'floorsupports',
          updatedFields: { details },
          type: 'accessories',
          saveToBackend: false,
        })
      )

      await ConveyorService.deleteFloorSupportsForConveyor(conveyorId, rowId)
    } catch (error) {
      dispatch(
        runUpdateDefaultMaterialOrAccessoryForConveyor({
          conveyorId,
          materialKey: 'floorsupports',
          updatedFields: { details: oldState },
          type: 'accessories',
          saveToBackend: false,
        })
      )
    }
  })
}
