/**
 * @typedef {import('shared/types/ListElement').ListElement} ListElement
 */

/**
 * Get `textvalue` for use in searching options
 *
 * @param {ListElement} railSelection
 * @param {ListElement} bracketSelection
 * @returns {string}
 */
export default function getTextvalue(railSelection, bracketSelection) {
  if (!railSelection || !bracketSelection) return null

  return `${railSelection?.title}${bracketSelection?.title}`
}
