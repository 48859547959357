import _ from 'lodash'

export default (props) => {
  const { sideframesOptions, sideframesData } = props

  if (!sideframesData) return null

  return {
    material: {
      dataKey: 'materialid',
      required: true,
      prettyName: 'Sideframe Material Core Specs',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Core Spec Material is required.',
        },
      ],
      options: sideframesOptions.materials,
    },
    cleanouttype: {
      dataKey: 'cleanouttypeid',
      required: true,
      prettyName: 'Cleanout Type',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Cleanout Type required.',
        },
      ],
      options: sideframesOptions.cleanouttypes,
    },
    getPreviewImage: () => {
      if (!sideframesData) {
        return null
      }
      const fields = {
        materialid: sideframesOptions.materials,
        cleanouttypeid: sideframesOptions.cleanouttypes,
      }
      let available = true
      let imageTitle = ''
      _.forEach(fields, (options, fieldKey) => {
        const existingValue = sideframesData[fieldKey]
        if (existingValue && available) {
          const selectedOption = options[sideframesData[fieldKey]]
          imageTitle += selectedOption.title
        } else {
          available = false
        }
      })
      return available
        ? _.find(sideframesOptions.materialscleanouttypes, (o) => o.title === imageTitle).image
        : available
    },
    standardcolors: {
      dataKey: 'standardcolorid',
      required: false,
      prettyName: 'Painted Sideframe Color',
      type: 'radio',
      rules: [
        {
          required: false,
        },
      ],
      options: {
        ...sideframesOptions.standardcolors,
        '': {
          title: 'Manual Color',
          id: '',
        },
      },
    },
  }
}
