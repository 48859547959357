import React from 'react'

export default (props) => {
  const {
    ceilingsupportsOptions: {
      materials,
      // mountinglocations,
      styles,
      brackets,
    },
    ceilingsupportsData: {
      materialid: selectedMaterialId,
      // supportmountingid: selectedSupportMounting
    },
    isMetric,
  } = props

  return {
    materials: {
      dataKey: 'materialid',
      required: true,
      prettyName: 'Support Material',
      type: 'radio',
      vertical: true,
      rules: [
        {
          required: true,
          message: 'A Mounting Location is required.',
        },
      ],
      options: materials,
    },
    materialPreview: () => {
      return materials.find[selectedMaterialId]
    },
    ceilingsupportsTable: {
      required: true,
      type: 'table',
      label: 'Ceiling Supports',
      key: null,
      tableDataPropName: 'ceilingsupports',
      context: React.createContext(),
      apiPath: 'Ceilingsupports',
      columns: [
        {
          title: 'Quantity',
          key: 'quantity',
          dataIndex: 'quantity',
          editable: true,
          inputType: 'number',
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.quantity,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Quantity must be a number and greater than 0',
            },
          ]
        },
        {
          title: 'Style',
          key: 'styleid',
          dataIndex: 'styleid',
          editable: true,
          inputType: 'select',
          options: styles,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: styles[row.styleid] ? styles[row.styleid].title : '',
            }
          },
        },
        {
          title: 'Bracket',
          key: 'bracketid',
          dataIndex: 'bracketid',
          editable: true,
          inputType: 'select',
          options: brackets,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: brackets[row.bracketid] ? brackets[row.bracketid].title : '',
            }
          },
        },
        {
          title: 'Locking Collars',
          key: 'lockcollars',
          dataIndex: 'lockcollars',
          editable: true,
          inputType: 'number',
          required: false,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.lockcollars,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Locking Collars must be a number and greater than 0',
            },
          ]
        },
        {
          title: `Common Width (${isMetric ? 'mm' : 'in'})`,
          key: 'chainwidth',
          dataIndex: 'chainwidth',
          editable: true,
          inputType: 'number',
          required: false,
          render: (text, row, index) => {
            if (!row) {
              return {
                children: 'N/A',
              }
            }
            return {
              children: row.chainwidth,
            }
          },
          rules: [
            {
              type: 'number',
              transform: (val) => Number(val),
              min: 0,
              message: 'Chain Width must be a number and greater than 0',
            },
          ]
        },
        ...([181, 182].includes(selectedMaterialId)
          ? [
              {
                title: 'Thick Walls',
                key: 'thickwalls',
                dataIndex: 'thickwalls',
                editable: true,
                inputType: 'checkbox',
                label: 'Yes',
                required: false,
                render: (text, row, index) => {
                  const yesOrNo = row?.thickwalls ? 'Yes' : 'No'
                  return {
                    children: row ? yesOrNo : 'N/A',
                  }
                },
              },
            ]
          : []),
      ],
    },
  }
}
