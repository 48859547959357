import getHorizontalCurvePoint from '../components/HorizontalCurve/helpers/getHorizontalCurvePoint'
import getVerticalCurveSectionLength from '../components/VerticalCurve/helpers/getVerticalCurveSectionLength'
import { IBuilderSectionData } from '../types/types'
import getPointAtLength from './getPointAtLength'

/**
 * The point where the next SVG should start.
 * All except Horizontal Curve (bottom-right) start at the top-left of the previous segment
 * so the connecting point is always the bottom left
 * @param {SVGGeometryElement} path i.e.  document.getElementById(prevSection.id.toString()) as unknown) as SVGGeometryElement
 */
export const findConnectingPoint = (
  prevSection: Partial<IBuilderSectionData>,
  chainwidth: number,
  path: SVGGeometryElement,
  conveyorWidth: number
): DOMPoint => {
  const { angle, incline, type, radius } = prevSection
  const topLength = conveyorWidth
  const bottomLength = conveyorWidth
  let connectingPoint = new DOMPoint()
  let totalLen: number

  try {
    totalLen = path.getTotalLength()
  } catch (error) {
    console.log('error finding SVGElement w/ supplied sectionId', error)
  }

  /** Non Curvy */
  if (type === 'Infeed') {
    /**
     * See getSVGPathByType.ts - the path starts at the top left, kinda
     * the top-left and top right parts of the path are curves. The curves are
     * based on a 1/10 conveyorWidth radius circle. So Math.PI * (conveyorWidth / 10) gives the path length of
     * 4 curves. This path only includes 1 curve (Math.PI * 5).
     *
     * The length of one side must be calculated as well. This boils down to
     * (totalpath - width*2) / 2. One part of the width includes 2 curves or
     * Math.PI * 10 (the two curves) - (conveyorWidth - 40 (radius doubled))
     */
    // eslint-disable-next-line
    const sideLength = (totalLen - conveyorWidth * 2) / 2
    connectingPoint = getPointAtLength(path, totalLen - sideLength)
  } else if (type === 'Straight' || type === 'MetalDetector' || type === 'IntermediateDrive') {
    const sidesLength = totalLen - conveyorWidth * 2
    const sideLength = sidesLength / 2
    connectingPoint = getPointAtLength(path, topLength + sideLength + bottomLength)
    /** Curvy segments from here on */
  } else if (type === 'VerticalCurve') {
    /** The incline value determines how long the svg will be
     *   ..........         ..........
     *   |    .-'`| (4°)    |..--''``| (2°)
     *   |.-'`    |         ``````````
     *   ``````````
     */
    connectingPoint = getPointAtLength(
      path,
      topLength + Math.abs(getVerticalCurveSectionLength(incline)) + bottomLength
    )
  } else if (type === 'HorizontalCurve') {
    if (angle > 0) {
      connectingPoint = getPointAtLength(path, conveyorWidth)
    } else {
      // Angle is negative for right horizontal curse
      const updatedRadius = (radius / chainwidth) * conveyorWidth
      connectingPoint = getPointAtLength(
        path,
        getHorizontalCurvePoint(updatedRadius, angle, conveyorWidth)
      )
    }
  }

  const ctm = path ? path.getCTM() : new DOMMatrix()
  return connectingPoint.matrixTransform(ctm)
}
