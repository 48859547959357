import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DefaultHeader } from '../../shared/Headers'
import { updateWashdown } from '../redux/default/WashdownActions'
import { captureSentryError } from 'utils/helpers'

class Washdown extends Component {
  render() {
    if (this.props.loading) {
      return null
    }

    const {
      washdownData: { parameteractive },
      washdownMeta: { open },
      updateWashdown,
      conveyorId,
    } = this.props
    return (
      <div className="material-accessory-item">
        <DefaultHeader
          conveyorId={conveyorId}
          type="materials"
          fieldKey="washdown"
          checked={parameteractive}
          noExpandAllowed
          onClickCheck={() => {
            updateWashdown({
              conveyorId,
              updatedWashdownFields: { parameteractive: !parameteractive },
            })
          }}
          open={open}
          onClickOpen={() => {}}
          title="Washdown"
        />
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer } = state
    const { conveyorId, match } = props
    const {
      params: { versionId },
    } = match

    const washdownData = EstimatorReducer?.[conveyorId]?.materials?.washdown
    const washdownMeta =
      EstimatorMetaReducer?.[conveyorId]?.materialsAccessoriesMetadata?.materials?.washdown || {}

    if (!washdownData) {
      return { loading: true }
    }

    return {
      versionId,
      conveyorId,
      washdownMeta,
      washdownData,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateWashdown,
  })(Washdown)
)
