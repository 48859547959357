import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table } from 'antd'
import _ from 'lodash'
import { normalTransport, productAccum } from './TMResultsConfig'
import './TMResultsTable.scss'
import { captureSentryError } from 'utils/helpers'

class TMResultsTable extends Component {
  render() {
    const {
      accumulatedtorque,
      cautiontorque,
      conveyorsections,
      discharge,
      failtorque,
      infeed,
      requiredtorque,
      unit,
    } = this.props.conveyor

    const filteredSections = _.filter(
      conveyorsections,
      (s) => s.type !== 'MetalDetector' && s.type !== 'IntermediateDrive'
    )

    const sortedSections = _.sortBy(filteredSections, (s) => s.sectionnumber)

    const data = [
      {
        ...infeed,
        sectionnumber: 0,
      },
      ...sortedSections,
      {
        ...discharge,
        sectionnumber: filteredSections.length + 1,
      },
    ]

    return (
      <div className="tm-results-table-container">
        <div className="torquemaster-results">
          <h1>Torquemaster Results</h1>
          <div className="torquemaster-results-summary">
            <div className="">
              Required Shaft Torque:
              <span>
                {requiredtorque} {unit !== 'English' ? 'n-ms' : 'in-lbs'}
              </span>
            </div>
            <div className="">
              In Accumulation:
              <span>
                {accumulatedtorque} {unit !== 'English' ? 'n-ms' : 'in-lbs'}
              </span>
            </div>
            <div className="">
              Max Chain Pull:
              <span>
                {failtorque} {unit !== 'English' ? 'newtons' : 'lbs Force'}
              </span>
            </div>
            <div className="">
              Caution Chain Pull:
              <span>
                {cautiontorque} {unit !== 'English' ? 'newtons' : 'lbs Force'}
              </span>
            </div>
          </div>
        </div>
        <div className="normal-transport-calculations">
          <h2>Normal Transport Calculations</h2>

          <Table
            rowKey={(record) => record.id + Math.random()}
            className="normal-transport-table"
            columns={normalTransport(unit)}
            dataSource={data}
            size="middle"
            pagination={false}
          />
        </div>

        <div className="product-accum-calculations">
          <h2>Product Accumulation Calculations</h2>
          <Table
            rowKey={(record) => record.id + Math.random()}
            className="product-accum-table"
            columns={productAccum(unit)}
            dataSource={data}
            size="middle"
            pagination={false}
          />
        </div>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { conveyorId } = props
    return {
      conveyor: state.ConveyorReducer.conveyors[conveyorId],
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default connect(mapStateToProps, null)(TMResultsTable)
