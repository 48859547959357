import React, { Component } from 'react'
export default class StarOutlineIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M16,23.0517609 C16.2099982,23.0517609 16.4204338,23.1020729 16.6116196,23.2031346 L22.0982588,26.0932341 L21.050018,19.9669756 C20.9773937,19.5421668 21.1178299,19.1081706 21.4262647,18.8067358 L25.8707881,14.465899 L19.7309671,13.5716569 C19.3035334,13.5090949 18.9338492,13.2404723 18.7431008,12.8528507 L16,7.28308728 L13.2564617,12.8528507 C13.0657133,13.2404723 12.6960291,13.5090949 12.2685954,13.5716569 L6.12921194,14.4654615 L10.5737353,18.8067358 C10.8821701,19.1081706 11.0226063,19.5421668 10.949982,19.9669756 L9.90174121,26.0932341 L15.3883804,23.2031346 C15.5795662,23.1020729 15.7900018,23.0517609 16,23.0517609 M23.8412434,29.8071389 C23.6316828,29.8071389 23.4216846,29.7572643 23.2291863,29.6562027 L16,25.8477988 L8.77081367,29.6562027 C8.32894257,29.8885132 7.79257229,29.851326 7.38788835,29.5568911 C6.98364192,29.2633312 6.7810812,28.7663356 6.86508046,28.2737149 L8.24625579,20.2032235 L2.39518233,14.4886488 C2.03818547,14.1395269 1.9099991,13.618469 2.06443524,13.1437857 C2.21887138,12.6686648 2.62924277,12.3226054 3.12317592,12.250856 L11.2067922,11.0735539 L14.8226979,3.73280605 C15.0431959,3.283935 15.4999419,3 16,3 C16.5000581,3 16.9568041,3.283935 17.1773021,3.73280605 L20.7927703,11.0735539 L28.8768241,12.250856 C29.3707572,12.3226054 29.7811286,12.6686648 29.9355648,13.1437857 C30.0900009,13.618469 29.9618145,14.1395269 29.6048177,14.4886488 L23.7537442,20.2032235 L25.1349195,28.2737149 C25.2189188,28.7663356 25.0163581,29.2633312 24.6121116,29.5568911 C24.3833012,29.7231396 24.1129285,29.8071389 23.8412434,29.8071389"
            fill={color}
          ></path>
        </g>
      </svg>
    )
  }
}
