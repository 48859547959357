import React, { PureComponent } from 'react'
import './CollapsibleSection.scss'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'
import cn from 'classnames'
import { ActionMenu } from 'shared/components'

/**
 * @typedef {Object} Props
 * @prop {Function} onClickOpen
 * @prop {any} title
 * @prop {boolean} open
 * @prop {React.ReactNode} children
 * @prop {import('shared/components/ActionMenu').ActionMenuOption[]} options
 *
 * @extends {PureComponent<Props>}
 */
class CollapsibleSection extends PureComponent {
  renderInner = () => {
    const { children, open } = this.props
    return open ? <div className="collapse-section__inner">{children}</div> : null
  }

  render() {
    const { onClickOpen, title, open, options } = this.props

    return (
      <>
        <div
          className={cn('collapse-section', 'collapse-section--clickable')}
          onClick={() => {
            onClickOpen()
          }}
        >
          <Icon
            className="collapse-section__expand"
            icon={open ? 'minus' : 'plus'}
            size={20}
            color={open ? Colors.novaBlue : Colors.ash}
          />
          <span className="collapse-section__name">{title}</span>
          <ActionMenu options={options} menuTitle="Actions" className="collapse-section__actions" />
        </div>
        {this.renderInner()}
      </>
    )
  }
}

export default CollapsibleSection
