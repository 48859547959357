import React, { memo } from 'react'
import { MeasuringUnit } from 'shared/types/swagger'
import InfoIcon from './components/InfoIcon'

interface Props {
  angle: number
  cautions: number
  incline: number
  length: number
  radius: number
  type: string
  warnings: number
  chainwidth: number
  unit: MeasuringUnit
  hoverEnabled: boolean
  hover: () => void
}

function InfoBox(props: Props) {
  const {
    angle = 0,
    cautions,
    incline,
    length,
    radius,
    type,
    warnings,
    chainwidth,
    unit,
    hover,
    hoverEnabled,
  } = props

  const renderValue = (num, displayUnit = 'Ft') => {
    let recalculatedValue = (num / 100) * chainwidth

    if (unit === 'Metric') {
      return Number.isInteger(recalculatedValue)
        ? `${recalculatedValue} mm`
        : `${recalculatedValue.toFixed(2)} mm`
    } else if (unit === 'English') {
      if (displayUnit === 'Ft') {
        recalculatedValue = recalculatedValue / 12
      } else {
        recalculatedValue = num / 25.4
      }
      return Number.isInteger(recalculatedValue)
        ? `${recalculatedValue} ${displayUnit}`
        : `${recalculatedValue.toFixed(2)} ${displayUnit}`
    }
  }

  /** TODO: refactor this nonsense */
  const height = (() => {
    if (warnings && cautions && type === 'VerticalCurve') return 100
    if (warnings && cautions && radius) return 155
    if (warnings && cautions) return 130
    if ((warnings || cautions) && type === 'VerticalCurve') return 90
    if ((warnings || cautions) && radius) return 125
    if (warnings || cautions) return 98
    if (type === 'VerticalCurve') return 35
    if (radius) return 95
    return 72
  })()

  // eslint-disable-next-line no-nested-ternary
  const distance = warnings && cautions ? 60 : warnings || cautions ? 30 : 0

  return (
    <g className={hoverEnabled && hover ? '' : 'hidden'} transform="translate(30, 10)">
      <rect x="10" y="10" width="190" height={height} fill="#545767ab" stroke="#C0C0C0" />
      {type === 'HorizontalCurve' || type === 'VerticalCurve' ? (
        <InfoIcon
          type={incline < 0 ? 'Decline' : 'Incline'}
          y={type === 'VerticalCurve' ? distance + 18 : distance + 24}
          value={`${Math.abs(incline)}°`}
        />
      ) : (
        <InfoIcon type="Length" y={distance + 25} value={renderValue(length)} />
      )}
      <g transform={`translate(0, ${type === 'VerticalCurve' ? '-30' : '0'})`}>
        {type === 'VerticalCurve' ? null : (
          <InfoIcon
            y={distance + 49}
            type="Angle"
            value={`${type === 'HorizontalCurve' && angle !== 0 ? Math.abs(angle) : angle}°`}
          />
        )}
        {radius ? (
          <InfoIcon
            type="Radius"
            y={distance + 75}
            value={`${((radius * chainwidth) / 100).toFixed(2)} ${unit === 'Metric' ? 'mm' : 'in'}`}
          />
        ) : null}
      </g>

      {warnings ? <InfoIcon type="Warning" value={warnings} /> : null}
      {cautions ? (
        <InfoIcon type="Caution" y={warnings ? distance - 15 : undefined} value={cautions} />
      ) : null}
    </g>
  )
}

export default memo(InfoBox)
