import * as SVG from 'shared/assets/svgIcons'
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaRegDotCircle } from 'react-icons/fa'
import { TiCogOutline } from 'react-icons/ti'
import { FiEdit3, FiMaximize2, FiMinimize2 } from 'react-icons/fi'
import { MdMoreHoriz, MdMoreVert, MdPanoramaFishEye, MdZoomIn } from 'react-icons/md'

const icons = {
  accountManager: SVG.AccountManagerIcon,
  add: SVG.PlusCircleIcon,
  cancel: SVG.CancelIcon,
  caution: SVG.CautionIcon,
  check: SVG.CheckIcon,
  chevronDown: FaAngleDown,
  chevronLeft: FaAngleLeft,
  chevronRight: FaAngleRight,
  circle: MdPanoramaFishEye,
  circleDot: FaRegDotCircle,
  clone: SVG.CloneIcon,
  customers: SVG.CustomersIcon,
  dashboard: SVG.DashboardIcon,
  delete: SVG.DeleteIcon,
  designer: SVG.DesignerIcon,
  dotsH: MdMoreHoriz,
  dotsV: MdMoreVert,
  dollar: SVG.DollarIcon,
  download: SVG.DownloadIcon,
  drag: SVG.DragIcon,
  editSquare: SVG.EditSquareIcon,
  editLine: FiEdit3,
  envelope: SVG.EnvelopeIcon,
  estimator: SVG.EstimatorIcon,
  expand: FiMaximize2,
  external: SVG.ExternalAltIcon,
  history: SVG.HistoryIcon,
  home: SVG.HomeIcon,
  info: SVG.InfoIcon,
  infoOutline: SVG.InfoOutlineIcon,
  internal: SVG.InternalAltIcon,
  manager: SVG.ManagerIcon,
  minimize: FiMinimize2,
  minus: SVG.MinusIcon,
  minusOutline: SVG.MinusOutlineIcon,
  password: SVG.LockIcon,
  paste: SVG.PasteIcon,
  plus: SVG.PlusIcon,
  reload: SVG.ReloadIcon,
  save: SVG.SaveIcon,
  search: SVG.SearchIcon,
  settings: TiCogOutline,
  star: SVG.StarIcon,
  starOutline: SVG.StarOutlineIcon,
  startEstimate: SVG.StartEstimateIcon,
  sync: SVG.SyncIcon,
  tag: SVG.TagIcon,
  trendDown: SVG.TrendDownIcon,
  trendUp: SVG.TrendUpIcon,
  upload: SVG.UploadIcon,
  user: SVG.UserIcon,
  x: SVG.XIcon,
  zoomIn: MdZoomIn,
}

export type IconKey = keyof typeof icons
export default icons
