import { persistReducer, createMigrate } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import srcReducer from './srcReducer'
import localForage from 'localforage'
import { migrations } from 'bootstrap/migrations'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: localForage,
  blacklist: ['VersionReducer', 'ConveyorReducer', 'ListReducer', 'EstimatorReducer','ScreenReducer'],
  migrate: createMigrate(migrations),
  version: 0,
}

// TODO: blacklist loading, loadingMessage, loadingOverride, pendingApiResponse from ScreenReducer

const persistedReducer = persistReducer(persistConfig, srcReducer)

export type RootState = ReturnType<typeof srcReducer>

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: true,
})

export type Store = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>
export type AppThunk = ThunkAction<void, Store, null, Action<string>>
export default store
