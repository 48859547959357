// sync_with_syteline

import { syncProjectToSyteline } from 'features/Version/redux/VersionActions'
import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { AntIconsKey } from 'shared/components/Icon'
import { useAppDispatch } from 'shared/hooks/app'
import { closeConfirmModal, openConfirmModal } from 'shared/redux/ScreenActions'
import { IConfirmData } from 'shared/redux/ScreenReducer'
import { typedUseSelector } from 'utils/helpers'

type SytelineSyncButtonProps = {
  issyncedwithsyteline: boolean;
  versionId: number;
}

export default function SytelineSyncButton({
  issyncedwithsyteline,
  versionId,
}: SytelineSyncButtonProps) {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const permissions = typedUseSelector((state) => state.UserReducer.permissions)
  const dispatch = useAppDispatch()
  const disabled = issyncedwithsyteline !== false || isFetching

  function openSyncConfirmModal() {
    dispatch(openConfirmModal<IConfirmData>({
      headerText: 'Confirm',
      bodyText: 'Are you sure you want to sync with Syteline?',
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      onConfirm: sync,
      onCancel: () => dispatch(closeConfirmModal())
    }))
  }

  async function sync() {
    setIsFetching(true)
    await dispatch(syncProjectToSyteline(versionId))
    setIsFetching(false)
  }

  if (!permissions.includes('sync_with_syteline')) return null

  const icon: AntIconsKey = (() => {
    if (issyncedwithsyteline) return 'check-circle'
    if (isFetching) return 'sync'
    return 'cloud-sync'
  })()

  return (
    <Button
      text={`Sync${issyncedwithsyteline ? 'ed' : ''} With Syteline`}
      antIcon={icon}
      disabled={disabled}
      small
      antIconProps={{ spin: isFetching }}
      onClick={openSyncConfirmModal}
    />
  )
}
