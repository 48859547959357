import { IGearmotorSpecsAggregateResponse } from 'shared/types/swagger'
import { formatIdAsKey } from 'utils/helpers'

export default function arrangeDesiredUnitOptions(
  desiredUnitOptions: Array<IGearmotorSpecsAggregateResponse>
) {
  const sortedOptions = desiredUnitOptions.map((d) => {
    const sortedInnerOptions = d.gearmotors.map((g, i) => {
      return {
        id: g.gearmotorid,
        listorder: i + 1,
        label: g.description,
      }
    })
    return {
      id: d.desiredunitid,
      options: formatIdAsKey(sortedInnerOptions),
    }
  })
  return formatIdAsKey(sortedOptions)
}
