import React, { memo, ReactElement } from 'react'

interface Props {
  fill: string
}

function RadiusInfoIcon({ fill }: Props): ReactElement {
  return (
    <g fill={fill} stroke="none">
      <path
        d="M74.301,0C33.333,0,0,33.333,0,74.301c0,40.969,33.333,74.301,74.301,74.301c40.969,0,74.301-33.332,74.301-74.301
  C148.602,33.333,115.27,0,74.301,0z M132.768,77.954h3.537c-1.897,32.56-28.978,58.468-62.004,58.468
  c-34.254,0-62.121-27.867-62.121-62.121c0-34.253,27.867-62.12,62.121-62.12c33.846,0,61.436,27.211,62.09,60.902h-3.623
  c-1.348,0-2.437,1.089-2.437,2.437S131.42,77.954,132.768,77.954z"
      />
      <path
        d="M110.842,73.083h-7.308c-1.349,0-2.437,1.089-2.437,2.437s1.088,2.436,2.437,2.436h7.308c1.348,0,2.438-1.088,2.438-2.436
    C113.277,74.172,112.189,73.083,110.842,73.083z"
      />
      <path
        d="M96.227,73.083h-7.309c-1.348,0-2.438,1.089-2.438,2.437s1.09,2.436,2.438,2.436h7.309c1.347,0,2.437-1.088,2.437-2.436
    C98.662,74.172,97.572,73.083,96.227,73.083z"
      />
      <path
        d="M125.459,73.083h-7.309c-1.349,0-2.437,1.089-2.437,2.437s1.088,2.436,2.437,2.436h7.309c1.348,0,2.436-1.088,2.436-2.436
    S126.807,73.083,125.459,73.083z"
      />
      <path
        d="M81.609,73.083H74.3c-1.347,0-2.436,1.089-2.436,2.437s1.089,2.436,2.436,2.436h7.31c1.347,0,2.436-1.088,2.436-2.436
    S82.956,73.083,81.609,73.083z"
      />
    </g>
  )
}

export default memo(RadiusInfoIcon)
