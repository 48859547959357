import React from 'react'
import { DEFAULT_CONVEYOR_WIDTH, DEFAULT_PART_COLOR, SELECTED_PART_COLOR } from '../../../constants'
import { TransferTypeKey } from '../../../types/types'

interface Props {
  selected: boolean;
  anchorAngleDeg: number;
  typeKey: TransferTypeKey;
  width: number;
}

export const SF_PER_CHAIN_WIDTH = 2.62
const SVG_HEIGHT = 266
const SVG_WIDTH = 1187
const CONNECTING_OFFSET = 163

function IdlerSVG({ selected, anchorAngleDeg, typeKey, width }: Props) {
  const rotation = 90 - anchorAngleDeg
  const widthChange = (width - DEFAULT_CONVEYOR_WIDTH) * SF_PER_CHAIN_WIDTH

  return (
    <g
      width={SVG_WIDTH}
      height={SVG_HEIGHT}
      viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
      preserveAspectRatio="none"
    >
      <title>Idler - Standard</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          stroke={selected ? SELECTED_PART_COLOR : DEFAULT_PART_COLOR}
          transform={`rotate(${rotation}), scale(${1 / SF_PER_CHAIN_WIDTH})`}
        >
          <g transform="translate(-24.5, -261.5)" strokeWidth={4 * SF_PER_CHAIN_WIDTH}>
            <line
              x1={typeKey === 'infeed' ? 24.5 : 325 * SF_PER_CHAIN_WIDTH + 24.5}
              y1={1 - widthChange}
              x2={typeKey === 'infeed' ? 24.5 : 325 * SF_PER_CHAIN_WIDTH + 24.5}
              y2={261.5}
              strokeLinecap="round"
            ></line>
            <line
              x1={24.5}
              y1={261.5}
              x2={330 * SF_PER_CHAIN_WIDTH}
              y2={261.5}
              strokeLinecap="round"
            ></line>
            <line
              x1={24.5}
              y1={0 + -widthChange}
              x2={330 * SF_PER_CHAIN_WIDTH}
              y2={0 + -widthChange}
              strokeLinecap="round"
            ></line>
            <rect
              x={typeKey === 'infeed' ? 215 : 598}
              y={14.5 - widthChange}
              width="90"
              height={233 + widthChange}
            ></rect>
          </g>
        </g>
      </g>
    </g>
  )
}

export default IdlerSVG
