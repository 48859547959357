import { createAction } from '@reduxjs/toolkit'
import { IVersionWithAutorizedActions } from 'features/Version/shared/types'
import { IProject, IVersion } from 'shared/types/swagger'
import { IProjectState } from './ProjectReducer'
import { IOrganizedSettingsList } from 'utils/api/list/types'

export const getProjectsSuccess = createAction('GET_PROJECTS_SUCCESS')
export const deleteVersionSuccess = createAction('DELETE_VERSION_SUCCESS')
export const searchProjectsPurge = createAction('SEARCH_PROJECTS_PURGE')
export const searchProjectsSuccess = createAction<{
  searchResults: Record<string, IProject>;
  searchTerm: string;
  currentSearchPage: number;
  totalSearchPages: number;
}>('SEARCH_PROJECT_SUCCESS')
export const selectProjectFromQueueOrSearch = createAction<number>('SELECT_PROJECT')
export const setProjectManually = createAction<IProject>('SET_PROJECT')
export const selectProjectTab = createAction('SELECT_PROJECT_TAB')
export const updateMasterVersion = createAction<{ projectId: number; versionId: number }>(
  'UPDATE_MASTER_VERSION'
)
export const updateProjectReducerVersion = createAction<IVersionWithAutorizedActions>(
  'UPDATE_PROJECT_REDUCER_VERSION'
)
export const updateProjects = createAction<Partial<IProjectState>>('UPDATE_PROJECTS')
export const updateVersions = createAction<Record<string, IVersionWithAutorizedActions | IVersion>>(
  'UPDATE_VERSIONS'
)
export const updateProject = createAction<IProject>('UPDATE_PROJECT')
export const saveListsSettings = createAction<{ regularProjectSettings: IOrganizedSettingsList, laProjectSettings: IOrganizedSettingsList }>('SAVE_LISTS_SETTINGS')
