import React, { PureComponent } from 'react'
import ProjectNotesToggle from './components/ProjectNotesToggle'
import ProjectNotesDisplay from './components/ProjectNotesDisplay'
import './ProjectNotes.scss'

/**
 * @typedef {Object} Props
 * @prop {string} versionId
 */

/** @extends PureComponent<Props> */
class ProjectNotes extends PureComponent {
  state = {
    showNotes: false,
  }

  /** @param {React.MouseEvent} e */
  handleClick = (e) => {
    e.preventDefault()
    this.setState(({ showNotes }) => ({ showNotes: !showNotes }))
  }

  render() {
    const { showNotes } = this.state
    const { versionId } = this.props

    return (
      <>
        <ProjectNotesDisplay
          show={showNotes}
          versionId={versionId}
          closeNotes={() => {
            this.setState({ showNotes: false })
          }}
        />
        <ProjectNotesToggle handleClick={this.handleClick} show={showNotes} />
      </>
    )
  }
}

export default ProjectNotes
