import React, { PureComponent } from 'react'
import { Form, Button } from 'shared/components'
import { Input } from 'antd'

/**
 * @typedef {import('antd/lib/form').FormComponentProps} FormComponentProps
 *
 * @typedef {Object} Props
 * @prop {Boolean} [editing=false]
 * @prop {Object} [note]
 * @prop {function} handleSave
 * @prop {function} handleCancel
 */

/** @extends {PureComponent<Props & FormComponentProps>} */
class ProjectNoteForm extends PureComponent {
  state = {
    saving: false,
  }

  /** @param {React.FormEvent} e */
  handleSubmit = (e) => {
    const { handleSave, note, form } = this.props

    e.preventDefault()

    form.validateFields(async (errors, values) => {
      if (errors) return
      this.setState({ saving: true }, async () => {
        const success = await handleSave(note, values.note)
        if (success) {
          form.resetFields()
        }
        this.setState({ saving: false })
      })
    })
  }

  /** @type {React.EventHandler<React.MouseEvent>} */
  handleCancel = () => {
    const { form, handleCancel } = this.props
    handleCancel(form.resetFields)
  }

  render() {
    const { form, editing, note } = this.props
    const { saving } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className="project-notes-form">
        <Form.Item label={`${editing ? 'Edit' : 'Leave'} a Note`} style={{ width: '100%' }}>
          {form.getFieldDecorator('note', {
            initialValue: editing ? note.note : '',
          })(<Input.TextArea disabled={saving} autoSize={{ minRows: 2 }} />)}
        </Form.Item>

        <Button
          onClick={this.handleCancel}
          disabled={saving}
          small
          type="reset"
          text="Cancel"
          secondary
          className="project-notes-form__cancel"
        />
        <Button disabled={saving} small type="submit" text="Save" />
      </Form>
    )
  }
}

/** @type {import('antd/lib/form/interface').FormWrappedProps<FormComponentProps & Props>} */
const formInstance = Form.create({ name: 'project-note' })
const projectNoteForm = formInstance(ProjectNoteForm)

export default projectNoteForm
