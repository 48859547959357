import React, { Component } from 'react'

const MIN_LENGTH = 1
const MAX_LENGTH = 256

/**
 * @typedef {Object} Props
 * @prop {string} text
 * @prop {function} handleChange
 * @prop {boolean} disabled
 *
 * @extends {Component<Component['props'] & Props>}
 */
class InlineEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      text: this.props.text,
    }
    this.ref = React.createRef()
  }

  // componentWillReceiveProps(nextProps) {
  //   const isTextChanged = (nextProps.text !== this.props.text)
  //   const isEditingChanged = (nextProps.editing !== this.props.editing)
  //   const nextState = {}
  //   if (isTextChanged) {
  //     nextState.text = nextProps.text
  //   }
  //   if (isEditingChanged) {
  //     nextState.editing = nextProps.editing
  //   }
  //   if (isTextChanged || isEditingChanged) {
  //     this.setState(nextState)
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    const inputElem = this.ref.current
    const { editing } = this.state
    if (editing && !prevState.editing) {
      inputElem.focus()
      inputElem.setSelectionRange(0, inputElem.value.length)
    } else if (editing && prevProps.text !== this.props.text) {
      this.finishEditing()
    }
  }

  startEditing = () => {
    const { text, disabled } = this.props
    if (!disabled) {
      this.setState({ editing: true, text })
    }
  }

  /** @param {React.KeyboardEvent} e */
  handleKeyDown = (e) => {
    e.stopPropagation()
    if (e.key === ' ' || e.key === 'Enter') {
      this.startEditing()
    }
  }

  finishEditing = () => {
    const { text } = this.state
    const { text: initialText } = this.props
    if (this.isInputValid(text) && initialText !== text) {
      this.commitEditing()
    } else if (initialText === text || !this.isInputValid(text)) {
      this.cancelEditing()
    }
  }

  cancelEditing = () => {
    const { text } = this.props
    this.setState({ editing: false, text })
  }

  commitEditing = () => {
    const { text } = this.state
    this.setState({ editing: false, text })
    this.props.handleChange(text)
  }

  isInputValid = (text) => {
    const textLength = text.length
    return textLength >= MIN_LENGTH && textLength <= MAX_LENGTH
  }

  keyDown = (event) => {
    if (event.keyCode === 13) {
      this.finishEditing()
    } else if (event.keyCode === 27) {
      this.cancelEditing()
    }
  }

  textChanged = (event) => {
    this.setState({
      text: event.target.value.trim(),
    })
  }

  render() {
    const { editing, text } = this.state
    const { text: propsText, handleChange, disabled, ...restProps } = this.props

    if (editing) {
      return (
        <input
          onKeyDown={this.keyDown}
          onBlur={this.finishEditing}
          defaultValue={text}
          onChange={this.textChanged}
          disabled={disabled}
          ref={this.ref}
          {...restProps}
        />
      )
    }

    return (
      <span
        onClick={this.startEditing}
        onKeyDown={this.handleKeyDown}
        tabIndex={0}
        aria-disabled={disabled}
        {...restProps}
      >
        {text}
      </span>
    )
  }
}

export default InlineEdit
