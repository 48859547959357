import _ from 'lodash'
import { IConveyorSection } from 'shared/types/swagger'

type CautionAndWarnings = {
  warnings: number;
  cautions: number;
}

export function countCautionAndWarnings(
  item: IConveyorSection
): CautionAndWarnings {
  const { length, ...rest } = item
  const data: CautionAndWarnings = {
    warnings: 0,
    cautions: 0,
  }

  _.forEach(rest, (value) => {
    if (value === 'Caution') {
      data.cautions = data.cautions + 1
    }
    if (value === 'Warning') {
      data.warnings = data.warnings + 1
    }
  })
  return data
}