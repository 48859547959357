import { createAction, ActionCreatorWithPayload } from '@reduxjs/toolkit'
import _ from 'lodash'
import { formatIdAsKey } from 'utils/helpers'

export const initializeConveyorSectionsMetadata = createAction(
  'INITIALIZE_CONVEYOR_SECTIONS_METADATA'
)
export const initializeConveyorSectionsMetadatas = createAction(
  'INITIALIZE_CONVEYOR_SECTIONS_METADATAS'
)
export const addOneSectionMetadata = createAction('ADD_ONE_SECTION_METADATA')
export const collapseAllSections = createAction('COLLAPSE_ALL_SECTIONS')
/** @type {ActionCreatorWithPayload<{collapsed: boolean, conveyorId: number, collapseOthers?: boolean, sectionId: string | number}, 'COLLAPSE_SECTION'>} */
export const collapseSection = createAction('COLLAPSE_SECTION')
export const deleteSectionMetadata = createAction('DELETE_SECTION_METADATA')
/** @type {ActionCreatorWithPayload<{conveyorId: number, rotateCanvas: boolean}, 'SET_CANVAS_ROTATION'>} */
export const setCanvasRotation = createAction('SET_CANVAS_ROTATION')
/** @type {ActionCreatorWithPayload<{conveyorId: number, selectedSectionId: string}, 'SELECT_SECTION'>} */
export const selectSection = createAction('SELECT_SECTION')
/** @type {ActionCreatorWithPayload<{validated: boolean, conveyorId: number, sectionId: string | number}, 'VALIDATE_SECTION'>} */
export const validateSection = createAction('VALIDATE_SECTION')

export const addConveyorSectionMetadata = (conveyors) => async (dispatch) => {
  const payload = {
    conveyors: [],
  }
  _.forEach(conveyors, (conveyor) => {
    const { conveyorsections, discharge, infeed } = conveyor
    const infeedConveyorSectionsDischarge = {
      infeed: {
        id: `infeed-${infeed.id}`,
      },
      ...conveyorsections,
      discharge: {
        id: `discharge-${discharge.id}`,
      },
    }
    const defaultMaterialAccessoryState = { open: false }
    const sectionsMetadata = _.map(infeedConveyorSectionsDischarge, (section) => {
      return {
        id: section.id,
        collapsed: false,
        sectionFirstValidation: false,
        validated: false,
        materialsAccessoriesMetadata: {
          materials: {
            sideframes: defaultMaterialAccessoryState,
            wearstrips: defaultMaterialAccessoryState,
          },
          accessories: {
            driptray: defaultMaterialAccessoryState,
            guiderails: defaultMaterialAccessoryState,
            underguard: defaultMaterialAccessoryState,
            sidewalls: defaultMaterialAccessoryState,
            fixedendstop: defaultMaterialAccessoryState,
            floorsupports: defaultMaterialAccessoryState,
            ceilingsupports: defaultMaterialAccessoryState,
          },
        },
      }
    })
    payload.conveyors.push({
      conveyorId: conveyor.id,
      selectedSectionId: null,
      sectionsMetadata: formatIdAsKey(sectionsMetadata),
      rotateCanvas: false,
    })
  })
  dispatch(initializeConveyorSectionsMetadatas(payload))
}
