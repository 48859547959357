export default (props) => {
  const { wearstripsOptions } = props

  return {
    materials: {
      dataKey: 'materialid',
      required: true,
      prettyName: 'Wearstrip Material',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'Wearstrip Material is required.',
        },
      ],
      options: wearstripsOptions.materials,
    },
    lubricated: {
      dataKey: 'lubricated',
      required: true,
      prettyName: 'Lubricated Wearstrip',
      type: 'radio',
      rules: [
        {
          required: true,
          message: 'A selection is required.',
        },
      ],
      options: [
        { id: true, title: 'Lubricated' },
        { id: false, title: 'No Lubrication' },
      ],
    },
    railschoiceid: {
      type: 'rail',
    },
  }
}
