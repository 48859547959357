import React, { ReactNode, CSSProperties, ComponentPropsWithoutRef } from 'react'
import './ModalContent.scss'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'
import ModalSection from './ModalSection'
import { useDispatch } from 'react-redux'
import { setModalContent } from 'shared/redux/ScreenActions'

interface ModalContentProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title'> {
  title: string | ReactNode;
  noHeader?: boolean;
  children: ReactNode;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  id?: string;
}

function ModalContent({
  title,
  children,
  width = '600px',
  height = '600px',
  noHeader = false,
  ...restProps
}: ModalContentProps) {
  const dispatch = useDispatch()

  return (
    <div className="modal-content" style={{ height, width }} {...restProps}>
      {!noHeader && <ModalHeader title={title} handleClose={() => dispatch(setModalContent(null))} />}
      <div className="modal-inner">{children}</div>
    </div>
  )
}

ModalContent.Section = ModalSection
ModalContent.Footer = ModalFooter

export default ModalContent
