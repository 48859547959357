import * as React from 'react'
import { Form, Input } from 'antd'
import EnglishLengthInputs from './components/EnglishLengthInputs'
import { FormConfigItem } from 'shared/types/formConfig'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { IConveyorSection, MeasuringUnit } from 'shared/types/swagger'
import { IConveyorWithModifiedInfeedDischarge } from 'features/Conveyor/shared/types'

interface Props {
  isactive: boolean
  islocked: boolean
  field: FormConfigItem
  form: WrappedFormUtils
  item: IConveyorSection
  unit: MeasuringUnit
  conveyor: IConveyorWithModifiedInfeedDischarge
  customValidateFields: () => unknown
  updateSection: (payload: { [key: string]: unknown }) => void
}

export default function BuilderTileInput(props: Props) {
  const {
    item,
    field,
    form,
    unit,
    isactive,
    islocked,
    customValidateFields,
    updateSection,
    conveyor,
  } = props
  const helicalBend = item.type === 'HorizontalCurve' && Math.abs(item.incline) > 7

  if (item.type === 'Straight' && unit === 'English' && field.key === 'length') {
    return (
      <EnglishLengthInputs
        form={form}
        field={field}
        item={item}
        isactive={isactive}
        islocked={islocked}
        customValidateFields={customValidateFields}
        updateSection={updateSection}
      />
    )
  }

  const typeIsCurve = item.type === 'HorizontalCurve'

  /** @type {React.ChangeEventHandler<HTMLInputElement>} */
  function handleChange(e) {
    let { value } = e.currentTarget
    if (typeIsCurve) {
      if (field.key === 'angle') {
        const direction = form.getFieldValue('direction')
        value = (parseFloat(value) * direction).toString()
      }
      if (field.key === 'incline') {
        const inclineDeclineValue = parseFloat(form.getFieldValue('incline')) || 0
        const { radiusminiflat, radiusminihelical } = conveyor

        const oldValueIsGTE5 = Math.abs(inclineDeclineValue) > 7
        const newValueIsGTE5 = Math.abs(parseFloat(value) || 0) > 7

        if (!((oldValueIsGTE5 && newValueIsGTE5) || (!oldValueIsGTE5 && !newValueIsGTE5))) {
          form.setFieldsValue({
            radius: newValueIsGTE5 ? radiusminihelical : radiusminiflat,
          })
        }
      }
    }
    const payload = { [field.key]: value }
    if (field.key === 'length' && unit === 'Metric') {
      payload.lengthinputmm = value
    }
    updateSection(payload)
  }

  return (
    <div
      className="builder-tile__expanded__content__wrapper"
      key={`form-item-${field.key}-${item.id}`}
      style={{ position: 'relative' }}
    >
      <Form.Item label={field.prettyName} className={`option-label option-label__${field.key}`}>
        {form.getFieldDecorator(field.key, {
          initialValue: (() => {
            if (field.value) return field.value
            if (field.key === 'incline' && typeIsCurve) return 0
            return null
          })(),
          rules: field.rules,
        })(
          <Input
            disabled={!isactive || islocked}
            className="option-input"
            addonAfter={field.chipText}
            placeholder={field.placeholder}
            onChange={handleChange}
          />
        )}
      </Form.Item>
      {field.key === 'incline' && helicalBend ? (
        <div className="helical-bend">*Helical Bend</div>
      ) : null}
      {field.key === 'incline' && item.type === 'VerticalCurve'? (
        <div className="max-recommended-incline">Recommended between +/- {conveyor.chain.recommendedmaxangle}°</div>
      ) : null}
    </div>
  )
}
