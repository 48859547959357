import { Form, Tabs, Checkbox, Radio as AntRadio } from 'antd'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { SectionOverrideHeader } from 'features/Estimator/components/shared/Headers'
import { updateGuiderails } from '../../redux/sectionOverrides/GuideRailsActions'
import {
  updateSectionOverrideMaterialAcessoriesMetaData,
  addAccessoryWithSidesToConveyorSection,
  updateAccessoryWithSides,
} from 'features/Estimator/redux/SectionOverrideActions'
import { fetchDefaultAccessoryForConveyor } from 'features/Estimator/redux/EstimatorActions'
import { Radio, InputWithValidation, Select } from 'shared/components'
import GuideRailsConfig from '../GuideRails/GuideRailsConfig'
import _ from 'lodash'
import sidewallsGuiderailsConfig from './sidewallsGuiderailsConfig'
import { captureSentryError } from 'utils/helpers'

const { TabPane } = Tabs

const titleStyles = {
  maxWidth: '120px',
}

class Guiderails extends PureComponent {
  renderFields = (sideId, sideName) => {
    const {
      guiderailsData,
      updateGuiderails,
      conveyorId,
      sectionId,
      isactive,
      islocked,
      guiderailsOptions,
    } = this.props
    const config = GuideRailsConfig(guiderailsOptions, guiderailsData[sideName], true)
    const disabled = !isactive || islocked

    return _.map(config, (fieldConfig) => {
      const { dataKey, prettyName, options, rules, vertical, type, className = '' } = fieldConfig

      if (type === 'radio') {
        return (
          <div
            className={className}
            key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
          >
            <Radio
              disabled={disabled}
              dataKey={dataKey}
              prettyName={prettyName}
              margin="0 0 10px 0"
              options={options}
              onChange={(val) =>
                updateGuiderails({
                  sectionId,
                  conveyorId,
                  sideId,
                  sideName,
                  updatedGuiderailsFields: { [dataKey]: val },
                })
              }
              rules={rules}
              vertical={vertical}
              initialValue={_.get(guiderailsData[sideName], dataKey)}
              tinyImages="70px"
            />
          </div>
        )
      }
      if (type === 'rails') {
        return (
          <div
            className="guiderails-railsfield indented sectionoverride"
            key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
          >
            <label>Rails</label>
            {this.renderGuiderailRailFields(
              sideName,
              sideId,
              config.railschoiceid.railspreferredchoices,
              true
            )}
          </div>
        )
      }
      if (type === 'distancebetweenrailsid') {
        const distanceOptions = config.distancebetweenrailslist.options
        if (Array.isArray(distanceOptions) && distanceOptions.length) {
          return (
            <div
              className="guiderails-inline"
              key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
            >
              <label>Distance Between Rails</label>
              <Select
                small
                disabled={disabled}
                className="status-actions"
                options={distanceOptions}
                placeholder="Please Select"
                width={220}
                style={{ margin: '0 10px' }}
                onSelect={(val) => {
                  updateGuiderails({
                    sectionId,
                    conveyorId,
                    sideId,
                    sideName,
                    updatedGuiderailsFields: { distancebetweenrailsid: val },
                  })
                }}
                value={guiderailsData[sideName].distancebetweenrailsid}
              />
            </div>
          )
        } else {
          return (
            <InputWithValidation
              label="Distance Between Rails"
              placeholder="Enter Distance Between Rails"
              disabled={disabled}
              id="distancebetweenrailsmanual"
              name="distancebetweenrailsmanual"
              key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
              small
              containerStyle={{
                width: '200px',
                display: 'inline-block',
                marginRight: '30px',
              }}
              addonAfter="in"
              rules={[
                {
                  required: true,
                  message: 'Distance Between Rails',
                },
                {
                  type: 'number',
                  transform: (val) => Number(val),
                  message: 'Distance between rails must be a number',
                },
              ]}
              type="text"
              defaultValue={guiderailsData[sideName].distancebetweenrailsmanual}
              onChange={(value) => {
                updateGuiderails({
                  sectionId,
                  sideId,
                  sideName,
                  conveyorId,
                  updatedGuiderailsFields: { distancebetweenrailsmanual: value },
                })
              }}
            />
          )
        }
      }
      if (type === 'distancetocid') {
        const tocOptions = config.distancetoclist.options
        if (Array.isArray(tocOptions) && tocOptions.length) {
          return (
            <div
              className="guiderails-inline"
              key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
            >
              <label className="guiderails-label">Distance TOC to Centerline of T Rail</label>
              <Select
                small
                disabled={disabled}
                className="status-actions"
                options={tocOptions}
                placeholder="Please Select"
                width={220}
                style={{ margin: '0 10px' }}
                onSelect={(val) => {
                  updateGuiderails({
                    sectionId,
                    conveyorId,
                    sideId,
                    sideName,
                    updatedGuiderailsFields: { distancetocid: val },
                  })
                }}
                value={guiderailsData[sideName].distancetocid}
              />
            </div>
          )
        } else {
          return (
            <InputWithValidation
              label="Distance TOC to Centerline of T Rail"
              disabled={disabled}
              placeholder="Enter Distance TOC"
              id="distancetocmanual"
              name="distancetocmanual"
              key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
              small
              containerStyle={{
                width: '200px',
                display: 'inline-block',
              }}
              addonAfter="in"
              rules={[
                {
                  required: true,
                  message: 'Distance TOC is required',
                },
                {
                  type: 'number',
                  transform: (val) => Number(val),
                  message: 'Distance TOC must be a number',
                },
              ]}
              type="text"
              defaultValue={guiderailsData[sideName].distancetocmanual}
              onChange={(value) => {
                updateGuiderails({
                  sectionId,
                  sideId,
                  sideName,
                  conveyorId,
                  updatedGuiderailsFields: { distancetocmanual: value },
                })
              }}
            />
          )
        }
      }
      if (type === 'rodid') {
        const rodOptions = config.rods.options
        if (rodOptions.length) {
          return (
            <div
              className="guiderails-inline"
              key={'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId}
            >
              <label className="guiderails-label">Length of Rods</label>
              <Select
                small
                disabled={disabled}
                className="status-actions"
                options={rodOptions}
                placeholder="Please Select"
                width={220}
                style={{ margin: '0 10px' }}
                onSelect={(val) => {
                  updateGuiderails({
                    sectionId,
                    conveyorId,
                    sideId,
                    sideName,
                    updatedGuiderailsFields: { rodid: val },
                  })
                }}
                value={guiderailsData[sideName].rodid}
              />
            </div>
          )
        }
      }
      return null
    })
  }

  renderGuiderailRailFields = (sideName, sideId, config, preferred) => {
    const { dataKey, prettyName, options, rules, vertical } = config
    const {
      guiderailsData,
      conveyorId,
      updateGuiderails,
      sectionId,
      isactive,
      islocked,
    } = this.props
    return (
      <Radio
        ref={`rails-radio-${config.shortname}`}
        disabled={!isactive || islocked}
        key={
          'default-accessories-guiderails-' + dataKey + sideName + this.props.conveyorId + preferred
            ? 'preferred'
            : 'notpreferred'
        }
        dataKey={dataKey}
        prettyName={prettyName}
        labelStyles={{
          fontWeight: 400,
        }}
        margin="0 0 10px 0"
        titleStyles={titleStyles}
        options={options}
        onChange={(val) => {
          updateGuiderails({
            sectionId,
            conveyorId,
            sideId,
            sideName,
            updatedGuiderailsFields: { [dataKey]: val },
          })
        }}
        rules={rules}
        vertical={vertical}
        initialValue={_.get(guiderailsData[sideName], dataKey)}
        guiderailsData={guiderailsData}
        tinyImages="75px"
        mapPropsToFields={(props) => {
          return {
            railschoiceid: Form.createFormField({
              value: props.guiderailsData[sideName].railschoiceid,
            }),
          }
        }}
      />
    )
  }

  renderOverrideFields = (showBottom) => {
    const {
      guiderailsData,
      updateGuiderails,
      conveyorId,
      sectionId,
      isactive,
      islocked,
    } = this.props
    const {
      left: { parameteractive: leftChecked, removedforsection: leftRemovedforsection, id: leftId },
      right: {
        parameteractive: rightChecked,
        removedforsection: rightRemovedforsection,
        id: rightId,
      },
    } = guiderailsData
    const disabled = !isactive || islocked

    const config = sidewallsGuiderailsConfig(this.props, 'guiderails')
    return config.map((fieldConfig) => {
      const { dataKey, prettyName, options, type, value } = fieldConfig

      const key = `default-accessories-guiderails-${dataKey}${this.props.conveyorId}`

      if (type === 'radio') {
        return (
          <div className="builder-tile__expanded__content__wrapper" key={key}>
            <div className="radio-title">{prettyName}</div>
            <AntRadio.Group
              disabled={disabled}
              onChange={(e) => {
                const { value } = e.target
                const guiderailsOnSection = value.toString() === 'true'
                updateGuiderails({
                  sectionId,
                  conveyorId,
                  sideId: leftId,
                  sideName: 'left',
                  updatedGuiderailsFields: { removedforsection: !guiderailsOnSection },
                })
                updateGuiderails({
                  sectionId,
                  conveyorId,
                  sideId: rightId,
                  sideName: 'right',
                  updatedGuiderailsFields: { removedforsection: !guiderailsOnSection },
                })
              }}
              defaultValue={value}
            >
              {options.map((opt) => (
                <AntRadio value={opt.id} key={opt.id}>
                  {opt.title}
                </AntRadio>
              ))}
            </AntRadio.Group>
          </div>
        )
      }

      if (type === 'checkbox') {
        if (!showBottom) return null

        return (
          <div className="builder-tile__expanded__content__wrapper" key={key}>
            <div className="radio-title">{prettyName}</div>
            {options.map((opt) => {
              const { title, id } = opt
              const isLeft = id === 'L'
              const checked = isLeft
                ? leftChecked && !leftRemovedforsection
                : rightChecked && !rightRemovedforsection

              return (
                <Checkbox
                  disabled={disabled}
                  checked={checked}
                  key={`${key}-${id}`}
                  onChange={(e) => {
                    updateGuiderails({
                      sectionId,
                      conveyorId,
                      sideId: isLeft ? leftId : rightId,
                      sideName: title.toLowerCase(),
                      updatedGuiderailsFields: {
                        removedforsection: !e.target.checked,
                        parameteractive: e.target.checked,
                      },
                    })
                  }}
                >
                  {title}
                </Checkbox>
              )
            })}
          </div>
        )
      }
      return null
    })
  }

  render() {
    const {
      guiderailsData,
      guiderailsMeta = { open: false },
      updateAccessoryWithSides,
      conveyorId,
      updateSectionOverrideMaterialAcessoriesMetaData,
      sectionId,
    } = this.props

    const hasguiderailsData =
      Boolean(guiderailsData) && Boolean(guiderailsData.left && guiderailsData.right)

    if (!hasguiderailsData) {
      return (
        <SectionOverrideHeader
          checked={false}
          open={guiderailsMeta.open}
          onClickCheck={async () => {
            const { guiderailsAccessoriesData } = this.props
            await this.props.addAccessoryWithSidesToConveyorSection({
              sideOptions: this.props.guiderailsOptions.sides,
              sectionId: this.props.sectionId,
              conveyorId: this.props.conveyorId,
              itemName: 'guiderails',
              defaultData: guiderailsAccessoriesData,
            })
            await updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'accessories',
              name: 'guiderails',
              updatedFields: {
                open: true,
              },
            })
          }}
          title="Guide Rails"
          type="accessories"
          conveyorId={this.props.conveyorId}
          fieldKey="guiderails"
        />
      )
    }

    const {
      left: {
        parameteractive: leftChecked,
        removedforsection: leftRemovedforsection,
        sectionoverride: leftOverridden,
        id: leftId,
      },
      right: {
        parameteractive: rightChecked,
        removedforsection: rightRemovedforsection,
        sectionoverride: rightOverridden,
        id: rightId,
      },
    } = guiderailsData
    const { open } = guiderailsMeta

    const showLeft = leftChecked && !leftRemovedforsection
    const showRight = rightChecked && !rightRemovedforsection
    const showBottom = showLeft || showRight

    return (
      <div className="material-accessory-item">
        <SectionOverrideHeader
          isLeftRight
          showSingleCheckbox
          checked={leftOverridden || rightOverridden || leftChecked || rightChecked}
          open={open}
          title="Guide Rails"
          type="accessories"
          conveyorId={conveyorId}
          fieldKey="guiderails"
          onClickCheck={(e) => {
            const { checked } = e.target
            updateAccessoryWithSides({
              sectionId,
              conveyorId,
              checked,
              materialKey: 'guiderails',
              updatedFields: { parameteractive: checked },
              sideIds: { left: leftId, right: rightId },
            })
          }}
          onClickOpen={() => {
            updateSectionOverrideMaterialAcessoriesMetaData({
              conveyorId,
              sectionId,
              type: 'accessories',
              name: 'guiderails',
              updatedFields: {
                open: !open,
              },
            })
          }}
        >
          {this.renderOverrideFields(showBottom)}
          {showBottom ? (
            <Tabs onChange={() => {}} type="card">
              {showLeft ? (
                <TabPane style={{ margin: '0px 20px' }} tab="Left" key={`sidetab-${leftId}`}>
                  {this.renderFields(leftId, 'left')}
                </TabPane>
              ) : null}
              {showRight ? (
                <TabPane style={{ margin: '0px 20px' }} tab="Right" key={`sidetab-${rightId}`}>
                  {this.renderFields(rightId, 'right')}
                </TabPane>
              ) : null}
            </Tabs>
          ) : null}
        </SectionOverrideHeader>
      </div>
    )
  }
}

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  try {
    const { EstimatorReducer, EstimatorMetaReducer, ProjectReducer } = state
    const { conveyorId, sectionId, match } = props
    const {
      params: { versionId },
    } = match

    const sectionMetadata =
      EstimatorMetaReducer[conveyorId].conveyorbuilder.sectionsMetadata[sectionId]
    const guiderailsData = EstimatorReducer?.sections?.[sectionId]?.accessories?.guiderails
    const guiderailsAccessoriesData = EstimatorReducer?.[conveyorId]?.accessories?.guiderails
    const guiderailsMeta = sectionMetadata?.materialsAccessoriesMetadata?.accessories
      ?.guiderails || {
      open: false,
    }

    const { isactive, islocked } = ProjectReducer.versions[versionId]

    if (!guiderailsData) {
      return {
        loading: true,
        guiderailsOptions: ProjectReducer.currentlistsettings.accessories.guiderails,
        guiderailsAccessoriesData,
      }
    }

    return {
      versionId,
      sectionId,
      conveyorId,
      guiderailsMeta,
      guiderailsData,
      guiderailsAccessoriesData,
      guiderailsOptions: ProjectReducer.currentlistsettings.accessories.guiderails,
      isactive,
      islocked,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

export default withRouter(
  connect(mapStateToProps, {
    updateGuiderails,
    updateSectionOverrideMaterialAcessoriesMetaData,
    addAccessoryWithSidesToConveyorSection,
    fetchDefaultAccessoryForConveyor,
    updateAccessoryWithSides,
  })(Guiderails)
)
