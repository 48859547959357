import { selectEstimatorMetaReducer } from 'features/Estimator/components/Accessories/redux/selectors'
import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from 'shared/hooks/app'
import { typedUseSelector } from 'utils/helpers'
import { getInfeedDischargeTransfer } from '../../../redux/ConveyorSectionActions'
import { SVG_G_SIZE_ID } from '../constants'
import { prepareConveyorSectionsToSectionData } from '../helpers/prepareConveyorSectionsForSvg'
import { selectAllConveyors } from '../redux/builderCanvasSelectors'
import { BuilderCanvasSectionData } from '../types/types'
import ConveyorRenderer, { ConveyorRendererProps } from './ConveyorRenderer'
import { selectConveyorSVGWidth } from '../../../redux/selectors'

type ConveyorSvgProps = {
  height?: string;
  width?: string;
} & Pick<ConveyorRendererProps, 'conveyorId'>

export default function ConveyorSvg({
  conveyorId,
  height = '500',
  width = '500',
}: ConveyorSvgProps) {
  const [sectionData, setSectionData] = useState<BuilderCanvasSectionData>({})
  const dispatch = useAppDispatch()
  const sectionDataLength = Object.keys(sectionData).length
  const estimatorMetaReducer = typedUseSelector(selectEstimatorMetaReducer)
  const rotateCanvas = useMemo(
    () => estimatorMetaReducer[conveyorId]?.conveyorbuilder?.rotateCanvas,
    [conveyorId, estimatorMetaReducer]
  )
  const allConveyors = typedUseSelector(selectAllConveyors)
  const conveyor = useMemo(() => allConveyors[conveyorId], [allConveyors, conveyorId])
  const unit = useMemo(() => conveyor?.unit, [conveyor])
  const chainwidth = useMemo(
    () => (unit === 'Metric' ? conveyor?.chain.widthmetric : conveyor?.chain.widthenglish),
    [conveyor, unit]
  )
  const conveyorWidth = typedUseSelector((state) => selectConveyorSVGWidth(state, conveyorId))

  useEffect(() => {
    if (conveyor?.infeed?.hastransfer && !conveyor.infeed.transfer)
      dispatch(getInfeedDischargeTransfer(conveyorId, 'infeed'))
    if (conveyor?.discharge?.hastransfer && !conveyor.discharge.transfer)
      dispatch(getInfeedDischargeTransfer(conveyorId, 'discharge'))
  }, [conveyorId, dispatch, conveyor])

  useEffect(() => {
    prepareConveyorSectionsToSectionData({
      conveyorId,
      rotateCanvas,
      unit,
      chainwidth,
      conveyor,
      sectionData,
      setSectionData: (data) => setSectionData(data),
      conveyorWidth,
    })

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDataLength])

  return (
    <svg id='builder' data-conveyorid={conveyorId}  height={height} width={width}>
      <g id={SVG_G_SIZE_ID} data-conveyorid={conveyorId}>
        <ConveyorRenderer
          conveyorId={conveyorId}
          sectionData={sectionData}
        />
      </g>
    </svg>
  )
}
