import React, { Component } from 'react'
export default class XIcon extends Component {
  render() {
    const { className = 'svg', color, disabled, size, style = {} } = this.props
    return (
      <svg
        style={style}
        className={`${className}__img ${disabled ? 'disabled' : ''}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 32 32"
      >
        <g>
          <path
            fill={color}
            d="M18.5,16l8.7-8.7c0.5-0.7,0.5-1.7-0.2-2.4s-1.6-0.7-2.4-0.2L16,13.5L7.3,4.8C6.6,4.2,5.6,4.3,4.9,4.9S4.2,6.6,4.8,7.3
          l8.7,8.7l-8.7,8.7c-0.6,0.4-0.8,1.1-0.7,1.8c0.1,0.7,0.7,1.2,1.4,1.4c0.7,0.1,1.4-0.1,1.8-0.7l8.7-8.7l8.7,8.7
          c0.7,0.5,1.7,0.5,2.4-0.2s0.7-1.6,0.2-2.4L18.5,16z"
          />
        </g>
      </svg>
    )
  }
}
