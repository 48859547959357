import React, { useRef, useEffect } from 'react'
import { Checkbox as AntCheckbox, Form } from 'antd'
import './Checkbox.scss'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

function Checkbox(props) {
  const {
    form,
    onChange,
    checked,
    className = '',
    dataKey,
    initialValue,
    disabled,
    prettyName,
    label,
    margin = '',
    style = {},
    width,
    extra,
  } = props

  /** @type {React.MutableRefObject<string>} */
  const id = useRef()

  useEffect(() => {
    id.current = uuidv4()
    form.validateFields()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleChange(e) {
    const { name, checked } = e.target
    form.setFieldsValue({ [name]: checked })
    form.validateFields((errors, values) => {
      onChange(checked, errors, values)
    })
  }

  return (
    <Form>
      <div className={`tm-checkbox ${className}`} style={{ margin }}>
        {prettyName ? <div className="tm-checkbox__pretty-name">{prettyName}</div> : null}
        <Form.Item extra={extra}>
          {form.getFieldDecorator(dataKey)(
            <AntCheckbox
              id={id.current}
              className={cn({ className, disabled })}
              name={dataKey}
              style={{ ...style, margin, width: width ? `${width}px` : 'auto' }}
              onChange={onChange ? handleChange : (e) => {}}
              disabled={disabled}
              defaultChecked={initialValue}
              checked={checked}
            >
              <label htmlFor={id.current} className="tm-checkbox__label">
                {label}
              </label>
            </AntCheckbox>
          )}
        </Form.Item>
      </div>
    </Form>
  )
}

/**
 * @type {React.ComponentClass<Pick<import('antd/lib/form').FormComponentProps, any>, any>}
 */
const formCheckbox = Form.create({ name: 'checkbox' })(Checkbox)

export default formCheckbox
