import { runUpdateDefaultMaterialOrAccessoryForConveyor } from '../../../../redux/EstimatorActions'

export const updateWearstrips = ({ conveyorId, updatedWearstripFields }) => (dispatch) => {
  dispatch(
    runUpdateDefaultMaterialOrAccessoryForConveyor({
      conveyorId,
      materialKey: 'wearstrips',
      updatedFields: updatedWearstripFields,
      type: 'materials',
    })
  )
}
