import { IConveyorWithKeyedSections } from 'shared/types/Conveyor'
import { IConveyor } from 'shared/types/swagger'
import { formatIdAsKey } from 'utils/helpers'

export default function formatConveyorsAndConveyorSections(
  conveyors: Array<IConveyor> | Record<string, IConveyorWithKeyedSections>
) {
  const conveyorArray = !Array.isArray(conveyors) ? Object.values(conveyors) : conveyors
  const conveyorsWithFormattedConveyorSections = conveyorArray.map(
    (conveyor: IConveyor | IConveyorWithKeyedSections) => {
      const sharedmuf = []
      const mandatory = []
      const nonmandatory = []

      if (Array.isArray(conveyor.pricevalidations)) {
        (conveyor?.pricevalidations || []).forEach((pv) => {
          if (pv.sharedmuf) {
            sharedmuf.push(pv)
          } else if (pv.mandatory) {
            mandatory.push(pv)
          } else {
            nonmandatory.push(pv)
          }
        })
      }

      return {
        ...conveyor,
        conveyorsections: Array.isArray(conveyor.conveyorsections)
          ? formatIdAsKey(conveyor.conveyorsections)
          : conveyor.conveyorsections,
        pricevalidations: {
          sharedmuf: formatIdAsKey(sharedmuf),
          mandatory: formatIdAsKey(mandatory),
          nonmandatory: formatIdAsKey(nonmandatory),
        },
      }
    }
  )

  return formatIdAsKey<IConveyorWithKeyedSections>(conveyorsWithFormattedConveyorSections)
}
